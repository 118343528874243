import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';

export type DataDepartment = {
  stt: number;
  departmentId: string;
  companyId: string;
  storeId: string;
  storeName: string;
  workShopId: string;
  workShopName: string;
  departmentCode: string;
  departmentName: string;
  departmentType: string;
  orderIndex: number;
  actived: boolean;
};

export interface IDepartmentServices {
  // Search department
  searchDepartment(data: object): Promise<DataResponse<Array<DataDepartment>>>;
  // Get detail department
  getDetailDepartment(departmentId: string): Promise<DataResponse<DataDepartment>>;
  // Update detail department
  updateDetailDepartment(params: object): Promise<DataResponse<boolean>>;
  // Create detail department
  createDetailDepartment(data: object): Promise<DataResponse<boolean>>;
  // Delete department
  deleteDepartment(departmentId: string): Promise<DataResponse<boolean>>;
}

export function createDepartmentServices(): IDepartmentServices {
  return {
    // Search department
    searchDepartment: async (data) => {
      return (await post(MASTER_DATA_API_URL, `department/search`, data)).data;
    },
    // Get detail department
    getDetailDepartment: async (departmentId) => {
      return (await get(MASTER_DATA_API_URL, `department/${departmentId}`)).data;
    },
    // Update detail department
    updateDetailDepartment: async (data) => {
      return (await put(MASTER_DATA_API_URL, `department/update`, data)).data;
    },
    // Create department
    createDetailDepartment: async (data) => {
      return (await post(MASTER_DATA_API_URL, `department/create`, data)).data;
    },
    // Delete department
    deleteDepartment: async (departmentId) => {
      return (await deleted(MASTER_DATA_API_URL, `department/delete/${departmentId}`)).data;
    },
  };
}
