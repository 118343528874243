/* eslint-disable react-hooks/exhaustive-deps */
import * as Icon from 'base/ui/components/Icons';

import { Form, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { catalogTypePresenter, catalogTypeStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import { SYSTEM_TITLE } from 'utils/constants';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

const createAddUpdateCatalogType = () => {
  return observer(() => {
    // translate en/vn
    const { t } = useTranslation();
    // Get data params from url
    const { id: idParams } = useParams();
    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }
    const navigate = useNavigate();
    // Check button save and save continue
    let isSaveAndContinue = false;

    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);

    // =================================================================================================================
    //===================================================FUNCTION=====================================================//
    // Call api detail store
    const getDetailCatalog = async (catalogId: string) => {
      await catalogTypePresenter.getCatalogTypeByCode(catalogTypeStore, catalogId);
      if (catalogTypeStore.catalogTypeDetailResponse.isSuccess) {
        form.setFieldsValue(catalogTypeStore.catalogTypeDetailResponse.data);
        setLoading(false);
      } else {
        navigate('/MasterData/CatalogType');
      }
    };

    // Handle create company
    const onFinish = async (values: any) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        // create new (page)
        await catalogTypePresenter.createCatalogType(catalogTypeStore, values);
        if (catalogTypeStore.crudResponse.isSuccess) {
          message.success(catalogTypeStore.crudResponse.message);

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/CatalogType');
          }
          form.resetFields();
        }
      } else {
        // update (page)
        await catalogTypePresenter.updateCatalogType(catalogTypeStore, {
          ...values,
        });
        if (catalogTypeStore.crudResponse.isSuccess) {
          message.success(catalogTypeStore.crudResponse.message);

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/CatalogType');
          }
        }
      }
      setLoading(false);
    };

    useEffect(() => {
      (async () => {
        if (idParams) {
          await getDetailCatalog(idParams);
        }
      })();
    }, []);
    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }
    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('masterdata.catalogType.title'),
                link: '/MasterData/CatalogType',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/CatalogType/${idParams}` : '/MasterData/CatalogType/create',
              },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                  {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                </div>
              </>
            }
          />

          {/* Form nhập tìm kiếm */}
          <BoxContainer fullHeight>
            <Spin spinning={loading}>
              <SearchForm onFinish={onFinish} form={form}>
                {/* loại danh mục */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường loại danh mục' }]}
                  name={'catalogTypeCode'}
                  label={t('masterdata.catalogType.catalog-type')}
                >
                  <CustomInput disabled={idParams ? true : false} />
                </SearchItem>
                {/* tên loại danh mục */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường tên loại danh mục' }]}
                  name={'catalogTypeName'}
                  label={t('masterdata.catalogType.name-catalog-type')}
                >
                  <CustomInput />
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateCatalogType;
