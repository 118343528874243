import createBranch from '.';
import createAddUpdateBranch from './CreateUpdate';
import { branchRoutes } from './routes';

export const createBranchPage = () => {
  const Branch = createBranch();
  const AddBranch = createAddUpdateBranch();
  const UpdateBranch = createAddUpdateBranch();

  const BranchPage = branchRoutes;
  return () => <BranchPage Branch={Branch} AddBranch={AddBranch} UpdateBranch={UpdateBranch} />;
};
