import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post } from 'utils/api';

export interface Province {
  stt: number;
  orderIndex: number;
  provinceId: string;
  provinceName: string;
  provinceCode?: string;
  area?: string;
  actived?: boolean;
  isForeign?: boolean;
}

export interface IProvinceServices {
  // Search province
  searchProvince(ProvinceId?: string): Promise<DataResponse<Array<Province>>>;
  // Search list province
  searchListProvince(data: object): Promise<DataResponse<Array<Province>>>;
  // create new province
  createProvince(data: object): Promise<DataResponse<any>>;
  // update new province
  updateProvince(data: object): Promise<DataResponse<any>>;
  deleteProvince(id: string): Promise<DataResponse<any>>;
  // get province by id
  getProvinceById(ProvinceId: string): Promise<DataResponse<Province>>;
}
export function createProvinceServices(): IProvinceServices {
  return {
    // Search province
    searchProvince: async (keyword) => {
      return (await get(MASTER_DATA_API_URL, `MasterData/Province/list-all${keyword ? `?SearchTerm=${keyword}` : ''}`))
        .data;
    },
    // Search list province
    searchListProvince: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/Province/search`, data)).data;
    },
    // create new province
    createProvince: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/Province/create`, data)).data;
    },
    // update new province
    updateProvince: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/Province/update`, data)).data;
    },
    // get province by id
    getProvinceById: async (ProvinceId) => {
      return (
        await get(MASTER_DATA_API_URL, `MasterData/Province/get-by-id?${ProvinceId ? `ProvinceId=${ProvinceId}` : ''}`)
      ).data;
    },
    // delete
    deleteProvince: async (id) => {
      return (await deleted(MASTER_DATA_API_URL, `MasterData/Province/delete`, { ProvinceId: id })).data;
    },
  };
}
