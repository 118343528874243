import functionPage from ".";
import { createAddUpdateFunction } from "./detail";
import { functionRoutes } from "./routes";

export const createFunctionPage = () => {
    const Search = functionPage()

    const Create = createAddUpdateFunction()
    const Update = createAddUpdateFunction()

    const FunctionPage = functionRoutes;
    return () => (
        <FunctionPage
            Search={Search} Create={Create} Update={Update} />
    );
};
