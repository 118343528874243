import createProduct from ".";
import createEditProduct from "./EditProduct";
import { productRoutes } from "./routes";

export const createProductPage = () => {
    const List = createProduct();
    const Edit = createEditProduct();

    const ProductPage = productRoutes;
    return () => (
        <ProductPage
            List={List}
            Edit={Edit}
        />
    );
};
