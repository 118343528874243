export const dataReport = [
    {
        stt: 1,
        registrationCode: '231025-035',
        ncc: "CÔNG TY CỔ PHẦN SẢN XUẤT -THƯƠNG MẠI XUẤT NHẬP KHẪU -CHẾ BIẾN GỖ MIỀN NAM",
        tgGHDuKien: new Date('2023-12-01 08:00:00'),
        tgGHThucTe: new Date('2023-12-01 08:00:00'),
        taiXe: "Nguyễn Văn A",
        sdt: "0978676567",
        soXe: '59C1-68363',
        kqAnToan: "Đạt",
        tinhTrangCan: 'Đã cân',
        tinhTrangNhanHang: 'Đã nhận hàng',
        poNumber: "4900016555",
        poItem: '0010',
        productName: "294010884 | GC CNC mặt bàn Chadwick square coffee",
        slDuKien: 200,
        slThucTe: 200,
        tgNhanHang: new Date('2023-12-07 15:24:00'),
        tyLe: 100,
        trangThaiGiaoHang: "Đúng hẹn"
    },
    {
        stt: 2,
        registrationCode: '231025-036',
        ncc: "CÔNG TY CỔ PHẦN SẢN XUẤT -THƯƠNG MẠI XUẤT NHẬP KHẪU -CHẾ BIẾN GỖ MIỀN NAM",
        tgGHDuKien: new Date('2023-12-01 15:00:00'),
        tgGHThucTe: new Date('2023-12-01 15:00:00'),
        taiXe: "Nguyễn Văn B",
        sdt: "0978676567",
        soXe: '59C1-68363',
        kqAnToan: "Đạt",
        tinhTrangCan: 'Đã cân',
        tinhTrangNhanHang: 'Đã nhận hàng',
        poNumber: "4900016556",
        poItem: '0011',
        productName: "294010884 | GC CNC mặt bàn Chadwick square coffee",
        slDuKien: 300,
        slThucTe: 300,
        tgNhanHang: new Date('2023-12-07 14:24:00'),
        tyLe: 100,
        trangThaiGiaoHang: "Đúng hẹn"
    },
]

export const dataReceicing = [{
    order: 1,
    itemCode: '14100607',
    itemName: "Soda ash Dense(truck)",
    ncc: 'Taiko',
    unit: ['Ton/Tấn', 'Truck/Xe'],
    po: ['4500017114', '4500017116'],
    warehouse: 'MB01-NL Khô R1',
    plan: '60',
    actual: 50,
    difference: 10
}]