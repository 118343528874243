import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { DataCatalog, ICatalogServices, WorkType } from 'services/RawData/catalog_services';

export type catalogStore = {
  // Type default response search
  searchDropdownCatalogResponse: DataResponse<Array<DataCatalog>>;
  searchCatalogResponse: DataResponse<Array<DataCatalog>>;
  catalogDetailResponse: DataResponse<DataCatalog | undefined>;
  crudResponse: DataResponse<null>;
  workType: DataResponse<WorkType[]>;
};

export interface ICatalogPresenter {
  createStore(): catalogStore;
  // Search dropdown
  searchDropdownCatalog(store: catalogStore, catalogtypeCode: string): Promise<void>;
  // Search catalog
  searchCatalog(store: catalogStore, data: object): Promise<void>;
  // Update catalog
  updateCatalog(store: catalogStore, data: object): Promise<void>;
  // Create catalog
  createCatalog(store: catalogStore, data: object): Promise<void>;
  // Delete catalog
  deleteCatalog(store: catalogStore, id: string): Promise<void>;
  // get catalog by id
  getCatalogById(store: catalogStore, getCatalogById: string): Promise<void>;
  // get dropdown work type
  getDropdownWorkType(store: catalogStore): Promise<void>;
}

export function createCatalogPresenter({ catalogServices }: { catalogServices: ICatalogServices }): ICatalogPresenter {
  return {
    createStore: () =>
      observable({
        searchDropdownCatalogResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
        searchCatalogResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        catalogDetailResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        crudResponse: {
          data: null,
          message: '',
          isSuccess: false,
        },
        workType: {
          data: [],
          message: '',
          isSuccess: false,
        },
      }),
    // Search dropdown
    searchDropdownCatalog: async (store, catalogtypeCode) => {
      try {
        const result = await catalogServices.searchDropdownCatalog(catalogtypeCode);
        runInAction(() => {
          store.searchDropdownCatalogResponse.data = result.data;
          store.searchDropdownCatalogResponse.isSuccess = result.isSuccess;
          store.searchDropdownCatalogResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Search catalog
    searchCatalog: async (store, data) => {
      try {
        const result = await catalogServices.searchCatalog(data);
        runInAction(() => {
          store.searchCatalogResponse.data = result.data;
          store.searchCatalogResponse.isSuccess = result.isSuccess;
          store.searchCatalogResponse.message = result.message;
          store.searchCatalogResponse.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // update catalog
    updateCatalog: async (store, data) => {
      try {
        const result = await catalogServices.updateCatalog(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    createCatalog: async (store, data) => {
      try {
        const result = await catalogServices.createCatalog(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
          store.crudResponse.developerMessage = result.developerMessage;
        });
      } catch (error) {
        console.log(error);
      }
    },
    deleteCatalog: async (store, id) => {
      try {
        const result = await catalogServices.deleteCatalog(id);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getCatalogById: async (store, id) => {
      try {
        const result = await catalogServices.getCatalogById(id);
        runInAction(() => {
          store.catalogDetailResponse.isSuccess = result.isSuccess;
          store.catalogDetailResponse.data = result.data;
          store.catalogDetailResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getDropdownWorkType: async (store) => {
      try {
        const result = await catalogServices.getDropdownWorkType();
        runInAction(() => {
          store.workType.data = result.data;
          store.workType.message = result.message;
          store.workType.isSuccess = result.isSuccess;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
