import menuPage from ".";
import createAddUpdateMenu from "./detail";
import { menuRoutes } from "./routes";

export const createMenuPage = () => {
    const Search = menuPage()

    const Create = createAddUpdateMenu()
    const Update = createAddUpdateMenu()

    const Menu = menuRoutes;
    return () => (
        <Menu
            Search={Search} Create={Create} Update={Update} />
    );
};
