import { createServices } from 'services/services';
import { createUserPresenter } from 'stores/User/user_store';
import { createCommonPresenter } from './Main/common_store';
import { createUIPresenter } from './DisplaySetting/ui_store';
import { createLanguagePresenter } from './Language/language_store';

import { createReportSavedPresenter } from './SaveRepoprtTemplete/reportSaved_store';
import { createProvincePresenter } from './RawData/province_store';
import { createDistrictPresenter } from './RawData/district_store';
import { createCompanyPresenter } from './RawData/company_store';
import { createBranchPresenter } from './RawData/branch_store';
import { createCatalogPresenter } from './RawData/catalog_store';
import { createStockPresenter } from './RawData/stock_store';
import { createDepartmentPresenter } from './RawData/department_store';
import { createCatalogTypePresenter } from './RawData/catalogType_store';
import { createUserConfigPresenter } from './User/userConfig_store';
import { createAccountPresenter } from './RawData/account_store';
import { createRolesPresenter } from './RawData/role_store';
import { createSalesEmployeePresenter } from './RawData/salesEmployee_store';
import { createWardPresenter } from './RawData/ward_store';
import { createCustomerPresenter } from './RawData/customer_store';
import { createProductPresenter } from './RawData/product_store';
import { createWorkPresenter } from './RawData/work_store';
import { createCategoryPresenter } from './RawData/sales_stores';
import { createCommonMasterDataPresenter } from './RawData/commonMasterData_store';
import { createVendorPresenter } from './RawData/vendor_store';
import { createPurchaseOrderPresenter } from './Main/purchaseOrder_store';
import { createPagePresenter } from './RawData/page_store';
import { createMenuPresenter } from './RawData/menu_store';
import { createReportPresenter } from './Main/report_store';
import { createDriverPresenter } from './RawData/driver_store';
import { createFunctionPresenter } from './RawData/function_store';
import { createMobileScreenPresenter } from './RawData/mobileScreen_store';
import { createNotificationPresenter } from './Notification/notification_store';


// khởi tạo services
export const services = createServices();

// khởi tạo presenter và store của user
export const userPresenter = createUserPresenter(services);
export const userStore = userPresenter.createStore();

// khởi tạo presenter và store của user
export const uiPresenter = createUIPresenter();
export const uiStore = uiPresenter.createStore();

// khởi tạo presenter và store của language
export const languagePresenter = createLanguagePresenter(services);
export const languageStore = languagePresenter.createStore();

// khởi tạo presenter và store của Common
export const commonPresenter = createCommonPresenter(services);
export const commonStore = commonPresenter.createStore();




//========================================STORE DÙNG CHUNG===========================================================
// Luu mau bao cao
export const reportSavedPresenter = createReportSavedPresenter(services);
export const reportSavedStore = reportSavedPresenter.createStore();


//========================================CHỨC NĂNG CHÍNH===========================================================
export const purchaseOrderPresenter = createPurchaseOrderPresenter(services);
export const purchaseOrderStore = purchaseOrderPresenter.createStore();
// Báo cáo
export const reportPresenter = createReportPresenter(services);
export const reportStore = reportPresenter.createStore();
// Thông báo
export const notificationPresenter = createNotificationPresenter(services);
export const notificationStore = notificationPresenter.createStore();


// ======== MASTER DATA =================================================================================================

// khởi tạo presenter và store của Common
export const commonMasterDataPresenter = createCommonMasterDataPresenter(services);
export const commonMasterDataStore = commonMasterDataPresenter.createStore();


// Initialize presenter and store of province
export const provincePresenter = createProvincePresenter(services);
export const provinceStore = provincePresenter.createStore();

// Initialize presenter and store of district
export const districtPresenter = createDistrictPresenter(services);
export const districtStore = districtPresenter.createStore();

// Initialize presenter and store of company
export const companyPresenter = createCompanyPresenter(services);
export const companyStore = companyPresenter.createStore();

// Initialize presenter and store of branch (store)
export const branchPresenter = createBranchPresenter(services);
export const branchStore = branchPresenter.createStore();

// Initialize presenter and store of catalog
export const catalogPresenter = createCatalogPresenter(services);
export const catalogStore = catalogPresenter.createStore();

// Initialize presenter and store of stock
export const stockPresenter = createStockPresenter(services);
export const stockStore = stockPresenter.createStore();

// Initialize presenter and store of department
export const departmentPresenter = createDepartmentPresenter(services);
export const departmentStore = departmentPresenter.createStore();

// Initialize presenter and store of catalogType (Loại danh mục dùng chung)
export const catalogTypePresenter = createCatalogTypePresenter(services);
export const catalogTypeStore = catalogTypePresenter.createStore();

// Initialize presenter and store of user config
export const userConfigPresenter = createUserConfigPresenter(services);
export const userConfigStore = userConfigPresenter.createStore();


// Initialize presenter and store of account
export const accountPresenter = createAccountPresenter(services);
export const accountStore = accountPresenter.createStore();

// Initialize presenter and store of roles (nhóm người dùng)
export const rolesPresenter = createRolesPresenter(services);
export const rolesStore = rolesPresenter.createStore();

// Initialize presenter and store of sales employee (nhân viên)
export const salesEmployeePresenter = createSalesEmployeePresenter(services);
export const salesEmployeeStore = salesEmployeePresenter.createStore();

// Initialize presenter and store of ward (phường xã)
export const wardPresenter = createWardPresenter(services);
export const wardStore = wardPresenter.createStore();

// Initialize presenter and store of product
export const productPresenter = createProductPresenter(services);
export const productStore = productPresenter.createStore();

// Initialize presenter and store of customer
export const customerPresenter = createCustomerPresenter(services);
export const customerStore = customerPresenter.createStore();

// Initialize presenter and store of vendor
export const vendorPresenter = createVendorPresenter(services);
export const vendorStore = vendorPresenter.createStore();

// Initialize presenter and store of page
export const pagePresenter = createPagePresenter(services);
export const pageStore = pagePresenter.createStore();

// Initialize presenter and store of menu
export const menuPresenter = createMenuPresenter(services);
export const menuStore = menuPresenter.createStore();

// Initialize presenter and store of driver
export const driverPresenter = createDriverPresenter(services);
export const driverStore = driverPresenter.createStore();

// Thao tác
export const functionPresenter = createFunctionPresenter(services);
export const functionStore = functionPresenter.createStore();

// Quản lý chức năng mobile
export const mobileScreenPresenter = createMobileScreenPresenter(services);
export const mobileScreenStore = mobileScreenPresenter.createStore();



// Initialize presenter and store work
export const workPresenter = createWorkPresenter(services);
export const workStore = workPresenter.createStore();

// Initialize presenter and store sales
export const categoryPresenter = createCategoryPresenter(services);
export const categoryStore = categoryPresenter.createStore();


