import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';
import { CommonModel } from './commonMasterData_services';

export type PageModel = Partial<{
    stt: number;
    pageId: string;
    pageName: string;
    pageUrl: string;
    menuId: string;
    menuName: string;
    orderIndex: number;
    icon: string;
    actived: boolean;
    visiable: boolean;
    isSystem: boolean;
    parameter: string;
    isWebPortal: boolean;
    functionList: FunctionModel[]
    activedFunctionList: FunctionModel[]
}>;



export interface FunctionModel {
    functionId: string;
    functionName: string;
    orderIndex: number;
}


export interface IPageServices {
    getListPage(data: object): Promise<DataResponse<Array<PageModel>>>;
    // detail
    getPageById(pageId: string): Promise<DataResponse<PageModel | undefined>>;
    // Danh sách thao tác
    getAllFunction(): Promise<DataResponse<Array<FunctionModel>>>;
    // danh sách danh mục (Menu)
    getDropdownMenu(keyword?: string): Promise<DataResponse<Array<CommonModel>>>
    // tạo chức năng
    createPage(data: object): Promise<DataResponse<null>>
    updatePage(data: object): Promise<DataResponse<null>>
    // xóa page
    deletePage(pageId: string): Promise<DataResponse<null>>
}
export function createPageServices(): IPageServices {
    return {
        // Search page
        getListPage: async (data) => {
            return (await post(MASTER_DATA_API_URL, `page/search`, data)).data;
        },
        getPageById: async (pageId) => {
            return (await get(MASTER_DATA_API_URL, `page/${pageId}`)).data;
        },
        // Danh sách thao tác
        getAllFunction: async () => {
            return (await get(MASTER_DATA_API_URL, `common/list-all-function`)).data;
        },
        // danh sách danh mục (Menu)
        getDropdownMenu: async (keyword) => {
            return (await get(MASTER_DATA_API_URL, `common/get-dropdown-menu`, { keyword: keyword ? keyword : undefined })).data;
        },
        // Tạo chức năng 
        createPage: async (data) => {
            return (await post(MASTER_DATA_API_URL, `page/create`, data)).data;
        },
        updatePage: async (data) => {
            return (await put(MASTER_DATA_API_URL, `page/update`, data)).data;
        },
        // xóa page
        deletePage: async (pageId) => {
            return (await deleted(MASTER_DATA_API_URL, `page/delete/${pageId}`)).data;
        }
    };
}
