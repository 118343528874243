import BoxContainer from 'base/ui/components/BoxContainer'
import { observer } from 'mobx-react'
import PageWrapper from 'pages/Components/PageWrapper'
import image6 from 'assets/images/image6.png'
import image15 from 'assets/images/image15.png'
import image14 from 'assets/images/image14.png'
import image96 from 'assets/images/image96.png'
import image20 from 'assets/images/image20.png'
import { Affix, Card, DatePicker, Form, Input, Select, SelectProps, Spin } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { SYSTEM_TITLE } from 'utils/constants';
import { Link } from 'react-router-dom';
import { IPurchaseOrderServices, SearchReceiveSchedule } from 'services/Main/purchaseOrder_services'
import { ReceiveScheduleModel } from 'models/DeliveryRegistration'
import dayjs from 'dayjs'
import { commonPresenter, commonStore, stockPresenter, stockStore } from 'stores/root_store'
import { CommonModel } from 'services/Main/common_services'
import { debounce } from 'utils/debounce'
import { CardItem } from './cardItem'
import { TaskListEdit } from 'base/ui/components/Icons'
import CustomButton from 'base/ui/components/Button';
import { AiOutlineFullscreen } from 'react-icons/ai'

const data = [
    {
        name: 'Dự kiến nhận hàng',
        children: [
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực"
            },
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực"
            },
        ]
    },
    {
        name: 'Đang nhận hàng',
        children: [
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực",
                checkIn: "7:50 20/10/2023",
            },
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực",
                checkIn: "7:50 20/10/2023",
            },

        ]
    },
    {
        name: 'Đã giao hàng',
        children: [
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực",
                checkIn: "7:50 20/10/2023",
            },
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực",
                checkIn: "7:50 20/10/2023",
            },

        ]
    },
    {
        name: 'Từ chối nhận hàng',
        children: [
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực",
                checkIn: "7:50 20/10/2023",
            },
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực",
                checkIn: "7:50 20/10/2023",
            },

        ]
    },
    {
        name: 'Đã ra cổng',
        children: [
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực",
                checkIn: "7:50 20/10/2023",
            },
            {
                title: "Đăng ký xe 1000213 - Sáng 20/07/2023",
                vendor: "CÔNG TY TNHH NAM VIỆT",
                expectedDate: "08:30 20/07/2023",
                licensePlate: "71C-05036",
                driverName: "Phan Văn Lực",
                checkIn: "7:50 20/10/2023",
            },

        ]
    },
]

const createReceiveSchedule = (services: IPurchaseOrderServices) => {
    return observer(() => {
        document.title = 'Lịch nhận hàng - ' + SYSTEM_TITLE;

        const [formSearch] = Form.useForm()
        const [listCheckInOut, setListCheckInOut] = useState<ReceiveScheduleModel | null>(null)
        const [loadingSekeleton, setLoadingSekeleton] = useState(true)
        const [loading, setLoading] = useState(false)
        const [listVendor, setListVendor] = useState<CommonModel[]>([])
        // Dropdown kho nhận
        const [listStocks, setListStocks] = useState<SelectProps['options']>([])
        // Dropdown kho nhận
        const [listProducts, setListProducts] = useState<SelectProps['options']>([])

        // sau khi render lần đầu thì set = true
        const [isReady, setIsReady] = useState(false)


        const getReceiveSchedule = async (values: SearchReceiveSchedule) => {
            try {
                setLoading(true)
                const result = await services.getListCheckinCheckout(values)
                setLoading(false)
                if (result && result.data) {
                    setListCheckInOut(result.data)
                }
            } catch (error) {

            }
        }
        const [isFullscreen, setIsFullscreen] = useState(false);



        useEffect(() => {
            const handleFullscreenChange = () => {
                setIsFullscreen(
                    document.fullscreenElement !== null
                );
            };
            document.addEventListener('fullscreenchange', handleFullscreenChange);
            return () => {
                document.removeEventListener('fullscreenchange', handleFullscreenChange);
            }
        }, []);


        // Danh sách kho 
        const getDropdownStock = async (keyword?: string) => {
            await stockPresenter.searchAllStock(stockStore, keyword || '')
            if (stockStore.searchAllStockResponse.isSuccess) {
                const stocks = stockStore.searchAllStockResponse.data
                setListStocks(stocks.map(stock => ({ label: stock.value, value: stock.stockCode })))
            }
        }
        // Danh sách hàng hóa 
        const getDropdownProduct = async (keyword?: string) => {
            const res = await commonPresenter.getDropdownProduct(keyword || '')
            if (res) {
                setListProducts(res.map(prd => ({ label: prd.value, value: prd.key })))
            }
        }
        const searchProduct = useCallback(debounce(getDropdownProduct, 300), [])

        // Dropdown nhà cung cấp
        const getDropdownVendor = async (keyword?: string) => {
            await commonPresenter.getDropdownVendor(commonStore, { keyword: keyword, takeNumber: 20 })
            if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
                setListVendor(commonStore.commonRespone.data)
            }
        }
        const searchVendor = useCallback(debounce(getDropdownVendor, 300), [])

        useEffect(() => {
            (async () => {
                await Promise.allSettled([
                    getDropdownVendor(),
                    getDropdownStock(),
                    getDropdownProduct(),
                    getReceiveSchedule({ searchDate: dayjs().format("YYYY-MM-DD") }),
                ])
                setIsReady(true)
                setLoadingSekeleton(false)
            })()
        }, []);

        useEffect(() => {
            let timeoutId: NodeJS.Timer;
            if (isReady) {
                timeoutId = setInterval(() => {
                    formSearch.submit()
                }, 60 * 1000)
            }
            return () => {
                clearInterval(timeoutId)
            }
        }, [isReady, formSearch]);


        return (
            <PageWrapper
                breadcrumb={[{
                    text: "Theo dõi nhận hàng"
                }]}
            // extras={
            //     <CustomButton
            //         type='primary'
            //         onClick={() => {
            //             const screen = document.getElementById('screen')
            //             if (screen) {
            //                 screen.requestFullscreen()
            //             }
            //         }}
            //         icon={<AiOutlineFullscreen />}
            //     >
            //         Toàn màn hình
            //     </CustomButton>
            // }
            >
                {/* <BoxContainer>
                    
                </BoxContainer> */}
                <BoxContainer id={'screen'} fullHeight className='!gap-2 overflow-auto' >
                    <SearchForm
                        // className={isFullscreen ? '!hidden' : ''}
                        layout='vertical'
                        column='col-5'
                        colon={false}
                        initialValues={{
                            searchDate: dayjs(),
                        }}
                        form={formSearch}
                        onFinish={(values) => {
                            const { searchDate, licensePlate } = values
                            getReceiveSchedule({
                                ...values,
                                searchDate: dayjs(searchDate).format('YYYY-MM-DD'),
                                licensePlate: licensePlate ? licensePlate.trim() : undefined
                            })
                        }}

                    >
                        <button type='submit' hidden></button>
                        {/* Ngày giao hàng */}
                        <SearchItem name={'searchDate'} label="Ngày giao hàng">
                            <DatePicker className='w-full' allowClear={false} format={'DD/MM/YYYY'}
                                changeOnBlur
                                onSelect={(value) => {
                                    formSearch.submit()
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        formSearch.submit()
                                    }
                                }}
                            />
                        </SearchItem>
                        {/* Nhà cung cấp */}
                        <SearchItem name={'vendorNumber'} label="Nhà cung cấp">
                            <Select
                                allowClear
                                options={listVendor}
                                fieldNames={{ label: "value", value: 'key' }}
                                filterOption={false}
                                popupMatchSelectWidth={false}
                                showSearch
                                onSearch={(values) => searchVendor(values || undefined)}
                                onSelect={(value) => {
                                    formSearch.submit()
                                }}
                                onClear={() => {
                                    formSearch.submit()
                                    searchVendor()
                                }}
                                placeholder="Vui lòng chọn"
                            />
                        </SearchItem>
                        {/* Kho */}
                        <SearchItem name={'stockCodes'} label="Kho">
                            <Select
                                allowClear
                                showSearch
                                mode='multiple'
                                options={listStocks}
                                filterOption={(input, option) => (option?.value + '' || '')?.toLowerCase()?.includes(input.toLowerCase())}
                                // onSelect={(value) => {
                                //     formSearch.submit()
                                // }}
                                onBlur={() => {
                                    formSearch.submit()
                                }}
                                onDeselect={() => {
                                    formSearch.submit()
                                }}
                                onClear={() => {
                                    formSearch.submit()
                                }}
                                placeholder="Vui lòng chọn"
                            />
                        </SearchItem>
                        {/* Hàng hóa */}
                        <SearchItem name={'productCodes'} label="Hàng hóa">
                            <Select
                                mode='multiple'
                                allowClear
                                options={listProducts}
                                filterOption={false}
                                popupMatchSelectWidth={false}
                                showSearch
                                onSearch={(values) => searchProduct(values || undefined)}
                                // onSelect={(value) => {
                                //     formSearch.submit()
                                // }}
                                onBlur={() => {
                                    formSearch.submit()
                                }}
                                onDeselect={() => {
                                    formSearch.submit()
                                }}
                                onClear={() => {
                                    formSearch.submit()
                                    searchProduct()
                                }}
                                placeholder="Vui lòng chọn"
                            />
                        </SearchItem>
                        {/* Số xe */}
                        <SearchItem name={'licensePlate'} label="Số xe">
                            <Input onBlur={() => {
                                formSearch.submit()
                            }}
                                placeholder="Nhập số xe"
                            />
                        </SearchItem>
                    </SearchForm>
                    <div className="h-full" >
                        <Spin spinning={!loadingSekeleton && loading} rootClassName='h-full' className='h-full' >
                            <div className='w-full h-full' >
                                <Affix offsetTop={0} className='w-full'>
                                    <div className={`px-2 mb-2 z-10 bg-white`} style={{ boxShadow: '0 10px 7px -5px #dbdbdb' }}>
                                        <div className='grid grid-cols-5 gap-1.5 text-sm font-bold py-1'>
                                            <div className='w-full flex items-center gap-2 justify-center'>
                                                <img src={image6} className='w-10' /><h2>Dự kiến nhận hàng</h2>
                                            </div>
                                            <div className='w-full flex items-center gap-2 justify-center'>
                                                <img src={image14} className='w-8' /><h2>Đang nhận hàng</h2>
                                            </div>
                                            <div className='w-full flex items-center gap-2 justify-center'>
                                                <img src={image96} className='w-8' /><h2>Đã giao hàng</h2>
                                            </div>
                                            <div className='w-full flex items-center gap-2 justify-center'>
                                                <img src={image20} className='w-8' /><h2>Từ chối nhận hàng</h2>
                                            </div>
                                            <div className='w-full flex items-center gap-2 justify-center'>
                                                <img src={image15} className='w-10' /><h2>Đã ra cổng</h2>
                                            </div>
                                        </div>
                                    </div>
                                </Affix>

                                <div className="grid grid-cols-5 gap-1.5 text-xs" style={{ minHeight: isFullscreen ? 'calc(100vh - 128px)' : undefined }}>
                                    {loadingSekeleton && data.map((item, index) => (
                                        <div key={index} className="flex flex-col gap-1.5 bg-gray-100 p-1 h-full" style={{ minHeight: 'calc(100vh - 280px)' }}>
                                            {item.children.map((child, idx) => (
                                                <Card className='!z-0' key={idx} size='small' loading></Card>
                                            ))}
                                        </div>
                                    ))}
                                    {!loadingSekeleton && (<>
                                        {/* Dự kiến nhận hàng */}
                                        <div className="flex flex-col gap-1.5 bg-gray-100 p-1 h-full" style={{ minHeight: 'calc(100vh - 280px)' }}>
                                            {listCheckInOut && listCheckInOut.listExpectedDelivery.map((item, index) => (
                                                <CardItem
                                                    key={index}
                                                    data={{
                                                        id: item.deliveryRegistrationId,
                                                        title: item.title,
                                                        expectedDate: item.deliveryDate,
                                                        driverName: item.driverName,
                                                        licensePlate: item.licensePlate,
                                                        vendor: item.vendorName
                                                    }}
                                                    icon={<img src={image6} className='w-4' />}
                                                    hidenCheckInOut={true}
                                                    color={'#ffffff'}
                                                />
                                            ))}
                                        </div>
                                        {/* Đang nhận hàng */}
                                        <div className="flex flex-col gap-1.5 bg-gray-100 p-1 h-full" style={{ minHeight: 'calc(100vh - 280px)' }}>
                                            {listCheckInOut && listCheckInOut.listReceiving.map((item, index) => (
                                                <CardItem
                                                    key={index}
                                                    data={{
                                                        id: item.deliveryRegistrationId,
                                                        title: item.title,
                                                        expectedDate: item.deliveryDate,
                                                        driverName: item.driverName,
                                                        licensePlate: item.licensePlate,
                                                        vendor: item.vendorName,
                                                        checkIn: item.checkInDate
                                                    }}
                                                    icon={<img src={image14} className='w-4' />}
                                                    hidenCheckInOut={false}
                                                    color={'#ffe699'}
                                                />
                                            ))}
                                        </div>
                                        {/* Đã giao hàng */}
                                        <div className="flex flex-col gap-1.5 bg-gray-100 p-1 h-full" style={{ minHeight: 'calc(100vh - 280px)' }}>
                                            {listCheckInOut && listCheckInOut.listReceived.map((item, index) => (
                                                <CardItem
                                                    key={index}
                                                    data={{
                                                        id: item.deliveryRegistrationId,
                                                        title: item.title,
                                                        expectedDate: item.deliveryDate,
                                                        driverName: item.driverName,
                                                        licensePlate: item.licensePlate,
                                                        vendor: item.vendorName,
                                                        checkIn: item.checkInDate,
                                                        checkOut: item.checkOutDate
                                                    }}
                                                    icon={<img src={image96} className='w-4' />}
                                                    hidenCheckInOut={false}
                                                    color={'#D7DAE4'}
                                                />
                                            ))}
                                        </div>
                                        {/* Từ chối nhận hàng */}
                                        <div className="flex flex-col gap-1.5 bg-gray-100 p-1 h-full" style={{ minHeight: 'calc(100vh - 280px)' }}>
                                            {listCheckInOut && listCheckInOut?.listReject.map((item, index) => (
                                                <CardItem
                                                    key={index}
                                                    data={{
                                                        id: item.deliveryRegistrationId,
                                                        title: item.title,
                                                        expectedDate: item.deliveryDate,
                                                        driverName: item.driverName,
                                                        licensePlate: item.licensePlate,
                                                        vendor: item.vendorName,
                                                        checkIn: item.checkInDate,
                                                        checkOut: item.checkOutDate,
                                                        reason: item.rejectReason
                                                    }}
                                                    icon={<img src={image20} className='w-4' />}
                                                    hidenCheckInOut={false}
                                                    hidenReason={false}
                                                    color={'#FFDADA'}
                                                />
                                            ))}
                                        </div>
                                        {/* Đã ra cổng */}
                                        <div className="flex flex-col gap-1.5 bg-gray-100 p-1 h-full" style={{ minHeight: 'calc(100vh - 280px)' }}>
                                            {listCheckInOut && listCheckInOut.listCheckout.map((item, index) => (
                                                <CardItem
                                                    key={index}
                                                    data={{
                                                        id: item.deliveryRegistrationId,
                                                        title: item.title,
                                                        expectedDate: item.deliveryDate,
                                                        driverName: item.driverName,
                                                        licensePlate: item.licensePlate,
                                                        vendor: item.vendorName,
                                                        checkIn: item.checkInDate,
                                                        checkOut: item.checkOutDate
                                                    }}
                                                    icon={<img src={image15} className='w-4' />}
                                                    hidenCheckInOut={false}
                                                    color={'#c6e0b4'}
                                                />
                                            ))}
                                        </div>
                                    </>)}
                                </div>
                            </div>
                        </Spin>
                    </div>
                </BoxContainer>

            </PageWrapper>
        )
    })
}



export default createReceiveSchedule