/* eslint-disable react-hooks/exhaustive-deps */
import * as Icon from 'base/ui/components/Icons';

import { Form, InputNumber, Radio, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { catalogPresenter, catalogStore, commonMasterDataPresenter, commonMasterDataStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { SYSTEM_TITLE } from 'utils/constants';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

const createAddUpdateCatalog = () => {
  return observer(() => {
    // translate en/vn
    const { t } = useTranslation();
    // Get data params from url
    const { id: idParams } = useParams();
    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }

    const navigate = useNavigate();
    // Check button save and save continue
    let isSaveAndContinue = false;

    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);

    //=================================================FUNCTION===========================================================//
    //=================================================FUNCTION===========================================================//
    // Call api detail store
    const getDetailCatalog = async (catalogId: string) => {
      await catalogPresenter.getCatalogById(catalogStore, catalogId);
      if (catalogStore.catalogDetailResponse.isSuccess) {
        form.setFieldsValue(catalogStore.catalogDetailResponse.data);
        setLoading(false);
      } else {
        navigate('/MasterData/Catalog');
      }
    };

    // Handle create / update danh mục dùng chung
    const onFinish = async (values: any) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        // create danh mục dùng chung
        await catalogPresenter.createCatalog(catalogStore, values);
        if (catalogStore.crudResponse.isSuccess) {
          message.success(catalogStore.crudResponse.message);

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Catalog');
          }
          form.resetFields();
        }
      } else {
        // update danh mục dùng chung
        await catalogPresenter.updateCatalog(catalogStore, {
          ...values,
          actived: JSON.parse(values.actived),
          catalogId: idParams,
        });
        if (catalogStore.crudResponse.isSuccess) {
          message.success(catalogStore.crudResponse.message);

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Catalog');
          }
        }
      }
      setLoading(false);
    };

    useEffect(() => {
      (async () => {
        if (idParams) {
          await getDetailCatalog(idParams);
        }
        await commonMasterDataPresenter.getDropDownCategoryType(commonMasterDataStore);
      })();
    }, []);

    // giá trị ban đầu của form
    const initialValues = {
      actived: true,
    };
    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }
    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('masterdata.shared-category.title'),
                link: '/MasterData/Catalog',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/Catalog/${idParams}` : '/MasterData/Catalog/create',
              },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                  {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                </div>
              </>
            }
          />
          {/* Form nhập tìm kiếm */}
          <BoxContainer fullHeight>
            <Spin spinning={loading}>
              <SearchForm onFinish={onFinish} initialValues={idParams ? {} : initialValues} form={form}>
                {/* mã */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mã' }]}
                  name={'catalogCode'}
                  label={t('masterdata.shared-category.catalogue-code')}
                >
                  <CustomInput />
                </SearchItem>
                {/* loại danh mục */}
                <SearchItem name={'catalogTypeCode'} label={t('masterdata.shared-category.catalogue-type')}>
                  <CustomSelect
                    className="rounded-sm"
                    options={commonMasterDataStore.commonRespone.data}
                    showSearch
                    filterOption={false}
                    onSearch={debounce(async (value) => {
                      await commonMasterDataPresenter.getDropDownCategoryType(commonMasterDataStore, value);
                    }, 500)}
                    fieldNames={{ label: 'value', value: 'key' }}
                  />
                </SearchItem>
                {/* tên tiếng việt */}
                <SearchItem name={'catalogText_vi'} label={t('masterdata.shared-category.vietnamese-name')}>
                  <CustomInput />
                </SearchItem>
                {/* tên tiếng anh */}
                <SearchItem name={'catalogText_en'} label={t('masterdata.shared-category.english-name')}>
                  <CustomInput />
                </SearchItem>
                {/* thứ tự hiển thị */}
                <SearchItem name={'orderIndex'} label={t('masterdata.shared-category.order-index')}>
                  <InputNumber className="!w-full" controls={false} />
                </SearchItem>
                {/* trạng thái */}
                <SearchItem className="m-0 mb-2" name={'actived'} label={t('masterdata.shared-category.status')}>
                  <Radio.Group>
                    <Radio value={true}> {t('radio-btn.active')}</Radio>
                    <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                  </Radio.Group>
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateCatalog;
