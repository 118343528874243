/* eslint-disable react-hooks/exhaustive-deps */
import * as Icon from 'base/ui/components/Icons';

import { Checkbox, Form, Radio, Select, Space, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { accountPresenter, accountStore, commonMasterDataPresenter, commonMasterDataStore, rolesPresenter, rolesStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import { SYSTEM_TITLE } from 'utils/constants';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import { CommonModel } from 'services/Main/common_services';

const createAddUpdateRoles = () => {
  return observer(() => {
    const { t } = useTranslation();
    // Get data params from url
    const { id: idParams } = useParams();
    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }
    const navigate = useNavigate();
    // Check button save and save continue
    let isSaveAndContinue = false;

    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);
    const [dropdownDepartment, setDropdownDepartment] = useState<CommonModel[]>([]);

    //==============================================================FUNCTION=====================================================//
    //==============================================================FUNCTION=====================================================//
    // Call api get detail in store
    const getDetailRoleById = async (roleId: string) => {
      await rolesPresenter.getRolesById(rolesStore, roleId);
      if (rolesStore.rolesDetailResponse.isSuccess) {
        form.setFieldsValue(rolesStore.rolesDetailResponse.data);
        setLoading(false);
      } else {
        message.error(rolesStore.rolesDetailResponse.message)
        navigate('/Permission/Roles');
      }
    };

    // lấy dropdown phòng ban
    const getDropdownDepartment = async () => {
      await commonMasterDataPresenter.getDropdownDepartment(commonMasterDataStore)
      if (commonMasterDataStore.commonRespone.isSuccess) {
        setDropdownDepartment(commonMasterDataStore.commonRespone.data)
      }
    }

    // Handle create company
    const onFinish = async (values: any) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        // create roles page
        await rolesPresenter.createRoles(rolesStore, {
          ...values,
        });
        if (rolesStore.crudResponse.isSuccess) {
          message.success(rolesStore.crudResponse.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/Permission/Roles');
          }
          form.resetFields();
        }
      } else {
        // update roles page
        await rolesPresenter.updateRoles(rolesStore, {
          ...values,
          roleId: idParams,
        });
        if (rolesStore.crudResponse.isSuccess) {
          message.success(rolesStore.crudResponse.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/Permission/Roles');
          }
        }
      }
      setLoading(false);
    };

    const initialValues = {
      actived: true,
      isSendSMSPermission: false,
    };

    useEffect(() => {

      (async () => {
        if (idParams) {
          setLoading(true);
          await Promise.allSettled([
            getDetailRoleById(idParams),
            getDropdownDepartment()
          ])
          setLoading(false);
        } else {
          setLoading(true);
          await getDropdownDepartment()
          setLoading(false);
        }
      })();
    }, []);
    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }
    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: "Nhóm người dùng",
                link: '/Permission/Roles',
              },
              {
                title: pageTitle,
                link: idParams ? `/Permission/Roles/${idParams}` : '/Permission/Roles/create',
              },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                  {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                </div>
              </>
            }
          />

          <BoxContainer fullHeight>
            <Spin spinning={loading}>
              <SearchForm
                onFinish={onFinish}
                initialValues={idParams ? {} : initialValues}
                form={form}
                layout='horizontal'
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 13 }}
                autoComplete='off'
              >
                {/* Mã nhóm người dùng */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường "Mã nhóm người dùng"' }]}
                  name={'rolesCode'}
                  label={"Mã nhóm người dùng"}
                >
                  <CustomInput disabled={idParams ? true : false} />
                </SearchItem>
                {/* Tên nhóm người dùng */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường "Tên nhóm người dùng"' }]}
                  name={'rolesName'}
                  label={"Tên nhóm người dùng"}
                >
                  <CustomInput />
                </SearchItem>
                {/* Phòng ban */}
                <SearchItem
                  rules={[{ required: true, message: `Vui lòng chọn "Phòng ban"` }]}
                  name={'departmentId'} label={'Phòng ban'} rootClassName='col-start-1'>
                  <Select options={dropdownDepartment.map((item) => ({
                    label: item.value,
                    value: item.key,
                  }))}></Select>
                </SearchItem>
                {/* trạng thái */}
                <SearchItem name={'actived'} label={t('permission.roles.status')}>
                  <Radio.Group>
                    <Radio value={true}>{t('radio-btn.active')}</Radio>
                    <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                  </Radio.Group>
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateRoles;
