import { observable, runInAction } from 'mobx';
import {
  CommonModel,
  Company,
  DataGetStoreByUsername,
  ICommonMasterDataServices,
  ParamsWorkType,
  RoleByDepartment,
  StoreByUsername,
} from 'services/RawData/commonMasterData_services';

export type CommonMasterDataStore = {
  commonRespone: {
    data: CommonModel[];
    message: string;
    isSuccess: boolean;
  };

  // Tỉnh thành
  provinceList: CommonModel[];

  // TemplateTickets
  templateTickets: CommonModel[];

  // TicketWorkType
  ticketWorkType: CommonModel[];

  // phương thức xử lý
  phuongThucXL: CommonModel[];

  salesOrgSelect: {
    data: CommonModel[];
    message: string;
    isSuccess: boolean;
  };
  companyRes: {
    data: Company[];
    message: string;
    isSuccess: boolean;
  };
  dropDownStoreByUser: {
    data: StoreByUsername[];
    message: string;
    isSuccess: boolean;
  };
  areaDropdown: CommonModel[];

  districtDropdown: {
    data: CommonModel[];
    message: string;
    isSuccess: boolean;
  };
  provinceDropdown: {
    data: CommonModel[];
    message: string;
    isSuccess: boolean;
  };
  parentCateDropdown: {
    data: CommonModel[];
    message: string;
    isSuccess: boolean;
  };
  cateDropdown: {
    data: CommonModel[];
    message: string;
    isSuccess: boolean;
  };
  colorDropdown: {
    data: CommonModel[];
    message: string;
    isSuccess: boolean;
  };
  // Loại thời gian bảo hành
  warrantyTypeDropdown: {
    data: CommonModel[];
    message: string;
    isSuccess: boolean;
  };
  // Danh sách công ty
  listCompany: CommonModel[];
  listRoles: CommonModel[];

  listRoleByDepartment: RoleByDepartment[],
  // loại hàng hóa
  productTypes: CommonModel[]
};

export interface ICommonMasterDataPresenter {
  createStore(): CommonMasterDataStore;
  // Lấy danh sách common date
  getListSelectCommonDate(store: CommonMasterDataStore): Promise<void>;

  // =================================================================================================
  // ======================================DỮ LIỆU NỀN================================================
  // =================================================================================================
  // Lấy data danh sách công ty
  getListSaleOrg(store: CommonMasterDataStore): Promise<void>;
  // Lấy data danh sách store
  getListSelectCommonStore(store: CommonMasterDataStore, accountId?: string): Promise<void>;
  // Loại danh mục
  getDropDownCategoryType(store: CommonMasterDataStore, keyword?: string): Promise<void>;
  setDropDownCategoryType(store: CommonMasterDataStore, data: CommonModel[]): Promise<void>;
  // Danh sách store bằng companyID
  getDropDownStore(store: CommonMasterDataStore, conpanyId?: string): Promise<void>;
  // Dropdown 2 (Có công ty + List store theo công ty)
  getDropDownStore2(store: CommonMasterDataStore, accountId?: string): Promise<void>;
  getDropDownStoreByUser(store: CommonMasterDataStore, params: DataGetStoreByUsername): Promise<void>;
  // Lấy dropdown phòng ban
  getDropdownDepartment(store: CommonMasterDataStore): Promise<void>;
  // Lấy dropdown tỉnh thành phố
  getDropDownProvince(store: CommonMasterDataStore, data?: { keyword?: string }): Promise<void>;
  // Lấy dropdown quận huyện
  getDropDownDistrict(store: CommonMasterDataStore, provinceId: string, keyword?: string): Promise<void>;
  // Lấy data phân loại vật tư
  getDropDownParentCategory(store: CommonMasterDataStore, kw?: string, takeNumber?: number): Promise<void>;
  // Lấy data nhóm vật tư
  getDropDownCategory(store: CommonMasterDataStore, kw?: string, takeNumber?: number): Promise<void>;
  // Lấy data dropdown màu
  getDropDownColor(store: CommonMasterDataStore, kw?: string, takeNumber?: number): Promise<void>;
  // Lấy data dropdown warranty (loại thời gian warranty)
  getDropDownWarranty(store: CommonMasterDataStore, kw?: string, takeNumber?: number): Promise<void>;
  // GET data common theo key:
  // Dropdown Khu vực : SaleOffice
  getCommonDataByKey(store: CommonMasterDataStore, key: string): Promise<void>;

  setAreaDropdown(store: CommonMasterDataStore, data: CommonModel[]): Promise<void>;
  // lấy danh Tỉnh/Thành phố
  getListProvince(store: CommonMasterDataStore, area?: string): Promise<void>;
  // Lấy mã công việc theo loại công việc
  getDropdownCatalogByWorkType(store: CommonMasterDataStore, params: ParamsWorkType): Promise<void>;
  // Get dropdown list template tickets
  getDropdownTemplateTickets(store: CommonMasterDataStore): Promise<void>;
  // danh sách công ty
  getListCompany(store: CommonMasterDataStore): Promise<void>;
  // Danh sách công ty (companyId)
  getDropdownCompanyV2(store: CommonMasterDataStore): Promise<void>;
  // danh sách nhóm người dùng (Role)
  getDropdownRole(store: CommonMasterDataStore, keyword?: string): Promise<void>;
  // dropdown nhóm người dùng theo từng phòng ban
  getDropdownRoleByDepartment(store: CommonMasterDataStore, option?: { keyword?: string, takeNumber?: number }): Promise<void>
  getDropdownProductType(store: CommonMasterDataStore): Promise<void>;
}

export function createCommonMasterDataPresenter({ commonMasterDataServices }: { commonMasterDataServices: ICommonMasterDataServices }): ICommonMasterDataPresenter {
  return {
    createStore: (): CommonMasterDataStore =>
      observable({
        commonRespone: {
          data: [],
          message: '',
          isSuccess: false,
        },

        // tinh thanh
        provinceList: [],
        // phương thức xử lý
        phuongThucXL: [],

        ticketWorkType: [],
        templateTickets: [],

        salesOrgSelect: {
          data: [],
          message: '',
          isSuccess: false,
        },
        salesOrgAllSelect: {
          data: [],
          message: '',
          isSuccess: false,
        },
        companyRes: {
          data: [],
          message: '',
          isSuccess: false,
        },
        dropDownStoreByUser: {
          data: [],
          message: '',
          isSuccess: false,
        },
        areaDropdown: [],
        provinceDropdown: {
          data: [],
          message: '',
          isSuccess: false,
        },
        districtDropdown: {
          data: [],
          message: '',
          isSuccess: false,
        },
        parentCateDropdown: {
          data: [],
          message: '',
          isSuccess: false,
        },
        cateDropdown: {
          data: [],
          message: '',
          isSuccess: false,
        },
        warrantyTypeDropdown: {
          data: [],
          message: '',
          isSuccess: false,
        },
        colorDropdown: {
          data: [],
          message: '',
          isSuccess: false,
        },
        // Danh sách công ty
        listCompany: [],
        listAllStock: [],
        listRoles: [],
        listRoleByDepartment: [],
        productTypes: []
      }),
    // Hàm lấy data danh sách công ty
    getListSaleOrg: async (store) => {
      try {
        const result = await commonMasterDataServices.getListSaleOrg();
        runInAction(() => {
          store.commonRespone.data = result.data;
          store.commonRespone.isSuccess = result.isSuccess;
          store.commonRespone.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Lấy danh sách common date
    getListSelectCommonDate: async (store) => {
      try {
        const result = await commonMasterDataServices.getListSelectCommonDate();
        runInAction(() => {
          store.commonRespone.data = result.data;
          store.commonRespone.isSuccess = result.isSuccess;
          store.commonRespone.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Lấy data danh sách store
    getListSelectCommonStore: async (store, accountId) => {
      try {
        const result = await commonMasterDataServices.getListSelectCommonStore(accountId);
        runInAction(() => {
          store.salesOrgSelect.data = result.data;
          store.salesOrgSelect.isSuccess = result.isSuccess;
          store.salesOrgSelect.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },

    // Lấy data danh sách category type loại danh mục
    getDropDownCategoryType: async (store, keyword) => {
      try {
        const result = await commonMasterDataServices.getDropDownCategoryType(keyword);
        runInAction(() => {
          if (result) {
            store.commonRespone.data = result.data;
            store.commonRespone.isSuccess = result.isSuccess;
            store.commonRespone.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    setDropDownCategoryType: async (store, data) => {
      try {
        runInAction(() => {
          store.commonRespone.data = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Lấy data danh sách category type loại danh mục
    getDropDownStore: async (store, companyId) => {
      try {
        const result = await commonMasterDataServices.getDropDownStore(companyId);
        runInAction(() => {
          if (result) {
            store.commonRespone.data = result.data;
            store.commonRespone.isSuccess = result.isSuccess;
            store.commonRespone.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Dropdown 2 (Có công ty + List store theo công ty)
    getDropDownStore2: async (store, accountId) => {
      try {
        const result = await commonMasterDataServices.getDropDownStore2(accountId);
        runInAction(() => {
          if (result) {
            store.companyRes.data = result.data;
            store.companyRes.isSuccess = result.isSuccess;
            store.companyRes.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    getDropDownStoreByUser: async (store, params) => {
      try {
        const result = await commonMasterDataServices.getDropDownStoreByUser(params);
        runInAction(() => {
          if (result) {
            store.dropDownStoreByUser.data = result.data;
            store.dropDownStoreByUser.isSuccess = result.isSuccess;
            store.dropDownStoreByUser.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // lấy data phòng ban
    getDropdownDepartment: async (store) => {
      try {
        const result = await commonMasterDataServices.getDropdownDepartment();
        runInAction(() => {
          if (result) {
            store.commonRespone.data = result.data;
            store.commonRespone.isSuccess = result.isSuccess;
            store.commonRespone.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // lấy data tỉnh thành phố
    getDropDownProvince: async (store, data) => {
      try {
        const result = await commonMasterDataServices.getDropDownProvince(data);
        runInAction(() => {
          if (result) {
            store.provinceDropdown.data = result.data;
            store.provinceDropdown.isSuccess = result.isSuccess;
            store.provinceDropdown.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // lấy data quận huyện
    getDropDownDistrict: async (store, provinceId, keyword) => {
      try {
        const result = await commonMasterDataServices.getDropDownDistrict(provinceId, keyword);
        runInAction(() => {
          if (result) {
            store.districtDropdown.data = result.data;
            store.districtDropdown.isSuccess = result.isSuccess;
            store.districtDropdown.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // lấy data phân loại vật tư
    getDropDownParentCategory: async (store, kw, takeNumber) => {
      try {
        const result = await commonMasterDataServices.getDropDownParentCategory(kw, takeNumber);
        runInAction(() => {
          if (result) {
            store.parentCateDropdown.data = result.data;
            store.parentCateDropdown.isSuccess = result.isSuccess;
            store.parentCateDropdown.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // lấy data nhóm vật tư
    getDropDownCategory: async (store, kw, takeNumber) => {
      try {
        const result = await commonMasterDataServices.getDropDownCategory(kw, takeNumber);
        runInAction(() => {
          if (result) {
            store.cateDropdown.data = result.data;
            store.cateDropdown.isSuccess = result.isSuccess;
            store.cateDropdown.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // lấy data dropdown màu
    getDropDownColor: async (store, kw, takeNumber) => {
      try {
        const result = await commonMasterDataServices.getDropDownColor(kw, takeNumber);
        runInAction(() => {
          if (result) {
            store.colorDropdown.data = result.data;
            store.colorDropdown.isSuccess = result.isSuccess;
            store.colorDropdown.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // lấy data dropdown warranty (loại thời gian warranty)
    getDropDownWarranty: async (store, kw, takeNumber) => {
      try {
        const result = await commonMasterDataServices.getDropDownWarranty(kw, takeNumber);
        runInAction(() => {
          if (result) {
            store.warrantyTypeDropdown.data = result.data;
            store.warrantyTypeDropdown.isSuccess = result.isSuccess;
            store.warrantyTypeDropdown.message = result.message;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // GET data common theo key:
    // Dropdown Khu vực : SaleOffice
    getCommonDataByKey: async (store, key) => {
      try {
        const result = await commonMasterDataServices.getCommonDataByKey(key);
        runInAction(() => {
          switch (key) {
            case 'PHUONGTHUCXL':
              store.phuongThucXL = result.data;
              break;

            case 'TicketWorkType':
              store.ticketWorkType = result.data;
              break;

            default:
              store.commonRespone.data = result.data;
              store.commonRespone.isSuccess = result.isSuccess;
              store.commonRespone.message = result.message;
              break;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    setAreaDropdown: async (store, data) => {
      store.areaDropdown = data;
    },
    // lấy danh Tỉnh/Thành phố
    getListProvince: async (store, area) => {
      try {
        const result = await commonMasterDataServices.getListProvince(area);
        runInAction(() => {
          store.provinceList = result.data;

          store.commonRespone.data = result.data;
          store.commonRespone.isSuccess = result.isSuccess;
          store.commonRespone.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Lấy mã công việc theo loại công việc
    getDropdownCatalogByWorkType: async (store, params) => {
      try {
        const result = await commonMasterDataServices.getDropdownCatalogByWorkType(params);
        runInAction(() => {
          store.commonRespone.data = result.data;
          store.commonRespone.isSuccess = result.isSuccess;
          store.commonRespone.message = result.message;
        });
      } catch (error) {
        console.error(error);
      }
    },
    // Get dropdown list template tickets
    getDropdownTemplateTickets: async (store) => {
      try {
        const result = await commonMasterDataServices.getDropdownTemplateTickets();
        runInAction(() => {
          store.templateTickets = result.data;

          store.commonRespone.data = result.data;
          store.commonRespone.isSuccess = result.isSuccess;
          store.commonRespone.message = result.message;
        });
      } catch (error) {
        console.error(error);
      }
    },
    // danh sách công ty
    getListCompany: async (store) => {
      try {
        const result = await commonMasterDataServices.getListCompany();
        runInAction(() => {
          store.listCompany = result.data;
        });
      } catch (error) {
        console.error(error);
      }
    },
    // Danh sách công ty (companyId)
    getDropdownCompanyV2: async (store) => {
      try {
        const result = await commonMasterDataServices.getDropdownCompanyV2();
        runInAction(() => {
          store.listCompany = result.data;
        });
      } catch (error) {
        console.error(error);
      }
    },
    // danh sách nhóm người dùng (Role)
    getDropdownRole: async (store, key) => {
      try {
        const result = await commonMasterDataServices.getDropdownRole(key);
        runInAction(() => {
          store.listRoles = result.data;
        });
      } catch (error) {
        console.error(error);
      }
    },
    // dropdown nhóm người dùng theo từng phòng ban
    getDropdownRoleByDepartment: async (store, option) => {
      try {
        const result = await commonMasterDataServices.getDropdownRoleByDepartment(option);
        runInAction(() => {
          store.listRoleByDepartment = result.data;
        });
      } catch (error) {
        console.error(error);
      }
    },
    getDropdownProductType: async (store) => {
      try {
        const result = await commonMasterDataServices.getCommonDataByKey('ProductType');
        runInAction(() => {
          if (result.data) {
            store.productTypes = result.data;
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
}
