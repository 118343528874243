import * as Icon from 'base/ui/components/Icons';

import { Form, Modal, Select, Tooltip, message } from 'antd';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useEffect, useState } from 'react';
import { catalogPresenter, catalogStore, userStore, workPresenter, workStore } from 'stores/root_store';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import CustomProTable from 'base/ui/components/CustomTable';
import { usePermission } from 'hooks/usePermission';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { HiSearch } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { WorkModel } from 'services/RawData/work_services';
import { dropdownStatus } from 'utils/dropdown';
import { formatNumber } from 'utils/formatHelper';
import { removeEmptyValues } from 'utils/transformHelper';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';

const { confirm } = Modal;

const taskPage = () => {
  return observer(() => {
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    const { t } = useTranslation();
    const pagetitle = t('Danh sách công việc');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);

    // Columns table title
    const defaultColumn: ProColumns<WorkModel>[] = [
      // stt
      {
        title: t('STT'),
        dataIndex: 'stt',
        key: 'stt',
        width: 50,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
      },
      // Mã loại công việc
      {
        title: t('Loại công việc'),
        dataIndex: 'worktypeCode',
        key: 'worktypeCode',
        fixed: 'left',
        width: 150,
        className: '!rounded-none !py-1',
      },
      // Tên loại công việc
      {
        title: t('Tên loại công việc'),
        dataIndex: 'worktypeName',
        key: 'worktypeCode',
        fixed: 'left',
        width: 150,
        className: '!rounded-none !py-1',
      },
      // Mã công việc
      {
        title: t('Mã công việc'),
        dataIndex: 'workCode',
        key: 'workCode',
        fixed: 'left',
        width: 150,
        className: '!rounded-none !py-1',
      },
      // Tên công việc
      {
        title: t('Tên công việc'),
        dataIndex: 'workName',
        key: 'workName',
        ellipsis: true,
        className: '!rounded-none !py-1',
      },
      // Giờ công
      {
        title: t('Giờ công'),
        dataIndex: 'workHour',
        key: 'workHour',
        width: 150,
        className: '!rounded-none !py-1',
        onCell: () => ({ style: { textAlign: 'right' } }),
      },
      // Giá
      {
        title: t('Giá'),
        dataIndex: 'price',
        key: 'price',
        width: 150,
        className: '!rounded-none !py-1',
        render: (_, record) => {
          return <>{formatNumber(record.price, 0)}</>;
        },
        onCell: () => ({ style: { textAlign: 'right' } }),
      },
      // trạng thái
      {
        title: t('Trạng thái'),
        dataIndex: 'actived',
        width: 80,
        key: 'actived',
        render: (_, record) => {
          return (
            <div>
              {record.actived ? (
                <Tooltip title={t('btn.edit')}>
                  <Icon.CheckStatus className="w-4 text-sm text-center mx-auto !fill-[#389E0D]" />
                </Tooltip>
              ) : (
                <Tooltip title={t('btn.delete')}>
                  <Icon.CloseStatus className="w-4 text-sm text-center mx-auto !fill-[#D93133E5]" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      // chức năng
      {
        title: t('Chức năng'),
        dataIndex: '',
        key: '',
        width: 100,
        className: '!rounded-none !py-1',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') ? (
                <CustomButtonIcon color="#007BF1" onClick={() => navigate(`${record.workId}`)}>
                  <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                </CustomButtonIcon>
              ) : null}
              {userStore.userPermission?.includes('DELETE') ? (
                record.actived ? (
                  <CustomButtonIcon
                    color="#FC5C5E"
                    onClick={() => {
                      showDeleteConfirm(record);
                    }}
                  >
                    <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                  </CustomButtonIcon>
                ) : null
              ) : null}
            </div>
          );
        },
      },
    ];
    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<WorkModel>[]>(defaultColumn);
    // Loading all page
    const [loading, setLoading] = useState(false);

    // Data search
    const [dataSearch, setDataSearch] = useState({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    });
    // List data table
    const [listWork, setListWork] = useState<WorkModel[]>([]);

    // function open hide drawer ====================================================================

    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Ui modal confirm delete store ================================================================
    const showDeleteConfirm = (item: WorkModel) => {
      confirm({
        title: `${t('btn.delete')} công việc`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} "${item.workName}"`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDelete(item.workId);
        },
        onCancel() { },
      });
    };
    // Call api delete
    const handleClickDelete = async (id?: string) => {
      setLoading(true);
      if (id) {
        await workPresenter.deleteWork(workStore, id);
        if (workStore.crudResponse.isSuccess) {
          message.success(workStore.crudResponse.message);
          // Call list branch after change
          await getDataSearch(dataSearch);
        } else {
          message.error(workStore.crudResponse.message);
        }
      }
      setLoading(false);
    };

    // Handle click button search ===========================================================
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
        ...values,
      };
      removeEmptyValues(tempDataSearch);
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);

      await getDataSearch(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };

    // Call api data search
    const getDataSearch = async (dataSearch: object) => {
      await workPresenter.searchListWork(workStore, dataSearch);
      if (workStore.listWork.isSuccess) {
        setListWork(workStore.listWork.data);
      }
    };

    useEffect(() => {
      (async () => {
        await catalogPresenter.getDropdownWorkType(catalogStore);
      })();
    }, []);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
      <div className="w-full">
        {/* ==================================== Breadcrumb ========================================= */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: 'Task' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  onClick={() => {
                    navigate('create');
                  }}
                  type="primary"
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<WorkModel>
            columns={columns}
            handleResize={() => { }}
            rowKey="stt"
            size="small"
            bordered
            search={false}
            loading={loading}
            dataSource={listWork}
            scroll={{ x: true }}
            options={false}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const values = form.getFieldsValue();
              const data: any = await getDataSearch({
                ...values,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: workStore.listWork.isSuccess,
              };
            }}
            pagination={{
              pageSize: dataSearch.paging.pageSize,
              total: workStore.listWork.paging?.recordsTotal,
              current: dataSearch.paging.pageIndex,
              responsive: true,
              position: ['topRight', 'bottomRight'],
              style: { margin: '10px 0' },
              showSizeChanger: false,
              showTotal: (total, range) => (
                <>
                  <span className="!absolute left-1">
                    {t('message.view')}{' '}
                    <CustomSelect
                      size="middle"
                      className="min-w-[72px] !rounded-none"
                      value={String(dataSearch.paging.pageSize)}
                      onChange={(value) => {
                        setDataSearch({
                          ...dataSearch,
                          paging: { ...dataSearch.paging, pageSize: Number(value) },
                        });
                      }}
                    >
                      {PAGE_SIZE_OPTION.map((pageSize) => (
                        <Select.Option key={pageSize} value={String(pageSize)}>
                          {pageSize}
                        </Select.Option>
                      ))}
                    </CustomSelect>{' '}
                    {t('message.item')}
                  </span>
                  <span className="hidden md:block">{`${t('message.display')} ${range[0]}-${range[1]} ${t(
                    'message.of',
                  )} ${total} ${t('message.entries')}`}</span>{' '}
                </>
              ),
              onChange: (page, pageSize) => {
                setDataSearch({
                  ...dataSearch,
                  paging: { ...dataSearch.paging, pageIndex: page, pageSize: pageSize },
                });
              },
            }}
          />
        </BoxContainer>

        {/* form tìm kiếm */}
        <SearchBox
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm column="col-1" form={form} onFinish={handleSearch}>
            {/* Mã công việc */}
            <SearchItem label={t('Mã công việc')} name={'workCode'}>
              <CustomInput />
            </SearchItem>
            {/* Mã loại công việc */}
            <SearchItem label={t('Mã loại công việc')} name={'worktypeCode'}>
              <CustomSelect
                fieldNames={{ value: 'catalogCode', label: 'catalogName' }}
                options={catalogStore.workType.data}
              />
            </SearchItem>
            {/* Tên công việc */}
            <SearchItem label={t('Tên công việc')} name={'workName'}>
              <CustomInput />
            </SearchItem>
            {/* Trạng thái */}
            <SearchItem label={t('Trạng thái')} name={'actived'}>
              <CustomSelect options={dropdownStatus} placeholder={t('place-holder.all')} allowClear />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default taskPage;
