import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const salesEmployeeRoutes = ({
  SalesEmployee,
  AddSalesEmployee,
  UpdateSalesEmployee,
}: {
  SalesEmployee: React.ComponentType;
  AddSalesEmployee: React.ComponentType;
  UpdateSalesEmployee: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<SalesEmployee />} />
    <Route path={ROUTES.MASTER_DATA.SALES_EMPLOYEE.CREATE} element={<AddSalesEmployee />} />
    <Route path={ROUTES.MASTER_DATA.SALES_EMPLOYEE.EDIT} element={<UpdateSalesEmployee />} />
  </Routes>
);
