import { CommonModel, HistoryLogIn } from 'services/Main/common_services';
import { User, WebPermission } from 'services/User/user_services';
import { clearUserSession, setUserSession } from '../../utils/localStorageHelper';
import { observable, runInAction } from 'mobx';

import { DataResponse } from 'services/response';
import { IUserServices } from 'services/User/user_services';
import { cloneDeep } from 'lodash';
import { message } from 'antd';

export type UserStore = {

  userRespone: {
    data: User | undefined;
    isSuccess: boolean;
    message: string;
  };
  userPermission?: string[];
  // danh sách saleorg
  listSaleOrg: CommonModel[];
  currentUser: string | null;
  historyLogInRes: DataResponse<Array<HistoryLogIn>>;
  logOutDeviceRes: boolean;
  summaryLogLogin: any;
};

export interface IUserPresenter {
  createStore(): UserStore;
  login(store: UserStore, data: any): Promise<boolean>;
  signOut(store: UserStore, deviceId: string, isAdminLogout: boolean): Promise<void>;
  setUserPermission(store: UserStore, data: string[] | undefined): Promise<void>;
  // danh sách saleorg by username
  getSaleorgByUsername(store: UserStore, { companyCode, username }: { username: string; companyCode: string }): Promise<void>;
  // Xác thực OTP
  confirmOTP(data: { userName: string; otp: string, saleOrg: string }): Promise<boolean>

  setCurrentUser(store: UserStore, data: string | null): Promise<void>;
  getLogLogIn(store: UserStore, data: object): Promise<void>;
  getLogOutDeviceId(store: UserStore, deviceId: string, username: string): Promise<void>;
  logOutAllDevice(store: UserStore, deviceIds: string[]): Promise<void>;
  getSummaryLogLogin(store: UserStore, username: string): Promise<void>;
}

export function createUserPresenter({ userServices }: { userServices: IUserServices }): IUserPresenter {
  return {
    createStore: (): UserStore =>
      observable({
        userRespone: {
          data: undefined,
          isSuccess: false,
          message: '',
        },
        // danh sách saleorg
        listSaleOrg: [],
        currentUser: null,
        historyLogInRes: {
          data: [],
          message: '',
          isSuccess: false,
        },
        logOutDeviceRes: false,
        summaryLogLogin: undefined,
      }),
    login: async (store: UserStore, data) => {
      try {
        const result = await userServices.login(data);

        runInAction(() => {
          const user = cloneDeep(result?.data)
          if (user) {
            Object.assign(user, { userName: data.userName, saleOrgCode: data.saleOrg })
          }
          store.userRespone.data = user;
          store.userRespone.message = result.message;
          store.userRespone.isSuccess = result.isSuccess;
          // kiểm tra nếu có data thì lưu thông tin vào localStore
          if (result.data.isAuthenticate || process.env.NODE_ENV === 'development') {
            const userSession = {
              userName: result.data.userName,
              fullName: result.data.fullName,
              employeeCode: result.data.employeeCode,
              accountId: result.data.accountId,
              // companyCode: result.data.companyCode,
              companyId: result.data.companyId,
              // companyName: result.data.companyName,
              expiredTime: result.data.expiredTime,
              saleOrgCode: result.data.saleOrgCode,
              saleOrgName: result.data.saleOrgName,
              // validaty: result.data.validaty,
              // roles: result.data.roles,
              role: result.data.role,
              roleName: result.data.roleName,
              token: result.data.token,
              plantCode: result.data.plantCode,
              plantName: result.data.plantName,
              refreshToken: result.data.refreshToken,
              webPermission: result.data.webPermission,

              isShowDashboard: result.data.isShowDashboard,
              isShowChooseModule: result.data.isShowChooseModule,
              isResetPassword: result.data.isResetPassword,
              isSupplier: result.data.isSupplier,
              vendorCode: result.data.vendorCode,
              vendorName: result.data.vendorName
            };
            setUserSession(userSession);
          }
        });

        if (result) {
          return true;
        } else {
          return false;
        }
      } catch {
        // error
        return false;
      }
    },
    confirmOTP: async (data) => {
      try {
        const result = await userServices.confirmOTP(data);
        if (result.isSuccess) {
          const { userName, fullName, employeeCode, saleOrgCode, saleOrgName, role, roles, roleName, refreshToken, token, plantCode, plantName, webPermission } = result.data
          const userSession = { userName, fullName, employeeCode, saleOrgCode, saleOrgName, role, roles, roleName, refreshToken, token, plantCode, plantName, webPermission }
          setUserSession(userSession);
          message.success(result.message)
          return true
        } else {
          message.success(result.message)
          return false;
        }
      } catch {
        // error
        return false;
      }
    },
    // Set list permission
    setUserPermission: async (store, data) => {
      try {
        runInAction(() => {
          store.userPermission = data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    // danh sách saleorg by username
    getSaleorgByUsername: async (store, data) => {
      try {
        const result = await userServices.getSaleorgByUsername(data)
        runInAction(() => {
          if (result && result.data) {
            store.listSaleOrg = result.data
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    setCurrentUser: async (store: UserStore, data) => {
      try {
        runInAction(() => {
          store.currentUser = data;
        });
      } catch { }
    },

    signOut: async (store: UserStore, deviceId: string, isAdminLogout: boolean) => {
      try {
        await userServices.getLogOut(deviceId, isAdminLogout);
        clearUserSession();
        // window.location.href = `${BASE_URL} /Permission/Auth/Logout`;
      } catch (err) {
        console.log(err);
      }
    },
    getLogLogIn: async (store, data) => {
      try {
        const result = await userServices.getLogLogIn(data);
        runInAction(() => {
          store.historyLogInRes.data = result.data;
          store.historyLogInRes.isSuccess = result.isSuccess;
          store.historyLogInRes.message = result.message;

        });
      } catch (error) {
        console.log(error);
      }
    },
    getLogOutDeviceId: async (store, deviceId, username) => {
      try {
        const result = await userServices.getLogOutDeviceId(deviceId, username);
        runInAction(() => {
          store.logOutDeviceRes = result.data;


        });
      } catch (error) {
        console.log(error);
      }
    },
    logOutAllDevice: async (store, deviceIds) => {
      try {
        const result = await userServices.logOutAllDevice(deviceIds);
        runInAction(() => {
          store.logOutDeviceRes = result.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getSummaryLogLogin: async (store, username) => {
      try {
        const result = await userServices.getSummaryLogLogin(username);
        runInAction(() => {
          store.summaryLogLogin = result.data;
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
}
