import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { get, post, put } from 'utils/api';

export interface AccessMobileModel {
    menuId: string;
    menuName: string;
    moduleId: string;
    icon: string;
    orderIndex: number;
    mobileScreenAccessResponses: MobileScreenAccessResponse[]
    rowIndex: number;
    error: any
    isNullValueId?: boolean;
    isChooseAll?: boolean
}

export interface MobileScreenAccessResponse {
    mobileScreenId: string
    screenName: string
    screenCode: string
    menuId: string
    iconType: string
    iconName: string
    orderIndex: number
    functionAccessResponses: FunctionAccessResponse[]
}

export interface FunctionAccessResponse {
    pageId: string
    mobileScreenId: string
    functionId: string
    functionName: string
    orderIndex: number
    selected: boolean
}


export interface IAccessMobileServices {
    getAccessMobileByRoleId(roleId: string): Promise<DataResponse<Array<AccessMobileModel>>>
    // update permissions (function check)
    updateFunctionPermissions(data: { roleId: string; functionId: string; mobileScreenId: string; check: boolean }): Promise<DataResponse<null>>

}
export function createAccessMobileServices(): IAccessMobileServices {
    return {
        // Search danh mục
        getAccessMobileByRoleId: async (roleId) => {
            return (await get(MASTER_DATA_API_URL, `accessmobile/${roleId}`)).data;
        },
        // update permissions (function check)
        updateFunctionPermissions: async (data) => {
            return (await put(MASTER_DATA_API_URL, `accessmobile/update-mobile-permission`, data)).data;
        },

    };
}
