
import * as Icon from 'base/ui/components/Icons';

import { Checkbox, Divider, Form, Input, Modal, Select, Space, Tooltip, message } from 'antd';
import { DataSearchAccount, DataSearchPage, statusList } from '../model';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { accountPresenter, accountStore, mobileScreenPresenter, mobileScreenStore, pagePresenter, pageStore, userStore } from 'stores/root_store';
import { useEffect, useRef, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import CustomSelect from 'base/ui/components/CustomSelect';
import { DataAccount } from 'services/RawData/account_services';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FaKey } from 'react-icons/fa';
import { HiSearch } from 'react-icons/hi';
import { ActionType, ProColumns } from '@ant-design/pro-components';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { PageModel } from 'services/RawData/page_services';
import { cloneDeep } from 'lodash';
import { MobileScreenModel } from 'services/RawData/mobileScreen_services';

const { confirm } = Modal;

const mobileScreenPage = () => {
    return observer(() => {
        usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

        const { t } = useTranslation();

        const pagetitle = "Màn hình";
        document.title = pagetitle + ' - ' + SYSTEM_TITLE;
        const navigate = useNavigate();
        const [form] = Form.useForm();
        // state ===============================================================================
        const [openDrawer, setOpenDrawer] = useState(false);

        // Columns table title
        const defaultColumn: ProColumns<MobileScreenModel>[] = [
            // stt
            {
                title: t('permission.account.stt'),
                dataIndex: 'stt',
                key: 'stt',
                width: 60,
                align: 'center',
                render: (text) => <div className="!text-center">{text}</div>,
                fixed: 'left',
            },
            // Tên màn hình
            {
                title: "Tên màn hình",
                dataIndex: 'screenName',
                key: 'screenName',
                width: 350,
            },
            // Đường dẫn
            {
                title: 'Đường dẫn',
                dataIndex: 'screenCode',
                key: 'screenCode',
                width: 250,
            },

            {
                title: "Thứ tự",
                dataIndex: 'orderIndex',
                key: 'orderIndex',
                width: 70,
                onCell: () => ({
                    className: 'text-right'
                })
            },
            {
                title: "Loại icon",
                dataIndex: 'iconType',
                key: 'iconType',
                width: 150,
            },
            {
                title: "Tên icon",
                dataIndex: 'iconName',
                key: 'iconName',
                width: 150,
            },
            // trạng thái
            {
                title: t('permission.account.status'),
                dataIndex: 'actived',
                width: 100,
                key: 'actived',
                render: (text) => (
                    <div className="w-full flex justify-center">
                        {text ? (
                            <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
                        ) : (
                            <Icon.CloseStatus width="15" className="!fill-red-500" />
                        )}
                    </div>
                ),
            },
            // Chức năng
            {
                title: t('permission.account.actions'),
                dataIndex: '',
                key: '',
                width: 150,
                className: '!rounded-none !py-1',
                fixed: 'right',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div className="w-full flex items-center justify-center gap-x-1">
                            {userStore.userPermission?.includes('EDIT') ? (
                                <Tooltip title={t('btn.edit')}>
                                    <Link to={`${record.mobileScreenId}`}>
                                        <CustomButtonIcon color="#007BF1">
                                            <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                                        </CustomButtonIcon>
                                    </Link>
                                </Tooltip>
                            ) : null}
                            {userStore.userPermission?.includes('DELETE') ? (
                                <Tooltip title={t('btn.delete')}>
                                    <CustomButtonIcon
                                        color="#FC5C5E"
                                        onClick={() => {
                                            showDeleteConfirm(record);
                                        }}
                                    >
                                        <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                                    </CustomButtonIcon>
                                </Tooltip>
                            ) : null}

                        </div>
                    );
                },
            },
        ];
        // Custom default columns table with resizeable
        // Loading all page
        const [loading, setLoading] = useState(false);
        const tableRef = useRef<ActionType>();
        // Data search
        const [dataSearch, setDataSearch] = useState<DataSearchPage>({
            paging: {
                pageIndex: 1,
                pageSize: PAGE_SIZE_DEFAULT,
                orderBy: '',
                orderByDesc: '',
            },
        });
        const [listData, setListData] = useState<MobileScreenModel[]>([]);

        // function open hide drawer ===========================================================
        const showDrawer = () => {
            setOpenDrawer(true);
        };

        const onCloseDrawer = () => {
            setOpenDrawer(false);
        };

        // Call api search Data
        const getDataSearch = async (dataSearch: DataSearchPage) => {
            await mobileScreenPresenter.searchMobileScreen(mobileScreenStore, dataSearch);
            if (mobileScreenStore.searchScreenRes.isSuccess) {
                const dataClone = cloneDeep(mobileScreenStore.searchScreenRes.data);
                setListData(dataClone);
                return dataClone;
            }
            return []
        };



        // Handle click button search
        const handleSearch = async (values: any) => {
            setLoading(true);
            const tempDataSearch = {
                paging: {
                    ...dataSearch.paging,
                    pageIndex: 1,
                },
                ...values,
            };
            // set pageIndex 1 when search form
            setDataSearch(tempDataSearch);

            await getDataSearch(tempDataSearch);
            setLoading(false);
            onCloseDrawer();
        };

        // scroll to top
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        // initialValues of form search
        const initialValues = {};

        // Call api delete
        const handleClickDeleteScreen = async (id: string) => {
            setLoading(true);
            if (id) {
                const result = await mobileScreenPresenter.deleteMobileScreen(id);
                if (result) {
                    tableRef.current?.reload()
                }
            }
            setLoading(false);
        };

        // Ui modal confirm delete store
        const showDeleteConfirm = (item: MobileScreenModel) => {
            confirm({
                title: `${t('btn.delete')} "${item.screenName}"`,
                icon: <ExclamationCircleFilled />,
                content: `${t('message.question-delete')} "${item.screenName}"`,
                okText: t('btn.delete'),
                okType: 'danger',
                cancelText: t('btn.cancel'),
                className: 'custom__modalconfirm--delete',
                onOk() {
                    handleClickDeleteScreen(item.mobileScreenId || '');
                },
                onCancel() { },
            });
        };

        return (
            <div className="w-full">
                {/* ================ Breadcrumb =============== */}
                <CustomBreadcrumb
                    pagetitle={[{ title: pagetitle, link: '/Permission/Account' }]}
                    extra={
                        <>
                            <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                                <Icon.Filter className="fill-primary" />
                            </CustomButton>

                            {userStore.userPermission?.includes('CREATE') ? (
                                <Link to={'create'}>
                                    <CustomButton
                                        type="primary"
                                        icon={<Icon.Plus className="!fill-white" />}
                                    >
                                        {t('btn.add')}
                                    </CustomButton></Link>
                            ) : null}
                        </>
                    }
                />
                {/* Table */}
                <BoxContainer>
                    <CustomProTable<MobileScreenModel>
                        columns={defaultColumn}
                        rowKey="stt"
                        loading={loading}
                        actionRef={tableRef}
                        request={async (params = {}, sort, filter) => {
                            const data = await getDataSearch({
                                ...dataSearch,
                                paging: {
                                    ...dataSearch.paging,
                                    orderBy:
                                        Object.values(sort)[0] === "ascend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                    orderByDesc:
                                        Object.values(sort)[0] === "descend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                },
                            });
                            if (Object.values(sort).length) {

                                setDataSearch({
                                    ...dataSearch,
                                    paging: {
                                        ...dataSearch.paging,
                                        orderBy:
                                            Object.values(sort)[0] === "ascend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                        orderByDesc:
                                            Object.values(sort)[0] === "descend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                    },
                                });
                            }
                            return {
                                data: data,
                                success: mobileScreenStore.searchScreenRes?.isSuccess
                            };

                        }}
                        dataSource={listData}
                        scroll={{ y: 500 }}

                        pagination={{
                            ...tablePagination(dataSearch, setDataSearch),
                            total: mobileScreenStore.searchScreenRes?.paging?.recordsTotal,
                        }}
                        options={false}
                    />
                </BoxContainer>
                {/* Form search */}
                <SearchBox
                    className="lg:!w-[35%]"
                    footer={[
                        <div key="footer" className="flex justify-end">
                            <CustomButton
                                type="primary"
                                icon={<HiSearch />}
                                htmlType="submit"
                                onClick={() => {
                                    form.submit();
                                    onCloseDrawer();
                                }}
                            >
                                {t('btn.search')}
                            </CustomButton>
                        </div>,
                    ]}
                    onClose={onCloseDrawer}
                    open={openDrawer}
                >
                    <SearchForm column="col-1" initialValues={initialValues} form={form} onFinish={handleSearch}>
                        {/* Tên chức năng */}
                        <SearchItem label={"Tên chức năng"} name={'screenName'}>
                            <Input />
                        </SearchItem>
                        {/* Danh mục */}
                        {/* <SearchItem label={"Danh mục"} name={'fullname'}>
                            <CustomInput className="!rounded-none" />
                        </SearchItem> */}
                        {/* trạng thái */}
                        <SearchItem label={t('permission.account.status')} name={'actived'}>
                            <CustomSelect options={statusList} className="!rounded-none" />
                        </SearchItem>

                    </SearchForm>
                </SearchBox>
            </div>
        );
    });
};

export default mobileScreenPage;
