import catalogPage from '.';
import createAddUpdateCatalog from './CreateUpdate';
import { catalogRoutes } from './routes';

export const createCatalogPage = () => {
  const Catalog = catalogPage();
  const AddCatalog = createAddUpdateCatalog();
  const UpdateCatalog = createAddUpdateCatalog();

  const CatalogPage = catalogRoutes;
  return () => <CatalogPage Catalog={Catalog} AddCatalog={AddCatalog} UpdateCatalog={UpdateCatalog} />;
};
