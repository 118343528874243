import taskPage from '.';
import createAddUpdateTask from './CreateUpdate';
import { taskRoutes } from './routes';

export const createTaskPage = () => {
  const Task = taskPage();
  const Add = createAddUpdateTask();
  const Update = createAddUpdateTask();

  const TaskPage = taskRoutes;
  return () => <TaskPage Task={Task} Add={Add} Update={Update} />;
};
