import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const rolesRoutes = ({
  Roles,
  AddRoles,
  UpdateRoles,
}: {
  Roles: React.ComponentType;
  AddRoles: React.ComponentType;
  UpdateRoles: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Roles />} />
    <Route path={ROUTES.PERMISSION.ROLES.CREATE} element={<AddRoles />} />
    <Route path={ROUTES.PERMISSION.ROLES.EDIT} element={<UpdateRoles />} />
  </Routes>
);
