import salesEmployeePage from '.';
import createAddSalesEmployee from './CreateUpdate';
import { salesEmployeeRoutes } from './routes';

export const createSalesEmployeePage = () => {
  const SalesEmployee = salesEmployeePage();
  const AddSalesEmployee = createAddSalesEmployee();
  const UpdateSalesEmployee = createAddSalesEmployee();

  const SalesEmployeePage = salesEmployeeRoutes;
  return () => (
    <SalesEmployeePage
      SalesEmployee={SalesEmployee}
      AddSalesEmployee={AddSalesEmployee}
      UpdateSalesEmployee={UpdateSalesEmployee}
    />
  );
};
