/* eslint-disable react-hooks/exhaustive-deps */

import * as Icon from 'base/ui/components/Icons';

import { Form, Input, Radio, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import {
  vendorPresenter,
  vendorStore,
} from 'stores/root_store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { validatePhone } from 'utils/validateHelper';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import { SYSTEM_TITLE } from 'utils/constants';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { useTranslation } from 'react-i18next';
import { ProColumns } from '@ant-design/pro-components';
import CustomProTable from 'base/ui/components/CustomTable';
import { Driver } from 'services/RawData/driver_services';
import { usePermission } from 'hooks/usePermission';

const createAddVendor = () => {
  return observer(() => {
    const { t } = useTranslation();

    // Get data params from url
    const { id: idParams } = useParams();

    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      // usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      // usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }
    const navigate = useNavigate();
    // Check button save and save continue

    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);
    // Danh sách Tài xế
    const [listDriver, setListDriver] = useState<Driver[]>([]);
    //======================================================FUNCTION===============================================//
    // Call api detail store
    const getDetailVendor = async (employeeCode: string) => {
      setLoading(true);
      // call data nhân viên by code
      await vendorPresenter.getVendorDetail(vendorStore, employeeCode);
      if (vendorStore.vendorDetailResponse.isSuccess && vendorStore.vendorDetailResponse.data) {
        if (idParams) {
          setListDriver(vendorStore.vendorDetailResponse.data.drivers)
          form.setFieldsValue(vendorStore.vendorDetailResponse.data);
        }
      } else {
        navigate('/MasterData/Vendor');
      }
      setLoading(false);
    };

    const defaultColumn: ProColumns<Driver>[] = [
      // Stt
      {
        title: t('masterdata.vendor.stt'),
        key: 'stt',
        width: 30,
        valueType: 'index',
        align: 'center',
        fixed: 'left',
      },
      // Mã tài xế
      {
        title: "Mã tài xế",
        dataIndex: 'driverCode',
        key: 'driverCode',
        width: 50,
        fixed: 'left',
        render: (_, record) => (
          <Link to={`/MasterData/Driver/${record.driverId}`}>{record.driverCode}</Link>
        )
      },
      // tên tài xế
      {
        title: 'Tên tài xế',
        dataIndex: 'driverName',
        key: 'driverName',
        width: 200,
      },
      // Điện thoại
      {
        title: t('masterdata.vendor.phone'),
        dataIndex: 'phone',
        key: 'phone',
        width: 40,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Email
      {
        title: "Số bằng lái",
        dataIndex: 'licenseNumber',
        key: 'licenseNumber',
        width: 80,
      },

    ];

    useEffect(() => {
      (async () => {
        if (idParams) {
          getDetailVendor(idParams);
        }
      })();
    }, []);

    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: "Nhà cung cấp",
                link: '/MasterData/Vendor',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/Vendor/${idParams}` : '/MasterData/Vendor/create',
              },
            ]}
          />
          {/* Form nhập tìm kiếm */}
          <BoxContainer fullHeight>
            <Spin spinning={loading}>
              <SearchForm
                form={form}>
                <SearchItem
                  name={'supplierNumber'}
                  label={t('masterdata.vendor.vendor-code')}
                >
                  <Input readOnly />
                </SearchItem>
                {/* họ và tên */}
                <SearchItem
                  name={'longName'}
                  label={t('masterdata.vendor.full-name')}
                >
                  <Input readOnly />
                </SearchItem>
                {/* Địa chỉ */}
                <SearchItem
                  name={'address'}
                  label={'Địa chỉ'}
                >
                  <Input readOnly />
                </SearchItem>
                {/* phone điện thoại */}
                <SearchItem
                  name={'telephone'}
                  label={t('masterdata.vendor.phone')}
                >
                  <Input readOnly />
                </SearchItem>
                {/* Email */}
                <SearchItem
                  name={'email'}
                  label={'Email'}
                >
                  <Input readOnly />
                </SearchItem>
                {/* Trạng thái */}
                <SearchItem name={'actived'} label={t('masterdata.sales-employee.status')}>
                  <Radio.Group >
                    <Radio value={true}>{t('radio-btn.active')}</Radio>
                    <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                  </Radio.Group>
                </SearchItem>

              </SearchForm>

            </Spin>
            <CustomProTable<Driver>
              headerTitle={<h3 className='text-default font-bold'>Danh sách tài xế đã học an toàn</h3>}
              loading={loading}
              columns={defaultColumn}
              rowKey="index"
              scroll={{ x: 'auto' }}
              dataSource={listDriver}
              pagination={false}
              options={false}
            />
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddVendor;
