import { message } from 'antd';
import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { CommonModel } from 'services/RawData/commonMasterData_services';
import { FunctionModel, IPageServices, PageModel } from 'services/RawData/page_services';

export type PageStore = {
    searchPageRes: DataResponse<Array<PageModel>>
    pageDetail: DataResponse<PageModel | undefined>
    listFunctions: FunctionModel[]
    dropdownMenu: CommonModel[]
};

export interface IPagePresenter {
    createStore(): PageStore;
    // Search page
    getListPage(store: PageStore, data: object): Promise<void>;
    getPageById(store: PageStore, id: string): Promise<void>
    // Danh sách thao tác
    getAllFunction(store: PageStore): Promise<void>
    getDropdownMenu(store: PageStore, keyword?: string): Promise<void>
    createPage(data: object): Promise<boolean>
    updatePage(data: object): Promise<boolean>
    // xóa page
    deletePage(pageId: string): Promise<boolean>
}

export function createPagePresenter({
    pageServices,
}: {
    pageServices: IPageServices;
}): IPagePresenter {
    return {
        createStore: () =>
            observable({
                searchPageRes: {
                    data: [],
                    paging: {
                        totalCount: 0,
                        totalPage: 0,
                        pageSize: 0,
                    },
                    message: '',
                    isSuccess: false,
                },
                pageDetail: {
                    data: undefined,
                    message: '',
                    isSuccess: false,
                },
                listFunctions: [],
                dropdownMenu: []
            }),
        // Search district
        getListPage: async (store, data) => {
            try {
                const result = await pageServices.getListPage(data);
                runInAction(() => {
                    store.searchPageRes.data = result.data;
                    store.searchPageRes.isSuccess = result.isSuccess;
                    store.searchPageRes.message = result.message;
                    store.searchPageRes.paging = result.paging;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getPageById: async (store, id) => {
            try {
                const result = await pageServices.getPageById(id);
                runInAction(() => {
                    store.pageDetail = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // Danh sách thao tác
        getAllFunction: async (store) => {
            try {
                const result = await pageServices.getAllFunction();
                runInAction(() => {
                    store.listFunctions = result.data;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getDropdownMenu: async (store, keyword) => {
            try {
                const result = await pageServices.getDropdownMenu(keyword);
                runInAction(() => {
                    if (result.isSuccess) {
                        store.dropdownMenu = result.data;
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        createPage: async (data) => {
            try {
                const result = await pageServices.createPage(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        updatePage: async (data) => {
            try {
                const result = await pageServices.updatePage(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        // xóa page
        deletePage: async (pageId) => {
            try {
                const result = await pageServices.deletePage(pageId);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
    };
}
