import { clearUserSession, getAccountId, getDeviceId, getUserName } from "utils/localStorageHelper";
import { notificationPresenter, notificationStore, userPresenter, userStore } from "stores/root_store";

import { BASE_API_URL } from "utils/constants";
import { HubConnectionBuilder } from "@microsoft/signalr"

const signalrConnection = () => {
    const URL = process.env.REACT_APP_BASE_API + '/Notification'
    const connection = new HubConnectionBuilder()
        .withUrl(URL)
        .withAutomaticReconnect()
        .build()

    connection
        .start()
        .then(() => console.log("SignalR Connected"))
        .catch((error) => {
            console.log("Failed: " + error);
        });

    // connection.on("ReceiveNotification", (message) => {
    //     // Xử lý thông báo nhận được từ backend
    //     console.log("Received Notification:", message);
    //     // Cập nhật giao diện để hiển thị thông báo cho người dùng
    // });

    connection.on('LogOutDevice', async (dataSignalR: string) => {
        const [deviceIdSignal, userNameSignal] = dataSignalR.split('_');
        const userName = getUserName();
        const deviceId = getDeviceId();
        console.log({ deviceId, deviceIdSignal, userNameSignal, userName });


        if (deviceId === deviceIdSignal.toLocaleLowerCase() && userName === userNameSignal.toLocaleLowerCase()) {
            //await userPresenter.getLogOutDeviceId(userStore, deviceId, userName);
            // clearUserSession();
            userPresenter.signOut(userStore, getDeviceId(), true);
        }
    });

    connection.on('ReceiveNotification', async (data) => {
        if (data === "THONGBAO") {
            await notificationPresenter.getListNotiByUser(notificationStore, {
                paging: {
                    pageIndex: 1,
                    pageSize: 10
                },
                userId: getAccountId() || ''
            })
        }
    })

    return connection;
}

export default signalrConnection