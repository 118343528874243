import { observable, runInAction } from 'mobx';
import { Category, ICategoryServices } from 'services/RawData/Sales_services';

import { DataResponse } from 'services/response';

export type CategoryStore = {
  category: DataResponse<Array<Category>>;
  categoryDropdown: DataResponse<Array<Category>>;

  // sản phẩm
  productList: DataResponse<Array<Category>>;
  brandList: DataResponse<Array<Category>>;
  groupList: DataResponse<Array<Category>>;
  subGroupList: DataResponse<Array<Category>>;
  subBrandList: DataResponse<Array<Category>>;
};

export interface ICategoryPresenter {
  createStore(): CategoryStore;
  searchListCategory(store: CategoryStore, data: object): Promise<void>;
  dropdownCategory(store: CategoryStore, productTypeName: string): Promise<void>;
}

export function createCategoryPresenter({
  categoryServices,
}: {
  categoryServices: ICategoryServices;
}): ICategoryPresenter {
  return {
    createStore: (): CategoryStore =>
      observable({
        category: {
          data: [],
          message: '',
          isSuccess: false,
        },
        categoryDropdown: {
          data: [],
          message: '',
          isSuccess: false,
        },
        productList: {
          data: [],
          message: '',
          isSuccess: false,
        },
        brandList: {
          data: [],
          message: '',
          isSuccess: false,
        },
        groupList: {
          data: [],
          message: '',
          isSuccess: false,
        },
        subGroupList: {
          data: [],
          message: '',
          isSuccess: false,
        },
        subBrandList: {
          data: [],
          message: '',
          isSuccess: false,
        },
      }),
    searchListCategory: async (store, data) => {
      try {
        const result = await categoryServices.searchListCategory(data);
        runInAction(() => {
          store.category.data = result.data;
          store.category.isSuccess = result.isSuccess;
          store.category.message = result.message;
          store.category.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    dropdownCategory: async (store, productTypeName) => {
      try {
        const result = await categoryServices.dropdownCategory(productTypeName);
        runInAction(() => {
          switch (productTypeName) {
            case 'Brand':
              store.brandList.data = result.data;
              store.brandList.isSuccess = result.isSuccess;
              store.brandList.message = result.message;
              break;
            case 'Sub-Brand/LoB':
              store.subBrandList.data = result.data;
              store.subBrandList.isSuccess = result.isSuccess;
              store.subBrandList.message = result.message;
              break;
            case 'Group':
              store.groupList.data = result.data;
              store.groupList.isSuccess = result.isSuccess;
              store.groupList.message = result.message;
              break;
            case 'Sub-Group/Sub-Cate':
              store.subGroupList.data = result.data;
              store.subGroupList.isSuccess = result.isSuccess;
              store.subGroupList.message = result.message;
              break;

            default:
              break;
          }

          store.categoryDropdown.data = result.data;
          store.categoryDropdown.isSuccess = result.isSuccess;
          store.categoryDropdown.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
