import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const branchRoutes = ({
  Branch,
  AddBranch,
  UpdateBranch,
}: {
  Branch: React.ComponentType;
  AddBranch: React.ComponentType;
  UpdateBranch: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Branch />} />
    <Route path={ROUTES.MASTER_DATA.BRANCH.CREATE} element={<AddBranch />} />
    <Route path={ROUTES.MASTER_DATA.BRANCH.EDIT} element={<UpdateBranch />} />
  </Routes>
);
