import { LayoutHOC } from "base/ui/layout/layout";
import { vehicleRegistrationRoutes } from "./routes";
import { createPrintPDF } from "./printPDF";

export const createPrintPDFPage = (layoutHOC: LayoutHOC) => {
    const PrintPDF = createPrintPDF()


    const VehicleRegistrationPage = layoutHOC(vehicleRegistrationRoutes);

    return () => (
        <VehicleRegistrationPage
            PrintPDF={PrintPDF}
        />
    );
};