import { toNumber } from "lodash";

export const formatNumber = (num: number | undefined, fixed = 0, shorten?: boolean) => {
  if (!num) {
    return '0';
  }
  if (!shorten) {
    return num.toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } else {
    if (num < 1e3) return num + ' ';
    if (num >= 1e3 && num < 1e6) return +(num / 1e3).toFixed(1) + 'K';
    if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(1) + 'M';
    if (num >= 1e9 && num < 1e12) return +(num / 1e9).toFixed(1) + 'B';
    if (num >= 1e12) return +(num / 1e12).toFixed(1) + 'T';
  }
};
// Simple format number ex:999999999 to 999,999,999
export const sFormatNumber = (number: number | string | undefined) => {
  if (typeof number === 'number') return new Intl.NumberFormat('en-VN').format(number);
  else return number;
};


const removeSpecialCharacter = (str: string) => {
  return str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, '');
};
export const generateNameId = ({ name, id }: { name: string; id: string }) => {
  return removeSpecialCharacter(name).replace(/\s/g, '-') + `-i.${id}`;
};

export const getIdFromNameId = (nameId: string) => {
  const arr = nameId.split('-i.');
  return arr[arr.length - 1];
};

// So sánh 2 chuỗi
export function compareStrings(str1: string, str2: string) {
  for (let i = 0; i < Math.min(str1.length, str2.length); i++) {
    if (toNumber(str1.charCodeAt(i)) !== toNumber(str2.charCodeAt(i))) {
      return toNumber(str1.charCodeAt(i)) - toNumber(str2.charCodeAt(i));
    }
  }
  // Nếu các ký tự đầu tiên trong hai chuỗi giống nhau, so sánh độ dài của chuỗi
  return str1.length - str2.length;
}


export function formatDecimalNumber(numberString: string): string {
  // Xóa dấu phẩy nếu có
  const numberWithoutCommas = numberString.replace(/,/g, '');

  // Chuyển đổi chuỗi thành số với phần thập phân
  const number = parseFloat(numberWithoutCommas);

  // Kiểm tra xem số có phần thập phân hay không
  if (!isNaN(number) && numberString.includes('.')) {
    // Sử dụng hàm toFixed để giới hạn số lượng chữ số thập phân sau dấu chấm là 3
    return number.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // Kiểm tra xem số có phần thập phân sau không
  const hasDecimal = /\.\d+$/.test(numberString);

  // Nếu không có phần thập phân, thêm ".000"
  if (!hasDecimal) {
    return `${numberString || 0}.000`;
  }


  // Trả về số nguyên không làm tròn
  return numberString;
}