/* eslint-disable react-hooks/exhaustive-deps */

import * as Icon from 'base/ui/components/Icons';

import { Checkbox, Form, InputNumber, Radio, Spin, message } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import {
  branchPresenter,
  branchStore,
  commonMasterDataPresenter,
  commonMasterDataStore,
  districtPresenter,
  districtStore,
  provincePresenter,
  provinceStore,
  stockPresenter,
  stockStore,
} from 'stores/root_store';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEmail, validatePhone } from 'utils/validateHelper';

import BoxContainer from 'base/ui/components/BoxContainer';
import { CommonModel } from 'services/RawData/commonMasterData_services';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import CustomTabs from 'base/ui/components/CustomTabs';
import { DataBranch } from 'services/RawData/branch_services';
import { DataStock } from 'services/RawData/stock_services';
import { District } from 'services/RawData/district_services';
import { Province } from 'services/RawData/province_services';
import { SYSTEM_TITLE } from 'utils/constants';
import { debounce } from 'utils/debounce';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

const createAddUpdateBranch = () => {
  return observer(() => {
    const { t } = useTranslation();

    // Get data params from url
    const { id: idParams } = useParams();

    let pageTitle = '';
    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }

    const navigate = useNavigate();

    //==========================STATE===================//
    // Loading
    const [loading, setLoading] = useState(false);
    // List organization
    const [listOrg, setListOrg] = useState<CommonModel[]>([]);
    // List province
    const [listProvince, setListProvince] = useState<CommonModel[]>([]);
    // List district
    const [listDistrict, setListDistrict] = useState<CommonModel[]>([]);
    // List stock
    const [listStock, setListStock] = useState<DataStock[]>([]);

    // Key đang active của tab
    const [activeKey, setActiveKey] = useState('0'); // Mặc định mở tab danh sách
    // Check button save and save continue
    let isSaveAndContinue = false;
    const [form] = Form.useForm();

    // ==================================================================================================
    // ==================================================================================================
    // ==================================================================================================
    // Call api detail store
    const getDetailBranch = async (branchId: string) => {
      await branchPresenter.getDetailBranch(branchStore, branchId);
      if (branchStore.detailBranchResponse.isSuccess) {
        const dataGetFirst = branchStore.detailBranchResponse.data as DataBranch;
        // Set province and district
        if (dataGetFirst) {
          form.setFieldValue('provinceId', dataGetFirst.provinceId);
          getListDistrict(dataGetFirst.provinceId);
          if (dataGetFirst.provinceId) {
            form.setFieldValue('districtId', dataGetFirst.districtId);
          }
        }
        form.setFieldsValue(dataGetFirst);
        setLoading(false);
      } else {
        navigate('/MasterData/Store');
      }
    };

    // Call api get list company
    const getListOrg = async () => {
      await commonMasterDataPresenter.getDropdownCompanyV2(commonMasterDataStore);
      if (commonMasterDataStore.listCompany.length) {
        setListOrg(commonMasterDataStore.listCompany);
      }
    };

    // Call api province
    const getListProvince = async (keyword = '') => {
      await commonMasterDataPresenter.getDropDownProvince(commonMasterDataStore, { keyword: keyword || undefined });
      if (commonMasterDataStore.provinceDropdown.isSuccess) {
        setListProvince(commonMasterDataStore.provinceDropdown.data);
      }
    };
    // Call api district
    const getListDistrict = async (provinceId: string, keyword = '') => {
      await commonMasterDataPresenter.getDropDownDistrict(commonMasterDataStore, provinceId, keyword || undefined);
      if (commonMasterDataStore.districtDropdown.isSuccess) {
        setListDistrict(commonMasterDataStore.districtDropdown.data);
      }
    };

    // Set value form default
    useEffect(() => {
      // Call api first access
      if (idParams) {
        setLoading(true);
        getDetailBranch(idParams);
      }

      getListOrg();
      getListProvince();
    }, []);

    // Handle create branch
    const onFinish = async (values: DataBranch) => {
      setLoading(true);

      removeEmptyValues(values);

      if (!idParams) {
        await branchPresenter.createDetailBranch(branchStore, values);
        if (branchStore.createDetailBranchResponse.isSuccess) {
          message.success(branchStore.createDetailBranchResponse.message);
          // Redirect to search page store (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Store');
          }
          form.resetFields();
        }

      } else {
        const dataSendApi = {
          ...values,
          // invoiceStorename: values.invoiceStoreName,
          orderIndex: values.orderIndex || 0,
          storeId: idParams,
        } as DataBranch;
        // Call api update
        await branchPresenter.updateDetailBranch(branchStore, dataSendApi);
        if (branchStore.updateDetailBranchResponse.isSuccess) {
          message.success(branchStore.updateDetailBranchResponse.message);
          if (idParams) {
            getDetailBranch(idParams);
          }

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Store');
          }
        }

      }
      setLoading(false);
    };

    // Set value default of form
    const initialValues = {
      actived: true,
    };

    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('masterdata.store.title'),
                link: '/MasterData/Store',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/Store/${idParams}` : '/MasterData/Store/create',
              },
            ]}
            extra={
              <div className="custom__mobile--save flex space-x-2">
                <CustomButton
                  icon={<Icon.Download className="!fill-[#262626] " />}
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                  onClick={() => {
                    isSaveAndContinue = false;
                    form.submit();
                  }}
                >
                  {t('btn.save')}
                </CustomButton>
                <CustomButton
                  type="primary"
                  icon={<Icon.Download />}
                  disabled={loading}
                  loading={loading}
                  onClick={() => {
                    isSaveAndContinue = true;
                    form.submit();
                  }}
                >
                  {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
                </CustomButton>
              </div>
            }
          />
          {/* Form create/update */}
          <BoxContainer fullHeight>
            <SearchForm
              column="col-1"
              form={form}
              onFinish={onFinish}
              initialValues={idParams ? {} : initialValues}
            >
              <Spin spinning={loading}>
                <div className="grid-cols-2 relative lg:grid flex flex-col gap-y-1 gap-x-default w-[100%] lg:w-full">
                  {/* Mã nhà máy */}
                  <SearchItem
                    label={t('masterdata.store.branch-code')}
                    name="saleOrgCode"
                    rules={[
                      {
                        required: true,
                        message: `${t('message.required-input')} "${t('masterdata.store.branch-code')}"`,
                      },
                    ]}
                  >
                    <CustomInput disabled={idParams ? true : false} />
                  </SearchItem>
                  {/* Tên nhà máy */}
                  <SearchItem
                    label={t('masterdata.store.branch-name')}
                    name="storeName"
                    rules={[
                      {
                        required: true,
                        message: `${t('message.required-input')} "${t('masterdata.store.branch-name')}"`,
                      },
                    ]}
                  >
                    <CustomInput />
                  </SearchItem>
                  {/* Công ty */}
                  <SearchItem
                    label={t('masterdata.store.company')}
                    name="companyId"
                    rules={[
                      {
                        required: true,
                        message: `${t('message.required-select')} "${t('masterdata.company.title')}"`,
                      },
                    ]}
                  >
                    <CustomSelect
                      options={listOrg}
                      disabled={idParams ? true : false}
                      fieldNames={{ label: 'value', value: 'key' }}
                      allowClear
                    />
                  </SearchItem>
                  {/* Email */}
                  <SearchItem
                    label="Email"
                    name="email"
                    rules={[
                      {
                        validator: validateEmail,
                      },
                    ]}
                  >
                    <CustomInput placeholder={t('masterdata.email-placeholder')} />
                  </SearchItem>
                  {/* Điện thoại */}
                  <SearchItem
                    label={t('masterdata.store.phone')}
                    name="telProduct"
                    rules={[
                      {
                        validator: validatePhone,
                      },
                    ]}
                  >
                    <CustomInput addonBefore={<Icon.PhoneVolume />} />
                  </SearchItem>
                  {/* Tỉnh/Thành phố */}
                  <SearchItem label={t('masterdata.store.province')} name="provinceId">
                    <CustomSelect
                      options={listProvince}
                      fieldNames={{
                        label: 'value',
                        value: 'key',
                      }}
                      showSearch
                      filterOption={false}
                      onSearch={debounce(async (value) => await getListProvince(value), 300)}
                      onChange={async (value, option) => {
                        await getListDistrict(option?.key);
                        // Clear đi các field liên quan (VD: quận huyện, xã)
                        form.setFieldsValue({
                          districtId: '',
                        });
                      }}
                      allowClear
                    />
                  </SearchItem>
                  {/* Điện thoại khác */}
                  <SearchItem
                    label={t('masterdata.store.other-phones')}
                    name="telService"
                    rules={[
                      {
                        validator: validatePhone,
                      },
                    ]}
                  >
                    <CustomInput addonBefore={<Icon.PhoneVolume />} />
                  </SearchItem>
                  {/* Quận huyện */}
                  <SearchItem label={t('masterdata.store.district')} name="districtId">
                    <CustomSelect
                      options={listDistrict.map((i) => ({
                        label: `${i.value}`,
                        value: i.key,
                      }))}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
                      }
                      showSearch
                      allowClear
                    />
                  </SearchItem>
                  {/* Trạng thái */}
                  <SearchItem name={'actived'} initialValue={true} hidden label={t('masterdata.store.status')}>
                    <Radio.Group>
                      <Radio value={true}>{t('radio-btn.active')}</Radio>
                      <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                    </Radio.Group>
                  </SearchItem>
                  {/* Địa chỉ */}
                  <SearchItem label={t('masterdata.store.address')} name="storeAddress">
                    <CustomInput />
                  </SearchItem>
                </div>
              </Spin>
            </SearchForm>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateBranch;
