import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { Ward, IWardServices } from 'services/RawData/ward_services';

export type wardStore = {
  listWard: DataResponse<Array<Ward>>;
  detailWard: DataResponse<Ward | undefined>;
  crudResponse: DataResponse<any>;
};

export interface IWardPresenter {
  createStore(): wardStore;
  // Search list province
  searchListWard(store: wardStore, data: object): Promise<void>;
  // create new Ward
  createWard(store: wardStore, data: object): Promise<void>;
  // update new Ward
  updateWard(store: wardStore, data: object): Promise<void>;
  // get Ward by id
  getWardById(store: wardStore, id: string): Promise<void>;
  // delete
  deleteWard(store: wardStore, id: string): Promise<void>;
  // get Ward by district id
  getWardByDistrictId(store: wardStore, districtId: string, keyword: string): Promise<void>;
}

export function createWardPresenter({ wardServices }: { wardServices: IWardServices }): IWardPresenter {
  return {
    createStore: () =>
      observable({
        searchWardResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        detailWard: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        listWard: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        crudResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
      }),
    // Search list Ward
    searchListWard: async (store, data) => {
      try {
        const result = await wardServices.searchListWard(data);
        runInAction(() => {
          store.listWard.data = result.data;
          store.listWard.isSuccess = result.isSuccess;
          store.listWard.message = result.message;
          store.listWard.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // create new Ward
    createWard: async (store, data) => {
      try {
        const result = await wardServices.createWard(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // update new Ward
    updateWard: async (store, data) => {
      try {
        const result = await wardServices.updateWard(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // update new Ward
    deleteWard: async (store, id) => {
      try {
        const result = await wardServices.deleteWard(id);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get ward by id
    getWardById: async (store, id) => {
      try {
        const result = await wardServices.getWardById(id);
        runInAction(() => {
          store.detailWard.data = result.data;
          store.detailWard.isSuccess = result.isSuccess;
          store.detailWard.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get ward by id
    getWardByDistrictId: async (store, districtId, keyword) => {
      try {
        const result = await wardServices.getWardByDistrictId(districtId, keyword);
        runInAction(() => {
          store.listWard.data = result.data;
          store.listWard.isSuccess = result.isSuccess;
          store.listWard.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
