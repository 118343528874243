import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';

export type WorkModel = Partial<{
  stt: number;
  workId: string;
  // mã công việc
  workCode: string;
  // tên công việc
  workName: string;
  // mã loại công việc
  worktypeCode: string;
  // tên loại công việc
  worktypeName: string;
  // giờ công
  workHour: string;
  //giá
  price: number;
  // trạng thái
  actived: string;
}>;

export interface IWorkServices {
  // search list paging
  searchListWork(data: object): Promise<DataResponse<Array<WorkModel>>>;
  // get detail data by id
  getDetailById(id: string): Promise<DataResponse<WorkModel>>;
  // create
  createWork(data: object): Promise<DataResponse<any>>;
  // update
  updateWork(data: object): Promise<DataResponse<any>>;
  // delete
  deleteWork(WorkId: string): Promise<DataResponse<any>>;
}
export function createWorkServices(): IWorkServices {
  return {
    // search list paging
    searchListWork: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/Work/list`, data)).data;
    },
    // search list paging
    getDetailById: async (id) => {
      return (await get(MASTER_DATA_API_URL, `MasterData/Work/get-by-id`, { WorkId: id })).data;
    },
    // create
    createWork: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/Work/Create`, data)).data;
    },
    // update
    updateWork: async (data) => {
      return (await put(MASTER_DATA_API_URL, `MasterData/Work/Update`, data)).data;
    },
    // delete
    deleteWork: async (WorkId) => {
      return (await deleted(MASTER_DATA_API_URL, `MasterData/Work/delete`, { WorkId: WorkId })).data;
    },
  };
}
