import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const catalogTypeRoutes = ({
  CatalogType,
  AddCatalogType,
  UpdateCatalogType,
}: {
  CatalogType: React.ComponentType;
  AddCatalogType: React.ComponentType;
  UpdateCatalogType: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<CatalogType />} />
    <Route path={ROUTES.MASTER_DATA.CATALOGTYPE.CREATE} element={<AddCatalogType />} />
    <Route path={ROUTES.MASTER_DATA.CATALOGTYPE.EDIT} element={<UpdateCatalogType />} />
  </Routes>
);
