import CustomButtonIcon, { ButtonProps } from 'base/ui/components/Button/buttonIcon';
import { Download, PencilEdit } from 'base/ui/components/Icons';
import React, { forwardRef } from 'react';
import { Button, ButtonProps as AntdButtonProps, ConfigProvider } from 'antd';
import CustomButton from 'base/ui/components/Button';
export type Ref = HTMLButtonElement;

const ButtonEdit = forwardRef<Ref, React.ButtonHTMLAttributes<HTMLButtonElement>>(
  (props: React.ButtonHTMLAttributes<HTMLButtonElement>, ref) => {
    return (
      <CustomButtonIcon ref={ref} {...props} color="#007aee">
        <PencilEdit width="13" height="13" className="!fill-[#007aee]" />
      </CustomButtonIcon>
    );
  },
);


type ExportButtonProps = AntdButtonProps & {
  title?: string,
}

const ExportButton: React.FC<AntdButtonProps> = (props) => {
  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: props.color ? props.color : "#14ae5c",
      },
    }}>
      <CustomButton icon={<Download />} type='primary' {...props}>

        {props.title}
      </CustomButton>
    </ConfigProvider>
  )
}

ExportButton.defaultProps = {
  title: "Export"
}

export { ButtonEdit, ExportButton };
