import { observer } from 'mobx-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DatePicker, Form, Input, Radio, Select, SelectProps, Tooltip } from 'antd'
import { ActionType, ProColumns } from '@ant-design/pro-components'
import { formatNumber } from 'utils/formatHelper'
import { PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants'
import { useResizableColumns } from 'utils/reportHelper'
import PageWrapper from 'pages/Components/PageWrapper'
import CustomButton from 'base/ui/components/Button';
import { Filter } from 'base/ui/components/Icons'
import BoxContainer from 'base/ui/components/BoxContainer'
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable'
import { ExportButton } from 'pages/Components/FunctionButton'
import SaveReportForm from 'pages/Components/reportForm'
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox'
import { SearchReceivingScheduleOfBHFReport } from 'services/Main/report_services'
import { ReceivingScheduleOfBHFItem, ReceivingScheduleOfBHFReport } from 'models/Report'
import { commonPresenter, commonStore, reportPresenter, reportStore, stockPresenter, stockStore } from 'stores/root_store'
import { debounce } from 'utils/debounce'
import { CommonModel } from 'services/Main/common_services'
import { HiSearch } from 'react-icons/hi'
import { cloneDeep, omit } from 'lodash'
import { usePermission } from 'hooks/usePermission'
import { checkPermissionWithId } from 'utils/permissionHelper'
import dayjs from 'dayjs'
import { removeEmptyValues } from 'utils/transformHelper'

const initialSearch: SearchReceivingScheduleOfBHFReport = {
    paging: {
        pageSize: PAGE_SIZE_DEFAULT,
        pageIndex: 1,
        orderBy: undefined,
        orderByDesc: undefined,
    },
    type: 'DATE',
    deliveredTime: dayjs().format("YYYY-MM-DD")
}

const createReceivingSchedule = () => {
    return observer(() => {
        document.title = 'Báo cáo - ' + SYSTEM_TITLE;
        usePermission('INDEX')
        const isExport = checkPermissionWithId("EXPORT")

        const [open, setOpen] = useState(false);

        // show filter form
        const showDrawer = () => {
            setOpen(true);
        };
        // close filter form
        const onClose = () => {
            setOpen(false);
        };

        // ========== SEARCH =================
        const [searchForm] = Form.useForm()
        const [loading, setLoading] = useState(true)
        // Dropdown nhà cung cấp
        const [listVendor, setListVendor] = useState<CommonModel[]>([])
        // Dropdown kho nhận
        const [listStocks, setListStocks] = useState<SelectProps['options']>([])
        // data tìm kiếm phân trang
        const [dataPaging, setDataPaging] = useState<SearchReceivingScheduleOfBHFReport>(initialSearch);
        const [dataTable, setDataTable] = useState<ReceivingScheduleOfBHFReport[]>([])

        // loại báo cáo (Theo tháng || Theo ngày)
        const [typeReport, setTypeReport] = useState<"date" | "month">("date")

        // Dropdown nhà cung cấp
        const getDropdownVendor = async (keyword?: string) => {
            await commonPresenter.getDropdownVendor(commonStore, { keyword: keyword, takeNumber: 20 })
            if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
                setListVendor(commonStore.commonRespone.data)
            }
        }
        const searchVendor = useCallback(debounce(getDropdownVendor, 300), [])

        // Danh sách kho 
        const getDropdownStock = async (keyword?: string) => {
            await stockPresenter.searchAllStock(stockStore, keyword || '')
            if (stockStore.searchAllStockResponse.isSuccess) {
                const stocks = stockStore.searchAllStockResponse.data
                setListStocks(stocks.map(stock => ({ label: stock.value, value: stock.stockCode })))
            }
        }

        // search báo cáo
        const getDataTable = async (dataSearch: SearchReceivingScheduleOfBHFReport) => {
            await reportPresenter.getReceivingSchedulReport(reportStore, dataSearch)
            if (reportStore.receivingScheduleOfBHFRes?.isSuccess) {
                setDataTable(reportStore.receivingScheduleOfBHFRes?.data)
                return reportStore.receivingScheduleOfBHFRes?.data
            }
            return []
        }

        useEffect(() => {
            Promise.allSettled([
                getDropdownVendor(),
                getDropdownStock()
            ]).then(() => { setLoading(false) })
        }, []);
        // =========== TABLE && MẪU BÁO CÁO =================
        const getColumnSearchProps = (dataIndex: keyof ReceivingScheduleOfBHFItem, option?: { align?: string; truncate?: "truncate", format?: boolean }): ProColumns<ReceivingScheduleOfBHFReport> => ({
            onCell: () => ({ className: `!p-0 !pt-0 divide-y ${option?.align}` }),
            render(_, record) {
                const { receivingScheduleOfBHFItemResponses } = record
                return receivingScheduleOfBHFItemResponses.length ?
                    receivingScheduleOfBHFItemResponses.map((item, index) => {
                        return <div key={index} className={`w-full py-1 px-2 ${option?.truncate}`}>
                            {option?.truncate === 'truncate' ?
                                (<Tooltip title={item[dataIndex]} >{item[dataIndex] ? item[dataIndex] : '-'}</Tooltip>)
                                : item[dataIndex] ? option?.format ? formatNumber(Number(item[dataIndex]), 3) : item[dataIndex] : "-"}
                        </div>
                    }) : ''
            },
        })
        const [columns, setColumns] = useState<ProColumns<ReceivingScheduleOfBHFReport>[]>([
            {
                title: 'Order\n(STT)',
                dataIndex: 'stt',
                key: 'stt',
                fixed: 'left',
                width: 80,
                align: 'center',
            },
            // Mã NVL
            {
                title: 'Item code\n(Mã nguyên vật liệu)',
                className: "whitespace-pre-line",
                dataIndex: 'productCode',
                key: 'productCode',
                fixed: 'left',
                align: 'center',
                width: 150,
            },
            // Tên NVL
            {
                title: 'Item name\n(Tên nguyên vật liệu)',
                dataIndex: 'productName',
                className: "whitespace-pre-line",
                key: 'productName',
                width: 250,
            },
            // Nhà cung cấp
            {
                title: 'Vendor name\n(Nhà cung cấp)',
                dataIndex: 'vendorFmt',
                className: "whitespace-pre-line",
                key: 'vendorFmt',
                width: 500,
                // ...getColumnSearchProps('vendorFmt')
            },
            // Đơn vị tính
            {
                title: 'Unit\n(Đơn vị tính)',
                dataIndex: 'unit',
                className: "whitespace-pre-line",
                key: 'unit',
                width: 100,
                ...getColumnSearchProps('unit')
            },
            // Số PO
            {
                title: 'PO no\n(Số PO)',
                dataIndex: 'purchaseOrderCode',
                key: 'purchaseOrderCode',
                width: 100,
                className: "whitespace-pre-line",
                ...getColumnSearchProps('purchaseOrderCode')
            },
            // Kho nhận
            {
                title: 'Warehouse\n(Kho nhận)',
                dataIndex: 'stockFmt',
                key: 'stockFmt',
                width: 250,
                className: "whitespace-pre-line",
                ...getColumnSearchProps('stockFmt', { truncate: 'truncate' })
            },
            // Số lượng kế hoặc
            {
                title: 'Plan\n(Kế hoạch)',
                dataIndex: 'planDeliveryQuantity',
                key: 'planDeliveryQuantity',
                width: 150,
                className: "whitespace-pre-line",
                ...getColumnSearchProps('planDeliveryQuantity', { format: true, align: 'text-right' })
            },
            // Số lượng thực tế
            {
                title: 'Actual\n(Thực tế)',
                dataIndex: 'actualDeliveryQuantity',
                key: 'actualDeliveryQuantity',
                width: 150,
                className: "whitespace-pre-line",
                ...getColumnSearchProps('actualDeliveryQuantity', { format: true, align: 'text-right' })
            },
            // Số lượng chênh lệch
            {
                title: 'Differerence\n(Chênh lệch)',
                dataIndex: 'differenceDeliveryQuantity',
                key: 'differenceDeliveryQuantity',
                width: 150,
                className: "whitespace-pre-line",
                ...getColumnSearchProps('differenceDeliveryQuantity', { format: true, align: 'text-right' })
            },
            {
                title: 'Notes\n(Ghi chú)',
                dataIndex: 'notes',
                key: 'notes',
                width: 350,
                className: "whitespace-pre-line",
                ...getColumnSearchProps('notes', { truncate: 'truncate' })
            },
        ])

        // mẫu báo cáo
        const tableRef = useRef<ActionType>();
        const constDataName = 'BCReceivingSchedule';
        const {
            isResizablePresent,
            setIsResizablePresent,
            resizableColumnSelect,
            columnsState,
            handleColumnsStateChange,
            templateReportModalRef,
            userReports,
            selectedReportId,
            templateReportModalProps,
            handleResize,
        } = useResizableColumns<ReceivingScheduleOfBHFReport>(columns, setColumns, constDataName);

        return (
            <PageWrapper
                breadcrumb={[{
                    text: 'Báo cáo nhận hàng'
                }]}
                extras={
                    <>
                        {isExport && <ExportButton loading={loading} onClick={async () => {
                            setLoading(true);
                            const exportRequest = cloneDeep(dataPaging)
                            await reportPresenter.exportReceivingSchedulReport(omit(exportRequest, ['paging']))
                            setLoading(false);
                        }} />}
                        <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                            <Filter className="!fill-primary" />
                        </CustomButton>
                    </>
                }
            >
                <BoxContainer>
                    <CustomProTable<ReceivingScheduleOfBHFReport>
                        bordered
                        columns={columns}
                        dataSource={dataTable}
                        actionRef={tableRef}

                        tableAlertRender={false}
                        handleResize={handleResize}
                        isResizablePresent={isResizablePresent}
                        resizableColumnSelect={resizableColumnSelect}
                        columnsState={{
                            value: Object.keys(columnsState).length > 0 ? columnsState : undefined,
                            onChange: (newColumnsState) => {
                                handleColumnsStateChange(newColumnsState);
                            },
                        }}
                        toolBarRender={() => [

                            // nút lưu mẫu bc
                            <SaveReportForm
                                setIsResizablePresent={setIsResizablePresent}
                                templateReportModalRef={templateReportModalRef}
                                userReports={userReports}
                                selectedReportId={selectedReportId}
                                templateReportModalProps={templateReportModalProps}
                            />,
                        ]}
                        rowKey="stt"
                        scroll={{ y: "calc(100vh - 340px)", x: "1500px" }}
                        pagination={{
                            ...tablePagination(dataPaging, setDataPaging),
                            total: reportStore.receivingScheduleOfBHFRes?.paging?.recordsTotal,
                        }}
                        request={async (params = {}, sort, filter) => {
                            const data = await getDataTable({
                                ...dataPaging,
                                paging: {
                                    ...dataPaging.paging,
                                    orderBy:
                                        Object.values(sort)[0] === "ascend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                    orderByDesc:
                                        Object.values(sort)[0] === "descend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                },
                            });
                            if (Object.values(sort).length) {
                                setDataPaging({
                                    ...dataPaging,
                                    paging: {
                                        ...dataPaging.paging,
                                        orderBy:
                                            Object.values(sort)[0] === "ascend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                        orderByDesc:
                                            Object.values(sort)[0] === "descend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                    },
                                });
                            }
                            return {
                                data: data,
                                success: reportStore.receivingScheduleOfBHFRes?.isSuccess
                            };

                        }}
                    />

                </BoxContainer>
                <SearchBox
                    onClose={onClose}
                    className="lg:!w-[50%]"
                    open={open}
                    footer={
                        <CustomButton
                            loading={loading}
                            htmlType="submit"
                            className="my-auto ml-auto"
                            type="primary"
                            icon={<HiSearch />}
                            onClick={() => {
                                searchForm.submit();
                            }}
                        >
                            Tìm kiếm
                        </CustomButton>
                    }
                >
                    <SearchForm
                        form={searchForm}
                        initialValues={omit(initialSearch, ['paging', 'deliveredTime'])}
                        onFinish={(values) => {
                            // const dataSearch: SearchReceivingScheduleOfBHFReport = { paging: { ...dataPaging.paging, pageIndex: 1 }, ...values }
                            // setDataPaging(dataSearch)
                            // setOpen(false)
                            // tableRef.current?.reload()
                            // convert lại from date to date
                            const valueClone = cloneDeep(values)
                            const deliveredTime = valueClone?.deliveredTime ? valueClone.deliveredTime?.format("YYYY-MM-DD") : undefined

                            Object.assign(valueClone, { deliveredTime })
                            // bỏ các giá trị rỗng null và trim giá trị
                            removeEmptyValues(valueClone)
                            const dataSearch: SearchReceivingScheduleOfBHFReport = { paging: { ...dataPaging.paging, pageIndex: 1 }, ...valueClone }
                            setDataPaging(dataSearch)
                            setOpen(false)
                            tableRef.current?.reload()
                        }}
                    >
                        {/* Số PO */}
                        <SearchItem name={'purchaseOrderCode'} label={'Số PO'}>
                            <Input />
                        </SearchItem>
                        {/* Nhà cung cấp */}
                        <SearchItem name={'vendorNumber'} label={'Nhà cung cấp'}>
                            <Select
                                popupMatchSelectWidth={false}
                                options={listVendor.map(item => ({ value: item.key, label: item.value }))}
                                allowClear showSearch
                                filterOption={false}
                                onSearch={(value) => searchVendor(value || undefined)} />
                        </SearchItem>
                        {/* Mã hàng hóa */}
                        <SearchItem name={'productCode'} label={'Mã hàng hóa'}>
                            <Input />
                        </SearchItem>
                        {/* Tên hàng hóa */}
                        <SearchItem name={'productName'} label={'Tên hàng hóa'}>
                            <Input />
                        </SearchItem>
                        {/* Kho nhận */}
                        <SearchItem name={'stockCode'} label={'Kho nhận'}>
                            <Select options={listStocks} allowClear placeholder={'-- Tất cả --'} filterOption={false} />
                        </SearchItem>
                        {/* Type (Theo ngày\Theo tháng) */}
                        <SearchItem name={'type'} label={'Loại báo cáo'}>
                            <Radio.Group onChange={(e) => {
                                setTypeReport(e.target.value.toLowerCase())
                            }}>
                                <Radio value={'DATE'}>Theo ngày</Radio>
                                <Radio value={'MONTH'}>Theo tháng</Radio>
                            </Radio.Group>
                        </SearchItem>

                        {/* Ngày giao hàng */}
                        <SearchItem
                            initialValue={dayjs()}
                            rules={[{ required: true, message: 'Vui lòng chọn "Ngày giao hàng".' }]}
                            name={'deliveredTime'} label={'Ngày giao hàng'}>
                            <DatePicker format={typeReport === "month" ? "MM/YYYY" : 'DD/MM/YYYY'} picker={typeReport} className="w-full" />
                        </SearchItem>
                    </SearchForm>
                </SearchBox>
            </PageWrapper>
        )
    })
}

export default createReceivingSchedule