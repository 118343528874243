import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const productRoutes = ({
    List, Edit
}: {
    List: React.ComponentType;
    Edit: React.ComponentType;
}) => (
    <Routes>
        <Route path="" element={<List />} />
        <Route path={ROUTES.MASTER_DATA.PRODUCT.EDIT} element={<Edit />} />
    </Routes>
);
