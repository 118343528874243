import { AES, enc } from "crypto-js";



// Key ghi nhớ mật khẩu
const ENCRYPT_KEY = '3fiYLDFb8yNHPqEyUGMe62fJpnyaNXuM';
// ====MÃ HÓA===============================================================================
export const encrypt = (text: string): string => {
    return AES.encrypt(text, ENCRYPT_KEY).toString();
};

export const decrypt = (text: string): string => {
    return AES.decrypt(text, ENCRYPT_KEY).toString(enc.Utf8);
};