/* eslint-disable react-hooks/exhaustive-deps */

import { User } from 'services/User/user_services';
import { getUserSession } from './localStorageHelper';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';

export const getPagePermissions = () => {
    // Kiểm tra đường dẫn
    // check phân quyền bằng đường dẫn
    // kiểm tra url của web để tìm page id => dùng page id để tìm quyền của trang
    const path = window.location.pathname;
    const user = getUserSession() as User;


    // Xử lý tìm ra object chứa pageId khớp với url hiện tại
    const filteredData = user.webPermission?.pageModel.find((item) => {
        const pageUrl = item.pageUrl.startsWith('/') ? item.pageUrl : '/' + item.pageUrl
        return path === `${pageUrl}` || path.startsWith(`${pageUrl}/`);
    });

    if (filteredData) {
        const permission = user.webPermission?.pagePermissionModel?.filter((item) => item.pageId === filteredData.pageId);

        if (permission) {
            return {
                permiss: permission.map((item) => item.functionId),
                idPage: permission[0].pageId,
            };
        }
    }
    return null; // Trả về giá trị null nếu không có quyền truy cập
};

export const useCheckPermission = (functionId: string) => {
    const navigate = useNavigate();
    const permission = getPagePermissions()

    if (!permission?.permiss.includes(functionId)) {
        navigate('/notFount')
    }
}
export const useCheckPermissionByUrl = (url: string, functionId: string) => {
    const navigate = useNavigate();
    const permission = checkPermissionWithPageUrl(url)

    if (!permission?.includes(functionId)) {
        navigate('/notFount')
    }
}

export const checkPermissionWithId = (functionId: string) => {
    const permission = getPagePermissions()

    if (!permission?.permiss.includes(functionId)) return false;
    else return true
}

export const checkPermissionWithPageUrl = (pageUrl: string) => {
    const user = getUserSession() as User;
    const page = find(user.webPermission.pageModel, ['pageUrl', pageUrl])
    if (page) {
        const pagePermission = user.webPermission?.pagePermissionModel?.filter((item) => item.pageId === page.pageId);
        return pagePermission.map((item) => item.functionId)
    }
    return []
}

export const checkPermission = () => {
    // Kiểm tra đường dẫn
    // check phân quyền bằng đường dẫn
    // kiểm tra url của web để tìm page id => dùng page id để tìm quyền của trang
    const path = window.location.pathname;
    const user = getUserSession() as User;

    // Xử lý tìm ra object chứa pageId khớp với url hiện tại
    const filteredData = user.webPermission?.pageModel.find((item) => {
        return path === `${item.pageUrl}` || path.startsWith(`${item.pageUrl}/`);
    });

    if (filteredData) {
        const permission = user.webPermission?.pagePermissionModel?.filter((item) => item.pageId === filteredData.pageId);
        if (permission) {
            return {
                permiss: permission.map((item) => item.functionId),
                idPage: permission[0].pageId,
            };
        }
    }
    return null; // Trả về giá trị null nếu không có quyền truy cập
};


