import { get, post } from 'utils/api';

import { DataResponse } from '../response';
import { MASTER_DATA_API_URL } from 'utils/constants';
// import { ProductWarranty } from 'services/Main/Warranty/productWarranty_services';

export type ProductWarranty = Partial<{
  stt: number;
  productWarrantyCode: number;
  profileName: string;
  address: string;
  phone: string;
  erpProductCode: string;
  productName: string;
  warrantyName: string;
  serriNo: string; // Số seri
  saleOrder: string; // Số hóa đơn DGW
  saleDate: string; // Ngày bán cho đại lý
  sysActiveDate: string;
  productWarrantyNo: string;
  fromDate: string;
  toDate: string;
  productWarrantyId: string;
  profileId: string;
  productId: string;
  warrantyId: string;
  actived: boolean;
  provinceName: string;
  districtName: string;
  wardName: string;
  oldBillingCode: string; // Số hoá đơn cũ của DGW
  // Thêm phần type dùng khi search
  companyId: string;
  orderDelivery: string;
  age: string;
  fromDate_From: string;
  fromDate_To: string;
  toDate_From: string;
  toDate_To: string;

  // Thêm phần type dùng khi create
  provinceId: string;
  districtId: string;
  wardId: string;
  extendWarrantyType: string;
  note: string;
  isWarning: boolean;
  adjustmentDay: number;
  storeInDate: string;
  expiredMaintenance: number;
  warantyConditionCode: string;
  email: string;
  startActivedCustomerDate: string;
  messageWarning: string;

  // Thêm phần type dùng get detail
  warranty_ProfileName: string;
  profileShortName: string;
  activatedQuantity: number;
  warrantyConditionName: string;

  // Thời hạn bảo hành
  warrantyDate: number;
  // Hoá đơn giá trị gia tăng
  hdgtgt: string;
  // Check trùng số serial
  isColor: boolean;
  // Ngày KH kích hoạt
  fromCustomerActive: string;
  // Ngày kích hoạt đầu
  fromFirst: string;
  startDate: string;
  // Check điều kiện hiển thị field Ngày khách hàng đăng ký
  isDateCustomerActive: boolean;
  // Full serial number
  fullSerialNumber: string;
  createByFullName: string;
  createTime: string;
  lastEditByFullName: string;
  lastEditTime: string;
  // Ngày cập nhật
  lastFromDate: string;
  lastFromDate_From: string;
  lastFromDate_To: string;

  createTimeDate: string;
  createTimeDate_From: string;
  createTimeDate_To: string;
}>;

export type Customer = Partial<{
  customerCode: string | number;
  profileCustomCode: string;
  customerForeignCode: string;
  customerName: string;
  phoneNumber: string;
  isActived: boolean;
  customerTypeCode: string;

  stt: number;
  customerId: string;
  customerTypeName: string;
  email: string;
  address: string;
  createUser: string;

  title: string;
  profileShortName: string;
  note: string;
  saleOfficeCode: string;

  // Bổ sung thêm để dùng lúc fill data ở các input có nút (...)
  provinceId: string;
  provinceName: string;
  districtId: string;
  districtName: string;
  wardId: string;
  wardName: string;
  // mã số thuế
  mst: string;
  // Thời gian tạo
  createTime: string;
  // Được cập nhật bởi
  lastEditByFullName: string;
  // Thời gian cập nhật
  lastEditTime: string;
}>;

// Data liên hệ
export type ContactAttributes = Partial<{
  key: React.Key;
  id: React.Key;
  stt: number;
  profileId: string;
  profileCode: number;
  profileName: string;
  phoneNumber: string;
  email: string;
  createBy: string;
  dateTime: string;
  isActived: boolean;
  children: ContactAttributes[];
}>;
// Data product warranty
export type ProductWarrantyInCustomer = ProductWarranty &
  Partial<{
    productWarrantyId: string;
    productWarrantyCode: number;
    productName: string;
    productCode: string;
    warrantyName: string;
    productWarrantyNo: string;
    saleOrder: string; // Số hoá đơn
    orderDelivery: string;
    fromDate: string;
    toDate: string;
    stt: number;

    serriNo: string;
    hdgtgt: string; // Số hoá đơn của DGW
    warrantyDate: number;
  }>;
// Data detail customer
export type CustomerDetail = Customer &
  Partial<{
    customerResponse: Customer;
    proviceName: string;
    districtName: string;
    wardName: string;
    proviceId: string;
    provinceId: string;
    districtId: string;
    wardId: string;

    contactAttributes: ContactAttributes[];
    productWarranty: ProductWarrantyInCustomer[];
  }>;

// Data customer lấy bằng số điện thoại
export type CustomerByPhone = Partial<{
  /**
   * Type này có 3 kiểu khi có sđt gọi tới:
   * None => Không có KH nào
   * List => Có nhiều hơn 1 KH
   * Detail => Có 1 KH
   * */
  type: string;
  profileList: Customer[];
  profileDetail: CustomerDetail;
}>;

export interface ICustomerServices {
  // search list
  searchListCustomer(data: object): Promise<DataResponse<Customer[]>>;
  // get by id
  getCustomerById(id: string): Promise<DataResponse<object>>;
  // create customer
  createCustomer(data: object): Promise<DataResponse<boolean>>;
  // update customer
  updateCustomer(data: object): Promise<DataResponse<boolean>>;
  // get by phone
  getCustomerByPhone(phone: string): Promise<DataResponse<CustomerByPhone>>;
}
export function createCustomerServices(): ICustomerServices {
  return {
    // Search list
    searchListCustomer: async (data) => {
      return (await post(MASTER_DATA_API_URL, `profile/list`, data)).data;
    },
    // Get by id
    getCustomerById: async (CustomerId) => {
      return (await get(MASTER_DATA_API_URL, `profile/get-by-id?CustomerId=${CustomerId}`)).data;
    },
    createCustomer: async (data) => {
      return (await post(MASTER_DATA_API_URL, `profile/create`, data)).data;
    },
    updateCustomer: async (data) => {
      return (await post(MASTER_DATA_API_URL, `profile/update`, data)).data;
    },
    // Get by phone
    getCustomerByPhone: async (phoneNumber) => {
      return (await get(MASTER_DATA_API_URL, `profile/by-phonenumber?phonenumber=${phoneNumber}`)).data;
    },
  };
}
