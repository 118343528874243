import { Checkbox, Divider, Form, FormInstance, Input, Modal, Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { commonPresenter, commonStore, driverPresenter, driverStore, services, vendorPresenter } from 'stores/root_store'
import { validateLicensePlate, validatePhone } from 'utils/validateHelper'

import { CardBox } from './cardBox'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import CustomButton from 'base/ui/components/Button';
import { Driver } from 'services/RawData/driver_services'
import { SearchItem } from 'pages/Components/SearchBox'
import { cloneDeep } from 'lodash'
import { debounce } from 'utils/debounce'
import logo1 from 'assets/images/logo-tiny.png';
import { observer } from 'mobx-react'

type DriverInformationProps = {
    disabledField: (isDisable?: boolean) => boolean;
    formCreate: FormInstance<any>;
    isCheckedPolicyConfirm: boolean;
    onPolicyConfirmChange: (checked: boolean) => void;
}

const DriverInformation: React.FC<DriverInformationProps> = (props) => {
    const { disabledField, formCreate } = props
    const vendorNumber = Form.useWatch("vendorNumber", formCreate)
    // danh sách tài xế 
    const [dropdownDriver, setDropdownDriver] = useState<Driver[]>([])
    // loading Search driver
    const [searchDriverLoading, setSearchDriverLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(props.isCheckedPolicyConfirm);
    const [openModal, setOpenModal] = useState(false);
    const handleCheckboxChange = async (e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        setIsChecked(checked);
        props.onPolicyConfirmChange(checked); // Gọi hàm callback khi checkbox thay đổi
    };
    // Dropdown tài xế
    const getListDriver = async (driverName?: string) => {
        setSearchDriverLoading(true)
        const request = {
            paging: {
                pageIndex: 1,
                pageSize: 20,
            },
            driverName: driverName,
            actived: true,
            vendorNumber: vendorNumber
        }
        await driverPresenter.searchDriver(driverStore, request);
        setSearchDriverLoading(false)
        if (driverStore.searchDriverResponse?.isSuccess) {
            // AVNMR-67
            // Sửa phiếu_Thêm phiếu _ Dropdown tài xế Thêm Số bằng lái
            const cloneDrivers = cloneDeep(driverStore.searchDriverResponse?.data)
            cloneDrivers.forEach(driver => {
                Object.assign(driver, { driverName: `${driver.driverName} | ${driver.licenseNumber}` })
            })
            setDropdownDriver(cloneDrivers);
        }
        return driverStore.searchDriverResponse.data
    }
    const handleSearchDriver = useCallback(debounce(getListDriver, 300), [searchDriverLoading])
    // danh sách kết quả đào tạo
    const getTrainingResult = async () => {
        if (!commonStore.dropdownTrainingResult.length) {
            await commonPresenter.getDropdownTrainingResult(commonStore)
        }
    }

    useEffect(() => {
        if (vendorNumber) {
            getListDriver()
            getTrainingResult()
        }
    }, [vendorNumber]);

    useEffect(() => {
        setIsChecked(props.isCheckedPolicyConfirm);
    }, [props.isCheckedPolicyConfirm]);


    return (
        <CardBox title="Thông tin tài xế" >
            <div className="grid grid-cols-2 gap-x-default">
                {/* -- Tên tài xế ------- */}
                <SearchItem name={'driverName'} label='Tên tài xế'
                    rules={[{ required: true, message: 'Vui lòng nhập "Tên tài xế".' }]}
                >
                    <Select
                        disabled={disabledField(true)}
                        loading={searchDriverLoading}
                        options={dropdownDriver}
                        fieldNames={{ label: 'driverName', value: 'driverName' }}
                        filterOption={false}
                        showSearch
                        allowClear
                        onSearch={async (value) => {
                            handleSearchDriver(value || undefined)
                            if (value) {
                                formCreate.setFieldValue('driverName', value)
                            }
                        }}
                        onClear={() => {
                            formCreate.setFieldsValue({
                                driverName: undefined,
                                phone: undefined,
                                licenseNumber: undefined,
                                result: undefined
                            })
                            getListDriver()
                        }}
                        onChange={(_, option) => {
                            if (option && !Array.isArray(option)) {
                                formCreate.setFieldsValue({
                                    driverName: option.driverName,
                                    phone: option.phone,
                                    licenseNumber: option.licenseNumber,
                                    result: option.trainingResult
                                })
                            }
                        }}
                    />
                </SearchItem>
                {/* -- Số điện thoại ------ */}
                {/* Số điện thoại */}
                <SearchItem name={'phone'} label='Số điện thoại'
                    rules={[
                        {
                            validator: validatePhone,
                        },

                    ]}>
                    <Input disabled={disabledField(true)} />
                </SearchItem>
                {/* số xe */}
                <SearchItem name={'licensePlate'} label='Số xe'
                    rules={[{ required: true, message: 'Vui lòng nhập "Số xe".' }, { validator: validateLicensePlate }]}
                >
                    <Input disabled={disabledField(true)} />
                </SearchItem>
                {/* Số bằng lái */}
                <SearchItem name={'licenseNumber'} label='Số bằng lái xe '

                // rules={[{ required: true, message: 'Vui lòng nhập "Số bằng lái".' }]}
                >
                    <Input disabled={disabledField(true)} />
                </SearchItem>
                {/* Kết quả đào tạo an toàn */}
                <SearchItem initialValue={"DAT"} name={'result'} label='Kết quả đào tạo an toàn'
                    rules={[{ required: true, message: 'Vui lòng chọn "Kết quả đào tạo an toàn".' }]}
                >
                    <Select disabled={disabledField(true)} options={commonStore.dropdownTrainingResult} fieldNames={{ label: 'catalogName', value: 'catalogCode' }} />
                </SearchItem>

            </div>

            <Checkbox disabled={disabledField(true)} style={{ paddingTop: 10 }} checked={isChecked}
                onChange={handleCheckboxChange}>Xác nhận cho phép được sử dụng thông tin cá nhân của tài xế &nbsp;&nbsp; &nbsp;&nbsp;
                <span className='underline font-bold text-sky-600 cursor-pointer'
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenModal(true);
                    }}>
                    Xem chi tiết điều khoản tại đây
                </span>
            </Checkbox>


            <Modal
                open={openModal}
                width={1000}
                onCancel={() => setOpenModal(false)}
                title={[
                    <>
                        <div className='flex flex-row gap-x-10'>
                            <img src={logo1} className='w-[90px] h-[70px]' alt='logo_avn' />
                            <div className='flex flex-1 justify-center'>
                                <span className='font-bold text-sky-800 flex items-center justify-center text-[25px] uppercase '>Điều Khoản Sử Dụng Thông Tin Tài Xế</span>
                            </div>

                        </div>
                        <Divider />
                    </>


                ]}
                footer={[
                    <div className='flex flex-row justify-between ml-5'>
                        <span className='text-red-500 text-[15px]'>Nhà cung cấp chỉ cần tích vào ô xác nhận để đồng ý với điều khoản trên.</span>
                        <CustomButton
                            className='flex flex-end'
                            style={{ color: 'white' }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            color='#069E15'
                        >
                            Đóng
                        </CustomButton>
                    </div>

                ]}
            >
                <span className='text-base mx-5 block my-3'>
                    {/* Tôi, <span className='font-bold'>{formCreate.getFieldValue('vendorName')}</span>, đại diện cho công ty, xác nhận  */}

                    <p><span className='font-bold'>1. Mục Đích Sử Dụng: </span>Thông tin tài xế được thu thập và sử dụng để xác thực danh tính, quản lý và theo dõi quy trình nhận hàng trong hệ thống. Thông tin này giúp đảm bảo an toàn, hiệu quả và chính xác trong việc giao nhận hàng hóa.
                    </p>
                    <p><span className='font-bold'>2. Bảo Mật: </span>Chúng tôi cam kết bảo mật thông tin tài xế và chỉ sử dụng nó cho mục đích đã nêu. Thông tin không được chia sẻ với bên thứ ba ngoài phạm vi cần thiết để thực hiện dịch vụ.</p>
                    <p> <span className='font-bold'>3. Lưu Trữ: </span>Thông tin tài xế sẽ được lưu trữ trong thời gian cần thiết để phục vụ mục đích quản lý và tuân thủ quy định pháp luật.</p>
                    <p>
                        <span className='font-bold'>4. Chia Sẻ: </span>Thông tin có thể được chia sẻ với các đối tác, cơ quan pháp lý hoặc bên thứ ba khi cần thiết để thực hiện các chức năng liên quan đến việc nhận hàng và theo yêu cầu của pháp luật.
                    </p>
                </span>


                <Divider />
            </Modal>
        </CardBox>

    )
}

export default observer(DriverInformation)