import { Checkbox, Form, FormInstance, Input, Radio, Select, Space } from 'antd';
import { CommonModel, storeList } from 'services/RawData/commonMasterData_services';
import React, { useCallback, useEffect, useState } from 'react'
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { accountPresenter, accountStore, commonMasterDataPresenter, commonMasterDataStore, commonPresenter, commonStore, vendorPresenter, vendorStore } from 'stores/root_store';
import { validateEmail, validatePhone, validateUsername } from 'utils/validateHelper';

import CustomInput from 'base/ui/components/CustomInput';
import { DataNode } from 'antd/es/tree';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'antd/es/form/Form';

type CreateUpdateAccountFormProps = {
  idParams?: string;
  form: FormInstance<any>;
  isCreateVendor?: boolean;

}

const CreateUpdateAccountForm: React.FC<CreateUpdateAccountFormProps> = (props) => {
  const { idParams, form, isCreateVendor } = props;
  const { t } = useTranslation();
  // Nhà máy
  const [listStore, setListStore] = useState<storeList[]>([]);
  const [visibleNCC, setVisibleNCC] = useState(true);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [listVendor, setListVendor] = useState<CommonModel[]>([]);
  const [dropdownRoles, setDropdownRoles] = useState<CommonModel[]>([]);
  const [disablePassword, setDisablePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [NCCId, setNCCId] = useState('');
  const defaultPasswordNCC = "Ajinomoto@1234";
  const defaultPassword = "Isdcorp@1234";

  const getDataRoles = async () => {
    if (!accountStore.dataDropdownRoles.data.length) {
      await accountPresenter.getDropdownRoles(accountStore);
    }
    if (isCreateVendor) {
      form.setFieldValue('listRoleId', accountStore.dataDropdownRoles.data.find(r => r.value === "Nhà cung cấp")?.key)
    }
  }

  let isUpdateVendor = useWatch('isSupplier', form);
  // console.log({ isUpdateVendor });


  const getDropdownRoles = async () => {
    if (!commonMasterDataStore.listRoleByDepartment.length) {
      await commonMasterDataPresenter.getDropdownRoleByDepartment(commonMasterDataStore)
    }
    const tempt: DataNode[] = commonMasterDataStore.listRoleByDepartment.map((item, index) => {
      return {
        title: item.departmentName,
        key: item.departmentId,
        value: item.departmentId,
        children: item.roleItemResponses.map((i, idx) => ({
          title: i.rolesName,
          key: i.rolesId,
          value: i.rolesId,
        })),
      };
    });
    setTreeData(tempt);
  }
  const getDropdownStore = async () => {
    await commonMasterDataPresenter.getDropDownStore2(commonMasterDataStore);
    if (commonMasterDataStore.companyRes.data.length) {
      const stores = commonMasterDataStore.companyRes.data.at(0)?.storeListResponses || []
      setListStore(stores)
      form.setFieldValue('listStoreId', [stores?.at(0)?.storeId])
    }
  }

  const getDropdownVendor = async (keyword?: string) => {
    await commonPresenter.getDropdownVendor(commonStore, { keyword: keyword, takeNumber: 20 });
    if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
      setListVendor(commonStore.commonRespone.data)
    }
  }

  const fillVendorInformation = async (supplierNumber: string) => {
    setLoading(true);

    await vendorPresenter.getVendorByCode(vendorStore, supplierNumber);
    if (vendorStore.vendorByCodeRes.isSuccess && vendorStore.vendorByCodeRes.data) {
      const dataResult = vendorStore.vendorByCodeRes.data;
      form.setFieldValue('employeeName', dataResult.longName || '');
      form.setFieldValue('phone', dataResult.telephone);
      form.setFieldValue('email', dataResult.email || '');
    }
    setLoading(false);
  }


  const searchVendor = useCallback(debounce(getDropdownVendor, 300), []);

  useEffect(() => {
    getDropdownStore();
    getDataRoles();

  }, []);

  useEffect(() => {
    if (idParams) {
      getDropdownVendor(accountStore.accountDetail.data?.vendorNumber);
    } else {
      getDropdownVendor();
    }
    let period = props.form.getFieldValue('periodTime');
    console.log({ period })


  }, [idParams, accountStore.accountDetail.data])


  return (
    <>
      {/* Tên nhân viên/Nhà cung cấp */}
      <SearchItem
        name={'isSupplier'}
        label={'Tài khoản nhà cung cấp'}
        valuePropName='checked'
      >
        <Checkbox onChange={(event) => {
          if (event.target.checked) {
            const findIdNCC = accountStore.dataDropdownRoles.data.filter(e => e.value === 'Nhà cung cấp').map(item => item.key).join();
            form.setFieldValue('listRoleId', findIdNCC);
            setNCCId(findIdNCC);
            form.setFieldValue("password", defaultPasswordNCC);
            form.setFieldValue("confirm", defaultPasswordNCC);
            setDropdownRoles(accountStore.dataDropdownRoles.data || []);
            setDisablePassword(true);
          } else {
            form.setFieldValue("password", "");
            form.setFieldValue("confirm", "");
            setDisablePassword(false);
            setNCCId("");
          }
          setVisibleNCC(!event.target.checked);
        }} disabled={idParams ? true : false} />
      </SearchItem>
      <div></div>
      <SearchItem
        rules={[{ required: !visibleNCC, message: 'Hãy chọn thông tin Nhà cung cấp"' }]}
        name={'vendorNumber'}
        label={'Nhà cung cấp'}
      >
        <Select
          allowClear
          options={listVendor.map(v => ({ value: v.key, label: v.value }))}
          filterOption={false}
          popupMatchSelectWidth={false}
          disabled={idParams ? true : visibleNCC}
          showSearch onSearch={(value) => searchVendor(value || undefined)}
          onChange={(value) => { fillVendorInformation(value); form.setFieldValue('userName', value) }}
        />
      </SearchItem>
      <SearchItem
        rules={[{ required: true, message: 'Hãy nhập thông tin cho trường "Tên nhân viên/Nhà cung cấp"' }]}
        name={'employeeName'}
        label={'Tên nhân viên/Nhà cung cấp'}
      >
        <CustomInput />
      </SearchItem>
      {/* tên tài khoản */}
      <SearchItem
        rules={[
          { required: true, message: 'Hãy nhập thông tin cho trường "Tên tài khoản"' },
          {
            validator: validateUsername,
          },
        ]}
        name={'userName'}
        label={t('permission.account.username')}
      >
        <CustomInput autoComplete='off' disabled={isCreateVendor ? false : idParams ? true : false} />
      </SearchItem>
      {/* Email */}
      <SearchItem
        name={'email'}
        label={'Email'}
        rules={[
          { required: true, message: 'Hãy nhập thông tin cho trường "Email"' },
          {
            validator: validateEmail,
          },
        ]}
      >
        <CustomInput />
      </SearchItem>
      {/* Nhà máy */}
      <SearchItem
        name={'listStoreId'}
        label={'Nhà máy'}
        rules={[{ required: true, message: 'Chưa chọn "Nhà máy".' }]}
      >
        {/* <Tree checkable treeData={treeData} /> */}
        <Checkbox.Group>
          <Space direction='vertical'>
            {listStore.map((data, index) => (
              <Checkbox key={index} value={data.storeId}>{data.storeName}</Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      </SearchItem>
      {/* Số điện thoại */}
      <SearchItem
        name={'phone'}
        label={"Số điện thoại"}
        rules={[
          {
            validator: validatePhone,
          },
        ]}
      >
        <Input />
      </SearchItem>
      {/*Tài khoản nhà cung cấp */}


      {/* mật khẩu */}
      {!idParams &&
        <SearchItem
          hasFeedback
          rootClassName='col-start-1'
          rules={[
            { required: true, message: 'Hãy nhập thông tin cho trường "Mật khẩu"' },
            {
              min: 8,
              message: 'Mật khẩu có độ dài tối thiểu 8 ký tự.',
            },
            {
              // pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@$#%]).{12,}$/,
              pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message: 'Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số hoặc 1 ký tự đặc biệt.',
            },
          ]}
          name={'password'}
          label={t('permission.account.password')}
          normalize={(value) => value.trim()}
        >
          <Input.Password className="rounded-none" disabled={disablePassword} />
        </SearchItem>}
      {isCreateVendor && !isUpdateVendor && (
        <SearchItem
          rootClassName='col-start-1'
          hasFeedback
          rules={[
            { required: true, message: 'Hãy nhập thông tin cho trường "Mật khẩu"' },
            {
              min: 8,
              message: 'Mật khẩu có độ dài tối thiểu 8 ký tự.',
            },
            {
              // pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@$#%]).{12,}$/,
              pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message: 'Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt.',
            },
          ]}

          name={'password'}
          label={t('permission.account.password')}
          normalize={(value) => value.trim()}
        >
          <Input.Password className="rounded-none" />
        </SearchItem>
      )}
      {/* xác nhận mật khẩu */}
      {idParams ? null : (
        <>
          <SearchItem
            hasFeedback
            dependencies={['password']}
            name={'confirm'}
            label={t('permission.account.confirm-password')}
            normalize={(value) => value.trim()}
            rules={[
              {
                required: true,
                message: t('message.please-check-password'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('message.password-incorrect')));
                },
              }),
            ]}
          >
            <Input.Password disabled={disablePassword} />
          </SearchItem>
        </>
      )}
      {isCreateVendor && (
        <SearchItem
          hasFeedback
          dependencies={['password']}
          name={'confirm'}
          label={t('permission.account.confirm-password')}
          normalize={(value) => value.trim()}
          rules={[
            {
              required: true,
              message: t('message.please-check-password'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('message.password-incorrect')));
              },
            }),
          ]}
        >
          <Input.Password />
        </SearchItem>
      )}

      {/* Nhóm người dùng */}

      <SearchItem
        hidden={isCreateVendor || isUpdateVendor ? true : false}
        rules={[{ required: true, message: `Chọn ít nhất 1 "Nhóm người dùng".` }]}
        name={'listRoleId'} label={'Nhóm người dùng'} rootClassName='col-start-1'>
        <Checkbox.Group className='w-full' >
          <div className='flex flex-col gap-2 mt-2'>
            {accountStore.dataDropdownRoles.data.map((data, index) => (
              <Checkbox onChange={(e) => {
                if (data.value === "Nhà cung cấp") {
                  form.setFieldValue('isSupplier', true);
                } else if (!e.target.checked && data.value !== "Nhà cung cấp") {
                  form.setFieldValue('isSupplier', false);
                }

              }} style={{
                display: data.value === 'Nhà cung cấp' && !NCCId ? 'none' : undefined
              }} key={index} value={data.key} disabled={!!NCCId}>{data.value}</Checkbox>
            ))}
          </div>
        </Checkbox.Group>
      </SearchItem>

      {/* Trạng thái */}
      <SearchItem hidden={idParams && !isCreateVendor ? false : true} name={'actived'} label={t('permission.account.status')}>
        <Radio.Group>
          <Radio value={true}>{t('radio-btn.active')}</Radio>
          <Radio value={false}>{t('radio-btn.in-active')}</Radio>
        </Radio.Group>
      </SearchItem>

      {/* <SearchItem
      rules={[{ required: true, message: `Chọn ít nhất 1 "Phòng ban".` }]}
      name={'listRoleId'} label={'Phòng ban'} rootClassName='col-start-1'
      valuePropName="checkedKeys"
      trigger="onCheck"
    >
      <Tree checkable treeData={treeData} />
    </SearchItem> */}
    </>
  )
}

export default observer(CreateUpdateAccountForm)