import { userPresenter, userStore } from 'stores/root_store';
import { BASE_API_AUTH, MVC_URL } from './constants';
import { clearUserSession, getAccessToken, getDeviceId, getExpiredTime, getRefreshToken, setToken } from './localStorageHelper';

import axios from 'axios';

interface Coordinates {
  lat: number;
  lon: number;
}

interface LocationData {
  address: {
    suburb?: string;
    city_district?: string;
    city?: string;
    town?: string;
    village?: string;
    road?: string;
  };
}

export const MaintainSession = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isParams = urlParams.get('username');
  const token = getAccessToken(); //get info token
  const currentPath = window.location.pathname;
  const expiredTime = Date.parse(getExpiredTime()); //lấy hạn sử dụng của token
  const dateNow = Date.now();




  if (process.env.NODE_ENV === 'production') {
    if (token) {
      // Nếu token hết hạn thì đăng xuất về trang login của web MVC
      if (!isParams) {
        if (dateNow > expiredTime) {
          // clearUserSession();
          userPresenter.signOut(userStore, getDeviceId(), true);
          window.location.assign(`${MVC_URL}/Permission/Auth/Login`);
        }
        //check token tồn tại hay không nếu có thì trả về trang chủ không thì trang login
        if (currentPath === '/login') {
          window.location.assign('/')
        }
      }


      if (currentPath === '/logout') {
        userPresenter.signOut(userStore, getDeviceId(), false);
        // clearUserSession();
        window.location.assign(`${MVC_URL}/Permission/Auth/Logout`);
        window.location.reload();
      }
    } else {
      // bắt language || return url (1 trong 2)
      // đổi ngôn ngữ trên web khi chưa đăng nhập
      // vì đổi ngôn ngữ liên quan đến mvc và mobile 
      if (!urlParams.get('lang')) {
        // nếu url == '/login?username=user&password=password thì không redirect
        if (!isParams) {
          if (currentPath === '/logout') {
            window.location.assign(`${MVC_URL}/Permission/Auth/Login`);
          } else window.location.assign(`${MVC_URL}/Permission/Auth/Logout`);
        }
      }
    }
  } else if (process.env.NODE_ENV === 'development') {
    if (token) {
      // Nếu token hết hạn thì đăng xuất về trang login của web MVC
      if (dateNow > expiredTime) {
        userPresenter.signOut(userStore, getDeviceId(), true);
        // clearUserSession();
        window.location.assign('/login');
        window.location.reload();

      }
      //check token tồn tại hay không nếu có thì trả về trang chủ không thì trang login
      if (currentPath === '/login') {
        window.location.assign('/');
      }
      if (currentPath === '/logout') {
        userPresenter.signOut(userStore, getDeviceId(), true);
        // clearUserSession();
        window.location.reload();
      }
    } else {
      window.location.assign('/login');

    }
  }
};

export const checkToken = () => {
  const token = getAccessToken();
  return token ? true : false;
};

export const setAuthHeader = () => {
  const token = getAccessToken();

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    addRefreshToken();
  }
};
export const removeAuthHeader = () => {
  delete axios.defaults.headers.common['Authorization'];
};

export const addRefreshToken = () => {
  let isRefreshing = false;
  const interceptor = axios.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error) => {
      const originalRequest = error.response.config;

      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && originalRequest.url === `${BASE_API_AUTH}/Permission/Auth/RefreshToken`) {
        userPresenter.signOut(userStore, getDeviceId(), true);
        // clearUserSession();
        // window.location.assign('auth/login');
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      // axios.interceptors.response.eject(interceptor);
      if (error.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          return await axios
            .post(`${BASE_API_AUTH}/Permission/Auth/RefreshToken`, {
              accessToken: getAccessToken(),
              refreshToken: getRefreshToken(),
            })
            .then(({ data }) => {
              const result = data;
              setToken(result.data.accessToken);
              axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.accessToken.token}`;
              return axios(originalRequest);
            })
            .catch((error) => {
              return Promise.reject(error);
            })
            .finally(() => {
              isRefreshing = false;
            });
        }
      }
    },
  );
};



const getCoords = (): Promise<Coordinates> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ lat: latitude, lon: longitude });
        },
        (error) => {
          reject(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0 // ensures that the geolocation request is not cached
        }
      );
    } else {
      reject(new Error('Geolocation is not supported by this browser.'));
    }
  });
};

export const getCoordsFromLocalStorage = () => {
  const coords: { lat: number; lon: number } = JSON.parse(localStorage.getItem('coords') || '{}');
  return coords ? coords : null;
};

// Function to get the current location details using Nominatim API
export const getCurrentLocation = async (): Promise<string> => {
  try {
    // const coords = await getCoords();
    // const latitude = coords.lat;
    // const longitude = coords.lon;

    const latitude = getCoordsFromLocalStorage()?.lat;
    const longitude = getCoordsFromLocalStorage()?.lon;

    // Make API call to get location details
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=vi`;
    const response = await fetch(url);
    const data: LocationData = await response.json();

    // Log the location data
    console.log({ data });

    // Extract district and city from the data (if available)

    const address = `${data.address.road}, ${data.address.suburb}, ${data.address.city}`;

    return address;
  } catch (error) {
    console.error('Error getting location:', error);
    return 'Unknown';
  }
};

export const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  let device = 'Unknown Device';

  if (/Windows NT/.test(userAgent)) {
    device = 'Windows';
  } else if (/Macintosh/.test(userAgent)) {
    device = 'Mac';
  } else if (/iPhone/.test(userAgent)) {
    device = 'iPhone';
  } else if (/iPad/.test(userAgent)) {
    device = 'iPad';
  } else if (/Android/.test(userAgent)) {
    device = 'Android';
  } else if (/Linux/.test(userAgent)) {
    device = 'Linux';
  }

  return device;
}


