import { Customer, CustomerByPhone, CustomerDetail, ICustomerServices } from 'services/RawData/customer_services';
import { observable, runInAction } from 'mobx';

import { DataResponse } from '../../services/response';

export type customerStore = {
  // Type default response search customer
  listCustomer: DataResponse<Array<Customer>>;
  detailCustomer: DataResponse<CustomerDetail | undefined>;
  setDetailCustomer: CustomerDetail | undefined;
  stateCustomerTypeSelect: string;
  crudResponse: DataResponse<any>;
  getCustomerByPhone: DataResponse<CustomerByPhone>;

  // State global
  isLoading: boolean;
  isOpenSearchBox: boolean;
};

export interface ICustomerPresenter {
  createStore(): customerStore;
  // Search list
  searchListCustomer(store: customerStore, data: object): Promise<void>;
  // get/set Customer by id
  getCustomerById(store: customerStore, id: string): Promise<void>;
  setDetailCustomer(store: customerStore, data: CustomerDetail): Promise<void>;
  setCustomerTypeSelect(store: customerStore, typeCustomer: string): Promise<void>;

  // Create customer
  createCustomer(store: customerStore, data: object): Promise<void>;
  // Update customer
  updateCustomer(store: customerStore, data: object): Promise<void>;
  // Get Customer By Phone
  getCustomerByPhone(store: customerStore, phone: string): Promise<void>;

  // State loading global
  setLoading(store: customerStore, loading: boolean): Promise<any>;
  // State searchbox global
  setOpenSearchBox(store: customerStore, isOpen: boolean): Promise<any>;

  // Clear đi data customer by phone khi kết thúc cuộc gọi để cuộc gọi sau có thể bắt được data mới
  resetGetCustomerByPhone(store: customerStore): Promise<void>;

  resetCrudResponse(store: customerStore): void;
}

export function createCustomerPresenter({
  customerServices,
}: {
  customerServices: ICustomerServices;
}): ICustomerPresenter {
  return {
    createStore: () =>
      observable({
        listCustomer: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        detailCustomer: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        setDetailCustomer: undefined,
        stateCustomerTypeSelect: 'C',
        crudResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
        getCustomerByPhone: {
          data: {
            type: '',
            profileList: undefined,
            profileDetail: undefined,
          },
          message: '',
          isSuccess: false,
        },
        isLoading: false,
        isOpenSearchBox: false,
      }),

    // Search list Customer
    searchListCustomer: async (store, data) => {
      try {
        const result = await customerServices.searchListCustomer(data);
        runInAction(() => {
          store.listCustomer.data = result.data;
          store.listCustomer.isSuccess = result.isSuccess;
          store.listCustomer.message = result.message;
          store.listCustomer.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get customer by id
    getCustomerById: async (store, id) => {
      try {
        const result = await customerServices.getCustomerById(id);
        runInAction(() => {
          store.detailCustomer.data = result.data;
          store.detailCustomer.isSuccess = result.isSuccess;
          store.detailCustomer.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // create customer
    createCustomer: async (store, data) => {
      try {
        const result = await customerServices.createCustomer(data);
        runInAction(() => {
          store.crudResponse.data = result.data;
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Update customer
    updateCustomer: async (store, data) => {
      try {
        const result = await customerServices.updateCustomer(data);
        runInAction(() => {
          store.crudResponse.data = result.data;
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Get customer by phone
    getCustomerByPhone: async (store, phone) => {
      try {
        const result = await customerServices.getCustomerByPhone(phone);
        runInAction(() => {
          store.getCustomerByPhone.data = result.data;
          store.getCustomerByPhone.isSuccess = result.isSuccess;
          store.getCustomerByPhone.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * ============== STORE NOT API =================
     * */
    setDetailCustomer: async (store, data) => {
      try {
        runInAction(() => {
          store.setDetailCustomer = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Select loại khách hàng
    setCustomerTypeSelect: async (store, typeCustomer) => {
      try {
        runInAction(() => {
          store.stateCustomerTypeSelect = typeCustomer;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // State loading global
    setLoading: async (store, loading) => {
      try {
        runInAction(() => {
          store.isLoading = loading;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // State open search box global
    setOpenSearchBox: async (store, isOpen) => {
      try {
        runInAction(() => {
          store.isOpenSearchBox = isOpen;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Reset data response by phone
    resetGetCustomerByPhone: async (store) => {
      try {
        runInAction(() => {
          store.getCustomerByPhone.data = {
            type: '',
            profileList: undefined,
            profileDetail: undefined,
          };
          store.getCustomerByPhone.isSuccess = false;
          store.getCustomerByPhone.message = '';
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Hàm resetCrudResponse để clear đi đống dữ liệu cũ trả về trước đó
    resetCrudResponse(store) {
      runInAction(() => {
        store.crudResponse.data = [];
        store.crudResponse.isSuccess = false;
        store.crudResponse.message = '';
      });
    },
  };
}
