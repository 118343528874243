import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { District, IDistrictServices } from 'services/RawData/district_services';

export type districtStore = {
  // Type default response search district
  searchDistrictResponse: DataResponse<Array<District>>;
  detailDistrict: DataResponse<District | undefined>;
  listDistrict: DataResponse<Array<District>>;
  crudResponse: DataResponse<any>;
};

export interface IDistrictPresenter {
  createStore(): districtStore;
  // Search district
  searchDropdownDistrict(store: districtStore, provinceId: string, keyword?: string): Promise<void>;
  // Search list province
  searchListDistrict(store: districtStore, data: object): Promise<void>;
  // create new District
  createDistrict(store: districtStore, data: object): Promise<void>;
  // update new District
  updateDistrict(store: districtStore, data: object): Promise<void>;
  // get District by id
  getDistrictById(store: districtStore, id: string): Promise<void>;
  // delete
  deleteDistrict(store: districtStore, id: string): Promise<void>;
}

export function createDistrictPresenter({
  districtServices,
}: {
  districtServices: IDistrictServices;
}): IDistrictPresenter {
  return {
    createStore: () =>
      observable({
        searchDistrictResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        detailDistrict: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        listDistrict: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        crudResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
      }),
    // Search district
    searchDropdownDistrict: async (store, provinceId, keyword) => {
      try {
        const result = await districtServices.searchDropdownDistrict(provinceId, keyword);
        runInAction(() => {
          store.searchDistrictResponse.data = result.data;
          store.searchDistrictResponse.isSuccess = result.isSuccess;
          store.searchDistrictResponse.message = result.message;
          store.searchDistrictResponse.paging = {
            totalCount: result.paging?.pagesCount,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Search list District
    searchListDistrict: async (store, data) => {
      try {
        const result = await districtServices.searchListDistrict(data);
        runInAction(() => {
          store.listDistrict.data = result.data;
          store.listDistrict.isSuccess = result.isSuccess;
          store.listDistrict.message = result.message;
          store.listDistrict.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // create new District
    createDistrict: async (store, data) => {
      try {
        const result = await districtServices.createDistrict(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // update new District
    updateDistrict: async (store, data) => {
      try {
        const result = await districtServices.updateDistrict(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // delete
    deleteDistrict: async (store, id) => {
      try {
        const result = await districtServices.deleteDistrict(id);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get district by id
    getDistrictById: async (store, id) => {
      try {
        const result = await districtServices.getDistrictById(id);
        runInAction(() => {
          store.detailDistrict.data = result.data;
          store.detailDistrict.isSuccess = result.isSuccess;
          store.detailDistrict.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
