import { observable, runInAction } from 'mobx';
import i18n from 'translation/i18n';


export interface ThemeModel {
  name: string;
  color: string;
}

export type UIStore = {
  // ẩn hiện sidebar
  sidebarOpen: boolean;
  submenuOpen: {
    open: boolean;
    id: string;
    childId: string;
    active: string;
  };
  theme: ThemeModel;
  language: string;

  openModalAuthorize: boolean;
  messageRefreshError: string;


};

export interface IUIPresenter {
  createStore(): UIStore;
  setSidebarOpen(store: UIStore, open: boolean): Promise<void>;
  setSubmenuOpen(store: UIStore, id: string, childId?: string, active?: string, open?: boolean): Promise<void>;
  // set theme => lưu theme vào store
  setTheme(store: UIStore, theme: ThemeModel): Promise<void>;
  setLanguage(store: UIStore, language: string): Promise<void>;
  resetSubmenuOpen(store: UIStore): void;

  // thông báo và Refresh token 
  setOpenModalAuthorize(store: UIStore, open: boolean): Promise<void>;
  setMessageRefreshError(store: UIStore, message: string): Promise<void>;
}


export function createUIPresenter(): IUIPresenter {
  return {
    createStore: (): UIStore =>
      observable({
        sidebarOpen: false,
        submenuOpen: {
          open: false,
          id: '',
          childId: '',
          active: '',
        },
        // lấy theme từ localStorage neueds không có lấy mặc định
        theme: localStorage.getItem('theme') && typeof JSON.parse(localStorage.getItem('theme') + '') !== 'string' ? JSON.parse(localStorage.getItem('theme') || '{}') : {
          name: 'gray',
          color: '#354A5F'
        },
        language: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language') || '') : 'vi',

        openModalAuthorize: false,
        messageRefreshError: '',
      }),

    setSidebarOpen: async (store: UIStore, open: boolean) => {
      try {
        runInAction(() => {
          store.sidebarOpen = open;
        });
      } catch (error) {
        console.log(error);
      }
    },

    setSubmenuOpen: async (store: UIStore, id: string, childId?: string, active?: string, open?: boolean) => {
      try {
        runInAction(() => {
          if (store.submenuOpen.id === id) {
            if (!active) {
              store.submenuOpen.open = !store.submenuOpen.open;
            }
          } else {
            if (active) {
              store.submenuOpen.id = id;
              store.submenuOpen.open = false;
            } else {
              store.submenuOpen.id = id;
              store.submenuOpen.open = true;
            }
          }
          store.submenuOpen.open = open !== undefined ? open : store.submenuOpen.open;
          store.submenuOpen.childId = childId ? childId : '';
          store.submenuOpen.active = active !== undefined ? active : store.submenuOpen.active;
        });
      } catch (err) {
        console.log(err);
      }
    },
    resetSubmenuOpen: (store) => {
      runInAction(() => {
        store.submenuOpen = {
          open: false,
          id: '',
          childId: '',
          active: '',
        };
      });
    },

    setTheme: async (store: UIStore, theme) => {
      try {
        runInAction(() => {
          store.theme = theme;
        });
      } catch (error) {
        console.log(error);
      }
    },
    setLanguage: async (store: UIStore, language) => {
      try {
        runInAction(() => {
          i18n.changeLanguage(language)
          localStorage.setItem('language', JSON.stringify(language));
          store.language = language;
        });
      } catch (error) {
        console.log(error);
      }
    },


    // toggle modal authorize when call api refresh token get error
    setOpenModalAuthorize: async (store, open) => {
      try {
        runInAction(() => {
          store.openModalAuthorize = open;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // set message error when call api refresh token
    setMessageRefreshError: async (store, message) => {
      try {
        runInAction(() => {
          store.messageRefreshError = message;
        });
      } catch (error) {
        console.log(error);
      }
    },

  };
}
