import { ROUTES } from "base/routes/routes";
import { userPresenter, userStore } from "stores/root_store";

export const getAccessToken = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.token : null;
};

export const getExpiredTime = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.expiredTime : null;
};

export const getRoles = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.roles : null;
};
export const getRole = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.role : null;
};

export const getRoleName = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.roleName : null;
};

export const getPlantCode = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.plantCode : null;
};
export const getPlantName = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.plantName : null;
};
export const getCompanyId = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.companyId : null;
};

export const getAccountId = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.accountId : null;
};

export const getsaleOrg = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.saleOrgCode : null;
};
export const getsaleOrgName = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.saleOrgName : null;
};

export const getRefreshToken = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.refreshToken : null;
};

export const getUserName = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');

  return userSession.userName || "";
};

export const getIsResetPassword = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');

  return userSession.isResetPassword || false;
};

export const getFullName = () => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession.fullName || "";
};

export const getUserSession = () => {
  return JSON.parse(localStorage.getItem('user_session') || '{}');
};

export const getDeviceId = () => {
  const deviceId = JSON.parse(localStorage.getItem('deviceId') || 'null');
  return deviceId;
};

export const getFormCompleted = () => {
  return JSON.parse(localStorage.getItem('form_complete') || '[]') as string[];
}

export const getIsShowDashboard = (): boolean => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.isShowDashboard : false;
};

export const getIsShowChooseModule = (): boolean => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  return userSession ? userSession.isShowChooseModule : false;
};

export const getVendorCode = (): string => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}')
  return userSession.vendorCode || "";
}

export const getVendorName = (): string => {
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}')
  return userSession.vendorName || "";
}

export const setFormCompleted = (arr: string[]) => {
  // return JSON.parse(localStorage.getItem('form_complete') || '[]')
  localStorage.setItem('form_complete', JSON.stringify(arr));
}


export const setUserSession = (userSession: any) => {
  localStorage.setItem('user_session', JSON.stringify(userSession) || '{}');
};

export const setToken = (accessToken: any) => {
  // console.log(accessToken);
  const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
  userSession.token = accessToken.token;
  userSession.refreshToken = accessToken.refreshToken;
  localStorage.setItem('user_session', JSON.stringify(userSession));
};

export const getThemeColorCode = () => {
  const userSession = JSON.parse(localStorage.getItem('theme') || '{}');
  return userSession.color;
}



export const clearUserSession = () => {
  // const currentPath = window.location.pathname;
  // if (currentPath !== ROUTES.CHANGE_PASSWORD_AFTER_90DAYS.LINK) {
  localStorage.removeItem('user_session');
  localStorage.removeItem('logoutTime');
  userPresenter.setCurrentUser(userStore, null);
  // }
}
