import { Modal } from 'antd'
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import Spreadsheet, { CellBase, Matrix } from 'react-spreadsheet';
import CustomButton from 'base/ui/components/Button';
import { uniq } from 'lodash';

const initSheet = [
    [undefined],
    [undefined],
    [undefined],
    [undefined],

]

type SearchMultiplePopupProps = {
    title: string;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onOk: (values: string) => void;
    onReset: () => void
}

export type SearchMultiplePopupRef = {
    clearData: () => void;
}


const SearchMultiplePopup = forwardRef<SearchMultiplePopupRef, SearchMultiplePopupProps>((props, ref) => {
    const { open, onOpenChange, onOk, onReset, title } = props
    const [isOK, setIsOk] = useState(false)
    const [dataSheet, setDataSheet] = useState<Matrix<CellBase<any>>>(initSheet)

    const hadleClear = () => {
        setIsOk(false)
        setDataSheet(initSheet)
    }

    useImperativeHandle(ref, () => ({
        clearData: hadleClear
    }))

    return (
        <Modal
            open={open}
            title={<p className='uppercase text-base'>Tìm kiếm {title}</p>}
            onCancel={() => {
                if (!isOK) {
                    setDataSheet(initSheet)
                }
                onOpenChange(false)
            }}
            footer={false}
        >
            <div className="max-h-[400px] w-full overflow-y-auto">
                <Spreadsheet
                    className="w-full"
                    data={dataSheet}
                    onChange={setDataSheet}
                />
            </div>
            <div className="flex gap-default justify-end mt-3 -mb-3">
                <CustomButton
                    onClick={() => {
                        setIsOk(false)
                        setDataSheet(initSheet)
                        onReset()
                        // onOpenChange(false)
                    }}
                >
                    Reset
                </CustomButton>
                <CustomButton
                    type="primary"
                    onClick={() => {
                        setIsOk(true)
                        const dataPO = dataSheet.flatMap(d => (d.at(0) === undefined || d.at(0)?.value === '') ? [] : d).map(d => d?.value)
                        const removeDuplicates = uniq(dataPO).join(',')
                        onOk(removeDuplicates)
                        onOpenChange(false)
                    }}
                >
                    Đồng ý
                </CustomButton>
            </div>
        </Modal>
    )
})

export default SearchMultiplePopup