import React, { useState, useEffect } from 'react';
import { Spin, Steps, Tooltip, Modal, Button, Card, Typography, List, Space, Divider, Tag } from 'antd';
import { HiOutlineLogin, HiOutlineDesktopComputer, HiOutlineLocationMarker } from 'react-icons/hi';
import { CgDetailsMore } from 'react-icons/cg';
import { GoDotFill } from 'react-icons/go';
import { MdOutlineClose } from 'react-icons/md';
import dayjs from 'dayjs';
import classNames from 'classnames';
import moment from 'moment';
import { BsLaptopFill, BsPhoneFill } from 'react-icons/bs';
import PageWrapper from 'pages/Components/PageWrapper'
import { getDeviceId, getUserName } from 'utils/localStorageHelper';
import { SYSTEM_TITLE } from 'utils/constants';
import { accountPresenter, accountStore, services, userPresenter, userStore } from 'stores/root_store';
import { HistoryLogIn } from 'services/Main/common_services';
import { observer } from 'mobx-react';
import { DesktopOutlined, EnvironmentOutlined, InfoCircleOutlined, LogoutOutlined, MobileOutlined } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import BoxContainer from 'base/ui/components/BoxContainer';
import { Location } from 'base/ui/components/Icons';
import { IoLocationOutline } from "react-icons/io5"; import { FaLaptopCode } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { useRowStyle } from 'antd/es/grid/style';
const createLoginHistory = () => {
  return observer(() => {
    const currentDeviceId = getDeviceId();
    document.title = 'Lịch sử truy cập - ' + SYSTEM_TITLE;
    const [historyLogIn, setHistoryLogIn] = useState<HistoryLogIn[]>([]);
    const [loading, setLoading] = useState(false);
    const [itemsToShow, setItemsToShow] = useState(5);

    const handleLoadMore = () => {
      setItemsToShow(prev => prev + 5); // Load 5 more items
    };

    const getLogHistoryLogIn = async () => {
      setLoading(true)
      await userPresenter.getLogLogIn(userStore, { userName: getUserName(), paging: { pageSize: 50, pageIndex: 1 } });
      if (userStore.historyLogInRes.isSuccess) {
        setHistoryLogIn(userStore.historyLogInRes.data || []);

        const device = getDeviceId();
      }
      setLoading(false)
    }

    useEffect(() => {
      getLogHistoryLogIn();
    }, [])

    const WeirdFunction = (historyLogin: HistoryLogIn): HistoryLogIn => {
      // Tại  vì không bao giờ có chuyện 2 máy tính cùng lúc đăng nhập được, (máy tính sau đăng nhập sẽ làm máy tính trước đó đã đăng nhập bị đăng xuất), nên là mình hiểu ngầm là chỉ có mỗi máy tính hiện tại có trạng thái isActive là true, mấy máy tính khác không phải máy tính hiện tại trạng thái đăng nhập isActive là false hết, hàm này kiểm tra, nếu cái tài khoản đã truy cập đó không phải là máy tính hiện đang đăng nhập historyLogin.deviceId !== currentDeviceId, đồng thời tài khoản đã truy cập đó là máy tính chứ kh phải điện thoại !historyLogin.isPhone, đồng thời bằng cách nào đó mà tài khoản đã truy cập này lại có isActive là true thì sẽ set lại isActive của nó là false
      if (historyLogin.isActive && !historyLogin.isPhone && historyLogin.deviceId !== currentDeviceId) {
        return {
          ...historyLogin,
          isActive: false,
        }
      } else {
        return historyLogin
      }
    }





    return (
      <PageWrapper
        breadcrumb={[{
          text: "Lịch sử đăng nhập"
        }]}
      >
        <BoxContainer>
          <h2 className="font-bold text-lg mb-4">Các thiết bị đã đăng nhập</h2>
          <Spin spinning={loading}>
            <div className='flex gap-14'>
              <ul className='flex flex-col gap-8 pl-3 items-stretch-'>
                {historyLogIn.map(WeirdFunction).slice(0, itemsToShow).map((device, index) => (
                  <li key={index} className='flex gap-7 items-start '>
                    {device.isPhone ? <FaMobileAlt className="text-3xl" /> : <FaLaptopCode className="text-3xl" />}
                    <div>
                      <div className='flex gap-2 items-center'>
                        <p className='text-xs text-gray-600'>{moment(device.loginDate).format('DD/MM/YYYY hh:mm A')}</p>
                        <div className={`w-2 h-2 rounded-full ${device.isActive ? "bg-green-500" : "bg-slate-500"}`}></div>
                      </div>
                      <div className='flex justify-between mt-1'>
                        <p className='uppercase font-semibold'>{device.deviceName}</p>
                      </div>

                      <p className='text-gray-500 gap-1 flex mt-[2px] items-center'>
                        <IoLocationOutline className='text-lg' />
                        <span className='text-sm'> {device.location}</span>
                      </p>
                      {currentDeviceId === device.deviceId && <p className={`text-green-500 text-sm mt-2`}>Thiết bị này</p>}
                      {currentDeviceId !== device.deviceId && device.isActive && <button
                        onClick={async () => {

                          await userPresenter.getLogOutDeviceId(userStore, device.deviceId, getUserName())
                          // await userPresenter.signOut(userStore, device.deviceId, false);
                          await services.userServices.getLogOut(device.deviceId, false);

                          getLogHistoryLogIn();
                          // window.location.reload();
                        }}
                        className={`text-red-500 hover:brightness-110 transition duration-300 text-sm mt-2 flex gap-1`}>
                        <MdOutlineLogout className='text-lg' />  <span>Đăng xuất</span>
                      </button>}
                    </div>
                  </li>
                ))}
                {itemsToShow < historyLogIn.length && (
                  <button
                    onClick={handleLoadMore}
                    className='mt-4 px-4 py-2 text-gray-700 border-gray-700 border rounded transition duration-300'>
                    Xem thêm
                  </button>
                )}
              </ul>
              {/* <Button
                onClick={async () => {
                  setLoading(true)

                  await userPresenter.logOutAllDevice(userStore, historyLogIn.map(history => history.deviceId))
                  await Promise.all(historyLogIn.map(history => userPresenter.getLogOutDeviceId(userStore, history.deviceId, getUserName())))

                  setLoading(false)
                  getLogHistoryLogIn();
                }}
                danger className="bg-red-500 text-white py-4 px-7 rounded-lg text-lg font-medium hover:brightness-110 transition duration-300">Đăng xuất tất cả</Button> */}
            </div>
          </Spin>
        </BoxContainer>

      </PageWrapper>
    )
  });

}

export default createLoginHistory;

// return (
//   <div style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
//     <Typography.Title level={3}>Lịch sử đăng nhập</Typography.Title>
//     {historyLogIn.map((item) => (
//       <Card
//         key={item.id}
//         style={{
//           marginBottom: '20px',
//           borderRadius: '8px',
//           boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
//           backgroundColor: item.deviceId === currentDeviceId ? '#e6f7ff' : 'white',
//         }}
//       >
//         <Space direction="vertical" size="middle" style={{ width: '100%' }}>
//           <Space style={{ justifyContent: 'space-between', width: '100%' }}>
//             <Space>
//               {item.isPhone ? (
//                 <MobileOutlined style={{ fontSize: '24px' }} />
//               ) : (
//                 <DesktopOutlined style={{ fontSize: '24px' }} />
//               )}
//               <Typography.Text strong>{new Date(item.loginDate).toLocaleString()}</Typography.Text>
//               <Typography.Text type="secondary">
//                 <span style={{ color: 'green', fontSize: '12px' }}>●</span>
//               </Typography.Text>
//             </Space>
//           </Space>
//           <Typography.Text strong>{item.deviceName}</Typography.Text>
//           <Typography.Text type="secondary">
//             <EnvironmentOutlined /> {item.location}
//           </Typography.Text>
//           {item.deviceId === currentDeviceId && (
//             <Typography.Text style={{ color: 'green' }}>Thiết bị này</Typography.Text>
//           )}
//           <Space style={{ justifyContent: 'space-between', width: '100%' }}>
//             <Typography.Link href="#">
//               <InfoCircleOutlined /> Xem chi tiết...
//             </Typography.Link>
//             <Button type="primary" danger>
//               Đăng xuất thiết bị này
//             </Button>
//           </Space>
//         </Space>
//       </Card>
//     ))}
//     <div style={{ textAlign: 'right', marginTop: '20px' }}>
//       <Button type="primary" danger icon={<InfoCircleOutlined />}>
//         Đăng xuất tất cả thiết bị
//       </Button>
//     </div>
//   </div>
// );
