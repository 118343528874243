// link api đăng nhập
// export const BASE_API_AUTH = process.env.REACT_APP_AUTH_API + '/api/v1';
export const BASE_API_AUTH = process.env.REACT_APP_BASE_API + '/api/v1';
// link api masterData
// export const MASTER_DATA_API_URL = process.env.REACT_APP_MASTERDATA_API + '/api/v1';
export const MASTER_DATA_API_URL = process.env.REACT_APP_BASE_API + '/api/v1';

// api chức năng
// export const BASE_API_URL = process.env.REACT_APP_FUNC_API + '/api/v1';
export const BASE_API_URL = process.env.REACT_APP_BASE_API + '/api/v1';


// link web mvc phân quyền
export const MVC_URL = '';

// DB firebase realtime
export const FIREBASE_USERS = process.env.REACT_APP_FIREBASE_USERS;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECTID = process.env.REACT_APP_FIREBASE_PROJECTID;
export const FIREBASE_STORAGEBUCKET = process.env.REACT_APP_FIREBASE_STORAGEBUCKET;
export const FIREBASE_MESSAGINGSENDERID = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_DB_REALTIME = process.env.REACT_APP_FIREBASE_DB_REALTIME;


// mã hóa password và username
export const CRYPTED_KEY = '3fiYLDFb8yNHPqEyUGMe62fJpnyaNXuM';
// số item trên trang (mặc định)
export const PAGE_SIZE_DEFAULT = 10;
// Option table
export const PAGE_SIZE_OPTION = [10, 25, 50, 100, 1000, 10000];
//  Tên hệ thống
export const SYSTEM_TITLE = 'AVN MR System';
export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY';
export const DATE_FORMAT_POST = 'YYYY-MM-DD';





// =====================================================================================================================
// Hiện tại đang config bằng biến môi trường (.env) chỉ cần thêm các biến môi trường
// VD: .end.prd
// + và lấy ra sử dụng trong đây, hạn chế của việc này là không thể thay đổi sau khi build
// + vì chỉ định build:prd là build ra bảng producting , nếu muốn thay đổi môi trường thì build bảng khác


// nếu muốn đặt config ra 1 file riêng thì sử dụng đoạn code ở dưới
// đặt tạo file config.json ở thư mục public rồi dùng axios hoặc fetch để lấy config từ file
// có thể đổi config sau khi build


// const configUrl = process.env.PUBLIC_URL + 'config.json';
// let BASE_API_URL;
// async function getApiUrl() {
//     try {
//         const response = await axios.get(`${process.env.PUBLIC_URL}/config.json`);
//         const data = await response.data;
//         //   apiUrl = data.apiUrl;
//         BASE_API_URL = data.apiUrl

//     } catch (error) {
//         // Xử lý lỗi nếu cần
//         console.error('Lỗi khi lấy apiUrl:', error);
//         //   apiUrl = ''; // Giá trị mặc định hoặc xử lý lỗi
//     }
// }
// // Gọi hàm để lấy giá trị apiUrl
// getApiUrl();
// export { BASE_API_URL }

//============================================================================================================================






