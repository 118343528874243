import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const provinceRoutes = ({
  Province,
  AddProvince,
  UpdateProvince,
}: {
  Province: React.ComponentType;
  AddProvince: React.ComponentType;
  UpdateProvince: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Province />} />
    <Route path={ROUTES.MASTER_DATA.PROVINCE.CREATE} element={<AddProvince />} />
    <Route path={ROUTES.MASTER_DATA.PROVINCE.EDIT} element={<UpdateProvince />} />
  </Routes>
);
