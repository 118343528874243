import { observable, runInAction } from 'mobx';

import { DataResponse } from 'services/response';
import { Driver, IDriverServices } from 'services/RawData/driver_services';

export type DriverStore = {
    // Type default response search
    searchDriverResponse: DataResponse<Array<Driver>>;
    driverDetailResponse: DataResponse<Driver | undefined>;
    crudResponse: DataResponse<any>;
};

export interface IDriverPresenter {
    createStore(): DriverStore;
    // Search driver
    searchDriver(store: DriverStore, data: object): Promise<void>;
    // Update driver
    updateDriver(store: DriverStore, data: object): Promise<void>;
    // Create driver
    createDriver(store: DriverStore, data: object): Promise<void>;
    // Delete driver
    deleteDriver(store: DriverStore, driverId: string): Promise<void>;
    // get driver by driverId
    getDriverDetail(store: DriverStore, driverId: string): Promise<void>;
    // xuất excel template tạo tài xế
    exportCreateDriverTemplate(): Promise<void>;
}

export function createDriverPresenter({
    driverServices,
}: {
    driverServices: IDriverServices;
}): IDriverPresenter {
    return {
        createStore: () =>
            observable({
                searchDriverResponse: {
                    data: [],
                    paging: {
                        totalCount: 0,
                        totalPage: 0,
                        pageSize: 0,
                    },
                    message: '',
                    isSuccess: false,
                },
                driverDetailResponse: {
                    data: undefined,
                    message: '',
                    isSuccess: false,
                },
                crudResponse: {
                    data: null,
                    message: '',
                    isSuccess: false,
                },
            }),
        // Search driver
        searchDriver: async (store, data) => {
            try {
                const result = await driverServices.searchDriver(data);
                runInAction(() => {
                    store.searchDriverResponse = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        updateDriver: async (store, data) => {
            try {
                const result = await driverServices.updateDriver(data);
                runInAction(() => {
                    store.crudResponse.isSuccess = result.isSuccess;
                    store.crudResponse.message = result.message;
                });
            } catch (error) {
                console.log(error);
            }
        },
        createDriver: async (store, data) => {
            try {
                const result = await driverServices.createDriver(data);
                runInAction(() => {
                    store.crudResponse.data = result.data;
                    store.crudResponse.isSuccess = result.isSuccess;
                    store.crudResponse.message = result.message;
                });
            } catch (error) {
                console.error(error);
            }
        },
        deleteDriver: async (store, code) => {
            try {
                const result = await driverServices.deleteDriver(code);
                runInAction(() => {
                    store.crudResponse.isSuccess = result.isSuccess;
                    store.crudResponse.message = result.message;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getDriverDetail: async (store, EmployeeCode) => {
            try {
                const result = await driverServices.getDriverById(EmployeeCode);
                runInAction(() => {
                    store.driverDetailResponse = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // xuất excel template tạo tài xế
        exportCreateDriverTemplate: async () => {
            try {
                await driverServices.exportCreateDriverTemplate()
            } catch (error) {

            }
        }
    };
}
