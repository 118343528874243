import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const companyRoutes = ({
  Company,
  AddCompany,
  UpdateCompany,
}: {
  Company: React.ComponentType;
  AddCompany: React.ComponentType;
  UpdateCompany: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Company />} />
    <Route path={ROUTES.MASTER_DATA.COMPANY.CREATE} element={<AddCompany />} />
    <Route path={ROUTES.MASTER_DATA.COMPANY.EDIT} element={<UpdateCompany />} />
  </Routes>
);
