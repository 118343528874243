import { deleted, get, post, put } from 'utils/api';
import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';

export interface DataCatalog {
  stt: number;
  // mã loại danh mục
  catalogTypeCode: string;
  catalogCode: string;
  catalogName: string;
  catalogText_en: string;
  // tên loại danh mục
  catalogText_vi: string;
  orderIndex: number;
  // trạng thái
  actived: boolean;
  catalogId: string;
}

export type WorkType = {
  catalogCode: string;
  catalogName: string;
};

export interface ICatalogServices {
  // Search catalog
  searchDropdownCatalog(catalogtypeCode: string): Promise<DataResponse<Array<DataCatalog>>>;

  searchCatalog(data: object): Promise<DataResponse<Array<DataCatalog>>>;
  // Create catalog
  createCatalog(data: object): Promise<DataResponse<any>>;
  // Update catalog
  updateCatalog(data: object): Promise<DataResponse<any>>;
  // Delete catalog
  deleteCatalog(CatalogId: string): Promise<DataResponse<any>>;
  // get catalog by catalogTypeCode
  getCatalogById(catalogId: string): Promise<DataResponse<any>>;

  // get drop down loại công việc
  getDropdownWorkType(): Promise<DataResponse<WorkType[]>>;
}
export function createCatalogServices(): ICatalogServices {
  return {
    // Search catalog
    searchDropdownCatalog: async (catalogtypeCode) => {
      return (
        await get(MASTER_DATA_API_URL, `catalog/get-by-catalogtypecode?catalogtypecode=${catalogtypeCode}`)
      ).data;
    },
    searchCatalog: async (data) => {
      return (await post(MASTER_DATA_API_URL, `catalog/list`, data)).data;
    },
    // Create catalog
    createCatalog: async (data) => {
      return (await post(MASTER_DATA_API_URL, `catalog/create`, data)).data;
    },
    // Update catalog
    updateCatalog: async (data) => {
      return (await put(MASTER_DATA_API_URL, `catalog/update`, data)).data;
    },
    // Delete catalog
    deleteCatalog: async (CatalogId) => {
      return (
        await deleted(MASTER_DATA_API_URL, `catalog/delete/${CatalogId}`)
      ).data;
    },
    // get catalog by code
    getCatalogById: async (catalogId) => {
      return (
        await get(MASTER_DATA_API_URL, `catalog/get-by-id?${catalogId ? `catalogId=${catalogId}` : ''}`)
      ).data;
    },

    // get drop down loại công việc
    getDropdownWorkType: async () => {
      return (
        await get(MASTER_DATA_API_URL, `catalog/get-by-catalogtypecode`, {
          catalogtypecode: 'WorkType',
        })
      ).data;
    },
  };
}
