import catalogTypePage from '.';
import createAddUpdateCatalogType from './CreateUpdate';
import { catalogTypeRoutes } from './routes';

export const createCatalogTypePage = () => {
  const CatalogType = catalogTypePage();
  const AddCatalogType = createAddUpdateCatalogType();
  const UpdateCatalogType = createAddUpdateCatalogType();

  const CatalogPage = catalogTypeRoutes;
  return () => (
    <CatalogPage CatalogType={CatalogType} AddCatalogType={AddCatalogType} UpdateCatalogType={UpdateCatalogType} />
  );
};
