import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const branchRoutes = ({
  Stock,
  AddStock,
  UpdateStock,
}: {
  Stock: React.ComponentType;
  AddStock: React.ComponentType;
  UpdateStock: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Stock />} />
    <Route path={ROUTES.MASTER_DATA.STOCK.CREATE} element={<AddStock />} />
    <Route path={ROUTES.MASTER_DATA.STOCK.EDIT} element={<UpdateStock />} />
  </Routes>
);
