import { Drawer, DrawerProps, Form, FormProps, FormItemProps } from "antd";
import { Time } from "base/ui/components/Icons";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { uiStore } from "stores/root_store";

interface SearchBoxProps extends DrawerProps { }

function SearchBox(props: SearchBoxProps) {
  //=====================================CHUYỂN NGÔN NGỮ=====================================//
  const { t } = useTranslation();
  return (
    <Drawer
      {...props}
      title={
        <h2 className="font-medium text-[18px] p-0 leading-[26px] text-dark m-0">
          {t("search-form.title")}
        </h2>
      }
      placement={props.placement ? props.placement : "left"}
      width={props.width ? props.width : "100%"}
      contentWrapperStyle={{ boxShadow: "none" }}
      className={uiStore.theme.name + " !w-full lg:!w-[35%] " + props.className}
      styles={{ header: { padding: "12px 24px 12px 32px" }, body: { padding: "24px 24px 24px 32px" } }}
      closeIcon={
        <Time
          width="14"
          height="14"
          className={"hover:fill-primary fill-textColor"}
        />
      }
    >
      {props.children}
    </Drawer>
  );
}

interface SearchFormProps extends FormProps {
  children: React.ReactNode;
  column?: "col-1" | "col-2" | "col-3" | "col-4" | "col-5";
}

function SearchForm({ column = "col-2", ...props }: SearchFormProps) {
  //=====================================CHUYỂN NGÔN NGỮ=====================================//

  let gridCol;
  switch (column) {
    case "col-1":
      gridCol = "";
      break;
    case "col-2":
      gridCol = " grid-cols-2 ";
      break;
    case "col-3":
      gridCol = " grid-cols-3 ";
      break;
    case "col-4":
      gridCol = " grid-cols-4 ";
      break;
    case "col-5":
      gridCol = " grid-cols-5 ";
      break;
    default:
      break;
  }
  return (
    <Form
      {...props}
      layout={props.layout ? props.layout : "vertical"}
      className={
        uiStore.theme.name +
        " relative lg:grid flex flex-col gap-default gap-y-1 w-[100%] lg:w-full  [&_.ant-form-item]:!mb-0" +
        gridCol +
        props.className
      }
    >
      {props.children}
    </Form>
  );
}

interface SearchItemProps<Value = any> extends FormItemProps<Value> {
  start?: boolean;
  end?: boolean;
  col2?: boolean;
  extra?: ReactNode
  rootClassName?: string;
}
function SearchItem<Value = any>({
  start = false,
  end = false,
  col2 = false,
  extra,
  ...props
}: SearchItemProps<Value>) {
  //=====================================CHUYỂN NGÔN NGỮ=====================================//
  return (
    <div
      className={
        uiStore.theme.name +
        ` ${col2 ? "col-span-2" : ""} ${start ? " col-start-1" : ""}${props.hidden ? " hidden" : ""
        }` +
        ` ${end ? "col-end-3" : ""}` + 'relative' + ' ' + props.rootClassName
      }
    >
      <Form.Item<Value>
        {...props}
        label={
          props.label ? (
            <p className="font-semibold whitespace-pre-line">{props.label}</p>
          ) : undefined
        }
        className={"!m-0 [&_.ant-form-item-label]:!pb-0 " + props.className}
      >
        {props.children}
      </Form.Item>
      {extra && (<div className="absolute right-5 top-1/2 -translate-y-1/2">
        {extra}
      </div>)}
    </div>
  );
}

export { SearchBox, SearchForm, SearchItem };
