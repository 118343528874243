/* eslint-disable react-hooks/exhaustive-deps */

import * as Icon from 'base/ui/components/Icons';

import { Form, Radio, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { commonMasterDataPresenter, commonMasterDataStore, commonPresenter, commonStore, stockPresenter, stockStore, userStore } from 'stores/root_store';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { usePermission } from 'hooks/usePermission';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { CommonModel } from 'services/RawData/commonMasterData_services';
import { SYSTEM_TITLE } from 'utils/constants';
import { removeEmptyValues } from 'utils/transformHelper';

const createAddUpdateStock = () => {
  return observer(() => {
    const { t } = useTranslation();

    // Get data params from url
    const { id: idParams } = useParams();
    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('EDIT'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }

    const navigate = useNavigate();
    //==========================STATE===================//
    // Loading
    const [loading, setLoading] = useState(false);
    const [listStockType, setListStockType] = useState<CommonModel[]>([]);

    // Check button save and save continue
    let isSaveAndContinue = false;
    const [form] = Form.useForm();

    //==========================FUNCTION===================//

    // Call api detail stock
    const getDetailStock = async (stockId: string) => {
      await stockPresenter.getDetailStock(stockStore, stockId);
      if (stockStore.detailStockResponse.isSuccess) {
        form.setFieldsValue(stockStore.detailStockResponse.data);

        setLoading(false);
      } else {
        navigate('/MasterData/Warehouse');
      }
    };

    // Lấy danh sách khu vực
    const getListStockType = async () => {
      await commonMasterDataPresenter.getCommonDataByKey(commonMasterDataStore, 'StockType');
      if (commonStore.commonRespone.isSuccess) {
        setListStockType(commonStore.commonRespone.data);
      }
    };

    // Call api first access
    useEffect(() => {
      if (idParams) {
        getDetailStock(idParams);
      }
      getListStockType();
    }, []);

    // Handle create stock
    const onFinish = async (values: any) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        await stockPresenter.createDetailStock(stockStore, values);
        if (stockStore.createDetailStockResponse.isSuccess) {
          message.success(stockStore.createDetailStockResponse.message);

          // Redirect to search page stock (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Warehouse');
          }
          form.resetFields();
        }
      } else {
        const dataSendApi = {
          ...values,
          code: idParams,
        };

        // Call api update
        await stockPresenter.updateDetailStock(stockStore, dataSendApi);
        if (stockStore.updateDetailStockResponse.isSuccess) {
          message.success(stockStore.updateDetailStockResponse.message);

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Warehouse');
          }
        }
      }
      setLoading(false);
    };

    // initial value in form
    const initialValue = {
      actived: true,
      isValueManagement: true,
    };

    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }
    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('masterdata.stock.title'),
                link: '/MasterData/Warehouse',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/Warehouse/${idParams}` : '/MasterData/Warehouse/create',
              },
            ]}
            extra={
              <div className="custom__mobile--save flex space-x-2">
                {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
              </div>
            }
          />
          <BoxContainer fullHeight>
            {/* Form create/update */}
            <Spin spinning={loading}>
              <SearchForm initialValues={idParams ? {} : initialValue} form={form} onFinish={onFinish}>
                {/* Mã kho */}
                <SearchItem
                  label={t('masterdata.stock.stock-code')}
                  name="stockCode"
                  rules={[
                    { required: true, message: `${t('message.required-input')} ${t('masterdata.stock.stock-code')}` },
                  ]}
                >
                  <CustomInput disabled={idParams ? true : false} />
                </SearchItem>
                {/* Tên kho */}
                <SearchItem label={t('masterdata.stock.stock-name')} name="stockName">
                  <CustomInput />
                </SearchItem>
                {/* Trạng thái */}
                <SearchItem className="m-0 mb-2" name={'actived'} label={t('masterdata.stock.status')}>
                  <Radio.Group>
                    <Radio value={true}>{t('radio-btn.active')}</Radio>
                    <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                  </Radio.Group>
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateStock;
