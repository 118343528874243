import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';

export interface Roles {
  stt: number;
  rolesId: string;
  // mã nhóm
  rolesCode: string;
  // tên nhóm người dùng
  rolesName: string;
  // phân nhóm
  isEmployeeGroup: boolean;
  // thứ tự
  orderIndex: number;
  // trạng thái
  actived: boolean;
  departmentName: string;
  salesEmployeeResponses: salesEmployee[];
}

export type salesEmployee = {
  stt: number;
  actived: boolean;
  salesEmployeeCode: string;
  salesEmployeeName: string;
  userName: string;
};

export interface IRolesServices {
  // Search roles
  searchRoles(data: object): Promise<DataResponse<Array<Roles>>>;
  // Create roles
  createRoles(data: object): Promise<DataResponse<any>>;
  // Update roles
  updateRoles(data: object): Promise<DataResponse<any>>;
  // Delete id
  deleteRoles(RoleId: string): Promise<DataResponse<any>>;
  // get roles by id
  getRolesByCode(RoleId: string): Promise<DataResponse<any>>;
}
export function createRolesServices(): IRolesServices {
  return {
    // Search roles
    searchRoles: async (data) => {
      return (await post(MASTER_DATA_API_URL, `role/search`, data)).data;
    },
    // Create roles
    createRoles: async (data) => {
      return (await post(MASTER_DATA_API_URL, `role/create`, data)).data;
    },
    // Update roles
    updateRoles: async (data) => {
      return (await put(MASTER_DATA_API_URL, `role/update`, data)).data;
    },
    // Delete roles
    deleteRoles: async (RoleId) => {
      return (await deleted(MASTER_DATA_API_URL, `role/delete/${RoleId}`)).data;
    },
    // get roles by code
    getRolesByCode: async (RoleId) => {
      return (await get(MASTER_DATA_API_URL, `role/${RoleId}`)).data;
    },
  };
}
