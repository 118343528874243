import { deleted, downloadFilePost, get, post, put } from 'utils/api';

import { DataResponse } from '../response';
import { MASTER_DATA_API_URL } from 'utils/constants';
import { CommonModel } from './commonMasterData_services';

export type ProductModel = {
  stt: number;
  productId: string;
  productCode: string;
  productName: string;
  productType?: string;
  actived: boolean;
  createByName: string;
  createTime: string;
}


export interface IProductServices {
  // search product list
  searchProducts(data: object): Promise<DataResponse<Array<ProductModel>>>;
  // add an product
  updateProduct(data: object): Promise<DataResponse>;
  getDetailProduct(productId: string): Promise<DataResponse<ProductModel | undefined>>
  // cập nhật nhiều sản phẩm
  updateMultiProduct(data: object): Promise<DataResponse>;
  // export template 
  exportProductTemplate(data: object): Promise<void>
}

export function createProductServices(): IProductServices {
  return {
    searchProducts: async (data) => {
      return (await post(MASTER_DATA_API_URL, `product/search`, data)).data;
    },
    updateProduct: async (data) => {
      return (await put(MASTER_DATA_API_URL, `product/update`, data)).data;
    },
    getDetailProduct: async (id) => {
      return (await get(MASTER_DATA_API_URL, `product/${id}`)).data;
    },
    updateMultiProduct: async (data) => {
      return (await post(MASTER_DATA_API_URL, `product/update-multi-product`, data)).data;
    },
    exportProductTemplate: async (data) => {
      await downloadFilePost(MASTER_DATA_API_URL, `product/export-update-product-template`, data)
    }
  };
}
