import * as Icon from 'base/ui/components/Icons';

import { Form, Modal, Select, Tooltip, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { catalogTypePresenter, catalogTypeStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import CustomSelect from 'base/ui/components/CustomSelect';
import { DataCatalogType } from 'services/RawData/catalogType_services';
import { DataSearchCatalog } from '../model';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { HiSearch } from 'react-icons/hi';
import { ProColumns } from '@ant-design/pro-components';
import { checkPermission } from 'utils/permissionHelper';
import { dropdownStatus } from 'utils/dropdown';
import { observer } from 'mobx-react';
import { removeEmptyValues, trimStrings } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { SearchModel } from 'pages/model';


const { confirm } = Modal;

// LOẠI DANH MỤC DÙNG CHUNG
const catalogTypePage = () => {
  return observer(() => {
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    const navigate = useNavigate();
    // translate en/vn
    const { t } = useTranslation();

    const pagetitle = t('masterdata.catalogType.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;

    const [form] = Form.useForm();

    const [openDrawer, setOpenDrawer] = useState(false);

    // Columns table title
    const defaultColumn: ProColumns<DataCatalogType>[] = [
      // STT
      {
        title: t('masterdata.catalogType.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 40,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
      },
      // Loại danh mục
      {
        title: t('masterdata.catalogType.catalog-type'),
        dataIndex: 'catalogTypeCode',
        key: 'catalogTypeCode',

        width: 400,
        className: '!rounded-none !py-1',
        render: (_, record) => {
          return userStore.userPermission?.includes('EDIT') ? (
            <Link to={`/MasterData/CatalogType/${record.catalogTypeCode}`}>{record.catalogTypeCode}</Link>
          ) : (
            <div>{record.catalogTypeCode}</div>
          );
        },
      },
      // Tên loại danh mục
      {
        title: t('masterdata.catalogType.name-catalog-type'),
        dataIndex: 'catalogTypeName',
        key: 'catalogTypeName',

        width: 400,
        className: '!rounded-none !py-1',
      },
      // Trạng thái
      {
        title: t('masterdata.catalogType.actived'),
        dataIndex: 'actived',
        width: 80,
        key: 'actived',
        align: 'center',
        render: (text) => (
          <div className="w-full flex justify-center">
            {text ? (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            ) : (
              <Icon.CloseStatus width="15" className="!fill-red-500" />
            )}
          </div>
        ),
      },
      // Chức năng
      {
        title: t('masterdata.catalogType.function'),
        dataIndex: '',
        key: '',
        width: 100,
        className: '!rounded-none !py-1',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') ? (
                <Tooltip title={t('btn.edit')}>
                  <CustomButtonIcon color="#007BF1" onClick={() => navigate(`${record.catalogTypeCode}`)}>
                    <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                  </CustomButtonIcon>
                </Tooltip>
              ) : null}
              {userStore.userPermission?.includes('DELETE') ? (
                record.actived ? (
                  <Tooltip title={t('btn.delete')}>
                    <CustomButtonIcon
                      color="#FC5C5E"
                      onClick={() => {
                        showDeleteConfirm(record);
                      }}
                    >
                      <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                    </CustomButtonIcon>
                  </Tooltip>
                ) : (
                  <CustomButtonIcon color="#fff00" className="border-none outline-none cursor-default">
                    <Icon.Trash className="!fill-transparent" width="14" height="14" />
                  </CustomButtonIcon>
                )
              ) : null}
            </div>
          );
        },
      },
    ];
    // Custom default columns table with resizeable
    // const [columns, setColumn] = useState<ProColumns<DataCatalogType>[]>(defaultColumn);
    // Loading all page
    const [loading, setLoading] = useState(false);

    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    });

    // List data company
    const [listDataCatalogType, setListDataCatalogType] = useState<DataCatalogType[]>([]);
    // function open hide drawer ===========================================================

    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Call api search catalogType
    const getDataSearchCatalogType = async (dataSearch: DataSearchCatalog) => {
      await catalogTypePresenter.searchCatalogType(catalogTypeStore, dataSearch);
      if (catalogTypeStore.searchCatalogTypeResponse.isSuccess) {
        setListDataCatalogType(catalogTypeStore.searchCatalogTypeResponse.data);
      }
    };

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
        ...values,
      };
      removeEmptyValues(tempDataSearch);
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);

      await getDataSearchCatalogType(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };

    // initialValues of form search
    const initialValues = {
      actived: null,
    };

    // Call api delete
    const handleClickDeleteCatalog = async (catalogTypeCode: string) => {
      setLoading(true);
      if (catalogTypeCode) {
        await catalogTypePresenter.deleteCatalogType(catalogTypeStore, catalogTypeCode);
        if (catalogTypeStore.crudResponse.isSuccess) {
          message.success(catalogTypeStore.crudResponse.message);
          // Call list branch after change
          await getDataSearchCatalogType(dataSearch);
        } else {
          message.error(catalogTypeStore.crudResponse.message);
        }
      }
      setLoading(false);
    };

    // Ui modal confirm delete store
    const showDeleteConfirm = (item: DataCatalogType) => {
      confirm({
        title: `${t('btn.delete')} ${item.catalogTypeName}`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} ${item.catalogTypeName}`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDeleteCatalog(item.catalogTypeCode);
        },
        onCancel() { },
      });
    };

    // check if role permission
    useEffect(() => {
      const pagePermissions = () => {
        const per = checkPermission();
        console.log(per?.permiss);
      };
      pagePermissions();
    }, []);

    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/MasterData/CatalogType' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  onClick={() => {
                    navigate('create');
                  }}
                  type="primary"
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<DataCatalogType>
            columns={defaultColumn}
            handleResize={() => { }}
            rowKey="stt"
            size="small"
            bordered
            search={false}
            loading={loading}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const values = form.getFieldsValue();
              const data: any = await getDataSearchCatalogType({
                ...values,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: catalogTypeStore.searchCatalogTypeResponse.isSuccess,
              };
            }}
            dataSource={listDataCatalogType}
            scroll={{ y: 500 }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: catalogTypeStore.searchCatalogTypeResponse?.paging?.recordsTotal,
            }}

          />
        </BoxContainer>

        <SearchBox
          // tìm kiếm
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm
            initialValues={initialValues}
            form={form}
            onFinish={handleSearch}
            layout="vertical"
            column="col-1"
          >
            <button type="submit" hidden></button>
            <SearchItem label={t('masterdata.catalogType.catalog-type')} name={'catalogTypeCode'}>
              <CustomInput />
            </SearchItem>
            <SearchItem label={t('masterdata.catalogType.name-catalog-type')} name={'catalogTypeName'}>
              <CustomInput />
            </SearchItem>
            <SearchItem label={t('masterdata.catalogType.actived')} name={'actived'}>
              <CustomSelect options={dropdownStatus} placeholder={t('place-holder.all')} allowClear />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default catalogTypePage;
