import { Input, InputProps } from 'antd';
import { memo } from 'react';
interface CustomInputProps extends InputProps { }

function CustomInput(props: CustomInputProps) {
  return (
    <Input
      {...props}
      className={
        '!h-8 !rounded-sm !text-default hover:!border-primary focus:!border-primary focus:!shadow-none disabled:!border-[#d9d9d9] disabled:cursor-not-allowed text-gray-700 [&_span]:!text-default focus-visible:outline-none ' +
        ' [&_.ant-input-wrapper_.ant-input]:rounded-r-sm [&_.ant-input-group_.ant-input-group-addon]:rounded-l-sm [&_.ant-input:hover]:!border-primary [&_.ant-input:focus]:!border-primary [&_.ant-input:focus]:!shadow-none [&_.ant-input:disabled]:!border-[#d9d9d9] [&_.ant-input:disabled]:cursor-not-allowed [&_.ant-input]:text-gray-700  [&_.ant-input]:focus-visible:outline-none ' +
        props.className
      }
    />
  );
}

export default memo(CustomInput);
