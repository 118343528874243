import React, { ReactNode, useState } from 'react'
import { CardBox } from '../ListPO/createUpdate/cardBox'
import { SearchItem } from 'pages/Components/SearchBox'
import { Form, FormInstance, Input, InputNumber, Space } from 'antd'
import CustomButton from 'base/ui/components/Button';
import { HiDotsHorizontal } from 'react-icons/hi';
import ModalVendor from 'pages/synchPO/modalVendor';
import { sFormatNumber } from 'utils/formatHelper';

type GeneralProps = {
    form: FormInstance;
    isCopy?: boolean;
    extras?: ReactNode
}

const General: React.FC<GeneralProps> = (props) => {
    const { form, isCopy, extras } = props
    // Modal nhà cunng cấp 
    const [modalVendorOpen, setModalVendorOpen] = useState(false)

    return (
        <CardBox title='Thông tin chung' className='col-span-2'>
            <div className="grid grid-cols-4 gap-2 gap-x-8">
                {/* Số PR */}
                <SearchItem
                    name={"prNumber"}
                    label={"Số PR"}
                >
                    <Input disabled={isCopy} />
                </SearchItem>
                {/* Số PO */}
                <SearchItem
                    name={"purchaseOrderCode"}
                    label={"Số PO"}
                    rules={[{ required: true, message: 'Vui lòng nhập số PO.' }]}
                >
                    <Input disabled={isCopy} />
                </SearchItem>
                {/* Dung sai cho phép của PO */}
                <SearchItem
                    name={"tolerance"}
                    label={"Dung sai cho phép của PO"} >
                    <Input suffix="%" />
                </SearchItem>
                {/* Loại PO */}
                <SearchItem
                    name="documentType"
                    label={"Loại PO"} >
                    <Input />
                </SearchItem>
                {/* Nhà cung cấp */}
                <SearchItem
                    required={true}
                    label={'Mã nhà cung cấp'}
                    rootClassName='col-span-2'
                >
                    <Space.Compact block>
                        <Form.Item
                            name={'vendorNumber'}
                            noStyle
                            rules={[{ required: true, message: 'Vui lòng chọn Nhà cung cấp.' }]}
                        >
                            <Input
                                disabled={isCopy}
                                style={{ width: '100%' }}
                                placeholder="Mã nhà cung cấp"
                                allowClear
                            />
                        </Form.Item>
                        <CustomButton
                            disabled={isCopy}
                            onClick={() => {
                                setModalVendorOpen(true)
                            }}>
                            <HiDotsHorizontal />
                        </CustomButton>
                    </Space.Compact>
                </SearchItem>
                {/* Số lượng đặt hàng */}
                <SearchItem
                    name="totalQuantity"
                    label={"Số lượng đặt hàng"}
                    rootClassName='col-span-2' >
                    <InputNumber
                        controls={false}
                        style={{ width: '100%' }}
                        formatter={(value, info) => {
                            return sFormatNumber(Number(value)) as string;
                        }}
                    />
                </SearchItem>
                {/* Tên nhà cung cấp */}
                <SearchItem
                    name={"vendorName"}
                    label={"Tên nhà cung cấp"}
                    rules={[{ required: true, message: 'Vui lòng chọn Nhà cung cấp.' }]}
                    rootClassName='col-span-2'
                >
                    <Input disabled={isCopy} />
                </SearchItem>

                {extras}
                {/* Modal search nhà cung cấp */}
                <ModalVendor
                    openModal={modalVendorOpen}
                    onModalOpenChange={(open) => setModalVendorOpen(open)}
                    onSelected={(value) => {
                        form.setFieldValue('vendorNumber', value.vendor);
                        form.setFieldValue('vendorName', value.vendoR_NAME);
                    }} />
            </div>
        </CardBox>
    )
}

export default General