import { deleted, get, post, put } from 'utils/api';

import { DataResponse } from '../response';
import { MASTER_DATA_API_URL } from 'utils/constants';

export interface DataStock {
  stockId: string;
  stockName: string;
  stockCode: string;
  value: string;
  orderIndex: number;
  actived: boolean;

  stt: number;
  createBy: string;
  createTime: string;
}

export interface IStockServices {
  // Search all stock
  searchAllStock(keyword: string): Promise<DataResponse<Array<DataStock>>>;
  // Search stock
  searchStock(data: object): Promise<DataResponse<Array<DataStock>>>;
  // Get detail stock
  getDetailStock(stockId: string): Promise<DataResponse<DataStock>>;
  // Update detail stock
  updateDetailStock(params: object): Promise<DataResponse<boolean>>;
  // Create detail stock
  createDetailStock(data: object): Promise<DataResponse<boolean>>;
  // Delete stock
  deleteStock(stockId: string): Promise<DataResponse<boolean>>;
}
export function createStockServices(): IStockServices {
  return {
    // Search all stock
    searchAllStock: async (keyword) => {
      return (await get(MASTER_DATA_API_URL, `stock/list-select?${keyword ? `Keyword=${keyword}` : ''}`))
        .data;
    },
    // Search stock
    searchStock: async (data) => {
      return (await post(MASTER_DATA_API_URL, `stock/search`, data)).data;
    },
    // Get detail stock
    getDetailStock: async (stockId) => {
      return (await get(MASTER_DATA_API_URL, `stock/${stockId}`)).data;
    },
    // Update detail stock
    updateDetailStock: async (data) => {
      return (await put(MASTER_DATA_API_URL, `stock/update`, data)).data;
    },
    // Create stock
    createDetailStock: async (data) => {
      return (await post(MASTER_DATA_API_URL, `stock/create`, data)).data;
    },
    // Delete stock
    deleteStock: async (stockId) => {
      return (await deleted(MASTER_DATA_API_URL, `stock/delete/${stockId}`)).data;
    },
  };
}
