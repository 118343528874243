import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';

export type FunctionModel = Partial<{
    stt: number;
    functionId: string;
    functionName: string;
}>;


export interface IFunctionServices {
    searchFunction(data: object): Promise<DataResponse<Array<FunctionModel>>>;
    // detail
    getFunctionById(id: string): Promise<DataResponse<FunctionModel | undefined>>;
    // tạo Thao tác
    createFunction(data: object): Promise<DataResponse<null>>
    // sửa sanh mục
    updateFunction(data: object): Promise<DataResponse<null>>
    // Xóa
    deleteFunction(id: string): Promise<DataResponse<null>>
}
export function createFunctionServices(): IFunctionServices {
    return {
        // Search Thao tác
        searchFunction: async (data) => {
            return (await post(MASTER_DATA_API_URL, `function/search`, data)).data;
        },
        getFunctionById: async (id) => {
            return (await get(MASTER_DATA_API_URL, `function/${id}`)).data;
        },
        // Tạo Thao tác 
        createFunction: async (data) => {
            return (await post(MASTER_DATA_API_URL, `function/create`, data)).data;
        },
        // Sửa Thao tác
        updateFunction: async (data) => {
            return (await put(MASTER_DATA_API_URL, `function/update`, data)).data;
        },
        deleteFunction: async (id) => {
            return (await deleted(MASTER_DATA_API_URL, `function/delete/${id}`)).data;
        },
    };
}
