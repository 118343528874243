import { Radio, RadioProps } from 'antd';
import React from 'react';
import { uiStore } from 'stores/root_store';

const CustomRadio = (props: RadioProps) => {
  return (
    <Radio
      {...props}
      className={
        uiStore.theme.name +
        ' [&_.ant-radio-checked_.ant-radio-inner]:!bg-primary [&_.ant-radio-inner]:!text-primary ' +
        props.className
      }
    >
      {props.children}
    </Radio>
  );
};

export default CustomRadio;
