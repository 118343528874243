import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { DataStock, IStockServices } from 'services/RawData/stock_services';

export type stockStore = {
  // Type response search all
  searchAllStockResponse: DataResponse<Array<DataStock>>;
  // Type default response search
  searchStockResponse: DataResponse<Array<DataStock>>;
  // Type default response get detail
  detailStockResponse: DataResponse<DataStock | undefined>;
  // Type default response update detail
  updateDetailStockResponse: DataResponse<boolean>;
  // Type default response create detail
  createDetailStockResponse: DataResponse<boolean>;
  // Type default response delete
  deleteStockResponse: DataResponse<boolean>;
};

export interface IStockPresenter {
  createStore(): stockStore;
  // Search stock
  searchAllStock(store: stockStore, keyword: string): Promise<void>;
  // Searchstock
  searchStock(store: stockStore, data: object): Promise<void>;
  // Search detailstock
  getDetailStock(store: stockStore, stockId: string): Promise<void>;
  // Update detailstock
  updateDetailStock(store: stockStore, params: object): Promise<void>;
  // Create detailstock
  createDetailStock(store: stockStore, data: object): Promise<void>;
  // Delete stock
  deleteStock(store: stockStore, stockId: string): Promise<void>;
}

export function createStockPresenter({ stockServices }: { stockServices: IStockServices }): IStockPresenter {
  return {
    createStore: () =>
      observable({
        searchAllStockResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
        searchStockResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        detailStockResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        updateDetailStockResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
        createDetailStockResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
        deleteStockResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
      }),
    // Search stock all
    searchAllStock: async (store, keyword) => {
      try {
        const result = await stockServices.searchAllStock(keyword);
        runInAction(() => {
          store.searchAllStockResponse.data = result.data;
          store.searchAllStockResponse.isSuccess = result.isSuccess;
          store.searchAllStockResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Search list stock
    searchStock: async (store, data) => {
      try {
        const result = await stockServices.searchStock(data);
        runInAction(() => {
          store.searchStockResponse.data = result.data;
          store.searchStockResponse.isSuccess = result.isSuccess;
          store.searchStockResponse.message = result.message;
          store.searchStockResponse.paging = result.paging;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Search detail stock
    getDetailStock: async (store, stockId) => {
      try {
        const result = await stockServices.getDetailStock(stockId);
        runInAction(() => {
          store.detailStockResponse.data = result.data;
          store.detailStockResponse.isSuccess = result.isSuccess;
          store.detailStockResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Update detail stock
    updateDetailStock: async (store, data) => {
      try {
        const result = await stockServices.updateDetailStock(data);
        runInAction(() => {
          store.updateDetailStockResponse.data = result.data;
          store.updateDetailStockResponse.isSuccess = result.isSuccess;
          store.updateDetailStockResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Create detail stock
    createDetailStock: async (store, data) => {
      try {
        const result = await stockServices.createDetailStock(data);
        runInAction(() => {
          store.createDetailStockResponse.data = result.data;
          store.createDetailStockResponse.isSuccess = result.isSuccess;
          store.createDetailStockResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Delete stock
    deleteStock: async (store, stockId) => {
      try {
        const result = await stockServices.deleteStock(stockId);
        runInAction(() => {
          store.deleteStockResponse.data = result.data;
          store.deleteStockResponse.isSuccess = result.isSuccess;
          store.deleteStockResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
