import { useState, memo, useEffect } from 'react';
import * as rootStore from 'stores/root_store';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { MenuProps, Modal } from 'antd';
import { Bars, BarsSort, CircleUser, EnglandFlag, VietnamFlag } from 'base/ui/components/Icons';
import Module from './module';
import AcountSetting from './account';
import CustomSelect from 'base/ui/components/CustomSelect';
import { getIsShowChooseModule, getPlantCode, getPlantName, getRoleName, getsaleOrg } from 'utils/localStorageHelper';
import { decrypt } from 'utils/cryptedHelper';
import { useTranslation } from 'react-i18next';
import Notification from './notification'
import NewNotification from './newNotification';

const Header = () => {
  //=====================================CHUYỂN NGÔN NGỮ=====================================//
  const { t } = useTranslation();
  // show details
  const [show, setShow] = useState(false);
  // Lấy data danh sách công ty
  const [open, setOpen] = useState(false);
  // Lấy data công ty đã chọn
  const [selectOrg, setSelectOrg] = useState<string>(getsaleOrg());
  const [listCommonStore, setListCommonStore] = useState<{ label: string; value: string }[]>([])

  const handleShowSidebar = () => {
    if (rootStore.uiStore.sidebarOpen) {
      rootStore.uiPresenter.setSubmenuOpen(
        rootStore.uiStore,
        toJS(rootStore.uiStore.submenuOpen.id),
        toJS(rootStore.uiStore.submenuOpen.childId),
        toJS(rootStore.uiStore.submenuOpen.active),
        false,
      );
      rootStore.uiPresenter.setSidebarOpen(rootStore.uiStore, false);
    } else {
      rootStore.uiPresenter.setSidebarOpen(rootStore.uiStore, true);
    }
  };

  // Hàm lấy data danh sách công ty
  const getListCommonStore = async (accountId: string) => {
    if (!rootStore.commonStore.commonStore.length) {
      await rootStore.commonPresenter.getSeleOrgByAccountId(rootStore.commonStore, accountId);
      if (rootStore.commonStore.commonStore.length) {
        const apiResult = rootStore.commonStore.commonStore;
        const result = apiResult.map((item) => {
          return {
            label: item.value,
            value: item.key,
          };
        });
        setListCommonStore(result);
      }
    }
  };
  // Hàm lấy data công ty đã chọn
  const handleSelectOrg = (value: string) => {
    setSelectOrg(value);
  };

  const items: MenuProps['items'] = [
    {
      label: 'English',
      key: 'en',
      icon: <EnglandFlag />,
    },
    {
      label: 'Việt Nam',
      key: 'vi',
      icon: <VietnamFlag />,
    },
  ];

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('user_session') || '{}');
    const accountId = data.accountId;
    const loadData = async () => {
      await getListCommonStore(accountId);
    };
    // loadData();
  }, []);

  return (
    <div
      className={`${rootStore.uiStore.theme.name === 'gray' ? '!bg-[#354A5F]' : 'bg-primary'
        } h-[48px] relative top-0 left-0 w-full 
      bg-primary lg:bg-white shadow-[0px_1px_3px_rgba(0,0,0,0.1)] z-[1] flex items-center justify-between px-[16px] py-[9px] 
      before:absolute before:w-full before:h-full before:z-[2] before:bg-primary before:top-0 before:left-0 before:lg:hidden`}
    >
      {/* các nút show sidebar và show header trên giao diện mobile */}
      <div className="z-10 flex lg:hidden w-full h-full justify-between items-center">
        <span onClick={handleShowSidebar} className="cursor-pointer">
          <BarsSort width="19" className="!fill-white" />
        </span>
        <span className="uppercase text-xl font-semibold text-white">Dash</span>
        <span className="cursor-pointer" onClick={() => setShow(!show)}>
          <Bars width="24" className="!fill-white" />
        </span>
      </div>
      {/* Chọn module */}
      <div className={`hidden lg:inline-flex justify-center items-center gap-[6px]`}>
        {getIsShowChooseModule() && <Module />}
      </div>

      <div
        className={`absolute lg:relative flex justify-between lg:justify-end items-center top-full lg:top-0 left-0 w-full 
        lg:w-fit px-[11px] py-2 lg:p-0 bg-third lg:bg-white lg:gap-[22px] z-[1] ${rootStore.uiStore.theme.name === 'gray' && '!bg-hover'
          } 
        ${show ? 'translate-y-0' : 'translate-y-[-200%]'} lg:translate-y-0 duration-500`}
      >
        {/* Chi nhánh */}
        <div
          // onClick={() => setOpen(true)}
          className={
            `${rootStore.uiStore.theme.name === 'gray' ? 'bg-[#E3EDF6] text-primary' : 'bg-primary/70 text-white'}` +
            ' rounded-[2px] h-[30px] px-[15px] py-[4px] hidden lg:flex justify-center items-center gap-[8px] border-[1px] border-primary cursor-pointer'
          }
        >
          <span>
            <svg
              width="15"
              height="14"
              className={rootStore.uiStore.theme.name === 'gray' ? 'fill-primary' : 'fill-white'}
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9324 4.20002C11.9324 1.88301 10.0494 0 7.73241 0C5.4154 0 3.53239 1.88301 3.53239 4.20002C3.53239 6.64303 6.06641 9.93305 7.19341 11.2841C7.47341 11.6201 7.98442 11.6201 8.26442 11.2841C9.39842 9.93305 11.9324 6.64303 11.9324 4.20002ZM6.33242 4.20036C6.33242 3.43036 6.96242 2.80036 7.73242 2.80036C8.50243 2.80036 9.13243 3.43036 9.13243 4.20036C9.13243 4.97037 8.50943 5.60037 7.73242 5.60037C6.96242 5.60037 6.33242 4.97037 6.33242 4.20036ZM3.5324 13.9998C3.1474 13.9998 2.8324 13.6848 2.8324 13.2998C2.8324 12.9148 3.1474 12.5998 3.5324 12.5998H11.9324C12.3174 12.5998 12.6324 12.9148 12.6324 13.2998C12.6324 13.6848 12.3174 13.9998 11.9324 13.9998H3.5324Z"
              />
            </svg>
          </span>
          <p className="text-default m-0">
            {getPlantCode()} | {getPlantName()}
          </p>
        </div>

        {/* Role name  */}
        <div
          className={
            `${rootStore.uiStore.theme.name === 'gray' ? 'bg-[#E3EDF6] text-primary' : 'bg-primary/70 text-white'}` +
            ' rounded-[2px] h-[30px] px-[15px] py-[4px] flex justify-center items-center gap-[8px] border-[1px] border-primary'
          }
        >
          <span>
            <CircleUser width='15' className={rootStore.uiStore.theme.name === 'gray' ? '!fill-primary' : '!fill-white'} />
          </span>
          <p className="text-default m-0">
            {getRoleName()}
          </p>
        </div>

        {/* Thông báo */}
        <div>
          {/* <Notification /> */}
          <NewNotification />
        </div>
        {/* Chuyển ngôn ngữ */}
        <a className="hidden lg:block cursor-pointer" onClick={(e) => e.preventDefault()}>
          {rootStore.uiStore.language === 'en' ? <EnglandFlag /> : <VietnamFlag />}
        </a>
        {/* Avatar */}
        <AcountSetting />
      </div>
      <Modal
        title={t('message.select-saleorg')}
        open={open}
        onOk={async () => {
          await rootStore.userPresenter.login(rootStore.userStore, {
            userName: decrypt(localStorage.getItem('username')!),
            password: decrypt(localStorage.getItem('password')!),
            saleOrg: selectOrg,
            language: localStorage.getItem('language'),
          });
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
      >
        <CustomSelect
          options={listCommonStore}
          onChange={handleSelectOrg}
          value={selectOrg}
          // fieldNames={{ label: 'value', value: 'key' }}
          filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
          showSearch
          className={`w-full font-[400] text-[14px] m-0 !min-w-[240px] `}
        />
      </Modal>
    </div>
  );
};

export default memo(observer(Header));
