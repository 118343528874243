// import vendorPage from ".";
import createAddVendor from "./CreateUpdate";
import { VendorRoutes } from "./routes";
import vendorPage from ".";

export const createVendorPage = () => {
  const Vendor = vendorPage();
  const AddVendor = createAddVendor();
  const UpdateVendor = createAddVendor();

  const VendorPage = VendorRoutes;
  return () => (
    <VendorPage
      Vendor={Vendor}
      AddVendor={AddVendor}
      UpdateVendor={UpdateVendor}
    />
  );
};
