import * as Icon from 'base/ui/components/Icons';

import { Form, Modal, Select, Tooltip, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { commonMasterDataPresenter, commonMasterDataStore, rolesPresenter, rolesStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import CustomSelect from 'base/ui/components/CustomSelect';
import { DataSearchRoles } from '../model';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { HiSearch } from 'react-icons/hi';
import { ProColumns } from '@ant-design/pro-components';
import { Roles } from 'services/RawData/role_services';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { dropdownStatus } from 'utils/dropdown';
import { SearchModel } from 'pages/model';
import { CommonModel } from 'services/RawData/commonMasterData_services';

const { confirm } = Modal;

// Dự án này đổi nhóm người dùng được đổi tên thành phòng ban
// 

const initSearch = {
  paging: {
    pageIndex: 1,
    pageSize: PAGE_SIZE_DEFAULT,
    orderBy: '',
    orderByDesc: '',
  },
}

// Nhóm người dùng
const rolesPage = () => {
  return observer(() => {
    const { t } = useTranslation();

    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    const pagetitle = "Nhóm người dùng";
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);

    // Columns table title
    const defaultColumn: ProColumns<Roles>[] = [
      // stt
      {
        title: t('permission.roles.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 30,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
      },
      {
        title: 'Phòng ban',
        key: 'departmentName',
        dataIndex: 'departmentName',
        width: 120
      },
      //Mã nhóm
      {
        title: 'Mã nhóm',
        dataIndex: 'rolesCode',
        key: 'rolesCode',
        width: 120,
        className: '!rounded-none !py-1',
        render: (_, record) => {
          return userStore.userPermission?.includes('EDIT') ? (
            <Link to={`/Permission/Roles/${record.rolesId}`}>{record.rolesCode}</Link>
          ) : (
            <div>{record.rolesCode}</div>
          );
        },
      },
      // tên nhóm người dùng
      {
        title: "Tên nhóm người dùng",
        dataIndex: 'rolesName',
        key: 'rolesName',
        width: 230,
        className: '!rounded-none !py-1',
      },
      // thứ tự
      // {
      //   title: t('permission.roles.order'),
      //   dataIndex: 'orderIndex',
      //   key: 'orderIndex',
      //   width: 60,
      // },
      // trạng thái
      {
        title: t('permission.roles.status'),
        dataIndex: 'actived',
        width: 60,
        key: 'actived',
        render: (text) => (
          <div className="w-full flex justify-center">
            {text ? (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            ) : (
              <Icon.CloseStatus width="15" className="!fill-red-500" />
            )}
          </div>
        ),
      },
      // Chức năng
      {
        title: t('permission.roles.actions'),
        dataIndex: '',
        key: '',
        width: 60,
        className: '!rounded-none !py-1',
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') ? (
                <Tooltip title={t('btn.edit')}>
                  <CustomButtonIcon color="#007BF1" onClick={() => navigate(`${record.rolesId}`)}>
                    <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                  </CustomButtonIcon>
                </Tooltip>
              ) : <span className='w-[25px] h-[25px] bg-transparent'></span>}
              {userStore.userPermission?.includes('DELETE') && record.actived ? (
                <Tooltip title={t('btn.delete')}>
                  <CustomButtonIcon
                    color="#FC5C5E"
                    onClick={() => {
                      showDeleteConfirm(record);
                    }}
                  >
                    <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                  </CustomButtonIcon>
                </Tooltip>
              ) : <span className='w-[25px] h-[25px] bg-transparent'></span>}
            </div>
          );
        },
      },
    ];
    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<Roles>[]>(defaultColumn);
    // Loading all page
    const [loading, setLoading] = useState(false);

    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>(initSearch);

    // List data company
    const [listDataRoles, setListDataRoles] = useState<Roles[]>([]);
    const [dropdownDepartment, setDropdownDepartment] = useState<CommonModel[]>([]);

    // function open hide drawer ===========================================================

    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Call api search roles
    const getDataSearchRoles = async (dataSearch: DataSearchRoles) => {
      await rolesPresenter.searchRoles(rolesStore, dataSearch);
      if (rolesStore.searchRoleResponse?.isSuccess) {
        setListDataRoles(rolesStore.searchRoleResponse?.data);
      }
    };

    // lấy dropdown phòng ban
    const getDropdownDepartment = async () => {
      await commonMasterDataPresenter.getDropdownDepartment(commonMasterDataStore)
      if (commonMasterDataStore.commonRespone.isSuccess) {
        setDropdownDepartment(commonMasterDataStore.commonRespone.data)
      }
    }

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
        ...values,
      };
      removeEmptyValues(tempDataSearch);
      // set page 1 when search data
      setDataSearch(tempDataSearch);

      await getDataSearchRoles(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };

    // initialValues of form search
    const initialValues = {};

    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
      getDropdownDepartment()
    }, []);

    // Call api delete
    const handleClickDeleteCatalog = async (rolesId: string) => {
      setLoading(true);
      if (rolesId) {
        await rolesPresenter.deleteRoles(rolesStore, rolesId);
        if (rolesStore.crudResponse.isSuccess) {
          message.success(rolesStore.crudResponse.message);
          // Call list branch after change
          setDataSearch(initSearch)
          await getDataSearchRoles(initSearch);
        } else {
          message.error(rolesStore.crudResponse.message);
        }
      }
      setLoading(false);
    };

    // Ui modal confirm delete store
    const showDeleteConfirm = (item: Roles) => {
      confirm({
        title: `${t('btn.delete')} ${item.rolesName}`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} ${item.rolesName}`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDeleteCatalog(item.rolesId);
        },
        onCancel() { },
      });
    };

    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/Permission/Roles' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  onClick={() => {
                    navigate('create');
                  }}
                  type="primary"
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<Roles>
            columns={columns}
            loading={loading}
            rowKey="stt"
            size="small"
            bordered
            search={false}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const values = form.getFieldsValue();
              const data: any = await getDataSearchRoles({
                ...values,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: rolesStore.searchRoleResponse.isSuccess,
              };
            }}
            dataSource={listDataRoles}
            scroll={{ y: 500 }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: rolesStore.searchRoleResponse?.paging?.recordsTotal,
            }}
            options={false}
          />
        </BoxContainer>

        {/* form search */}
        <SearchBox // tìm kiếm
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm
            initialValues={initialValues}
            form={form}
            layout="vertical"
            onFinish={handleSearch}
            column="col-1"
          >
            {/* Mã nhóm */}
            <SearchItem label={"Mã nhóm"} name={'roleCode'}>
              <CustomInput />
            </SearchItem>
            {/* TÊN NHÓM NGƯỜI DÙNG */}
            <SearchItem label={"Tên nhóm người dùng"} name={'roleName'}>
              <CustomInput />
            </SearchItem>
            {/* Phòng ban */}
            <SearchItem
              name={'departmentId'} label={'Phòng ban'} >
              <Select options={dropdownDepartment.map((item) => ({
                label: item.value,
                value: item.key,
              }))}
                allowClear
              ></Select>
            </SearchItem>

            {/* TRẠNG THÁI */}
            <SearchItem label={"Trạng thái"} name={'actived'}>
              <CustomSelect placeholder={t('place-holder.all')} allowClear options={dropdownStatus} />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default rolesPage;
