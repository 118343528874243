import * as Icon from 'base/ui/components/Icons';

import { Form, Input, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { accountPresenter, accountStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import { SYSTEM_TITLE } from 'utils/constants';
import { getPlantCode } from 'utils/localStorageHelper';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const createResetPassword = () => {
  return observer(() => {
    const { t } = useTranslation();

    const pagetitle = "Reset Password";
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    // Get data params from url
    const { id: storeIdParams } = useParams();
    // Check button save and save continue
    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);

    // =============================================================================================================
    //===================================================FUNCTION==================================================//
    // Call api get detail in store
    const getDetailAccountById = async (accountId: string) => {
      await accountPresenter.getAccountById(accountStore, accountId);
      if (accountStore.accountDetail.isSuccess) {
        form.setFieldsValue(accountStore.accountDetail.data);
        setLoading(false);
      } else {
        navigate('/Permission/Account');
      }
    };

    // Handle create company ================================
    const onFinish = async (values: any) => {
      setLoading(true);
      await accountPresenter.resetPassword(accountStore, {
        ...values,
        accountId: storeIdParams,
        plantCode: getPlantCode()
      });
      if (accountStore.crudRes.isSuccess) {
        message.success(accountStore.crudRes.message);
        navigate('/Permission/Account');
      } else {
        message.error(accountStore.crudRes.message);
      }
      setLoading(false);
    };

    useEffect(() => {
      if (storeIdParams) {
        (async () => {
          getDetailAccountById(storeIdParams);
        })();
      } else {
        navigate('/Permission/Account');
      }
    }, []);

    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('permission.account.title'),
                link: '/Permission/Account',
              },
              { title: pagetitle, link: `/Permission/Account/ResetPassword/${storeIdParams}` },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  <CustomButton
                    type='primary'
                    loading={loading}
                    icon={<Icon.Download className="!fill-white " />}
                    htmlType="submit"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    {t('btn.save')}
                  </CustomButton>
                </div>
              </>
            }
          />

          {/* Form nhập tìm kiếm */}
          <BoxContainer>
            <SearchForm onFinish={onFinish}
              form={form} column='col-1'
              layout='horizontal'
              wrapperCol={{ span: 14 }}
              labelCol={{ span: 8 }} autoComplete='off'
              colon={false}
            >
              {/* tên tài khoản */}
              <SearchItem
                rules={[{ required: true, message: 'Hãy nhập thông tin cho trường tên tài khoản' }]}
                name={'userName'}
                label={t('permission.account.username')}
              >
                <CustomInput disabled />
              </SearchItem>
              {/* Mật khẩu mới */}
              <SearchItem
                hasFeedback
                normalize={(value) => value.trim()}
                rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mật khẩu mới' },
                {
                  min: 8,
                  message: 'Mật khẩu có độ dài tối thiểu 8 ký tự.',
                },
                {
                  // pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@$#%]).{12,}$/,
                  pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message: 'Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt.',
                },
                ]}
                name={'newPassword'}
                label={t('permission.account.new-password')}

              >
                <Input.Password className="rounded-none" />
              </SearchItem>

            </SearchForm>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createResetPassword;
