import React from 'react';

export type BoxContainerProps = {
  children: React.ReactNode;
  className?: string;
  fullHeight?: boolean;
  id?: string;
};

export default function BoxContainer({ fullHeight = false, ...props }: BoxContainerProps) {
  return (
    <div className={`pt-[12px] px-4 ${fullHeight ? 'flex-1' : ''}`}>
      <div
        id={props.id}
        className={
          'bg-white p-[12px] flex flex-col gap-default rounded-[2px] w-full ' +
          `${fullHeight ? 'h-full ' : ''}` +
          props.className
        }
      >
        {props.children}
      </div>
    </div>
  );
}
