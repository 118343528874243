import { ColumnsState, ProColumns } from '@ant-design/pro-components';
import { Form } from 'antd';
import { ColumnType } from 'antd/es/table';
// import { cloneDeep } from 'lodash';
import { TypeColumnsBase, TypeColumnsSystem, TypeColumnsUser } from 'pages/model';
// import { TypeColumnsBase, TypeColumnsSystem, TypeColumnsUser } from 'pages/report/BC8WorkOrderFinished/model';
import { useEffect, useRef, useState } from 'react';
import { reportSavedPresenter, reportSavedStore } from 'stores/root_store';

export const defaultColumnsState = {
  show: true,
  fixed: 'left' as 'left' | 'right' | undefined,
  order: undefined,
  disable: undefined,
};

export const useResizableColumns = <T>(
  columns: ProColumns<any>[],
  setColumn: React.Dispatch<React.SetStateAction<ProColumns<T>[]>>,
  constDataName: string,
) => {
  const templateReportModalRef = useRef<any>(null);

  let resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnType<T>) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const handleColumnsStateChange = (newColumnsState: Record<string, ColumnsState>) => {
    setColumnsState(newColumnsState);
  };

  // Form item
  const [form] = Form.useForm();

  // Report sample data
  // const [columnsState, setColumnsState] = useState<Record<string, ColumnsState>>({ stt: defaultColumnsState });
  const [columnsState, setColumnsState] = useState<Record<string, ColumnsState>>(() => {
    const dataTemp = {}
    columns.forEach((column, index) => {
      Object.assign(dataTemp, {
        [`${column.key}`]: {
          show: true,
          fixed: column.fixed,
          order: column.order,
          disable: column.disable,
        }
      })
    })
    return dataTemp
  });



  // Data report system and user
  const [systemReports, setSystemReports] = useState<TypeColumnsSystem[]>([]);
  const [userReports, setUserReports] = useState<TypeColumnsUser[]>([]);

  // Id report selected
  const [selectedReportId, setSelectedReportId] = useState('');
  // Is first access
  const [isFirstAccess, setIsFirstAccess] = useState(true);

  const [resizableColumnSelect, setResizableColumnSelect] = useState<any>([]);
  const [isResizablePresent, setIsResizablePresent] = useState(false);

  // State for TemplateReportModal component
  const templateReportModalProps = {
    constDataName,
    form,
    // setIsLoading,
    columnsState,
    setColumnsState,
    resizableColumns,
    systemReports,
    setSystemReports,
    userReports,
    setUserReports,
    resizableColumnSelect,
    setResizableColumnSelect,
    isResizablePresent,
    setIsResizablePresent,
    setSelectedReportId,
  };

  const handleResize =
    (index: number, id?: number) =>
      (e: any, { size }: any) => {
        setColumn((prevColumns) => {
          const nextColumns = [...prevColumns];
          // nextColumns[index] = {
          //   ...nextColumns[index],
          //   width: size.width,
          //   // @ts-ignore
          //   children: nextColumns[index].children
          //     ? // @ts-ignore
          //     nextColumns[index].children.map((child, i) => {
          //       if (id === i) {
          //         return {
          //           ...child,
          //           width: size.width,
          //         };
          //       }
          //       return child;
          //     })
          //     : undefined,
          // };
          Object.assign(nextColumns[index], {
            width: size.width,
            // @ts-ignore
            children: nextColumns[index].children
              ? // @ts-ignore
              nextColumns[index].children.map((child, i) => {
                if (id === i) {
                  return {
                    ...child,
                    width: size.width,
                  };
                }
                return child;
              })
              : undefined,
          });
          return nextColumns;
        });
      };

  useEffect(() => {
    const getReportTemplate = async () => {
      await reportSavedPresenter.getListReportTemplate(reportSavedStore, constDataName);
      if (reportSavedStore.listTemplateResponse.isSuccess) {
        const newData = reportSavedStore.listTemplateResponse.data.map((item) => ({
          id: item.reportTemplateId,
          value: JSON.parse(item.templateString),
          title: item.reportTemplateName,
          description: item.description,
          default: item.isDefault,
          resizable: JSON.parse(item.resizable),
        }));
        setUserReports(newData);
        // Show report default in first reload
        const reportDefault = newData.find((i: TypeColumnsBase) => i.default === true);

        if (reportDefault && isFirstAccess) {
          setIsResizablePresent(true);
          setResizableColumnSelect(reportDefault.resizable);

          setColumnsState(reportDefault.value);

          setSelectedReportId(reportDefault.id);
          setIsFirstAccess(false);
        }
      }
    };
    getReportTemplate();
  }, []);


  useEffect(() => {
    if (resizableColumnSelect) {
      if (isResizablePresent) {
        setColumn((prev) => {
          const tempColumn = [...prev]
          tempColumn.forEach(column => {
            const matchItem = resizableColumnSelect.find((d: any) => d.key === column.key)
            if (matchItem) {
              Object.assign(column, { width: matchItem.width })
              // @ts-ignore
              if (column.children) {
                Object.assign(column, {
                  // @ts-ignore
                  children: column.children.map((child: any, id: any) => ({
                    ...child,
                    width: matchItem.children.find((d: any) => d.key === child.key).width,

                  }))
                })
              }
            }
          })
          return tempColumn
        })
      }
    }

  }, [isResizablePresent]);

  useEffect(() => {
    // Update resizable columns when isResizablePresent changes
    if (isResizablePresent) {
      setResizableColumnSelect(
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: (column: ColumnType<T>) => ({
            width: column.width,
            onResize: handleResize(index),
          }),
        })),
      );

    }


  }, [columns]);



  return {
    resizableColumnSelect,
    isResizablePresent,
    handleResize,
    setResizableColumnSelect,
    setIsResizablePresent,
    templateReportModalProps,
    templateReportModalRef,
    selectedReportId,
    handleColumnsStateChange,
    columnsState,
    userReports,
  };
};
