import createCompany from '.';
import createAddUpdateCompany from './CreateUpdate';
import { companyRoutes } from './routes';

export const createCompanyPage = () => {
  const Company = createCompany();
  const AddCompany = createAddUpdateCompany();
  const UpdateCompany = createAddUpdateCompany();

  const CompanyPage = companyRoutes;
  return () => <CompanyPage Company={Company} AddCompany={AddCompany} UpdateCompany={UpdateCompany} />;
};
