import driverPage from ".";
import createAddDriver from "./CreateUpdate";
import { driverRoutes } from "./routes";

export const createDriverPage = () => {
    const List = driverPage();
    const Add = createAddDriver();
    const Update = createAddDriver();

    const DriverPage = driverRoutes;
    return () => (
        <DriverPage
            List={List}
            Add={Add}
            Edit={Update}
        />
    );
};
