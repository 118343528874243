// Dropdown status
export const dropdownStatus = [
  {
    label: 'Đang sử dụng',
    value: true,
  },
  {
    label: 'Ngưng sử dụng',
    value: false,
  },
];

// Phân loại khách hàng
export const customerType = [
  {
    label: 'Khách lẻ trung tâm bảo hành',
    value: 'C',
  },
  {
    label: 'Đại lý',
    value: 'B',
  },
  {
    label: 'Kho DGW',
    value: 'S',
  },
];

// Loại cuộc gọi
export const dropdowTicketType = [
  {
    label: 'Gọi vào',
    value: 'TICKET_CALLIN',
  },
  {
    label: 'Gọi ra',
    value: 'TICKET_CALLOUT',
  },
];
