import {
  DeleteOutlined,
  DownOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Popconfirm, Space, Tooltip } from 'antd';
import { TypeColumnsUser } from 'pages/model';
import TemplateReportModal from './TemplateReportModal';
import { Dispatch, SetStateAction } from 'react';
// import { TypeColumnsUser } from '../BC8WorkOrderFinished/model';

export type ReportFormProps = {
  templateReportModalRef: React.MutableRefObject<any>;
  userReports: TypeColumnsUser[];
  selectedReportId: string;
  templateReportModalProps: object;
  setIsResizablePresent: Dispatch<SetStateAction<boolean>>;
};

function SaveReportForm(props: ReportFormProps) {
  const { templateReportModalRef, userReports, selectedReportId, templateReportModalProps } = props;
  // Call function showModal in child component
  const callShowModal = (reportType: string) => {
    if (templateReportModalRef.current) {
      templateReportModalRef.current.showModal(reportType);
    }
  };
  // Call function showModalUpdate in child component
  const callShowModalUpdate = (reportType: string, reportId: string) => {
    if (templateReportModalRef.current) {
      templateReportModalRef.current.showModalUpdate(reportType, reportId);
    }
  };
  // Call function handleGetReportSaved in child component
  const callHandleGetReportSaved = (reportType: string, reportId: string) => {
    if (templateReportModalRef.current) {
      templateReportModalRef.current.handleGetReportSaved(reportType, reportId);
    }
  };
  // Call function deleteReport in child component
  const callDeleteReport = (reportType: string, reportId: string) => {
    if (templateReportModalRef.current) {
      templateReportModalRef.current.deleteReport(reportType, reportId);
    }
  };

  const listDocumentDropdown = [
    // --------------------ĐỪNG XÓA ĐOẠN NÀY - SẼ DÙNG TRONG TƯƠNG LAI--------------------
    // {
    //   key: '1',
    //   type: 'group',
    //   label: (
    //     <Space className="inline-flex">
    //       Mẫu báo cáo hệ thống
    //       <Tooltip title="Thêm mới báo cáo hệ thống">
    //         <Button
    //           onClick={() => showModal('system')}
    //           icon={<FileAddOutlined className="!flex items-center justify-center" />}
    //         ></Button>
    //       </Tooltip>
    //     </Space>
    //   ),
    //   children: systemReports?.map((system) => {
    //     const optionClassName = selectedReportId === system.id ? 'bg-[#0000000a]' : '';
    //     return {
    //       key: `1-${system.id}`,
    //       label: (
    //         <div className="w-full flex justify-between items-center">
    //           <div
    //             className={`w-full pr-3 leading-8 ${optionClassName}`}
    //             onClick={() => {
    //               handleGetReportSaved({
    //                 type: 'system',
    //                 reportId: system.id,
    //               });
    //             }}
    //           >
    //             <FileDoneOutlined className="mr-3" />
    //             <span>{system.title}</span>
    //           </div>
    //           <div className="flex">
    //             <Tooltip title="Lưu chỉnh sửa">
    //               <Button
    //                 type="dashed"
    //                 icon={<SaveOutlined />}
    //                 className="!mr-1"
    //                 onClick={() => {
    //                   showModalUpdate('system', system.id);
    //                 }}
    //               />
    //             </Tooltip>
    //             <Tooltip title="Xóa">
    //               <Button
    //                 type="dashed"
    //                 danger
    //                 icon={<DeleteOutlined />}
    //                 onClick={() => {
    //                   deleteReport('system', system.id);
    //                 }}
    //               />
    //             </Tooltip>
    //           </div>
    //         </div>
    //       ),
    //     };
    //   }),
    // },
    {
      key: '2',
      type: 'group',
      label: (
        <Space className="inline-flex">
          Mẫu báo cáo của bạn
          <Tooltip title="Thêm mới báo cáo của bạn">
            <Button onClick={() => callShowModal('user')} icon={<FileAddOutlined />}></Button>
          </Tooltip>
        </Space>
      ),
      children: userReports.map((user) => {
        const optionClassName = selectedReportId === user.id ? 'bg-[#0000000a]' : '';
        return {
          key: `2-${user.id}`,
          label: (
            <div className="w-full flex justify-between items-center">
              <div
                className={`w-full pr-3 leading-8 ${optionClassName}`}
                onClick={() => {
                  return callHandleGetReportSaved('user', user.id);
                }}
              >
                <FileDoneOutlined className="mr-3" />
                <span>{user.title}</span>
              </div>
              <div className="flex">
                <Tooltip title="Lưu chỉnh sửa">
                  <Button
                    type="dashed"
                    icon={<SaveOutlined />}
                    className="!mr-1"
                    onClick={() => callShowModalUpdate('user', user.id)}
                  />
                </Tooltip>
                <Popconfirm
                  title="Xóa mẫu báo cáo"
                  description="Bạn có chắc muốn xóa mẫu báo cáo này?"
                  onConfirm={() => callDeleteReport('user', user.id)}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                  <Tooltip title="Xóa">
                    <Button
                      type="dashed"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Tooltip>
                </Popconfirm>
              </div>
            </div>
          ),
        };
      }),
    },
  ];
  return (
    <>
      <Dropdown
        placement="bottomRight"
        arrow
        onOpenChange={() => {
          props.setIsResizablePresent(false);
        }}
        trigger={['click']}
        menu={{
          items: listDocumentDropdown,
        }}
      >
        <Button className="!flex justify-between items-center gap-2 !px-3 !rounded-sm !py-1">
          <Space>
            Mẫu báo cáo
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>

      <TemplateReportModal ref={templateReportModalRef} {...templateReportModalProps} />
    </>
  );
}

export default SaveReportForm;
