/* eslint-disable react-hooks/exhaustive-deps */

import * as Icon from 'base/ui/components/Icons';

import { Checkbox, Col, Form, Radio, Row, Spin, message } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import {
  categoryPresenter,
  categoryStore,
  commonMasterDataPresenter,
  commonMasterDataStore,
  salesEmployeePresenter,
  salesEmployeeStore,
  userStore,
} from 'stores/root_store';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { validateEmail, validatePhone } from 'utils/validateHelper';

import BoxContainer from 'base/ui/components/BoxContainer';
import { Category } from 'services/RawData/Sales_services';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { SYSTEM_TITLE } from 'utils/constants';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

const createAddSalesEmployee = () => {
  return observer(() => {
    const { t } = useTranslation();

    // Get data params from url
    const { id: idParams } = useParams();
    const [searchParams] = useSearchParams();
    const salesEmpCodeCopy = searchParams.get('copy');

    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }
    const navigate = useNavigate();
    // Check button save and save continue
    let isSaveAndContinue = false;

    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);
    const [listGroup, setListGroup] = useState<Category[]>([]);
    const [listBrand, setListBrand] = useState<Category[]>([]);
    // Handle check all group
    const [checkedListGroup, setCheckedListGroup] = useState<Category[]>([]);
    let checkAllGroup = listGroup.length === checkedListGroup.length;
    const indeterminateGroup = checkedListGroup.length > 0 && checkedListGroup.length < listGroup.length;

    // Handle check all brand
    const [checkedListBrand, setCheckedListBrand] = useState<Category[]>([]);
    let checkAllBrand = listBrand.length === checkedListBrand.length;
    const indeterminateBrand = checkedListBrand.length > 0 && checkedListBrand.length < listBrand.length;
    //======================================================FUNCTION===============================================//
    // Call api detail store
    const getDetailSalesEmployee = async (employeeCode: string) => {
      setLoading(true);
      // call data nhân viên by code
      await salesEmployeePresenter.getSalesEmployeeByCode(salesEmployeeStore, employeeCode);
      if (salesEmployeeStore.salesEmployeeDetailResponse.isSuccess) {
        if (idParams) {
          form.setFieldsValue(salesEmployeeStore.salesEmployeeDetailResponse.data);
        } else if (salesEmpCodeCopy) {
          form.setFieldsValue({
            brand: salesEmployeeStore.salesEmployeeDetailResponse.data?.brand,
            group: salesEmployeeStore.salesEmployeeDetailResponse.data?.group,
          });
        }
      } else {
        navigate('/MasterData/SalesEmployee');
      }
      setLoading(false);
    };

    // Call api list group
    const getDataSearchListGroup = async (keyword: string) => {
      await categoryPresenter.dropdownCategory(categoryStore, keyword);
      if (categoryStore.categoryDropdown.isSuccess) {
        // setListGroup(categoryStore.categoryDropdown.data);
      }
    };
    // Call api list brand
    const getDataSearchListBrand = async (keyword: string) => {
      await categoryPresenter.dropdownCategory(categoryStore, keyword);
      if (categoryStore.categoryDropdown.isSuccess) {
        // setListBrand(categoryStore.categoryDropdown.data);
      }
    };

    // Handle create company
    const onFinish = async (values: any) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        await salesEmployeePresenter.createSalesEmployee(salesEmployeeStore, {
          ...values,
          actived: true,
        });
        if (salesEmployeeStore.crudResponse.isSuccess) {
          message.success(salesEmployeeStore.crudResponse.message);

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/SalesEmployee');
          }
          form.resetFields();
          checkAllGroup = false;
          checkAllBrand = false;
        }
      } else {
        await salesEmployeePresenter.updateSalesEmployee(salesEmployeeStore, {
          ...values,
          salesEmployeeCode: idParams,
        });
        if (salesEmployeeStore.crudResponse.isSuccess) {
          message.success(salesEmployeeStore.crudResponse.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/SalesEmployee');
          }
        }
      }
      setLoading(false);
    };

    useEffect(() => {
      (async () => {
        if (idParams) {
          getDetailSalesEmployee(idParams);
        } else if (salesEmpCodeCopy) {
          getDetailSalesEmployee(salesEmpCodeCopy);
        }
        await commonMasterDataPresenter.getDropdownDepartment(commonMasterDataStore);
        // Promise.all([getDataSearchListGroup('Group'), getDataSearchListBrand('Brand')]);
      })();
    }, []);

    const initialValues = {
      actived: true,
    };

    // Group
    const onChangeGroup = (list: any) => {
      setCheckedListGroup(list);
    };
    const onCheckAllChangeGroup = (e: CheckboxChangeEvent) => {
      const checkAllTmp = listGroup.map((item) => item.key as Category);
      setCheckedListGroup(e.target.checked ? checkAllTmp : []);
      form.setFieldValue('group', e.target.checked ? checkAllTmp : []);
    };

    // Brand
    const onChangeBrand = (list: any) => {
      setCheckedListBrand(list);
    };

    const onCheckAllChangeBrand = (e: CheckboxChangeEvent) => {
      const checkAllTmp = listBrand.map((item) => item.key as Category);
      setCheckedListBrand(e.target.checked ? checkAllTmp : []);
      form.setFieldValue('brand', e.target.checked ? checkAllTmp : []);
    };

    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }

    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('masterdata.sales-employee.title'),
                link: '/MasterData/SalesEmployee',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/SalesEmployee/${idParams}` : '/MasterData/SalesEmployee/create',
              },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                  {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                </div>
              </>
            }
          />
          {/* Form nhập tìm kiếm */}
          <BoxContainer fullHeight>
            <Spin spinning={loading}>
              <SearchForm onFinish={onFinish} initialValues={idParams ? {} : initialValues} form={form}>
                {/* mã nhân viên */}
                {/* <SearchItem
                  rules={[{ required: true, message: 'Vui lòng nhập mã nhân viên' }]}
                  name={'salesEmployeeCode'}
                  label={t('masterdata.sales-employee.employee-code')}
                >
                  <CustomInput disabled={idParams ? true : false} />
                </SearchItem> */}
                {/* họ và tên */}
                <SearchItem
                  rules={[{ required: true, message: 'Vui lòng nhập họ và tên' }]}
                  name={'salesEmployeeName'}
                  label={t('masterdata.sales-employee.full-name')}
                >
                  <CustomInput />
                </SearchItem>
                {/* tên ngắn */}
                <SearchItem name={'salesEmployeeShortName'} label={t('masterdata.sales-employee.short-name')}>
                  <CustomInput />
                </SearchItem>
                {/* phone điện thoại */}
                <SearchItem
                  name={'phone'}
                  label={t('masterdata.sales-employee.phone')}
                  rules={[
                    {
                      validator: validatePhone,
                    },
                  ]}
                >
                  <CustomInput />
                </SearchItem>
                {/* email */}
                <SearchItem
                  name={'email'}
                  label={t('masterdata.sales-employee.email')}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập email',
                    },
                    {
                      validator: validateEmail,
                    },
                  ]}
                >
                  <CustomInput />
                </SearchItem>
                {/* phòng ban */}
                <SearchItem
                  name={'departmentId'}
                  label={'Bộ phận'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn Bộ phận',
                    },
                  ]}
                >
                  <CustomSelect
                    popupMatchSelectWidth={300}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    options={commonMasterDataStore.commonRespone.data.map((item) => {
                      return {
                        label: item.value,
                        value: item.key,
                      };
                    })}
                    showSearch
                    allowClear
                  />
                </SearchItem>
                {/* Trạng thái */}
                {idParams ? (
                  <SearchItem name={'actived'} label={t('masterdata.sales-employee.status')}>
                    <Radio.Group>
                      <Radio value={true}>{t('radio-btn.active')}</Radio>
                      <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                    </Radio.Group>
                  </SearchItem>
                ) : (
                  <div></div>
                )}
                {/* Group */}
                {/* <SearchItem
                  label={
                    <span>
                      Group
                      <Checkbox
                        className="!ml-3 !mr-1"
                        indeterminate={indeterminateGroup}
                        onChange={onCheckAllChangeGroup}
                        checked={checkAllGroup}
                      >
                        <span className="font-normal text-[#0070B1]">Chọn tất cả</span>
                      </Checkbox>
                    </span>
                  }
                  name={'group'}
                >
                  <Checkbox.Group
                    onChange={onChangeGroup}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto auto',
                    }}
                  >
                    {listGroup.map((i) => (
                      <Checkbox key={i.key} value={i.key}>
                        {i.value}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </SearchItem> */}
                {/* Brand */}
                {/* <SearchItem
                  label={
                    <span>
                      Brand
                      <Checkbox
                        className="!ml-3 !mr-1"
                        indeterminate={indeterminateBrand}
                        onChange={onCheckAllChangeBrand}
                        checked={checkAllBrand}
                      >
                        <span className="font-normal text-[#0070B1]">Chọn tất cả</span>
                      </Checkbox>
                    </span>
                  }
                  name={'brand'}
                >
                  <Checkbox.Group
                    onChange={onChangeBrand}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto auto',
                    }}
                  >
                    {listBrand.map((i) => (
                      <Checkbox key={i.key} value={i.key}>
                        {i.value}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </SearchItem> */}
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddSalesEmployee;
