import { DatePicker, Form, Select, Tag } from 'antd';
import { observer } from 'mobx-react'
import PageWrapper from 'pages/Components/PageWrapper';
import { useCallback, useEffect, useRef, useState } from 'react'
import CustomButton from 'base/ui/components/Button';
import { CheckStatus, CloseStatus, Filter } from 'base/ui/components/Icons';
import BoxContainer from 'base/ui/components/BoxContainer';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import SaveReportForm from 'pages/Components/reportForm';
import { ActionType, ProColumns } from '@ant-design/pro-components';
import { useResizableColumns } from 'utils/reportHelper';
import { DATE_FORMAT_DEFAULT, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';
import { ExportButton } from 'pages/Components/FunctionButton';
import { commonPresenter, commonStore, reportPresenter, reportStore } from 'stores/root_store';
import dayjs from 'dayjs';
import { DeliveryRegistrationReport } from 'models/Report';
import { SearchDeliveryRegistrationReport } from 'services/Main/report_services';
import { checkPermissionWithId } from 'utils/permissionHelper';
import { usePermission } from 'hooks/usePermission';
import { HiSearch } from 'react-icons/hi';
import { CommonModel } from 'services/Main/common_services';
import { debounce } from 'utils/debounce';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ROUTES } from 'base/routes/routes';
import { cloneDeep } from 'lodash';
import { removeEmptyValues } from 'utils/transformHelper';

const dropdownActive = [{
    label: 'Đang hoạt động',
    value: true
}, {
    label: "Hủy phiếu",
    value: false
}]

const initialSearch = {
    paging: {
        pageSize: PAGE_SIZE_DEFAULT,
        pageIndex: 1,
        orderBy: undefined,
        orderByDesc: undefined,
    },
    deliveryDateFrom: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    deliveryDateTo: dayjs().format('YYYY-MM-DD')
}

const createListRegistration = () => {
    return observer(() => {
        document.title = 'Báo cáo - ' + SYSTEM_TITLE;
        usePermission('INDEX')
        const isExport = checkPermissionWithId("EXPORT")

        // ========== SEARCH =================
        const [searchForm] = Form.useForm()
        // mở drawer search
        const [openDrawer, setOpenDrawer] = useState(false)

        // =========== TABLE && MẪU BÁO CÁO =================
        const [columns, setColumns] = useState<ProColumns<DeliveryRegistrationReport>[]>([
            {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                fixed: 'left',
                width: 50,
                align: 'center',
            },
            {
                title: 'Mã đăng ký',
                dataIndex: 'deliveryRegistrationCode',
                key: 'deliveryRegistrationCode',
                fixed: 'left',
                width: 120,
                align: 'center',
                render: (text, record) => (
                    <Link to={`${ROUTES.PURCHASE_ORDER.LIST_PO.EDIT_VOTE.LINK}/${record.deliveryRegistrationId}`}>{text}</Link>
                )
            },
            {
                title: 'Nhà cung cấp',
                dataIndex: 'vendorFmt',
                key: 'vendorFmt',
                width: 500,
            },
            {
                title: 'Thời gian giao hàng dự kiến',
                dataIndex: 'deliveryDates',
                key: 'deliveryDates',
                width: 180,
                align: 'center',
                onCell: () => ({ className: `!p-0 !pt-0 divide-y` }),
                render: (_, record) => {
                    return record?.deliveryDates ? record.deliveryDates.map((time, index) => (
                        <div key={index} className={`w-full py-1 px-2`}>
                            {moment(time).format("DD/MM/YYYY HH:mm:ss")}
                        </div>
                    )) : '-'
                }
            },
            {
                title: 'Tên tài xế',
                dataIndex: 'driverName',
                key: 'driverName',
                width: 230,
            },
            {
                title: 'Số điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                width: 100,
            },
            {
                title: 'Số xe',
                dataIndex: 'licensePlate',
                key: 'licensePlate',
                width: 100,
            },
            {
                title: 'Kết quả đào tạo an toàn',
                dataIndex: 'result',
                key: 'result',
                width: 180,
            },
            {
                title: 'Tình trạng phiếu',
                dataIndex: 'taskStatusName',
                key: 'taskStatusName',
                width: 130,
                align: 'center',
                render: (text, record) => {
                    return <div className="w-full !text-[10px] !text-center font-medium !py-0.5 border rounded-sm"
                        style={{
                            borderColor: record.color + '80',
                            color: record.color,
                            backgroundColor: record.color + '1a'
                        }}
                    >{text}</div>
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'actived',
                key: 'actived',
                width: 80,
                align: 'center',
                render: (text, record) => {
                    if (record.actived === true) {
                        return <CheckStatus className="!fill-[#389e0d] mx-auto" width="15" />
                    } else if (record.actived === false) {
                        return <CloseStatus width="15" className="!fill-red-500 mx-auto" />
                    }
                    return text
                }
            },
            {
                title: 'Ghi chú hủy phiếu',
                dataIndex: 'cancelReason',
                key: 'cancelReason',
                width: 350,
            },
        ])


        const [dataPaging, setDataPaging] = useState<SearchDeliveryRegistrationReport>(initialSearch);
        const [dataTable, setDataTable] = useState<DeliveryRegistrationReport[]>([])
        // disable nhập ngày khi chọn khác "Custom"
        const [disableDatePicker, setDisableDatePicker] = useState(false)
        const [loading, setLoading] = useState(true)
        // Dropdown nhà cung cấp
        const [listVendor, setListVendor] = useState<CommonModel[]>([])

        const getListCommom = async () => {
            if (!commonStore.listCommonDate.length) {
                await commonPresenter.getListCommonDate(commonStore)
            }
        }
        // search báo cáo
        const getDataTable = async (dataSearch: SearchDeliveryRegistrationReport) => {
            await reportPresenter.getListReportDeliveryRegistration(reportStore, dataSearch)
            if (reportStore.deliveryRegistrationRes?.isSuccess) {
                setDataTable(reportStore.deliveryRegistrationRes?.data)
                return reportStore.deliveryRegistrationRes?.data
            }
            return []
        }

        const getDateByCommonDate = async (commonDate: string) => {
            setDisableDatePicker(true)
            await commonPresenter.getDateByCommonDate(commonStore, commonDate)
            if (commonStore.commonDate.fromDate) {
                searchForm.setFieldsValue({ deliveryDateFrom: dayjs(commonStore.commonDate.fromDate, "YYYY-MM-DD"), deliveryDateTo: dayjs(commonStore.commonDate.toDate, "YYYY-MM-DD") })
            }
        }

        // Dropdown nhà cung cấp
        const getDropdownVendor = async (keyword?: string) => {
            await commonPresenter.getDropdownVendor(commonStore, { keyword: keyword, takeNumber: 20 })
            if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
                setListVendor(commonStore.commonRespone.data)
            }
        }
        const searchVendor = useCallback(debounce(getDropdownVendor, 300), [])
        // Dropdown trạng thái luồn duyệt
        const getTaskStatus = async () => {
            if (!commonStore.dropdownTaskStatus.length) {
                await commonPresenter.getDropdownTaskStatus(commonStore)
            }
        }

        useEffect(() => {
            Promise.allSettled([
                getListCommom(),
                getDropdownVendor(),
                getTaskStatus()
            ]).then(() => { setLoading(false) })
        }, []);
        // =================================================================================================
        // mẫu báo cáo
        const tableRef = useRef<ActionType>();
        const constDataName = 'BCDanhSachXeGiaoHang';
        const {
            isResizablePresent,
            setIsResizablePresent,
            resizableColumnSelect,
            columnsState,
            handleColumnsStateChange,
            templateReportModalRef,
            userReports,
            selectedReportId,
            templateReportModalProps,
            handleResize,
        } = useResizableColumns<DeliveryRegistrationReport>(columns, setColumns, constDataName);

        return (
            <PageWrapper
                breadcrumb={[{
                    text: 'Báo cáo danh sách xe giao hàng'
                }]}
                extras={
                    <>
                        {isExport && <ExportButton loading={loading} onClick={async () => {
                            setLoading(true);
                            await reportPresenter.exportListReportDeliveryRegistration(dataPaging)
                            setLoading(false);
                        }} />}
                        <CustomButton
                            onClick={() => setOpenDrawer(true)}
                            type="primary" color="#EFF4F9"
                            className="!px-0 w-8"
                        >
                            <Filter className="!fill-primary" />
                        </CustomButton>
                    </>
                }
            >
                <BoxContainer>
                    <CustomProTable<DeliveryRegistrationReport>
                        bordered
                        columns={columns}
                        dataSource={dataTable}
                        actionRef={tableRef}
                        tableAlertRender={false}
                        handleResize={handleResize}
                        isResizablePresent={isResizablePresent}
                        resizableColumnSelect={resizableColumnSelect}
                        columnsState={{
                            value: Object.keys(columnsState).length > 0 ? columnsState : undefined,
                            onChange: (newColumnsState) => {
                                handleColumnsStateChange(newColumnsState);
                            },
                        }}
                        tableLayout='fixed'
                        toolBarRender={() => [

                            // nút lưu mẫu bc
                            <SaveReportForm
                                setIsResizablePresent={setIsResizablePresent}
                                templateReportModalRef={templateReportModalRef}
                                userReports={userReports}
                                selectedReportId={selectedReportId}
                                templateReportModalProps={templateReportModalProps}
                            />,
                        ]}
                        rowKey="stt"
                        pagination={{
                            ...tablePagination(dataPaging, setDataPaging),
                            total: reportStore.deliveryRegistrationRes?.paging?.recordsTotal,
                        }}
                        request={async (params = {}, sort, filter) => {
                            const data = await getDataTable({
                                ...dataPaging,
                                paging: {
                                    ...dataPaging.paging,
                                    orderBy:
                                        Object.values(sort)[0] === "ascend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                    orderByDesc:
                                        Object.values(sort)[0] === "descend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                },
                            });
                            if (Object.values(sort).length) {

                                setDataPaging({
                                    ...dataPaging,
                                    paging: {
                                        ...dataPaging.paging,
                                        orderBy:
                                            Object.values(sort)[0] === "ascend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                        orderByDesc:
                                            Object.values(sort)[0] === "descend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                    },
                                });
                            }
                            return {
                                data: data,
                                success: reportStore.deliveryRegistrationRes?.isSuccess
                            };

                        }}
                    />

                </BoxContainer>
                <SearchBox
                    onClose={() => { setOpenDrawer(false) }}
                    className="lg:!w-[50%]"
                    open={openDrawer}
                    footer={
                        <CustomButton
                            htmlType="submit"
                            className="my-auto ml-auto"
                            type="primary"
                            icon={<HiSearch />}
                            onClick={() => {
                                searchForm.submit();
                            }}
                        >
                            Tìm kiếm
                        </CustomButton>
                    }
                >
                    <SearchForm
                        form={searchForm}
                        onFinish={(values) => {
                            const valueClone = cloneDeep(values)
                            // convert lại from date to date
                            const deliveryDateFrom = valueClone?.deliveryDateFrom ? valueClone.deliveryDateFrom?.format("YYYY-MM-DD") : undefined
                            const deliveryDateTo = valueClone?.deliveryDateTo ? valueClone.deliveryDateTo?.format("YYYY-MM-DD") : undefined
                            Object.assign(valueClone, { deliveryDateFrom, deliveryDateTo })
                            // bỏ các giá trị rỗng null và trim giá trị
                            removeEmptyValues(valueClone)
                            const dataSearch: SearchDeliveryRegistrationReport = { paging: { ...dataPaging.paging, pageIndex: 1 }, ...valueClone }
                            setDataPaging(dataSearch)
                            setOpenDrawer(false)
                            tableRef.current?.reload()
                        }}
                    >
                        {/* Mã NCC */}
                        <SearchItem name={'vendorNumber'} label={'Nhà cung cấp'}>
                            <Select
                                // fieldNames={{ value: 'key', label: 'value' }}
                                popupMatchSelectWidth={false}
                                options={listVendor.map(item => ({ value: item.key, label: item.value }))}
                                allowClear showSearch
                                filterOption={false}
                                onSearch={(value) => searchVendor(value || undefined)} />
                        </SearchItem>
                        {/* Tình trạng phiếu */}
                        <SearchItem name={'taskStatusCategory'} label={'Tình trạng phiếu'}>
                            <Select
                                options={commonStore.dropdownTaskStatus}
                                fieldNames={{ label: 'value', value: 'key' }}
                                filterOption={false}
                                allowClear
                                placeholder={'-- Tất cả --'}
                            />
                        </SearchItem>
                        {/* Thời gian giao hàng */}
                        <SearchItem name="common" initialValue={'Custom'} label={'Thời gian giao hàng'} start>
                            <Select options={commonStore.listCommonDate} fieldNames={{ label: 'value', value: 'key' }} onChange={async (value) => {
                                if (value && value !== "Custom") {
                                    getDateByCommonDate(value)
                                } else {
                                    setDisableDatePicker(false)
                                    searchForm.setFieldsValue({ deliveryDateFrom: undefined, deliveryDateTo: undefined })
                                }
                            }} />
                        </SearchItem>
                        {/* Trạng thái phiếu */}
                        <SearchItem name={'actived'} label={'Trạng thái phiếu'}>
                            <Select options={dropdownActive} allowClear filterOption={false} placeholder={'-- Tất cả --'} />
                        </SearchItem>
                        {/* Từ ngày */}
                        <SearchItem
                            initialValue={dayjs().subtract(1, 'month')}
                            name={'deliveryDateFrom'} label={'Từ ngày'}
                            start
                            dependencies={['deliveryDateTo']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value || getFieldValue('deliveryDateTo')) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error('Chưa nhập giá trị "Từ ngày".'));;
                                    },
                                })
                            ]}
                        >
                            <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>
                        {/* Đến ngày */}
                        <SearchItem
                            initialValue={dayjs()}
                            name={'deliveryDateTo'} label={'Đến ngày'}
                            dependencies={['deliveryDateFrom']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value || getFieldValue('deliveryDateFrom')) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Chưa nhập giá trị "Đến ngày".'));
                                    },
                                })
                            ]}
                        >
                            <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>
                    </SearchForm>
                </SearchBox>
            </PageWrapper>
        )
    })
}

export default createListRegistration