import React, { memo, useEffect, useState } from 'react';
import { Button, ButtonProps, ConfigProvider } from 'antd';
import { uiStore } from 'stores/root_store';

function CustomButton(props: ButtonProps) {
  const [type, setType] = useState('');
  const [color, setColor] = useState(props.color !== undefined ? props.color : 'primary');
  useEffect(() => {
    const checkType = () => {
      switch (props.type) {
        case 'primary':
          setType(
            `${!props.color && ''} text-white border-primary hover:text-white disabled:hover:!text-[#00000040] ${props.color === undefined ? '' : 'hover:opacity-80'
            } `,
          );
          break;
        default:
          setType('text-dark hover:!text-primary hover:!border-primary disabled:hover:!text-[#00000040] ');
      }
    };
    checkType();
  }, []);

  return (
    <Button
      {...props}
      className={
        uiStore.theme.name +
        ' disabled:!bg-[#0000000a] disabled:!border-[#d9d9d9] !rounded-sm text-default [&_div]:!shadow-none !shadow-none !flex gap-1 px-3 items-center justify-center font-normal ' +
        type +
        ' ' +
        props.className
      }
      style={{
        background: color,

        borderColor: color,

        ...props.style
      }}
    >
      {props.children}
    </Button>
  );
}

export default memo(CustomButton);
