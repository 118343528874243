import { EditableProTable, ProColumns, nanoid } from '@ant-design/pro-components'
import { AutoComplete } from 'antd'
import { debounce } from 'lodash'
import { purchaseOrderItems } from 'models/PurchaseOrder'
import React, { useEffect, useState } from 'react'
import { CommonModel } from 'services/Main/common_services'
import { commonPresenter, stockPresenter, stockStore } from 'stores/root_store'
import { sFormatNumber } from 'utils/formatHelper'
import { CardBox } from '../ListPO/createUpdate/cardBox'
import { customTableClassName } from 'base/ui/components/CustomTable'


type PurchaseOrderTabelProps = {
    id: string;
    editableKeys: React.Key[];
    setEditableRowKeys: (value: React.Key[]) => void;
    isEdit?: boolean;
}

const PurchaseOrderTabel: React.FC<PurchaseOrderTabelProps> = (props) => {
    const { editableKeys, id, setEditableRowKeys, isEdit } = props

    const [dropdownProducts, setDropdownProducts] = useState<CommonModel[]>([])
    const [dataSource, setDataSource] = useState<any[]>([{ purchaseOrderDetailId: id }]);


    const defaultDataTable: ProColumns<purchaseOrderItems>[] = [
        {
            title: 'STT',
            // dataIndex: 'stt',
            valueType: 'index',
            key: 'stt',
            fixed: 'left',
            width: 50,
            align: 'center',
            readonly: true
        },
        {
            title: 'Ngày giao hàng(*)',
            dataIndex: 'documentDate',
            key: 'documentDate',
            width: 100,
            align: 'center',
            valueType: 'date',
            fixed: 'left',
            fieldProps: {
                format: 'DD/MM/YYYY',
                size: 'small',
                style: {
                    width: "100%"
                }
            },
            formItemProps: (form, config) => ({
                rules: [{ required: true, message: "Vui lòng chọn Ngày giao hàng." }]
            })
        },
        {
            title: 'PO Item',
            dataIndex: 'poItem',
            key: 'poItem',
            width: 100,
            align: 'center',
            fieldProps: {
                size: 'small',
            },
            // formItemProps: (form, config) => ({
            //     rules: [{ required: true, message: "Vui lòng nhập PO item." }]
            // })
        },

        {
            title: 'Mã hàng hóa(*)',
            dataIndex: 'productCode',
            key: 'productCode',
            width: 150,
            renderFormItem(schema, config, form, action) {
                return (
                    <AutoComplete
                        popupMatchSelectWidth={false}
                        placeholder="Vui lòng chọn"
                        allowClear
                        size="small"
                        options={dropdownProducts.map(product => ({
                            value: product.key,
                            label: product.value
                        }))}
                        onSearch={(value) => {
                            searchProduct(value)
                        }}
                        onClear={() => {
                            form.setFieldValue(['purchaseOrderItems', `${schema.index}`, "productName"], undefined)
                        }}
                        onSelect={(_, option) => {
                            form.setFieldValue(['purchaseOrderItems', `${schema.index}`, "productName"], option.label)
                        }}
                    />
                )
            },
            formItemProps: (form, config) => ({
                rules: [{ required: true, message: "Vui lòng chọn Mã hàng hóa." }]
            })
        },
        {
            title: 'Tên hàng hóa(*)',
            dataIndex: 'productName',
            key: 'productName',
            width: 300,
            fieldProps: {
                size: 'small',
            },
            formItemProps: (form, config) => ({
                rules: [{ required: true, message: "Vui lòng nhập tên hàng hóa." }]
            })
        },
        {
            title: 'Mã kho(*)',
            dataIndex: 'stockCode',
            key: 'stockCode',
            width: 100,
            fieldProps: {
                size: 'small',
            },
            renderFormItem(schema, _config, form, _action) {
                return (
                    <AutoComplete
                        popupMatchSelectWidth={false}
                        placeholder="Vui lòng chọn"
                        allowClear
                        size="small"
                        options={stockStore.searchAllStockResponse.data.map(stock => ({
                            label: stock.stockName || '',
                            value: stock.stockCode || ''
                        }))}
                        // onSearch={(value) => {
                        //     searchProduct(value)
                        // }}
                        filterOption
                        onClear={() => {
                            form.setFieldValue(['purchaseOrderItems', `${schema.index}`, "stockName"], undefined)
                        }}
                        onSelect={(_, option) => {
                            form.setFieldValue(['purchaseOrderItems', `${schema.index}`, "stockName"], option.label)
                        }}
                    />
                )
            },
            formItemProps: (form, config) => ({
                rules: [{ required: true, message: "Vui lòng chọn Kho." }]
            })
        },
        {
            title: 'Tên kho(*)',
            dataIndex: 'stockName',
            key: 'stockName',
            width: 120,
            fieldProps: {
                size: 'small',
            },
            formItemProps: (form, config) => ({
                rules: [{ required: true, message: "Vui lòng chọn Kho." }]
            }),
        },
        {
            title: 'ĐVT(*)',
            dataIndex: 'orderUnit',
            key: 'orderUnit',
            width: 100,
            fieldProps: {
                size: 'small',
            },
            formItemProps: (form, config) => ({
                rules: [{ required: true, message: "Vui lòng nhập đơn vị tính." }]
            })
        },

        {
            title: 'Số lượng nhập kho',
            dataIndex: 'receivedQuantity',
            key: 'receivedQuantity',
            width: 150,
            valueType: 'digit',
            fieldProps: {
                size: 'small',
                style: {
                    width: "100%",
                    textAlignLast: 'right'
                },
                controls: false,
                formatter: (value: number | undefined, info: {
                    userTyping: boolean;
                    input: string;
                }) => {
                    if (value) {
                        return sFormatNumber(Number(value)) as string;
                    }
                    return ''
                }
            },
            // formItemProps: (form, config) => ({
            //     rules: [{ required: true, message: "Vui lòng nhập Số lượng nhập kho." }]
            // })
        },
        // Số lượng kế hoạch (SAP)
        {
            title: 'Số lượng kế hoạch(*)',
            dataIndex: 'openQuantity',
            key: 'openQuantity',
            width: 160,
            valueType: 'digit',
            fieldProps: {
                size: 'small',
                style: {
                    width: "100%",
                    textAlignLast: 'right'
                },
                controls: false,
                formatter: (value: number | undefined, info: {
                    userTyping: boolean;
                    input: string;
                }) => {
                    if (value) {
                        return sFormatNumber(Number(value)) as string;
                    }
                    return ''
                }
            },
            formItemProps: (form, config) => ({
                rules: [{ required: true, message: "Vui lòng nhập Số lượng kế hoạch." }]
            })
        },
        {
            hideInTable: isEdit ? true : false,
            title: '',
            // valueType: 'option',
            width: 80,
            renderFormItem(_, config, form) {
                return (
                    <a
                        key="delete"
                        onClick={() => {
                            const data = form.getFieldValue('purchaseOrderItems') as purchaseOrderItems[]
                            const listFilter = data.filter(item => item.purchaseOrderDetailId !== config?.record?.purchaseOrderDetailId)
                            form.setFieldValue('purchaseOrderItems', listFilter)
                        }}
                    >
                        Xóa
                    </a>
                )
            },
        },
    ]


    // danh sách kho
    const getListStock = async () => {
        if (!stockStore.searchAllStockResponse.data.length) {
            await stockPresenter.searchAllStock(stockStore, '')
        }
    }

    const getListProduct = async (keyword?: string) => {
        const result = await commonPresenter.getDropdownProduct(keyword)
        if (result) {
            setDropdownProducts(result)
        }
    }

    const searchProduct = debounce(getListProduct, 300)

    useEffect(() => {
        getListStock()
        getListProduct()
    }, []);

    return (
        <CardBox title="Thông tin hàng hóa">
            <EditableProTable<purchaseOrderItems>
                rowKey="purchaseOrderDetailId"
                name={"purchaseOrderItems"}
                className={customTableClassName}
                columns={defaultDataTable}
                recordCreatorProps={isEdit ? false : {
                    newRecordType: 'dataSource',
                    record: (_index, _records: any[]) => ({
                        purchaseOrderDetailId: nanoid(),
                    }),
                }}
                scroll={{ x: 1800 }}
                tableLayout='fixed'
                value={dataSource}
                editable={{
                    type: 'multiple',
                    editableKeys,
                    actionRender: (_row, _config, defaultDoms) => {
                        return [];
                    },
                    onChange: setEditableRowKeys,
                    deletePopconfirmMessage: "Bạn có chắc chắn muốn xóa dòng này?"
                }}
            />
        </CardBox>
    )
}

export default PurchaseOrderTabel