import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const catalogRoutes = ({
  Catalog,
  AddCatalog,
  UpdateCatalog,
}: {
  Catalog: React.ComponentType;
  AddCatalog: React.ComponentType;
  UpdateCatalog: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Catalog />} />
    <Route path={ROUTES.MASTER_DATA.CATALOG.CREATE} element={<AddCatalog />} />
    <Route path={ROUTES.MASTER_DATA.CATALOG.EDIT} element={<UpdateCatalog />} />
  </Routes>
);
