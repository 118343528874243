/* eslint-disable react-hooks/exhaustive-deps */

import * as Icon from 'base/ui/components/Icons';

import { Form, Input, Radio, Select, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import {
    commonPresenter,
    commonStore,
    driverPresenter,
    driverStore,
    userStore,
} from 'stores/root_store';
import { useNavigate, useParams } from 'react-router-dom';
import { validatePhone } from 'utils/validateHelper';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import { SYSTEM_TITLE } from 'utils/constants';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { CommonModel } from 'services/RawData/commonMasterData_services';
import { cloneDeep } from 'lodash';
import { usePermission } from 'hooks/usePermission';

const createAddDriver = () => {
    return observer(() => {
        const { t } = useTranslation();

        // Get data params from url
        const { id: idParams } = useParams();

        // Check id để đặt tên trang
        let pageTitle = '';

        // Nếu có id là chỉnh sửa (xem chi tiết)
        if (idParams) {
            // Kiểm tra phân quyền
            usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
            pageTitle = t('page-title.update');
            document.title = pageTitle + ' - ' + SYSTEM_TITLE;
        } else {
            // Kiểm tra phân quyền
            usePermission('CREATE');
            pageTitle = t('page-title.create');
            document.title = pageTitle + ' - ' + SYSTEM_TITLE;
        }
        const navigate = useNavigate();
        // Check button save and save continue

        const [form] = Form.useForm();
        // Loading
        const [loading, setLoading] = useState(false);
        // Danh sách Tài xế
        const [listVendor, setListVendor] = useState<CommonModel[]>([])
        //======================================================FUNCTION===============================================//
        // Call api detail store
        const getDetailDriver = async (id: string) => {
            // call data diver by code
            await driverPresenter.getDriverDetail(driverStore, id);
            if (driverStore.driverDetailResponse.isSuccess && driverStore.driverDetailResponse.data) {
                form.setFieldsValue(driverStore.driverDetailResponse.data);
            } else {
                message.error(driverStore.driverDetailResponse.message)
            }
        };

        // Dropdown nhà cung cấp
        const getDropdownVendor = async () => {
            await commonPresenter.getDropdownVendor(commonStore);
            if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
                setListVendor(commonStore.commonRespone.data)
            }
        }
        // danh sách kết quả đào tạo
        const getTrainingResult = async () => {
            if (!commonStore.dropdownTrainingResult.length) {
                await commonPresenter.getDropdownTrainingResult(commonStore)
            }
        }

        // Thêm mới tài xế
        const handleCreate = async (values: any, isContinue: boolean) => {
            setLoading(true)
            // call api thêm mới
            await driverPresenter.createDriver(driverStore, values)
            setLoading(false)
            if (driverStore.crudResponse.isSuccess) {
                message.success(driverStore.crudResponse.message)
                // kiểm tra nếu bấm nút lưu thì thoát ra màn danh sách 
                if (!isContinue) navigate('/MasterData/Driver')
                form.resetFields()
            } else message.error(driverStore.crudResponse.message)
        }
        // Thêm mới tài xế
        const handleUpdate = async (values: any, isContinue: boolean) => {
            setLoading(true)
            const request = cloneDeep(values)
            Object.assign(request, { driverId: idParams })
            // call api thêm mới
            await driverPresenter.updateDriver(driverStore, request)
            setLoading(false)
            if (driverStore.crudResponse.isSuccess) {
                message.success(driverStore.crudResponse.message)
                // kiểm tra nếu bấm nút lưu thì thoát ra màn danh sách 
                if (!isContinue) navigate('/MasterData/Driver')
            } else message.error(driverStore.crudResponse.message)
        }


        useEffect(() => {
            if (idParams) {
                setLoading(true)
                Promise.allSettled([
                    getDropdownVendor(),
                    getTrainingResult(),
                    getDetailDriver(idParams)
                ]).then(() => { setLoading(false) })
            } else {
                setLoading(true)
                Promise.allSettled([
                    getDropdownVendor(),
                    getTrainingResult()
                ]).then(() => { setLoading(false) })
            }
        }, []);

        return (
            <>
                <div className="flex flex-col h-full">
                    <CustomBreadcrumb
                        pagetitle={[
                            {
                                title: "Tài xế",
                                link: '/MasterData/Driver',
                            },
                            {
                                title: pageTitle,
                                link: idParams ? `/MasterData/Driver/${idParams}` : '/MasterData/Driver/create',
                            },
                        ]}
                        extra={
                            <>
                                {((!idParams && userStore.userPermission?.includes('CREATE')) || (idParams && userStore.userPermission?.includes('EDIT'))) && (
                                    <>
                                        <CustomButton
                                            icon={<Icon.Download className="!fill-[#262626]" />}
                                            htmlType="submit"
                                            loading={loading}
                                            onClick={() => {
                                                form.validateFields().then((values) => {
                                                    if (idParams) handleUpdate(values, false);
                                                    else handleCreate(values, false);
                                                }).catch((error) => { })
                                            }}
                                        >
                                            {t('btn.save')}
                                        </CustomButton>
                                        <CustomButton
                                            type="primary"
                                            loading={loading}
                                            onClick={() => {
                                                <Icon.Download />
                                                form.validateFields().then((values) => {
                                                    if (idParams) handleUpdate(values, true);
                                                    else handleCreate(values, true);
                                                }).catch((error) => { })
                                            }}
                                        >
                                            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
                                        </CustomButton>
                                    </>
                                )}
                            </>
                        }
                    />
                    {/* Form  */}
                    <BoxContainer fullHeight>
                        <Spin spinning={loading}>
                            <SearchForm
                                form={form}>
                                {/* Mã tài xế */}
                                {idParams && (
                                    <SearchItem
                                        name={'driverCode'}
                                        label={"Mã tài xế"}
                                    >
                                        <Input disabled />
                                    </SearchItem>
                                )}
                                {/* Nhà cung cấp */}
                                <SearchItem
                                    name={'vendorNumber'}
                                    label={'Nhà cung cấp'}
                                    rules={[{ required: true, message: 'Chưa chọn nhà cung cấp.' }]}
                                >
                                    <Select
                                        options={listVendor.map(v => ({ label: v.value, value: v.key }))}
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                        showSearch
                                    />
                                </SearchItem>
                                {/* Tên tài xế */}
                                <SearchItem
                                    name={'driverName'}
                                    label={'Tên tài xế'}
                                    rules={[{ required: true, message: 'Chưa nhập tên tài xế.' }]}
                                >
                                    <Input />
                                </SearchItem>
                                {/*  điện thoại */}
                                <SearchItem
                                    name={'phone'}
                                    label={t('masterdata.vendor.phone')}
                                    rules={[
                                        {
                                            validator: validatePhone,
                                        },
                                    ]}
                                >
                                    <Input />
                                </SearchItem>
                                {/* Số bằng lái */}
                                <SearchItem
                                    name={'licenseNumber'}
                                    label={'Số bằng lái'}
                                >
                                    <Input />
                                </SearchItem>
                                {/* kết quả đào tạo */}
                                <SearchItem initialValue={"DAT"} name={'trainingResult'} label={'Kết quả đào tạo'}>
                                    <Radio.Group >
                                        {commonStore.dropdownTrainingResult.map((result) => (
                                            <Radio key={result.catalogCode} value={result.catalogCode}>{result.catalogName}</Radio>
                                        ))}
                                    </Radio.Group>
                                </SearchItem>
                                {/* Trạng thái */}
                                <SearchItem initialValue={true} name={'actived'} label={t('masterdata.sales-employee.status')}>
                                    <Radio.Group >
                                        <Radio value={true}>{t('radio-btn.active')}</Radio>
                                        <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                                    </Radio.Group>
                                </SearchItem>
                            </SearchForm>

                        </Spin>
                    </BoxContainer>
                </div>
            </>
        );
    });
};

export default createAddDriver;
