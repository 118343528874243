import { Breadcrumb, BreadcrumbProps } from 'antd';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type CustomBreadcrumbProps = BreadcrumbProps & {
  itemRender?: Array<{
    label: React.ReactNode | string;
    href?: string;
  }>;
  pagetitle: { title: string; link: string }[];
  extra?: React.ReactNode;
};

function CustomBreadcrumb(props: CustomBreadcrumbProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pagetitle, extra } = props;
  const items = pagetitle.map((item) => {
    return {
      title: item.title,
      onClick: () => navigate(`${item.link}`),
    };
  });

  items.unshift({
    title: t('bread-crumb.home'),
    onClick: () => navigate('/'),
  });
  return (
    <div className="min-h-[40px] flex flex-col w-full bg-white px-4 py-[8px] gap-default">
      <div className="flex items-center justify-between">
        <Breadcrumb
          {...props}
          className="[&_ol_li:last-child]:font-medium [&_ol_li:last-child]:text-dark  [&_ol]:items-center cursor-pointer"
          items={items}
        />
        <div className="flex gap-4">{extra}</div>
      </div>
    </div>
  );
}

export default CustomBreadcrumb;
