import { LayoutHOC } from "base/ui/layout/layout";
import { vehicleRegistrationRoutes } from "./routes";
import { createVehicleRegistration } from ".";

export const createVehicleRegistrationPage = (layoutHOC: LayoutHOC) => {
    const List = createVehicleRegistration()


    const VehicleRegistrationPage = layoutHOC(vehicleRegistrationRoutes);

    return () => (
        <VehicleRegistrationPage
            List={List}
        />
    );
};