import * as Icon from 'base/ui/components/Icons';

import { ActionType, ProColumns } from '@ant-design/pro-components';
import { Button, Form, Modal, Tooltip, Upload, message } from 'antd';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { listSangChieu, statusList, vendorList } from 'pages/rawData/Permission/model';
import { useEffect, useRef, useState } from 'react';
import { HiOutlineExternalLink, HiSearch, HiUserAdd } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import {
  userStore,
  vendorPresenter,
  vendorStore,
} from 'stores/root_store';
import { BASE_API_URL, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { usePermission } from 'hooks/usePermission';
import { observer } from 'mobx-react';
import { ExportButton } from 'pages/Components/FunctionButton';
import { SearchModel } from 'pages/model';
import { useTranslation } from 'react-i18next';
import { createVendorServices, VendorModel } from 'services/RawData/vendor_services';
import { getAccessToken, getPlantCode } from 'utils/localStorageHelper';
import { checkPermissionWithId } from 'utils/permissionHelper';
import { customFilterOption } from 'utils/searchHelper';
import { DataSearchVendor } from '../model';
import SearchMultiplePopup, { SearchMultiplePopupRef } from 'pages/synchPO/SearchMultiplePopup';
import { fromByteArray } from 'base64-js';

// Nhà cung cấp
const vendorPage = () => {
  return observer(() => {
    const { t } = useTranslation();

    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
    const isCreate = checkPermissionWithId("CREATE")


    const pagetitle = t('masterdata.vendor.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);
    const [loadingImportAccount, setLoadingImportAccount] = useState(false);

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const searchMultiVendorRef = useRef<SearchMultiplePopupRef>(null)
    const [showModalVendor, setShowModalVendor] = useState(false)
    // Columns table title
    const defaultColumn: ProColumns<VendorModel>[] = [
      // Stt
      {
        title: t('masterdata.vendor.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 30,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
      },
      //thời gian giao hàng
      {
        title: 'Thời gian giao hàng',
        dataIndex: 'periodTimeName',
        key: 'periodTimeName',
        width: 80,
        className: '!rounded-none !py-1',
        align: 'left',
        fieldProps: {
          format: 'DD/MM/YYYY HH:mm:ss',
        },
      },
      // Mã ncc
      {
        title: t('masterdata.vendor.vendor-code'),
        dataIndex: 'supplierNumber',
        key: 'supplierNumber',
        width: 100,
        className: '!rounded-none !py-1',
        fixed: 'left',
        ellipsis: true,
        render: (_, record) => {
          return userStore.userPermission?.includes('VIEW') ? (
            <Link to={`/MasterData/Vendor/${record.vendorId}`}>{record.supplierNumber}</Link>
          ) : (
            <div>{record.supplierNumber}</div>
          );
        },
      },
      // tên ncc
      {
        title: t('masterdata.vendor.full-name'),
        dataIndex: 'longName',
        key: 'longName',
        width: 200,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Địa chỉ
      {
        title: t('masterdata.vendor.address'),
        dataIndex: 'address',
        key: 'address',
        width: 250,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },

      // Điện thoại
      {
        title: t('masterdata.vendor.phone'),
        dataIndex: 'telephone',
        key: 'telephone',
        width: 70,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Email
      {
        title: "Email",
        dataIndex: 'email',
        key: 'email',
        width: 80,
      },
      // Ngày tạo
      {
        title: 'Ngày tạo',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 100,
        align: 'center',
        valueType: 'dateTime',
        fieldProps: {
          format: 'DD/MM/YYYY HH:mm:ss',
        },
      },


      //Trạng thái 
      {
        title: t('masterdata.vendor.status'),
        dataIndex: 'actived',
        width: 70,
        key: 'actived',
        ellipsis: true,
        align: 'center',
        render: (text, record) => (
          <div className="w-full flex justify-center">
            {record.actived ? (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            ) : (
              <Icon.CloseStatus width="15" className="!fill-red-500" />
            )}
          </div>
        ),
      },
      //Trạng thái NCC
      {
        title: 'Tài khoản NCC',
        dataIndex: 'isCreateAccount',
        width: 60,
        key: 'isCreateAccount',
        ellipsis: true,
        align: 'center',
        render: (text, record) => (
          <div className="w-full flex justify-center">
            {record.isCreateAccount ? (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            ) : (
              <Icon.CloseStatus width="15" className="!fill-red-500" />
            )}
          </div>
        ),
      },
      {
        title: "Chức năng",
        width: 40,
        key: 'action',
        align: 'center',
        hideInTable: isCreate ? false : true,
        hideInSetting: isCreate ? false : true,
        render: (_, record) => (
          <div className='w-full '>
            {!record.isCreateAccount && (
              <Tooltip title={"Tạo tài khoản"}>
                <Link to={`/Permission/Account/CreateVendorAccount/${record.supplierNumber}?VendorName=${record.longName}`}>
                  <CustomButtonIcon color="#3b82f6" className='mx-auto'>
                    <HiUserAdd className='text-blue-500 text-sm' />
                  </CustomButtonIcon>
                </Link>
              </Tooltip>
            )}
          </div>
        ),
      },

    ];

    const columnsModalDeliveryTime: ProColumns<VendorModel>[] = [
      // Stt
      {
        title: t('masterdata.vendor.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 50,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (_, __, index) => {
          const { pageIndex, pageSize } = modalVendorSearch.paging;
          return (pageIndex - 1) * pageSize + index + 1;
        },
      },
      //thời gian giao hàng
      {
        title: t('masterdata.vendor.delivery-time'),
        dataIndex: 'periodTimeName',
        key: 'periodTimeName',
        width: 160,
        className: '!rounded-none !py-1',
        align: 'center',

      },
      // Mã ncc
      {
        title: t('masterdata.vendor.vendor-code'),
        dataIndex: 'supplierNumber',
        key: 'supplierNumber',
        width: 120,
        className: '!rounded-none !py-1',
      },
      // tên ncc
      {
        title: t('masterdata.vendor.full-name'),
        dataIndex: 'longName',
        key: 'longName',
        width: 400,
        className: '!rounded-none !py-1',

      },
    ]
    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<VendorModel>[]>(defaultColumn);
    const [listModalVendor, setListModalVendor] = useState<VendorModel[]>([]);
    // Loading all page
    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingExportAccount, setLoadingExportAccount] = useState(false);

    const tableRef = useRef<ActionType>();

    // ====================SEARCH DROPDOWN=========================================

    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    });

    const [modalVendorSearch, setModalVendorSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    })

    // List data company
    const [listSalesEmployee, setListSalesEmployee] = useState<VendorModel[]>([]);

    // Function open hide drawer
    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Call api search data
    const getDataSearchVendor = async (dataSearch: DataSearchVendor) => {
      await vendorPresenter.searchVendor(vendorStore, dataSearch);
      if (vendorStore.searchVendorResponse.isSuccess) {
        setListSalesEmployee(vendorStore.searchVendorResponse.data);
      }
    };

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      // set pageIndex 1 when search form
      setDataSearch({
        // loại bỏ những field không có giá trị
        // ...Object.fromEntries(Object.entries(dataResult).filter(([_, value]) => !!value)),
        ...values,
        paging: {
          pageSize: 10,
          pageIndex: 1,
        },
      });
      tableRef.current?.reload()
      onCloseDrawer();
      setLoading(false);
    };

    // initialValues of form search
    const initialValues = {
      supplierNumber: null,
      supplierName: null,
      actived: null,
      isCreateAccount: null,
      periodTime: null
    };

    // ================================ CALL DATA ==========================================
    // scroll to top
    const updateListVendor = async () => {
      setLoadingUpdate(true);

      await vendorPresenter.getListVendorUpdate(vendorStore, {
        periodTime: formUpdate.getFieldValue('periodTime'),
        listVendor: listModalVendor.map(e => e.vendorId || '')
      });

      if (vendorStore.getListVendorRes.isSuccess) {
        message.success(vendorStore.getListVendorRes.message);

        getDataSearchVendor(dataSearch);
      } else {
        message.error(vendorStore.getListVendorRes.message);
      }
      form.resetFields();
      setSelectedRowKeys([]);
      setListModalVendor([]);
      setOpenModalUpdate(false);
      setLoadingUpdate(false);
    };

    useEffect(() => {
      window.scrollTo(0, 0);

    }, []);

    const handleSelect = (record: VendorModel, selected: boolean) => {
      const vendorId = record.vendorId;
      if (selected) {
        setSelectedRowKeys((keys) => [...keys, vendorId]);

        // setListModalVendor((prevList) => [
        //   ...prevList,
        //   ...listSalesEmployee.filter(e => e.vendorId === vendorId)
        // ]);

        setListModalVendor((prevList) => {
          const updatedList = [
            ...prevList,
            ...listSalesEmployee.filter(e => e.vendorId === vendorId),
          ];

          // Sort the updatedList according to the order of VendorTable
          return updatedList.sort((a, b) => {
            const indexA = listSalesEmployee.findIndex(v => v.vendorId === a.vendorId);
            const indexB = listSalesEmployee.findIndex(v => v.vendorId === b.vendorId);
            return indexA - indexB;
          });
        });
      } else {
        setSelectedRowKeys((keys) => keys.filter((key) => key !== vendorId));
        setListModalVendor((prevList) => prevList.filter(vendor => vendor.vendorId !== vendorId));
      }
    };


    const handleSelectAll = (selected: boolean, selectedRows: VendorModel[]) => {
      // Filter out any undefined or null rows before proceeding
      const validSelectedRows = selectedRows.filter((row): row is VendorModel => row !== undefined && row !== null);

      if (selected) {
        const newSelectedRowKeys = validSelectedRows.map((row) => row.vendorId);
        setSelectedRowKeys(newSelectedRowKeys);
        setListModalVendor((prevList) => [
          ...prevList,
          ...validSelectedRows.filter((row) => !prevList.some((item) => item.vendorId === row.vendorId))
        ]);
      } else {
        setSelectedRowKeys([]);
        setListModalVendor([]);
      }
    };
    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/Masterdata/SalesEmployee' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {userStore.userPermission?.includes('EXPORT_ACCOUNT_TEMPLATE') ? (
                <ExportButton
                  color="#eab308"
                  title='Export account template'
                  loading={loadingExportAccount}
                  onClick={async () => {
                    setLoadingExportAccount(true)
                    // await vendorPresenter.exportVendorTemplate(dataSearch)
                    const vendorService = createVendorServices();
                    await vendorService.exportVendorAccountTemplate(dataSearch);
                    setLoadingExportAccount(false)
                  }}
                />) : <></>}
              {userStore.userPermission?.includes('IMPORT_ACCOUNT') ? (
                <Upload
                  maxCount={1}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  showUploadList={false}
                  onChange={async (info) => {
                    if (info.file.status === "uploading") {
                      setLoadingImportAccount(true)
                    }
                    if (info.file.status === 'done') {
                      setLoadingImportAccount(false)
                      message.success(info.file.response.message);
                      // form.resetFields();
                      tableRef.current?.reload()
                      // form.setFieldValue("supplierNumber", info.file.response.data.join(','));
                      // form.submit();
                    }
                    if (info.file.status === 'error') {
                      setLoadingImportAccount(false)
                      message.error(info.file.response.message);
                    }
                  }}
                  action={BASE_API_URL + '/vendor/import-vendor-account'}
                  data={{ PlantCode: getPlantCode() }}
                  headers={{
                    Authorization: `Bearer ${getAccessToken()}`,
                  }}
                  name="File"
                >
                  <Button
                    // color='#14ae5c'
                    style={{
                      backgroundColor: '#22c55e',
                      borderColor: '#22c55e',
                      color: 'white',
                      boxShadow: '0 2px 0 rgba(0, 0, 0, 0.045)'
                    }}
                    type="primary"
                    className='hover:brightness-110 transition duration-300'
                    loading={loadingImportAccount}
                    icon={<Icon.Upload className='fill-white' />}>
                    Import Account
                  </Button>
                </Upload>) : <></>}
              {userStore.userPermission?.includes('EXPORT_TEMPLATE') ? (
                <ExportButton
                  color="#f59e0b"
                  title='Export template'
                  loading={loadingExport}
                  onClick={async () => {
                    setLoadingExport(true)
                    await vendorPresenter.exportVendorTemplate(dataSearch)
                    setLoadingExport(false)
                  }}
                />) : <></>}
              {userStore.userPermission?.includes('IMPORT') ? (
                <Upload
                  maxCount={1}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  showUploadList={false}
                  onChange={async (info) => {
                    if (info.file.status === "uploading") {
                      setLoadingImport(true)
                    }
                    if (info.file.status === 'done') {
                      setLoadingImport(false)
                      message.success(info.file.response.message);
                      form.resetFields();
                      form.setFieldValue("supplierNumber", info.file.response.data.join(','));
                      form.submit();
                      // setDataSearch(prevDataSearch => ({ ...prevDataSearch, }))
                      // tableRef.current?.reload()
                    }
                    if (info.file.status === 'error') {
                      message.error(info.file.response.message);
                    }
                  }}
                  action={BASE_API_URL + '/vendor/import-vendor'}
                  headers={{
                    Authorization: `Bearer ${getAccessToken()}`,
                  }}
                  name="File"
                >
                  <Button
                    // color='#14ae5c'
                    style={{
                      backgroundColor: '#14ae5c',
                      borderColor: '#14ae5c',
                      color: 'white',
                      boxShadow: '0 2px 0 rgba(0, 0, 0, 0.045)'
                    }}
                    type="primary"
                    className='hover:brightness-110 transition duration-300'
                    loading={loadingImport}
                    icon={<Icon.Upload className='fill-white' />}>
                    Import
                  </Button>
                </Upload>) : <></>}

              {userStore.userPermission?.includes('EDIT') ? (
                <CustomButton
                  type='primary'
                  loading={loadingUpdate}
                  onClick={() => setOpenModalUpdate(true)}

                >
                  Cập nhật
                </CustomButton>) : <></>}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<VendorModel>
            columns={columns}
            rowKey="vendorId"
            size="small"
            bordered
            actionRef={tableRef}
            search={false}
            tableAlertOptionRender={false}
            tableAlertRender={false}
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              type: 'checkbox',
              columnWidth: 30,
              fixed: true,
              alwaysShowAlert: false,
              onSelect: handleSelect,
              onSelectAll: handleSelectAll
            }}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const data: any = await getDataSearchVendor({
                ...dataSearch,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  ...dataSearch,
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: vendorStore.searchVendorResponse.isSuccess,
              };
            }}
            dataSource={listSalesEmployee}
            scroll={{ x: 2000, y: 500 }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: vendorStore.searchVendorResponse?.paging?.recordsTotal,
            }}

            options={false}
          />
        </BoxContainer>

        <SearchBox // tìm kiếm
          className="lg:!w-[35%] px-4"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm
            initialValues={initialValues}
            form={form}
            onFinish={(values) => {
              handleSearch(values);
            }}
            layout="vertical"
            column="col-1"
          >
            {/* mã nhà cung cấp */}
            <div className='relative'>
              <SearchItem label={t('masterdata.vendor.vendor-code')} name={'supplierNumber'}>
                <CustomInput
                  placeholder="Nhập mã nhà cung cấp"
                  allowClear
                  onChange={(e) => {
                    if (!e.target.value) {
                      searchMultiVendorRef.current?.clearData()
                    }
                  }} />
              </SearchItem>
              <div
                onClick={() => {
                  setShowModalVendor(true)
                }}
                className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                <HiOutlineExternalLink className="text-xl" />
              </div>
            </div>
            {/* họ và tên */}
            <SearchItem label={t('masterdata.vendor.full-name')} name={'supplierName'}>
              <CustomInput />
            </SearchItem>
            {/* Trạng thái */}
            <SearchItem label={'Trạng thái'} name={'actived'}>
              <CustomSelect options={statusList} className="!rounded-none" allowClear placeholder="-- Tất cả --" />
            </SearchItem>
            {/* Tài khoản nhà cung cấp */}
            <SearchItem label={'Tài khoản nhà cung cấp'} name={'isCreateAccount'}>
              <CustomSelect options={vendorList} className="!rounded-none" allowClear placeholder="-- Tất cả --" />
            </SearchItem >
            <SearchItem label={'Thời gian giao hàng'} name={'periodTime'}>
              <CustomSelect
                options={listSangChieu}
                // filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                filterOption={customFilterOption}
                showSearch
                className={`w-full font-[400] text-[14px] m-0 !min-w-[240px] `}
                allowClear
              />
            </SearchItem>

            {/* Tìm kiếm nhà cung câp */}
            <SearchMultiplePopup
              ref={searchMultiVendorRef}
              title="Nhà cung cấp"
              open={showModalVendor}
              onOpenChange={setShowModalVendor}
              onOk={(value) => {
                form.setFieldValue('supplierNumber', value)
              }}
              onReset={() => {
                form.setFieldValue('supplierNumber', undefined)
              }}
            />
          </SearchForm>
        </SearchBox>
        <Modal
          open={openModalUpdate}
          onCancel={() => {
            setOpenModalUpdate(false);
          }}
          width={800}

          footer={false}
          className='flex p-3'
          title={<h3 className="text-base font-bold uppercase">CẬP NHẬT THỜI GIAN GIAO HÀNG</h3>}
        >
          <hr className="mb-3 -mt-1"></hr>

          <SearchForm form={formUpdate} column='col-1'>
            <SearchItem label={t('Thời gian giao hàng')} name="periodTime" rules={[
              {
                required: true,
                message: 'Vui lòng chọn thời gian giao hàng',
              },
            ]}>
              <CustomSelect
                options={listSangChieu}

                filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
                showSearch
                className={`w-full font-[400] text-[14px] m-0 !min-w-[240px] `}
              />

            </SearchItem>
            <CustomProTable<VendorModel>
              columns={columnsModalDeliveryTime}
              rowKey="vendorId"
              size="small"
              bordered
              actionRef={tableRef}
              search={false}
              dataSource={listModalVendor}
              scroll={{ y: 250 }}
              options={false}
              pagination={{
                ...tablePagination(modalVendorSearch, setModalVendorSearch),
                total: listModalVendor.length,
              }}
            />
          </SearchForm>
          <div className='flex flex-row justify-end gap-x-3 my-2'>
            <CustomButton
              loading={loading}
              disabled={loading}
              onClick={() => setOpenModalUpdate(false)}
            >
              Huỷ
            </CustomButton>
            <CustomButton
              color=' #394562'
              style={{ color: "white" }}
              loading={loadingUpdate}
              onClick={updateListVendor}
            >
              Đồng ý
            </CustomButton></div>
        </Modal>
      </div>

    );
  });
};

export default vendorPage;
