import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { DataBranch, IBranchServices } from 'services/RawData/branch_services';

export type branchStore = {
  // Type default response search store
  searchBranchResponse: DataResponse<Array<DataBranch>>;
  // Type default response get detail store
  detailBranchResponse: DataResponse<DataBranch | undefined>;
  // Type default response update detail store
  updateDetailBranchResponse: DataResponse<boolean>;
  // Type default response create detail store
  createDetailBranchResponse: DataResponse<boolean>;
  // Type default response delete store
  deleteBranchResponse: DataResponse<boolean>;
};

export interface IBranchPresenter {
  createStore(): branchStore;
  // Search store
  searchBranch(store: branchStore, data: object): Promise<void>;
  // Search detail store
  getDetailBranch(store: branchStore, storeId: string): Promise<void>;
  // Update detail store
  updateDetailBranch(store: branchStore, params: object): Promise<void>;
  // Create detail store
  createDetailBranch(store: branchStore, data: object): Promise<void>;
  // Delete company
  deleteBranch(store: branchStore, storeId: string): Promise<void>;
}

export function createBranchPresenter({ branchServices }: { branchServices: IBranchServices }): IBranchPresenter {
  return {
    createStore: () =>
      observable({
        searchBranchResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        detailBranchResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        updateDetailBranchResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
        createDetailBranchResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
        deleteBranchResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
      }),
    // Search list store
    searchBranch: async (store, data) => {
      try {
        const result = await branchServices.searchBranch(data);
        runInAction(() => {
          store.searchBranchResponse.data = result.data;
          store.searchBranchResponse.isSuccess = result.isSuccess;
          store.searchBranchResponse.message = result.message;
          store.searchBranchResponse.paging = {
            totalCount: result.paging?.recordsTotal,
            totalPage: result.paging?.pagesCount,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Search detail store
    getDetailBranch: async (store, storeId) => {
      try {
        const result = await branchServices.getDetailBranch(storeId);
        runInAction(() => {
          store.detailBranchResponse.data = result.data;
          store.detailBranchResponse.isSuccess = result.isSuccess;
          store.detailBranchResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Update detail store
    updateDetailBranch: async (store, data) => {
      try {
        const result = await branchServices.updateDetailBranch(data);
        runInAction(() => {
          store.updateDetailBranchResponse.data = result.data;
          store.updateDetailBranchResponse.isSuccess = result.isSuccess;
          store.updateDetailBranchResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Create detail store
    createDetailBranch: async (store, data) => {
      try {
        const result = await branchServices.createDetailBranch(data);
        runInAction(() => {
          store.createDetailBranchResponse.data = result.data;
          store.createDetailBranchResponse.isSuccess = result.isSuccess;
          store.createDetailBranchResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Delete store
    deleteBranch: async (store, storeId) => {
      try {
        const result = await branchServices.deleteBranch(storeId);
        runInAction(() => {
          store.deleteBranchResponse.data = result.data;
          store.deleteBranchResponse.isSuccess = result.isSuccess;
          store.deleteBranchResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
