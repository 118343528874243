export const ROUTES = {
  HOME: '/',
  LOGIN: {
    BASE: 'login',
    LINK: '/login',
  },
  VERIFY: {
    BASE: 'verify',
  },
  MODULE: {
    BASE: '/Module/:id/*',
    TASK: {
      BASE: 'Task',
      LINK: '/Module/Task',
    },
  },
  // Đổi mật khẩu
  CHANGE_PASSWORD: {
    BASE: '/ChangePassword',
    LINK: '/ChangePassword',
  },

  CHANGE_PASSWORD_AFTER_90DAYS: {
    BASE: '/ChangePasswordAfter90Days',
    LINK: '/ChangePasswordAfter90Days',
  },

  HISTORY: {
    BASE: '/History',
    LINK: '/History'
  },

  // Purchase order
  PURCHASE_ORDER: {
    BASE: '/PurchaseOrder/*',
    LIST_PO: {
      BASE: 'ListPurchaseOrder',
      LINK: '/PurchaseOrder/ListPurchaseOrder',
      DETAIL: {
        BASE: 'Detail',
        LINK: '/PurchaseOrder/Detail'
      },
      PRINT_BARCODE: {
        BASE: 'printBarcode',
      },
      CREATE_VOTE: {
        BASE: 'VehicleRegistration/Create',
        LINK: '/PurchaseOrder/VehicleRegistration/Create'
      },
      EDIT_VOTE: {
        BASE: 'VehicleRegistration/:deliveryRegistrationId',
        LINK: '/PurchaseOrder/VehicleRegistration'
      },
    },
    ADD_PO: {
      BASE: "Create",
      LINK: '/PurchaseOrder/Create'
    },
    COPY_PO: {
      BASE: "Create/:purchaseOrderId",
      LINK: '/PurchaseOrder/Create'
    },
    EDIT_PO: {
      BASE: "/:purchaseOrderId",
      LINK: '/PurchaseOrder/'
    }
  },
  // Đồng bộ PO
  SYNCH_PO: {
    BASE: "synchPurchaseOrder",
    LINK: "/synchPurchaseOrder"
  },
  // Đăng ký giao hàng
  VEHICLE_REGISTRATION: {
    BASE: '/Registration/*',
    LIST_REGISTRATION: {
      BASE: 'VehicleRegistration',
      LINK: '/Registration/VehicleRegistration',
    },

  },
  PRINT_PDF: {
    BASE: '/Print/*',
    CONTENT: '/:deliveryRegistrationId'
  },

  // kết nối Microsoft
  CONNECT_MS: {
    BASE: "/Extensions/ConnectMicrosoft"
  },

  // Báo cáo
  REPORT: {
    BASE: 'Report/*',
    // Báo cáo danh sách xe đăng ký giao hàng
    DELIVERY_REGISTRATION: {
      BASE: "DeliveryRegistration",
      LINK: "/Report/DeliveryRegistration"
    },
    // Báo cáo kết quả giao hàng
    DELIVERY_RESULT: {
      BASE: "DeliveryResult",
      LINK: "/Report/DeliveryResult"
    },
    // Báo cáo tỷ lệ giao hàng đúng hẹn
    DELIVERY_RATE: {
      BASE: "DeliveryRate",
      LINK: "/Report/DeliveryRate"
    },
    // Receiving schedule of BHF
    RECEIVING_SCHEDULE: {
      BASE: 'ReceivingSchedule',
      LINK: "/Report/ReceivingSchedule"
    },
    EMAIL_SENDING_RESULT: {
      BASE: 'EmailSendingResult',
      LINK: "/Report/EmailSendingResult"
    }
  },
  // LỊCH NHẬN HÀNG
  RECEIVE_SCHEDULE: {
    BASE: 'ReceiveSchedule',
    LINK: '/ReceiveSchedule',
  },

  ICONS: {
    BASE: '/Icons',
  },

  // ===================================================================================================================
  // Dữ liệu nền
  MASTER_DATA: {
    BASE: 'MasterData/*',
    // Công ty
    COMPANY: {
      BASE: 'Company/*',
      CREATE: 'Create',
      EDIT: 'Edit/:id',
    },
    // Chi nhánh
    BRANCH: {
      BASE: 'Store/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Phòng ban
    DEPARTMENT: {
      BASE: 'Department/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Kho
    STOCK: {
      BASE: 'Warehouse/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Danh mục dùng chung
    CATALOG: {
      BASE: 'Catalog/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Loại danh mục dùng chung
    CATALOGTYPE: {
      BASE: 'CatalogType/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Nhân viên
    SALES_EMPLOYEE: {
      BASE: 'SalesEmployee/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Nhà cung cấp
    VENDOR: {
      BASE: 'Vendor/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Tài xế
    DRIVER: {
      BASE: 'Driver/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Sản phẩm
    PRODUCT: {
      BASE: 'Product/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Tỉnh/Thành phố
    PROVINCE: {
      BASE: 'Province/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Quận huyện
    DISTRICT: {
      BASE: 'District/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // phường xã
    WARD: {
      BASE: 'Ward/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // phân loại vật tư
    BRAND: {
      BASE: 'Brand/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // nhóm vật tư
    CATEGORY: {
      BASE: 'Category/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // Công việc
    TASK: {
      BASE: 'Task/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
  },

  // Sản phẩm
  SALES: {
    BASE: 'Sale/*',
    // sản phẩm sub
    PRODUCT: {
      BASE: 'Product/*',
      LINK: '/Sale/Product',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // nhãn hàng
    BRAND: {
      BASE: 'Brand/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // SUB-BRAND
    SUBBRAND: {
      BASE: 'SubBrand/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // ngành hàng
    GROUP: {
      BASE: 'Group/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    // SUB GROUP/SUB CATE
    SUBGROUP: {
      BASE: 'SubGroup/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
  },
  // Permission
  PERMISSION: {
    BASE: 'Permission/*',
    // Config account
    CONFIG_ACCOUNT: {
      BASE: 'ConfigAccount/*',
      CREATE: 'Create',
      EDIT: 'Edit/:id',
    },
    // Account VOIP
    CONFIG_ACCOUNT_VOIP: {
      BASE: 'AccountVOIP/*',
      CREATE: 'Create',
      EDIT: 'Edit/:id',
    },
    ROLES: {
      BASE: 'Roles/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    ACCESSWEB: {
      BASE: 'Access/*',
    },
    ACCESSMOBILE: {
      BASE: 'AccessMobile/*',
    },
    ACCOUNT: {
      BASE: 'Account/*',
      CREATE: 'Create',
      CREATE_VENDOR_ACCOUNT: 'CreateVendorAccount/:id',
      EDIT: ':id',
      CHANGE_PASSWORD: 'ChangePassword/:id',
      RESET_PASSWORD: 'ResetPassword/:id',
      VIEW_HISTORY: 'History/:id'
    },
    PAGE: {
      BASE: 'Page/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    MENU: {
      BASE: 'Menu/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    MOBILE_SCREEN: {
      BASE: 'MobileScreen/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
    FUNCTION: {
      BASE: 'function/*',
      CREATE: 'Create',
      EDIT: ':id',
    }
  },
  // Customer
  CUSTOMER: {
    BASE: '/Customer/*',
    PROFILE_CONFIG: {
      BASE: '/ProfileConfig/*',
      CREATE: 'Create',
      EDIT: ':id',
    },
  },

};
