import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { DataUserConfig, IUserConfigServices } from 'services/User/userConfig_services';

export type userConfigStore = {
  // Type default response get list
  listUserConfigResponse: DataResponse<Array<DataUserConfig>>;
};

export interface IUserConfigPresenter {
  createStore(): userConfigStore;
  // List user config
  getListUserConfig(store: userConfigStore): Promise<void>;
}

export function createUserConfigPresenter({
  userConfigServices,
}: {
  userConfigServices: IUserConfigServices;
}): IUserConfigPresenter {
  return {
    createStore: () =>
      observable({
        listUserConfigResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
      }),

    // List user config
    getListUserConfig: async (store) => {
      try {
        const result = await userConfigServices.getUserConfigList();
        runInAction(() => {
          store.listUserConfigResponse.data = result.data;
          store.listUserConfigResponse.isSuccess = result.isSuccess;
          store.listUserConfigResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
