import { observer } from 'mobx-react'
import PageWrapper from 'pages/Components/PageWrapper'
import CustomButton from 'base/ui/components/Button';
import { Filter } from 'base/ui/components/Icons';
import { useRef, useState } from 'react';
import BoxContainer from 'base/ui/components/BoxContainer';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { DatePicker, Form, Input, Select } from 'antd';
import { dataReport } from '../ListRigistration/mock';
import { ActionType, ProColumns } from '@ant-design/pro-components';
import { formatNumber } from 'utils/formatHelper';
import { SearchModel } from 'pages/model';
import { PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';
import { useResizableColumns } from 'utils/reportHelper';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { ExportButton } from 'pages/Components/FunctionButton';
import SaveReportForm from 'pages/Components/reportForm';


type ReportModel = typeof dataReport[0]
const createDeliveryRate = () => {
    return observer(() => {
        document.title = 'Báo cáo - ' + SYSTEM_TITLE;
        const [open, setOpen] = useState(false);

        // show filter form
        const showDrawer = () => {
            setOpen(true);
        };
        // close filter form
        const onClose = () => {
            setOpen(false);
        };

        // ========== SEARCH =================
        const [searchForm] = Form.useForm()

        // =========== TABLE && MẪU BÁO CÁO =================
        const [columns, setColumns] = useState<ProColumns<ReportModel>[]>([
            {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                fixed: 'left',
                width: 50,
                align: 'center',
            },
            {
                title: 'Nhà cung cấp',
                dataIndex: 'ncc',
                key: 'ncc',
                fixed: 'left',
                width: 250,
                ellipsis: true,
            },
            {
                title: 'Mã đăng ký',
                dataIndex: 'registrationCode',
                key: 'registrationCode',
                width: 100,
                fixed: 'left',
                align: 'center',
            },
            {
                title: 'Mã PO',
                dataIndex: 'poNumber',
                key: 'poNumber',
                width: 100,
                align: 'center',
            },
            {
                title: 'Item',
                dataIndex: 'poItem',
                key: 'poItem',
                width: 100,
                align: 'center',
            },

            {
                title: 'Tên hàng',
                dataIndex: 'productName',
                key: 'productName',
                width: 250,
                ellipsis: true
            },
            {
                title: 'Trạng thái cân',
                dataIndex: 'tinhTrangCan',
                key: 'tinhTrangCan',
                width: 120,
            },
            {
                title: 'Trạng thái nhận hàng',
                dataIndex: 'tinhTrangNhanHang',
                key: 'tinhTrangNhanHang',
                width: 150,
            },
            {
                title: 'Thời gian dự kiến',
                dataIndex: 'tgGHDuKien',
                key: 'tgGHDuKien',
                width: 150,
                align: 'center',
                valueType: 'dateTime',
                fieldProps: {
                    format: 'DD/MM/YYYY HH:mm:ss',
                },
            },
            {
                title: 'Thời gian thực tế',
                dataIndex: 'tgGHThucTe',
                key: 'tgGHThucTe',
                width: 150,
                align: 'center',
                valueType: 'dateTime',
                fieldProps: {
                    format: 'DD/MM/YYYY HH:mm:ss',
                },
            },
            {
                title: 'Số lượng giao',
                dataIndex: 'slGiao',
                key: 'slGiao',
                children: [{
                    title: 'Dự kiến',
                    dataIndex: 'slDuKien',
                    key: 'slDuKien',
                    width: 100,
                    onCell: () => ({ className: 'text-right' }),
                    render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
                },
                {
                    title: 'Thực tế',
                    dataIndex: 'slThucTe',
                    key: 'slThucTe',
                    width: 100,
                    onCell: () => ({ className: 'text-right' }),
                    render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
                }]
            },
            {
                title: 'Tỷ lệ(%)',
                dataIndex: 'tyLe',
                key: 'tyLe',
                width: 80,
                onCell: () => ({ className: 'text-right' }),
            },
            {
                title: 'Trạng thái giao hàng',
                dataIndex: 'trangThaiGiaoHang',
                key: 'trangThaiGiaoHang',
                width: 150,
            },
            {
                title: 'Nguyên nhân trễ hẹn',
                dataIndex: 'nguyenNhan',
                key: 'nguyenNhan',
                width: 150,
            },


        ])
        const [dataPaging, setDataPaging] = useState<SearchModel>({
            paging: {
                pageSize: PAGE_SIZE_DEFAULT,
                pageIndex: 1,
                orderBy: undefined,
                orderByDesc: undefined,
            },

        });
        const [dataTable, setDataTable] = useState<ReportModel[]>(dataReport)

        // mẫu báo cáo
        const tableRef = useRef<ActionType>();
        const constDataName = 'BCKetQuaGiaoHang';
        const {
            isResizablePresent,
            setIsResizablePresent,
            resizableColumnSelect,
            columnsState,
            handleColumnsStateChange,
            templateReportModalRef,
            userReports,
            selectedReportId,
            templateReportModalProps,
            handleResize,
        } = useResizableColumns<ReportModel>(columns, setColumns, constDataName);

        return (
            <PageWrapper
                breadcrumb={[{
                    text: 'Báo cáo tỷ lệ giao hàng đúng hẹn'
                }]}
                extras={
                    <>
                        <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                            <Filter className="!fill-primary" />
                        </CustomButton>
                    </>
                }
            >
                <BoxContainer>
                    <CustomProTable<ReportModel>
                        bordered
                        columns={columns}
                        dataSource={dataTable}
                        actionRef={tableRef}

                        tableAlertRender={false}
                        handleResize={handleResize}
                        isResizablePresent={isResizablePresent}
                        resizableColumnSelect={resizableColumnSelect}
                        columnsState={{
                            value: Object.keys(columnsState).length > 0 ? columnsState : undefined,
                            onChange: (newColumnsState) => {
                                handleColumnsStateChange(newColumnsState);
                            },
                        }}
                        toolBarRender={() => [
                            <ExportButton />,
                            // nút lưu mẫu bc
                            <SaveReportForm
                                setIsResizablePresent={setIsResizablePresent}
                                templateReportModalRef={templateReportModalRef}
                                userReports={userReports}
                                selectedReportId={selectedReportId}
                                templateReportModalProps={templateReportModalProps}
                            />,
                        ]}
                        rowKey="stt"
                        pagination={{
                            ...tablePagination(dataPaging, setDataPaging),
                            total: 0,
                        }}
                    />

                </BoxContainer>
                <SearchBox
                    onClose={onClose}
                    className="lg:!w-[50%]"
                    open={open}
                    footer={
                        <CustomButton
                            htmlType="submit"
                            className="my-auto ml-auto"
                            type="primary"
                            onClick={() => {

                            }}
                        >
                            Tìm kiếm
                        </CustomButton>
                    }
                >
                    <SearchForm
                        form={searchForm}
                        onFinish={(values) => {
                            // tableRef.current?.reload();
                        }}
                    >
                        {/* Mã phiếu */}
                        <SearchItem name={'purchaseOrderCode'} label={'Mã phiếu'}>
                            <Input />
                        </SearchItem>
                        {/* Mã PO */}
                        <SearchItem name={'purchaseOrderCode'} label={'Mã PO'}>
                            <Input />
                        </SearchItem>
                        {/* Mã NCC */}
                        <SearchItem name={'purchaseOrderCode'} label={'Mã NCC'}>
                            <Input />
                        </SearchItem>
                        {/* Tên hàng hóa */}
                        <SearchItem name={'purchaseOrderCode'} label={'Tên hàng hóa'}>
                            <Select />
                        </SearchItem>
                        {/* Tên NCC */}
                        <SearchItem name={'purchaseOrderCode'} label={'Tên NCC'}>
                            <Input />
                        </SearchItem>
                        {/* Trạng thái cân */}
                        <SearchItem name={'purchaseOrderCode'} label={'Trạng thái cân'}>
                            <Select />
                        </SearchItem>
                        {/* Trạng thái nhận hàng */}
                        <SearchItem name={'purchaseOrderCode'} label={'Trạng thái nhận hàng'}>
                            <Select />
                        </SearchItem>
                        {/* Trạng thái giao hàng */}
                        <SearchItem name={'purchaseOrderCode'} label={'Trạng thái giao hàng'}>
                            <Select />
                        </SearchItem>
                        {/* TG giao hàng dự kiến */}
                        <SearchItem label={'TG giao hàng dự kiến'} start>
                            <Select />
                        </SearchItem>
                        {/* Từ ngày */}
                        <SearchItem name={'documentDateFrom'} label={'Từ ngày'} start>
                            <DatePicker className="w-full" />
                        </SearchItem>
                        {/* Đến ngày */}
                        <SearchItem name={'documentDateTo'} label={'Đến ngày'}>
                            <DatePicker className="w-full" />
                        </SearchItem>
                        {/* TG giao hàng thực tế */}
                        <SearchItem label={'TG giao hàng thực tế'} start>
                            <Select />
                        </SearchItem>
                        {/* Từ ngày */}
                        <SearchItem name={'documentDateFrom'} label={'Từ ngày'} start>
                            <DatePicker className="w-full" />
                        </SearchItem>
                        {/* Đến ngày */}
                        <SearchItem name={'documentDateTo'} label={'Đến ngày'}>
                            <DatePicker className="w-full" />
                        </SearchItem>
                    </SearchForm>
                </SearchBox>
            </PageWrapper>
        )
    })
}

export default createDeliveryRate