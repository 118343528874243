import { PDFViewer } from '@react-pdf/renderer';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const vehicleRegistrationRoutes = ({
    List,
}: {
    List: React.ComponentType;
    // PrintPDF: React.ComponentType;
}) => (
    <Routes>
        <Route path={ROUTES.VEHICLE_REGISTRATION.LIST_REGISTRATION.BASE} element={<List />} />
    </Routes>
);
