import * as Icon from 'base/ui/components/Icons';

import { Form, Modal, Tooltip, message } from 'antd';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useEffect, useState } from 'react';
import {
  branchPresenter,
  branchStore,
  commonMasterDataPresenter,
  commonMasterDataStore,
  provincePresenter,
  provinceStore,
  userStore,
} from 'stores/root_store';
import { PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { usePermission } from 'hooks/usePermission';
import { observer } from 'mobx-react';
// import { SearchModel } from 'pages/model';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { HiSearch } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import { DataBranch } from 'services/RawData/branch_services';
import { CommonModel } from 'services/RawData/commonMasterData_services';
import { Province } from 'services/RawData/province_services';
import { debounce } from 'utils/debounce';
import { dropdownStatus } from 'utils/dropdown';
import { removeEmptyValues } from 'utils/transformHelper';
import { DataSearchBranch } from '../model';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { SearchModel } from 'pages/model';
import { getCompanyId } from 'utils/localStorageHelper';

const { confirm } = Modal;

const createBranch = () => {
  return observer(() => {
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    // translate en/vn
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    // Đối với web này thì Chi nhánh -> Trung tâm bảo hành
    const pagetitle = t('masterdata.store.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;

    //==========================STATE===================//
    // Loading all page
    const [loading, setLoading] = useState(false);
    // List data company
    const [listDataBranch, setListDataBranch] = useState<DataBranch[]>([]);
    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
      companyId: getCompanyId()
    });

    const [openDrawer, setOpenDrawer] = useState(false);

    // List organization
    const [listOrg, setListOrg] = useState<CommonModel[]>([]);

    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Columns table title
    const defaultColumn: ProColumns<DataBranch>[] = [
      // STT
      {
        title: t('masterdata.store.stt'),
        dataIndex: 'stt',
        width: 25,
        key: 'stt',
        className: '!rounded-none !py-1',
        align: 'center',
        fixed: 'left',
        render: (text) => <div className="!text-center">{text}</div>,
      },
      // Mã trung tâm bảo hành
      {
        title: t('masterdata.store.branch-code'),
        dataIndex: 'saleOrgCode',
        width: 50,
        key: 'saleOrgCode',
        className: '!rounded-none !py-1',
        fixed: 'left',
        render: (_, record) => userStore.userPermission?.includes('EDIT') ? <Link to={record.storeId}>{record.saleOrgCode}</Link> : _,
      },
      // Tên trung tâm bảo hành
      {
        title: t('masterdata.store.branch-name'),
        dataIndex: 'storeName',
        width: 100,
        key: 'storeName',
        className: '!rounded-none !py-1',
      },
      // Công ty
      {
        title: t('masterdata.store.company'),
        dataIndex: 'companyName',
        width: 75,
        key: 'companyName',
        className: '!rounded-none !py-1',
      },
      // Tỉnh thành
      {
        title: t('masterdata.store.province'),
        dataIndex: 'provinceName',
        width: 50,
        key: 'provinceName',
        className: '!rounded-none !py-1',
      },
      // Trạng thái
      {
        title: t('masterdata.store.status'),
        dataIndex: 'actived',
        width: 30,
        key: 'actived',
        className: '!rounded-none !py-1',
        render: (_, record) => {
          return (
            <div>
              {record.actived ? (
                <Tooltip title="Đang sử dụng">
                  <Icon.CheckStatus className="w-4 text-sm text-center mx-auto !fill-[#389E0D]" />
                </Tooltip>
              ) : (
                <Tooltip title="Ngưng sử dụng">
                  <Icon.CloseStatus className="w-4 text-sm text-center mx-auto !fill-[#D93133E5]" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      // Chức năng
      {
        title: t('masterdata.store.actions'),
        dataIndex: 'function',
        width: 40,
        key: 'function',
        className: '!rounded-none !py-1',
        fixed: 'right',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') ? (
                <Tooltip title={t('btn.edit')}>
                  <CustomButton
                    className="!w-6 !h-6 rounded-sm !border-[#4DA8FF] flex gap-1 items-center justify-center !bg-[#C9E8FF] hover:bg-[#95D2FF]"
                    icon={<Icon.PencilEdit className="!fill-[#007BF1]" width="16" height="16" />}
                    onClick={() => navigate(`${record.storeId}`)}
                  />
                </Tooltip>
              ) : null}
              {/* {userStore.userPermission?.includes('DELETE') ? (
                record.actived ? (
                  <Tooltip title={t('btn.delete')}>
                    <CustomButton
                      className="!w-6 !h-6 rounded-sm !border-[#FF4F51] flex gap-1 items-center justify-center !bg-[#FFE5E6] hover:bg-[#FF8081]"
                      icon={<Icon.Trash className="!fill-[#FC5C5E]" width="16" height="16" />}
                      onClick={() => {
                        showDeleteConfirm(record);
                      }}
                    />
                  </Tooltip>
                ) : (
                  <CustomButtonIcon color="#fff00" className="border-none outline-none cursor-default">
                    <Icon.Trash className="!fill-transparent" width="14" height="14" />
                  </CustomButtonIcon>
                )
              ) : null} */}
            </div>
          );
        },
      },
    ];

    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<DataBranch>[]>(defaultColumn);

    // Function handle resize columns
    const handleResize =
      (index: number) =>
        (e: any, { size }: any) => {
          // Set columns
          setColumn((prevColumns) => {
            const nextColumns = [...prevColumns];
            nextColumns[index] = {
              ...nextColumns[index],
              width: size.width,
            };
            return nextColumns;
          });
        };

    // Call api search branch
    const getDataSearchBranch = async (dataSearch: DataSearchBranch) => {
      await branchPresenter.searchBranch(branchStore, dataSearch);
      if (branchStore.searchBranchResponse.isSuccess) {
        setListDataBranch(branchStore.searchBranchResponse.data);
      }
    };
    // Call api get list company
    const getListOrg = async () => {
      await commonMasterDataPresenter.getDropdownCompanyV2(commonMasterDataStore);
      if (commonMasterDataStore.listCompany.length) {
        setListOrg(commonMasterDataStore.listCompany);
        form.setFieldValue('companyId', commonMasterDataStore.listCompany[0])
      }
    };


    useEffect(() => {
      Promise.all([getListOrg()]);
    }, []);

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        ...values,
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
      };

      removeEmptyValues(tempDataSearch);
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);

      await getDataSearchBranch(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };



    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
      <div className="w-full">
        {/* ==================================== Breadcrumb ========================================= */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/MasterData/Store' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {/* Yêu cầu ở task jira DGWWAR-53 */}
              {/* {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  type="primary"
                  onClick={() => navigate('Create')}
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null} */}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<DataBranch>
            columns={columns}
            handleResize={handleResize}
            rowKey="storeId"
            bordered
            search={false}
            loading={loading}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const data: any = await getDataSearchBranch({
                ...dataSearch,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });
              if (Object.values(sort).length) {
                setDataSearch({
                  ...dataSearch,
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: branchStore.searchBranchResponse.isSuccess,
              };
            }}
            dataSource={listDataBranch}
            scroll={{ x: 1000, y: 500 }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: branchStore.searchBranchResponse.paging?.recordsTotal,
            }}
            options={false}
          />
        </BoxContainer>
        <SearchBox
          className="lg:!w-[35%]"
          footer={[
            // tìm kiếm
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          {/* Form nhập tìm kiếm */}
          <SearchForm column="col-1" form={form} onFinish={handleSearch}>
            <button type='submit' hidden></button>
            {/* Công ty */}
            <SearchItem label={t('masterdata.store.company')} name={'companyId'}>
              <CustomSelect
                className="w-full"
                options={listOrg}
                fieldNames={{ label: 'value', value: 'key' }}
                allowClear
              />
            </SearchItem>
            {/* Mã chi nhánh */}
            <SearchItem label={t('masterdata.store.branch-code')} name={'saleOrgCode'}>
              <CustomInput />
            </SearchItem>
            {/* Tên chi nhánh */}
            <SearchItem label={t('masterdata.store.branch-name')} name={'storeName'}>
              <CustomInput />
            </SearchItem>

          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default createBranch;
