/* eslint-disable react-hooks/exhaustive-deps */

import { DATE_FORMAT_DEFAULT, SYSTEM_TITLE } from 'utils/constants';
import { Form, Radio, Spin } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useEffect, useState } from 'react';
import { userConfigPresenter, userConfigStore } from 'stores/root_store';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomInputNumber from 'base/ui/components/CustomInputNumber';
import CustomRadio from 'base/ui/components/CustomRadio';
import moment from 'moment';
import { observer } from 'mobx-react';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

/**
 *  Màn hình này chỉ hiển thị lên dữ liệu xem thôi
 *  Cấu hình tay ở DB nhé (BE Nam bảo vậy)
 * */
const createConfigAccount = () => {
  return observer(() => {
    // usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    const { t } = useTranslation();
    const pagetitle = t('page-title.config-account');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    //==========================STATE===================//
    // Loading
    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();

    // Handle create
    const onFinish = async (values: any) => {
      console.log('values>>>', values);
    };

    // Handle call api get list user config VOIP
    const getListUserConfig = async () => {
      await userConfigPresenter.getListUserConfig(userConfigStore);
      if (userConfigStore.listUserConfigResponse.isSuccess) {
        form.setFieldsValue(userConfigStore.listUserConfigResponse.data[0]);
        form.setFieldValue(
          'expireDate',
          moment(userConfigStore.listUserConfigResponse.data[0].expireDate).format(DATE_FORMAT_DEFAULT),
        );
        setLoading(false);
      }
    };

    useEffect(() => {
      setLoading(true);
      getListUserConfig();
    }, []);

    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: pagetitle,
                link: '/Permission/ConfigAccount',
              },
            ]}
          />
          <BoxContainer fullHeight>
            {/* Form input */}
            <Spin spinning={loading}>
              <SearchForm form={form} onFinish={onFinish}>
                {/* Số lượng user */}
                <SearchItem label={t('permission.config-account.number-user')} name="numberOfUser">
                  <CustomInputNumber disabled />
                </SearchItem>
                {/* Có sử dụng VOIP */}
                <SearchItem name="isUserVOIPUser" label={t('permission.config-account.is-use-voip')}>
                  <Radio.Group disabled>
                    <CustomRadio value={true}>{t('radio-btn.have-use')}</CustomRadio>
                    <CustomRadio value={false}>{t('radio-btn.not-use')}</CustomRadio>
                  </Radio.Group>
                </SearchItem>
                {/* Số lượng user VOIP */}
                <SearchItem label={t('permission.config-account.number-user-voip')} name="numberOfVOIPUser">
                  <CustomInputNumber max={10} disabled />
                </SearchItem>
                {/* Ngày hết hạn */}
                <SearchItem label={t('permission.config-account.expire-date')} name="expireDate">
                  <CustomInput disabled />
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createConfigAccount;
