import createStock from '.';
import createAddUpdateStock from './CreateUpdate';
import { branchRoutes } from './routes';

export const createStockPage = () => {
  const Stock = createStock();
  const AddStock = createAddUpdateStock();
  const UpdateStock = createAddUpdateStock();

  const StockPage = branchRoutes;
  return () => <StockPage Stock={Stock} AddStock={AddStock} UpdateStock={UpdateStock} />;
};
