import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const taskRoutes = ({
  Task,
  Add,
  Update,
}: {
  Task: React.ComponentType;
  Add: React.ComponentType;
  Update: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Task />} />
    <Route path={ROUTES.MASTER_DATA.TASK.CREATE} element={<Add />} />
    <Route path={ROUTES.MASTER_DATA.TASK.EDIT} element={<Update />} />
  </Routes>
);
