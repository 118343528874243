import { ProColumns } from '@ant-design/pro-components';
import { Form, Modal, Select, message } from 'antd'
import CustomProTable from 'base/ui/components/CustomTable';
import { SearchItem } from 'pages/Components/SearchBox';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { ProductModel } from 'services/RawData/product_services';
import { commonMasterDataStore, productPresenter, productStore, userStore } from 'stores/root_store';
import CustomButton from 'base/ui/components/Button';

type ModalUpdateProps = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    product: ProductModel[];
    handleReload: () => void;
}

const ModalUpdate: React.FC<ModalUpdateProps> = (props) => {
    const { open, onOpenChange, product: dataTable, handleReload } = props
    const [form] = Form.useForm()
    const [isSubmit, setIsSubmit] = useState(false)
    const defaultColumn: ProColumns<ProductModel>[] = [
        // Stt
        {
            title: "STT",
            dataIndex: 'stt',
            key: 'stt',
            width: 50,
            align: 'center',
            render: (text) => <div className="!text-center">{text}</div>,
            fixed: 'left',
        },
        // Loại hàng hóa
        {
            title: "Loại hàng hóa",
            dataIndex: 'productType',
            key: 'productType',
            width: 100,
        },
        // Mã hàng hóa
        {
            title: "Mã hàng hóa",
            dataIndex: 'productCode',
            key: 'productCode',
            width: 100,
            render: (_, record) => {
                return userStore.userPermission?.includes('EDIT') ? (
                    <Link to={`/MasterData/Product/${record.productId}`}>{record.productCode}</Link>
                ) : (
                    <div>{record.productCode}</div>
                );
            },
        },
        // Tên hàng hóa
        {
            title: "Tên hàng hóa",
            dataIndex: 'productName',
            key: 'productName',
            width: 250,
        },
    ];

    const handleUpdate = async (value: { productType: string }) => {
        const dataUpdate = {
            listProductId: dataTable.map(data => data.productId),
            productType: value.productType
        }
        setIsSubmit(true)
        await productPresenter.updateMultiProduct(productStore, dataUpdate)
        setIsSubmit(false)
        if (productStore.crudResponse.isSuccess) {
            message.success(productStore.crudResponse.message)
            onOpenChange(false)
            handleReload()
            form.resetFields()
        } else {
            message.error(productStore.crudResponse.message)
        }
    }

    return (
        <Modal open={open}
            onCancel={() => onOpenChange(false)}
            width={850}
            title={<span className='uppercase text-base'>Chỉnh sửa thông tin hàng hóa</span>}
            onOk={() => {
                form.submit()
            }}
            okButtonProps={{
                loading: isSubmit
            }}
        >
            <div>
                <div className='grid grid-cols-2 mb-5'>
                    <Form
                        form={form}
                        onFinish={handleUpdate}
                    >
                        <SearchItem
                            colon={false}
                            name="productType"
                            label="Loại hàng hóa"
                            rules={[{ required: true, message: 'Vui lòng chọn loại hàng hóa.' }]}
                        >
                            <Select
                                options={commonMasterDataStore?.productTypes.map((type) => ({
                                    label: type.value,
                                    value: type.key
                                }))}
                                allowClear
                                placeholder={'Vui lòng chọn'}
                            />
                        </SearchItem>
                    </Form>
                </div>
                <CustomProTable<ProductModel>
                    columns={defaultColumn}
                    rowKey="productId"
                    size="small"
                    bordered
                    scroll={{ x: 'auto', y: 500 }}
                    tableAlertRender={false}
                    dataSource={dataTable}
                    pagination={false}
                    options={false}
                />
            </div>
        </Modal>
    )
}

export default ModalUpdate