import { Form, Input, Modal } from 'antd'
import { DeliveryRegistrationModel } from 'models/DeliveryRegistration';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox'
import React from 'react'
import { IoIosInformationCircle } from "react-icons/io";
import CustomButton from 'base/ui/components/Button';

export type DeleteDelivaryConfirmProps = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    dataDelete?: Pick<DeliveryRegistrationModel, 'deliveryRegistrationCode' | "deliveryRegistrationId">
    onFormSubmit: (value: Promise<{ cancelReason: string; deliveryRegistrationId: string }>) => void
    loading: boolean
}

const DeleteDeliveryConfirm: React.FC<DeleteDelivaryConfirmProps> = (props) => {
    const { open, onOpenChange, dataDelete, onFormSubmit, loading } = props

    const [deleteForm] = Form.useForm()

    return (
        <Modal
            destroyOnClose
            open={open}
            onCancel={() => {
                deleteForm.resetFields()
                onOpenChange(false)
            }}
            footer={false}
            title={<span className='flex items-center gap-2'>
                <IoIosInformationCircle className='text-3xl text-orange-500' /><p>Xác nhận xóa phiếu đăng ký "{dataDelete?.deliveryRegistrationCode}"</p>
            </span>}
        >
            <h2>Bạn có chắc muốn xóa phiếu "{dataDelete?.deliveryRegistrationCode}"</h2>
            <SearchForm
                column='col-1'
                form={deleteForm}
            >
                <SearchItem
                    name="cancelReason"
                    rules={[{ required: true, message: 'Vui lòng nhập lý do!' }]}
                    label={<span className="font-semibold text-default">Lý do</span>} labelCol={{ span: 24 }}>
                    <Input.TextArea placeholder="Nhập lý do hủy phiếu..." style={{ borderRadius: 2 }} rows={3} />
                </SearchItem>
                <div className='flex justify-end gap-3 pt-3'>
                    <CustomButton loading={loading} onClick={() => {
                        deleteForm.resetFields()
                        onOpenChange(false)
                    }} htmlType='button'>
                        Hủy
                    </CustomButton>
                    <CustomButton loading={loading} htmlType='button'
                        onClick={() => {
                            deleteForm.validateFields().then((value) => {
                                onFormSubmit(Promise.resolve({
                                    ...value,
                                    deliveryRegistrationId: dataDelete?.deliveryRegistrationId || '',
                                }))
                            }).catch((error) => { onFormSubmit(Promise.reject(error)) })
                        }}
                        type='primary' color='#ef4444'>
                        Xóa
                    </CustomButton>
                </div>
            </SearchForm>
        </Modal>
    )
}

export default DeleteDeliveryConfirm