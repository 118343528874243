import { useEffect, useState } from 'react';

const useGeolocationCoordinates = () => {
  // const [coords, setCoords] = useState<GeolocationCoordinates | null>(null);

  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      if (permissionStatus.state !== 'denied') {
        navigator.geolocation.getCurrentPosition((position) => {
          // setCoords(position.coords);
          localStorage.setItem(
            'coords',
            JSON.stringify({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            }),
          );
        });
      } else {
        localStorage.setItem('coords', JSON.stringify({}));
        // setCoords(null);
        // alert('You have denied location access. Please enable it in your browser settings.');
      }
    });
  }, [navigator.permissions]);

  // return coords;
};

export default useGeolocationCoordinates;
