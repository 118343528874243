/* eslint-disable react-hooks/exhaustive-deps */
import * as Icon from 'base/ui/components/Icons';

import { Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select, Space, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { catalogPresenter, catalogStore, commonMasterDataPresenter, commonMasterDataStore, mobileScreenPresenter, mobileScreenStore, pagePresenter, pageStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { SYSTEM_TITLE } from 'utils/constants';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

const createAddUpdateMobileScreen = () => {
    return observer(() => {
        // translate en/vn
        const { t } = useTranslation();
        // Get data params from url
        const { id: idParams } = useParams();
        // Check id để đặt tên trang
        let pageTitle = '';

        // Nếu có id là chỉnh sửa (xem chi tiết)
        if (idParams) {
            // Kiểm tra phân quyền
            usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
            pageTitle = t('page-title.update');
            document.title = pageTitle + ' - ' + SYSTEM_TITLE;
        } else {
            // Kiểm tra phân quyền
            usePermission('CREATE');
            pageTitle = t('page-title.create');
            document.title = pageTitle + ' - ' + SYSTEM_TITLE;
        }

        const navigate = useNavigate();
        // Check button save and save continue
        let isSaveAndContinue = false;

        const [form] = Form.useForm();
        // Loading
        const [loading, setLoading] = useState(false);

        //=================================================FUNCTION===========================================================//
        //=================================================FUNCTION===========================================================//
        // Call api detail store
        const getDetailPage = async () => {
            setLoading(true);
            await mobileScreenPresenter.getDetailMobileScreen(mobileScreenStore, idParams || '');
            if (mobileScreenStore.screenDetail.isSuccess) {
                form.setFieldsValue(mobileScreenStore.screenDetail.data);
                const functionList = mobileScreenStore.screenDetail.data?.activedFunctionList?.map(item => item.functionId)
                form.setFieldValue('functionId', functionList)
                setLoading(false);
            } else {
                navigate('/Permission/MobileScreen');
            }
        };

        // Handle create / update 
        const onFinish = async (values: any) => {
            setLoading(true);
            removeEmptyValues(values);
            if (!idParams) {
                // create 
                const result = await mobileScreenPresenter.createMobileScreen(values);
                if (result) {
                    // Redirect to search page routing (Button Save)
                    if (!isSaveAndContinue) {
                        navigate('/Permission/MobileScreen');
                    }
                    form.resetFields();
                }
            } else {
                // update 
                const result = await mobileScreenPresenter.updateMobileScreen({
                    ...values,
                    actived: JSON.parse(values.actived),
                    mobileScreenId: idParams,
                });
                if (result) {
                    // Redirect to search page routing (Button Save)
                    if (!isSaveAndContinue) {
                        navigate('/Permission/MobileScreen');
                    }
                }
            }
            setLoading(false);
        };

        const getListFunction = async () => {
            if (!pageStore.listFunctions.length) {
                await pagePresenter.getAllFunction(pageStore)
            }
        }
        const getDropDownMenu = async () => {
            if (!pageStore.dropdownMenu.length) {
                await pagePresenter.getDropdownMenu(pageStore)
            }
        }

        useEffect(() => {
            if (idParams) {
                getDetailPage()
            }
            getListFunction()
            getDropDownMenu()
        }, []);

        // giá trị ban đầu của form
        const initialValues = {
            actived: true,
            visible: true,
            isCreated: true,
            isReporter: true,
            isAssignee: true,
        };
        function ButtonCreateUpdate() {
            return (
                <>
                    <CustomButton
                        icon={<Icon.Download className="!fill-[#262626]" />}
                        htmlType="submit"
                        disabled={loading}
                        loading={loading}
                        onClick={() => {
                            isSaveAndContinue = false;
                            form.submit();
                        }}
                    >
                        {t('btn.save')}
                    </CustomButton>
                    <CustomButton
                        type="primary"
                        icon={<Icon.Download />}
                        disabled={loading}
                        loading={loading}
                        onClick={() => {
                            isSaveAndContinue = true;
                            form.submit();
                        }}
                    >
                        {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
                    </CustomButton>
                </>
            );
        }
        return (
            <>
                <div className="flex flex-col h-full">
                    <CustomBreadcrumb
                        pagetitle={[
                            {
                                title: "Màn hình",
                                link: '/Permission/MobileScreen',
                            },
                            {
                                title: pageTitle,
                                link: idParams ? `/Permission/MobileScreen/${idParams}` : '/Permission/MobileScreen/create',
                            },
                        ]}
                        extra={
                            <>
                                <div className="custom__mobile--save flex space-x-2">
                                    {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                                    {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                                </div>
                            </>
                        }
                    />
                    {/* Form nhập  */}
                    <BoxContainer fullHeight>
                        <Spin spinning={loading}>
                            <SearchForm
                                onFinish={onFinish}
                                initialValues={idParams ? {} : initialValues}
                                form={form}
                                layout='horizontal'
                                wrapperCol={{ span: 16 }}
                                labelCol={{ span: 6 }}
                                column='col-1'
                                colon={false}
                            >
                                {/* Tên màn hình */}
                                <SearchItem
                                    rules={[{ required: true, message: 'Hãy nhập thông tin cho trường "Tên màn hình"' }]}
                                    name={'screenName'}
                                    label={"Tên màn hình"}
                                >
                                    <Input />
                                </SearchItem>
                                {/* Đường dẫn */}
                                <SearchItem
                                    rules={[{ required: true, message: 'Hãy nhập thông tin cho trường "Đường dẫn"' }]}
                                    name={'screenCode'}
                                    label={'Đường dẫn'}
                                >
                                    <Input />
                                </SearchItem>
                                {/* loại danh mục */}
                                <SearchItem name={'menuId'} label={"MenuId"}>
                                    <Select
                                        options={pageStore.dropdownMenu}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.value ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        fieldNames={{ label: 'value', value: 'key' }}
                                    />
                                </SearchItem>
                                {/* thứ tự hiển thị */}
                                <SearchItem
                                    rules={[{ required: true, message: `Hãy nhập thông tin cho trường "${t('masterdata.shared-category.order-index')}"` }]}
                                    name={'orderIndex'}
                                    label={t('masterdata.shared-category.order-index')}
                                >
                                    <InputNumber className="!w-full" controls={false} />
                                </SearchItem>
                                {/* loại icon */}
                                <SearchItem name={'iconType'} label={'Loại icon'}>
                                    <Input />
                                </SearchItem>
                                {/* Tên icon */}
                                <SearchItem name={'iconName'} label={'Tên icon'}>
                                    <Input />
                                </SearchItem>

                                {/* trạng thái */}
                                <SearchItem
                                    className="m-0 mb-2"
                                    name={'actived'}
                                    label={t('masterdata.shared-category.status')}
                                >
                                    <Radio.Group>
                                        <Radio value={true}> {t('radio-btn.active')}</Radio>
                                        <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                                    </Radio.Group>
                                </SearchItem>
                                {/*Hiển thị trên mobile */}
                                <SearchItem name={'visible'} label={'Hiển thị trên mobile'}>
                                    <Radio.Group>
                                        <Radio value={true}>Có</Radio>
                                        <Radio value={false}>Không</Radio>
                                    </Radio.Group>
                                </SearchItem>
                                {/*Người giao việc được quyền xem*/}
                                <SearchItem name={'isCreated'} label={'Người giao việc được quyền xem'}>
                                    <Radio.Group>
                                        <Radio value={true}>Có</Radio>
                                        <Radio value={false}>Không</Radio>
                                    </Radio.Group>
                                </SearchItem>
                                {/*Người theo dõi được quyền xem*/}
                                <SearchItem name={'isReporter'} label={'Người theo dõi được quyền xem'}>
                                    <Radio.Group>
                                        <Radio value={true}>Có</Radio>
                                        <Radio value={false}>Không</Radio>
                                    </Radio.Group>
                                </SearchItem>
                                {/*Người được phân công được quyền xem*/}
                                <SearchItem name={'isAssignee'} label={'Người được phân công được quyền xem'}>
                                    <Radio.Group>
                                        <Radio value={true}>Có</Radio>
                                        <Radio value={false}>Không</Radio>
                                    </Radio.Group>
                                </SearchItem>
                                <hr className=''></hr>
                                {/* <div className=' flex justify-center' > */}
                                <SearchItem name='functionId' label='Thao tác'>
                                    <Checkbox.Group className='w-full' >
                                        <Space direction='vertical'>
                                            {pageStore.listFunctions.filter(f => f.functionId.includes('M_')).map((item) => (
                                                <Checkbox key={item.functionId} value={item.functionId}>{item.functionName}</Checkbox>
                                            ))}
                                        </Space>
                                    </Checkbox.Group>
                                </SearchItem>
                                {/* </div> */}
                            </SearchForm>
                        </Spin>
                    </BoxContainer>
                </div>
            </>
        );
    });
};

export default createAddUpdateMobileScreen;
