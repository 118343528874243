import { Form, Input, Radio, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { clearUserSession, getDeviceId, getPlantCode, getUserName } from 'utils/localStorageHelper';

import BoxContainer from 'base/ui/components/BoxContainer'
import CustomButton from 'base/ui/components/Button';
import { FaSave } from 'react-icons/fa';
import { IAccountServices } from 'services/RawData/account_services';
import PageWrapper from 'pages/Components/PageWrapper'
import { SYSTEM_TITLE } from 'utils/constants';
import { observer } from 'mobx-react'
import { userPresenter, userStore } from 'stores/root_store';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const createChangePassword = (service: IAccountServices) => {

  return observer(() => {
    document.title = 'Đổi mật khẩu - ' + SYSTEM_TITLE;
    const [form] = Form.useForm()
    const { t } = useTranslation();
    // Hàm validate để kiểm tra confirmPassword có giống với newPassword hay không
    const validateConfirmPassword = (_: any, value: any) => {
      if (value && value !== form.getFieldValue('newPassword')) {
        return Promise.reject(new Error('Mật khẩu xác nhận không khớp!'));
      }
      return Promise.resolve();
    };

    const [newPasswordDirty, setNewPasswordDirty] = useState(false);
    const [confirmDirty, setConfirmDirty] = useState(false);

    // Khi bắt đầu nhập vào trường confirmPassword
    const handleConfirmChange = (e: any) => {
      const { value } = e.target;
      if (e.target.name === "confirm")
        setConfirmDirty(!!value); // Đánh dấu confirmDirty khi có nhập liệu
      else {
        setNewPasswordDirty(!!value);
      }
    };

    return (
      <PageWrapper
        breadcrumb={[{
          text: "Đổi mật khẩu"
        }]}
        extras={
          <CustomButton onClick={() => {
            form.submit();
            //clearUserSession();
          }}
            type="primary">
            <FaSave />
            Lưu
          </CustomButton>
        }
      >
        {/* <BoxContainer>

                </BoxContainer> */}
        <BoxContainer fullHeight >
          <SearchForm
            initialValues={{ userName: getUserName() }}
            form={form}
            layout='horizontal'
            column='col-1'
            colon={false}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            onFinish={async (values) => {
              const dataSendApi = {
                ...values,
                plantCode: getPlantCode(),
                isChangeDefaultPass: false
              }

              try {
                const result = await service.changePasswordByUser(dataSendApi);
                if (result && result.isSuccess) {
                  message.success(result.message)
                  userPresenter.signOut(userStore, getDeviceId(), true);
                } else {
                  message.error(result?.message)
                }
              } catch (error) {

              }
            }}
          >

            <SearchItem name="userName" label="Tên tài khoản" tooltip="Tài khoản dùng để đăng nhập">
              <Input disabled />
            </SearchItem>
            <SearchItem name='oldPassword' rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mật khẩu cũ' }]} label="Mật khẩu cũ">
              <Input.Password />
            </SearchItem>
            <SearchItem
              hasFeedback={newPasswordDirty}
              validateTrigger="onChange"
              normalize={(value) => value.trim()}
              rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mật khẩu mới' },
              {
                min: 8,
                message: 'Mật khẩu có độ dài tối thiểu 8 ký tự.',
              },
              {
                pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
                message: 'Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt.',
              },
              ]}
              name={'newPassword'}
              label={"Mật khẩu mới"}

            >
              <Input.Password className="rounded-none" name="newPassword" onChange={handleConfirmChange} />
            </SearchItem>
            {/* Xác nhận mật khẩu mới */}
            <SearchItem
              hasFeedback={confirmDirty}
              normalize={(value) => value.trim()}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Hãy nhập thông tin cho trường, xác nhận mật khẩu mới',
              //   },
              //   ({ getFieldValue }) => ({
              //     validator(_, value) {
              //       if (!value || getFieldValue('newPassword') === value) {
              //         return Promise.resolve();
              //       }
              //       return Promise.reject(new Error("Mật khẩu không khớp."));
              //     },
              //   }),
              // ]} 
              validateTrigger="onChange"
              dependencies={['newPassword']}
              rules={[
                {
                  required: confirmDirty, // Chỉ yêu cầu khi trường đã "dirty"
                  message: t('message.please-check-password'),
                },
                {
                  validator: validateConfirmPassword,
                },
              ]}
              name={'confirm'}
              label={"Xác nhận mật khẩu mới"}
            >
              <Input.Password className="rounded-none" name="confirm" onChange={handleConfirmChange} />
            </SearchItem>
          </SearchForm>

        </BoxContainer>

      </PageWrapper>
    )
  })
}


export default createChangePassword