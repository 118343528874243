import * as Icon from 'base/ui/components/Icons';

import { Form, Modal, Select, Tooltip, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { catalogPresenter, catalogStore, commonMasterDataPresenter, commonMasterDataStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import CustomSelect from 'base/ui/components/CustomSelect';
import { DataCatalog } from 'services/RawData/catalog_services';
import { DataSearchCatalog } from '../model';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { HiSearch } from 'react-icons/hi';
import { ProColumns } from '@ant-design/pro-components';
import { checkPermission } from 'utils/permissionHelper';
import { debounce } from 'utils/debounce';
import { dropdownStatus } from 'utils/dropdown';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { SearchModel } from 'pages/model';

const { confirm } = Modal;

// Loai danh muc dung chung
const catalogPage = () => {
  return observer(() => {
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    // translate en/vn
    const { t } = useTranslation();
    const pagetitle = t('masterdata.shared-category.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    // Columns table title
    const defaultColumn: ProColumns<DataCatalog>[] = [
      {
        title: t('masterdata.shared-category.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 30,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
      },
      // mã
      {
        title: t('masterdata.shared-category.catalogue-code'),
        dataIndex: 'catalogCode',
        key: 'catalogCode',

        width: 40,
        className: '!rounded-none !py-1',
        render: (text, record) => {
          return userStore.userPermission?.includes('EDIT') ? (
            <Link to={`/MasterData/Catalog/${record.catalogId}`}>{text}</Link>
          ) : (
            <div>{record.catalogCode}</div>
          );
        },
      },
      // tên tiếng việt
      {
        title: t('masterdata.shared-category.vietnamese-name'),
        dataIndex: 'catalogText_vi',
        key: 'catalogText_vi',

        width: 100,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // loại danh mục
      {
        title: t('masterdata.shared-category.catalogue-type'),
        dataIndex: 'catalogTypeCode',
        key: 'catalogTypeCode',

        width: 100,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // thứ tự hiển thị
      {
        title: t('masterdata.shared-category.order-index'),
        dataIndex: 'orderIndex',
        key: 'orderIndex',

        width: 100,
        className: '!rounded-none !py-1',
        onCell: () => {
          return {
            style: {
              textAlign: 'right',
            },
          };
        },
      },
      // trạng thái
      {
        title: t('masterdata.shared-category.status'),
        dataIndex: 'actived',
        width: 40,
        key: 'actived',
        align: 'center',
        render: (_, record) => {
          return (
            <div>
              {record.actived ? (
                <Tooltip title={t('btn.edit')}>
                  <Icon.CheckStatus className="w-4 text-sm text-center mx-auto !fill-[#389E0D]" />
                </Tooltip>
              ) : (
                <Tooltip title={t('btn.delete')}>
                  <Icon.CloseStatus className="w-4 text-sm text-center mx-auto !fill-[#D93133E5]" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      // chức năng
      {
        title: t('masterdata.shared-category.actions'),
        dataIndex: 'function',
        width: 100,
        key: 'function',
        align: 'center',
        className: '!rounded-none !py-1',
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') ? (
                <Tooltip title={t('btn.edit')}>
                  <CustomButtonIcon color="#007BF1" onClick={() => navigate(`${record.catalogId}`)}>
                    <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                  </CustomButtonIcon>
                </Tooltip>
              ) : null}
              {userStore.userPermission?.includes('DELETE') ? (
                record.actived ? (
                  <Tooltip title={t('btn.delete')}>
                    <CustomButtonIcon
                      color="#FC5C5E"
                      onClick={() => {
                        showDeleteConfirm(record);
                      }}
                    >
                      <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                    </CustomButtonIcon>
                  </Tooltip>
                ) : (
                  <CustomButtonIcon color="#fff00" className="border-none outline-none cursor-default">
                    <Icon.Trash className="!fill-transparent" width="14" height="14" />
                  </CustomButtonIcon>
                )
              ) : null}
            </div>
          );
        },
      },
    ];
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);
    // Custom default columns table with resizeable
    // const [columns, setColumn] = useState<ProColumns<DataCatalog>[]>(defaultColumn);
    // Loading all page
    const [loading, setLoading] = useState(false);
    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    });

    // List data company
    const [listDataCatalogType, setListDataCatalogType] = useState<DataCatalog[]>([]);
    // function open hide drawer ===========================================================
    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Call api search catalogType
    const getDataSearchCatalog = async (dataSearch: DataSearchCatalog) => {
      await catalogPresenter.searchCatalog(catalogStore, dataSearch);
      if (catalogStore.searchCatalogResponse.isSuccess) {
        setListDataCatalogType(catalogStore.searchCatalogResponse.data);
      }
    };

    // Ui modal confirm delete store
    const showDeleteConfirm = (item: DataCatalog) => {
      confirm({
        title: `${t('btn.delete')} "${item.catalogText_vi}"`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} "${item.catalogText_vi}"`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDeleteCatalog(item.catalogId);
        },
        onCancel() { },
      });
    };

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
        ...values,
      };
      removeEmptyValues(tempDataSearch);
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);

      await getDataSearchCatalog(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };

    // Call api delete
    const handleClickDeleteCatalog = async (catalogId: string) => {
      if (catalogId) {
        await catalogPresenter.deleteCatalog(catalogStore, catalogId);
        if (catalogStore.crudResponse.isSuccess) {
          message.success(catalogStore.crudResponse.message);
          // Call list branch after change
          await getDataSearchCatalog(dataSearch);
        } else {
          message.error(catalogStore.crudResponse.message);
        }
      }
      setLoading(false);
    };

    const initialValues = {
      actived: null,
    };

    // check if role permission
    useEffect(() => {
      const pagePermissions = () => {
        const per = checkPermission();
        console.log(per?.permiss);
      };
      pagePermissions();
    }, []);

    // get list dropdown catelog
    useEffect(() => {
      (async () => {
        await commonMasterDataPresenter.getDropDownCategoryType(commonMasterDataStore);
      })();
    }, []);

    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/MasterData/Catalog' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {/* Check quyền trước khi cho hiện nút */}
              {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  onClick={() => {
                    navigate('create');
                  }}
                  type="primary"
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<DataCatalog>
            columns={defaultColumn}
            handleResize={() => { }}
            rowKey="stt"
            size="small"
            bordered
            search={false}
            loading={loading}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const values = form.getFieldsValue();
              const data: any = await getDataSearchCatalog({
                ...values,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: catalogStore.searchCatalogResponse.isSuccess,
              };
            }}
            dataSource={listDataCatalogType}
            scroll={{ x: true }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: catalogStore.searchCatalogResponse?.paging?.recordsTotal,
            }}
          />
        </BoxContainer>
        {/* Search */}
        <SearchBox // tìm kiếm
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm
            initialValues={initialValues}
            form={form}
            onFinish={handleSearch}
            layout="vertical"
            column="col-1"
          >
            <button type='submit' hidden></button>
            {/* mã */}
            <SearchItem label={t('masterdata.shared-category.catalogue-code')} name={'catalogCode'}>
              <CustomInput />
            </SearchItem>
            {/* tên tiếng việt */}
            <SearchItem label={t('masterdata.shared-category.vietnamese-name')} name={'catalogText_vi'}>
              <CustomInput />
            </SearchItem>
            {/* loại danh mục */}
            <SearchItem name={'catalogTypeCode'} label={t('masterdata.shared-category.catalogue-type')}>
              <CustomSelect
                className="rounded-sm"
                options={commonMasterDataStore.commonRespone.data}
                showSearch
                allowClear
                filterOption={false}
                onSearch={debounce(async (value) => {
                  await commonMasterDataPresenter.getDropDownCategoryType(commonMasterDataStore, value);
                }, 500)}
                fieldNames={{ label: 'value', value: 'key' }}
              />
            </SearchItem>
            {/* trạng thái */}
            <SearchItem label={t('masterdata.shared-category.status')} name={'actived'}>
              <CustomSelect options={dropdownStatus} placeholder={t('place-holder.all')} allowClear />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default catalogPage;
