import * as Icon from 'base/ui/components/Icons';

import { Form, Modal, Select, Tooltip, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { companyPresenter, companyStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomProTable from 'base/ui/components/CustomTable';
import CustomSelect from 'base/ui/components/CustomSelect';
import { DataCompany } from 'services/RawData/company_services';
import { DataSearchCompany } from './model';
import Draggable from 'react-draggable';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { HiSearch } from 'react-icons/hi';
import { ProColumns } from '@ant-design/pro-components';
import { dropdownStatus } from 'utils/dropdown';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { useResizableColumns } from 'utils/reportHelper';

const { confirm } = Modal;

const createCompany = () => {
  return observer(() => {
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    //=====================================CHUYỂN NGÔN NGỮ=====================================//
    const { t } = useTranslation();
    const pagetitle = t('masterdata.company.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    // Loading all page
    const [loading, setLoading] = useState(false);
    // List data company
    const [listDataCompany, setListDataCompany] = useState<DataCompany[]>([]);

    // Data search
    const [dataSearch, setDataSearch] = useState({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    });

    const [openDrawer, setOpenDrawer] = useState(false);

    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Columns table title
    const defaultColumn: ProColumns<DataCompany>[] = [
      // STT
      {
        title: t('masterdata.company.stt'),
        dataIndex: 'stt',
        width: 60,
        key: 'stt',
        className: '!rounded-none !py-1',
        fixed: 'left',
        align: 'center',
      },
      // Mã công ty
      {
        title: t('masterdata.company.company-code'),
        dataIndex: 'companyCode',
        width: 120,
        key: 'companyCode',

        className: '!rounded-none !py-1',
        fixed: 'left',
        ellipsis: true,
        render: (_, record) => {
          return userStore.userPermission?.includes('EDIT') ? <Link to={`/MasterData/Company/Edit/${record.companyId}`}>{record.companyCode}</Link> : _
        },
      },
      // Tên công ty
      {
        title: t('masterdata.company.company-name'),
        dataIndex: 'companyName',
        key: 'companyName',
        width: 450,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Trạng thái
      {
        title: t('masterdata.status'),
        dataIndex: 'actived',
        key: 'actived',
        width: 100,
        className: '!rounded-none !py-1',
        render: (_, record) => {
          return (
            <div>
              {record.actived ? (
                <Tooltip title={t('masterdata.active')}>
                  <Icon.CheckStatus className="w-4 text-sm text-center mx-auto !fill-[#389E0D]" />
                </Tooltip>
              ) : (
                <Tooltip title={t('masterdata.non-active')}>
                  <Icon.CloseStatus className="w-4 text-sm text-center mx-auto !fill-[#D93133E5]" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    ];

    const [columns, setColumns] = useState<ProColumns<DataCompany>[]>(defaultColumn)

    // Call api search company
    const getDataSearchCompany = async (dataSearch: DataSearchCompany) => {
      await companyPresenter.searchCompany(companyStore, dataSearch);
      if (companyStore.searchCompanyResponse.isSuccess) {
        setListDataCompany(companyStore.searchCompanyResponse.data);
      }
    };

    // Handle click button search
    const handleSearch = async (values: DataCompany) => {
      setLoading(true);

      const tempDataSearch = {
        ...values,
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
      };
      removeEmptyValues(tempDataSearch);
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);

      await getDataSearchCompany(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };


    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const constDataName = 'Company';
    const {
      isResizablePresent,
      resizableColumnSelect,
      handleResize,
    } = useResizableColumns<DataCompany>(columns, setColumns, constDataName);
    console.log(userStore.userPermission);

    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/MasterData/Company' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
            </>
          }
        />
        {/* Table */}
        <BoxContainer fullHeight>
          <CustomProTable<DataCompany>
            columns={columns}
            handleResize={handleResize}
            isResizablePresent={isResizablePresent}
            resizableColumnSelect={resizableColumnSelect}
            rowKey="companyId"
            bordered
            search={false}
            loading={loading}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const data: any = await getDataSearchCompany({
                ...dataSearch,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  ...dataSearch,
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }

              setLoading(false);

              return {
                data: data,
                success: companyStore.searchCompanyResponse.isSuccess,
              };
            }}
            dataSource={listDataCompany}
            scroll={{ y: 400 }}
            pagination={{
              pageSize: dataSearch.paging.pageSize,
              total: companyStore.searchCompanyResponse.paging?.totalCount,
              current: dataSearch.paging.pageIndex,
              responsive: true,
              hideOnSinglePage: true,
              position: ['topRight', 'bottomRight'],
              style: { margin: '10px 0' },
              showSizeChanger: false,
              showTotal: (total, range) => (
                <>
                  <span className="!absolute left-1">
                    {t('message.view')}{' '}
                    <CustomSelect
                      size="middle"
                      className="min-w-[72px] !rounded-none"
                      value={String(dataSearch.paging.pageSize)}
                      onChange={(value) => {
                        setDataSearch({
                          ...dataSearch,
                          paging: { ...dataSearch.paging, pageSize: Number(value) },
                        });
                      }}
                    >
                      {PAGE_SIZE_OPTION.map((pageSize) => (
                        <Select.Option key={pageSize} value={String(pageSize)}>
                          {pageSize}
                        </Select.Option>
                      ))}
                    </CustomSelect>{' '}
                    {t('message.item')}
                  </span>
                  <span className="hidden md:block">{`${t('message.display')} ${range[0]}-${range[1]} ${t(
                    'message.of',
                  )} ${total} ${t('message.entries')}`}</span>
                </>
              ),
              onChange: (page, pageSize) => {
                setDataSearch({
                  ...dataSearch,
                  paging: { ...dataSearch.paging, pageIndex: page, pageSize: pageSize },
                });
              },
            }}
            options={false}
          />
        </BoxContainer>

        <SearchBox // tìm kiếm
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          {/* Form nhập tìm kiếm */}
          <SearchForm form={form} onFinish={handleSearch} layout="vertical" column="col-1">
            <button type='submit' hidden></button>
            {/* Tên công ty */}
            <SearchItem label={t('masterdata.company.company-name')} name={'companyName'}>
              <CustomInput />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default createCompany;
