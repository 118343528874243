import { observable, runInAction } from 'mobx';
import { ILanguageServices } from 'services/Language/language_services';
import { User } from 'services/User/user_services';
import { getUserSession, setUserSession } from 'utils/localStorageHelper';




export type LanguageStore = {
    translateSuccess: boolean

};

export interface ILanguagePresenter {
    createStore(): LanguageStore;
    translateLanguage(languageStore: LanguageStore, language: string): Promise<void>;

}

export function createLanguagePresenter({ languageServices }: { languageServices: ILanguageServices }): ILanguagePresenter {
    return {
        createStore: (): LanguageStore =>
            observable({
                translateSuccess: false

            }),
        translateLanguage: async (languageStore: LanguageStore, language: string) => {
            try {
                const userSession = getUserSession() as User;
                const result = await languageServices.translateLanguage(language, userSession.accountId);
                runInAction(() => {
                    userSession.webPermission = result
                    setUserSession(userSession)
                });


            } catch {
                // error
            }
        }
    };
}
