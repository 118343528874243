import { isPlainObject, isString, mapValues } from 'lodash';

export const checkAndTransformNull = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (obj[key] === null) {
      obj[key] = undefined;
    }
  }
};

// Hàm này bỏ null hoặc "" của object khi search
export function removeEmptyValues(obj: any) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
      delete obj[key];
    } else if (typeof obj[key] === 'string') {
      obj[key] = obj[key] ? obj[key].trim() : undefined;
    }
    else if (typeof obj[key] === 'object') {
      // Nếu thuộc tính là một object, thì mình loại bỏ giá trị rỗng từ object con
      removeEmptyValues(obj[key]);
      // Kiểm tra nếu object con đã trở thành rỗng sau khi được xoá thì xoá luôn cái phần tử chứa object con đó
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
}

export function trimStrings(obj: any): any {
  return mapValues(obj, value => {
    if (isPlainObject(value)) {
      return trimStrings(value); // Gọi đệ quy nếu giá trị là một object
    } else if (isString(value)) {
      return value.trim();
    } else {
      return value;
    }
  });
}