import * as Icon from 'base/ui/components/Icons';

import { Form, Modal, Tooltip, message } from 'antd';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { stockPresenter, stockStore, userStore } from 'stores/root_store';
import { DATE_FORMAT_DEFAULT, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { usePermission } from 'hooks/usePermission';
import { observer } from 'mobx-react';
import { SearchModel } from 'pages/model';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { HiSearch } from 'react-icons/hi';
import { DataStock } from 'services/RawData/stock_services';
import { dropdownStatus } from 'utils/dropdown';
import { removeEmptyValues } from 'utils/transformHelper';
import { DataSearchStock } from './model';

const { confirm } = Modal;

const createStock = () => {
  return observer(() => {
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    // translate en/vn
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const pagetitle = t('masterdata.stock.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();

    //==========================STATE===================//
    // Loading all page
    const [loading, setLoading] = useState(false);
    // List data company
    const [listDataStock, setListDataStock] = useState<DataStock[]>([]);
    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
      actived: true,
    });

    const [openDrawer, setOpenDrawer] = useState(false);

    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Columns table title
    const defaultColumn: ProColumns<DataStock>[] = [
      // stt
      {
        title: t('masterdata.stock.stt'),
        dataIndex: 'stt',
        width: 30,
        key: 'stt',
        className: '!rounded-none !py-1',
        align: 'center',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
        ellipsis: true,
      },
      // mã kho
      {
        title: t('masterdata.stock.stock-code'),
        dataIndex: 'stockCode',
        width: 40,
        key: 'stockCode',
        className: '!rounded-none !py-1',
        fixed: 'left',
        ellipsis: true,
        // render: (_, record) => {
        //   return <Link to={`/MasterData/Warehouse/${record.stockId}`}>{record.stockCode}</Link>;
        // },
      },
      // tên kho
      {
        title: t('masterdata.stock.stock-name'),
        dataIndex: 'stockName',
        width: 180,
        key: 'stockName',
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Loại kho
      // {
      //   title: 'Loại kho',
      //   dataIndex: 'stockType',
      //   width: 100,
      //   key: 'stockType',
      //   className: '!rounded-none !py-1',
      //   ellipsis: true,
      // },
      // người tạo
      {
        title: t('masterdata.stock.create-by'),
        dataIndex: 'userName',
        width: 80,
        key: 'userName',
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // ngày tạo
      {
        title: t('masterdata.stock.create-time'),
        dataIndex: 'createTime',
        width: 70,
        key: 'createTime',
        className: '!rounded-none !py-1',
        align: 'center',
        valueType: 'dateTime',
        fieldProps: {
          format: DATE_FORMAT_DEFAULT,
        },
        ellipsis: true,
      },
      // trạng thái
      {
        title: t('masterdata.stock.status'),
        dataIndex: 'actived',
        width: 50,
        key: 'actived',
        className: '!rounded-none !py-1',
        ellipsis: true,
        render: (_, record) => {
          return (
            <div>
              {record.actived ? (
                <Tooltip title="Đang sử dụng">
                  <Icon.CheckStatus className="w-4 text-sm text-center mx-auto !fill-[#389E0D]" />
                </Tooltip>
              ) : (
                <Tooltip title="Ngưng sử dụng">
                  <Icon.CloseStatus className="w-4 text-sm text-center mx-auto !fill-[#D93133E5]" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      // chức năng
      // {
      //   title: t('masterdata.stock.actions'),
      //   dataIndex: 'function',
      //   width: 50,
      //   key: 'function',
      //   className: '!rounded-none !py-1',
      //   align: 'center',
      //   fixed: 'right',
      //   render: (_, record) => {
      //     return (
      //       <div className="w-full flex items-center justify-center gap-x-1">
      //         {userStore.userPermission?.includes('EDIT') ? (
      //           <Tooltip title={t('btn.edit')}>
      //             <CustomButton
      //               className="!w-6 !h-6 rounded-sm !border-[#4DA8FF] flex gap-1 items-center justify-center !bg-[#C9E8FF] hover:bg-[#95D2FF]"
      //               icon={<Icon.PencilEdit className="!fill-[#007BF1]" width="16" height="16" />}
      //               onClick={() => navigate(`${record.stockId}`)}
      //             />
      //           </Tooltip>
      //         ) : null}
      //         {userStore.userPermission?.includes('DELETE') ? (
      //           record.actived ? (
      //             <Tooltip title={t('btn.delete')}>
      //               <CustomButton
      //                 className="!w-6 !h-6 rounded-sm !border-[#FF4F51] flex gap-1 items-center justify-center !bg-[#FFE5E6] hover:bg-[#FF8081]"
      //                 icon={<Icon.Trash className="!fill-[#FC5C5E]" width="16" height="16" />}
      //                 onClick={() => {
      //                   showDeleteConfirm(record);
      //                 }}
      //               />
      //             </Tooltip>
      //           ) : null
      //         ) : null}
      //       </div>
      //     );
      //   },
      // },
    ];

    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<DataStock>[]>(defaultColumn);

    // Function handle resize columns
    const handleResize =
      (index: number) =>
        (e: any, { size }: any) => {
          // Set columns
          setColumn((prevColumns) => {
            const nextColumns = [...prevColumns];
            nextColumns[index] = {
              ...nextColumns[index],
              width: size.width,
            };
            return nextColumns;
          });
        };

    // Call api search stock
    const getDataSearchStock = async (dataSearch: DataSearchStock) => {
      await stockPresenter.searchStock(stockStore, dataSearch);
      if (stockStore.searchStockResponse.isSuccess) {
        setListDataStock(stockStore.searchStockResponse.data);
      }
    };

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        ...values,
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
      };
      removeEmptyValues(tempDataSearch);
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);

      await getDataSearchStock(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };

    // Ui modal confirm delete store
    const showDeleteConfirm = (itemStock: DataStock) => {
      confirm({
        title: `Xoá kho`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} "${itemStock.stockName}"`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDeleteStock(itemStock.stockId);
        },
        onCancel() { },
        modalRender(modal) {
          return (
            <Draggable>
              <div className="cursor-move">{modal}</div>
            </Draggable>
          );
        },
      });
    };

    // Call api delete
    const handleClickDeleteStock = async (stockId: string) => {
      setLoading(true);
      if (stockId) {
        await stockPresenter.deleteStock(stockStore, stockId);
        if (stockStore.deleteStockResponse.isSuccess) {
          message.success(stockStore.deleteStockResponse.message);
          // Call list stock after change
          await getDataSearchStock(dataSearch);
        } else {
          message.error(stockStore.deleteStockResponse.message);
        }
      }
      setLoading(false);
    };

    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <div className="w-full">
        {/* ==================================== Breadcrumb ========================================= */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/MasterData/Warehouse' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {/* {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  type="primary"
                  onClick={() => navigate('Create')}
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null} */}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<DataStock>
            columns={columns}
            handleResize={handleResize}
            rowKey="stockId"
            bordered
            search={false}
            loading={loading}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const data: any = await getDataSearchStock({
                ...dataSearch,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });
              if (Object.values(sort).length) {
                setDataSearch({
                  ...dataSearch,
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: stockStore.searchStockResponse.isSuccess,
              };
            }}
            dataSource={listDataStock}
            scroll={{ x: 1000, y: 500 }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: stockStore.searchStockResponse?.paging?.recordsTotal,
            }}
            options={false}
          />
        </BoxContainer>
        <SearchBox // tìm kiếm
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          {/* Form nhập tìm kiếm */}
          <SearchForm column="col-1" form={form} onFinish={handleSearch}>
            <button hidden type='submit'></button>
            {/* Mã kho */}
            <SearchItem label={t('masterdata.stock.stock-code')} name={'stockCode'}>
              <CustomInput />
            </SearchItem>
            {/* Tên kho */}
            <SearchItem label={t('masterdata.stock.stock-name')} name={'stockName'}>
              <CustomInput />
            </SearchItem>
            {/* trạng thái */}
            <SearchItem label={t('masterdata.store.status')} name={'actived'}>
              <CustomSelect options={dropdownStatus} placeholder={t('place-holder.all')} allowClear />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default createStock;
