import { ProColumns } from '@ant-design/pro-components'
import BoxContainer from 'base/ui/components/BoxContainer'
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable'
import { clone } from 'lodash'
import { HistoryEditModel } from 'models/DeliveryRegistration'
import { SearchModel } from 'pages/model'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SearchHistory } from 'services/Main/purchaseOrder_services'
import { purchaseOrderPresenter, purchaseOrderStore } from 'stores/root_store'


export const TabHistory = () => {
    const { deliveryRegistrationId } = useParams();
    const [dataPaging, setDataPaging] = useState<SearchHistory>({
        id: deliveryRegistrationId || '',
        paging: {
            pageIndex: 1,
            pageSize: 10
        }
    })
    const [dataTable, setDataTable] = useState<HistoryEditModel[]>([])

    const columns: ProColumns<HistoryEditModel>[] = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 50,
        },
        {
            title: "Mã phiếu",
            key: "deliveryRegistrationCode",
            dataIndex: "deliveryRegistrationCode",
            width: 100,
            readonly: true,
            align: "center",
        },

        {
            title: "Tên trường thay đổi",
            key: "fieldDisplayName",
            dataIndex: "fieldDisplayName",
            width: 130,
            readonly: true,
        },
        {
            title: "Nội dung cũ",
            key: "oldData",
            dataIndex: "oldData",
            width: 350,
            readonly: true,
        },
        {
            title: "Nội dung thay đổi",
            key: "newData",
            dataIndex: "newData",
            width: 150,

        },
        {
            title: "Thay đổi bởi",
            key: "lastEditByName",
            dataIndex: "lastEditByName",
            width: 150,
        },
        {
            title: "Thời gian thay đổi",
            key: "lastEditTime",
            dataIndex: "lastEditTime",
            width: 120,
            align: 'center',
            valueType: 'dateTime',
            fieldProps: {
                format: 'DD/MM/YYYY HH:mm:ss',
            },
        },
    ]

    const fetchDataTable = async (data: SearchHistory) => {
        if (deliveryRegistrationId) {
            await purchaseOrderPresenter.getHistory(purchaseOrderStore, data)
            if (purchaseOrderStore.dataHistory.isSuccess) {
                const data = clone(purchaseOrderStore.dataHistory.data)
                setDataTable(data)
                return data
            }
        }
        return []
    }

    return (
        <CustomProTable<HistoryEditModel>
            bordered
            columns={columns}
            dataSource={dataTable}
            // actionRef={tableRef}
            // handleResize={handleResize}
            // isResizablePresent={isResizablePresent}
            // resizableColumnSelect={resizableColumnSelect}
            // columnsState={{
            //     value: Object.keys(columnsState).length > 0 ? columnsState : undefined,
            //     onChange: (newColumnsState) => {
            //         handleColumnsStateChange(newColumnsState);
            //     },
            // }}
            scroll={{ x: 'auto' }}
            options={false}
            rowKey="stt"
            pagination={{
                ...tablePagination(dataPaging, setDataPaging as any),
                total: purchaseOrderStore.dataHistory?.paging?.recordsTotal,
            }}
            request={async (params = {}, sort, filter) => {
                const data = await fetchDataTable({
                    ...dataPaging,
                    paging: {
                        ...dataPaging.paging,
                        orderBy:
                            Object.values(sort)[0] === "ascend"
                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                : undefined,
                        orderByDesc:
                            Object.values(sort)[0] === "descend"
                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                : undefined,
                    },
                });
                if (Object.values(sort).length) {

                    setDataPaging({
                        ...dataPaging,
                        paging: {
                            ...dataPaging.paging,
                            orderBy:
                                Object.values(sort)[0] === "ascend"
                                    ? `${Object.keys(sort)[0].split(",").pop()}`
                                    : undefined,
                            orderByDesc:
                                Object.values(sort)[0] === "descend"
                                    ? `${Object.keys(sort)[0].split(",").pop()}`
                                    : undefined,
                        },
                    });
                }
                return {
                    data: data,
                    success: purchaseOrderStore.dataHistory?.isSuccess
                };
            }}
        />
    )
}
