import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const wardRoutes = ({
  Ward,
  AddWard,
  UpdateWard,
}: {
  Ward: React.ComponentType;
  AddWard: React.ComponentType;
  UpdateWard: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Ward />} />
    <Route path={ROUTES.MASTER_DATA.WARD.CREATE} element={<AddWard />} />
    <Route path={ROUTES.MASTER_DATA.WARD.EDIT} element={<UpdateWard />} />
  </Routes>
);
