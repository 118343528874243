import { observer } from 'mobx-react'
import PageWrapper from 'pages/Components/PageWrapper'
import { useEffect, useState } from 'react'
import CustomButton from 'base/ui/components/Button';
import { Download } from 'base/ui/components/Icons';
import BoxContainer from 'base/ui/components/BoxContainer';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { Form, Input, Radio, Select, Spin, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { commonMasterDataPresenter, commonMasterDataStore, productPresenter, productStore } from 'stores/root_store';
import { ProductModel } from 'services/RawData/product_services';
import { pick } from 'lodash';
import { usePermission } from 'hooks/usePermission';

const createEditProduct = () => {
    return observer(() => {
        usePermission('EDIT'); // Kiểm tra xem được quyền truy cập vào trang
        const [form] = Form.useForm()
        const { id } = useParams()
        const navigate = useNavigate()

        const [loading, setLoading] = useState(true)
        const [isSubmit, setIsSubmit] = useState(true)

        const getDetail = async () => {
            if (id) {
                await productPresenter.getProductById(productStore, id)
                if (productStore.productDetailResponse.isSuccess) {
                    form.setFieldsValue(productStore.productDetailResponse.data)
                }
            }
        }

        const handleUpdate = async (values: ProductModel, isContinue?: boolean) => {
            setIsSubmit(true)
            const request = pick(values, ['productType', 'actived'])
            await productPresenter.updateProduct(productStore, { ...request, productId: id })
            setIsSubmit(false)
            if (productStore.crudResponse.isSuccess) {
                message.success(productStore.crudResponse?.message)
                if (!isContinue) {
                    navigate("/MasterData/Product")
                } else {
                    getDetail()
                }
            } else {
                message.error(productStore.crudResponse?.message)
            }
        }

        const getProductType = async () => {
            if (!commonMasterDataStore.productTypes.length) {
                await commonMasterDataPresenter.getDropdownProductType(commonMasterDataStore)
            }
        }

        useEffect(() => {
            Promise.allSettled([
                getDetail(),
                getProductType()
            ]).then(() => {
                setIsSubmit(false)
                setLoading(false)
            })
        }, []);

        return (
            <PageWrapper
                breadcrumb={[{
                    text: "Thông tin hàng hóa",
                    to: '/MasterData/Product'
                }, {
                    text: "Cập nhật"
                }]}
                extras={<>

                    <CustomButton
                        icon={<Download className="!fill-[#262626]" />}
                        htmlType="submit"
                        disabled={isSubmit}
                        loading={isSubmit}
                        onClick={() => {
                            form.validateFields().then((values: ProductModel) => {
                                handleUpdate(values)
                            }).catch((errors) => console.log(errors))
                        }}
                    >
                        Lưu
                    </CustomButton>
                    <CustomButton
                        type="primary"
                        icon={<Download />}
                        disabled={isSubmit}
                        loading={isSubmit}
                        onClick={() => {
                            form.validateFields().then((values: ProductModel) => {
                                handleUpdate(values, true)
                            }).catch((errors) => console.log(errors))
                        }}
                    >
                        Lưu và cập nhật
                    </CustomButton>

                </>}
            >
                {/* Table */}
                <BoxContainer fullHeight>
                    <Spin spinning={loading}>
                        <div className='h-[70vh]'>
                            <SearchForm
                                //  onFinish={onFinish}
                                layout='horizontal'
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 13 }}
                                autoComplete='off'
                                form={form}
                            >
                                <SearchItem
                                    rules={[{ required: true }]}
                                    name={'productCode'}
                                    label='Mã hàng hóa'>
                                    <Input disabled />
                                </SearchItem>
                                <SearchItem
                                    rules={[{ required: true }]}
                                    name={'productName'}
                                    label='Tên hàng hóa'>
                                    <Input disabled />
                                </SearchItem>
                                <SearchItem name={'productType'} label='Loại hàng hóa'>
                                    <Select
                                        options={commonMasterDataStore?.productTypes.map((type) => ({
                                            label: type.value,
                                            value: type.key
                                        }))}
                                    />
                                </SearchItem>
                                {/* Trạng thái */}
                                <SearchItem initialValue={true} name={'actived'} label={'Trạng thái'}>
                                    <Radio.Group >
                                        <Radio value={true}>{"Đang hoạt động"}</Radio>
                                        <Radio value={false}>{"Ngưng hoạt động"}</Radio>
                                    </Radio.Group>
                                </SearchItem>
                            </SearchForm>
                        </div>
                    </Spin>
                </BoxContainer>


            </PageWrapper>
        )
    })
}

export default createEditProduct