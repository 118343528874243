import { MASTER_DATA_API_URL } from "utils/constants";
import { downloadFilePost, post, get } from '../../utils/api';
import { DataResponse } from "services/response";
import { SearchModel } from "pages/model";
import { DeliveryRegistrationReport, DeliveryResultReport, ReceivingScheduleOfBHFReport } from "models/Report";
import { NotificationByUser } from "models/Notification";

// Tìm kiếm Thông báo theo userId có phân trang
export type SearchNotiByUserModel = SearchModel & {
    userId: string;
}
// ============ Khai báo phương thức =====================
export interface INotificationServices {
    // Danh sách Thông báo của từng user 
    getListNotiByUser(request: SearchNotiByUserModel): Promise<DataResponse<NotificationByUser[]>>;
    // Đếm số lượng thông báo chưa đọc
    countUnreadNotification(userId: string): Promise<DataResponse<number>>;
    // Đánh dấu thông báo đã đọc
    readNotification(request: { userId: string }): Promise<DataResponse<null>>
    // Đọc 1 thông báo
    readSingleNotification(id: string): Promise<DataResponse<null>>
}
// Hàm khởi tạo các phương nhà của Notification service
export function createNotificationServices(): INotificationServices {
    return {

        // Danh sách Thông báo của từng user 
        getListNotiByUser: async (request) => {
            return (await post(MASTER_DATA_API_URL, `notification/list-noti-by-user`, request)).data;
        },
        // Đếm số lượng thông báo chưa đọc
        countUnreadNotification: async (userId) => {
            return (await get(MASTER_DATA_API_URL, `notification/count-unread-noti/${userId}`)).data;
        },
        // Đánh dấu thông báo đã đọc
        readNotification: async (request) => {
            return (await post(MASTER_DATA_API_URL, `notification/read-noti`, request)).data;
        },
        // Đọc 1 thông báo
        readSingleNotification: async (request) => {
            return (await post(MASTER_DATA_API_URL, `notification/read-single-noti`, { id: request })).data;
        }
        // ============================================================================================

    };
}
