import { Checkbox, Form, Select, Spin, message } from 'antd';
import { useEffect, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomProTable from 'base/ui/components/CustomTable';
import CustomSelect from 'base/ui/components/CustomSelect';
import { ProColumns } from '@ant-design/pro-components';
import { SYSTEM_TITLE } from 'utils/constants';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react';
import { AccessMobileModel, IAccessMobileServices, MobileScreenAccessResponse } from 'services/RawData/accessMobile_services';
import { usePermission } from 'hooks/usePermission';
import { commonMasterDataPresenter, commonMasterDataStore } from 'stores/root_store';

const accessMobilePage = (accessMobileServices: IAccessMobileServices) => {
  return observer(() => {
    const pagetitle = 'Phân quyền chức năng mobile';
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
    const [form] = Form.useForm();

    // ========================USE STATE================================================================
    // Loading all page
    const [loading, setLoading] = useState(false);

    // Columns table title
    const defaultColumn: ProColumns<MobileScreenAccessResponse>[] = [
      {
        title: 'STT',
        dataIndex: 'index',
        valueType: 'index',
        key: 'stt',
        width: 40,
        align: 'center',
        // render: (text, record) => <div className="!text-center">{record.stt}</div>,
      },
      // Chức năng
      {
        title: 'Màn hình',
        dataIndex: 'screenName',
        key: 'screenName',
        width: 200,
      },
      // Thao tác
      {
        title: 'Thao tác',
        dataIndex: 'thaotac',
        key: 'thaotac',
        width: 200,
        render: (_, record) => (
          <div className="flex items-center gap-4">
            {record.functionAccessResponses.map((item, idx) => {
              return (
                <Checkbox
                  key={item.functionId}
                  checked={item.selected}
                  value={item.functionId}
                  onChange={(e) => {
                    handleCheckFunction(item.functionId, record.mobileScreenId, e.target.checked)
                  }}
                >
                  {item.functionName}
                </Checkbox>
              );
            })}
          </div>
        ),
      },
    ];
    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<MobileScreenAccessResponse>[]>(defaultColumn);
    const [dataAccess, setDataAccess] = useState<AccessMobileModel[]>([])

    // ========================FUNCTION ================================================================
    const handleResize =
      (index: number) =>
        (e: any, { size }: any) => {
          // Set columns
          setColumn((prevColumns) => {
            const nextColumns = [...prevColumns];
            nextColumns[index] = {
              ...nextColumns[index],
              width: size.width,
            };
            return nextColumns;
          });
        };
    const getListRoles = async () => {
      if (!commonMasterDataStore.listRoles.length) {
        await commonMasterDataPresenter.getDropdownRole(commonMasterDataStore)
      }
      form.setFieldValue('role', commonMasterDataStore.listRoles[0].key)
      getAccessFromRole(commonMasterDataStore.listRoles[0].key)
    }

    const getAccessFromRole = async (roleId: string) => {
      try {
        setLoading(true);
        const result = await accessMobileServices.getAccessMobileByRoleId(roleId)
        setLoading(false);
        if (result.isSuccess) {
          const dataClone = cloneDeep(result.data)
          const dataFilter = dataClone.filter(item => item.menuName === 'Màn hình mobile')
          setDataAccess(dataFilter)
        } else {
          message.error(result.message)
        }
      } catch (error) {
        console.log(error);
      }
    }
    const handleCheckFunction = async (functionId: string, pageId: string, checked: boolean) => {
      try {
        const result = await accessMobileServices.updateFunctionPermissions({ check: checked, functionId: functionId, roleId: form.getFieldValue('role'), mobileScreenId: pageId })
        if (result.isSuccess) {
          getAccessFromRole(form.getFieldValue('role'))
        }
      } catch (error) {
        console.log(error);
      }
    }
    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
      getListRoles()
    }, []);


    return (
      <div className="w-full">
        {loading && <div className='fixed w-screen h-screen bg-black/20 top-0 z-50 flex justify-center items-center'> <Spin></Spin></div>}
        {/* ==================================== Breadcrumb ========================================= */}
        <CustomBreadcrumb pagetitle={[{ title: pagetitle, link: '/MasterData/Access' }]} />

        {/* Tables */}
        <BoxContainer>
          <Form colon={false} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}  >
            <Form.Item name={'role'} label={<h3 className='font-medium'>Nhóm người dùng</h3>}>
              <Select onChange={(value, option) => {
                getAccessFromRole(value)
              }} options={commonMasterDataStore.listRoles} fieldNames={{ label: 'value', value: 'key' }} />
            </Form.Item>
          </Form>
          {dataAccess.map((item, index) => {
            return (
              <div key={item.menuId} className='border border-primary/90 mb-4 rounded-t-[4px]'>
                <div className='text-white text-sm w-full bg-primary/90 px-3 py-2 rounded-t-sm'>
                  {item.menuName}
                </div>
                <CustomProTable<MobileScreenAccessResponse>
                  className='w-full'
                  columns={columns}
                  handleResize={handleResize}
                  rowKey="pageId"
                  size="small"
                  bordered
                  scroll={{ x: undefined }}
                  search={false}
                  options={false}
                  // loading={loading}
                  dataSource={item.mobileScreenAccessResponses}
                  pagination={false}

                />
              </div>
            );
          })}

        </BoxContainer>
      </div>
    );
  });
};

export default accessMobilePage;
