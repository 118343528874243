import { ActionType, ProColumns } from "@ant-design/pro-components";
import { Form, Input, Modal } from "antd"
import CustomProTable, { tablePagination } from "base/ui/components/CustomTable";
import { observer } from "mobx-react"
import { SearchForm, SearchItem } from "pages/Components/SearchBox";
import { DataSearchDriver } from "pages/rawData/MasterData/model";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { driverStore, vendorPresenter, vendorStore } from "stores/root_store";
import { PAGE_SIZE_DEFAULT } from "utils/constants";
import CustomButton from 'base/ui/components/Button';
import { FaSearch } from "react-icons/fa";
import { cloneDeep } from "lodash";
import { removeEmptyValues } from "utils/transformHelper";
import { VendorFromSAP, VendorModel } from "services/RawData/vendor_services";

const initSearch = {
    paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
    },
    actived: true
}

type ModalVendorProps = {
    openModal: boolean;
    onModalOpenChange: (open: boolean) => void;
    onSelected: (driver: VendorFromSAP) => void;
}


const ModalVendor = (props: ModalVendorProps) => {
    const { onModalOpenChange, openModal, onSelected } = props
    const { t } = useTranslation();
    const defaultColumn: ProColumns<VendorFromSAP>[] = [
        // Stt
        {
            title: "STT",
            dataIndex: 'stt',
            key: 'stt',
            width: 50,
            align: 'center',
            render: (text) => <div className="!text-center">{text}</div>,
            fixed: 'left',
        },

        // Mã nhà cung cấp
        {
            title: "Mã nhà cung cấp",
            dataIndex: 'vendor',
            key: 'vendor',
            width: 100,
        },
        // Tên nhà cung cấp
        {
            title: "Tên nhà cung cấp",
            dataIndex: 'vendoR_NAME',
            key: 'vendoR_NAME',
            width: 150,
            ellipsis: true,
        },
        {
            title: t('permission.account.actions'),
            dataIndex: 'functions',
            key: 'functions',
            width: 80,
            fixed: 'right',
            align: 'center',
            render: (_, record) => {
                return (
                    <CustomButton
                        type="primary" color="#389e0d"
                        className="mx-auto" size="small"
                        onClick={() => {
                            onSelected(record)
                            onModalOpenChange(false)
                        }}
                    >Chọn</CustomButton>
                );
            },
        },

    ];
    const tableRef = useRef<ActionType>();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false)
    // Data search
    const [dataPaging, setDataPaging] = useState<DataSearchDriver>(initSearch);
    // List data table
    const [dataTable, setDataTable] = useState<VendorFromSAP[]>([]);

    // Call api search data
    const getDataTable = async (dataSearch: DataSearchDriver) => {
        setLoading(true);
        await vendorPresenter.searchVendorSAP(vendorStore, dataSearch);
        setLoading(false);
        if (vendorStore.listVendorSAPRes?.isSuccess) {
            setDataTable(vendorStore.listVendorSAPRes?.data);
        }
    };

    return (
        <Modal
            title={<h2 className="text-base uppercase">Tìm kiếm thông tin nhà cung cấp</h2>} style={{ top: 10 }} width={900}
            rootClassName="[&_.ant-modal-content]:!pb-1"
            footer={false}
            open={openModal}
            onCancel={() => {
                // setDataPaging(initSearch)
                // form.resetFields()
                onModalOpenChange(false)
            }}
        // destroyOnClose
        >
            <hr></hr>
            <SearchForm
                className="!my-2"
                form={form}
                onFinish={(values) => {
                    const dataResult = cloneDeep(values);
                    // loại bỏ những field không có giá trị
                    const search = removeEmptyValues(dataResult)
                    // set pageIndex 1 when search form
                    const searchWidthPaging = {
                        ...search,
                        paging: {
                            pageSize: 10,
                            pageIndex: 1,
                        },
                    }
                    setDataPaging(searchWidthPaging);
                    getDataTable(searchWidthPaging)
                }}
                layout="vertical"
                column="col-3"
            >
                <button type='submit' hidden></button>

                {/* Mã nhà cung cấp */}
                <SearchItem
                    dependencies={['supplierName']}
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value || getFieldValue('supplierName')) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Vui lòng nhập mã hoặc tên nhà cung cấp.'));
                            },
                        })
                    ]}
                    label={"Mã nhà cung cấp"} name={'supplierNumber'}>
                    <Input />
                </SearchItem>
                {/* Tên nhà cung cấp */}
                <SearchItem
                    dependencies={['supplierNumber']}
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value || getFieldValue('supplierNumber')) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Vui lòng nhập mã hoặc tên nhà cung cấp.'));
                            },
                        })
                    ]}
                    rootClassName="col-span-2" label={"Tên nhà cung cấp"} name={'supplierName'}>
                    <Input />
                </SearchItem>
                <div className="col-span-4 mt-2">
                    <CustomButton type="primary" htmlType="submit" className="mx-auto" ><FaSearch />Tìm kiếm</CustomButton>
                </div>
            </SearchForm>
            <CustomProTable<VendorFromSAP>
                columns={defaultColumn}
                rowKey="stt"
                size="small"
                bordered
                loading={loading}
                actionRef={tableRef}
                search={false}
                scroll={{ x: 'auto', y: 400 }}
                dataSource={dataTable}
                pagination={{
                    ...tablePagination(dataPaging, setDataPaging),
                    position: ["topRight"],
                }}
                options={false}
            />
        </Modal>
    )
}


export default observer(ModalVendor)