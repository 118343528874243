
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_DEFAULT, SYSTEM_TITLE } from 'utils/constants';
import { IoLocationOutline } from 'react-icons/io5';
import { Button, Card, Spin } from 'antd';
import { FaLaptopCode, FaMobileAlt } from 'react-icons/fa';
import { accountPresenter, accountStore, services, userPresenter, userStore } from 'stores/root_store';
import { getDeviceId, getUserName } from 'utils/localStorageHelper';
import { MdOutlineLogout } from 'react-icons/md';
import { HistoryLogIn } from 'services/Main/common_services';
import moment from 'moment';
import dayjs from 'dayjs';

const createHistoryPage = () => {
  return observer(() => {
    const { t } = useTranslation();

    const pagetitle = "Lịch sử truy cập người dùng"
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    // Get data params from url
    const { id: storeIdParams } = useParams();
    // Check button save and save continue

    const [historyLogIn, setHistoryLogIn] = useState<HistoryLogIn[]>([]);
    const [loading, setLoading] = useState(false);
    const [itemsToShow, setItemsToShow] = useState(5);

    const handleLoadMore = () => {
      setItemsToShow(prev => prev + 5); // Load 5 more items
    };

    // Call api get detail in store
    const getUserInfo = async (accountId: string) => {
      await accountPresenter.getAccountById(accountStore, accountId);
    }


    // Call api get detail in store
    const getUsernameFromId = async (accountId: string) => {
      await accountPresenter.getAccountById(accountStore, accountId);
      if (accountStore.accountDetail.isSuccess) {
        return accountStore.accountDetail.data?.userName
      } else {
        return ""
      }
    }

    const getLogHistoryLogIn = async () => {
      setLoading(true)
      const username = await getUsernameFromId(storeIdParams || "");
      await userPresenter.getLogLogIn(userStore, { userName: username, paging: { pageSize: 50, pageIndex: 1 } });
      if (userStore.historyLogInRes.isSuccess) {
        setHistoryLogIn(userStore.historyLogInRes.data || []);

      }
      setLoading(false)
    }

    const getSummaryHistoryLogIn = async () => {
      setLoading(true)
      const username = await getUsernameFromId(storeIdParams || "");

      await userPresenter.getSummaryLogLogin(userStore, username || "")
      setLoading(false)
    }

    useEffect(() => {
      getLogHistoryLogIn(); getSummaryHistoryLogIn()
      getUserInfo(storeIdParams || "");
    }, [])
    const currentDeviceId = getDeviceId();

    const WeirdFunction = (historyLogin: HistoryLogIn): HistoryLogIn => {
      // Tại  vì không bao giờ có chuyện 2 máy tính cùng lúc đăng nhập được, (máy tính sau đăng nhập sẽ làm máy tính trước đó đã đăng nhập bị đăng xuất), nên là mình hiểu ngầm là chỉ có mỗi máy tính hiện tại có trạng thái isActive là true, mấy máy tính khác không phải máy tính hiện tại trạng thái đăng nhập isActive là false hết, hàm này kiểm tra, nếu cái tài khoản đã truy cập đó không phải là máy tính hiện đang đăng nhập historyLogin.deviceId !== currentDeviceId, đồng thời tài khoản đã truy cập đó là máy tính chứ kh phải điện thoại !historyLogin.isPhone, đồng thời bằng cách nào đó mà tài khoản đã truy cập này lại có isActive là true thì sẽ set lại isActive của nó là false
      if (historyLogin.isActive && !historyLogin.isPhone && historyLogin.deviceId !== currentDeviceId) {
        return {
          ...historyLogin,
          isActive: false,
        }
      } else {
        return historyLogin
      }
    }
    console.log(userStore.summaryLogLogin)

    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('permission.account.title'),
                link: '/Permission/Account',
              },
              { title: pagetitle, link: `/Permission/Account/History/${storeIdParams}` },
            ]}
          />


          <BoxContainer fullHeight>
            <div className='flex md:flex-row flex-col'>

              <div className='flex-1 pr-10 border-r-2'>
                <h2 className="font-bold text-lg mb-4">Các thiết bị đã đăng nhập</h2>
                <Spin spinning={loading}>
                  {/* {historyLogIn.length === 0 && <p className='text-lg'>Trống</p>} */}
                  <div className=' flex gap-6'>
                    <ul className='flex flex-col gap-8 pl-3 items-stretch-'>
                      {historyLogIn.slice(0, itemsToShow).map((device, index) => (
                        <li key={index} className='flex gap-7 items-start '>
                          {device.isPhone ? <FaMobileAlt className="text-3xl" /> : <FaLaptopCode className="text-3xl" />}
                          <div>
                            <div className='flex gap-2 items-center'>
                              <p className='text-xs text-gray-800'>{moment(device.loginDate).format('DD/MM/YYYY hh:mm A')}</p>
                              <div className={`w-2 h-2 rounded-full ${device.isActive ? "bg-green-500" : "bg-slate-500"}`}></div>
                            </div>
                            <div className='flex justify-between mt-1'>
                              <p className='uppercase font-semibold'>{device.deviceName}</p>
                            </div>

                            <p className='text-gray-500 gap-1 flex mt-[2px] items-center'>
                              <IoLocationOutline className='text-lg' />
                              <span className='text-sm'> {device.location}</span>
                            </p>
                            {currentDeviceId === device.deviceId && <p className={`text-green-500 text-sm mt-2`}>Thiết bị này</p>}
                            {currentDeviceId !== device.deviceId && device.isActive && <button
                              onClick={async () => {
                                const username = await getUsernameFromId(storeIdParams || "");
                                await userPresenter.getLogOutDeviceId(userStore, device.deviceId, username || "")
                                // await userPresenter.signOut(userStore, device.deviceId, false);
                                await services.userServices.getLogOut(device.deviceId, false);

                                getLogHistoryLogIn();
                                // window.location.reload();
                              }}
                              className={`text-red-500 hover:brightness-110 transition duration-300 text-sm mt-2 flex gap-1`}>
                              <MdOutlineLogout className='text-lg' />  <span>Đăng xuất</span>
                            </button>}
                          </div>
                        </li>
                      ))}
                      {itemsToShow < historyLogIn.length && (
                        <button
                          onClick={handleLoadMore}
                          className='mt-4 px-4 py-2 text-gray-700 border-gray-700 border rounded transition duration-300'>
                          Xem thêm
                        </button>
                      )}
                    </ul>
                    <Button
                      disabled={historyLogIn.filter(history => history.isActive && currentDeviceId !== history.deviceId).length === 0}
                      onClick={async () => {
                        setLoading(true)
                        const username = await getUsernameFromId(storeIdParams || "");
                        await userPresenter.logOutAllDevice(userStore, historyLogIn.map(history => history.deviceId))
                        await Promise.all(historyLogIn.map(history => userPresenter.getLogOutDeviceId(userStore, history.deviceId, username || "")))

                        setLoading(false)
                        getLogHistoryLogIn();
                      }}
                      danger className="bg-red-500 text-white py-4 px-7 rounded-lg text-lg font-medium hover:brightness-110 transition duration-300">Đăng xuất tất cả</Button>
                  </div>
                </Spin></div>

              <div className='flex-1 pl-10'>
                <h2 className="font-bold text-lg mb-4">Thông tin đăng nhập</h2>
                <ul className='mb-7 flex flex-col gap-1 text-[15px] text-gray-800'>
                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Tài khoản: </p>
                    <p> {accountStore.accountDetail?.data?.userName} </p>
                  </li>
                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Tên nhân viên/ khách hàng:  </p>
                    <p> {accountStore.accountDetail?.data?.fullName}</p>
                  </li>

                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Trạng thái tài khoản: </p>
                    <p> {userStore.summaryLogLogin?.actived === true ? <span className='text-green-500'>Đang hoạt động</span> : userStore.summaryLogLogin?.actived === false ? <span className='text-red-500'>Ngưng sử dụng</span> : ""} </p>
                  </li>
                </ul>
                <ul className=' flex flex-col gap-1 text-[15px] text-gray-800'>
                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Lần đổi mật khẩu gần đây: </p> <p> {userStore.summaryLogLogin?.lastChangePasswordTime && dayjs(userStore.summaryLogLogin?.lastChangePasswordTime).format('DD/MM/YYYY HH:mm')} </p></li>
                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Số lần đăng nhập: </p>
                    <p> {userStore.summaryLogLogin?.loginCount || 0}</p>
                  </li>
                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Lần đăng nhập gần đây: </p>
                    <p> {userStore.summaryLogLogin?.lastLogin} </p>

                  </li>
                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Đăng nhập trước đó: </p> <p>{userStore.summaryLogLogin?.prevLogin}</p></li>

                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Lần đăng nhập thất bại gần đây: </p> <p className='text-red-500'> {userStore.summaryLogLogin?.lastFailedLogin}</p></li>
                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Số lần đăng nhập thất bại hiện tại: </p> <p className='text-red-500'> {userStore.summaryLogLogin?.currentFailedLogin || 0}</p></li>
                  <li className='flex'>
                    <p className='font-medium min-w-[270px] '>Tổng số lần đăng nhập thất bại: </p> <p className='text-red-500'> {userStore.summaryLogLogin?.totalFailedLogin || 0}</p></li>
                </ul>
              </div>
            </div>

          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createHistoryPage;
