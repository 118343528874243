import { MASTER_DATA_API_URL } from "utils/constants";
import { downloadFilePost, post } from '../../utils/api';
import { DataResponse } from "services/response";
import { SearchModel } from "pages/model";
import { DeliveryRegistrationReport, DeliveryResultReport, EmailSendingReport, ReceivingScheduleOfBHFReport } from "models/Report";

// Tìm kiếm của báo cáo lịch sử giao hàng
export type SearchDeliveryRegistrationReport = SearchModel & Partial<{
    weightStatus: string;
    receiveStatus: string;
    vendorNumber: string;
    vendorName: string;
    deliveryDateFrom: string;
    deliveryDateTo: string
}>

// tìm kiếm của báo cáo kết quả giao hàng
export type SearchDeliveryResultReport = SearchModel & Partial<{
    deliveryRegistrationCode: string;
    purchaseOrderCode: string;
    productCode: string;
    productName: string;
    vendorNumber: string;
    vendorName: string;
    taskStatusCategory: string;
    receiveStatus: string;
    receiveDateFrom: string;
    receiveDateTo: string
}>
// tìm kiếm của báo cáo receiving-schedule-of-bhf
export type SearchReceivingScheduleOfBHFReport = SearchModel & Partial<{
    purchaseOrderCode: string;
    productCode: string;
    productName: string;
    vendorNumber: string;
    stockCode: string;
    deliveredTime: string;
    type: string
}>

export type SearchEmailSendingReport = SearchModel & Partial<{
    plantCode: string;
    status: boolean;
    type: string;
    fromTime: string;
    toTime: string;
}>

// ============ Khai báo phương thức =====================
export interface IReportServices {
    //====================== BÁO CÁO DANH SÁCH ĐĂNG KÝ XE =================================================
    // search
    getListReportDeliveryRegistration(data: SearchDeliveryRegistrationReport): Promise<DataResponse<DeliveryRegistrationReport[]>>;
    // Export excel
    exportListReportDeliveryRegistration(data: SearchDeliveryRegistrationReport): Promise<void>;
    //====================== BÁO CÁO KẾT QUẢ GIAO HÀNG =================================================
    //  Search
    getReportDeliveryResult(data: SearchDeliveryResultReport): Promise<DataResponse<DeliveryResultReport[]>>
    // Export excel
    exportReportDeliveryResult(data: Omit<SearchDeliveryResultReport, 'paging'>): Promise<void>;
    //====================== BÁO CÁO RECEIVING-SCHEDULE-OF-BHF =================================================
    // search 
    getReceivingSchedulReport(data: SearchReceivingScheduleOfBHFReport): Promise<DataResponse<ReceivingScheduleOfBHFReport[]>>
    // Export excel
    exportReceivingSchedulReport(data: Omit<SearchReceivingScheduleOfBHFReport, 'paging'>): Promise<void>;
    //====================== BÁO CÁO KẾT QUẢ GỬI EMAIL =========================================================
    // Search
    getEmailSendingReport(data: SearchEmailSendingReport): Promise<DataResponse<EmailSendingReport[]>>;
    // Export excel
    exportEmailSendingReport(data: Omit<SearchEmailSendingReport, 'paging'>): Promise<void>;
}
// Hàm khởi tạo các phương nhà của NKMH service
export function createReportServices(): IReportServices {
    return {
        //====================== BÁO CÁO DANH SÁCH ĐĂNG KÝ XE =================================================
        // search
        getListReportDeliveryRegistration: async (data) => {
            return (await post(MASTER_DATA_API_URL, `report/report-list-delivery-registration`, data)).data;
        },
        // Export excel
        exportListReportDeliveryRegistration: async (data) => {
            return (await downloadFilePost(MASTER_DATA_API_URL, `report/export-report-list-delivery-registration`, data));
        },
        //====================== BÁO CÁO KẾT QUẢ GIAO HÀNG =================================================
        // search
        getReportDeliveryResult: async (data) => {
            return (await post(MASTER_DATA_API_URL, `report/report-delivery-registration-result`, data)).data;
        },
        // Export excel
        exportReportDeliveryResult: async (data) => {
            return (await downloadFilePost(MASTER_DATA_API_URL, `report/export-report-delivery-registration-result`, data));
        },
        //====================== BÁO CÁO RECEIVING-SCHEDULE-OF-BHF =================================================
        // search
        getReceivingSchedulReport: async (data) => {
            return (await post(MASTER_DATA_API_URL, `report/receiving-schedule-of-bhf`, data)).data;
        },
        // Export excel
        exportReceivingSchedulReport: async (data) => {
            return (await downloadFilePost(MASTER_DATA_API_URL, `report/export-receiving-schedule-of-bhf`, data));
        },
        // ======================= Báo cáo kết quả gửi email =========================================================
        getEmailSendingReport: async (data) => {
            return (await post(MASTER_DATA_API_URL, `report/report-send-email`, data)).data;
        }
        ,
        exportEmailSendingReport: async (data) => {
            return (await downloadFilePost(MASTER_DATA_API_URL, `report/export-report-send-email`, data));
        },

    };
}
