import * as Icon from 'base/ui/components/Icons';

import { Dropdown, Form, MenuProps, Modal, Select, Space, message } from 'antd';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { provincePresenter, provinceStore, userStore } from 'stores/root_store';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import CustomProTable from 'base/ui/components/CustomTable';
import { usePermission } from 'hooks/usePermission';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { HiSearch } from 'react-icons/hi';
import { Province } from 'services/RawData/province_services';
import { dropdownStatus } from 'utils/dropdown';
import { removeEmptyValues } from 'utils/transformHelper';
import { DataSearchProvince } from '../model';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';

const { confirm } = Modal;

const provincePage = () => {
  return observer(() => {
    const { t } = useTranslation();

    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    const pagetitle = t('masterdata.province.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);

    // Columns table title
    const defaultColumn: ProColumns<Province>[] = [
      // STT
      {
        title: t('masterdata.province.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 60,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
      },
      // mã tỉnh thành
      {
        title: t('masterdata.province.province-code'),
        dataIndex: 'provinceCode',
        key: 'provinceCode',

        className: '!rounded-none !py-1',
        fixed: 'left',
        render: (text, record) => {
          return <Link to={`/MasterData/Province/${record.provinceId}`}>{record.provinceCode}</Link>;
        },
      },
      // tên tỉnh thảnh
      {
        title: t('masterdata.province.province-name'),
        dataIndex: 'provinceName',
        key: 'provinceName',

        className: '!rounded-none !py-1',
        fixed: 'left',
      },
      // khu vực
      {
        title: t('masterdata.province.area'),
        dataIndex: 'area',
        key: 'area',

        className: '!rounded-none !py-1',
        onCell: () => {
          return {
            style: {
              textAlign: 'right',
            },
          };
        },
      },
      // thứ tự hiển thị
      {
        title: t('masterdata.province.order-index'),
        dataIndex: 'orderIndex',
        key: 'orderIndex',

        className: '!rounded-none !py-1',
        onCell: () => {
          return {
            style: {
              textAlign: 'right',
            },
          };
        },
      },
      // trạng thái
      {
        title: t('masterdata.province.status'),
        dataIndex: 'actived',
        key: 'actived',
        width: 100,
        render: (text) => (
          <div className="w-full flex justify-center">
            {text ? (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            ) : (
              <Icon.CloseStatus width="15" className="!fill-red-500" />
            )}
          </div>
        ),
      },
      // chức năng
      {
        hideInTable:
          userStore.userPermission?.includes('EDIT') && userStore.userPermission?.includes('DELETE') ? true : false,
        title: t('masterdata.province.actions'),
        dataIndex: 'actions',
        key: 'actions',
        className: '!rounded-none !py-1',
        align: 'center',
        width: 100,
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') ? (
                <CustomButtonIcon color="#007BF1" onClick={() => navigate(`${record.provinceId}`)}>
                  <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                </CustomButtonIcon>
              ) : null}
              {userStore.userPermission?.includes('DELETE') ? (
                record.actived ? (
                  <CustomButtonIcon
                    color="#FC5C5E"
                    onClick={() => {
                      showDeleteConfirm(record);
                    }}
                  >
                    <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                  </CustomButtonIcon>
                ) : (
                  <CustomButtonIcon color="#fff00" className="border-none outline-none cursor-default">
                    <Icon.Trash className="!fill-transparent" width="14" height="14" />
                  </CustomButtonIcon>
                )
              ) : null}
            </div>
          );
        },
      },
    ];
    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<Province>[]>(defaultColumn);
    // Loading all page
    const [loading, setLoading] = useState(false);

    // Data search
    const [dataSearch, setDataSearch] = useState({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    });

    // List data table
    const [listProvince, setListProvince] = useState<Province[]>([]);

    const [selected, setSelected] = useState('Export');
    const items: MenuProps['items'] = [
      {
        label: (
          <Link onClick={(e) => setSelected(e.currentTarget.textContent || '')} to={'/Form/Customer'}>
            Mẫu thêm mới
          </Link>
        ),
        key: '0',
      },
      {
        label: (
          <Link onClick={(e) => setSelected(e.currentTarget.textContent || '')} to={'/Form/Customer'}>
            Mẫu cập nhật
          </Link>
        ),
        key: '1',
      },
    ];
    // function open hide drawer ===========================================================

    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Ui modal confirm delete store ================================================================
    const showDeleteConfirm = (item: Province) => {
      confirm({
        title: `Xoá tỉnh thành`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} "${item.provinceName}"`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDelete(item.provinceId);
        },
        onCancel() { },
      });
    };
    // Call api delete
    const handleClickDelete = async (id?: string) => {
      setLoading(true);
      if (id) {
        await provincePresenter.deleteProvince(provinceStore, id);
        if (provinceStore.crudResponse.isSuccess) {
          message.success(provinceStore.crudResponse.message);
          // Call list branch after change
          await getDataSearchListProvince(dataSearch);
        } else {
          message.error(provinceStore.crudResponse.message);
        }
      }
      setLoading(false);
    };

    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    // Call api data search
    const getDataSearchListProvince = async (dataSearch: DataSearchProvince) => {
      await provincePresenter.searchListProvince(provinceStore, dataSearch);
      if (provinceStore.listProvince.isSuccess) {
        setListProvince(provinceStore.listProvince.data);
      }
    };

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
        ...values,
      };
      removeEmptyValues(tempDataSearch);
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);

      await getDataSearchListProvince(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };

    // initialValues of form search
    const initialValues = {
      actived: null,
    };

    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: 'Province' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  onClick={() => {
                    navigate('create');
                  }}
                  type="primary"
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null}
              {userStore.userPermission?.includes('EXPORT') ? (
                <CustomButton>
                  <Dropdown menu={{ items }} trigger={['click']}>
                    <Space className="cursor-pointer h-[46px]">
                      <span>
                        <Icon.Download className={'!fill-dark'} />
                      </span>
                      <span className={`text-dark font-[400] text-[14px] leading-[22px]`}>{selected}</span>
                      <span>
                        <Icon.ChevronDown className={'!fill-dark'} />
                      </span>
                    </Space>
                  </Dropdown>
                </CustomButton>
              ) : null}
              {userStore.userPermission?.includes('IMPORT') ? (
                <CustomButton icon={<Icon.Upload />}>Import</CustomButton>
              ) : null}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<Province>
            columns={columns}
            handleResize={() => { }}
            rowKey="stt"
            size="small"
            bordered
            search={false}
            loading={loading}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const values = form.getFieldsValue();
              const data: any = await getDataSearchListProvince({
                ...values,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: provinceStore.listProvince.isSuccess,
              };
            }}
            dataSource={listProvince}
            scroll={{ x: true }}
            pagination={{
              pageSize: dataSearch.paging.pageSize,
              total: provinceStore.listProvince.paging?.recordsTotal,
              current: dataSearch.paging.pageIndex,
              responsive: true,
              position: ['topRight', 'bottomRight'],
              style: { margin: '10px 0' },
              showSizeChanger: false,
              showTotal: (total, range) => (
                <>
                  <span className="!absolute left-1">
                    {t('message.view')}{' '}
                    <CustomSelect
                      size="middle"
                      className="min-w-[72px] !rounded-none"
                      value={String(dataSearch.paging.pageSize)}
                      onChange={(value) => {
                        setDataSearch({
                          ...dataSearch,
                          paging: { ...dataSearch.paging, pageSize: Number(value) },
                        });
                      }}
                    >
                      {PAGE_SIZE_OPTION.map((pageSize) => (
                        <Select.Option key={pageSize} value={String(pageSize)}>
                          {pageSize}
                        </Select.Option>
                      ))}
                    </CustomSelect>{' '}
                    {t('message.item')}
                  </span>
                  <span className="hidden md:block">{`${t('message.display')} ${range[0]}-${range[1]} ${t(
                    'message.of',
                  )} ${total} ${t('message.entries')}`}</span>{' '}
                </>
              ),
              onChange: (page, pageSize) => {
                setDataSearch({
                  ...dataSearch,
                  paging: { ...dataSearch.paging, pageIndex: page, pageSize: pageSize },
                });
              },
            }}
            options={false}
          />
        </BoxContainer>

        {/* form tìm kiếm */}
        <SearchBox
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm
            initialValues={initialValues}
            form={form}
            onFinish={handleSearch}
            layout="vertical"
            column="col-1"
          >
            {/* Tên tỉnh thành */}
            <SearchItem label={t('masterdata.province.province-name')} name={'provinceName'}>
              <CustomInput className="!rounded-none" />
            </SearchItem>
            {/* Trạng thái */}
            <SearchItem label={t('masterdata.province.status')} name={'actived'}>
              <CustomSelect options={dropdownStatus} placeholder={t('place-holder.all')} allowClear />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default provincePage;
