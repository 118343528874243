import { observer } from "mobx-react"
import PageWrapper from "pages/Components/PageWrapper"
import CustomButton from 'base/ui/components/Button';
import { useEffect, useState } from "react";
import BoxContainer from "base/ui/components/BoxContainer";
import { Form, Radio, message } from "antd";
import { POCreatRequest } from "models/PurchaseOrder";
import { nanoid } from "@ant-design/pro-components";
import { SYSTEM_TITLE } from "utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "base/routes/routes";
import { purchaseOrderPresenter, purchaseOrderStore } from "stores/root_store";
import { MdSaveAs } from "react-icons/md";
import { IoMdSave } from "react-icons/io";
import General from "../CreatePO/General";
import PurchaseOrderTabel from "../CreatePO/PurchaseOrderTabel";
import { useCheckPermission, useCheckPermissionByUrl } from "utils/permissionHelper";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { SearchItem } from "pages/Components/SearchBox";


export function createUpdatePurchaseOrder() {
    return observer(() => {
        document.title = 'Cập nhật - ' + SYSTEM_TITLE;
        useCheckPermissionByUrl(ROUTES.PURCHASE_ORDER.LIST_PO.LINK, "EDIT")
        const { purchaseOrderId } = useParams();
        const { t } = useTranslation();

        const navigate = useNavigate()
        const [formEdit] = Form.useForm()
        const id = nanoid()
        const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([id]);
        const [loading, setLoading] = useState(true)



        const handleUpdate = async (values: POCreatRequest, isContinue?: boolean) => {
            const request = values.purchaseOrderItems?.map(p => ({ ...p, documentDate: typeof p.documentDate !== 'string' ? dayjs(p.documentDate).format("YYYY-MM-DD[T]HH:mm:ss") : p.documentDate }))
            setLoading(true)
            const response = await purchaseOrderPresenter.updatePurchaseOrder({ ...values, purchaseOrderItems: request, purchaseOrderId: purchaseOrderStore.detailPO?.purchaseOrderId })
            setLoading(false)
            if (response.isSuccess) {
                message.success(response.message)
                if (isContinue) {
                    getDetails()
                } else {
                    navigate(ROUTES.PURCHASE_ORDER.LIST_PO.LINK)
                }
            } else {
                message.error(response.message)
            }
        }

        const getDetails = async () => {
            if (purchaseOrderId) {
                await purchaseOrderPresenter.getDetailsPurchaseOrder(purchaseOrderStore, purchaseOrderId)
                setLoading(false)
                if (purchaseOrderStore.detailPO) {
                    formEdit.setFieldsValue(purchaseOrderStore.detailPO)
                    const keys = purchaseOrderStore.detailPO.purchaseOrderItems?.map(item => item.purchaseOrderDetailId || '')
                    setEditableRowKeys(keys || [])
                }
            }
        }

        useEffect(() => {
            getDetails()
        }, []);


        return (
            <PageWrapper breadcrumb={[{
                text: "Kế hoạch nhận hàng",
                to: ROUTES.PURCHASE_ORDER.LIST_PO.LINK
            }, {
                text: 'Cập nhật'
            }
            ]}
                extras={
                    <>
                        <CustomButton
                            loading={loading}
                            disabled={loading}
                            onClick={() => {
                                formEdit.validateFields().then((value) => {
                                    handleUpdate(value);
                                }).catch((error) => { console.log(error) })
                            }}
                            icon={<IoMdSave className="text-base" />}>
                            Lưu
                        </CustomButton>
                        <CustomButton
                            loading={loading}
                            disabled={loading}
                            type="primary"
                            icon={<MdSaveAs className="text-base" />}
                            onClick={() => {
                                formEdit.validateFields().then((value) => {
                                    handleUpdate(value, true);
                                }).catch((error) => { console.log(error) })
                            }}
                        >
                            Lưu và cập nhật
                        </CustomButton>
                    </>
                }
            >
                <BoxContainer fullHeight className="!bg-transparent !p-0">
                    <Form
                        autoComplete="off"
                        initialValues={{ purchaseOrderItems: [{ purchaseOrderDetailId: id }] }}
                        layout="vertical"
                        form={formEdit}
                        className="flex flex-col gap-default"
                    >
                        <General
                            form={formEdit}
                            extras={
                                <SearchItem className="m-0 mb-2" name={'actived'} label={t('masterdata.shared-category.status')}>
                                    <Radio.Group>
                                        <Radio value={true}> {t('radio-btn.active')}</Radio>
                                        <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                                    </Radio.Group>
                                </SearchItem>
                            }
                        />
                        <PurchaseOrderTabel
                            isEdit={true}
                            editableKeys={editableKeys}
                            id={id}
                            setEditableRowKeys={setEditableRowKeys}

                        />
                    </Form>

                </BoxContainer>
            </PageWrapper>
        )
    })
}