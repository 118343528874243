import { LayoutHOC } from 'base/ui/layout/layout';
import { createRolesPage } from './Roles/create';
import { PermissionRoutes } from './routes';
import { createConfigAccountPage } from './ConfigAccount/create';
// import { createConfigAccountVOIPPage } from './ConfigAccountVOIP/create';
import { createAccountPage } from './Account/create';
import { createAccessPage } from './AccessWeb/create';
import { createAccessMobilePage } from './AccessMobile/create';
import { createPagePage } from './Page/create';
import { createMenuPage } from './Menu/create';
import { IServices } from 'services/services';
import { createMobileScreenPage } from './MobileScreen/create';
import { createFunctionPage } from './Function/create';

export const createPermissionPage = (layoutHOC: LayoutHOC, services: IServices) => {
  // nhóm người dùng
  const RolesPage = createRolesPage();
  const AccountPage = createAccountPage();
  const ConfigAccountPage = createConfigAccountPage();
  // const ConfigAccountVOIPPage = createConfigAccountVOIPPage();
  const AccessPage = createAccessPage(services.accessServices);
  const AccessMobilePage = createAccessMobilePage(services.accessMobileServices);
  const Page = createPagePage();
  const Menu = createMenuPage();
  const MobileScreen = createMobileScreenPage();
  const FunctionPage = createFunctionPage();

  const PermissionPage = layoutHOC(PermissionRoutes);
  return () => (
    <PermissionPage
      RolesPage={RolesPage}
      AccountPage={AccountPage}
      ConfigAccountPage={ConfigAccountPage}
      // ConfigAccountVOIPPage={ConfigAccountVOIPPage}
      AccessPage={AccessPage}
      AccessMobilePage={AccessMobilePage}
      Page={Page}
      Menu={Menu}
      MobileScreen={MobileScreen}
      FunctionPage={FunctionPage}
    />
  );
};
