import { observable, runInAction } from 'mobx';
import { IProductServices, ProductModel } from 'services/RawData/product_services';
import { DataResponse } from 'services/response';

export type productStore = {
  // Type default response search
  searchProductResponse: DataResponse<Array<ProductModel>>;
  productDetailResponse: DataResponse<ProductModel | undefined>;
  crudResponse: DataResponse<null>;
};

export interface IProductPresenter {
  createStore(): productStore;
  // Search product
  searchProduct(store: productStore, data: object): Promise<void>;
  // Update product
  updateProduct(store: productStore, data: object): Promise<void>;
  // Get product by ProductId
  getProductById(store: productStore, ProductId: string): Promise<void>;
  updateMultiProduct(store: productStore, data: object): Promise<void>;
  // export template 
  exportProductTemplate(data: object): Promise<void>
}

export function createProductPresenter({ productServices }: { productServices: IProductServices }): IProductPresenter {
  return {
    createStore: () =>
      observable({
        searchProductResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        productDetailResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        crudResponse: {
          data: null,
          message: '',
          isSuccess: false,
        },
      }),
    // Search product
    searchProduct: async (store, data) => {
      try {
        const result = await productServices.searchProducts(data);
        runInAction(() => {
          store.searchProductResponse.data = result.data;
          store.searchProductResponse.isSuccess = result.isSuccess;
          store.searchProductResponse.message = result.message;
          store.searchProductResponse.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    updateProduct: async (store, data) => {
      try {
        const result = await productServices.updateProduct(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },

    getProductById: async (store, ProductId) => {
      try {
        const result = await productServices.getDetailProduct(ProductId);
        runInAction(() => {
          store.productDetailResponse.isSuccess = result.isSuccess;
          store.productDetailResponse.data = result.data;
          store.productDetailResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    updateMultiProduct: async (store, data) => {
      try {
        const result = await productServices.updateMultiProduct(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // export template 
    exportProductTemplate: async (data) => {
      try {
        await productServices.exportProductTemplate(data);
      } catch (error) {
        console.log(error);
      }
    },

  };
}
