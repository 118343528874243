import wardPage from '.';
import createAddWard from './CreateUpdate';
import { wardRoutes } from './routes';

export const createWardPage = () => {
  const Ward = wardPage();
  const AddWard = createAddWard();
  const UpdateWard = createAddWard();

  const WardPage = wardRoutes;
  return () => <WardPage Ward={Ward} AddWard={AddWard} UpdateWard={UpdateWard} />;
};
