import { PDFViewer } from '@react-pdf/renderer';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const vehicleRegistrationRoutes = ({
    PrintPDF,
}: {
    PrintPDF: React.ComponentType;
}) => (
    <Routes>
        <Route path={ROUTES.PRINT_PDF.CONTENT} element={<PrintPDF />} />
    </Routes>
);
