import districtPage from '.';
import createAddUpdateDistrict from './CreateUpdate';
import { DistrictRoutes } from './routes';

export const createDistrictPage = () => {
  const District = districtPage();
  const AddDistrict = createAddUpdateDistrict();
  const UpdateDistrict = createAddUpdateDistrict();

  const DistrictPage = DistrictRoutes;
  return () => <DistrictPage District={District} AddDistrict={AddDistrict} UpdateDistrict={UpdateDistrict} />;
};
