import { message } from 'antd';
import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { IMobileScreenServices, MobileScreenModel } from 'services/RawData/mobileScreen_services';

export type MobileScreenStore = {
    searchScreenRes: DataResponse<Array<MobileScreenModel>>
    screenDetail: DataResponse<MobileScreenModel | undefined>

};

export interface IPagePresenter {
    createStore(): MobileScreenStore;
    // Search page
    searchMobileScreen(store: MobileScreenStore, data: object): Promise<void>;
    getDetailMobileScreen(store: MobileScreenStore, id: string): Promise<void>

    createMobileScreen(data: object): Promise<boolean>
    updateMobileScreen(data: object): Promise<boolean>
    deleteMobileScreen(id: string): Promise<boolean>
}

export function createMobileScreenPresenter({
    mobileScreenServices,
}: {
    mobileScreenServices: IMobileScreenServices;
}): IPagePresenter {
    return {
        createStore: () =>
            observable({
                searchScreenRes: {
                    data: [],
                    paging: {
                        resultsCount: 0,
                        recordsTotal: 0,
                        pagesCount: 0,
                    },
                    message: '',
                    isSuccess: false,
                },
                screenDetail: {
                    data: undefined,
                    message: '',
                    isSuccess: false,
                },

            }),
        // Search district
        searchMobileScreen: async (store, data) => {
            try {
                const result = await mobileScreenServices.searchMobileScreen(data);
                runInAction(() => {
                    store.searchScreenRes = result;

                });
            } catch (error) {
                console.log(error);
            }
        },
        getDetailMobileScreen: async (store, id) => {
            try {
                const result = await mobileScreenServices.getDetailMobileScreen(id);
                runInAction(() => {
                    store.screenDetail = result;
                });
            } catch (error) {
                console.log(error);
            }
        },

        createMobileScreen: async (data) => {
            try {
                const result = await mobileScreenServices.createMobileScreen(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        updateMobileScreen: async (data) => {
            try {
                const result = await mobileScreenServices.updateMobileScreen(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        deleteMobileScreen: async (data) => {
            try {
                const result = await mobileScreenServices.deleteMobileScreen(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
    };
}
