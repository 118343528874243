import React, { useCallback, useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { useNavigate } from 'react-router-dom';
import signalrConnection from 'services/Notification/signalr-connection';
import { ROUTES } from 'base/routes/routes';
import { clearUserSession, getDeviceId, getThemeColorCode } from 'utils/localStorageHelper';
import { Modal } from 'antd';
import { FaLock } from 'react-icons/fa';
import { uiPresenter, userPresenter, userStore } from 'stores/root_store';

const SignalRListener: React.FC<{ currentUserAccountId: string }> = ({ currentUserAccountId }) => {
  const navigate = useNavigate();
  // const [isModalVisible, setIsModalVisible] = useState(false);

  const logoutUserAndShowModal = useCallback((title: string, content: string) => {
    // Hàm để đăng xuất người dùng
    const logoutUser = () => {
      clearUserSession();
      userPresenter.signOut(userStore, getDeviceId(), true)
      navigate(ROUTES.LOGIN.BASE)
    };

    logoutUser();
    // After navigating, show the modal
    setTimeout(() => {
      showLockedAccountModal(title, content);
    }, 0); // Use a timeout to ensure the modal is shown after navigation
  }, [navigate])

  useEffect(() => {
    const connection = signalrConnection();

    // Lắng nghe sự kiện "LogoutAccount"
    connection.on("LogoutAccount", (accountId) => {
      if (accountId === currentUserAccountId) {
        // Nếu accountId khớp với user hiện tại thì tiến hành đăng xuất
        // showLockedAccountModal();
        logoutUserAndShowModal("Tài khoản bị khóa", "Tài khoản của bạn đã bị khóa");
      }
    });

    connection.on("ReceiveLogoutMessage", () => {
      logoutUserAndShowModal("Phiên đăng nhập hết hạn", "Phiên đăng nhập của bạn đã hết hạn. Vui lòng đăng nhập lại. ");
    })

    // Cleanup: hủy kết nối khi component bị unmount
    return () => {
      connection.stop();
    };
  }, [currentUserAccountId, logoutUserAndShowModal]);

  const showLockedAccountModal = (title: string, content: string) => {
    Modal.info({
      title: title,
      content: content,
      okText: 'OK',
      centered: true,
      icon: <FaLock className={`text-[${getThemeColorCode()}] mr-3 mt-1`} size={20} />,
      okButtonProps: {
        style: {
          backgroundColor: getThemeColorCode(),
          borderColor: getThemeColorCode(),
          color: 'white',
          display: 'block',
          margin: '0 auto',
          outline: 'none'
        },
        className: "hover:brightness-110 duration-300 transition"
      }
    });
  };

  return null;
}

export default SignalRListener;
