import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { get, post, put } from 'utils/api';

export interface AccessModel {
    menuId: string;
    menuName: string;
    moduleId: string;
    icon: string;
    orderIndex: number;
    pageAccessResponses: PageAccess[]
    rowIndex: number;
    error: any
    isNullValueId?: boolean;
    isChooseAll?: boolean
}

export interface PageAccess {
    pageId: string;
    pageName: string;
    pageUrl?: string;
    icon?: string;
    color?: string;
    parameter?: string;
    moduleId?: string;
    menuId?: string;
    orderIndex: number;
    functionAccessResponses: FunctionAccess[]
    isChooseAll?: boolean
}

export interface FunctionAccess {
    pageId: string;
    mobileScreenId: string;
    functionId: string;
    functionName: string;
    orderIndex?: number;
    selected: boolean
}


export interface IAccessServices {
    getAccessByRoleId(roleId: string): Promise<DataResponse<Array<AccessModel>>>
    // update permissions (function check)
    updateFunctionPermissions(data: { roleId: string; pageId: string; functionId: string; check: boolean }): Promise<DataResponse<null>>
    // update permissions in page
    updateFunctionPermissionInPage(data: { roleId: string; pageId: string; check: boolean }): Promise<DataResponse<null>>
    // update permissions in menu
    updateFunctionPermissionInMenu(data: { roleId: string; menuId: string; check: boolean }): Promise<DataResponse<null>>

}
export function createAccessServices(): IAccessServices {
    return {
        // Search danh mục
        getAccessByRoleId: async (roleId) => {
            return (await get(MASTER_DATA_API_URL, `access/${roleId}`)).data;
        },
        // update permissions (function check)
        updateFunctionPermissions: async (data) => {
            return (await put(MASTER_DATA_API_URL, `access/update-function-permission`, data)).data;
        },
        // update permissions in page
        updateFunctionPermissionInPage: async (data) => {
            return (await put(MASTER_DATA_API_URL, `access/update-function-permission-in-page`, data)).data;
        },
        // update permissions in menu
        updateFunctionPermissionInMenu: async (data) => {
            return (await put(MASTER_DATA_API_URL, `access/update-function-permission-in-menu`, data)).data;
        }
    };
}
