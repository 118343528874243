import { deleted, get, post, put } from 'utils/api';

import { DataResponse } from '../response';
import { MASTER_DATA_API_URL } from 'utils/constants';

export type SalesEmployee = {
  stt: number;
  departmentId: string;
  salesEmployeeCode: string;
  companyId: string;
  companyName: string;
  storeId: string;
  departmentName: string;
  salesEmployeeName: string;
  salesEmployeeShortName: string;
  abbreviatedName: string;
  email: string;
  phone: string;
  actived: boolean;

  brand: string[];
  group: string[];
  brandName: string[];
  groupName: string[];
};

export interface ISalesEmployeeServices {
  // search account list
  searchSalesEmployees(data: object): Promise<DataResponse<Array<SalesEmployee>>>;
  // add an account
  addSalesEmployee(data: object): Promise<DataResponse<any>>;
  // update account list
  updateSalesEmployee(data: object): Promise<DataResponse<any>>;
  // delete account by Code
  deleteAccountByCode(AccountId: string): Promise<DataResponse<any>>;
  // lấy data nhân viên bằng id
  getSalesEmployeeById(EmployeeCode: string): Promise<DataResponse<any>>;
}

export function createSalesEmployeeServices(): ISalesEmployeeServices {
  return {
    searchSalesEmployees: async (data) => {
      return (await post(MASTER_DATA_API_URL, `employee/search`, data)).data;
    },
    addSalesEmployee: async (data) => {
      return (await post(MASTER_DATA_API_URL, `employee/create`, data)).data;
    },
    updateSalesEmployee: async (data) => {
      return (await put(MASTER_DATA_API_URL, `employee/update`, data)).data;
    },
    // lấy data nhân viên bằng id
    getSalesEmployeeById: async (EmployeeCode) => {
      return (
        await get(
          MASTER_DATA_API_URL,
          `employee/${EmployeeCode}`,
        )
      ).data;
    },
    // xóa tài khoản bằng id
    deleteAccountByCode: async (Code) => {
      return (await deleted(MASTER_DATA_API_URL, `employee/delete/${Code}`)).data;
    },
  };
}
