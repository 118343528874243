import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';

export type DataCompany = {
  stt: number;
  companyId: string;
  companyCode: string;
  companyName: string;
  telProduct: string;
  telService: string;
  companyAddress: string;
  logo?: string;
  orderIndex: number;
  actived: boolean;
  taxCode: string;
  plant: string;
};

export interface ICompanyServices {
  // Search company
  searchCompany(data: object): Promise<DataResponse<Array<DataCompany>>>;
  // Get detail company
  getDetailCompany(companyId: string): Promise<DataResponse<DataCompany>>;
  // Update detail company
  updateDetailCompany(params: DataCompany): Promise<DataResponse<boolean>>;
  // Create detail company
  createDetailCompany(params: DataCompany): Promise<DataResponse<boolean>>;
  // Create detail company
  deleteCompany(companyId: string): Promise<DataResponse<boolean>>;
}

export function createCompanyServices(): ICompanyServices {
  return {
    // Search company
    searchCompany: async (data) => {
      return (await post(MASTER_DATA_API_URL, `company/search`, data)).data;
    },
    // Get detail company
    getDetailCompany: async (companyId) => {
      return (
        await get(MASTER_DATA_API_URL, `company/${companyId}`)
      ).data;
    },
    // Update detail company
    updateDetailCompany: async (params) => {
      return (await put(MASTER_DATA_API_URL, `company/update`, params)).data;
    },
    // Create detail company
    createDetailCompany: async (data) => {
      return (await post(MASTER_DATA_API_URL, `company/create`, data)).data;
    },
    // Delete company
    deleteCompany: async (companyId) => {
      return (await deleted(MASTER_DATA_API_URL, `company/delete/${companyId}`)).data;
    },
  };
}
