/* eslint-disable react-hooks/exhaustive-deps */
import * as Icon from 'base/ui/components/Icons';

import { Form, InputNumber, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { commonMasterDataPresenter, commonMasterDataStore, userStore, wardPresenter, wardStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { SYSTEM_TITLE } from 'utils/constants';
import { TypeCommonWard } from 'pages/rawData/Permission/model';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'antd/es/form/Form';

const createAddWard = () => {
  return observer(() => {
    const { t } = useTranslation();

    // Get data params from url
    const { id: idParams } = useParams();
    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }
    const navigate = useNavigate();
    // Check button save and save continue
    let isSaveAndContinue = false;
    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);

    //===============================================FUNCTION=====================================//
    // Call api detail store
    const getDetailWard = async (id: string) => {
      await wardPresenter.getWardById(wardStore, id);
      if (wardStore.detailWard.isSuccess) {
        form.setFieldsValue(wardStore.detailWard.data);

        await commonMasterDataPresenter.getDropDownDistrict(commonMasterDataStore, wardStore.detailWard.data?.provinceId!);

        setLoading(false);
      } else {
        navigate('/MasterData/Ward');
      }
    };
    // Handle create/update
    const onFinish = async (values: any) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        await wardPresenter.createWard(wardStore, values);
        if (wardStore.crudResponse.isSuccess) {
          message.success(wardStore.crudResponse.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Ward');
          }
          form.resetFields();
        }
      } else {
        await wardPresenter.updateWard(wardStore, {
          ...values,
          wardId: idParams,
        });
        if (wardStore.crudResponse.isSuccess) {
          message.success(wardStore.crudResponse.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Ward');
          }
        }
      }

      setLoading(false);
    };

    const initialValues = {
      actived: true,
      appellation: 'Phường',
    };

    useEffect(() => {
      (async () => {
        if (idParams) {
          setLoading(true);
          await getDetailWard(idParams);
        }
        await commonMasterDataPresenter.getDropDownProvince(commonMasterDataStore);
      })();
    }, []);

    // call api get data of dropdown ward
    const provinceId = useWatch('provinceId', form);
    useEffect(() => {
      (async () => {
        await commonMasterDataPresenter.getDropDownDistrict(commonMasterDataStore, provinceId);
      })();
    }, [provinceId]);
    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }
    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: 'Phường xã',
                link: '/MasterData/Ward',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/Ward/${idParams}` : '/MasterData/Ward/create',
              },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                  {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                </div>
              </>
            }
          />

          <BoxContainer fullHeight>
            {/* Form create/update */}
            <Spin spinning={loading}>
              <SearchForm onFinish={onFinish} initialValues={idParams ? {} : initialValues} form={form}>
                {/* Tỉnh/thành phố */}
                <SearchItem label={t('masterdata.ward.province')} name={'provinceId'}>
                  <CustomSelect
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    options={commonMasterDataStore.provinceDropdown.data.map((item) => {
                      return {
                        label: item.value,
                        value: item.key,
                      };
                    })}
                  />
                </SearchItem>
                {/* quận huyện */}
                <SearchItem label={t('masterdata.ward.district')} name={'districtId'}>
                  <CustomSelect
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    options={commonMasterDataStore.districtDropdown.data.map((item) => {
                      return {
                        label: item.value,
                        value: item.key,
                      };
                    })}
                  />
                </SearchItem>
                {/* mã phường xã */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mã phường xã' }]}
                  name={'wardCode'}
                  label={t('masterdata.ward.ward-code')}
                >
                  <CustomInput />
                </SearchItem>
                {/* loại phường xã */}
                <SearchItem name={'appellation'} label={t('masterdata.ward.ward-type')}>
                  <CustomSelect options={TypeCommonWard} />
                </SearchItem>
                {/* tên phường xã */}
                <SearchItem name={'wardName'} label={t('masterdata.ward.ward-name')}>
                  <CustomInput />
                </SearchItem>
                {/* Thứ tự hiển thị */}
                <SearchItem name={'orderIndex'} label={t('masterdata.ward.order-index')}>
                  <InputNumber className="!rounded-none !w-full" />
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddWard;
