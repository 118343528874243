import { message } from 'antd';
import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { IMenuServices, MenuModel } from 'services/RawData/menu_services';
import { IFunctionServices } from 'services/RawData/function_services';

export type FunctionStore = {
    searchFunctionRes: DataResponse<Array<MenuModel>>
    functionDetail: DataResponse<MenuModel | undefined>
};

export interface IFunctionPresenter {
    createStore(): FunctionStore;
    // Search danh mục
    searchFunction(store: FunctionStore, data: object): Promise<void>;
    getFunctionById(store: FunctionStore, id: string): Promise<void>
    createFunction(data: object): Promise<boolean>
    updateFunction(data: object): Promise<boolean>
    deleteFunction(id: string): Promise<boolean>
}

export function createFunctionPresenter({
    functionServices,
}: {
    functionServices: IFunctionServices;
}): IFunctionPresenter {
    return {
        createStore: () =>
            observable({
                searchFunctionRes: {
                    data: [],
                    paging: {
                        totalCount: 0,
                        totalPage: 0,
                        pageSize: 0,
                    },
                    message: '',
                    isSuccess: false,
                },
                functionDetail: {
                    data: undefined,
                    message: '',
                    isSuccess: false,
                },
            }),
        // Search district
        searchFunction: async (store, data) => {
            try {
                const result = await functionServices.searchFunction(data);
                runInAction(() => {
                    store.searchFunctionRes.data = result.data;
                    store.searchFunctionRes.isSuccess = result.isSuccess;
                    store.searchFunctionRes.message = result.message;
                    store.searchFunctionRes.paging = result.paging;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getFunctionById: async (store, id) => {
            try {
                const result = await functionServices.getFunctionById(id);
                runInAction(() => {
                    store.functionDetail = result;
                });
            } catch (error) {
                console.log(error);
            }
        },

        createFunction: async (data) => {
            try {
                const result = await functionServices.createFunction(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        updateFunction: async (data) => {
            try {
                const result = await functionServices.updateFunction(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        deleteFunction: async (data) => {
            try {
                const result = await functionServices.deleteFunction(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
    };
}
