import { get, post } from 'utils/api';

import { DataResponse } from 'services/response';
import { MASTER_DATA_API_URL } from 'utils/constants';

export type Category = Partial<{
  stt: number;
  categoryId: string;
  categoryName: string;
  categoryCode: string;
  actived: boolean;
  key: string;
  value: string;
}>;

export interface ICategoryServices {
  // Search list
  searchListCategory(data: object): Promise<DataResponse<Category[]>>;
  // Dropdown category
  dropdownCategory(productTypeName: string): Promise<DataResponse<Category[]>>;
}
export function createCategoryServices(): ICategoryServices {
  return {
    // Search list
    searchListCategory: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/Category/list`, data)).data;
    },
    // Dropdown category
    dropdownCategory: async (productTypeName) => {
      return (
        await get(MASTER_DATA_API_URL, `MasterData/Category/dropdown-category?ProductTypeName=${productTypeName}`)
      ).data;
    },
  };
}
