import { IWorkServices, WorkModel } from 'services/RawData/work_services';
import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';

export type workStore = {
  listWork: DataResponse<Array<WorkModel>>;
  detailWork: DataResponse<WorkModel | undefined>;
  crudResponse: DataResponse<any>;
};

export interface IWorkPresenter {
  createStore(): workStore;
  // Search list province
  searchListWork(store: workStore, data: object): Promise<void>;
  // create new
  createWork(store: workStore, data: object): Promise<void>;
  // update new
  updateWork(store: workStore, data: object): Promise<void>;
  // delete
  deleteWork(store: workStore, id: string): Promise<void>;
  // get data by id
  getWorkById(store: workStore, id: string): Promise<void>;
}

export function createWorkPresenter({ workServices }: { workServices: IWorkServices }): IWorkPresenter {
  return {
    createStore: () =>
      observable({
        searchWorkResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        detailWork: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        listWork: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        crudResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
      }),
    // Search list
    searchListWork: async (store, data) => {
      try {
        const result = await workServices.searchListWork(data);
        runInAction(() => {
          store.listWork.data = result.data;
          store.listWork.isSuccess = result.isSuccess;
          store.listWork.message = result.message;
          store.listWork.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // create new
    createWork: async (store, data) => {
      try {
        const result = await workServices.createWork(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // update new
    updateWork: async (store, data) => {
      try {
        const result = await workServices.updateWork(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // delete
    deleteWork: async (store, id) => {
      try {
        const result = await workServices.deleteWork(id);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get data by id
    getWorkById: async (store, id) => {
      try {
        const result = await workServices.getDetailById(id);
        runInAction(() => {
          store.detailWork.data = result.data;
          store.detailWork.isSuccess = result.isSuccess;
          store.detailWork.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
