import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ThemeConfig from './changeTheme';
import {

  uiPresenter,
  uiStore,
} from 'stores/root_store';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import visionary from "assets/images/ajinomoto-bg.jpg"
//

export function createDashboard() {
  return observer(() => {
    document.title = 'Trang chủ - iCRM System';

    //=====================================CHUYỂN NGÔN NGỮ=====================================//
    const [searchParams] = useSearchParams();



    useEffect(() => {
      uiPresenter.resetSubmenuOpen(uiStore)
    }, []);

    const antIcon = <LoadingOutlined style={{ fontSize: 45, color: 'black' }} spin />;

    return searchParams.get('lang') ? (
      <div className="fixed inset-0 z-50 bg-white h-screen w-full flex items-center justify-center">
        <Spin indicator={antIcon} />
      </div>
    ) : (
      <div className="flex flex-col gap-default absolute inset-0 h-full">
        <div className='w-full ' style={{
          backgroundImage: `url(${visionary}),linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6))`,
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          backgroundBlendMode: 'color-dodge',
          backgroundRepeat: 'no-repeat',
          height: '100%',
          width: '100%'
        }}></div>
        {/* Thay đổi theme */}
        <ThemeConfig />



      </div>
    );
  });
}
