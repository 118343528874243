/* eslint-disable react-hooks/exhaustive-deps */

import * as Icon from 'base/ui/components/Icons';

import { Form, Input, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { accountPresenter, accountStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CreateUpdateAccountForm from '../CreateUpdate/CreateUpdateForm'
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import { checkPermissionWithPageUrl } from 'utils/permissionHelper';
import { getPlantCode } from 'utils/localStorageHelper';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const createAddVendorAccount = () => {
    return observer(() => {
        const { t } = useTranslation();
        // Get data params from url
        const { id: idParams } = useParams();
        const [searchParams] = useSearchParams();
        // Check id để đặt tên trang
        let pageTitle = t('page-title.create');

        const navigate = useNavigate();
        const checkPermission = checkPermissionWithPageUrl('/MasterData/Vendor')
        if (!checkPermission.includes("CREATE")) {
            navigate(-1)
        }


        const [form] = Form.useForm();
        // Loading
        const [loading, setLoading] = useState(false);

        // =============================================================================================================
        //==========================FUNCTION===================//
        // Call api get detail in store

        // Handle create
        const onFinish = async (values: any) => {
            setLoading(true);
            if (idParams) {
                await accountPresenter.addAccount(accountStore, {
                    ...values,
                    listRoleId: undefined,
                    plantCode: getPlantCode()
                });

                if (accountStore.crudRes.isSuccess) {
                    message.success(accountStore.crudRes.message);
                    // Redirect to search page routing (Button Save)
                    navigate('/Permission/Account');
                } else {
                    message.error(accountStore.crudRes.message);
                }
                form.resetFields();
            }
            setLoading(false);
        };

        const initialValues = {
            actived: true,
            isSupplier: true,
            employeeName: searchParams.get('VendorName')
        };

        useEffect(() => {
            form.setFieldValue('vendorNumber', idParams)
        }, []);

        return (
            <>
                <div className="flex flex-col h-full">
                    <CustomBreadcrumb
                        pagetitle={[
                            {
                                title: t('permission.account.title'),
                                link: '/Permission/Account',
                            },
                            {
                                title: pageTitle,
                                link: idParams ? `/Permission/Account/${idParams}` : '/Permission/Account/create',
                            },
                        ]}
                        extra={
                            <>
                                <CustomButton
                                    icon={<Icon.Download className="!fill-white" />}
                                    type='primary'
                                    disabled={loading}
                                    loading={loading}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                >
                                    {t('btn.save')}
                                </CustomButton>
                            </>
                        }
                    />

                    {idParams && <BoxContainer fullHeight>
                        <Spin spinning={loading}>
                            <SearchForm onFinish={onFinish}
                                layout='horizontal'
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 13 }}
                                autoComplete='off'
                                initialValues={initialValues} form={form}>
                                <SearchItem hidden name={'vendorNumber'}>
                                    <Input />
                                </SearchItem>
                                <CreateUpdateAccountForm
                                    form={form}
                                    idParams={idParams}
                                    isCreateVendor={true}
                                />

                            </SearchForm>
                        </Spin>
                    </BoxContainer>}
                </div>
            </>
        );
    });
};

export default createAddVendorAccount;
