import { BASE_API_URL, MASTER_DATA_API_URL } from 'utils/constants';
import { get } from '../../utils/api';
import { DataResponse } from '../response';
import { getUserSession } from 'utils/localStorageHelper';

//Interface của common service
export interface CommonModel {
  key: string;
  value: string;
  name?: string;
  code?: string;
  // Company list
  companyId?: string;
  // Store list
  storeId?: string;
  orderBy?: string;
  thenBy?: string;
  custom1?: any;
  custom2?: any;
  key2?: any;
  keyId?: string;
}

export interface BaseModel {
  key?: string;
  label: string;
  value?: string;
  data?: boolean | string;
  unit?: string;
  type?: string;
  code?: string;

  roleNames?: Array<string>;
  roleCodes?: Array<string>;
  fromDate?: string;
  toDate?: string;
  extension?: boolean;
}

//Type của common service
export type CommonDataResponse = {
  data: CommonModel[];
  message: string;
  isSuccess: boolean;
};

// model dropdown chi nhánh
export type Company = {
  companyId: string;
  companyName: string;
  storeListResponses: storeList[];
};

export type storeList = {
  storeId: string;
  storeName: string;
  saleOrgCode?: string | null;
};
export type StoreByUsername = {
  key: string;
  value: string;
  orderBy: string;
  thenBy: string;
};

export type DataGetStoreByUsername = {
  username: string;
  companyId: string;
};

export type ParamsWorkType = Partial<{
  catalogCode: string;
  workCode: string;
}>;

export type RoleByDepartment = {
  departmentId: string;
  departmentName: string;
  roleItemResponses: {
    rolesId: string;
    rolesName: string;
  }[]
}

// ============ Khai báo phương thức =====================
export interface ICommonMasterDataServices {
  // Lấy data danh sách common date
  getListSelectCommonDate(): Promise<CommonDataResponse>;

  // =================================================================================================
  // ==========================================DỮ LIỆU NỀN============================================
  // =================================================================================================
  // Lấy data danh sách công ty
  getListSaleOrg(): Promise<CommonDataResponse>;
  // Lấy data danh sách store
  getListSelectCommonStore(accountId?: string): Promise<CommonDataResponse>;
  getDropDownCategoryType(keyword?: string): Promise<CommonDataResponse>;
  // Lấy data danh sách store by company id
  getDropDownStore(companyId?: string): Promise<CommonDataResponse>;
  // lấy data danh sách store phòng ban
  getDropdownDepartment(): Promise<CommonDataResponse>;
  // lấy data dropdown chi nhánh
  getDropDownStore2(accountId?: string): Promise<DataResponse<Company[]>>;
  getDropDownStoreByUser(params: DataGetStoreByUsername): Promise<DataResponse<StoreByUsername[]>>;
  // lấy data tỉnh thành phố
  getDropDownProvince(data?: { keyword?: string }): Promise<DataResponse<CommonModel[]>>;
  // lấy data quận huyện
  getDropDownDistrict(provinceId: string, keyword?: string): Promise<DataResponse<CommonModel[]>>;
  // lấy data phân loại vật tư
  getDropDownParentCategory(keyword?: string, takeNumber?: number): Promise<DataResponse<CommonModel[]>>;
  // lấy data nhóm vật tư
  getDropDownCategory(keyword?: string, takeNumber?: number): Promise<DataResponse<CommonModel[]>>;
  // lấy data dropdown màu
  getDropDownColor(keyword?: string, takeNumber?: number): Promise<DataResponse<CommonModel[]>>;
  // lấy data dropdown warranty (loại thời gian warranty)
  getDropDownWarranty(keyword?: string, takeNumber?: number): Promise<DataResponse<CommonModel[]>>;
  // GET data common theo key:
  // Dropdown Khu vực : SaleOffice
  getCommonDataByKey(key: string): Promise<CommonDataResponse>;
  // lấy danh Tỉnh/Thành phố
  getListProvince(area?: string): Promise<CommonDataResponse>;

  getDropdownCatalogByWorkType(params: ParamsWorkType): Promise<DataResponse<CommonModel[]>>;
  // Get dropdown list template tickets
  getDropdownTemplateTickets(): Promise<DataResponse<CommonModel[]>>;
  // danh sách công ty
  getListCompany(): Promise<DataResponse<CommonModel[]>>;
  // Danh sách công ty (companyId)
  getDropdownCompanyV2(): Promise<CommonDataResponse>;
  // danh sách nhóm người dùng (Role)
  getDropdownRole(keyword?: string): Promise<DataResponse<CommonModel[]>>;
  // dropdown nhóm người dùng theo từng phòng ban
  getDropdownRoleByDepartment(option?: { keyword?: string, takeNumber?: number }): Promise<DataResponse<RoleByDepartment[]>>
}
// Hàm khởi tạo các phương thức của service
export function createCommonMasterDataServices(): ICommonMasterDataServices {
  return {
    // Hàm lấy data danh sách công ty
    getListSaleOrg: async () => {
      return (await get(BASE_API_URL, `common/list-company`)).data;
    },
    // Hàm lấy danh sách common date
    getListSelectCommonDate: async () => {
      return (await get(BASE_API_URL, `Common/select-commondate`)).data;
    },
    // Lấy data danh sách store
    getListSelectCommonStore: async (accountId) => {
      return (await get(BASE_API_URL, `Common/select-commonstore?${accountId ? `accountId=${accountId}` : ``}`)).data;
      // return (await get(BASE_API_URL, `Common/select-commonstore`)).data;
    },

    // Lấy data danh sách category type loại danh mục
    getDropDownCategoryType: async (keyword) => {
      return (
        await get(
          MASTER_DATA_API_URL,
          `common/get-dropdown-catalog-type?${keyword ? `keyword=${keyword}` : ``}`,
        )
      ).data;
    },
    // Lấy data danh sách chi nhánh (store)
    getDropDownStore: async (companyId) => {
      return (
        await get(BASE_API_URL, `Common/select-commonstore-by-companyId?${companyId ? `companyId=${companyId}` : ``}`)
      ).data;
    },
    // lấy data dropdown chi nhánh
    getDropDownStore2: async (accountId?: string) => {
      return (
        await get(MASTER_DATA_API_URL, `common/get-list-store-2`, {
          AccountId: getUserSession().accountId,
        })
      ).data;
    },
    getDropDownStoreByUser: async (params) => {
      return (
        await get(MASTER_DATA_API_URL, `MasterData/Common/get-list-store-by-username`, {
          username: params.username,
          companyId: params.companyId,
        })
      ).data;
    },
    // lấy data dropdown phòng ban
    getDropdownDepartment: async () => {
      return (await get(MASTER_DATA_API_URL, `common/get-dropdown-department`)).data;
    },
    // lấy data dropdown tỉnh thành phố
    getDropDownProvince: async (data) => {
      return (await get(MASTER_DATA_API_URL, `common/get-dropdown-province`, data)).data;
    },
    // lấy data dropdown quận huyện
    getDropDownDistrict: async (provinceId, keyword) => {
      const params = { provinceId, keyword }
      return (
        await get(
          MASTER_DATA_API_URL,
          `common/get-dropdown-district`, params,
        )
      ).data;
    },

    // lấy data phân loại vật tư
    getDropDownParentCategory: async (keyword, takeNumber) => {
      return (
        await get(
          MASTER_DATA_API_URL,
          `MasterData/Common/get-dropdown-parent-category?${keyword ? `keyword=${keyword}` : ''}`,
        )
      ).data;
    },
    // lấy data nhóm vật tư
    getDropDownCategory: async (keyword, takeNumber) => {
      return (
        await get(MASTER_DATA_API_URL, `MasterData/Common/get-dropdown-category?${keyword ? `keyword=${keyword}` : ''}`)
      ).data;
    },
    // lấy data dropdown màu
    getDropDownColor: async (keyword, takeNumber) => {
      return (
        await get(MASTER_DATA_API_URL, `MasterData/Common/get-dropdown-color?${keyword ? `keyword=${keyword}` : ''}`)
      ).data;
    },
    // lấy data dropdown warranty (loại thời gian warranty)
    getDropDownWarranty: async (keyword, takeNumber) => {
      return (
        await get(MASTER_DATA_API_URL, `MasterData/Common/get-dropdown-warranty?${keyword ? `keyword=${keyword}` : ''}`)
      ).data;
    },
    // GET data common theo key:
    // Dropdown Khu vực : SaleOffice
    getCommonDataByKey: async (key) => {
      return (await get(BASE_API_URL, `common/get-ctl-by-type${key ? `/${key}` : ''}`)).data;
    },
    // lấy danh Tỉnh/Thành phố
    getListProvince: async (area) => {
      return (await get(BASE_API_URL, `Common/get-list-province${area ? `?area=${area}` : ''}`)).data;
    },
    // Lấy mã công việc theo loại công việc
    getDropdownCatalogByWorkType: async (params) => {
      return (
        await get(MASTER_DATA_API_URL, 'MasterData/Common/get-by-catalogcode', {
          catalogCode: params.catalogCode,
          workCode: params.workCode,
        })
      ).data;
    },
    // Get dropdown list template tickets
    getDropdownTemplateTickets: async () => {
      return (await get(MASTER_DATA_API_URL, 'MasterData/Common/list-template-tickets')).data;
    },
    // danh sách công ty
    getListCompany: async () => {
      return (await get(MASTER_DATA_API_URL, 'common/list-company')).data;
    },
    // Danh sách công ty (companyId)
    getDropdownCompanyV2: async () => {
      return (await get(MASTER_DATA_API_URL, 'common/list-company-v2')).data;
    },
    // danh sách nhóm người dùng (Role)
    getDropdownRole: async (keyword) => {
      return (await get(MASTER_DATA_API_URL, 'common/get-dropdown-role', { keyword })).data;
    },
    // dropdown nhóm người dùng theo từng phòng ban
    getDropdownRoleByDepartment: async (option) => {
      return (await get(MASTER_DATA_API_URL, 'common/get-dropdown-role-by-department', option)).data;
    }
  };
}
