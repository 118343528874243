import { message } from 'antd';
import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { IMenuServices, MenuModel } from 'services/RawData/menu_services';

export type MenuStore = {
    searchMenuRes: DataResponse<Array<MenuModel>>
    menuDetail: DataResponse<MenuModel | undefined>
};

export interface IMenuPresenter {
    createStore(): MenuStore;
    // Search danh mục
    getListMenu(store: MenuStore, data: object): Promise<void>;
    getMenuById(store: MenuStore, id: string): Promise<void>
    createMenu(data: object): Promise<boolean>
    updateMenu(data: object): Promise<boolean>
    deleteMenu(id: string): Promise<boolean>
}

export function createMenuPresenter({
    menuServices,
}: {
    menuServices: IMenuServices;
}): IMenuPresenter {
    return {
        createStore: () =>
            observable({
                searchMenuRes: {
                    data: [],
                    paging: {
                        totalCount: 0,
                        totalPage: 0,
                        pageSize: 0,
                    },
                    message: '',
                    isSuccess: false,
                },
                menuDetail: {
                    data: undefined,
                    message: '',
                    isSuccess: false,
                },
            }),
        // Search district
        getListMenu: async (store, data) => {
            try {
                const result = await menuServices.getListMenu(data);
                runInAction(() => {
                    store.searchMenuRes.data = result.data;
                    store.searchMenuRes.isSuccess = result.isSuccess;
                    store.searchMenuRes.message = result.message;
                    store.searchMenuRes.paging = result.paging;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getMenuById: async (store, id) => {
            try {
                const result = await menuServices.getMenuById(id);
                runInAction(() => {
                    store.menuDetail = result;
                });
            } catch (error) {
                console.log(error);
            }
        },

        createMenu: async (data) => {
            try {
                const result = await menuServices.createMenu(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        updateMenu: async (data) => {
            try {
                const result = await menuServices.updateMenu(data);
                if (result.isSuccess) {
                    message.success(result.message)
                } else {
                    message.error(result.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
        deleteMenu: async (id) => {
            try {
                const result = await menuServices.deleteMenu(id);
                if (result?.isSuccess) {
                    message.success(result?.message)
                } else {
                    message.error(result?.message)
                }
                return result.isSuccess
            } catch (error) {
                console.log(error);
                return false
            }
        },
    };
}
