import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const functionRoutes = ({
    Search,
    Create,
    Update,

}: {
    Search: React.ComponentType;
    Create: React.ComponentType;
    Update: React.ComponentType;
}) => (
    <Routes>
        <Route path={''} element={<Search />} />
        <Route path={ROUTES.PERMISSION.FUNCTION.CREATE} element={<Create />} />
        <Route path={ROUTES.PERMISSION.FUNCTION.EDIT} element={<Update />} />
    </Routes>
);
