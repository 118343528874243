import { DataResponse } from 'services/response';
import { deleted, get, post, put } from 'utils/api';
import { MASTER_DATA_API_URL } from 'utils/constants';

export interface ReportTemplate {
  reportTemplateId: string;
  reportName: string;
  reportTemplateName: string;
  isDefault: boolean;
  templateString: string;
  description?: string;
  resizable: string;
}

export interface IReportSavedServices {
  saveReportTemplate(data: object): Promise<DataResponse<null>>;
  updateReportTemplate(data: object): Promise<DataResponse<null>>;
  getListReportTemplate(reportName: string): Promise<DataResponse<ReportTemplate[]>>;
  deleteReportTemplate(reportTemplateId: string): Promise<DataResponse<null>>;
}

export function createReportSavedServices(): IReportSavedServices {
  return {
    saveReportTemplate: async (data: object) => {
      return (await post(MASTER_DATA_API_URL, `reporttemplate/save-report-template`, data)).data;
    },
    updateReportTemplate: async (data: object) => {
      return (await post(MASTER_DATA_API_URL, `reporttemplate/update-report-template`, data)).data;
    },
    getListReportTemplate: async (reportName: string) => {
      return (await get(MASTER_DATA_API_URL, `reporttemplate/get-list-report-template?reportName=${reportName}`)).data;
    },
    deleteReportTemplate: async (reportName: string) => {
      return (await deleted(MASTER_DATA_API_URL, `reporttemplate/delete-report-template?ReportTemplateId=${reportName}`, {})).data;
    },
  };
}
