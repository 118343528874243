import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const purchaseOrderRoutes = ({
    ListPO, VehicleRegistration, EditVehicleRegistration, AddPurchaseOrder, EditPurchaseOrder, CopyPurchaseOrder
}: {
    ListPO: React.ComponentType;
    VehicleRegistration: React.ComponentType;
    EditVehicleRegistration: React.ComponentType;
    AddPurchaseOrder: React.ComponentType;
    CopyPurchaseOrder: React.ComponentType;
    EditPurchaseOrder: React.ComponentType;
}) => (
    <Routes>
        <Route path={ROUTES.PURCHASE_ORDER.LIST_PO.BASE} element={<ListPO />} />
        <Route path={ROUTES.PURCHASE_ORDER.LIST_PO.CREATE_VOTE.BASE} element={<VehicleRegistration />} />
        <Route path={ROUTES.PURCHASE_ORDER.LIST_PO.EDIT_VOTE.BASE} element={<EditVehicleRegistration />} />
        {/* tạo kế hoạch nhận hàng  */}
        <Route path={ROUTES.PURCHASE_ORDER.ADD_PO.BASE} element={<AddPurchaseOrder />} />
        <Route path={ROUTES.PURCHASE_ORDER.EDIT_PO.BASE} element={<EditPurchaseOrder />} />
        <Route path={ROUTES.PURCHASE_ORDER.COPY_PO.BASE} element={<CopyPurchaseOrder />} />
    </Routes>
);
