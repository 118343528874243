import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const PermissionRoutes = ({
  RolesPage,
  AccountPage,
  ConfigAccountPage,
  // ConfigAccountVOIPPage,
  AccessPage,
  AccessMobilePage,
  Page,
  Menu,
  MobileScreen,
  FunctionPage
}: {
  RolesPage: React.ComponentType;
  AccountPage: React.ComponentType;
  ConfigAccountPage: React.ComponentType;
  // ConfigAccountVOIPPage: React.ComponentType;
  AccessPage: React.ComponentType;
  AccessMobilePage: React.ComponentType;
  Page: React.ComponentType;
  Menu: React.ComponentType;
  MobileScreen: React.ComponentType;
  // Quản lý thao tác
  FunctionPage: React.ComponentType;
}) => (
  <Routes>
    <Route path={ROUTES.PERMISSION.ROLES.BASE} element={<RolesPage />} />
    <Route path={ROUTES.PERMISSION.ACCOUNT.BASE} element={<AccountPage />} />
    <Route path={ROUTES.PERMISSION.CONFIG_ACCOUNT.BASE} element={<ConfigAccountPage />} />
    {/* <Route path={ROUTES.PERMISSION.CONFIG_ACCOUNT_VOIP.BASE} element={<ConfigAccountVOIPPage />} /> */}
    <Route path={ROUTES.PERMISSION.ACCESSWEB.BASE} element={<AccessPage />} />
    <Route path={ROUTES.PERMISSION.ACCESSMOBILE.BASE} element={<AccessMobilePage />} />
    <Route path={ROUTES.PERMISSION.PAGE.BASE} element={<Page />} />
    <Route path={ROUTES.PERMISSION.MENU.BASE} element={<Menu />} />
    <Route path={ROUTES.PERMISSION.MOBILE_SCREEN.BASE} element={<MobileScreen />} />
    <Route path={ROUTES.PERMISSION.FUNCTION.BASE} element={<FunctionPage />} />
  </Routes>
);
