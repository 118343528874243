
import * as Icon from 'base/ui/components/Icons';

import { Form, Input, Modal, Tooltip } from 'antd';
import { DataSearchFunction } from '../model';
import { PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { functionPresenter, functionStore, pagePresenter, pageStore, userStore } from 'stores/root_store';
import { useEffect, useRef, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { HiSearch } from 'react-icons/hi';
import { ActionType, ProColumns } from '@ant-design/pro-components';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { FunctionModel } from 'services/RawData/function_services';
import { cloneDeep } from 'lodash';

const { confirm } = Modal;

const functionPage = () => {
    return observer(() => {
        usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

        const { t } = useTranslation();

        const pagetitle = "Thao tác";
        document.title = pagetitle + ' - ' + SYSTEM_TITLE;
        const [form] = Form.useForm();
        // state ===============================================================================
        const [openDrawer, setOpenDrawer] = useState(false);

        // Columns table title
        const defaultColumn: ProColumns<FunctionModel>[] = [
            // stt
            {
                title: t('permission.account.stt'),
                dataIndex: 'stt',
                key: 'stt',
                width: 60,
                align: 'center',
                fixed: 'left',
            },
            // Mã thao tác
            {
                title: "Mã thao tác",
                dataIndex: 'functionId',
                key: 'functionId',
                width: 350,
            },
            // Tên thao tác
            {
                title: "Tên thao tác",
                dataIndex: 'functionName',
                key: 'functionName',
                width: 350,
            },

            // Chức năng
            {
                title: t('permission.account.actions'),
                dataIndex: '',
                key: 'function',
                width: 150,
                className: '!rounded-none !py-1',
                fixed: 'right',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div className="w-full flex items-center justify-center gap-x-1">
                            {/* {userStore.userPermission?.includes('EDIT') ? ( */}
                            <Tooltip title={t('btn.edit')}>
                                <Link to={`${record.functionId}`}>
                                    <CustomButtonIcon color="#007BF1">
                                        <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                                    </CustomButtonIcon>
                                </Link>
                            </Tooltip>
                            {/* ) : null} */}
                            {/* {userStore.userPermission?.includes('DELETE') ? ( */}
                            <Tooltip title={t('btn.delete')}>
                                <CustomButtonIcon
                                    color="#FC5C5E"
                                    onClick={() => {
                                        showDeleteConfirm(record);
                                    }}
                                >
                                    <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                                </CustomButtonIcon>
                            </Tooltip>
                            {/* ) : null} */}

                        </div>
                    );
                },
            },
        ];
        // Custom default columns table with resizeable
        const [columns, setColumn] = useState<ProColumns<FunctionModel>[]>(defaultColumn);
        // Loading all page
        const [loading, setLoading] = useState(false);

        // Data search
        const [dataSearch, setDataSearch] = useState<DataSearchFunction>({
            paging: {
                pageIndex: 1,
                pageSize: PAGE_SIZE_DEFAULT,
                orderBy: '',
                orderByDesc: '',
            },
        });
        const [dataTable, setDataTable] = useState<FunctionModel[]>([]);
        const tableRef = useRef<ActionType>();

        // function open hide drawer ===========================================================
        const showDrawer = () => {
            setOpenDrawer(true);
        };

        const onCloseDrawer = () => {
            setOpenDrawer(false);
        };

        // Call api search Data
        const getDataSearch = async (dataSearch: DataSearchFunction) => {
            await functionPresenter.searchFunction(functionStore, dataSearch);
            if (functionStore.searchFunctionRes.isSuccess) {
                const cloneData = cloneDeep(functionStore.searchFunctionRes.data)
                setDataTable(cloneData);
                return cloneData
            }
            return []
        };

        // Handle click button search
        const handleSearch = async (values: any) => {
            setLoading(true);
            const tempDataSearch = {
                paging: {
                    ...dataSearch.paging,
                    pageIndex: 1,
                },
                ...values,
            };
            // set pageIndex 1 when search form
            setDataSearch(tempDataSearch);
            tableRef.current?.reload()
            setLoading(false);
            onCloseDrawer();
        };

        // scroll to top
        useEffect(() => {
            window.scrollTo(0, 0);
            getDropDownMenu()
        }, []);

        // initialValues of form search
        const initialValues = {};

        // Call api delete
        const handleClickDelete = async (functionId: string) => {
            setLoading(true);
            if (functionId) {
                const result = await functionPresenter.deleteFunction(functionId);
                if (result) {
                    tableRef.current?.reload()
                }
            }
            setLoading(false);
        };

        const getDropDownMenu = async () => {
            if (!pageStore.dropdownMenu.length) {
                await pagePresenter.getDropdownMenu(pageStore)
            }
        }

        // Ui modal confirm delete store
        const showDeleteConfirm = (item: FunctionModel) => {
            confirm({
                title: `${t('btn.delete')} "${item.functionName}"`,
                icon: <ExclamationCircleFilled />,
                content: `${t('message.question-delete')} "${item.functionName}"`,
                okText: t('btn.delete'),
                okType: 'danger',
                cancelText: t('btn.cancel'),
                className: 'custom__modalconfirm--delete',
                okButtonProps: {
                    loading: loading
                },
                onOk() {
                    handleClickDelete(item.functionId || '');
                },
                onCancel() { },
            });
        };

        return (
            <div className="w-full">
                {/* ================ Breadcrumb =============== */}
                <CustomBreadcrumb
                    pagetitle={[{ title: pagetitle, link: '/Permission/Account' }]}
                    extra={
                        <>
                            <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                                <Icon.Filter className="fill-primary" />
                            </CustomButton>
                            {userStore.userPermission?.includes('CREATE') ? (
                                <Link to="create">
                                    <CustomButton
                                        type="primary"
                                        icon={<Icon.Plus className="!fill-white" />}
                                    >
                                        {t('btn.add')}
                                    </CustomButton>
                                </Link>
                            ) : null}
                        </>
                    }
                />
                {/* Table */}
                <BoxContainer>
                    <CustomProTable<FunctionModel>
                        columns={columns}
                        handleResize={() => { }}
                        rowKey="stt"
                        size="small"
                        bordered
                        search={false}
                        actionRef={tableRef}
                        request={async (params = {}, sort, filter) => {
                            const data = await getDataSearch({
                                ...dataSearch,
                                paging: {
                                    ...dataSearch.paging,
                                    orderBy:
                                        Object.values(sort)[0] === "ascend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                    orderByDesc:
                                        Object.values(sort)[0] === "descend"
                                            ? `${Object.keys(sort)[0].split(",").pop()}`
                                            : undefined,
                                },
                            });
                            if (Object.values(sort).length) {

                                setDataSearch({
                                    ...dataSearch,
                                    paging: {
                                        ...dataSearch.paging,
                                        orderBy:
                                            Object.values(sort)[0] === "ascend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                        orderByDesc:
                                            Object.values(sort)[0] === "descend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                    },
                                });
                            }
                            return {
                                data: data,
                                success: functionStore.searchFunctionRes?.isSuccess
                            };

                        }}
                        dataSource={dataTable}
                        scroll={{ y: 500 }}
                        pagination={{
                            ...tablePagination(dataSearch, setDataSearch),
                            total: functionStore.searchFunctionRes?.paging?.recordsTotal,
                        }}
                        options={false}
                    />
                </BoxContainer>
                {/* Form search */}
                <SearchBox
                    className="lg:!w-[35%]"
                    footer={[
                        <div key="footer" className="flex justify-end">
                            <CustomButton
                                type="primary"
                                icon={<HiSearch />}
                                htmlType="submit"
                                onClick={() => {
                                    form.submit();
                                    onCloseDrawer();
                                }}
                            >
                                {t('btn.search')}
                            </CustomButton>
                        </div>,
                    ]}
                    onClose={onCloseDrawer}
                    open={openDrawer}
                >
                    <SearchForm column="col-1" initialValues={initialValues} form={form} onFinish={handleSearch}>
                        <button hidden type='submit'></button>
                        {/* Tên thao tác */}
                        <SearchItem label={"Tên thao tác"} name={'functionName'}>
                            <Input />
                        </SearchItem>
                    </SearchForm>
                </SearchBox>
            </div>
        );
    });
};

export default functionPage;
