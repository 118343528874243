import { ProTable, ProTableProps } from "@ant-design/pro-components";
import type { ParamsType } from "@ant-design/pro-provider";
import { ColumnType, TablePaginationConfig } from "antd/es/table";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import "./styles.css";
import { uiStore } from "stores/root_store";
import CustomSelect from "../CustomSelect";
import { Select } from "antd";
import { Translation } from "react-i18next";
import { PagingModel, SearchModel } from "pages/model";
import { formatNumber } from "utils/formatHelper";
import { find } from "lodash";
import { PAGE_SIZE_OPTION } from "utils/constants";

export const customTableClassName = " [&_table>thead>tr>th]:!text-[#262626] [&_table>thead>tr>th]:!bg-gradient-to-r [&_table>thead>tr>th]:from-primary/10 [&_table>thead>tr>th]:to-primary/10  [&_table>thead>tr]:!h-[40px]" +
  " [&_.ant-pro-table-column-setting-list-item-title]:clamp-1 [&_.ant-pro-card-body]:!p-0 !z-auto " +
  " [&_.ant-table-header]:!rounded-none [&_.ant-table-cell-scrollbar]:!rounded-none [&_.ant-table-thead>tr>th]:!rounded-none" +
  " [&_.ant-pro-table-list-toolbar-container]:!pt-0 [&_.ant-pro-table-list-toolbar-container]:!pb-[10px] [&_.ant-pagination:first-child]:!mt-0" +
  " [&_ul_.ant-pagination]:!relative [&_.ant-pagination]:!flex [&_.ant-pagination]:!items-center [&_.ant-pagination>li]:!mx-1" +
  " [&_.ant-pagination-item]:!border-[#D9D9D9] [&_.ant-pagination-item.ant-pagination-item-active]:!border-primary [&_.ant-pagination-item]:!rounded-sm" +
  " [&_.ant-pagination-item]:!min-w-[32px] [&_.ant-pagination-item]:!h-8 [&_.ant-pagination-item]:!flex" +
  " [&_.ant-pagination-item]:!items-center [&_.ant-pagination-item]:!justify-center [&_.ant-pagination-item>a]:!text-[#262626] " +
  " [&_.ant-table-cell-row-hover]:!bg-[#fafafa] [&_.ant-table-cell]:!py-1 [&_.react-resizable-handle]:cursor-col-resize " +
  " [&_.ant-table-row-selected>td]:!bg-white [&_.ant-table-row-selected>td]:!bg-gradient-to-r [&_.ant-table-row-selected>td]:!from-primary/20 [&_.ant-table-row-selected>td]:!to-primary/20 " +
  " [&_tr.ant-table-row-selected:hover>td]:!from-primary/20 [&_tr.ant-table-row-selected:hover>td]:!to-primary/20 [&_.ant-table-thead_.ant-table-cell]:!text-center "

const CustomProTable = <
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = "text"
>(
  props: ProTableProps<DataType, Params, ValueType> & {
    isResizablePresent?: boolean;
    resizableColumnSelect?: [];
    handleResize?: (index: number, id?: number) => void;
  }
) => {
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns =
    props.columns &&
    props.columns.map((col: any, index: any) => {
      Object.assign(col, {
        children: col.children
          ? col.children.map((child: any, id: any) => {
            Object.assign(child, {
              onHeaderCell: (column: ColumnType<DataType>) => ({
                width: column.width,
                onResize: props.handleResize && props.handleResize(index, id),
              }),
            });
            return child;
          })
          : undefined,
        onHeaderCell: (column: ColumnType<DataType>) => ({
          width: column.width,
          onResize: props.handleResize && props.handleResize(index),
        }),
      });
      return col;
    });


  return (
    <ProTable<DataType, Params, ValueType>
      {...props}
      components={props.handleResize ? components : undefined}
      columns={
        // Map từng dòng để lấy gán hàm onHeaderCell và width
        props.isResizablePresent
          ? props.columns?.map((col: any, index: any) => {
            // tìm column trong resizableColumnSelect để lấy width nếu có thì lấy width và gán hàm onHeaderCell
            //  không có thì chỉ gán onHeaderCell
            if (
              find(props.resizableColumnSelect, ['key', col.key])
              // props.resizableColumnSelect?.find((d: any) => d.key === col.key)
            ) {
              return {
                ...col,
                children: col.children
                  ? col.children.map((child: any, id: any) => ({
                    ...child,
                    onHeaderCell: (column: ColumnType<DataType>) => ({
                      width: column.width,
                      onResize: props.handleResize && props.handleResize(index, id),
                    }),
                  }))
                  : undefined,
                onHeaderCell: (column: ColumnType<DataType>) => ({
                  width: column.width,
                  onResize: props.handleResize && props.handleResize(index),
                }),
              };
            } else {
              return {
                ...col,
                onHeaderCell: (column: ColumnType<DataType>) => ({
                  width: column.width,
                  onResize: props.handleResize && props.handleResize(index),
                }),
              };
            }
          })
          : props.columns
      }
      className={
        `${uiStore.theme.name}` + customTableClassName + props.className
      }
      bordered={props.bordered ? props.bordered : false}
      cardBordered={false}
      search={false}
      options={
        props.options !== undefined
          ? props.options
          : {
            setting: {
              listsHeight: 200,

              draggable: true,
            },
            density: false,
            fullScreen: true, // Giữ lại tùy chọn toàn màn hình
            reload: false, // Giữ lại tùy chọn làm mới
          }
      }
      scroll={props.scroll ? props.scroll : { y: "calc(100vh - 310px)", x: "1500px" }}
    />
  );
};


export const tablePagination = (
  dataPaging: SearchModel,
  setDataPaging: (data: SearchModel) => void,
) => {
  return {
    pageSize: dataPaging.paging?.pageSize,
    current: dataPaging.paging?.pageIndex,
    responsive: true,
    pageSizeOptions: [10, 25, 50, 100],
    showSizeChanger: false,
    position: ["topRight"],
    style: { margin: "10px 0" },
    showTotal: (total, range) => (
      <>
        <span className="hidden md:block !absolute left-1">
          <Translation>{(t, { i18n }) => <>{t("message.view")}</>}</Translation>{" "}
          <CustomSelect
            size="middle"
            className="min-w-[72px] !rounded-none"
            value={String(dataPaging.paging?.pageSize)}
            onChange={(value) => {
              // setPageSizes(value);
              setDataPaging({
                ...dataPaging,
                paging: { ...dataPaging.paging, pageSize: value, pageIndex: 1 },
              });
            }}
          >
            {PAGE_SIZE_OPTION.map((pageSize) => (
              <Select.Option key={String(pageSize)} value={pageSize}>
                {formatNumber(pageSize, 0)}
              </Select.Option>
            ))}
          </CustomSelect>{" "}
          <Translation>{(t, { i18n }) => <>{t("message.item")}</>}</Translation>
        </span>

        <span className="hidden md:block">
          <Translation>
            {(t, { i18n }) => <>{t("message.display")}</>}
          </Translation>
          {` ${range[0]}-${range[1]} `}{" "}
          <Translation>{(t, { i18n }) => <>{t("message.of")}</>}</Translation>
          {` ${formatNumber(total, 0)} `}
          <Translation>
            {(t, { i18n }) => <>{t("message.entries")}</>}
          </Translation>
        </span>
      </>
    ),
    onChange: (page, pageSize) => {
      setDataPaging({
        ...dataPaging,
        paging: { ...dataPaging.paging, pageIndex: page, pageSize: pageSize },
      });
    },
  } as TablePaginationConfig;
};

interface ResizableTitleProps {
  onResize: any;
  width: number;
  [x: string]: any;
}

export const ResizableTitle: React.FC<ResizableTitleProps> = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default CustomProTable;
