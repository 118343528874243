import { DataCatalogType, ICatalogTypeServices } from 'services/RawData/catalogType_services';
import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';

export type catalogTypeStore = {
  // Type default response search
  searchCatalogTypeResponse: DataResponse<Array<DataCatalogType>>;
  catalogTypeDetailResponse: DataResponse<DataCatalogType | undefined>;
  crudResponse: DataResponse<null>;
};

export interface ICatalogTypePresenter {
  createStore(): catalogTypeStore;
  // Search catalogType
  searchCatalogType(store: catalogTypeStore, data: object): Promise<void>;
  // Update catalogType
  updateCatalogType(store: catalogTypeStore, data: object): Promise<void>;
  // Create catalogType
  createCatalogType(store: catalogTypeStore, data: object): Promise<void>;
  // Delete catalogType
  deleteCatalogType(store: catalogTypeStore, CatalogTypeCode: string): Promise<void>;
  // get catalogType by catalogTypeCode
  getCatalogTypeByCode(store: catalogTypeStore, catalogTypeCode: string): Promise<void>;
}

export function createCatalogTypePresenter({
  catalogTypeServices,
}: {
  catalogTypeServices: ICatalogTypeServices;
}): ICatalogTypePresenter {
  return {
    createStore: () =>
      observable({
        searchCatalogTypeResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        catalogTypeDetailResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        crudResponse: {
          data: null,
          message: '',
          isSuccess: false,
        },
      }),
    // Search catalogType
    searchCatalogType: async (store, data) => {
      try {
        const result = await catalogTypeServices.searchCatalogType(data);
        runInAction(() => {
          store.searchCatalogTypeResponse.data = result.data;
          store.searchCatalogTypeResponse.isSuccess = result.isSuccess;
          store.searchCatalogTypeResponse.message = result.message;
          store.searchCatalogTypeResponse.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    updateCatalogType: async (store, data) => {
      try {
        const result = await catalogTypeServices.updateCatalogType(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    createCatalogType: async (store, data) => {
      try {
        const result = await catalogTypeServices.createCatalogType(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
          store.crudResponse.developerMessage = result.developerMessage;
        });
      } catch (error) {
        console.log(error);
      }
    },
    deleteCatalogType: async (store, CatalogTypeCode) => {
      try {
        const result = await catalogTypeServices.deleteCatalogType(CatalogTypeCode);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getCatalogTypeByCode: async (store, catalogTypeCode) => {
      try {
        const result = await catalogTypeServices.getCatalogTypeByCode(catalogTypeCode);
        runInAction(() => {
          store.catalogTypeDetailResponse.isSuccess = result.isSuccess;
          store.catalogTypeDetailResponse.data = result.data;
          store.catalogTypeDetailResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
