import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';

export interface DataCatalogType {
  stt: number;
  // mã loại danh mục
  catalogTypeCode: string;
  // Tên loại danh mục
  catalogTypeName: string;
  // trạng thái
  actived: boolean;
}

export interface ICatalogTypeServices {
  // Search catalogType
  searchCatalogType(data: object): Promise<DataResponse<Array<DataCatalogType>>>;
  // Create catalogType
  createCatalogType(data: object): Promise<DataResponse<any>>;
  // Update catalogType
  updateCatalogType(data: object): Promise<DataResponse<any>>;
  // Delete catalogType
  deleteCatalogType(CatalogTypeCode: string): Promise<DataResponse<any>>;
  // get catalogType by code
  getCatalogTypeByCode(catalogTypeCode: string): Promise<DataResponse<any>>;
}
export function createCatalogTypeServices(): ICatalogTypeServices {
  return {
    // Search catalogType
    searchCatalogType: async (data) => {
      return (await post(MASTER_DATA_API_URL, 'catalogType/list', data)).data;
    },
    // Create catalogType
    createCatalogType: async (data) => {
      return (await post(MASTER_DATA_API_URL, `catalogType/create`, data)).data;
    },
    // Update catalogType
    updateCatalogType: async (data) => {
      return (await put(MASTER_DATA_API_URL, `catalogType/update`, data)).data;
    },
    // Delete catalogType
    deleteCatalogType: async (CatalogTypeCode) => {
      return (
        await deleted(
          MASTER_DATA_API_URL,
          `catalogType/delete/${CatalogTypeCode}`,
        )
      ).data;
    },
    // get catalogType by code
    getCatalogTypeByCode: async (catalogTypeCode) => {
      const data = {
        catalogTypeCode: catalogTypeCode,
      };
      return (await get(MASTER_DATA_API_URL, `catalogType/get-by-code`, data)).data;
    },
  };
}
