import * as Icon from 'base/ui/components/Icons';

import { Form, Modal, Tooltip, message } from 'antd';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { departmentPresenter, departmentStore, userStore } from 'stores/root_store';
import { PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { usePermission } from 'hooks/usePermission';
import { observer } from 'mobx-react';
import { SearchModel } from 'pages/model';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { HiSearch } from 'react-icons/hi';
import { DataDepartment } from 'services/RawData/department_services';
import { dropdownStatus } from 'utils/dropdown';
import { removeEmptyValues } from 'utils/transformHelper';
import { DataSearchDepartment } from './model';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';

const { confirm } = Modal;

const createDepartment = () => {
  return observer(() => {
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    // translate en/vn
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const pagetitle = t('page-title.department');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();

    //==========================STATE===================//
    // Loading all page
    const [loading, setLoading] = useState(false);
    // List data company
    const [listDataDepartment, setListDataDepartment] = useState<DataDepartment[]>([]);
    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
      departmentCode: undefined as string | undefined,
      departmentName: undefined as string | undefined,
      actived: undefined as boolean | undefined,
    });

    const [openDrawer, setOpenDrawer] = useState(false);

    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Columns table title
    const defaultColumn: ProColumns<DataDepartment>[] = [
      // STT
      {
        title: t('masterdata.department.stt'),
        dataIndex: 'stt',
        width: 25,
        key: 'stt',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
        align: 'center',
      },
      // Mã bộ phận
      {
        title: t('masterdata.department.department-code'),
        dataIndex: 'departmentCode',
        width: 50,
        key: 'departmentCode',
        className: '!rounded-none !py-1',
        fixed: 'left',
        render: (_, record) => {
          return <Link to={`/MasterData/Department/${record.departmentId}`}>{record.departmentCode}</Link>;
        },
      },
      // Tên bộ phận
      {
        title: t('masterdata.department.department-name'),
        dataIndex: 'departmentName',
        width: 150,
        key: 'departmentName',
        className: '!rounded-none !py-1',
      },
      // // Trung tâm bảo hành
      // {
      //   title: t('masterdata.department.branch'),
      //   dataIndex: 'storeName',
      //   width: 150,
      //   key: 'storeName',
      //
      //   className: '!rounded-none !py-1',
      // },
      // Trạng thái
      {
        title: t('masterdata.department.status'),
        dataIndex: 'actived',
        width: 40,
        key: 'actived',
        className: '!rounded-none !py-1',
        render: (_, record) => {
          return (
            <div>
              {record.actived ? (
                <Tooltip title="Đang sử dụng">
                  <Icon.CheckStatus className="w-4 text-sm text-center mx-auto !fill-[#389E0D]" />
                </Tooltip>
              ) : (
                <Tooltip title="Ngưng sử dụng">
                  <Icon.CloseStatus className="w-4 text-sm text-center mx-auto !fill-[#D93133E5]" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      // Chức năng
      {
        title: t('masterdata.department.actions'),
        dataIndex: 'function',
        width: 50,
        key: 'function',
        className: '!rounded-none !py-1',
        fixed: 'right',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') ? (
                <Tooltip title={t('btn.edit')}>
                  <CustomButton
                    className="!w-6 !h-6 rounded-sm !border-[#4DA8FF] flex gap-1 items-center justify-center !bg-[#C9E8FF] hover:bg-[#95D2FF]"
                    icon={<Icon.PencilEdit className="!fill-[#007BF1]" width="16" height="16" />}
                    onClick={() => navigate(`${record.departmentId}`)}
                  />
                </Tooltip>
              ) : null}
              {userStore.userPermission?.includes('DELETE') ? (
                record.actived ? (
                  <Tooltip title={t('btn.delete')}>
                    <CustomButton
                      className="!w-6 !h-6 rounded-sm !border-[#FF4F51] flex gap-1 items-center justify-center !bg-[#FFE5E6] hover:bg-[#FF8081]"
                      icon={<Icon.Trash className="!fill-[#FC5C5E]" width="16" height="16" />}
                      onClick={() => {
                        showDeleteConfirm(record);
                      }}
                    />
                  </Tooltip>
                ) : (
                  <CustomButtonIcon color="#fff00" className="border-none outline-none cursor-default">
                    <Icon.Trash className="!fill-transparent" width="14" height="14" />
                  </CustomButtonIcon>
                )
              ) : null}
            </div>
          );
        },
      },
    ];

    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<DataDepartment>[]>(defaultColumn);

    // Call api search department
    const getDataSearchDepartment = async (dataSearch: DataSearchDepartment) => {
      await departmentPresenter.searchDepartment(departmentStore, dataSearch);
      if (departmentStore.searchDepartmentResponse.isSuccess) {
        setListDataDepartment(departmentStore.searchDepartmentResponse.data);
      }
    };

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        ...values,
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
      };
      removeEmptyValues(tempDataSearch);
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);

      await getDataSearchDepartment(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };

    // Ui modal confirm delete store
    const showDeleteConfirm = (itemDepartment: DataDepartment) => {
      confirm({
        title: `Xoá bộ phận`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} "${itemDepartment.departmentName ?? itemDepartment.departmentCode}"`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDeleteDepartment(itemDepartment.departmentId);
        },
        onCancel() { },
        modalRender(modal) {
          return (
            <Draggable>
              <div className="cursor-move">{modal}</div>
            </Draggable>
          );
        },
      });
    };

    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    // Call api delete
    const handleClickDeleteDepartment = async (departmentId: string) => {
      setLoading(true);
      if (departmentId) {
        await departmentPresenter.deleteDepartment(departmentStore, departmentId);
        if (departmentStore.deleteDepartmentResponse.isSuccess) {
          message.success(departmentStore.deleteDepartmentResponse.message);
          // Call list department after change
          await getDataSearchDepartment(dataSearch);
        } else {
          message.error(departmentStore.deleteDepartmentResponse.message);
        }
      }
      setLoading(false);
    };
    return (
      <div className="w-full h-full">
        {/* ==================================== Breadcrumb ========================================= */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/MasterData/Department' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  type="primary"
                  onClick={() => {
                    navigate('Create');
                  }}
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null}
            </>
          }
        />

        {/* Table */}
        <BoxContainer fullHeight>
          <CustomProTable<DataDepartment>
            columns={columns}
            handleResize={() => { }}
            rowKey="departmentId"
            bordered
            search={false}
            loading={loading}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const data: any = await getDataSearchDepartment({
                ...dataSearch,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  ...dataSearch,
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }

              setLoading(false);

              return {
                data: data,
                success: departmentStore.searchDepartmentResponse.isSuccess,
              };
            }}
            scroll={{ x: 1200 }}
            dataSource={listDataDepartment}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: departmentStore.searchDepartmentResponse.paging?.recordsTotal,
            }}
            options={false}
          />
        </BoxContainer>

        <SearchBox // tìm kiếm
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          {/* Form nhập tìm kiếm */}
          <SearchForm column="col-1" form={form} onFinish={handleSearch}>
            {/* Mã phòng ban */}
            <SearchItem label={t('masterdata.department.department-code')} name={'departmentCode'}>
              <CustomInput />
            </SearchItem>
            {/* Tên phòng ban */}
            <SearchItem label={t('masterdata.department.department-name')} name={'departmentName'}>
              <CustomInput />
            </SearchItem>
            {/* trạng thái */}
            <SearchItem label={t('masterdata.department.status')} name={'actived'}>
              <CustomSelect options={dropdownStatus} placeholder={t('place-holder.all')} allowClear />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default createDepartment;
