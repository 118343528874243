import { InputNumber, InputNumberProps } from "antd";

import { memo } from "react";
import { uiStore } from "stores/root_store";

interface CustomInputProps extends InputNumberProps {}

function CustomInputNumber(props: CustomInputProps) {
  return (
    <InputNumber
      style={{
        textAlignLast: "end",
      }}
      {...props}
      className={
        uiStore.theme.name +
        " !w-full hover:!border-primary !shadow-none [&.ant-input-number-disabled]:!border-[#d9d9d9]  !rounded-sm focus:!shadow-none " +
        props.className
      }
    />
  );
}

export default memo(CustomInputNumber);
