import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const DistrictRoutes = ({
  District,
  AddDistrict,
  UpdateDistrict,
}: {
  District: React.ComponentType;
  AddDistrict: React.ComponentType;
  UpdateDistrict: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<District />} />
    <Route path={ROUTES.MASTER_DATA.DISTRICT.CREATE} element={<AddDistrict />} />
    <Route path={ROUTES.MASTER_DATA.DISTRICT.EDIT} element={<UpdateDistrict />} />
  </Routes>
);
