import { INotificationServices, SearchNotiByUserModel } from 'services/Notification/notification_services';
import { observable, runInAction } from 'mobx';

import { DataResponse } from 'services/response';
import { NotificationByUser } from 'models/Notification';

export type NotificationStore = {
    // Danh sách thông báo
    listNotifications: DataResponse<NotificationByUser[]>,
    //    Số lượng thông báo chưa xem
    notiCount: number
    // lưu lại số danh sách PO đã đồng bộ
    listPOAsynch: string[]
    createDate: string | undefined
};

export interface INotificationPresenter {
    createStore(): NotificationStore;
    // Danh sách Thông báo của từng user 
    getListNotiByUser(store: NotificationStore, request: SearchNotiByUserModel): Promise<void>;
    // Đếm số lượng thông báo chưa đọc
    countUnreadNotification(stroe: NotificationStore, userId: string): Promise<void>;
    // Đánh dấu thông báo đã đọc
    readNotification(request: { userId: string }): Promise<void>
    // Đọc 1 thông báo
    readSingleNotification(id: string): Promise<boolean>
    //  
    setNotificationCount(store: NotificationStore, count: number): void
    setListPOAsynch(store: NotificationStore, list: string[]): void
    setCreateDate(store: NotificationStore, date: string | undefined): void
}

export function createNotificationPresenter({ notificationServices }: { notificationServices: INotificationServices }): INotificationPresenter {
    return {
        createStore: (): NotificationStore =>
            observable({
                listNotifications: {
                    data: [],
                    isSuccess: false,
                    message: ''
                },
                notiCount: 0,
                listPOAsynch: [],
                createDate: undefined
            }),
        // Danh sách Thông báo của từng user 
        getListNotiByUser: async (store, request) => {
            try {
                const result = await notificationServices.getListNotiByUser(request);
                runInAction(() => {
                    if (result?.isSuccess) {
                        store.listNotifications = result
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        // Đếm số lượng thông báo chưa đọc
        countUnreadNotification: async (store, request) => {
            try {
                const result = await notificationServices.countUnreadNotification(request);
                runInAction(() => {
                    if (result?.isSuccess) {
                        store.notiCount = result.data
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        // Đánh dấu thông báo đã đọc
        readNotification: async (userId) => {
            try {
                const result = await notificationServices.readNotification(userId);
            } catch (error) {
                console.log(error);
            }
        },
        // Đọc 1 thông báo
        readSingleNotification: async (id) => {
            try {
                const result = await notificationServices.readSingleNotification(id);
                if (result && result.isSuccess) {
                    return true
                }
                return false;
            } catch (error) {
                console.log(error);
                return false
            }
        },
        setNotificationCount: (store, count) => {
            runInAction(() => {
                store.notiCount = count;
            });
        },
        setListPOAsynch: (store, list) => {
            runInAction(() => {
                store.listPOAsynch = list;
            });
        },
        setCreateDate: (store, date) => {
            runInAction(() => {
                store.createDate = date;
            });
        }
    };
}
