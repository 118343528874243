import { ROUTES } from "base/routes/routes";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

type CardItemProps = {
    data: {
        id: string;
        title: string;
        expectedDate: string;
        vendor: string;
        //   Số xe
        licensePlate: string;
        //   Tài xế
        driverName: string;
        checkIn?: string;
        checkOut?: string;
        reason?: string;
    };
    icon?: ReactNode;
    color?: string;
    hidenCheckInOut?: boolean;
    hidenReason?: boolean;
}
export const CardItem = (props: CardItemProps) => {
    const { data, color, hidenCheckInOut, hidenReason = true, icon } = props
    const { driverName, expectedDate, licensePlate, title, vendor, checkIn, checkOut, reason } = data
    return (
        <Link to={ROUTES.PURCHASE_ORDER.LIST_PO.EDIT_VOTE.LINK + '/' + data.id} className='!text-black'>
            <div className="w-full p-1 px-2.5 shadow-box hover:shadow-md hover:opacity-85 duration-150 rounded text-xs" style={{ background: color }}>
                <div className=''>
                    <span className='uppercase font-semibold flex items-end gap-2'>
                        {icon}
                        <h3>{title}</h3>
                    </span>
                </div>
                <div className='w-full text-default'>
                    <span className='col-span-2'>{vendor}</span>
                </div>
                <span >Số xe: <strong className='font-medium'>{licensePlate}</strong></span>
                <span className='block'>TG nhận hàng dự kiến: <strong className='font-medium'>{expectedDate && dayjs(expectedDate).format("HH:mm DD/MM/YYYY")}</strong></span>
                <span className='block'>Tài xế: <strong className='font-medium'>{driverName}</strong></span>
                {!hidenCheckInOut && (<>
                    <span className='block'>Vào cổng: <strong className='font-medium'>{checkIn && dayjs(checkIn).format("HH:mm DD/MM/YYYY")}</strong></span>
                    <span className='block'>Ra cổng: <strong className='font-medium'>{checkOut && dayjs(checkOut).format("HH:mm DD/MM/YYYY")}</strong></span>
                </>)}
                {!hidenReason && (
                    <span className='block'>Lý do từ chối: {reason}</span>
                )}
            </div>
        </Link>
    )
}
