import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { uiPresenter, uiStore } from 'stores/root_store';
import { createElement, memo } from 'react';
import Style from './sidebar.module.css';
import * as Icon from 'base/ui/components/Icons';
import { ChildMenu } from 'services/User/user_services';
import { MVC_URL } from 'utils/constants';
import { BsDot } from 'react-icons/bs';

type MenuItemProps = {
  menuId: string;
  menuName: string;
  icon: string;
  childMenu: Array<ChildMenu>;
  // childMenu?: Array<ChildModel>;
  active?: boolean;
  link?: string;
};

interface ChildModel {
  url: string;
  value: string;
  active?: boolean;
}

const MenuItem = ({ menuId, menuName, icon, childMenu, active: sidebarActive, link }: MenuItemProps) => {
  const handleDropdown = async () => {
    if (childMenu) {
      if (uiStore.sidebarOpen) {
        await uiPresenter.setSubmenuOpen(uiStore, menuId, uiStore.submenuOpen.childId, undefined);
      } else {
        uiPresenter.setSidebarOpen(uiStore, true);
      }
    } else {
      //
      if (uiStore.sidebarOpen) {
        await uiPresenter.setSubmenuOpen(uiStore, menuId, undefined, menuId);
      } else {
        uiPresenter.setSidebarOpen(uiStore, true);
      }
    }
  };

  // Phần này đang render theo kiểu tìm kiếm menuid trong pagemodel
  // Về sau BE có trả thêm pageViewModels trong module, nhưng FE chưa kịp điều chỉnh lại
  //

  return (
    <>
      <li
        onClick={() => {
          if (childMenu.find((i) => i.menuId === menuId)) {
            handleDropdown();
          } else {
            window.location.assign(MVC_URL + '' + link);
          }
        }}
        className={`group relative flex gap-3 mb-[2px] items-center justify-between cursor-pointer pr-[10px] hover:bg-hover hover:rounded-[4px]
        before:content-[''] before:absolute  before:w-[4px] before:h-[16px] before:bg-primary before:rounded-[0px_2px_2px_0px]  before:hidden [&::before]:hover:block
        ${!(uiStore.submenuOpen.active === menuId)
            ? 'mb-[2px] bg-primary '
            : uiStore.submenuOpen.id === menuId && uiStore.submenuOpen.open && uiStore.sidebarOpen
              ? ' rounded-t-[4px]'
              : Style.active
          } ${uiStore.submenuOpen.id === menuId && uiStore.submenuOpen.open
            ? 'bg-third rounded-t-[4px] !mb-0'
            : 'bg-primary'
          } ease-linear
    `}
      >
        <div className="flex items-center flex-1">
          <span
            className={`${uiStore.sidebarOpen ? 'w-[40px]' : 'w-[48px]'
              } flex justify-center items-center text-base select-none ${!(uiStore.submenuOpen.active === menuId)
                ? 'text-sidebar_item'
                : uiStore.submenuOpen.id === menuId && uiStore.submenuOpen.open
                  ? 'text-sidebar_item'
                  : 'text-sidebarTextHover'
              } ${uiStore.sidebarOpen ? 'h-[44px]' : 'h-[46px]'}`}
          >
            <i className={icon + ' group-hover:text-sidebarTextHover'} />
          </span>
          {/* nếu side bar đang đóng thì ẩn các items */}
          <span
            className={`group-hover:text-sidebarTextHover text-sm font-medium leading-5 flex-1 w-[156px] ${!uiStore.sidebarOpen ? 'hidden' : 'block'
              } duration-400 ${!(uiStore.submenuOpen.active === menuId)
                ? 'text-sidebar_item'
                : uiStore.submenuOpen.id === menuId && uiStore.submenuOpen.open
                  ? 'text-sidebar_item'
                  : 'text-sidebarTextHover'
              }`}
          >
            {menuName}
          </span>
        </div>
        {/* nếu side bar đang mở thì hiện nút dropdown */}
        {/* nếu submenu mở thì dropdowm icon hướng lên trên (rotate 180 deg) */}
        {childMenu && childMenu.find((i) => i.menuId === menuId) && uiStore.sidebarOpen && (
          <Icon.ArrowDown
            className={`group-hover:!fill-sidebarTextHover duration-300 ${uiStore.submenuOpen.open && uiStore.submenuOpen.id === menuId && 'rotate-180'
              } ${!(uiStore.submenuOpen.active === menuId)
                ? '!fill-sidebar_item'
                : uiStore.submenuOpen.id === menuId && uiStore.submenuOpen.open
                  ? '!fill-sidebar_item'
                  : '!fill-white'
              }`}
          />
        )}
      </li>
      <ul
        className={`m-0 list-none p-0 !bg-third overflow-hidden rounded-b-[4px] mb-[2px] ${uiStore.submenuOpen.open && uiStore.submenuOpen.id === menuId && uiStore.sidebarOpen
          ? 'max-h-[200vh] overflow-auto'
          : 'max-h-0 '
          } duration-300 ${!uiStore.sidebarOpen && 'hidden'}`}
      >
        {childMenu
          ? childMenu.map((item, index) => {
            if (item.menuId === menuId) {
              if (process.env.NODE_ENV === 'production') {
                if (item.domainConfig === 1) {
                  return (
                    <a
                      href={`${item.domainConfigUrl}${item.pageUrl}${item.parameter}`}
                      // target="_blank"
                      onClick={() => {
                        // uiPresenter.setSidebarOpen(uiStore, false);
                        uiPresenter.setSubmenuOpen(uiStore, menuId, item.pageId, menuId);
                      }}
                      className="w-full"
                      key={index}
                      rel="noreferrer"
                    >
                      <li
                        className={`text-sidebar_item text-sm leading-[22px] flex items-center cursor-pointer select-none p-1`}
                      >
                        <div
                          className={`w-full p-[7px] relative flex items-center ${uiStore.submenuOpen.childId === item.pageId && Style.active
                            } hover:bg-hover hover:rounded-[4px] hover:!text-sidebarTextHover
                          before:content-[''] before:absolute before:left-0 before:w-[4px] before:h-[16px] before:bg-primary before:rounded-[0px_2px_2px_0px]  before:hidden [&::before]:hover:block`}
                        >
                          <div className='w-[35px] justify-center inline-flex'>
                            <BsDot className='text-2xl' />
                          </div>
                          <span className='flex flex-1'>{item.pageName}</span>
                        </div>
                      </li>
                    </a>
                  );
                } else {
                  return (
                    <Link
                      onClick={() => {
                        // uiPresenter.setSidebarOpen(uiStore, false);
                        uiPresenter.setSubmenuOpen(uiStore, menuId, item.pageId, menuId);
                      }}
                      to={`${item.pageUrl.startsWith('/') ? item.pageUrl : '/' + item.pageUrl}`}
                      className="w-full"
                      key={index}
                    >
                      <li
                        className={`text-sidebar_item text-sm leading-[22px] flex items-center cursor-pointer select-none p-1`}
                      >
                        <div
                          className={`w-full p-[7px] relative flex items-center ${uiStore.submenuOpen.childId === item.pageId && Style.active
                            } hover:bg-hover hover:rounded-[4px] hover:!text-sidebarTextHover
                          before:content-[''] before:absolute before:left-0 before:w-[4px] before:h-[16px] before:bg-primary before:rounded-[0px_2px_2px_0px]  before:hidden [&::before]:hover:block`}
                        >
                          <div className='w-[35px] justify-center inline-flex'>
                            <BsDot className='text-2xl' />
                          </div>
                          <span className='flex flex-1'>{item.pageName}</span>
                        </div>
                      </li>
                    </Link>
                  );
                }
              } else {
                return (
                  <Link
                    onClick={() => {
                      // uiPresenter.setSidebarOpen(uiStore, false);
                      uiPresenter.setSubmenuOpen(uiStore, menuId, item.pageId, menuId);
                    }}
                    to={`${item.pageUrl.startsWith('/') ? item.pageUrl : '/' + item.pageUrl}`}
                    className="w-full"
                    key={index}
                  >
                    <li
                      className={`text-sidebar_item text-sm leading-[22px] flex items-center cursor-pointer select-none p-1`}
                    >
                      <div
                        className={`w-full p-[7px] relative flex items-center ${uiStore.submenuOpen.childId === item.pageId && Style.active
                          } hover:bg-hover hover:rounded-[4px] hover:!text-sidebarTextHover
                          before:content-[''] before:absolute before:left-0 before:w-[4px] before:h-[16px] before:bg-primary before:rounded-[0px_2px_2px_0px]  before:hidden [&::before]:hover:block`}
                      >
                        <div className='w-[35px] justify-center inline-flex'>
                          <BsDot className='text-2xl' />
                        </div>
                        <span className='flex flex-1'>{item.pageName}</span>
                      </div>
                    </li>
                  </Link>
                );
              }
            }
          })
          : ''}
      </ul>
    </>
  );
};

export default memo(observer(MenuItem));
