/* eslint-disable react-hooks/exhaustive-deps */

import { userPresenter, userStore } from 'stores/root_store';

import React from 'react';
import { checkPermission } from 'utils/permissionHelper';
import useNavigate from './useNavigation';

// Hook này check có quyền truy cập vào page hay không
export const usePermission = (requiredPermission: string) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    const pagePermissions = async () => {
      const permiss = checkPermission();

      if (!permiss?.permiss.includes(requiredPermission)) {
        navigate('/404');
      } else {
        await userPresenter.setUserPermission(userStore, permiss?.permiss);
      }
    };

    pagePermissions();
  }, [requiredPermission]);
};
