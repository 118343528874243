export function removeVNAccents(str: string) {
  return str
    .normalize('NFD') // Chuyển đổi thành Unicode Decomposed
    .replace(/[\u0300-\u036f]/g, ''); // Loại bỏ các ký tự dấu
}

// Hàm giúp tìm kiếm không cần gõ chính xác chữ tiếng Việt vẫn ra kết quả
export function customFilterOption(input: string, option: any) {
  return removeVNAccents(option?.label ?? '')
    .toLowerCase()
    .includes(removeVNAccents(input).toLowerCase());
}
