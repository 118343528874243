import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ROUTES } from 'base/routes/routes';

// components
import SearchBarInSideBar from './searchBar/searchBar';
import MenuItem from 'base/ui/layout/sideBar/menuItem';

// Icons
import { Bars } from 'base/ui/components/Icons';
import logo from 'assets/images/logo.gif';
// Models

// Store
import { User } from 'services/User/user_services';
import { uiPresenter, uiStore } from 'stores/root_store';

// Styles
import Style from './sidebar.module.css';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import { getUserSession } from 'utils/localStorageHelper';
import { MVC_URL } from 'utils/constants';
import { useTranslation } from 'react-i18next';




export const Sidebar = () => {
  const { t } = useTranslation();
  const data = getUserSession() as User;

  const parentMenu = data.webPermission?.menuModel || []
  const childMenu = data.webPermission?.pageModel || [];

  const handleShowSidebar = () => {
    if (uiStore.sidebarOpen) {
      uiPresenter.setSubmenuOpen(
        uiStore,
        toJS(uiStore.submenuOpen.id),
        toJS(uiStore.submenuOpen.childId),
        toJS(uiStore.submenuOpen.active),
        false,
      );
      uiPresenter.setSidebarOpen(uiStore, false);
    } else {
      uiPresenter.setSidebarOpen(uiStore, true);
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    const t = childMenu.find((item) => path.includes(item.pageUrl));
    if (t) {
      uiPresenter.setSubmenuOpen(uiStore, t.menuId, t.pageId, t.menuId);
    }
  }, []);

  return (
    <div
      className={`absolute h-full z-20 sm:h-auto sm:relative bg-primary min-h-screen ${uiStore.sidebarOpen ? 'sm:block w-[75%] sm:w-[270px]' : 'w-0 sm:w-[68px]'
        } duration-300 overflow-y-auto overflow-x-hidden`}
    >
      <div
        className={`flex w-full items-center justify-center px-[10px] ${Style.split} ${uiStore.sidebarOpen ? 'h-[48px]' : 'h-[64px]'
          }`}
        onClick={handleShowSidebar}
      >
        <span
          className={`w-full h-full overflow-hidden flex items-center ${uiStore.sidebarOpen ? 'justify-between' : 'justify-center'
            }`}
        >
          <Link to="/">
            <img src={logo} alt="Logo" className={`w-[40px] h-[40px] object-contain ${uiStore.sidebarOpen ? 'block' : 'hidden'}`} />
          </Link>
          {/* nav icon */}
          <span className="cursor-pointer">
            <Bars width={uiStore.sidebarOpen ? '16' : '18'} height={uiStore.sidebarOpen ? '16' : '18'} />
          </span>
        </span>
      </div>
      <ul className={`list-none m-0 py-[7px] px-[10px] ${uiStore.sidebarOpen ? '' : 'hidden sm:block'}`}>
        <div className={`${uiStore.sidebarOpen ? '' : 'hidden '}relative w-full outline-none`}>
          <SearchBarInSideBar />
        </div>
        {parentMenu
          ? parentMenu.map((parentItem: any, index: any) => (
            <MenuItem
              key={index}
              // active={parentItem.active}
              menuId={parentItem.menuId}
              // menuId={parentItem.value}
              // menuName={parentItem.value}
              menuName={parentItem.menuName}
              icon={parentItem.icon}
              // childMenu={childMenu}
              // childMenu={parentItem.child}
              childMenu={childMenu}
              link={parentItem.url}
            />
          ))
          : ''}
      </ul>
    </div>
  );
};

export default React.memo(observer(Sidebar));
