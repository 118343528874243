import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';
import { CommonModel } from './commonMasterData_services';

export type MobileScreenModel = Partial<{
    stt: number
    mobileScreenId: string
    screenName: string
    screenCode: string
    menuId: string
    iconType: string
    iconName: string
    orderIndex: number
    icon: string
    visible: boolean
    isSystem: boolean
    actived: boolean
    isCreated: boolean
    isReporter: boolean
    isAssignee: boolean
    activedFunctionList: ActivedFunction[]
}>;

export type ActivedFunction = {
    functionId: string
    functionName: string
}




export interface IMobileScreenServices {
    searchMobileScreen(data: object): Promise<DataResponse<Array<MobileScreenModel>>>;
    // detail
    getDetailMobileScreen(screenId: string): Promise<DataResponse<MobileScreenModel | undefined>>;

    // tạo chức năng
    createMobileScreen(data: object): Promise<DataResponse<null>>
    updateMobileScreen(data: object): Promise<DataResponse<null>>
    deleteMobileScreen(screenId: string): Promise<DataResponse<null>>
}
export function createMobileScreenServices(): IMobileScreenServices {
    return {
        // Search page
        searchMobileScreen: async (data) => {
            return (await post(MASTER_DATA_API_URL, `mobilescreen/search`, data)).data;
        },
        getDetailMobileScreen: async (pageId) => {
            return (await get(MASTER_DATA_API_URL, `mobilescreen/${pageId}`)).data;
        },

        // Tạo chức năng 
        createMobileScreen: async (data) => {
            return (await post(MASTER_DATA_API_URL, `mobilescreen/create`, data)).data;
        },
        updateMobileScreen: async (data) => {
            return (await put(MASTER_DATA_API_URL, `mobilescreen/update`, data)).data;
        },
        deleteMobileScreen: async (screenId) => {
            return (await deleted(MASTER_DATA_API_URL, `mobilescreen/delete/${screenId}`)).data;
        }
    };
}
