import { deleted, downloadFilePost, get, post, put } from 'utils/api';

import { DataResponse } from '../response';
import { Driver } from './driver_services';
import { MASTER_DATA_API_URL } from 'utils/constants';

export type VendorModel = {
    stt: number;
    vendorId: string;
    supplierNumber: string;
    longName: string;
    shortName: string;
    telephone: string;
    address: string;
    actived: boolean;
    drivers: Driver[]
    isCreateAccount: boolean;
    email: string;
    periodTime: string;
    periodTimeName: string;
};

export interface VendorFromSAP {
    stt: string;
    vendor: string;
    vendoR_NAME: string;
}


export interface IVendorServices {

    searchVendors(data: object): Promise<DataResponse<Array<VendorModel>>>;
    addVendor(data: object): Promise<DataResponse<any>>;
    updateVendor(data: object): Promise<DataResponse<any>>;
    deleteVendor(vendorId: string): Promise<DataResponse<any>>;
    getVendorById(vendorId: string): Promise<DataResponse<any>>;
    // search nhà cung cấp từ SAP
    searchVendorSAP(data: object): Promise<DataResponse<VendorFromSAP[]>>;
    getVendorByCode(supplierNumber: string): Promise<DataResponse<VendorModel>>;
    exportVendorTemplate(data: object): Promise<void>;
    getListVendorUpdate(data: object): Promise<DataResponse<any>>;
    exportVendorAccountTemplate(data: object): Promise<void>;
    checkEmailVendor(vendorNumber: string): Promise<DataResponse<any>>;
}

export function createVendorServices(): IVendorServices {
    return {
        searchVendors: async (data) => {
            return (await post(MASTER_DATA_API_URL, `vendor/search`, data)).data;
        },
        addVendor: async (data) => {
            return (await post(MASTER_DATA_API_URL, `vendor/create`, data)).data;
        },
        updateVendor: async (data) => {
            return (await put(MASTER_DATA_API_URL, `vendor/update`, data)).data;
        },
        getVendorById: async (vendorId) => {
            return (
                await get(
                    MASTER_DATA_API_URL,
                    `vendor/${vendorId}`,
                )
            ).data;
        },
        deleteVendor: async (vendorId) => {
            return (await deleted(MASTER_DATA_API_URL, `vendor/delete/${vendorId}`)).data;
        },
        // search nhà cung cấp từ SAP
        searchVendorSAP: async (data) => {
            return (await post(MASTER_DATA_API_URL, `vendor/search-vendor-sap`, data)).data;
        },
        getVendorByCode: async (supplierNumber) => {
            return (await get(MASTER_DATA_API_URL, `vendor/get-by-code?supplierNumber=${supplierNumber}`)).data;
        },
        exportVendorTemplate: async (data) => {
            await downloadFilePost(MASTER_DATA_API_URL, `vendor/export-vendor-template`, data)
        },
        getListVendorUpdate: async (data) => {
            return (await post(MASTER_DATA_API_URL, `vendor/get-list-vendor-update`, data)).data;
        },
        exportVendorAccountTemplate: async (data) => {
            await downloadFilePost(MASTER_DATA_API_URL, `vendor/export-vendor-account-template`, data)
        },
        checkEmailVendor: async (vendorNumber) => {
            return (await get(MASTER_DATA_API_URL, `vendor/check-email-vendor?vendorNumber=${vendorNumber}`)).data;
        }
    };
}
