import { observer } from 'mobx-react'
import PageWrapper from 'pages/Components/PageWrapper'
import React, { Key, useEffect, useRef, useState } from 'react'
import CustomButton from 'base/ui/components/Button';
import { Filter, PencilEdit } from 'base/ui/components/Icons';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { HiOutlineExternalLink, HiSearch } from 'react-icons/hi';
import { Button, Checkbox, CheckboxProps, Form, Input, InputRef, Radio, Select, Space, Table, Tooltip, Upload, message } from 'antd';
import CustomSelect from 'base/ui/components/CustomSelect';
import { ActionType, ProColumns } from '@ant-design/pro-components';
import { Link } from 'react-router-dom';
import { commonMasterDataPresenter, commonMasterDataStore, productPresenter, productStore, userStore } from 'stores/root_store';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { BASE_API_URL, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';
import { ProductModel } from 'services/RawData/product_services';
import { SearchModel } from 'pages/model';
import SearchMultiplePopup from 'pages/synchPO/SearchMultiplePopup';
import ModalUpdate from './components/ModalUpdate';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { uniq } from 'lodash';
import { FaFilter } from 'react-icons/fa';
import { SearchOutlined } from '@ant-design/icons';
import { usePermission } from 'hooks/usePermission';
import { checkPermissionWithId } from 'utils/permissionHelper';
import { ExportButton } from 'pages/Components/FunctionButton';
import * as Icon from 'base/ui/components/Icons';
import { getAccessToken } from 'utils/localStorageHelper';

const initSearch = {
    paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
    },
    actived: true,
    filterType: 'T1'
}

const createProduct = () => {
    return observer(() => {
        document.title = "Thông tin hàng hóa" + ' - ' + SYSTEM_TITLE;
        usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

        const isEdit = checkPermissionWithId('EDIT')
        const isExport = checkPermissionWithId('EXPORT_TEMPLATE')
        const isImport = checkPermissionWithId('IMPORT')

        const [form] = Form.useForm()
        const [openDrawer, setOpenDrawer] = useState(false);
        // Data search
        const [dataSearch, setDataSearch] = useState<SearchModel>(initSearch);
        const [dataTable, setDataTable] = useState<ProductModel[]>([])
        const [showModalProductCode, setShowModalProductCode] = useState(false);
        const [tableLoading, setTableLoading] = useState(false);
        const [modalUpdateOpen, setModalOpen] = useState(false);
        const [productSelected, setProductSelected] = useState<ProductModel[]>([]);
        const [selectedKeys, setSelectedKeys] = useState<Key[]>([])
        // Loading import và export templates
        const [loadingExport, setLoadingExport] = useState(false);
        const [loadingImport, setLoadingImport] = useState(false);

        // Function open hide drawer
        const showDrawer = () => {
            setOpenDrawer(true);
        };

        const onCloseDrawer = () => {
            setOpenDrawer(false);
        };
        const tableRef = useRef<ActionType>();
        // =================================================================================================================================
        const searchInput = useRef<InputRef>(null);
        const [searchText, setSearchText] = useState<string>('');
        const [searchedColumn, setSearchedColumn] = useState('');

        const handleSearch = (
            selectedKeys: string[],
            confirm: FilterDropdownProps['confirm'],
            dataIndex: keyof ProductModel,
        ) => {
            confirm();
            // setSearchText(selectedKeys);
            setSearchedColumn(dataIndex);
        };

        const handleReset = (clearFilters: () => void) => {
            clearFilters();
            // setSearchText([]);
        };
        const getColumnSearchProps = (dataIndex: keyof ProductModel): ProColumns<ProductModel> => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        placeholder={`Tìm kiếm`}
                        value={selectedKeys.length < 2 ? selectedKeys[0] : undefined}
                        onChange={(e) => setSearchText(e.target.value)}
                        // onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <hr className="mb-2 mt-1" />

                    {(() => {
                        const arrString = dataTable.map(data => data[dataIndex] ? data[dataIndex] : '-')
                        // loại bỏ các mã trùng
                        const uniqueArr = uniq(arrString);
                        const searchArr = uniqueArr.filter(i => searchText ? (i + '').includes(searchText) : true)
                        const checkedAll = searchArr.length === selectedKeys.length
                        const indeterminate = selectedKeys.length > 0 && selectedKeys.length < searchArr.length;
                        const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
                            setSelectedKeys(e.target.checked ? searchArr as string[] : []);
                            // setSearchText(e.target.checked ? uniqueArr as string[] : [])
                        };

                        return (
                            <Space direction='vertical' className="max-h-[250px] overflow-y-auto w-full" >
                                <Checkbox checked={checkedAll}
                                    onChange={onCheckAllChange} indeterminate={indeterminate}>Tất cả</Checkbox>
                                <Checkbox.Group value={selectedKeys as string[]} onChange={(checkValues) => {
                                    setSelectedKeys(checkValues as string[])
                                    // setSearchText(checkValues as string[])
                                }}>
                                    <Space direction='vertical'>
                                        {
                                            searchArr.map((item, index) => (
                                                <Checkbox key={index} value={item}>
                                                    {item}
                                                </Checkbox>
                                            ))
                                        }
                                    </Space>
                                </Checkbox.Group>
                            </Space>
                        )
                    })()}

                    <hr className="mb-2 mt-1" />
                    <Space>
                        <Button
                            type="primary"
                            htmlType="button"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <FaFilter style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                (record[dataIndex] || '-')
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()),
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        });

        const defaultColumn: ProColumns<ProductModel>[] = [
            // Stt
            {
                title: "STT",
                dataIndex: 'stt',
                key: 'stt',
                width: 50,
                align: 'center',
                render: (text) => <div className="!text-center">{text}</div>,
                fixed: 'left',
            },
            // Loại hàng hóa
            {
                title: "Loại hàng hóa",
                dataIndex: 'productType',
                key: 'productType',
                width: 100,
            },
            // Mã hàng hóa
            {
                title: "Mã hàng hóa",
                dataIndex: 'productCode',
                key: 'productCode',
                width: 100,
                render: (_, record) => {
                    return isEdit ? (
                        <Link to={`/MasterData/Product/${record.productId}`}>{record.productCode}</Link>
                    ) : (
                        <div>{record.productCode}</div>
                    );
                },
                // ...getColumnSearchProps('productCode')
            },
            // Tên hàng hóa
            {
                title: "Tên hàng hóa",
                dataIndex: 'productName',
                key: 'productName',
                width: 250,
            },
            // Người tạo
            {
                title: "Người tạo",
                dataIndex: 'createByName',
                key: 'createByName',
                width: 150,
            },
            // Ngày tạo
            {
                title: 'Ngày tạo',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 100,
                align: 'center',
                valueType: 'dateTime',
                fieldProps: {
                    format: 'DD/MM/YYYY HH:mm:ss',
                },
            },
            {
                title: '',
                dataIndex: 'functions',
                key: 'functions',
                width: 50,
                align: 'center',
                hideInTable: isEdit ? false : true,
                hideInSetting: isEdit ? false : true,
                render: (_, record) => {
                    return (
                        <div className="w-full flex items-center justify-center gap-x-1">
                            <Tooltip title={'Chỉnh sửa'}>
                                <Link to={record.productId}>
                                    <CustomButtonIcon color="#007BF1">
                                        <PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                                    </CustomButtonIcon>
                                </Link>
                            </Tooltip>
                        </div>
                    );
                },
            },

        ];

        const getDataSearchProduct = async (values: SearchModel) => {
            const request: SearchModel = {
                ...values,
            }
            setTableLoading(true)
            await productPresenter.searchProduct(productStore, request);
            setTableLoading(false)
            if (productStore.searchProductResponse?.isSuccess) {
                setDataTable(productStore.searchProductResponse?.data);
                return productStore.searchProductResponse?.data
            }
            return []
        };

        const getProductType = async () => {
            if (!commonMasterDataStore.productTypes.length) {
                await commonMasterDataPresenter.getDropdownProductType(commonMasterDataStore)
            }
        }

        useEffect(() => {
            getProductType()
        }, []);

        return (
            <PageWrapper
                breadcrumb={[{
                    text: "Thông tin hàng hóa"
                }]}
                extras={<>
                    {isImport && <Upload
                        maxCount={1}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        showUploadList={false}
                        onChange={async (info) => {
                            if (info.file.status === "uploading") {
                                setLoadingImport(true)
                            }
                            if (info.file.status === 'done') {
                                setLoadingImport(false)
                                message.success(info.file.response.message);
                                setDataSearch(initSearch)
                                tableRef.current?.reload()
                            }
                            if (info.file.status === 'error') {
                                message.error(info.file.response.message);
                            }
                        }}
                        action={BASE_API_URL + '/product/import-update-product'}
                        headers={{
                            Authorization: `Bearer ${getAccessToken()}`,
                        }}
                        name="File"
                    >
                        <CustomButton
                            loading={loadingImport}
                            icon={<Icon.Upload />}>
                            Import
                        </CustomButton>
                    </Upload>
                    }
                    {isExport && <ExportButton
                        title='Export template'
                        loading={loadingExport}
                        onClick={async () => {
                            setLoadingExport(true)
                            await productPresenter.exportProductTemplate(dataSearch)
                            setLoadingExport(false)
                        }}
                    />}
                    {isEdit && <CustomButton
                        disabled={!productSelected.length}
                        type='primary'
                        onClick={() => {
                            setModalOpen(true)
                        }}
                    >
                        Cập nhật
                    </CustomButton>}
                    <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                        <Filter className="fill-primary" />
                    </CustomButton>
                </>}
            >
                {/* Table */}
                <BoxContainer>
                    <CustomProTable<ProductModel>
                        columns={defaultColumn}
                        rowKey="productId"
                        size="small"
                        bordered
                        // loading={tableLoading}
                        actionRef={tableRef}
                        scroll={{ x: 'auto' }}
                        tableAlertRender={false}
                        rowSelection={{
                            selections: [Table.SELECTION_ALL,
                            Table.SELECTION_INVERT,
                            Table.SELECTION_NONE,],
                            onChange(selectedRowKeys, selectedRows) {
                                setProductSelected(selectedRows)
                                setSelectedKeys(selectedRowKeys)
                            },
                            selectedRowKeys: selectedKeys
                        }}
                        search={false}
                        request={async (params = {}, sort, filter) => {
                            const data: any = await getDataSearchProduct({
                                ...dataSearch,
                                paging: {
                                    ...dataSearch.paging,
                                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                },
                            });

                            if (Object.values(sort).length) {
                                setDataSearch({
                                    ...dataSearch,
                                    paging: {
                                        ...dataSearch.paging,
                                        orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                        orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                    },
                                });
                            }
                            return {
                                data: data,
                                success: productStore.searchProductResponse?.isSuccess
                            };
                        }}
                        dataSource={dataTable}
                        // pagination={{
                        //     ...tablePagination(dataSearch, setDataSearch),
                        // }}
                        pagination={{
                            ...tablePagination(dataSearch, setDataSearch),
                            total: productStore.searchProductResponse?.paging?.recordsTotal
                        }}

                        options={false}
                    />
                </BoxContainer>

                <SearchBox // tìm kiếm
                    className="lg:!w-[50%]"
                    footer={[
                        <div key="footer" className="flex justify-end">
                            <CustomButton
                                type="primary"
                                icon={<HiSearch />}
                                htmlType="submit"
                                onClick={() => {
                                    form.submit();
                                    onCloseDrawer();
                                }}
                            >
                                Tìm kiếm
                            </CustomButton>
                        </div>,
                    ]}
                    onClose={onCloseDrawer}
                    open={openDrawer}
                >
                    <SearchForm
                        form={form}
                        onFinish={(values) => {
                            const search = {
                                ...values,
                                paging: {
                                    ...dataSearch.paging,
                                    pageIndex: 1
                                }
                            }
                            setDataSearch(search)
                            // getDataSearchProduct(search)
                            tableRef.current?.reload()
                        }}
                        className="gap-x-12"
                        layout="vertical"
                        column="col-2"
                    >
                        <button type='submit' hidden></button>
                        {/* Mã hàng hóa */}
                        <div className="relative">
                            <SearchItem
                                // rules={[{ required: true, message: 'Vui lòng nhập mã hàng hóa.' }]}
                                label={"Mã hàng hóa"}
                                name={'productCode'}
                            >
                                <Input placeholder='Mã hàng hóa' />
                            </SearchItem>
                            <div
                                onClick={() => {
                                    setShowModalProductCode(true)
                                }}
                                className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                                <HiOutlineExternalLink className="text-xl" />
                            </div>
                        </div>
                        {/* Tên hàng hóa */}
                        <SearchItem label={"Tên hàng hóa"} name={'productName'}>
                            <Input placeholder='Tên hàng hóa' />
                        </SearchItem>
                        <SearchItem initialValue={'T1'} label='Tìm kiếm theo' name="filterType">
                            <Radio.Group>
                                <Radio value={'T1'}>Gần đúng</Radio>
                                <Radio value={'T2'}>Đầu số</Radio>
                                <Radio value={'T3'}>Excel</Radio>
                            </Radio.Group>
                        </SearchItem>
                        <SearchItem label={"Loại hàng hóa"} name={'productType'}>
                            <Select
                                options={commonMasterDataStore?.productTypes.map((type) => ({
                                    label: type.value,
                                    value: type.key
                                }))}
                                allowClear
                                placeholder={'Vui lòng chọn'}
                            />
                        </SearchItem>
                    </SearchForm>
                    <SearchMultiplePopup
                        title="PO"
                        open={showModalProductCode}
                        onOpenChange={setShowModalProductCode}
                        onOk={(value) => {
                            if (value) form.setFieldValue("filterType", "T3")
                            form.setFieldValue('productCode', value)
                        }}
                        onReset={() => {
                            form.setFieldValue('productCode', undefined)
                        }}
                    />
                </SearchBox>
                <ModalUpdate
                    open={modalUpdateOpen}
                    onOpenChange={setModalOpen}
                    product={productSelected}
                    handleReload={() => {
                        getDataSearchProduct(dataSearch)
                        setSelectedKeys([])
                        setProductSelected([])
                    }}
                />
            </PageWrapper>
        )
    })
}

export default createProduct