import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function Logout() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="h-screen w-full">
      <Spin indicator={antIcon}>
        <div className="h-screen w-full"></div>
      </Spin>
    </div>
  );
}

export default Logout;
