import { Breadcrumb } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

export interface PageWrapperProps {
  children?: React.ReactNode;
  extras?: React.ReactNode;
  breadcrumb?: {
    to?: string;
    text: string;
  }[];
}

function PageWrapper(props: PageWrapperProps) {
  const linkItems =
    props.breadcrumb && props.breadcrumb.length
      ? props.breadcrumb.map((link, index) => {
        if (index === (props.breadcrumb?.length || 0) - 1) {
          return {
            title: link.text,
          };
        } else {
          return {
            title: <Link to={link.to || '/'}>{link.text}</Link>,
            key: link.text.toLowerCase(),
          };
        }
      })
      : [];
  const breadcrumbItems = [
    {
      title: <Link to="/">Trang chủ</Link>,
      key: 'home',
    },
  ];
  return (
    <div className="flex flex-col w-full h-full">
      {/* breadcum */}
      <div className="flex flex-col w-full bg-white px-4 py-2 gap-default">
        <div className="flex flex-wrap gap-2 items-center justify-between">
          <Breadcrumb
            className="[&_ol_li:last-child]:font-medium [&_ol_li:last-child]:text-dark [&_ol_li:last-child]:text-[13xp] [&_ol]:items-center"
            items={[...breadcrumbItems, ...linkItems]}
          />
          {props.extras ? <div className="flex gap-[6px]">{props.extras}</div> : ''}
          {/* <div className="flex gap-[6px]">
            <CustomButton
              onClick={() => {
                if (isCheckWatch) {
                  handleDelete();
                } else {
                  handleAdd();
                }
              }}
              type="primary"
            >
              {isCheckWatch ? t('btn.delete-calendar') : t('btn.add-calendar')}
            </CustomButton>

            <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
              <Icon.Filter className="!fill-primary" />
            </CustomButton>
          </div> */}
        </div>
      </div>
      <div className="flex-1">
        <div className="flex flex-col w-full h-full">{props.children}</div>
      </div>
    </div>
  );
}

export default PageWrapper;
