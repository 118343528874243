import { observable, runInAction } from 'mobx';
import { CommonCatalogModel, CommonModel, ICommonServices } from 'services/Main/common_services';

export type CommonStore = {
  commonRespone: {
    data: CommonModel[];
    message: string;
    isSuccess: boolean;
  };
  commonDate: {
    fromDate: string;
    toDate: string;
  };
  // Common Date 
  listCommonDate: CommonModel[];
  // Danh sách nhà máy theo tài khoản
  commonStore: CommonModel[],

  // ============= KHU VỰC ==========================================
  dropdownProvinces: CommonModel[]
  // =================================================================
  // danh sách kết quả đào tạo an toàn
  dropdownTrainingResult: CommonCatalogModel[],
  // Danh sách PO type
  dropdownPOType: CommonCatalogModel[],
  // Danh sách trạng thái luồng duyệt
  dropdownTaskStatus: CommonModel[]
  // Danh sách Trạng thái nhận hàng 
  dropdownReceiveStatus: CommonModel[]
};

export interface ICommonPresenter {
  createStore(): CommonStore;
  // =====================COMMON DATE===========================
  // lấy ngày từ common date
  getDateByCommonDate(store: CommonStore, date: string): Promise<void>;
  getListCommonDate(store: CommonStore, type?: "CommonDate" | 'CommonDate2' | "Future"): Promise<void>
  // =================================================================
  // Lấy data danh sách công ty
  getListSaleOrg(store: CommonStore): Promise<void>;
  // Danh sách saleorg by account id
  getSeleOrgByAccountId(store: CommonStore, accountId: string): Promise<void>
  // Danh sách Tỉnh thành phố
  getDropdownProvince(store: CommonStore, data: { keyword?: string }): Promise<void>
  // lấy danh sách kết quả đào tạo (catalogCode = 'TrainingResult')
  getDropdownTrainingResult(store: CommonStore): Promise<void>
  // Danh sách nhà cung cấp
  getDropdownVendor(store: CommonStore, data?: { keyword?: string; takeNumber?: number }): Promise<void>
  // Danh sách PO type
  getDropdownPOType(store: CommonStore): Promise<void>
  // Danh sách trạng thái luồng duyệt
  getDropdownTaskStatus(store: CommonStore): Promise<void>
  //Lấy danh sách trạng thái nhận hàng
  getDropdownReceiveStatus(store: CommonStore): Promise<void>
  // lấy danh sách PO 
  getDropdownPO(keyword?: string): Promise<CommonModel[]>
  // danh sách product
  getDropdownProduct(keyword?: string): Promise<CommonModel[]>
}

export function createCommonPresenter({ commonServices }: { commonServices: ICommonServices }): ICommonPresenter {
  return {
    createStore: (): CommonStore =>
      observable({
        commonRespone: {
          data: [],
          message: '',
          isSuccess: false,
        },
        commonDate: {
          fromDate: '',
          toDate: ''
        },
        //list Common Date 
        listCommonDate: [],
        // Danh sách chi nhánh
        commonStore: [],
        // ============= KHU VỰC ==========================================
        dropdownProvinces: [],
        // =================================================================
        // danh sách kết quả đào tạo an toàn
        dropdownTrainingResult: [],
        // Danh sách PO type
        dropdownPOType: [],
        // Danh sách trạng thái luồng duyệt
        dropdownTaskStatus: [],
        // Danh sách Trạng thái nhận hàng 
        dropdownReceiveStatus: []
      }),
    // =====================COMMON DATE===========================
    // lấy ngày từ common date
    getDateByCommonDate: async (store, date: string) => {
      try {
        const result = await commonServices.getDateByCommonDate(date);
        runInAction(() => {
          store.commonDate = result;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getListCommonDate: async (store, type) => {
      try {
        const result = await commonServices.getCommonDate(type);
        runInAction(() => {
          store.listCommonDate = result.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    // =================================================================
    // Hàm lấy data danh sách công ty
    getListSaleOrg: async (store) => {
      try {
        const result = await commonServices.getListSaleOrg();
        runInAction(() => {
          store.commonRespone.data = result.data;
          store.commonRespone.isSuccess = result.isSuccess;
          store.commonRespone.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },

    // Danh sách saleorg by account id
    getSeleOrgByAccountId: async (store: CommonStore, accountId: string) => {
      try {
        const result = await commonServices.getSeleOrgByAccountId(accountId);
        if (result.data) {
          runInAction(() => {
            store.commonStore = result.data;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Danh sách Tỉnh thành phố
    getDropdownProvince: async (store: CommonStore, data) => {
      try {
        const result = await commonServices.getDropdownProvince(data);
        if (result.data) {
          runInAction(() => {
            store.dropdownProvinces = result.data;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // lấy danh sách kết quả đào tạo (catalogCode = 'TrainingResult')
    getDropdownTrainingResult: async (store) => {
      try {
        const result = await commonServices.getCatalogByCode('TrainingResult');
        if (result.data) {
          runInAction(() => {
            store.dropdownTrainingResult = result.data;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Danh sách nhà cung cấp
    getDropdownVendor: async (store, data) => {
      try {
        const result = await commonServices.getDropdownVendor(data);
        if (result.data) {
          runInAction(() => {
            store.commonRespone = result;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Danh sách PO type (catalogCode = 'POType')
    getDropdownPOType: async (store) => {
      try {
        const result = await commonServices.getCatalogByCode('POType');
        if (result.data) {
          runInAction(() => {
            store.dropdownPOType = result.data;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Danh sách trạng thái luồng duyệt
    getDropdownTaskStatus: async (store) => {
      try {
        const result = await commonServices.getDropdownTaskStatus();
        if (result.data) {
          runInAction(() => {
            store.dropdownTaskStatus = result.data;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    //Lấy danh sách trạng thái nhận hàng
    getDropdownReceiveStatus: async (store) => {
      try {
        const result = await commonServices.getDropdownCatalogByCatalogTypeCode('ReceiveStatus');
        if (result.data) {
          runInAction(() => {
            store.dropdownReceiveStatus = result.data;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // lấy danh sách PO 
    getDropdownPO: async (keyword) => {
      try {
        const result = await commonServices.getDropdownPOCode(keyword);
        return result.data
      } catch (error) {
        console.log(error);
        return []
      }
    },
    // danh sách product
    getDropdownProduct: async (keyword) => {
      try {
        const result = await commonServices.getDropdownProduct(keyword);
        return result.data
      } catch (error) {
        console.log(error);
        return []
      }
    }
  };
}
