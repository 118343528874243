import { Select, SelectProps } from 'antd';
import React from 'react';
import './styles.css';
import { uiStore } from 'stores/root_store';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';

export interface CustomSelect<
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> extends SelectProps<any, any> {
  selectAll?: boolean;
}

const CustomSelect: React.FC<CustomSelect<any, any>> = ({ selectAll = false, ...props }) => {
  return (
    <Select
      {...props}
      className={
        uiStore.theme.name +
        ' [&>.ant-select-selector]:rounded-sm [&>.ant-select-selector]:border [&>.ant-select-selector]:border-border [&>.ant-select-selector]:!px-3 text-textColor ' +
        '[&>.ant-select-selector:hover]:!border-primary [&>.ant-select-selector]:!shadow-none [&.ant-select-disabled>.ant-select-selector:hover]:!border-[#d9d9d9] ' +
        props.className
      }
    />
  );
};

export default CustomSelect;
