import { ISalesEmployeeServices, SalesEmployee } from 'services/RawData/salesEmployee_services';
import { observable, runInAction } from 'mobx';

import { DataResponse } from 'services/response';

export type salesEmployeeStore = {
  // Type default response search
  searchSalesEmployeeResponse: DataResponse<Array<SalesEmployee>>;
  salesEmployeeDetailResponse: DataResponse<SalesEmployee | undefined>;
  crudResponse: DataResponse<any>;
};

export interface ISalesEmployeePresenter {
  createStore(): salesEmployeeStore;
  // Search salesEmployee
  searchSalesEmployee(store: salesEmployeeStore, data: object): Promise<void>;
  // Update salesEmployee
  updateSalesEmployee(store: salesEmployeeStore, data: object): Promise<void>;
  // Create salesEmployee
  createSalesEmployee(store: salesEmployeeStore, data: object): Promise<void>;
  // Delete salesEmployee
  deleteSalesEmployee(store: salesEmployeeStore, code: string): Promise<void>;
  // get salesEmployee by EmployeCode
  getSalesEmployeeByCode(store: salesEmployeeStore, EmployeeCode: string): Promise<void>;
}

export function createSalesEmployeePresenter({
  salesEmployeeServices,
}: {
  salesEmployeeServices: ISalesEmployeeServices;
}): ISalesEmployeePresenter {
  return {
    createStore: () =>
      observable({
        searchSalesEmployeeResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        salesEmployeeDetailResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        crudResponse: {
          data: null,
          message: '',
          isSuccess: false,
        },
      }),
    // Search salesEmployee
    searchSalesEmployee: async (store, data) => {
      try {
        const result = await salesEmployeeServices.searchSalesEmployees(data);
        runInAction(() => {
          store.searchSalesEmployeeResponse.data = result.data;
          store.searchSalesEmployeeResponse.isSuccess = result.isSuccess;
          store.searchSalesEmployeeResponse.message = result.message;
          store.searchSalesEmployeeResponse.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    updateSalesEmployee: async (store, data) => {
      try {
        const result = await salesEmployeeServices.updateSalesEmployee(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    createSalesEmployee: async (store, data) => {
      try {
        const result = await salesEmployeeServices.addSalesEmployee(data);
        runInAction(() => {
          store.crudResponse.data = result.data;
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.error(error);
      }
    },
    deleteSalesEmployee: async (store, code) => {
      try {
        const result = await salesEmployeeServices.deleteAccountByCode(code);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getSalesEmployeeByCode: async (store, EmployeeCode) => {
      try {
        const result = await salesEmployeeServices.getSalesEmployeeById(EmployeeCode);
        runInAction(() => {
          store.salesEmployeeDetailResponse.isSuccess = result.isSuccess;
          store.salesEmployeeDetailResponse.data = result.data;
          store.salesEmployeeDetailResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
