import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { Province, IProvinceServices } from 'services/RawData/province_services';

export type provinceStore = {
  // Type default response search province
  searchProvinceResponse: DataResponse<Array<Province>>;
  detailProvince: DataResponse<Province | undefined>;
  listProvince: DataResponse<Array<Province>>;
  crudResponse: DataResponse<any>;
};

export interface IProvincePresenter {
  createStore(): provinceStore;
  // Search province
  searchProvince(store: provinceStore, keyword?: string): Promise<void>;
  // Search list province
  searchListProvince(store: provinceStore, data: object): Promise<void>;
  // create new province
  createProvince(store: provinceStore, data: object): Promise<void>;
  // update new province
  updateProvince(store: provinceStore, data: object): Promise<void>;
  // delete
  deleteProvince(store: provinceStore, id: string): Promise<void>;
  // get province by id
  getProvinceById(store: provinceStore, ProvinceId: string): Promise<void>;
}

export function createProvincePresenter({
  provinceServices,
}: {
  provinceServices: IProvinceServices;
}): IProvincePresenter {
  return {
    createStore: () =>
      observable({
        searchProvinceResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        detailProvince: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        listProvince: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        crudResponse: {
          data: [],
          message: '',
          isSuccess: false,
        },
      }),
    // Search province
    searchProvince: async (store, keyword) => {
      try {
        const result = await provinceServices.searchProvince(keyword);
        runInAction(() => {
          store.searchProvinceResponse.data = result.data;
          store.searchProvinceResponse.isSuccess = result.isSuccess;
          store.searchProvinceResponse.message = result.message;
          store.searchProvinceResponse.paging = {
            totalCount: result.paging?.pagesCount,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Search list province
    searchListProvince: async (store, data) => {
      try {
        const result = await provinceServices.searchListProvince(data);
        runInAction(() => {
          store.listProvince.data = result.data;
          store.listProvince.isSuccess = result.isSuccess;
          store.listProvince.message = result.message;
          store.listProvince.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            totalPage: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // create new province
    createProvince: async (store, data) => {
      try {
        const result = await provinceServices.createProvince(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // update new province
    updateProvince: async (store, data) => {
      try {
        const result = await provinceServices.updateProvince(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // delete
    deleteProvince: async (store, id) => {
      try {
        const result = await provinceServices.deleteProvince(id);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get province by id
    getProvinceById: async (store, ProvinceId) => {
      try {
        const result = await provinceServices.getProvinceById(ProvinceId);
        runInAction(() => {
          store.detailProvince.data = result.data;
          store.detailProvince.isSuccess = result.isSuccess;
          store.detailProvince.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
