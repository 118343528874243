import { message } from 'antd';
import { observable, runInAction } from 'mobx';
import { DeliveryRegistrationModel } from 'models/DeliveryRegistration';
import { PurchaseOrderDetailModel, PurchaseOrderModel } from 'models/PurchaseOrder';
import { DeliveryRegistrationReport, DeliveryResultReport, EmailSendingReport, ReceivingScheduleOfBHFReport } from 'models/Report';
import { IPurchaseOrderServices, SearchDeliveryRegistration, SearchPO } from 'services/Main/purchaseOrder_services';
import { IReportServices, SearchDeliveryRegistrationReport, SearchDeliveryResultReport, SearchEmailSendingReport, SearchReceivingScheduleOfBHFReport } from 'services/Main/report_services';
import { DataResponse } from 'services/response';

export type ReportStore = {
    // Báo cáo danh sách đăng ký xe
    deliveryRegistrationRes: DataResponse<DeliveryRegistrationReport[]>;
    // Báo cáo kết quả giao hàng
    deliveryResultRes: DataResponse<DeliveryResultReport[]>;
    // Báo cáo receiving-schedule-of-bhf
    receivingScheduleOfBHFRes: DataResponse<ReceivingScheduleOfBHFReport[]>;
    // Báo cáo kết quả gửi email
    emailSendingRes: DataResponse<EmailSendingReport[]>;
};

export interface IReportPresenter {
    createStore(): ReportStore;
    // ======================= BÁO CÁO DANH SÁCH ĐĂNG KÝ XE ==========================
    getListReportDeliveryRegistration(store: ReportStore, data: SearchDeliveryRegistrationReport): Promise<void>;
    // Export excel
    exportListReportDeliveryRegistration(data: SearchDeliveryRegistrationReport): Promise<void>;
    //====================== BÁO CÁO KẾT QUẢ GIAO HÀNG =================================================
    //  Search
    getReportDeliveryResult(store: ReportStore, data: SearchDeliveryResultReport): Promise<void>
    // Export excel
    exportReportDeliveryResult(data: Omit<SearchDeliveryResultReport, 'paging'>): Promise<void>;
    //====================== BÁO CÁO RECEIVING-SCHEDULE-OF-BHF =================================================
    //  Search
    getReceivingSchedulReport(store: ReportStore, data: SearchReceivingScheduleOfBHFReport): Promise<void>
    // Export excel
    exportReceivingSchedulReport(data: Omit<SearchReceivingScheduleOfBHFReport, 'paging'>): Promise<void>;
    //====================== BÁO CÁO KẾT QUẢ GỬI EMAIL =================================================
    //  Search
    getEmailSendingReport(store: ReportStore, data: SearchEmailSendingReport): Promise<void>
    // Export excel
    exportEmailSendingReport(data: Omit<SearchEmailSendingReport, 'paging'>): Promise<void>;
    // exportReceivingSchedulReport(data: Omit<SearchReceivingScheduleOfBHFReport, 'paging'>): Promise<void>;
}

export function createReportPresenter({ reportServices }: { reportServices: IReportServices }): IReportPresenter {
    return {
        createStore: (): ReportStore =>
            observable({
                // Báo cáo danh sách đăng ký xe
                deliveryRegistrationRes: {
                    data: [],
                    isSuccess: false,
                    message: ''
                },
                // Báo cáo kết quả giao hàng
                deliveryResultRes: {
                    data: [],
                    isSuccess: false,
                    message: ''
                },
                // Báo cáo receiving-schedule-of-bhf
                receivingScheduleOfBHFRes: {
                    data: [],
                    isSuccess: false,
                    message: ''
                },
                // Báo cáo kết quả gửi email
                emailSendingRes: {
                    data: [],
                    isSuccess: false,
                    message: ''
                }
            }),
        // ======================= BÁO CÁO DANH SÁCH ĐĂNG KÝ XE ==========================
        getListReportDeliveryRegistration: async (store, data) => {
            try {
                const result = await reportServices.getListReportDeliveryRegistration(data);
                runInAction(() => {
                    store.deliveryRegistrationRes = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // Export excel
        exportListReportDeliveryRegistration: async (data) => {
            try {
                await reportServices.exportListReportDeliveryRegistration(data);
            } catch (error) {
                console.log(error);
            }
        },
        //====================== BÁO CÁO KẾT QUẢ GIAO HÀNG =================================================
        //  Search
        getReportDeliveryResult: async (store, data) => {
            try {
                const result = await reportServices.getReportDeliveryResult(data);
                runInAction(() => {
                    store.deliveryResultRes = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // Export excel
        exportReportDeliveryResult: async (data) => {
            try {
                await reportServices.exportReportDeliveryResult(data);
            } catch (error) {
                console.log(error);
            }
        },
        //====================== BÁO CÁO RECEIVING-SCHEDULE-OF-BHF =================================================
        //  Search
        getReceivingSchedulReport: async (store, data) => {
            try {
                const result = await reportServices.getReceivingSchedulReport(data);
                runInAction(() => {
                    store.receivingScheduleOfBHFRes = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        // Export excel
        exportReceivingSchedulReport: async (data) => {
            try {
                await reportServices.exportReceivingSchedulReport(data);
            } catch (error) {
                console.log(error);
            }
        },
        //====================== BÁO CÁO KẾT QUẢ GỬI EMAIL =================================================
        //  Search
        getEmailSendingReport: async (store, data) => {
            try {
                const result = await reportServices.getEmailSendingReport(data);
                runInAction(() => {
                    store.emailSendingRes = result;
                });
            } catch (error) {
                console.log(error);
            }
        },
        exportEmailSendingReport: async (data) => {
            try {
                await reportServices.exportEmailSendingReport(data);
            } catch (error) {
                console.log(error);
            }
        }

    };
}
