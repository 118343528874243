import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font, PDFViewer } from '@react-pdf/renderer';
import { observer } from 'mobx-react';
import { formatNumber } from 'utils/formatHelper';
import { useParams } from 'react-router-dom';
import { purchaseOrderPresenter } from 'stores/root_store';
import { PurchaseOrderDetailModel } from 'models/PurchaseOrder';
import { Button, QRCode, Spin } from 'antd';
import moment from 'moment';
import { BsDot } from 'react-icons/bs';

// Register font
Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: `${window.location.origin}/Font/Roboto-Light.ttf`,
            fontWeight: 300,
            fontStyle: 'normal',
        },
        {
            src: `${window.location.origin}/Font/Roboto-LightItalic.ttf`,
            fontWeight: 300,
            fontStyle: 'italic',
        },
        {
            src: `${window.location.origin}/Font/Roboto-Regular.ttf`,
            fontWeight: 400,
            fontStyle: 'normal',
        },
        {
            src: `${window.location.origin}/Font/Roboto-Italic.ttf`,
            fontWeight: 400,
            fontStyle: 'italic',
        },
        {
            src: `${window.location.origin}/Font/Roboto-Medium.ttf`,
            fontWeight: 500,
            fontStyle: 'normal',
        },
        {
            src: `${window.location.origin}/Font/Roboto-MediumItalic.ttf`,
            fontWeight: 500,
            fontStyle: 'italic',
        },
        {
            src: `${window.location.origin}/Font/Roboto-Bold.ttf`,
            fontWeight: 700,
            fontStyle: 'normal',
        },
        {
            src: `${window.location.origin}/Font/Roboto-BoldItalic.ttf`,
            fontWeight: 700,
            fontStyle: 'italic',
        },
    ],
});


Font.registerHyphenationCallback(word => [word]);

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        position: 'relative',
        padding: "30px",
        paddingRight: "20px",
    },
    header: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    title: {
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 700,
    },
    serial: {
        fontStyle: 'italic',
        fontSize: 7,
        fontWeight: 400
    },
    imgBox: {
        width: "70px",
        height: "70px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px",
        borderColor: '#e7e7e7',
        borderRadius: '2px',
        marginTop: '14px',
    },
    img: {
        width: "65px",
        height: "65px",
    },
    infor: {
        display: 'flex',
        flexDirection: 'row',
        width: "83%",
        position: 'absolute',
        top: '58px',
        right: '30px',
        gap: '10px'
    },
    inforText: {
        display: 'flex',
        fontSize: '8px',
        fontWeight: 400,
        paddingBottom: '2px',
        flexWrap: 'wrap'
    },
    textBold: {
        fontWeight: 700,
    },
    table: {
        marginTop: '25px',
        fontSize: '7px',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: 1,
        borderColor: '#e1e1e1'
    },
    tableCell: {
        borderLeft: 1,
        borderColor: '#e1e1e1',
        padding: '4px 2px',
        fontWeight: 400
    },
});

// Create Document Component

export function createPrintPDF() {
    return observer(() => {
        const { deliveryRegistrationId } = useParams();
        const [dataDetail, setDataDetail] = useState<PurchaseOrderDetailModel | undefined>(undefined)
        const [qrImg, setQRImg] = useState<string | undefined>(undefined)
        // chi tiết phiếu đăng ký theo id
        const getDetailDeliveryRegistration = async () => {
            const detail = await purchaseOrderPresenter.getDetailDeliveryRegistration(deliveryRegistrationId || '')
            if (detail) {
                setDataDetail(detail)
            }
        }
        const downloadQRCode = () => {
            const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
            if (canvas) {
                // const base64String = canvas.toDataURL().replace('data:image/png;base64,', '');
                const base64String = canvas.toDataURL();
                // const byteCharacters = atob(base64String);
                // const byteNumbers = new Array(byteCharacters.length);
                // for (let i = 0; i < byteCharacters.length; i++) {
                //     byteNumbers[i] = byteCharacters.charCodeAt(i);
                // }
                // const byteArray = new Uint8Array(byteNumbers);
                // const blob = new Blob([byteArray], { type: 'image/jpeg' });

                // // Tạo URL đối tượng từ Blob
                // const blobUrl = URL.createObjectURL(blob);
                // console.log(base64String);

                // console.log(blobUrl);
                setQRImg(base64String)
            }
        };

        useEffect(() => {
            downloadQRCode()
        }, [dataDetail]);

        useEffect(() => {
            getDetailDeliveryRegistration()
        }, []);
        return !dataDetail ?
            (<div className='w-full h-full flex justify-center items-center'>
                <Spin />
            </div>) :
            (<>
                <div id="myqrcode" className='hidden'>
                    <QRCode value={dataDetail.deliveryRegistrationId} bgColor="#fff" style={{ marginBottom: 16 }} />
                </div>
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Document >
                        <Page wrap size="A4" style={styles.page} >
                            <View style={styles.header}>
                                <View style={styles.imgBox}>
                                    {qrImg && <Image style={styles.img} source={qrImg} />}
                                </View>
                                <Text style={styles.title}>Phiếu đăng ký xe giao hàng</Text>
                                <Text style={styles.serial}>Số phiếu: {dataDetail.deliveryRegistrationCode}</Text>
                            </View>
                            <View style={styles.infor}>
                                <View style={{ width: '60%' }}>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Nhà cung cấp:</Text> {dataDetail.vendorName}</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Tài xế:</Text> {dataDetail.driverName}</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Số xe:</Text> {dataDetail.licensePlate}</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Kết quả đào tạo:</Text> {dataDetail.resultName}</Text>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Số điện thoại:</Text> {dataDetail.phone}</Text>
                                </View>
                                <View style={{ width: '40%' }}>
                                    <View style={styles.inforText}>
                                        <Text style={{ ...styles.textBold, marginBottom: '2px' }}>Thời gian giao hàng:</Text>
                                        {dataDetail.deliveryTimeItems.length ? dataDetail.deliveryTimeItems.map((item, index) => {
                                            return (
                                                <Text style={{ width: '100%', paddingBottom: '2px' }} key={index}>
                                                    <Text style={styles.textBold}>    &#8226;   Đợt {index + 1}: </Text><Text>{item.deliveryTime ? moment(item.deliveryTime).format("DD/MM/YYYY HH:mm:ss") : ''}</Text>
                                                </Text>
                                            )
                                        }) : ''}
                                    </View>
                                    <Text style={styles.inforText}><Text style={styles.textBold}>Số bằng lái xe:</Text> {dataDetail.licenseNumber}</Text>
                                </View>

                            </View>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '4%', textAlign: 'center' }}><Text>STT</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '9%' }}><Text>Purchase Order</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '5%', textAlign: 'center', }}><Text>Item</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '9%' }}><Text>Mã hàng</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '18%' }}><Text>Tên hàng</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '9%', textAlign: 'right', }}><Text>SL đặt hàng</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '9%', textAlign: 'right', }}><Text>SL nhập kho</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '10%', textAlign: 'right', }}><Text>SL còn lại</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '10%', textAlign: 'right', }}><Text>SL giao hàng</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '5%' }}><Text>ĐVT</Text></View>
                                    <View style={{ ...styles.tableCell, fontWeight: 500, width: '12%', borderRightWidth: 1 }}><Text>Kho</Text></View>
                                </View>
                                {dataDetail?.deliveryInfoItems.map((item, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        {/*  */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '4%', textAlign: 'center' }}><Text>{index + 1}</Text></View>
                                        {/* Số po */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '9%' }}><Text>{item.purchaseOrderCode}</Text></View>
                                        {/* po item */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '5%', textAlign: 'center', }}><Text>{item.poItem}</Text></View>
                                        {/* mã hàng hóa */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '9%' }}><Text>{item.productCode}</Text></View>
                                        {/* tên hàng hóa */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '18%' }}><Text>{item.productName}</Text></View>
                                        {/* SL đặt hàng */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '9%', textAlign: 'right', }}><Text>{formatNumber(item.totalQuantity, 3)}</Text></View>
                                        {/* SL nhập kho */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '9%', textAlign: 'right', }}><Text>{formatNumber(item.receivedQuantity || 0, 3)}</Text></View>
                                        {/* Sl còn lại */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '10%', textAlign: 'right', }}><Text>{formatNumber(item.openQuantitySAP, 3)}</Text></View>
                                        {/* SL giao hàng */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '10%', textAlign: 'right', }}><Text>{formatNumber(item.deliveredQuantity, 3)}</Text></View>
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '5%' }}><Text>{item.orderUnit}</Text></View>
                                        {/* Kho */}
                                        <View style={{ ...styles.tableCell, paddingTop: 2, paddingBottom: 2, width: '12%', borderRightWidth: 1 }}><Text>{item.stockFmt}</Text></View>
                                    </View>
                                ))}
                                <View style={styles.tableRow}></View>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            </>)
    })
}

