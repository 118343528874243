import React, { forwardRef } from 'react';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  //   icon: React.ReactNode;
  color: string;
}

export type Ref = HTMLButtonElement;

const CustomButtonIcon = forwardRef<Ref, ButtonProps>((props: ButtonProps, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      style={{ backgroundColor: `${props.color}4D`, borderColor: `${props.color}80` }}
      className={`text-default p-[5px] rounded-sm  border flex gap-1 items-center justify-center disabled:grayscale disabled:opacity-50 disabled:cursor-not-allowed ` + props.className}
    >
      {props.children}
    </button>
  );
});

export default CustomButtonIcon;
