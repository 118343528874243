/* eslint-disable react-hooks/exhaustive-deps */

import * as Icon from 'base/ui/components/Icons';

import { Form, Radio, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { companyPresenter, companyStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomRadio from 'base/ui/components/CustomRadio';
import { DataCompany } from 'services/RawData/company_services';
import { SYSTEM_TITLE } from 'utils/constants';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import { validatePhone } from 'utils/validateHelper';

const createAddUpdateCompany = () => {
  return observer(() => {
    // Get data params from url
    const { id: idParams } = useParams();
    // translate en/vn
    const { t } = useTranslation();
    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }

    const navigate = useNavigate();

    //==========================STATE===================//
    // Loading
    const [loading, setLoading] = useState(false);

    // Check button save and save continue
    let isSaveAndContinue = false;
    const [form] = Form.useForm();

    //==========================FUNCTION===================//
    // Call api detail routing
    const getDetailCompany = async (companyId: string) => {
      setLoading(true);
      await companyPresenter.getDetailCompany(companyStore, companyId);
      if (companyStore.detailCompanyResponse.isSuccess) {
        const dataGetFirst = companyStore.detailCompanyResponse.data as DataCompany;

        form.setFieldsValue(dataGetFirst);

        setLoading(false);
      }
    };

    // Call api first access
    useEffect(() => {
      if (idParams) {
        getDetailCompany(idParams);
      }
    }, []);

    // Set value default of form
    const initialValues = {
      actived: true,
    };

    // Call api update detail routing
    const onFinish = async (values: DataCompany) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        await companyPresenter.createDetailCompany(companyStore, values);
        if (companyStore.createDetailCompanyResponse.isSuccess) {
          message.success(companyStore.createDetailCompanyResponse.message);

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Company');
          }
          form.resetFields();
        }
      } else {
        // update (page)
        const dataSendApi = {
          ...values,
          companyId: idParams,
        } as DataCompany;
        // Call api update
        await companyPresenter.updateDetailCompany(companyStore, dataSendApi);
        if (companyStore.updateDetailCompanyResponse.isSuccess) {
          message.success(companyStore.updateDetailCompanyResponse.message);

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Company');
          }
        }
      }

      setLoading(false);
    };

    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }

    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('masterdata.company.title'),
                link: '/MasterData/Company',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/Company/${idParams}` : '/MasterData/Company/create',
              },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                  {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                </div>
              </>
            }
          />
          {/* Form nhập tìm kiếm */}
          <BoxContainer fullHeight>
            <Spin spinning={loading}>
              <SearchForm
                column="col-2"
                form={form}
                onFinish={onFinish}
                initialValues={idParams ? {} : initialValues}
              >
                {/* Mã công ty */}
                <SearchItem
                  label={t('masterdata.company.company-code')}
                  name="companyCode"
                  rules={[{ required: true }]}
                >
                  <CustomInput disabled={idParams ? true : false} />
                </SearchItem>
                {/* Tên công ty */}
                <SearchItem
                  label={t('masterdata.company.company-name')}
                  name="companyName"
                >
                  <CustomInput />
                </SearchItem>
                {/* Điện thoại bán hàng */}
                <SearchItem label={"Điện thoại"} name="telProduct" rules={[{ required: true }, {
                  validator: validatePhone,
                }]}>
                  <CustomInput addonBefore={<Icon.PhoneVolume />} />
                </SearchItem>
                {/* Địa chỉ */}
                <SearchItem label={t('masterdata.company.address')} name="companyAddress">
                  <CustomInput />
                </SearchItem>
                {/* Trạng thái */}
                <SearchItem label={t('masterdata.status')} hidden name="actived">
                  <Radio.Group>
                    <CustomRadio value={true}>{t('radio-btn.active')}</CustomRadio>
                    <CustomRadio value={false}>{t('radio-btn.in-active')}</CustomRadio>
                  </Radio.Group>
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateCompany;
