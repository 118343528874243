import * as Icon from 'base/ui/components/Icons';

import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { Form, Modal, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { commonMasterDataPresenter, commonMasterDataStore, userStore, wardPresenter, wardStore } from 'stores/root_store';
import { useEffect, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import { CommonModel } from 'services/RawData/commonMasterData_services';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { HiSearch } from 'react-icons/hi';
import { ProColumns } from '@ant-design/pro-components';
import { SearchModel } from 'pages/model';
import { Ward } from 'services/RawData/ward_services';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

const { confirm } = Modal;

const wardPage = () => {
  return observer(() => {
    const { t } = useTranslation();

    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    const pagetitle = t('masterdata.ward.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);

    // Columns table title
    const defaultColumn: ProColumns<Ward>[] = [
      {
        title: t('masterdata.ward.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 60,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
      },
      // Tỉnh/Thành phố
      {
        title: t('masterdata.ward.province'),
        dataIndex: 'provinceName',
        key: 'provinceName',

        fixed: 'left',
        width: 300,
        className: '!rounded-none !py-1',
      },
      // Quận huyện
      {
        title: t('masterdata.ward.district'),
        dataIndex: 'districtName',
        key: 'districtName',

        fixed: 'left',
        width: 300,
        className: '!rounded-none !py-1',
      },
      // Mã phường xã
      {
        title: t('masterdata.ward.ward-code'),
        dataIndex: 'wardCode',
        key: 'wardCode',
        width: 150,
        className: '!rounded-none !py-1',
        render: (text, record) => {
          return <Link to={`/MasterData/Ward/${record.wardId}`}>{text}</Link>;
        },
      },
      // Tên phường xã
      {
        title: t('masterdata.ward.ward-name'),
        dataIndex: 'wardName',
        key: 'wardName',
        width: 150,
        className: '!rounded-none !py-1',
      },
      // // trạng thái
      // {
      //   title: 'Trạng thái',
      //   dataIndex: 'actived',
      //   width: 80,
      //   key: 'actived',
      //   render: (_, record) => {
      //     return (
      //       <div>
      //         {record.actived ? (
      //           <Icon.CheckStatus className="w-4 text-sm text-center mx-auto !fill-[#389E0D]" />
      //         ) : (
      //           <Icon.CloseStatus className="w-4 text-sm text-center mx-auto !fill-[#D93133E5]" />
      //         )}
      //       </div>
      //     );
      //   },
      // },
      // // chức năng
      // {
      //   title: t('masterdata.ward.actions'),
      //   dataIndex: 'actions',
      //   key: 'actions',
      //   width: 100,
      //   className: '!rounded-none !py-1',
      //   align: 'center',
      //   hideInTable:
      //     userStore.userPermission?.includes('EDIT') && userStore.userPermission?.includes('DELETE') ? true : false,
      //   render: (_, record) => {
      //     return (
      //       <div className="w-full flex items-center justify-center gap-x-1">
      //         {userStore.userPermission?.includes('EDIT') ? (
      //           <CustomButtonIcon color="#007BF1" onClick={() => navigate(`${record.wardId}`)}>
      //             <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
      //           </CustomButtonIcon>
      //         ) : null}
      //         {userStore.userPermission?.includes('DELETE') ? (
      //           record.actived ? (
      //             <CustomButtonIcon
      //               color="#FC5C5E"
      //               onClick={() => {
      //                 showDeleteConfirm(record);
      //               }}
      //             >
      //               <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
      //             </CustomButtonIcon>
      //           ) : null
      //         ) : null}
      //       </div>
      //     );
      //   },
      // },
    ];
    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<Ward>[]>(defaultColumn);
    // Loading all page
    const [loading, setLoading] = useState(false);
    // List province
    const [listProvince, setListProvince] = useState<CommonModel[]>([]);
    // List district
    const [listDistrict, setListDistrict] = useState<CommonModel[]>([]);
    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    });

    // List data table
    const [listWard, setListWard] = useState<Ward[]>([]);

    // function open hide drawer ===========================================================

    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Ui modal confirm delete store ================================================================
    const showDeleteConfirm = (item: Ward) => {
      confirm({
        title: `Xoá phường xã`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} "${item.wardName}"`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDelete(item.wardId);
        },
        onCancel() { },
      });
    };
    // Call api delete
    const handleClickDelete = async (id?: string) => {
      setLoading(true);
      if (id) {
        await wardPresenter.deleteWard(wardStore, id);
        if (wardStore.crudResponse.isSuccess) {
          message.success(wardStore.crudResponse.message);
          // Call list branch after change
          await getListWard(dataSearch);
        } else {
          message.error(wardStore.crudResponse.message);
        }
      }
      setLoading(false);
    };
    // Call api province
    const getListProvince = async () => {
      await commonMasterDataPresenter.getDropDownProvince(commonMasterDataStore);
      if (commonMasterDataStore.provinceDropdown.isSuccess) {
        setListProvince(commonMasterDataStore.provinceDropdown.data);
      }
    };
    // Call api district
    const getListDistrict = async (provinceId = '') => {
      await commonMasterDataPresenter.getDropDownDistrict(commonMasterDataStore, provinceId);
      if (commonMasterDataStore.districtDropdown.isSuccess) {
        setListDistrict(commonMasterDataStore.districtDropdown.data);
      }
    };

    useEffect(() => {
      (async () => {
        await Promise.all([getListProvince(), getListDistrict()]);
      })();
    }, []);

    // Call api list ward
    const getListWard = async (dataSearch: object) => {
      await wardPresenter.searchListWard(wardStore, dataSearch);
      if (wardStore.listWard.isSuccess) {
        setListWard(wardStore.listWard.data);
      }
    };

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const tempDataSearch = {
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
        ...values,
      };
      removeEmptyValues(tempDataSearch);
      // set pageIndex 1 when search form
      setDataSearch(tempDataSearch);

      await getListWard(tempDataSearch);
      setLoading(false);
      onCloseDrawer();
    };

    // initialValues of form search
    const initialValues = {};

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <div className="w-full">
        {/* ==================================== Breadcrumb ========================================= */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: 'District' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  onClick={() => {
                    navigate('create');
                  }}
                  type="primary"
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<Ward>
            columns={columns}
            handleResize={() => { }}
            rowKey="stt"
            size="small"
            bordered
            search={false}
            loading={loading}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const values = form.getFieldsValue();
              const data: any = await getListWard({
                ...values,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: wardStore.listWard.isSuccess,
              };
            }}
            dataSource={listWard}
            scroll={{ x: true }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              total: wardStore.listWard.paging?.recordsTotal,
              hideOnSinglePage: true,
            }}
            options={false}
          />
        </BoxContainer>

        {/* form tìm kiếm */}
        <SearchBox
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm column="col-1" initialValues={initialValues} form={form} onFinish={handleSearch}>
            {/* Tỉnh/thành phố */}
            <SearchItem label={t('masterdata.ward.province')} name={'provinceId'}>
              <CustomSelect
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Chọn Tỉnh/Thành phố"
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                options={listProvince.map((item) => {
                  return {
                    label: item.value,
                    value: item.key,
                  };
                })}
                onChange={async (value, option) => {
                  await getListDistrict(option?.key);

                  // Clear đi các field liên quan (VD: quận huyện, xã)
                  form.setFieldsValue({
                    districtId: undefined,
                  });
                }}
              />
            </SearchItem>
            {/* Tên quận huyện */}
            <SearchItem label={t('masterdata.ward.district')} name={'districtId'}>
              <CustomSelect
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Quận huyện"
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                options={listDistrict.map((item) => {
                  return {
                    label: item.value,
                    value: item.key,
                  };
                })}
              />
            </SearchItem>
            {/* Tên phường xã */}
            <SearchItem label={t('masterdata.ward.ward-name')} name={'wardName'}>
              <CustomInput />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default wardPage;
