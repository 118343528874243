/* eslint-disable react-hooks/exhaustive-deps */
import * as Icon from 'base/ui/components/Icons';

import { Form, InputNumber, Radio, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { commonMasterDataPresenter, commonMasterDataStore, districtPresenter, districtStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { SYSTEM_TITLE } from 'utils/constants';
import { TypeCommonDistrict } from 'pages/rawData/Permission/model';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

const createAddUpdateDistrict = () => {
  return observer(() => {
    // Get data params from url
    const { id: idParams } = useParams();
    // translate en/vn
    const { t } = useTranslation();

    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }

    const navigate = useNavigate();
    // Check button save and save continue
    let isSaveAndContinue = false;
    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);

    // =================================================================================================
    //==========================FUNCTION===================//
    // Call api detail store
    const getDetailDistrict = async (id: string) => {
      await districtPresenter.getDistrictById(districtStore, id);
      if (districtStore.detailDistrict.isSuccess) {
        form.setFieldsValue(districtStore.detailDistrict.data);
        setLoading(false);
      } else {
        navigate('/MasterData/Province');
      }
    };
    // Handle create
    const onFinish = async (values: any) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        // create district
        await districtPresenter.createDistrict(districtStore, values);
        if (districtStore.crudResponse.isSuccess) {
          message.success(districtStore.crudResponse.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/District');
          }
          form.resetFields();
        }
      } else {
        // update district
        await districtPresenter.updateDistrict(districtStore, {
          ...values,
          districtId: idParams,
        });
        if (districtStore.crudResponse.isSuccess) {
          message.success(districtStore.crudResponse.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/District');
          }
        }
      }
      setLoading(false);
    };

    const initialValues = {
      actived: true,
      appellation: 'Thành Phố',
    };

    useEffect(() => {
      (async () => {
        if (idParams) {
          await getDetailDistrict(idParams);
        }
        await commonMasterDataPresenter.getDropDownProvince(commonMasterDataStore);
      })();
    }, []);

    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }
    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('masterdata.district.title'),
                link: '/MasterData/District',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/District/${idParams}` : '/MasterData/District/create',
              },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                  {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                </div>
              </>
            }
          />
          <BoxContainer fullHeight>
            {/* Form create/update */}
            <Spin spinning={loading}>
              <SearchForm onFinish={onFinish} initialValues={idParams ? {} : initialValues} form={form}>
                {/* tỉnh/thành phố */}
                <SearchItem label={t('masterdata.district.province')} name={'provinceId'}>
                  <CustomSelect
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    options={commonMasterDataStore.provinceDropdown.data.map((item) => {
                      return {
                        label: item.value,
                        value: item.key,
                      };
                    })}
                  />
                </SearchItem>
                {/* mã quận huyện */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mã quận huyện' }]}
                  name={'districtCode'}
                  label={t('masterdata.district.district-code')}
                >
                  <CustomInput />
                </SearchItem>
                {/* quận huyện */}
                <SearchItem name={'appellation'} label={t('masterdata.district.title')}>
                  <CustomSelect options={TypeCommonDistrict} />
                </SearchItem>
                {/* tên quận huyện */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường tên quận huyện' }]}
                  name={'districtName'}
                  label={t('masterdata.district.district-name')}
                >
                  <CustomInput />
                </SearchItem>
                {/* thứ tự hiển thị */}
                <SearchItem name={'orderIndex'} label={t('masterdata.district.order-index')}>
                  <InputNumber className=" !w-full" controls={false} />
                </SearchItem>
                {/* trạng thái */}
                <SearchItem name={'actived'} label={t('masterdata.district.status')}>
                  <Radio.Group>
                    <Radio value={true}>{t('radio-btn.active')}</Radio>
                    <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                  </Radio.Group>
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateDistrict;
