import { Form, Input, message, Spin } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { clearUserSession, getDeviceId, getPlantCode, getUserName, getUserSession, setUserSession } from 'utils/localStorageHelper';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import { FaSave } from 'react-icons/fa';
import { IAccountServices } from 'services/RawData/account_services';
import { IoMdSave } from 'react-icons/io';
import PageWrapper from 'pages/Components/PageWrapper';
import { ROUTES } from 'base/routes/routes';
import { SYSTEM_TITLE } from 'utils/constants';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { userPresenter, userStore } from 'stores/root_store';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


const createChangeAfter90DaysPassword = (service: IAccountServices) => {
    return observer(() => {
        const [loading, setLoading] = useState(false);
        const [form] = Form.useForm();
        const navigate = useNavigate();
        const { t } = useTranslation();
        const validateConfirmPassword = (_: any, value: any) => {
            if (value && value !== form.getFieldValue('newPassword')) {
                return Promise.reject(new Error('Mật khẩu xác nhận không khớp!'));
            }
            return Promise.resolve();
        };

        const [newPasswordDirty, setNewPasswordDirty] = useState(false);
        const [confirmDirty, setConfirmDirty] = useState(false);

        // Khi bắt đầu nhập vào trường confirmPassword
        const handleConfirmChange = (e: any) => {
            const { value } = e.target;
            if (e.target.name === "confirm")
                setConfirmDirty(!!value); // Đánh dấu confirmDirty khi có nhập liệu
            else {
                setNewPasswordDirty(!!value);
            }
        };
        return (
            <Spin spinning={loading}>
                <div className='bg-gray-400 flex flex-col w-full h-full'>
                    <div className='w-full bg-white h-auto text-black font-bold text-lg p-3'>Đổi mật khẩu</div>

                    <div className='flex-grow h-full bg-[#DBDBDB]'>
                        <div className={`h-full pt-3`}>
                            <div
                                className={
                                    'bg-white p-[12px] flex flex-col gap-default rounded-[2px] w-full h-full'
                                }
                            >
                                <SearchForm
                                    initialValues={{ userName: getUserName() }}
                                    form={form}
                                    layout='horizontal'
                                    column='col-1'
                                    colon={false}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 16 }}
                                    onFinish={async (values) => {

                                        try {
                                            setLoading(true)
                                            const result = await service.resetPasswordAllow({ ...values, plantCode: getPlantCode(), isChangeDefaultPass: false });
                                            if (result && result.isSuccess) {
                                                message.success(result.message);
                                                // clearUserSession();
                                                await userPresenter.signOut(userStore, getDeviceId(), true);
                                                // updateUserSessionIsRequestPassword()
                                                setLoading(false);
                                                navigate(ROUTES.LOGIN.LINK)
                                            } else {
                                                message.error(result?.message);
                                                setLoading(false);
                                            }
                                        }
                                        catch (error) {
                                            message.error('Error while changing password');
                                            setLoading(false);
                                        }

                                    }}
                                >
                                    <SearchItem name="userName" label="Tên tài khoản" tooltip="Tài khoản dùng để đăng nhập">
                                        <Input disabled />
                                    </SearchItem>
                                    <SearchItem name='oldPassword' rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mật khẩu cũ' }]} label="Mật khẩu cũ">
                                        <Input.Password />
                                    </SearchItem>
                                    <SearchItem
                                        hasFeedback={newPasswordDirty}
                                        validateTrigger="onChange"
                                        normalize={(value) => value.trim()}
                                        rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mật khẩu mới' },
                                        {
                                            min: 8,
                                            message: 'Mật khẩu có độ dài tối thiểu 8 ký tự.',
                                        },
                                        {
                                            pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                            message: 'Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt.',
                                        },
                                        ]}
                                        name={'newPassword'}
                                        label={"Mật khẩu mới"}
                                    >
                                        <Input.Password className="rounded-none" name="newPassword" onChange={handleConfirmChange} />
                                    </SearchItem>
                                    <SearchItem
                                        hasFeedback={confirmDirty}
                                        validateTrigger="onChange"
                                        dependencies={['newPassword']}
                                        normalize={(value) => value.trim()}
                                        rules={[
                                            {
                                                required: confirmDirty, // Chỉ yêu cầu khi trường đã "dirty"
                                                message: t('message.please-check-password'),
                                            },
                                            {
                                                validator: validateConfirmPassword,
                                            },
                                        ]}
                                        name={'confirm'}
                                        label={"Xác nhận mật khẩu mới"}
                                    >
                                        <Input.Password className="rounded-none" name="confirm" onChange={handleConfirmChange} />
                                    </SearchItem>

                                </SearchForm>
                                <CustomButton
                                    type="primary"
                                    className='m-auto justify-center items-center'
                                    htmlType="submit"
                                    onClick={() => {
                                        form.submit();

                                    }}
                                >
                                    Cập nhật
                                </CustomButton>
                            </div>
                        </div>
                    </div>

                </div>
            </Spin>
        );
    });
};

export default createChangeAfter90DaysPassword;
