import { RuleObject } from 'antd/es/form';


// Kiểm tra định dạng số điện thoại
export const validatePhone = (rule: any, value: string) => {
  const phoneRegex = /^[0-9]{8,11}$/;
  if (value) {
    if (!phoneRegex.test(value)) {
      return Promise.reject('Vui lòng nhập số với độ dài 8-11 ký tự');
    }
  }

  return Promise.resolve();
};
/**
 * Kiểm tra định dạng số điện thoại, nếu trong trường hợp người dùng nhập có dấu () hoặc dấu cách thì mặc kệ vẫn nhận (Xem task DGWWAR-160)
 * */
export const validatePhoneIgnoreSpecialCharacters = (rule: any, value: string) => {
  if (value) {
    // Loại bỏ tất cả các ký tự không phải là số
    const cleanedPhoneNumber = value.replace(/\D/g, '');

    // Kiểm tra độ dài của chuỗi số điện thoại
    if (cleanedPhoneNumber.length >= 8 && cleanedPhoneNumber.length <= 11) {
      return Promise.resolve();
    } else {
      return Promise.reject('Vui lòng nhập số với độ dài 8-11 ký tự');
    }
  }

  return Promise.resolve();
};

// Kiểm tra định dạng số điện thoại không có +84 và có 9 số
export const validatePhoneZalo = (rule: any, value: string) => {
  const phoneRegex = /^\d{9}$/;
  if (value) {
    if (!phoneRegex.test(value)) {
      return Promise.reject('Vui lòng nhập số với độ dài 9 ký tự');
    }
  }

  return Promise.resolve();
};

// Kiểm tra định dạng email
export const validateEmail = (rule: any, value: string) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  if (value) {
    if (!emailRegex.test(value)) {
      return Promise.reject('Email không hợp lệ');
    }
  }
  return Promise.resolve();
};

// Kiểm tra giá trị là số
export const validateNumber = (rule: any, value: any) => {
  if (value !== '' && typeof value === 'string') {
    const numberRegex = /^[0-9]+$/;
    if (!numberRegex.test(value)) {
      return Promise.reject('Vui lòng nhập số');
    }
  }

  return Promise.resolve();
};

// Kiểm tra giá trị là số, số không âm
export const validatePositiveNumber = (
  rule: RuleObject,
  value: any,
  callback: (error?: string | undefined) => void,
) => {
  if (isNaN(value)) {
    return Promise.reject('Vui lòng chỉ nhập số');
  } else if (value < 0) {
    return Promise.reject('Vui lòng không nhập số âm');
  } else {
    return Promise.resolve();
  }
};

// Kiểm tra giá trị là số hoặc chữ thì chấp nhận
export const validateUsername = (rule: RuleObject, value: any, callback: (error?: string | undefined) => void) => {
  const usernameRegex = /^[a-zA-Z0-9_]+$/;

  if (value && !usernameRegex.test(value)) {
    return Promise.reject('Tài khoản không được nhập ký tự đặc biệt!');
  } else {
    return Promise.resolve();
  }
};
// validate Số xe
export const validateLicensePlate = (rule: RuleObject, value: any, callback: (error?: string | undefined) => void) => {
  const usernameRegex = /^[a-zA-Z0-9]+$/;

  if (value && !usernameRegex.test(value)) {
    return Promise.reject('Số xe không được nhập ký tự đặc biệt!');
  } else {
    return Promise.resolve();
  }
};


