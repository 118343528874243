import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const branchRoutes = ({
  Department,
  AddDepartment,
  UpdateDepartment,
}: {
  Department: React.ComponentType;
  AddDepartment: React.ComponentType;
  UpdateDepartment: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Department />} />
    <Route path={ROUTES.MASTER_DATA.DEPARTMENT.CREATE} element={<AddDepartment />} />
    <Route path={ROUTES.MASTER_DATA.DEPARTMENT.EDIT} element={<UpdateDepartment />} />
  </Routes>
);
