import * as rootStore from 'stores/root_store';

import { CRYPTED_KEY, FIREBASE_USERS } from 'utils/constants';
import { Form, Input, Select, message } from 'antd';
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha
} from "react-simple-captcha";
import { TbLoaderQuarter, TbReload } from 'react-icons/tb';
import { decrypt, encrypt } from 'utils/cryptedHelper';
import { getCurrentLocation, getDeviceInfo } from 'utils/common';
import { getDatabase, ref, update } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { userPresenter, userStore } from 'stores/root_store';

import { CommonModel } from 'services/Main/common_services';
import CryptoJS from 'crypto-js';
import { IUserServices, User } from 'services/User/user_services';
import { ROUTES } from 'base/routes/routes';
import avnLogo from 'assets/images/logo.gif';
import { debounce } from 'utils/debounce';
import firebaseApp from '../../firebase/config';
import icrmLogo from 'assets/bg_logo.svg';
import { nanoid } from '@ant-design/pro-components';
import { observer } from 'mobx-react-lite';
import styles from './login.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGeolocationCoordinates from 'hooks/useGeolocationCoordinates';

interface FormLoginModel {
  userName: string;
  password: string;
  saleOrg: string;
  company: string;
}


export function createLogin(services: IUserServices) {
  return observer(() => {
    // Tên trang
    //=====================================CHUYỂN NGÔN NGỮ=====================================//
    const { t } = useTranslation();
    document.title = t('page-title.login');

    // key lấy password, user name từ mvc
    const key = CryptoJS.enc.Utf8.parse(CRYPTED_KEY);
    const [formLogin] = Form.useForm()

    // lấy tọa độ của vị trí hiện tại
    useGeolocationCoordinates();

    // ============= Lấy parameter đăng nhập ==================//
    // Nhớ thông tin đăng nhập
    const [isRemember, setIsRemember] = useState(false);

    const initCommon = [{ key: '', value: '-- Nhà máy --' }];
    const navigate = useNavigate();




    // danh sach organization
    const [listSaleOrg, setListSaleOrg] = useState<CommonModel[]>(initCommon);
    const [isLogAuthen, setIsLogAuthen] = useState(false);
    // loading đăng nhập
    const [loading, setLoading] = useState<boolean>(false);
    // loading company
    const [saleOrgLoading, setSaleOrgLoading] = useState(false)

    // lấy danh sách công ty và IsLogAuthen bằng username
    const getCompanyByName = async (userName: string) => {
      try {
        // khi nhập userName thì xóa saleOrg
        setSaleOrgLoading(true)
        formLogin.setFieldValue('saleOrg', '');
        setListSaleOrg(initCommon)
        // call api lấy seleorg và kiểm tra là đăng nhập bằng password hay otp
        const result = await services.getCompanyByUsername(userName)
        if (result && result.data) {
          setListSaleOrg([...initCommon, ...result.data.listCompany])
          setIsLogAuthen(result.data.isLogAuthen)
          if (result.data.listCompany.length) {
            formLogin.setFieldValue('saleOrg', result.data.listCompany[0].key)
            formLogin.validateFields()
          }
        } else {
          setIsLogAuthen(false)
        }
        setSaleOrgLoading(false)
      } catch (error) {
        setIsLogAuthen(false)
      }
    }
    const debounceCompany = useCallback(debounce(getCompanyByName, 300), [])

    // ================== Submit login form =================
    const onLogin = async (value: FormLoginModel) => {
      const { password, userName, saleOrg } = value;
      setLoading(true);

      const checkCaptcha = doSubmit();

      rootStore.uiPresenter.setLanguage(rootStore.uiStore, 'vi');
      const deviceId = nanoid();

      // kiểm tra captcha hợp lệ mới call api login -> login được thì vô màn trang chủ
      if (checkCaptcha) {
        await userPresenter.login(userStore, {
          userName,
          password,
          saleOrg,
          // dev thì lấy mặc định tiếng việt
          language: 'vi',
          deviceId: deviceId,
          location: formLogin.getFieldValue('location'),
          deviceName: formLogin.getFieldValue('deviceName')
        });
        if (userStore.userRespone.isSuccess) {
          // Kiểm tra xem user có tick "Nhớ thông tin đăng nhập" không?
          if (isRemember) {
            // Nếu có thì lưu username và password vào localStorage và tự động móc ra lần sau
            localStorage.setItem('username', encrypt(userName));
            localStorage.setItem('password', encrypt(password));
            localStorage.setItem('saleOrg', encrypt(saleOrg));
          } else {
            // Không thì cũng phải clear hết localStorage
            localStorage.removeItem('username');
            localStorage.removeItem('password');
            localStorage.removeItem('saleOrg');
          }

          const userData = userStore.userRespone.data!;
          userPresenter.setCurrentUser(userStore, userData.refreshToken || null);
          saveFirebase(userData.userName || '', userData.refreshToken || '');
          localStorage.setItem('deviceId', JSON.stringify(deviceId));
          // window.location.reload()
          // if (userStore.userRespone.data?.isAuthenticate === false) {
          //   navigate('/verify');
          // } else {
          //   const userData = userStore.userRespone.data!;
          //   userPresenter.setCurrentUser(userStore, userData.refreshToken || null);
          //   saveFirebase(userData.userName || '', userData.refreshToken || '');
          // }
          if (userStore.userRespone.data?.isResetPassword) {
            navigate(ROUTES.CHANGE_PASSWORD_AFTER_90DAYS.LINK);

          } else {
            navigate(ROUTES.HOME);
          }
        } else {
          message.error(userStore.userRespone.message);
          getLocation();
        }
      }
      //navigate(ROUTES.CHANGE_PASSWORD_AFTER_90DAYS.BASE);
      setLoading(false);
    };

    /**
   * Hàm xử lý đẩy thông tin user lên firebase để lưu trữ
   * @param username
   * @param refreshToken
   */
    const saveFirebase = (username: string, refreshToken: string) => {
      const db = getDatabase(firebaseApp);
      const todoRef = ref(db, `/${FIREBASE_USERS || 'mes-dev-users'}/` + username);

      const todo = {
        refreshToken: refreshToken,
      };
      update(todoRef, todo).catch((e) => console.log(e));
    };

    const getLocation = async (): Promise<void> => {
      try {
        // lấy vị trí hiện tại 
        const location = await getCurrentLocation();
        formLogin.setFieldValue('location', location);
      } catch (error) {
        console.error('Error getting location:', error);
      }
    }

    async function getCurrentPosition(): Promise<void> {
      const bdcApiBase = "https://api.bigdatacloud.net/data/reverse-geocode-client";

      try {
        const position: GeolocationPosition = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          });
        });

        const bdcApi = `${bdcApiBase}?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`;
        await getApi(bdcApi);

      } catch (error) {
        await getApi(bdcApiBase);
      }
    }

    async function getApi(bdcApi: string): Promise<void> {
      const Http = new XMLHttpRequest();
      Http.open("GET", bdcApi);
      Http.send();

      const resultElement = document.getElementById('result');

      return new Promise((resolve, reject) => {
        Http.onreadystatechange = function () {
          if (this.readyState === 4) {
            if (this.status === 200) {
              if (resultElement) {
                resultElement.innerHTML = this.responseText;
              }
              resolve();
            } else {
              reject(new Error(`Request failed with status ${this.status}`));
            }
          }
        };
      });
    }


    useEffect(() => {
      const fetchLocationAndRememberInfo = async () => {
        await getCurrentPosition();
        getLocation();
        const deviceInfo = getDeviceInfo();
        formLogin.setFieldValue('deviceName', deviceInfo);

        // Remember user information
        const getRememberInfo = async () => {
          const username = localStorage.getItem('username');
          const password = localStorage.getItem('password');
          const saleOrg = localStorage.getItem('saleOrg');

          // If info is found, set it into the form
          if (username && password && saleOrg) {
            const userNameDecrypt = decrypt(username);

            setIsRemember(true);
            formLogin.setFieldsValue({
              userName: userNameDecrypt,
              password: decrypt(password),
              saleOrg: decrypt(saleOrg),
            });
            await getCompanyByName(userNameDecrypt);
          } else {
            // If not, clear localStorage
            localStorage.removeItem('username');
            localStorage.removeItem('password');
            localStorage.removeItem('saleOrg');
          }
        };

        // Initialize captcha
        loadCaptchaEnginge(6);

        // Get remember info
        await getRememberInfo();
      };

      fetchLocationAndRememberInfo();
    }, []);

    // Hàm giải mã user passwword từ mvc
    const decrypted = (data: any) => {
      const decryptedData = CryptoJS.AES.decrypt(data, key, {
        keySize: 256 / 8,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return CryptoJS.enc.Utf8.stringify(decryptedData);
    };


    const doSubmit = () => {
      let user_captcha = formLogin.getFieldValue('captcha')
      if (validateCaptcha(user_captcha) === false) {
        // message.success('Captcha trùng khớp');
        // loadCaptchaEnginge(6);
        // formLogin.resetFields(['captcha'])
        // return true
        message.error('Captcha không trùng khớp');
        formLogin.resetFields(['captcha'])
        return false
      } else {
        loadCaptchaEnginge(6);
        formLogin.resetFields(['captcha'])
        return true;
      }
    };

    return (
      <div className={styles.wrapper}>

        <div className={`bg-[#1F2D47] flex ${styles.containerWidth}`}>
          <div className={`relative w-1/2 hidden xl:flex md:flex sm:hiden flex-col justify-center items-center p-5 h-screen`}>
            <div className="flex justify-center h-full w-[85%] bg-[#374661] rounded-[10px]">
              <img src={icrmLogo} className="h-full" />
              <img src={avnLogo} className="h-[45px] absolute top-10 left-24" alt="" />
              {/* <img src={citekLogo} className="h-[30px] absolute top-10 right-24" alt="" /> */}
            </div>
          </div>
          <div className="xl:w-1/2 md:w-1/2 w-full p-[5%] sm:p-[10%]">
            <div className="w-full flex justify-between mb-6 items-end"></div>
            <div>
              {/* <img src={vnIcon} className="h-[25px] absolute top-[110px] sm:top-[38px] right-[85px]" /> */}
              {/* <img src={ukIcon} className="h-[25px] absolute top-[110px] sm:top-[38px] right-[30px]" /> */}
            </div>
            <div className="flex justify-center mb-2.5 sm:hidden">
              {/* <img src={logoICRM} className="h-[40px]" /> */}
            </div>

            <Form<FormLoginModel>
              form={formLogin}
              className='space-y-4'
              onFinish={(values) => {

                onLogin(values)

              }}
              initialValues={{
                saleOrg: ''
              }}
            >
              <h3 className="text-3xl font-bold text-white mb-8 text-center lg:text-left">Login system</h3>
              <div className="relative text-gray-500">
                <Form.Item<FormLoginModel> name={'userName'} rules={[{ required: true, message: 'Tài khoản không được để trống!' }]}>
                  <Input
                    className='!border-2 !border-gray-200 !bg-transparent !text-white !py-2 !pl-4 !placeholder-slate-400 !text-sm focus:!border-blue-300'
                    placeholder="Username"
                    onChange={(event) => {
                      if (event.target.value) {
                        debounceCompany(event.target.value)
                      }
                    }}
                  />
                </Form.Item>
              </div>

              <div className="relative text-gray-500">
                {/* đăng nhập lần đầu thì điền mật khẩu */}
                <Form.Item<FormLoginModel> name='password' rules={[{ required: true, message: 'Mật khẩu không được để trống!' }]}>
                  <Input.Password
                    className='!border-2 !border-gray-200 !bg-transparent [&>input]:!bg-transparent [&>input]:!text-white !py-2 !pl-4 [&>input]:!placeholder-slate-400 [&>input]:!text-sm
                       [&.ant-input-affix-wrapper-focused]:!border-blue-300 [&_.ant-input-password-icon_svg]:!w-4 [&_.ant-input-password-icon_svg]:!h-4 [&_.ant-input-password-icon]:!text-white'
                    placeholder="Password"
                  />
                </Form.Item>
                {/* mặc dịnh nhập mật khẩu */}
                {/* {isLogAuthen ? <Form.Item<FormLoginModel> name={'otp'}>
                  <Input
                    className='!border-2 !border-gray-200 !bg-transparent !text-white !py-2 !pl-4 !placeholder-slate-400 !text-sm focus:!border-blue-300'
                    placeholder="OTP"
                  />
                </Form.Item>
                  :
                  <Form.Item<FormLoginModel> name='password' rules={[{ required: true, message: 'Mật khẩu không được để trống!' }]}>
                    <Input.Password
                      className='!border-2 !border-gray-200 !bg-transparent [&>input]:!bg-transparent [&>input]:!text-white !py-2 !pl-4 [&>input]:!placeholder-slate-400 [&>input]:!text-sm
                       [&.ant-input-affix-wrapper-focused]:!border-blue-300 [&_.ant-input-password-icon_svg]:!w-4 [&_.ant-input-password-icon_svg]:!h-4 [&_.ant-input-password-icon]:!text-white'
                      placeholder="Password"
                    />
                  </Form.Item>
                } */}
                {/* <Form.Item<FormLoginModel> name='password' rules={[{ required: true, message: 'Mật khẩu không được để trống!' }]}>
                  <Input.Password
                    className='!border-2 !border-gray-200 !bg-transparent [&>input]:!bg-transparent [&>input]:!text-white !py-2 !pl-4 [&>input]:!placeholder-slate-400 [&>input]:!text-sm
                       [&.ant-input-affix-wrapper-focused]:!border-blue-300 [&_.ant-input-password-icon_svg]:!w-4 [&_.ant-input-password-icon_svg]:!h-4 [&_.ant-input-password-icon]:!text-white'
                    placeholder="Password"
                  />
                </Form.Item> */}
              </div>
              {/* Nhà máy */}
              <div className="text-gray-500 relative">
                <Form.Item<FormLoginModel> name='saleOrg' rules={[{ required: true, message: 'Vui lòng chọn Nhà máy.' }]}>
                  <Select
                    loading={saleOrgLoading}
                    options={listSaleOrg}
                    fieldNames={{ label: 'value', value: 'key' }}
                    className='!h-[40px] [&_.ant-select-selector]:!border-2 [&_.ant-select-selector]:!bg-transparent [&_.ant-select-selector]!py-2 [&:hover_.ant-select-selector]:!border-blue-300
                      [&.ant-select-focused_.ant-select-selector]:!border-blue-300 [&_.ant-select-selector]:!text-white [&_.ant-select-selector]:!text-sm [&_svg]:!fill-white'
                  />
                </Form.Item>
              </div>

              {/* CAPTCHA */}
              {/* <div className="w-full flex gap-4 justify-center items-center captcha-wrapper">
                <LoadCanvasTemplateNoReload />
                <TbReload onClick={() => {
                  loadCaptchaEnginge(6)
                }} className='text-2xl text-white cursor-pointer' />
              </div>
              <div className="relative text-gray-500">
                <Form.Item name={'captcha'}>
                  <Input
                    className='!border-2 !border-gray-200 !bg-transparent !text-white !py-2 !pl-4 !placeholder-slate-400 !text-sm focus:!border-blue-300'
                    placeholder="Type the word above"
                  />
                </Form.Item>
              </div> */}
              <div className="w-full flex gap-4 justify-center items-center captcha-wrapper">
                <div className="flex-1">
                  <Form.Item name={'captcha'} style={{ marginBottom: '0' }}>
                    <Input
                      className='captcha-input grow !border-2 !border-gray-200 !bg-transparent !text-white !py-2 !pl-4 !placeholder-slate-400 !text-sm focus:!border-blue-300'
                      placeholder="Type the capcha"
                    />
                  </Form.Item>
                </div>
                <LoadCanvasTemplateNoReload />
                <TbReload onClick={() => {
                  loadCaptchaEnginge(6)
                }} className='text-2xl text-white cursor-pointer' />
              </div>

              <div className="w-full flex items-center">
                <input
                  type="checkbox"
                  className={styles.checkBox}
                  id="btnRemember"
                  onChange={() => setIsRemember(!isRemember)}
                  checked={isRemember}
                />
                <label htmlFor="btnRemember">
                  <h3 className="text-white text-sm font-medium ml-2 p-0 mb-0 cursor-pointer select-none">
                    Remember me
                  </h3>
                </label>
              </div>
              <button
                type="submit"
                disabled={loading}
                className={`w-full text-white font-medium rounded-sm text-sm px-5 py-2.5 mt-[30px] text-center focus:outline-none h-[40px] ${loading ? '!bg-[#E3EDF6]' : '!bg-[#E3EDF6] hover:bg-[#E3EDF6]/75'
                  }`}
              >
                {loading ? (
                  <span className="text-[#354A5F] font-bold flex items-center justify-center">
                    <TbLoaderQuarter className="animate-spin bg mr-2" />
                    {t('btn.login')}...
                  </span>
                ) : (
                  <span className="text-[#354A5F] font-bold">{t('btn.login')}</span>
                )}
              </button>
            </Form>
          </div>
        </div>
      </div>
    );
  });
}
