import { Navigate, Route, useNavigate } from 'react-router-dom'

import { clearUserSession, getDeviceId, getIsResetPassword } from 'utils/localStorageHelper'
import { userPresenter, userStore } from 'stores/root_store'
import { ROUTES } from 'base/routes/routes'
import { useEffect, useMemo } from 'react'

type CustomeRouteProps = {
    isAuthenticated: boolean
    children: JSX.Element
}
// return isAuthenticated && !getIsResetPassword() ? <Navigate to={'/'} /> : <>{children}</>
const PublicRoute = ({ children, isAuthenticated }: CustomeRouteProps) => {
    return isAuthenticated && !getIsResetPassword() ? <Navigate to={'/'} /> : <>{children}</>
}
const PrivateRoute = ({ children, isAuthenticated }: CustomeRouteProps) => {
    const navigate = useNavigate();
    // const isResetPasswordRequired = userStore.userRespone?.data?.isResetPassword;

    // useEffect(() => {
    //     if (!isAuthenticated)
    //         navigate(ROUTES.LOGIN.LINK)

    // }, [isAuthenticated, navigate])

    const isResetPasswordRequired = useMemo(() => getIsResetPassword(), []);
    const currentPath = window.location.pathname;



    useEffect(() => {
        if (isResetPasswordRequired && currentPath !== ROUTES.CHANGE_PASSWORD_AFTER_90DAYS.LINK) {
            // userPresenter.signOut(userStore, getDeviceId(), true)
            navigate(ROUTES.LOGIN.LINK);
        }
    }, [isResetPasswordRequired, navigate, currentPath]);

    return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
}

export { PrivateRoute, PublicRoute }