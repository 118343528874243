import { ProColumns } from "@ant-design/pro-components";
import BoxContainer from "base/ui/components/BoxContainer";
import CustomProTable from "base/ui/components/CustomTable";
import { observer } from "mobx-react"
import PageWrapper from "pages/Components/PageWrapper";
import { SYSTEM_TITLE } from "utils/constants";
import CustomButton from 'base/ui/components/Button';
import { usePermission } from "hooks/usePermission";

type Connection = {
    stt: number;
    createTime: string;
    expireTime: string;
}

const createConnectMS = () => {
    return observer(() => {
        const title = 'Connect Microsoft Account'
        document.title = title + ' - ' + SYSTEM_TITLE;
        usePermission('INDEX')
        const defaultColumn: ProColumns<Connection>[] = [
            // stt
            {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                width: '20%',
                align: 'center',
                fixed: 'left',
            },
            // Create time
            {
                title: "Create time",
                dataIndex: 'createTime',
                key: 'createTime',
                width: '40%',
                align: 'center',
                valueType: 'dateTime',
                fieldProps: {
                    format: 'DD/MM/YYYY HH:mm:ss',
                },
            },
            // Expire time
            {
                title: 'Expire time',
                dataIndex: 'expireTime',
                key: 'expireTime',
                width: '40%',
                align: 'center',
                valueType: 'dateTime',
                fieldProps: {
                    format: 'DD/MM/YYYY HH:mm:ss',
                },
            },

        ];
        return (
            <PageWrapper
                breadcrumb={[{
                    text: title
                }]}
                extras={<CustomButton
                    type="primary"
                >
                    Connect
                </CustomButton>}
            >
                <BoxContainer >
                    <CustomProTable<Connection>
                        columns={defaultColumn}
                        rowKey="stt"
                        size="small"
                        bordered
                        search={false}
                        dataSource={[]}
                        pagination={false}
                        options={false}
                    />
                </BoxContainer>

            </PageWrapper>
        )
    })
}

export default createConnectMS