import { StepProps, Steps, Tooltip, message } from 'antd'
import BoxContainer from 'base/ui/components/BoxContainer'
import { cloneDeep, findLastIndex } from 'lodash'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { IPurchaseOrderServices } from 'services/Main/purchaseOrder_services'
import truck from 'assets/images/truck.png';
import scale from 'assets/images/scale.png';
import board from 'assets/images/board.png'
import boxCheck from 'assets/images/boxCheck.png';
import { AiFillInfoCircle } from 'react-icons/ai'
import moment from 'moment'
import { CardBox } from './cardBox'

type TransitionStatusProps = {
    purchaseOrderServices: IPurchaseOrderServices
    deliveryRegistrationId?: string
}

export type TransitionStatusRef = {
    reload: () => void
}


const TransitionStatus: React.ForwardRefRenderFunction<TransitionStatusRef, TransitionStatusProps> = (props, ref) => {
    const { purchaseOrderServices, deliveryRegistrationId } = props
    // trạng thái luồn duyệt
    const [listStatusTransition, setListStatusTransition] = useState<StepProps[]>([])
    // Trạng thái hiện tại của phiếu (vị trí)
    const [curentIndexStatus, setCurentIndexStatus] = useState(0)

    const getTransitionStatus = async () => {
        try {
            if (deliveryRegistrationId) {
                // call api chuyển trạng thái
                const result = await purchaseOrderServices.getStatusTranslation(deliveryRegistrationId)
                if (result.isSuccess) {
                    const dataClone = cloneDeep(result.data)
                    // Thêm icon
                    const items = dataClone.map(data => {
                        switch (data.taskStatusCode) {
                            case "CHECKIN":
                            case "CHECKOUT":
                            case "CHECKIN_KHO":
                                Object.assign(data, {
                                    icon:
                                        <div className={`${data.isApproved ? 'bg-[#069E15]' : 'bg-gray-400'} rounded-full w-12 h-12 flex justify-center items-center`}>
                                            <img src={truck} className="w-8" />
                                        </div>
                                })
                                break;
                            case "CANVAO_CAN":
                            case "CANVAO_KHO":
                            case "CANRA1":
                            case "CANRA2":
                                Object.assign(data, {
                                    icon:
                                        <div className={`${data.isApproved ? 'bg-[#069E15]' : 'bg-gray-400'} rounded-full w-12 h-12 flex justify-center items-center`}>
                                            <img src={scale} className="w-8" />
                                        </div>
                                })
                                break;
                            case "NHANHANG1":
                            case "NHANHANG2":
                                Object.assign(data, {
                                    icon:
                                        <div className={`${data.isApproved ? 'bg-[#069E15]' : 'bg-gray-400'} rounded-full w-12 h-12 flex justify-center items-center`}>
                                            <img src={boxCheck} className="w-8" />
                                        </div>
                                })
                                break;
                            case "TUCHOINH1":
                            case "TUCHOINH2":
                                Object.assign(data, {
                                    icon:
                                        <div className={`${data.isApproved ? 'bg-[#069E15]' : 'bg-gray-400'} rounded-full w-12 h-12 flex justify-center items-center`}>
                                            <img src={board} className="w-6" />
                                        </div>
                                })
                                break;
                            default:
                                break;
                        }
                        return {
                            // ...data,
                            title: <div className="relative">
                                <h3 className="font-bold text-sm">{data.title}</h3>
                                {data.note ? <Tooltip title={data.note}>
                                    <AiFillInfoCircle className="text-amber-500 text-sm absolute top-1/2 -translate-y-1/2 -right-5" />
                                </Tooltip> : ''}
                            </div>,
                            icon: data.icon,
                            description: <div>
                                <span className="block text-xs">{data.description}</span>
                                <span className="text-[10px]">{data.subTitle ? moment(data.subTitle).format("DD/MM/YYYY HH:mm:ss") : undefined}</span>
                            </div>,
                            // subTitle: undefined,
                            // subTitle: data.description
                        }
                    })
                    const currentIndex = findLastIndex(dataClone, ['isApproved', true])
                    setCurentIndexStatus(currentIndex)
                    setListStatusTransition(items)
                } else {
                    message.error(result.message)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getTransitionStatus()
    }, [deliveryRegistrationId]);

    useImperativeHandle(ref, () => ({
        reload: getTransitionStatus
    }))

    return (
        <CardBox >
            <Steps
                labelPlacement="vertical"
                current={curentIndexStatus}
                items={listStatusTransition}
                className="[&_.ant-steps-item-tail]:!pl-10 [&_.ant-steps-item-tail]:!top-[18px] 
                         [&_.ant-steps-item-tail::after]:!bg-transparent [&_.ant-steps-item-tail::after]:border [&_.ant-steps-item-tail::after]:border-gray-300
                         [&_.ant-steps-item-tail::after]:border-dashed [&_.ant-steps-item-finish_.ant-steps-item-tail::after]:border-gray-500 [&_.ant-steps-item-content]:!w-[118px]"
            />
        </CardBox>
    )
}

export default React.forwardRef(TransitionStatus)