import { BASE_API_AUTH } from 'utils/constants';
import axios, { AxiosError } from 'axios';
import { getAccessToken, getRefreshToken, getUserSession, setUserSession } from './localStorageHelper';
import { removeAuthHeader, setAuthHeader } from './common';
import { uiPresenter, uiStore } from 'stores/root_store';

import queryString from 'query-string';
import { message, Modal } from 'antd';

const controller = new AbortController();
const axiosClient = axios.create({
  // baseURL: BASE_API_AUTH,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
  signal: controller.signal
});

axiosClient.interceptors.request.use(async (config) => {
  Object.assign(config.headers ?? {}, {
    Authorization: `Bearer ${getAccessToken()}`,
  });
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const { response, config } = error;
    const status = response?.status;

    if (status !== 401) return Promise.reject(error);

    if (status === 401 && config.url === `${BASE_API_AUTH}/auth/refresh-token`) {
      return Promise.reject(error);
    }
    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    if (status === 401) {
      return await axiosClient
        .post(`${BASE_API_AUTH}/auth/refresh-token`, undefined,
          {
            params: {
              refreshToken: getRefreshToken(),
            },
          }
        )
        .then(({ data }) => {
          const userSession = getUserSession()
          const result = data.data;
          Object.assign(userSession, {
            refreshToken: result.refresh_token,
            token: result.access_token,
            expiredTime: result.expireTime
          })

          setUserSession(userSession);
          return axiosClient(
            Object.assign(config, {
              headers: {
                Authorization: `Bearer ${result.access_token}`,
              },
            }),
          );
        })
        .catch((error) => {
          if (!error?.response.data.isSuccess) {
            uiPresenter.setMessageRefreshError(uiStore, error?.response.data.message);
            uiPresenter.setOpenModalAuthorize(uiStore, true);

          }
          return Promise.reject(error);
        });
    }
  },
);

// Method GET
export const get = async (
  baseApi: string,
  url: string,
  params?: object,
  shouldSetAuthHeader = true,
  shouldRemoveAuthHeader = false,
) => {
  if (shouldSetAuthHeader) {
    setAuthHeader();
  }
  if (shouldRemoveAuthHeader) {
    removeAuthHeader();
  }

  return await axiosClient
    .get('/' + url, {
      baseURL: baseApi,
      params: {
        ...params,
      },
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 500) {
          // history.push('/500');
          // window.location.reload();
          return e.response;
        }
        return e.response;
      }
    });
};




// Method DELETE
export const deleted = async (
  baseApi: string,
  url: string,
  params?: object,
  shouldSetAuthHeader = true,
  shouldRemoveAuthHeader = false,
) => {
  if (shouldSetAuthHeader) {
    setAuthHeader();
  }
  if (shouldRemoveAuthHeader) {
    removeAuthHeader();
  }

  return await axiosClient.delete('/' + url, {
    baseURL: baseApi,
    params: {
      ...params,
    },
  }).catch(async (e) => {
    if (e.response.status !== 200) {
      // message.error(`${e.response.data.message}`, 3);
      return e.response
    }
    return e.response;
  });;
};

// download file (PUT)
export const downloadFile = async (
  url: string,
  // params : string,
  shouldSetAuthHeader = true,
  shouldRemoveAuthHeader = false,
) => {
  if (shouldSetAuthHeader) {
    setAuthHeader();
  }
  if (shouldRemoveAuthHeader) {
    removeAuthHeader();
  }
  await axios({
    url: BASE_API_AUTH + '/' + url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const fileName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};

// Method POST
export async function post<T = unknown>(
  baseApi: string,
  url: string,
  params: T,
  shouldSetAuthHeader = true,
  shouldRemoveAuthHeader = false,
) {
  if (shouldSetAuthHeader) {
    setAuthHeader();
  }
  if (shouldRemoveAuthHeader) {
    removeAuthHeader();
  }

  // axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
  return await axiosClient
    .post('/' + url, params, {
      baseURL: baseApi,
    })
    .catch(async (e) => {
      if (e.response) {
        // if (e.response.status !== 200) {
        //   // message.error(`${e.response.data.message}`, 3);
        //   return e.response.data
        // }
        return e.response
      }
      console.log(e);

    });
}
// download file (POST)
export async function downloadFilePost<T = unknown>(
  baseApi: string,
  url: string,
  params: T,
  shouldSetAuthHeader = true,
  shouldRemoveAuthHeader = false,
) {
  if (shouldSetAuthHeader) {
    setAuthHeader();
  }
  if (shouldRemoveAuthHeader) {
    removeAuthHeader();
  }
  await axios({
    url: '/' + url,
    method: 'POST',
    responseType: 'blob',
    data: params,
    baseURL: baseApi,
  })

    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = response.headers['content-disposition'].split(';')[1].replace('filename=', '').trim();
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((e) => {
      if (e.response) {
        if (e.response.status !== 200) {
          alert('Status: ' + e.response.status + '\n' + e.message);
        }
        return e;
      }
    });
}

// Method PUT
export async function put<T = unknown>(
  baseApi: string,
  url: string,
  params: T,
  shouldSetAuthHeader = true,
  shouldRemoveAuthHeader = false,
) {
  if (shouldSetAuthHeader) {
    setAuthHeader();
  }
  if (shouldRemoveAuthHeader) {
    removeAuthHeader();
  }
  return await axiosClient.put(baseApi + '/' + url, params).catch((e) => {
    if (e.response) {
      if (e.response.status !== 200) {
        /*
        Tạm thời chưa biết cách fix lỗi toast hiển thị một lần nên dùng message thay thế
        */
        // message.error(`${e.response.data.message}`, 5);
        // uiPresenter.setMessageSystemError(uiStore, true, e.response.status, e.response.data.message);
        // alert('Status: ' + e.response.status + '\n' + e.message + '\n' + e.response.data.message);
      }
      return e.response;
    }
  });
}
