import { LayoutHOC } from "base/ui/layout/layout";
import { purchaseOrderRoutes } from "./routes";
import { createPurchaseOrder } from "./ListPO";
import { createCreateUpdateVehicleRegistration } from "./ListPO/createUpdate/createUpdateVehicleRegist";
import { IServices } from "services/services";
import { createAddPurchaseOrder } from "./CreatePO";
import { createUpdatePurchaseOrder } from "./UpdatePO";
import { createCopyPurchaseOrder } from "./CreatePOCopy";

export const createPurchaseOrderPage = (layoutHOC: LayoutHOC, services: IServices) => {
    const ListPO = createPurchaseOrder()
    const CreateUpdate = createCreateUpdateVehicleRegistration(services.purchaseOrderServices)

    // Tạo kế hoạch nhận hàng
    const AddPOPage = createAddPurchaseOrder()
    const EditPOPage = createUpdatePurchaseOrder()
    const CopyPOPage = createCopyPurchaseOrder()
    const PurchaseOrderPage = layoutHOC(purchaseOrderRoutes);

    return () => (
        <PurchaseOrderPage
            ListPO={ListPO}
            AddPurchaseOrder={AddPOPage}
            VehicleRegistration={CreateUpdate}
            EditVehicleRegistration={CreateUpdate}
            EditPurchaseOrder={EditPOPage}
            CopyPurchaseOrder={CopyPOPage}
        />
    );
};