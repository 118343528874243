import { observable, runInAction } from 'mobx';
import { IReportSavedServices, ReportTemplate } from 'services/SaveRepoprtTemplete/reportSaved_servieces';
import { DataResponse } from 'services/response';

export type reportSavedStore = {
  saveResponse: DataResponse<null>;
  updateResponse: DataResponse<null>;
  deleteResponse: DataResponse<null>;
  listTemplateResponse: DataResponse<ReportTemplate[]>;
};

export interface IReportSavedPresenter {
  createStore(): reportSavedStore;
  saveReportTemplate(store: reportSavedStore, data: object): Promise<void>;
  updateReportTemplate(store: reportSavedStore, data: object): Promise<void>;
  getListReportTemplate(store: reportSavedStore, data: string): Promise<void>;
  deleteReportTemplate(store: reportSavedStore, data: string): Promise<void>;
}

export function createReportSavedPresenter({
  reportSavedServices,
}: {
  reportSavedServices: IReportSavedServices;
}): IReportSavedPresenter {
  return {
    createStore: (): reportSavedStore =>
      observable({
        saveResponse: {
          isSuccess: false,
          message: '',
          data: null,
        },
        updateResponse: {
          isSuccess: false,
          message: '',
          data: null,
        },
        deleteResponse: {
          isSuccess: false,
          message: '',
          data: null,
        },
        listTemplateResponse: {
          isSuccess: false,
          message: '',
          data: [],
        },
      }),
    saveReportTemplate: async (store: reportSavedStore, data: object) => {
      try {
        const result = await reportSavedServices.saveReportTemplate(data);
        runInAction(() => {
          store.saveResponse.message = result.message;
          store.saveResponse.isSuccess = result.isSuccess;
        });
      } catch (error) {
        console.log(error);
      }
    },
    updateReportTemplate: async (store: reportSavedStore, data: object) => {
      try {
        const result = await reportSavedServices.updateReportTemplate(data);
        runInAction(() => {
          store.updateResponse.message = result.message;
          store.updateResponse.isSuccess = result.isSuccess;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getListReportTemplate: async (store: reportSavedStore, data: string) => {
      try {
        const result = await reportSavedServices.getListReportTemplate(data);
        runInAction(() => {
          store.listTemplateResponse.message = result.message;
          store.listTemplateResponse.isSuccess = result.isSuccess;
          store.listTemplateResponse.data = result.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    deleteReportTemplate: async (store: reportSavedStore, data: string) => {
      try {
        const result = await reportSavedServices.deleteReportTemplate(data);
        runInAction(() => {
          store.deleteResponse.message = result.message;
          store.deleteResponse.isSuccess = result.isSuccess;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
