import createDepartment from '.';
import createAddUpdateDepartment from './CreateUpdate';
import { branchRoutes } from './routes';

export const createDepartmentPage = () => {
  const Department = createDepartment();
  const AddDepartment = createAddUpdateDepartment();
  const UpdateDepartment = createAddUpdateDepartment();

  const DepartmentPage = branchRoutes;
  return () => (
    <DepartmentPage Department={Department} AddDepartment={AddDepartment} UpdateDepartment={UpdateDepartment} />
  );
};
