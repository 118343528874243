import { Timeline, TimelineProps, Image, message } from 'antd'
import BoxContainer from 'base/ui/components/BoxContainer'
import dayjs from 'dayjs'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { IPurchaseOrderServices } from 'services/Main/purchaseOrder_services'
import EmptyImage from 'assets/images/empty.jpg'
import { CardBox } from './cardBox'

type DeliveryRegistTimelineProps = {
    purchaseOrderServices: IPurchaseOrderServices
    deliveryRegistrationId?: string
}
export type DeliveryRegistTimelineRef = {
    reload: () => void
}

const DeliveryRegistTimeline: React.ForwardRefRenderFunction<DeliveryRegistTimelineRef, DeliveryRegistTimelineProps> = (props, ref) => {
    const { purchaseOrderServices, deliveryRegistrationId } = props
    // timeline 
    const [dataTimeline, setDataTimeline] = useState<TimelineProps['items']>([])

    // chi tiết timeline
    const getTimeline = async () => {
        try {
            if (deliveryRegistrationId) {
                const result = await purchaseOrderServices.getTimeline(deliveryRegistrationId)
                if (result.isSuccess) {
                    const items: TimelineProps['items'] = result.data.map(item => {
                        return {
                            label: item.time ?
                                <span className="text-default">
                                    <p>{(() => {
                                        const today = dayjs();
                                        const date = dayjs(item.time);
                                        const formattedDate = date.isSame(today, 'day') ? 'Hôm nay' : date.locale('vi').format('DD [tháng] M');
                                        return formattedDate
                                    })()}</p>
                                    <p>{dayjs(item.time).format('HH:mm:ss')}</p>
                                </span>
                                : undefined,
                            children: (
                                <div className="w-full h-full p-2 rounded bg-slate-400/5 px-4 min-h-[50px]">
                                    <div className="grid grid-cols-10 gap-4">
                                        <div className="col-span-8">
                                            <span className="w-full text-green-500 font-bold text-default">{item.title}</span>
                                            {item.description && <h3 className="font-bold">Ghi chú:</h3>}
                                            {item.description && <p>{item.description}</p>}
                                            {item.documentNumber && <p><strong>E-goods out (Số chứng từ ra cổng): </strong>{item.documentNumber}</p>}
                                        </div>
                                        <div className="col-span-2 flex gap-2">
                                            {item.images.length > 0 && <>
                                                {item.images.map((image, index) => (
                                                    <Image
                                                        key={index}
                                                        width={90}
                                                        height={70}
                                                        rootClassName="[&>img]:object-cover bg-white"
                                                        fallback={EmptyImage}
                                                        src={image} />
                                                ))}
                                            </>}
                                        </div>
                                    </div>
                                </div>)
                        }
                    })
                    setDataTimeline(items)
                } else {
                    message.error(result.message)
                }
            }
        } catch (error) { }
    }
    useEffect(() => {
        getTimeline()
    }, [deliveryRegistrationId]);

    useImperativeHandle(ref, () => ({
        reload: getTimeline
    }))

    return dataTimeline?.length ? (
        <CardBox>
            <Timeline mode="left" items={dataTimeline}
                rootClassName="!mt-3 [&_.ant-timeline-item]:!pb-[10px] [&_.ant-timeline-item.ant-timeline-item-last]:!pb-0
                         [&_.ant-timeline-item-label]:!w-[calc(10%-12px)] [&_.ant-timeline-item-content]:!w-[calc(90%-24px)]
                        [&_.ant-timeline-item-content]:!left-[calc(10%-4px)] [&_.ant-timeline-item-tail]:!left-[10%] [&_.ant-timeline-item-head]:!left-[10%]"
            />
        </CardBox>
    ) : <></>
}

export default React.forwardRef(DeliveryRegistTimeline)