import { useState } from 'react';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Link, useNavigate } from 'react-router-dom';
import { ChildMenu, User } from 'services/User/user_services';
import './searchBar.css';
import { useTranslation } from 'react-i18next';

type ParentMenu = {
  menuId: string;
  menuName: string;
};

const SearchBarInSideBar = () => {
  const { t } = useTranslation();
  const data = JSON.parse(localStorage.getItem('user_session') || '{}') as User;
  const childMenu = data.webPermission?.pageModel;
  const parentMenu = data.webPermission?.menuModel;
  const [selected, setSelected] = useState([]);
  const options: ChildMenu[] = childMenu || [];
  const parents: ParentMenu[] = parentMenu;
  const props: any = {};
  const navigate = useNavigate();

  switch (selected) {
    default:
      props.renderMenuItemChildren = (selectedItem: ChildMenu, { text }: any) => {
        return (
          <Link
            className="relative font-bold text-[12px]"
            to={`${selectedItem.pageUrl.startsWith('/') ? selectedItem.pageUrl : '/' + selectedItem.pageUrl}`}
            key={selectedItem.menuId}
          >
            <div className={'uppercase'}>
              <Highlighter search={text}>{selectedItem.pageName}</Highlighter>
            </div>
            <div>
              {parents.map((item, index) => {
                if (item.menuId === selectedItem.menuId)
                  return (
                    <span key={index} className="text-gray-500 font-medium">
                      {item.menuName}
                    </span>
                  );
              })}
            </div>
          </Link>
        );
      };
      break;
  }

  return (
    <>
      <Typeahead
        autoFocus
        {...props}
        className="z-[1] outline-none border-none rounded-sm !bg-third px-4 py-1 mt-[7px] mb-[13px] text-sm [&_.rbt-input-hint]:!text-gray-200/30"
        id="basic-example"
        labelKey="pageName"
        onChange={setSelected}
        options={options}
        placeholder={t('btn.search')}
        selected={selected}
        minLength={1}
        inputProps={{
          className:
            '!outline-none text-sm font-normal text-sidebar_item !bg-third rounded-sm leading-6 placeholder:text-sm placeholder:text-white/60',
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const inputValue = (e.target as HTMLInputElement).value;

            const selectedItem = options.find((item) => item.pageName === inputValue);

            if (selectedItem) {
              navigate(`${selectedItem.pageUrl.startsWith('/') ? selectedItem.pageUrl : '/' + selectedItem.pageUrl}`); // Điều hướng đến đường dẫn tương ứng domain fe
            }
          }
        }}
      />
    </>
  );
};
export default SearchBarInSideBar;
