import { MASTER_DATA_API_URL } from "utils/constants";
import { get, post } from '../../utils/api';
import { WebPermission } from "services/User/user_services";





// ============ Khai báo phương thức =====================
export interface ILanguageServices {
    translateLanguage(language: string, accountId: string): Promise<WebPermission>;

}
// Hàm khởi tạo các phương thức của NKMH service
export function createLanguageServices(): ILanguageServices {
    return {
        translateLanguage: async (language: string, accountId: string) => {
            const data = {
                accountId: accountId,
                language: language
            }
            return (await post(MASTER_DATA_API_URL, `TranslateLanguage`, data)).data;
        }
    };
}
