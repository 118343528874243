import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post } from 'utils/api';

export type District = Partial<{
  stt: string;
  districtId: string;
  provinceId: string;
  provinceName: string;
  districtCode: string;
  appellation: string;
  districtName: string;
  orderIndex: number;
  actived: boolean;
  isForeign: boolean;
}>;

export interface IDistrictServices {
  // Search district
  searchDropdownDistrict(provinceId: string, keyword?: string): Promise<DataResponse<Array<District>>>;
  // search list district
  searchListDistrict(data: object): Promise<DataResponse<Array<District>>>;
  // create list of district
  createDistrict(data: object): Promise<DataResponse<any>>;
  // update list of district
  updateDistrict(data: object): Promise<DataResponse<any>>;
  deleteDistrict(id: string): Promise<DataResponse<any>>;
  // get district by id
  getDistrictById(id: string): Promise<DataResponse<District>>;
}
export function createDistrictServices(): IDistrictServices {
  return {
    // Search district
    searchDropdownDistrict: async (provinceId, keyword) => {
      return (
        await get(
          MASTER_DATA_API_URL,
          `MasterData/District/list-by-provinceId?${provinceId ? `provinceId=${provinceId}` : ''}${
            keyword ? `keyword=${keyword}&` : ''
          }`,
        )
      ).data;
    },
    // Search list district
    searchListDistrict: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/District/search`, data)).data;
    },
    // create list of district
    createDistrict: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/District/create`, data)).data;
    },
    // update list of district
    updateDistrict: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/District/update`, data)).data;
    },
    // get district by id
    getDistrictById: async (DistrictId) => {
      return (
        await get(MASTER_DATA_API_URL, `MasterData/District/get-by-id?${DistrictId ? `DistrictId=${DistrictId}` : ''}`)
      ).data;
    },
    // delete district
    deleteDistrict: async (id) => {
      return (await deleted(MASTER_DATA_API_URL, `MasterData/District/delete`, { DistrictId: id })).data;
    },
  };
}
