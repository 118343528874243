type CardBoxProps = {
    title?: string,
    className?: string
    children?: React.ReactNode,
}
export const CardBox = (props: CardBoxProps) => {
    const { title, children, className } = props
    return (
        <div className={"shadow-box p-3 bg-white rounded " + className}>
            {title && <div className="border-b border-gray-300 px-5">
                <h2 className="uppercase font-bold text-sm mb-1">{title}</h2>
            </div>}
            <div className="pt-2 px-5">
                {children}
            </div>
        </div>
    )
}