import pagePage from ".";
import createAddUpdatePage from "./detail";
import { pageRoutes } from "./routes";

export const createPagePage = () => {
    const Search = pagePage()

    const Create = createAddUpdatePage()
    const Update = createAddUpdatePage()

    const Page = pageRoutes;
    return () => (
        <Page
            Search={Search} Create={Create} Update={Update} />
    );
};
