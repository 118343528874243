import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';
import { DataDepartment, IDepartmentServices } from 'services/RawData/department_services';

export type departmentStore = {
  // Type default response search
  searchDepartmentResponse: DataResponse<Array<DataDepartment>>;
  // Type default response get detail
  detailDepartmentResponse: DataResponse<DataDepartment | undefined>;
  // Type default response update detail
  updateDetailDepartmentResponse: DataResponse<boolean>;
  // Type default response create detail
  createDetailDepartmentResponse: DataResponse<boolean>;
  // Type default response delete
  deleteDepartmentResponse: DataResponse<boolean>;
};

export interface IDepartmentPresenter {
  createStore(): departmentStore;
  // Searchdepartment
  searchDepartment(store: departmentStore, data: object): Promise<void>;
  // Search detaildepartment
  getDetailDepartment(store: departmentStore, departmentId: string): Promise<void>;
  // Update detaildepartment
  updateDetailDepartment(store: departmentStore, params: object): Promise<void>;
  // Create detaildepartment
  createDetailDepartment(store: departmentStore, data: object): Promise<void>;
  // Delete department
  deleteDepartment(store: departmentStore, departmentId: string): Promise<void>;
}

export function createDepartmentPresenter({
  departmentServices,
}: {
  departmentServices: IDepartmentServices;
}): IDepartmentPresenter {
  return {
    createStore: () =>
      observable({
        searchDepartmentResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        detailDepartmentResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        updateDetailDepartmentResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
        createDetailDepartmentResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
        deleteDepartmentResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
      }),
    // Search list department
    searchDepartment: async (store, data) => {
      try {
        const result = await departmentServices.searchDepartment(data);
        runInAction(() => {
          store.searchDepartmentResponse.data = result.data;
          store.searchDepartmentResponse.isSuccess = result.isSuccess;
          store.searchDepartmentResponse.message = result.message;
          store.searchDepartmentResponse.paging = {
            totalCount: result.paging?.recordsTotal,
            totalPage: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Search detail department
    getDetailDepartment: async (store, departmentId) => {
      try {
        const result = await departmentServices.getDetailDepartment(departmentId);
        runInAction(() => {
          store.detailDepartmentResponse.data = result.data;
          store.detailDepartmentResponse.isSuccess = result.isSuccess;
          store.detailDepartmentResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Update detail department
    updateDetailDepartment: async (store, data) => {
      try {
        const result = await departmentServices.updateDetailDepartment(data);
        runInAction(() => {
          store.updateDetailDepartmentResponse.data = result.data;
          store.updateDetailDepartmentResponse.isSuccess = result.isSuccess;
          store.updateDetailDepartmentResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Create detail department
    createDetailDepartment: async (store, data) => {
      try {
        const result = await departmentServices.createDetailDepartment(data);
        runInAction(() => {
          store.createDetailDepartmentResponse.data = result.data;
          store.createDetailDepartmentResponse.isSuccess = result.isSuccess;
          store.createDetailDepartmentResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Delete department
    deleteDepartment: async (store, departmentId) => {
      try {
        const result = await departmentServices.deleteDepartment(departmentId);
        runInAction(() => {
          store.deleteDepartmentResponse.data = result.data;
          store.deleteDepartmentResponse.isSuccess = result.isSuccess;
          store.deleteDepartmentResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
