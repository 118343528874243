import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const MasterDataRoutes = ({
  CompanyPage,
  BranchPage,
  DepartmentPage,
  StockPage,
  CatalogTypePage,
  CatalogPage,
  SalesEmployeePage,
  ProvincePage,
  DistrictPage,
  WardPage,
  TaskPage,
  VendorPage,
  Driver,
  Product
}: {
  CompanyPage: React.ComponentType;
  BranchPage: React.ComponentType;
  DepartmentPage: React.ComponentType;
  StockPage: React.ComponentType;
  CatalogTypePage: React.ComponentType;
  CatalogPage: React.ComponentType;
  SalesEmployeePage: React.ComponentType;
  ProvincePage: React.ComponentType;
  DistrictPage: React.ComponentType;
  WardPage: React.ComponentType;
  TaskPage: React.ComponentType;
  VendorPage: React.ComponentType;
  Driver: React.ComponentType;
  Product: React.ComponentType;
}) => (
  <Routes>
    <Route path={ROUTES.MASTER_DATA.COMPANY.BASE} element={<CompanyPage />} />
    <Route path={ROUTES.MASTER_DATA.BRANCH.BASE} element={<BranchPage />} />
    <Route path={ROUTES.MASTER_DATA.DEPARTMENT.BASE} element={<DepartmentPage />} />
    <Route path={ROUTES.MASTER_DATA.STOCK.BASE} element={<StockPage />} />
    <Route path={ROUTES.MASTER_DATA.CATALOGTYPE.BASE} element={<CatalogTypePage />} />
    <Route path={ROUTES.MASTER_DATA.CATALOG.BASE} element={<CatalogPage />} />
    <Route path={ROUTES.MASTER_DATA.SALES_EMPLOYEE.BASE} element={<SalesEmployeePage />} />
    <Route path={ROUTES.MASTER_DATA.PROVINCE.BASE} element={<ProvincePage />} />
    <Route path={ROUTES.MASTER_DATA.DISTRICT.BASE} element={<DistrictPage />} />
    <Route path={ROUTES.MASTER_DATA.WARD.BASE} element={<WardPage />} />
    <Route path={ROUTES.MASTER_DATA.TASK.BASE} element={<TaskPage />} />
    {/* Nhà cung cấp */}
    <Route path={ROUTES.MASTER_DATA.VENDOR.BASE} element={<VendorPage />} />
    {/* Tài xế */}
    <Route path={ROUTES.MASTER_DATA.DRIVER.BASE} element={<Driver />} />
    {/* Thông tin hàng hóa */}
    <Route path={ROUTES.MASTER_DATA.PRODUCT.BASE} element={<Product />} />
  </Routes>
);
