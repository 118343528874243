import { BASE_API_URL, MASTER_DATA_API_URL } from "utils/constants";
import { deleted, downloadFilePost, get, post, put } from '../../utils/api';
import { getAccountId } from "utils/localStorageHelper";
import { DataResponse } from "services/response";
import { POCreatRequest, PurchaseOrderDetailModel, PurchaseOrderModel, TimelineModel, TransitionButtonModel, TransitionStatus } from "models/PurchaseOrder";
import { SearchModel } from "pages/model";
import { DeliveryRegistrationModel, HistoryEditModel, ReceiveScheduleModel } from "models/DeliveryRegistration";
import { PurchaseOrderSAPModel, PurchaseOrderSAPRequest } from "models/PurchaseOrderSAP";

// tìm kiếm ở màn danh sách PO
export type SearchPO = SearchModel & Partial<{
    purchaseOrderCode: string;
    purchaseOrderCodes: string[];
    prNumber: string;
    documentType: string;
    saleOrgCode: string;
    productCode: string;
    productName: string;
    vendorNumber: string;
    vendorName: string;
    documentDateFrom: string;
    documentDateTo: string
    createTimeFrom: string;
    createTimeTo: string
}>

// Tìm kiếm ở màn danh sách đang ký giao hàng
export type SearchDeliveryRegistration = SearchModel & Partial<{
    vendorNumber: string;
    vendorName: string;
    deliveryRegistrationCode: string;
    driverName: string;
    documentDateFrom: string;
    documentDateTo: string;
    productCode: string;
    productName: string;
}>

// Tìm kiếm ở màn lịch giao hàng
export type SearchReceiveSchedule = {
    searchDate: string;
    vendorNumber?: string;
    licensePlate?: string;
}
// Tìm kiếm màn đồng bộ PO
export type SearchSynchPO = SearchModel & Partial<{
    purchaseOrderCodeFrom: string;
    purchaseOrderCodeTo: string;
    prNumberFrom: string;
    prNumberTo: string;
    documentType: string;
    storeName: string;
    productCodeFrom: string;
    productCodeTo: string;
    productName: string;
    vendorNumberFrom: string;
    vendorNumberTo: string;
    vendorName: string;
    documentDateFrom: string;
    documentDateTo: string
}>

// tìm kiếm lịch sử chỉnh sửa
export type SearchHistory = SearchModel & {
    id: string;
}



// ============ Khai báo phương thức =====================
export interface IPurchaseOrderServices {
    // danh sách PO
    getListPurchaseOrder(data: SearchPO): Promise<DataResponse<PurchaseOrderModel[]>>;
    // Tạo phiếu đăng ký giao hàng
    createDeliveryRegistration(data: object): Promise<DataResponse<null>>
    // chi tiết phiếu đăng ký giao hàng
    getDetailDeliveryRegistration(deliveryRegistrationId: string): Promise<DataResponse<PurchaseOrderDetailModel | undefined>>
    // cập nhật phiếu đăng ký giao hàng
    updateDeliveryRegistration(data: object): Promise<DataResponse<null>>
    // danh sách đăng ký giao hàng
    getListDeliveryRegistration(data: SearchDeliveryRegistration): Promise<DataResponse<Array<DeliveryRegistrationModel>>>
    // get transition button
    getTransitionButton(deliveryRegistrationId: string): Promise<DataResponse<Array<TransitionButtonModel>>>
    // thực thi transition button
    executeTransitionButton(data: object): Promise<DataResponse<null>>
    // Danh sách xe ra vào cổng
    getListCheckinCheckout(data: SearchReceiveSchedule): Promise<DataResponse<ReceiveScheduleModel | null>>
    // Trạng thái luồng duyệt
    getStatusTranslation(taskId: string): Promise<DataResponse<TransitionStatus[]>>
    // Danh sách chi tiết timeline
    getTimeline(taskId: string): Promise<DataResponse<TimelineModel[]>>
    // hủy phiếu 
    cancelDeliveryRegistration(data: { cancelReason: string; deliveryRegistrationId: string }): Promise<DataResponse<null>>
    // export excel màn danh sách PO
    exportListPO(request: Omit<SearchPO, 'paging'>): Promise<void>
    // Cập nhật PO item
    updatePOItem(data: Pick<PurchaseOrderModel, 'purchaseOrderDetailId' | "note">): Promise<DataResponse>
    // Lịch sử chỉnh sửa
    getHistory(data: SearchHistory): Promise<DataResponse<HistoryEditModel[]>>
    // Tạo mới PO
    createPurchaseOrder(data: POCreatRequest): Promise<DataResponse>;
    // Cập nhật PO
    updatePurchaseOrder(data: POCreatRequest): Promise<DataResponse>;
    deletePurchaseOrder(id: string): Promise<DataResponse>;
    // Chi tiết PO
    getDetailsPurchaseOrder(id: string): Promise<DataResponse<POCreatRequest>>
    exportCreatePOTemplate(): Promise<void>;
    // --- ĐỒNG BỘ PO ----------------------------------------------------------------------------------------------------------------------------------
    // search PO SAP
    searchPOSAP(data: SearchSynchPO): Promise<DataResponse<Array<PurchaseOrderSAPModel>>>
    // Đồng bộ po
    synchPO(data: { purchaseOrders: PurchaseOrderSAPRequest[] }): Promise<DataResponse<null>>

}
// Hàm khởi tạo các phương nhà của NKMH service
export function createPurchaseOrderServices(): IPurchaseOrderServices {
    return {
        getListPurchaseOrder: async (data) => {
            return (await post(MASTER_DATA_API_URL, `purchaseorder/search`, data)).data;
        },
        // Tạo phiếu đăng ký giao hàng
        createDeliveryRegistration: async (data) => {
            return (await post(BASE_API_URL, `deliveryregistration/create`, data)).data;
        },
        // chi tiết phiếu đăng ký giao hàng
        getDetailDeliveryRegistration: async (deliveryRegistrationId) => {
            return (await get(BASE_API_URL, `deliveryregistration/${deliveryRegistrationId}`)).data;
        },
        // cập nhật phiếu đăng ký giao hàng
        updateDeliveryRegistration: async (data) => {
            return (await put(BASE_API_URL, `deliveryregistration/update`, data)).data;
        },
        // danh sách đăng ký giao hàng
        getListDeliveryRegistration: async (data) => {
            return (await post(BASE_API_URL, `deliveryregistration/search`, data)).data;
        },
        // get transition button
        getTransitionButton: async (deliveryRegistrationId) => {
            return (await post(BASE_API_URL, `taskstatus/get-transition-button`, { taskId: deliveryRegistrationId })).data;
        },
        // thực thi transition button
        executeTransitionButton: async (data) => {
            return (await post(BASE_API_URL, `taskstatus/execute-transition-button`, data)).data;
        },
        // Danh sách xe ra vào cổng
        getListCheckinCheckout: async (data) => {
            return (await post(BASE_API_URL, `deliveryregistration/list-check-in-out`, data)).data;
        },
        // search PO SAP
        searchPOSAP: async (data) => {
            return (await post(BASE_API_URL, `purchaseorder/search-po-sap`, data)).data;
        },
        // Đồng bộ po
        synchPO: async (data) => {
            return (await post(BASE_API_URL, `pointegration/integration`, data)).data;
        },
        // Trạng thái luồng duyệt
        getStatusTranslation: async (taskId) => {
            return (await post(BASE_API_URL, `taskstatus/get-status-transition?TaskId=${taskId}`, {})).data;
        },
        // Danh sách chi tiết timeline
        getTimeline: async (taskId) => {
            return (await get(BASE_API_URL, `taskstatus/get-timeline?TaskId=${taskId}`)).data;
        },
        // hủy phiếu 
        cancelDeliveryRegistration: async (data) => {
            return (await post(BASE_API_URL, `deliveryregistration/cancel`, data)).data;
        },
        // export excel màn danh sách PO
        exportListPO: async (request) => {
            return (await downloadFilePost(BASE_API_URL, `purchaseorder/export-list-po`, request));
        },
        // Cập nhật PO item
        updatePOItem: async (data) => {
            return (await put(BASE_API_URL, `purchaseorder/update-po-item`, data)).data;
        },
        // Lịch sử chỉnh sửa
        getHistory: async (data) => {
            return (await post(BASE_API_URL, `deliveryregistration/get-history`, data)).data;
        },
        exportCreatePOTemplate: async () => {
            await downloadFilePost(BASE_API_URL, `purchaseorder/export-create-po-template`, {})
        },
        // ============================================================================================
        // Tạo mới PO
        createPurchaseOrder: async (data) => {
            return (await post(BASE_API_URL, `purchaseorder/create`, data)).data;
        },
        // Cập nhật PO
        updatePurchaseOrder: async (data) => {
            return (await put(BASE_API_URL, `purchaseorder/update`, data)).data;
        },
        // xóa PO
        deletePurchaseOrder: async (id) => {
            return (await deleted(BASE_API_URL, `purchaseorder/cancel/${id}`)).data;
        },
        // Chi tiết PO
        getDetailsPurchaseOrder: async (id) => {
            return (await get(BASE_API_URL, `purchaseorder/${id}`)).data;
        },
        // ============================================================================================

    };
}
