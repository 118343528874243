import { get } from 'utils/api';
import { BASE_API_AUTH } from 'utils/constants';
import { DataResponse } from '../response';

export type DataUserConfig = {
  numberOfUser: number;
  isUserVOIPUser: boolean;
  numberOfVOIPUser: number;
  expireDate: string;
};

export interface IUserConfigServices {
  // Get user config list
  getUserConfigList(): Promise<DataResponse<Array<DataUserConfig>>>;
}

export function createUserConfigServices(): IUserConfigServices {
  return {
    // Get user config list
    getUserConfigList: async () => {
      return (await get(BASE_API_AUTH, `Permission/UserConfig/list`)).data;
    },
  };
}
