import { DeliveryInfoItem, PurchaseOrderDetailModel, PurchaseOrderModel, TransitionButtonModel } from "models/PurchaseOrder";
import DeliveryRegistTimeline, { DeliveryRegistTimelineRef } from "./DeliveryRegistTimeline";
import { EditableFormInstance, EditableProTable, ProColumns } from "@ant-design/pro-components";
import { Form, Input, InputNumber, Select, Spin, Tabs, Tag, message } from "antd";
import TransitionStatus, { TransitionStatusRef } from "./TransitionStatus";
import { cloneDeep, debounce } from "lodash";
import { formatDecimalNumber, formatNumber, sFormatNumber } from "utils/formatHelper";
import { getPlantCode, getRole } from "utils/localStorageHelper";
import { purchaseOrderPresenter, stockPresenter, stockStore, uiStore } from "stores/root_store";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import BoxContainer from "base/ui/components/BoxContainer";
import { CardBox } from "./cardBox";
import CustomButton from 'base/ui/components/Button';
import DeleteDeliveryConfirm from "../deleteDeliveryConfirm";
import DriverInformation from "./DriverInformation";
import { FaSave } from "react-icons/fa";
import GeneralInformation from "./GeneralInformation";
import { IPurchaseOrderServices } from "services/Main/purchaseOrder_services";
import PageWrapper from "pages/Components/PageWrapper"
import PopupConfirm from "./popupConfirm";
import { ROUTES } from "base/routes/routes";
import { SYSTEM_TITLE } from "utils/constants";
import { SearchForm } from "pages/Components/SearchBox";
import { TabHistory } from "./TabHistory";
import { Trash } from "base/ui/components/Icons";
import { checkPermissionWithPageUrl } from "utils/permissionHelper";
import { customTableClassName } from "base/ui/components/CustomTable";
import dayjs from "dayjs";
import { observer } from "mobx-react"

// image












export function createCreateUpdateVehicleRegistration(purchaseOrderServices: IPurchaseOrderServices) {
    return observer(() => {
        document.title = 'Purchase Order - ' + SYSTEM_TITLE;

        const role: string = getRole()
        // kiểm tra phân quyền 
        // Sửa thông tin tài xế
        const isEditVote = role === "PP" || role === "NCC"
        // Sửa phiếu
        const isBGA = role === "BGA"
        const isNCC = role === "NCC"
        const pagePermis = checkPermissionWithPageUrl(ROUTES.VEHICLE_REGISTRATION.LIST_REGISTRATION.LINK)
        const isCancel = pagePermis.includes('CANCEL')

        // lấy id từ url

        const { deliveryRegistrationId } = useParams();

        const [formCreate] = Form.useForm()
        const editableFormRef = useRef<EditableFormInstance>();
        // lấy data từ màn danh sách truyền qua
        const loaction = useLocation()
        const navigate = useNavigate()
        // data từ màn danh sách PO truyền qua
        const dataFromList: PurchaseOrderModel[] | undefined = loaction.state


        const [pageTitle, setPageTitle] = useState(deliveryRegistrationId ? "Cập nhật" : "Thêm mới phiếu đăng ký xe")
        // nếu không có data truyền qua thì quay lại trang danh sách 
        if (!dataFromList && !deliveryRegistrationId) {
            window.location.assign(ROUTES.PURCHASE_ORDER.LIST_PO.LINK)
            return <Spin spinning ><div className="sticky w-screen h-screen"></div></Spin>
        }
        // loading trang
        const [loading, setLoading] = useState(false)
        // form của table
        const [editTableForm] = Form.useForm()
        // chi tiết phiếu
        const [dataDetail, setDataDetail] = useState<PurchaseOrderDetailModel | undefined>(undefined)
        const [dataTable, setDataTable] = useState<DeliveryInfoItem[]>([])
        // tổng số lượng giao hàng
        const [totalDeliveredQty, setTotalDeliveryQty] = useState({ total: 0, itemsTotal: 0 })
        // cho phép chỉnh sửa hay không (đươc bấm nút lưu hay không)
        const [accessUpdated, setAccessUpdated] = useState(false)

        // mở modal delete
        const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
        // data delete 
        const [deleteLoading, setDeleteLoading] = useState(false)
        const [rowEdit, setRowEdit] = useState<Record<string, boolean>>({})
        const [isCheckedPolicyConfirm, setIsCheckedPolicyConfirm] = useState(false);

        // Hàm callback để nhận dữ liệu từ DriverInformation
        const handlePolicyConfirmChange = useCallback((checked: boolean) => {
            setIsCheckedPolicyConfirm(checked);
            // Logs updated value
        }, []);

        const columns: ProColumns<PurchaseOrderModel | DeliveryInfoItem>[] = [
            {
                title: 'STT',
                dataIndex: 'index',
                valueType: 'index',
                width: 50,
                fixed: 'left',
            },
            {
                title: "Số PO",
                key: "purchaseOrderCode",
                dataIndex: "purchaseOrderCode",
                width: 100,
                readonly: true,
                fixed: 'left',
                align: "center",
            },
            {
                title: "PO Item",
                key: "poItem",
                dataIndex: "poItem",
                width: 70,
                fixed: 'left',
                readonly: true,
            },
            {
                title: "Mã hàng hóa",
                key: "productCode",
                dataIndex: "productCode",
                width: 130,
                fixed: 'left',
                readonly: true,
            },
            {
                title: "Tên hàng hóa",
                key: "productName",
                dataIndex: "productName",
                width: 350,
                readonly: true,
            },
            {
                title: "Số lượng giao hàng",
                key: "deliveredQuantity",
                dataIndex: "deliveredQuantity",
                width: 150,
                onCell: () => ({
                    className: 'text-right !px-0'
                }),
                renderFormItem(schema, config, form) {
                    const defaultDeliveredQuantity = config.record?.openQuantitySAP || 0;

                    if (deliveryRegistrationId) {
                        if (accessUpdated && (isBGA || isNCC)) {
                            return rowEdit[`${config?.record?.purchaseOrderDetailId}`] ? (
                                <InputNumber<number> controls={false} size="small" style={{
                                    textAlignLast: 'right', width: '100%'
                                }}
                                    step={0.001}
                                    onBlur={() => {
                                        const myObject: { [key: string]: any } = editTableForm.getFieldsValue()
                                        const totalDeliveredQuantity: number = Object.values(myObject).reduce(
                                            (sum, item) => sum + (item?.deliveredQuantity || 0),
                                            0
                                        );
                                        setTotalDeliveryQty(prev => ({ total: totalDeliveredQuantity, itemsTotal: prev.itemsTotal }))
                                        formCreate.setFieldValue(['deliveryTimeItems', 0, 'deliveryQuantity'], totalDeliveredQuantity)

                                    }}
                                    autoFocus
                                    className="!bg-transparent"
                                    formatter={(value, info) => {
                                        if (value) {
                                            return sFormatNumber(Number(value)) as string;
                                        }
                                        return ''
                                    }}
                                    value={1234783}
                                />
                            ) : <InputNumber<number>
                                controls={false}
                                size="small"
                                style={{
                                    textAlignLast: 'right', width: '100%'
                                }}

                                className="!bg-transparent"
                                onFocus={() => {
                                    setRowEdit({ [`${config?.record?.purchaseOrderDetailId}`]: true })
                                }}
                                formatter={(value, info) => {
                                    if (value) {
                                        if (info.userTyping) {
                                            return sFormatNumber(Number(value)) as string;
                                        } else {
                                            return formatDecimalNumber(`${sFormatNumber(Number(value))}`)
                                        }
                                    } else return ''
                                }}
                                value={defaultDeliveredQuantity}
                            />
                        }

                        const { record } = config
                        return <div>{record?.deliveredQuantity !== undefined && record.deliveredQuantity !== null ? formatNumber(Number(record.deliveredQuantity), 3) : '-'}</div>
                    } else {
                        return rowEdit[`${config?.record?.purchaseOrderDetailId}`] ? (
                            <InputNumber<number> controls={false} size="small" style={{
                                textAlignLast: 'right', width: '100%'
                            }}
                                changeOnBlur
                                onBlur={() => {
                                    const myObject: { [key: string]: any } = editTableForm.getFieldsValue()
                                    const totalDeliveredQuantity: number = Object.values(myObject).reduce(
                                        (sum, item) => sum + (item?.deliveredQuantity || 0),
                                        0
                                    );
                                    setTotalDeliveryQty(prev => ({ total: totalDeliveredQuantity, itemsTotal: prev.itemsTotal }))
                                    formCreate.setFieldValue(['deliveryTimeItems', 0, 'deliveryQuantity'], totalDeliveredQuantity)
                                    setRowEdit({})
                                }}
                                className="!bg-transparent"
                                autoFocus
                                formatter={(value, info) => {
                                    if (value) {
                                        return sFormatNumber(Number(value)) as string;
                                    } else return ''
                                }}
                            />
                        ) : <InputNumber<number>
                            controls={false}
                            size="small"
                            style={{
                                textAlignLast: 'right', width: '100%'
                            }}
                            className="!bg-transparent"
                            onFocus={() => {
                                setRowEdit({ [`${config?.record?.purchaseOrderDetailId}`]: true })
                            }}
                            formatter={(value, info) => {
                                if (value) {
                                    if (info.userTyping) {
                                        return sFormatNumber(Number(value)) as string;
                                    } else {
                                        return formatDecimalNumber(`${sFormatNumber(Number(value))}`)
                                    }
                                } else return ''
                            }}
                        />

                    }
                },
                formItemProps(_, config) {
                    return {
                        rules: [() => ({
                            validator: (_, value) => {
                                if (!value || value === 0) {
                                    return Promise.reject('Vui lòng nhập số lượng giao hàng')
                                }
                                return Promise.resolve()
                            },
                        }),
                        () => ({
                            validator: (_, value) => {

                                if (!deliveryRegistrationId) {
                                    if (value > (config?.entity?.openQuantityWithTolerance || 0))
                                        return Promise.reject('Số lượng giao hàng không được lớn hơn số lượng kế hoạch.')
                                }
                                return Promise.resolve()
                            },
                            warningOnly: true
                        })
                        ],
                        className: `${deliveryRegistrationId && ((config?.entity?.deliveredQuantity || 0) > (config?.entity?.openQuantityWithTolerance || 0)) && "bg-yellow-400"} !px-2`
                    }
                },
            },
            {
                title: "SL đặt hàng",
                key: "totalQuantity",
                dataIndex: "totalQuantity",
                width: 150,
                onCell: () => ({
                    className: 'text-right'
                }),
                renderFormItem(_, config) {
                    if (config.record) {
                        const { record } = config
                        return <div>{record.totalQuantity !== undefined && record.totalQuantity !== null ? formatNumber(Number(record.totalQuantity), 3) : '-'}</div>
                    }
                    return <div>-</div>
                },
                readonly: true,
            },
            {
                title: "SL nhập kho",
                key: "receivedQuantity",
                dataIndex: "receivedQuantity",
                width: 150,
                onCell: () => ({
                    className: 'text-right'
                }),
                renderFormItem(_, config) {
                    if (config.record) {
                        const { record } = config
                        return <div>{record.receivedQuantity !== undefined && record.receivedQuantity !== null ? formatNumber(Number(record.receivedQuantity), 3) : '-'}</div>
                    }
                    return <div>-</div>
                },
                readonly: true,
            },
            {
                title: "SL kế hoạch",
                key: "openQuantitySAP",
                dataIndex: "openQuantitySAP",
                width: 130,
                onCell: () => ({
                    className: 'text-right'
                }),
                renderFormItem(_, config) {
                    if (config.record) {
                        const { record } = config
                        return <div>{record.openQuantitySAP !== undefined && record.openQuantitySAP !== null ? formatNumber(Number(record.openQuantitySAP), 3) : '-'}</div>
                    }
                    return <div>-</div>
                },
                readonly: true,
            },
            {
                title: "SL chưa đăng ký",
                key: "slChuaDK",
                dataIndex: "slChuaDK",
                width: 130,
                onCell: () => ({
                    className: 'text-right'
                }),
                hideInTable: deliveryRegistrationId ? true : false,
                renderFormItem(_, config) {
                    if (config.record) {
                        const { record } = config
                        return <div>{record.slChuaDK !== undefined && record.slChuaDK !== null ? formatNumber(Number(record.slChuaDK), 3) : '-'}</div>
                    }
                    return <div>-</div>
                },
                readonly: true,
            },

            {
                title: "SL nhận hàng thực tế",
                key: "inputQuantity",
                dataIndex: "inputQuantity",
                width: 160,
                onCell: () => ({
                    className: 'text-right'
                }),
                renderFormItem(_, config) {
                    if (config.record) {
                        const { record } = config
                        return <div>{record.inputQuantity !== undefined && record.inputQuantity !== null ? formatNumber(Number(record.inputQuantity), 3) : '-'}</div>
                    }
                    return <div>-</div>
                },
                readonly: true,
            },
            {
                title: "SL thực giao SAP",
                key: "RECEIPT_QTY",
                dataIndex: "RECEIPT_QTY",
                width: 160,
                onCell: () => ({
                    className: 'text-right'
                }),
                // renderFormItem(_, config) {
                //     if (config.record) {
                //         const { record } = config
                //         return <div>{record.RECEIPT_QTY !== undefined && record.RECEIPT_QTY !== null ? formatNumber(Number(record.RECEIPT_QTY), 3) : '-'}</div>
                //     }
                //     return <div>-</div>
                // },
                readonly: true,
            },
            {
                title: "ĐVT",
                key: "orderUnit",
                dataIndex: "orderUnit",
                width: 70,
                readonly: true,
            },
            {
                title: "Kho",
                key: "stockCode",
                dataIndex: "stockCode",
                width: 250,
                renderFormItem(_, config) {
                    if (deliveryRegistrationId) {
                        return <Select
                            disabled={!(role === "PP" && accessUpdated)}
                            size="small"
                            options={stockStore.searchAllStockResponse.data}
                            fieldNames={{ label: "value", value: "stockCode" }}
                        />
                    } else {
                        const { record } = config
                        return <div>{record?.stockName ? record?.stockName : '-'}</div>
                    }
                }
            },
            {
                title: "Ghi chú",
                key: "note",
                dataIndex: "note",
                width: 400,
                readonly: true,
            },
            {
                title: "Trạng thái nhận hàng",
                dataIndex: 'deliveryStatusName',
                key: 'deliveryStatusName',
                width: 150,
                hideInTable: !deliveryRegistrationId,
                hideInSetting: !deliveryRegistrationId,
                readonly: true,
                renderFormItem(_, config) {
                    const { record } = config
                    return <Tag color={getDeliveryStatusColor(record?.deliveryStatusName || '')} className="w-full !text-xs !text-center font-medium !py-0.5">{record?.deliveryStatusName}</Tag>

                }
            }
        ]
        const getDeliveryStatusColor = (status: string) => {
            switch (status) {
                case 'Nhận hàng': return 'success'
                case 'Từ chối nhận hàng': return 'error'
                default: return 'default'
            }
        }

        const handleSave = async (values: any) => {
            let phone = formCreate.getFieldValue('phone');
            let licenseNumber = formCreate.getFieldValue('licenseNumber');
            let period = formCreate.getFieldsValue();

            // True nếu người dùng có nhập vào thời gian giao hàng, false nếu là họ để trống
            const isUserEnteredTheTimeForDeveliery = !!period.deliveryTimeItems[0].time


            if (phone || licenseNumber) {
                if (!isCheckedPolicyConfirm) {
                    message.error('Vui lòng xác nhận cho phép được sử dụng thông tin tài xế.');
                    return;
                }
            }

            const dataCreate = values;
            // console.log({ dataCreate })
            // dữ liệu nhập trong bảng
            const dataEditTable: PurchaseOrderModel[] = editTableForm.getFieldsValue()
            // Thời gian giao hàng

            // const deliveryTimeItems = dataCreate?.deliveryTimeItems?.map((data: any) =>
            // ({
            //     deliveryQuantity: dataCreate?.deliveryTimeItems.length === 1 ? totalDeliveredQty.total : data?.deliveryQuantity,
            //     // deliveryTime: dayjs(data.deliveryTime).toISOString()
            //     deliveryTime: data.deliveryTime
            // }))
            const deliveryTimeItems = dataCreate?.deliveryTimeItems?.map((data: any) => {
                let deliveryTime = data.deliveryTime;

                // If isUserEnteredTheTimeForDeveliery is true, change deliveryTime to 00:00:00
                if (!isUserEnteredTheTimeForDeveliery) {
                    const dateOnly = dayjs(deliveryTime).format('YYYY-MM-DD'); // Extract the date
                    deliveryTime = `${dateOnly}T00:00:00`; // Set the time to 00:00:00
                }

                return {
                    deliveryQuantity: dataCreate?.deliveryTimeItems.length === 1 ? totalDeliveredQty.total : data?.deliveryQuantity,
                    deliveryTime: deliveryTime
                };
            });

            // thông tin po item trong bảng
            const deliveryInfoItems = dataFromList?.map((data, index) => (
                {
                    purchaseOrderDetailId: data.purchaseOrderDetailId,
                    deliveredQuantity: Object.values(dataEditTable).at(index)?.deliveredQuantity,
                    purchaseOrderCode: data.purchaseOrderCode,
                    stockCode: data.stockCode
                }))
            Object.assign(dataCreate, { deliveryTimeItems: deliveryTimeItems, deliveryInfoItems, plantCode: getPlantCode(), periodTime: period.deliveryTimeItems[0].periodTime, isTickCheckBox: isCheckedPolicyConfirm, isTime: isUserEnteredTheTimeForDeveliery })

            editTableForm.validateFields().then(async () => {
                setLoading(true)
                const result = await purchaseOrderPresenter.createDeliveryRegistration(dataCreate)
                setLoading(false)
                if (result) {
                    // AVNMR-71
                    // Khi tạo mới phiếu quay ra màn hình danh sách phiếu đăng ký, hiện tại quay lại màn danh sach PO
                    navigate(ROUTES.VEHICLE_REGISTRATION.LIST_REGISTRATION.LINK)
                }
            }).catch((err) => { })

            // dữ liệu từ form

        }

        // isHideMessage: ẩn Thông báo
        // isContinue: lưu hay lưu và tiếp tục
        // isTranferStatus: cập nhật khi bấm lưu hay cập nhật khi execute button
        const handleUpdate = async (values: any, option?:
            {
                isHideMessage?: boolean;
                isContinue?: boolean;
                isTranferStatus?: boolean;
                isReload?: boolean
            }) => {
            const { isReload = true } = option ?? {};
            // dữ liệu từ form
            const dataUpdate = cloneDeep(values);
            let period = formCreate.getFieldsValue();
            let phone = formCreate.getFieldValue('phone');
            let licenseNumber = formCreate.getFieldValue('licenseNumber');

            // True nếu người dùng có nhập vào thời gian giao hàng, false nếu là họ để trống
            const isUserEnteredTheTimeForDeveliery = !!period.deliveryTimeItems[0].time

            if (phone || licenseNumber) {
                if (!isCheckedPolicyConfirm) {
                    message.error('Vui lòng xác nhận cho phép được sử dụng thông tin tài xế.');
                    return false;
                }
            }

            // dữ liệu nhập trong bảng
            const dataEditTable: PurchaseOrderModel[] = editTableForm.getFieldsValue()
            // Thời gian giao hàng
            const deliveryTimeItems = values?.deliveryTimeItems?.map((data: any) => {
                let deliveryTime = data.deliveryTime;
                // If isUserEnteredTheTimeForDeveliery is true, change deliveryTime to 00:00:00
                if (!isUserEnteredTheTimeForDeveliery) {
                    const dateOnly = dayjs(deliveryTime).format('YYYY-MM-DD'); // Extract the date
                    deliveryTime = `${dateOnly}T00:00:00`; // Set the time to 00:00:00
                }

                return {
                    deliveryRegistrationTimeId: data.deliveryRegistrationTimeId,
                    deliveryQuantity: dataUpdate?.deliveryTimeItems.length === 1 ? totalDeliveredQty.total : data?.deliveryQuantity,
                    deliveryTime: deliveryTime,
                }
            })
            // thông tin po item trong bảng
            const deliveryInfoItems = dataDetail?.deliveryInfoItems.map((data, index) => ({
                purchaseOrderDetailId: data.purchaseOrderDetailId,
                // deliveredQuantity: data.deliveredQuantity,
                deliveredQuantity: Object.values(dataEditTable).at(index)?.deliveredQuantity,
                purchaseOrderCode: data.purchaseOrderCode,
                stockCode: Object.values(dataEditTable).at(index)?.stockCode
            }))

            Object.assign(dataUpdate, { deliveryTimeItems, deliveryInfoItems, deliveryRegistrationId, isTranferStatus: option?.isTranferStatus, plantCode: getPlantCode(), periodTime: period.deliveryTimeItems[0].periodTime, isTickCheckBox: isCheckedPolicyConfirm, isTime: isUserEnteredTheTimeForDeveliery })

            return editTableForm.validateFields().then(async () => {
                setLoading(true)
                const result = await purchaseOrderPresenter.updateDeliveryRegistration(dataUpdate, option?.isHideMessage)
                setLoading(false)
                return new Promise<boolean>(async (resolve, reject) => {
                    if (result) {
                        if (option?.isContinue) {
                            if (isReload) {
                                setLoading(true)
                                await Promise.allSettled([
                                    getDetailDeliveryRegistration(),
                                    // getTransitionStatus()
                                    transitionStatusRef.current?.reload()
                                ])
                                setLoading(false)
                            }
                        } else {
                            navigate('/Registration/VehicleRegistration')
                        }
                        return resolve(result)
                    }
                    else return reject(result)
                })

            }).catch((err) => {
                return Promise.reject(err)
            })
        }

        // chi tiết phiếu đăng ký theo id
        const getDetailDeliveryRegistration = async () => {
            // setLoading(true)
            const detail = await purchaseOrderPresenter.getDetailDeliveryRegistration(deliveryRegistrationId || '')
            // setLoading(false)
            if (detail) {
                setDataDetail(detail)
                setDataTable(detail?.deliveryInfoItems || [])
                const dataFormEdit = detail?.deliveryInfoItems.reduce((acc: Record<string, any>, cur) => {
                    acc[cur.purchaseOrderDetailId] = { ...cur }
                    return acc
                }, {})
                // set vào form của table
                editTableForm.setFieldsValue(dataFormEdit)
                //@ts-ignore
                const { vendorNumber, vendorName, driverName, phone, licenseNumber, licensePlate, result, isSendEmail, taskStatusName, periodTime, isTickCheckBox, isTime: isUserEnteredTheTimeForDeveliery } = detail
                // convert lại deliveryTimeItems để set vào Form
                const deliveryTimeItems = detail.deliveryTimeItems.length ?
                    detail.deliveryTimeItems.map(item => ({
                        ...item,
                        deliveryTime: item.deliveryTime,
                        day: item.deliveryTime && dayjs(item.deliveryTime),
                        time: isUserEnteredTheTimeForDeveliery && item.deliveryTime && dayjs(item.deliveryTime),
                        deliveryRegistrationTimeId: item.deliveryRegistrationTimeId,
                        periodTime: periodTime
                    }))
                    : ['']

                // set vào form thông tin chung và thông tin tài xế
                formCreate.setFieldsValue({ vendorNumber, vendorName, driverName, phone, licenseNumber, licensePlate, result, deliveryTimeItems, taskStatusName })

                // Ở MÀN HÌNH CHỈNH SỬA, KHÔNG SET GIÁ TRỊ CHECKBOX ISSENDEMAIL TỪ BACKEND TRẢ VỀ , MÀN HÌNH NÀY CÓ LOGIC RIÊNG ĐỂ SET GIÁ TRỊ NÀY
                // if (!deliveryRegistrationId) {
                //     formCreate.setFieldsValue({ isSendEmail })
                // }

                setIsCheckedPolicyConfirm(isTickCheckBox)
                // tính lại tổng số lượng giao hàng
                const totalDelivery = detail?.deliveryInfoItems.reduce(
                    (sum, item) => sum + (item?.deliveredQuantity || 0), 0) || 0;
                setTotalDeliveryQty({ total: totalDelivery, itemsTotal: 0 })
                // gắn lại page title
                setPageTitle(`Cập nhật phiếu đăng ký xe - ${detail.deliveryRegistrationCode}`)

                // AVNMR-22
                // Điều kiện sửa phiếu: trước khi check in (vừa tạo, từ chối vào cổng, chờ giao hàng)
                const { taskStatusCode } = detail
                const validStatusCodes = ['VT', 'TCVAOCONG', 'CGH'];
                if (validStatusCodes.includes(taskStatusCode)) {
                    setAccessUpdated(true)
                } else setAccessUpdated(false)
            }
        }
        // console.warn(dataFromList?.[0].vendorNumber)

        // danh sách kho
        const getListStock = async () => {
            if (!stockStore.searchAllStockResponse.data.length) {
                await stockPresenter.searchAllStock(stockStore, '')
            }
        }

        useEffect(() => {
            //set mặc định số lượng giao = sl kế hoạch

            if (deliveryRegistrationId) {
                setLoading(true)
                Promise.allSettled([
                    // Chi tiết phiếu
                    getDetailDeliveryRegistration(),
                    // danh sách button
                    getTransitionButton(),
                ]).then(() => {
                    setLoading(false)
                })
            } else if (dataFromList) {
                const documentDate = dataFromList?.at(0)?.documentDate
                formCreate.setFieldsValue({
                    vendorName: dataFromList?.at(0)?.vendorName,

                    vendorNumber: dataFromList?.at(0)?.vendorNumber,
                    deliveryTimeItems: [{
                        deliveryTime: `${dayjs(documentDate).format("YYYY-MM-DD")}T${dayjs().format("HH")}:00:00`,
                        day: documentDate && dayjs(documentDate),
                        //time: dayjs(),
                        periodTime: dataFromList?.at(0)?.periodTime,
                    }]
                })
            }
            getListStock()
        }, []);



        // =================================================================================================================================================================
        //    TRANSITION BUTTON
        // timeline
        const timelineRef = useRef<DeliveryRegistTimelineRef>(null)
        // transition status
        const transitionStatusRef = useRef<TransitionStatusRef>(null)
        // list buttons
        const [transitionBtns, setTransitionBtns] = useState<TransitionButtonModel[]>([])
        // mở popup transition buttons
        const [popupOpen, setPopupOpen] = useState(false)
        // lưu lại thông tin button vừa nhấn 
        const [selectedBtn, setSelectedBtn] = useState<TransitionButtonModel | TransitionButtonModel[] | undefined>(undefined)

        // danh sách transition button
        const getTransitionButton = async () => {
            try {
                if (deliveryRegistrationId) {
                    const result = await purchaseOrderServices.getTransitionButton(deliveryRegistrationId)
                    if (result.isSuccess) { setTransitionBtns(result.data) }
                }
            } catch (error) {
            }
        }
        // thực thi transition buttons
        const executeTransitionButton = async (statusTransitionId: string) => {
            try {
                if (deliveryRegistrationId) {
                    setLoading(true)
                    // call api chuyển trạng thái
                    const result = await purchaseOrderServices.executeTransitionButton({ taskId: deliveryRegistrationId, statusTransitionId: statusTransitionId })
                    if (result.isSuccess) {
                        message.success(result.message)
                        // nếu thanh công thì call api lấy danh sách btn và chi tiết phiếu
                        await Promise.allSettled([getTransitionButton(),
                        getDetailDeliveryRegistration(),
                        transitionStatusRef.current?.reload(),
                        timelineRef.current?.reload()
                        ])
                        setLoading(false)
                    } else {
                        message.error(result.message)
                    }
                }
            } catch (error) { }
        }

        // chia làm 2 role là: Chỉnh sửa (danh cho PP) được chỉnh sửa tất cả field và
        // Chỉnh sửa thông tin tài xế (dành cho bảo vệ) chỉ được sửa thông tin tài xế
        const disabledField = (isDriverInfor?: boolean) => {
            if (!deliveryRegistrationId) return false
            if ((isEditVote || isBGA) && accessUpdated) {
                if (isDriverInfor) return false
                // if (accessUpdated) return false
                if (isEditVote) return false
            }


            return true
        }

        return (
            <PageWrapper breadcrumb={[{
                text: pageTitle
            }
            ]}
                extras={
                    <>
                        {(() => {
                            // Check điều kiện trường hợp đặt biệt
                            // nếu là "Đã nhận hàng" và "Từ chối nhận hàng" 
                            // kiểm tra workflow để check điều kiện btn
                            const check = transitionBtns?.some((btn) => (btn.fromStatusCode === "CANVAO_KHO" && (btn.toStatusCode === "NHANHANG2" || btn.toStatusCode === "TUCHOINH2")) ||
                                (btn.fromStatusCode === "CANRA1" && (btn.toStatusCode === "NHANHANG1" || btn.toStatusCode === "TUCHOINH1")) ||
                                (btn.fromStatusCode === "CHECKIN_KHO" && (btn.toStatusCode === "NHANHANG1" || btn.toStatusCode === "TUCHOINH1")))
                            if (check) {
                                // nút thu hồi
                                const backBtn = transitionBtns?.find((btn) => btn.isBackBtn)
                                return (<>
                                    {backBtn && (
                                        <CustomButton
                                            type="primary"
                                            color={backBtn.color}
                                            style={{ color: backBtn.textColor }}
                                            loading={loading}
                                            onClick={() => {
                                                handleUpdate(formCreate.getFieldsValue(), { isHideMessage: true, isContinue: true, isTranferStatus: true }).then(() => {
                                                    executeTransitionButton(backBtn.statusTransitionId)
                                                })
                                            }}
                                        >
                                            {backBtn.statusTransitionName}
                                        </CustomButton>
                                    )}
                                    <CustomButton
                                        type="primary"
                                        color="#069E15"
                                        onClick={() => {
                                            setSelectedBtn(transitionBtns)
                                            setPopupOpen(true)
                                        }}
                                    >
                                        Xác nhận nhận hàng
                                    </CustomButton>
                                </>)
                            }
                            return transitionBtns?.map((btn, index) => (
                                <CustomButton
                                    type="primary"
                                    color={btn.color}
                                    style={{ color: btn.textColor }}
                                    loading={loading}
                                    key={btn.statusTransitionId}
                                    onClick={() => {
                                        const dataEditTable = editTableForm.getFieldsValue()
                                        // kiểm tra nếu kho rỗng thì hiện thông báo
                                        const check = Object.values(dataEditTable).some((value: any) => !value?.stockCode)
                                        if (check && btn.toStatusCode !== 'TCVAOCONG') {
                                            message.error("Vui lòng cập nhật kho trước khi vào cổng.")
                                        }
                                        else {
                                            // nếu trạng thái "Chờ giao hàng" thì không cần mở pupup
                                            if (btn.toStatusCode === 'CGH' || btn.isBackBtn) {
                                                handleUpdate(formCreate.getFieldsValue(), { isHideMessage: true, isContinue: true, isTranferStatus: true }).then(() => {
                                                    executeTransitionButton(btn.statusTransitionId)
                                                })
                                            }
                                            else {
                                                setSelectedBtn(btn)
                                                setPopupOpen(true)
                                            }
                                        }
                                    }}
                                >
                                    {btn.statusTransitionName}
                                </CustomButton>
                            ))
                        })()}
                        {/* Hủy phiếu */}
                        {isCancel && dataDetail?.isShowCancelButton && (
                            <CustomButton
                                color="#cd201f"
                                type="primary"
                                icon={<Trash className="!fill-white" width="14" height="14" />}
                                onClick={() => {
                                    setModalDeleteOpen(true)
                                }}
                            >
                                Hủy phiếu
                            </CustomButton>
                        )}
                        {!disabledField(true) && <>
                            <CustomButton loading={loading} onClick={() => {
                                formCreate.validateFields().then(values => {
                                    if (deliveryRegistrationId) {
                                        handleUpdate(values)
                                    } else {
                                        handleSave(values)
                                    }
                                }).catch(err => { })
                            }} type="primary">
                                <FaSave />
                                Lưu
                            </CustomButton>
                            {deliveryRegistrationId && <CustomButton loading={loading} onClick={() => {
                                formCreate.validateFields().then(values => {
                                    handleUpdate(values, { isContinue: true })
                                }).catch(err => { })
                            }} type="primary">
                                <FaSave />
                                Lưu và tiếp tục
                            </CustomButton>}
                        </>}
                    </>
                }
            >
                <BoxContainer className="!bg-transparent !p-0">
                    <Tabs
                        // tabBarStyle={{ display: "none" }}
                        tabBarStyle={{ display: !deliveryRegistrationId ? "none" : 'unset' }}
                        items={[
                            {
                                key: '1',
                                label: <strong>Thông tin chung</strong>,
                                children: (
                                    <Spin spinning={loading}>
                                        <div className="flex flex-col gap-default">
                                            {deliveryRegistrationId && (
                                                <TransitionStatus
                                                    ref={transitionStatusRef}
                                                    purchaseOrderServices={purchaseOrderServices}
                                                    deliveryRegistrationId={deliveryRegistrationId}
                                                />
                                            )}
                                            <SearchForm
                                                column="col-1" initialValues={{ deliveryTimeItems: [''] }} form={formCreate} >
                                                <div className="lg:grid lg:grid-cols-2 gap-default">
                                                    {/* ================= THÔNG TIN CHUNG ======================= */}
                                                    <GeneralInformation
                                                        vendorNumber={String(dataFromList?.[0].vendorNumber || dataDetail?.vendorNumber)}
                                                        accessUpdated={accessUpdated}
                                                        disabledField={disabledField}
                                                        formCreate={formCreate}
                                                        role={role}
                                                        setTotalDeliveryQty={setTotalDeliveryQty}
                                                        totalDeliveredQty={totalDeliveredQty}
                                                        dataFromList={dataFromList}
                                                        deliveryRegistrationId={deliveryRegistrationId}
                                                    />
                                                    {/* ======================= THÔNG TIN TÀI XẾ ======================================================= */}
                                                    <DriverInformation
                                                        disabledField={disabledField}
                                                        formCreate={formCreate}
                                                        isCheckedPolicyConfirm={isCheckedPolicyConfirm}
                                                        onPolicyConfirmChange={handlePolicyConfirmChange}
                                                    />

                                                </div>
                                            </SearchForm>
                                            {/* === THÔNG TIN GIAO HÀNG =============== */}

                                            <CardBox title="Thông tin giao hàng" className="col-span-2">
                                                <span className="text-red-500 italic">Vui lòng nhập số lượng giao hàng</span>
                                                <EditableProTable<PurchaseOrderModel | DeliveryInfoItem>
                                                    editableFormRef={editableFormRef}
                                                    columns={columns}
                                                    dataSource={deliveryRegistrationId ? dataTable : dataFromList}
                                                    value={deliveryRegistrationId ? dataTable : dataFromList?.map(item => ({ ...item, deliveredQuantity: item.openQuantitySAP }))}
                                                    pagination={false}
                                                    className={
                                                        `${uiStore.theme.name}` + customTableClassName
                                                    }
                                                    editable={{
                                                        form: editTableForm,
                                                        editableKeys: (() => {
                                                            if (deliveryRegistrationId) {
                                                                return dataTable ? dataTable.map(detail => detail.purchaseOrderDetailId) : undefined
                                                            } else if (dataFromList) {
                                                                return dataFromList ? dataFromList.map(i => i.purchaseOrderDetailId || 0) : undefined
                                                            }
                                                            return undefined
                                                        })(),

                                                    }}
                                                    scroll={{ x: 1500 }}
                                                    rowKey={'purchaseOrderDetailId'}
                                                    recordCreatorProps={false}
                                                    bordered
                                                />
                                            </CardBox>

                                            {/* TIMELINE */}
                                            {deliveryRegistrationId && <DeliveryRegistTimeline
                                                ref={timelineRef}
                                                purchaseOrderServices={purchaseOrderServices}
                                                deliveryRegistrationId={deliveryRegistrationId}
                                            />}
                                        </div>
                                    </Spin>
                                )
                            },
                            {
                                key: '2',
                                destroyInactiveTabPane: true,
                                label: <strong>Lịch sử sửa</strong>,
                                children: (
                                    <CardBox title="Lịch sử chỉnh sửa">
                                        <TabHistory />
                                    </CardBox>
                                )
                            }
                        ]} />
                </BoxContainer>

                {/* MODAL CHUYỂN TRẠNG THÁI */}
                <PopupConfirm
                    modalOpen={popupOpen}
                    handleUpdate={async () => {
                        return handleUpdate(formCreate.getFieldsValue(), { isHideMessage: true, isContinue: true, isTranferStatus: true, isReload: false })
                    }}
                    onModalOpen={setPopupOpen}
                    buttonInfo={selectedBtn}
                    taskId={deliveryRegistrationId + ''}
                    reload={async () => {
                        setLoading(true)
                        await Promise.allSettled([getDetailDeliveryRegistration(),
                        getTransitionButton(),
                        transitionStatusRef.current?.reload(),
                        timelineRef.current?.reload()
                        ])
                        setLoading(false)
                    }}
                    dataDetails={dataDetail}
                />
                {/* MODAL THU HỒI */}
                <DeleteDeliveryConfirm
                    open={modalDeleteOpen}
                    onOpenChange={(open) => {
                        setModalDeleteOpen(open);
                    }}
                    loading={deleteLoading}
                    dataDelete={dataDetail}
                    onFormSubmit={(value) => {
                        value.then(async (data) => {
                            setDeleteLoading(true);
                            const result = await purchaseOrderPresenter.cancelDeliveryRegistration(data)
                            setDeleteLoading(false);
                            if (result) {
                                navigate(ROUTES.VEHICLE_REGISTRATION.LIST_REGISTRATION.LINK)
                            }
                        }).catch((error) => {
                            console.log(error);
                        })
                    }}
                />
            </PageWrapper>
        )
    })
}


