import * as Icon from 'base/ui/components/Icons';

import { Form, MenuProps, Modal, Select, Tooltip, message } from 'antd';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  categoryPresenter,
  categoryStore,
  commonMasterDataPresenter,
  commonMasterDataStore,
  salesEmployeePresenter,
  salesEmployeeStore,
  userStore,
} from 'stores/root_store';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';

import { CopyOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-components';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomButton from 'base/ui/components/Button';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import CustomProTable from 'base/ui/components/CustomTable';
import { usePermission } from 'hooks/usePermission';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { HiSearch } from 'react-icons/hi';
import { SalesEmployee } from 'services/RawData/salesEmployee_services';
import { removeEmptyValues } from 'utils/transformHelper';
import { DataSearchSalesEmployee } from '../model';
import { Category } from 'services/RawData/Sales_services';
import { cloneDeep } from 'lodash';
import { SearchModel } from 'pages/model';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';

const { confirm } = Modal;

// Nhân viên
const salesEmployeePage = () => {
  return observer(() => {
    const { t } = useTranslation();

    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    const pagetitle = t('masterdata.sales-employee.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);
    // Columns table title
    const defaultColumn: ProColumns<SalesEmployee>[] = [
      // Stt
      {
        title: t('masterdata.sales-employee.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 40,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
      },
      // Mã nhân viên
      {
        title: t('masterdata.sales-employee.employee-code'),
        dataIndex: 'salesEmployeeCode',
        key: 'salesEmployeeCode',
        width: 60,
        className: '!rounded-none !py-1',
        fixed: 'left',
        ellipsis: true,
        render: (_, record) => {
          return userStore.userPermission?.includes('EDIT') ? (
            <Link to={`/MasterData/SalesEmployee/${record.salesEmployeeCode}`}>{record.salesEmployeeCode}</Link>
          ) : (
            <div>{record.salesEmployeeCode}</div>
          );
        },
      },
      // Họ và tên
      {
        title: t('masterdata.sales-employee.full-name'),
        dataIndex: 'salesEmployeeName',
        key: 'salesEmployeeName',
        width: 110,
        className: '!rounded-none !py-1',
        fixed: 'left',
        ellipsis: true,
      },
      // Tên ngắn
      {
        title: t('masterdata.sales-employee.short-name'),
        dataIndex: 'salesEmployeeShortName',
        key: 'salesEmployeeShortName',
        width: 100,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Điện thoại
      {
        title: t('masterdata.sales-employee.phone'),
        dataIndex: 'phone',
        key: 'phone',
        width: 80,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Email
      {
        title: t('masterdata.sales-employee.email'),
        dataIndex: 'email',
        key: 'email',
        width: 100,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Bộ phận
      {
        title: 'Bộ phận',
        dataIndex: 'departmentName',
        key: 'departmentName',
        width: 100,
        className: '!rounded-none !py-1',
        ellipsis: true,
      },
      // Group
      // {
      //   title: 'Group',
      //   dataIndex: 'group',
      //   key: 'group',
      //   width: 100,
      //   className: '!rounded-none !py-1',
      //   ellipsis: true,
      //   render: (_, record) => {
      //     return <div>{record.groupName.join(', ')}</div>;
      //   },
      // },
      // Brand
      // {
      //   title: 'Brand',
      //   dataIndex: 'brand',
      //   key: 'brand',
      //   width: 100,
      //   className: '!rounded-none !py-1',
      //   ellipsis: true,
      //   render: (_, record) => {
      //     return <div>{record.brandName.join(', ')}</div>;
      //   },
      // },
      // trạng thái
      {
        title: t('masterdata.sales-employee.status'),
        dataIndex: 'actived',
        width: 80,
        key: 'actived',
        ellipsis: true,
        align: 'center',
        render: (text, record) => (
          <div className="w-full flex justify-center">
            {record.actived ? (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            ) : (
              <Icon.CloseStatus width="15" className="!fill-red-500" />
            )}
          </div>
        ),
      },
      // Chức năng
      {
        title: t('masterdata.sales-employee.actions'),
        dataIndex: 'actions',
        key: 'actions',
        width: 60,
        className: '!rounded-none !py-1',
        align: 'center',
        fixed: 'right',
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') && (
                <Tooltip title={t('btn.edit')}>
                  <CustomButtonIcon color="#007BF1" onClick={() => navigate(`${record.salesEmployeeCode}`)}>
                    <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                  </CustomButtonIcon>
                </Tooltip>
              )}
              {userStore.userPermission?.includes('DELETE') && (
                <Tooltip title={t('btn.delete')}>
                  <CustomButtonIcon
                    color="#FC5C5E"
                    onClick={() => {
                      showDeleteConfirm(record);
                    }}
                    className={`${record.actived ? '!block' : '!invisible'}`}
                  >
                    <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                  </CustomButtonIcon>
                </Tooltip>
              )}
              {/* {userStore.userPermission?.includes('CREATE') && (
                <Tooltip title={t('btn.copy')}>
                  <CustomButtonIcon
                    color="#389e0d"
                    onClick={() => navigate(`/MasterData/SalesEmployee/create?copy=${record.salesEmployeeCode}`)}
                    className={`${record.actived ? '!block' : '!invisible'} !leading-[25px] !h-[25px]`}
                  >
                    <CopyOutlined className="!text-[#389e0d] flex" />
                  </CustomButtonIcon>
                </Tooltip>
              )} */}
            </div>
          );
        },
      },
    ];

    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<SalesEmployee>[]>(defaultColumn);
    // Loading all page
    const [loading, setLoading] = useState(false);

    const tableRef = useRef<ActionType>();

    // ====================SEARCH DROPDOWN=========================================
    const [brand, setBrand] = useState<Category[]>([]);
    const [subBrandLob, setSubBrandLob] = useState<Category[]>([]);
    const [group, setGroup] = useState<Category[]>([]);
    const [subGroupSubCate, setSubGroupSubCate] = useState<Category[]>([]);
    const [departments, setDepartments] = useState<Category[]>([]);

    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        orderByDesc: '',
      },
    });

    // List data company
    const [listSalesEmployee, setListSalesEmployee] = useState<SalesEmployee[]>([]);

    const [selected, setSelected] = useState('Export');
    const items: MenuProps['items'] = [
      {
        label: (
          <Link onClick={(e) => setSelected(e.currentTarget.textContent || '')} to={'/Form/Customer'}>
            Mẫu thêm mới
          </Link>
        ),
        key: '0',
      },
      {
        label: (
          <Link onClick={(e) => setSelected(e.currentTarget.textContent || '')} to={'/Form/Customer'}>
            Mẫu cập nhật
          </Link>
        ),
        key: '1',
      },
    ];

    // Function open hide drawer
    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Call api search data
    const getDataSearchSalesEmployee = async (dataSearch: DataSearchSalesEmployee) => {
      await salesEmployeePresenter.searchSalesEmployee(salesEmployeeStore, dataSearch);
      if (salesEmployeeStore.searchSalesEmployeeResponse.isSuccess) {
        setListSalesEmployee(salesEmployeeStore.searchSalesEmployeeResponse.data);
      }
    };

    // Handle click button search
    const handleSearch = async (values: any) => {
      setLoading(true);
      const dataResult = cloneDeep(values);
      // set pageIndex 1 when search form
      setDataSearch({
        // loại bỏ những field không có giá trị
        ...Object.fromEntries(Object.entries(dataResult).filter(([_, value]) => !!value)),
        paging: {
          pageSize: 10,
          pageIndex: 1,
        },
      });

      onCloseDrawer();
      setLoading(false);
    };

    // initialValues of form search
    const initialValues = {};

    // Call api delete
    const handleClickDeleteCatalog = async (code: string) => {
      setLoading(true);
      if (code) {
        await salesEmployeePresenter.deleteSalesEmployee(salesEmployeeStore, code);
        if (salesEmployeeStore.crudResponse.isSuccess) {
          message.success(salesEmployeeStore.crudResponse.message);
          // Call list data after change
          if (listSalesEmployee.length !== 1) {
            tableRef.current?.reload();
          } else {
            setDataSearch({
              ...dataSearch,
              paging: {
                pageIndex: 1,
                pageSize: 10,
              },
            });
            tableRef.current?.reload();
          }
        }
      }
      setLoading(false);
    };


    // ================================ CALL DATA ==========================================
    // Get dropdow brand
    const getDropdownBrand = async () => {
      await categoryPresenter.dropdownCategory(categoryStore, 'Brand');
      setBrand(categoryStore.categoryDropdown.data);
    };

    // Get dropdow Sub-Brand/LoB
    const getDropdownSubBrandLob = async () => {
      await categoryPresenter.dropdownCategory(categoryStore, 'Sub-Brand/LoB');
      setSubBrandLob(categoryStore.categoryDropdown.data);
    };
    // Get dropdow group
    const getDropdownGroup = async () => {
      await categoryPresenter.dropdownCategory(categoryStore, 'Group');
      setGroup(categoryStore.categoryDropdown.data);
    };
    // Get dropdow Sub-Group/Sub-Cate
    const getDropdownSubGroupSubCate = async () => {
      await categoryPresenter.dropdownCategory(categoryStore, 'Sub-Group/Sub-Cate');
      setSubGroupSubCate(categoryStore.categoryDropdown.data);
    };
    // Get dropdow Sub-Group/Sub-Cate
    const getDropdownDepartment = async () => {
      await commonMasterDataPresenter.getDropdownDepartment(commonMasterDataStore);
      if (commonMasterDataStore.commonRespone.isSuccess) {
        setDepartments(commonMasterDataStore.commonRespone.data);
      }
    };


    // useEffect(() => {
    //   Promise.all([getDropdownBrand(), getDropdownSubBrandLob(), getDropdownGroup(), getDropdownSubGroupSubCate()]);
    // }, []);

    // scroll to top
    useEffect(() => {
      window.scrollTo(0, 0);
      getDropdownDepartment()
      // (async () => {
      //   await commonMasterDataPresenter.getDropdownDepartment(commonMasterDataStore);
      // })();
    }, []);

    // Ui modal confirm delete store
    const showDeleteConfirm = (item: SalesEmployee) => {
      confirm({
        title: `Xóa nhân viên`,
        icon: <ExclamationCircleFilled />,
        content: `Bạn có chắc chắn xóa ${item.salesEmployeeName}`,
        okText: 'Xóa',
        okType: 'danger',
        cancelText: 'Hủy',
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDeleteCatalog(item.salesEmployeeCode);
        },
        onCancel() { },
      });
    };

    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/Masterdata/SalesEmployee' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>
              {userStore.userPermission?.includes('CREATE') && (
                <CustomButton
                  onClick={() => {
                    navigate('create');
                  }}
                  type="primary"
                  icon={<Icon.Plus className="!fill-[#fff]" />}
                >
                  {t('btn.add')}
                </CustomButton>
              )}
              {/* <CustomButton>
                <Dropdown menu={{ items }} trigger={['click']}>
                  <Space className="cursor-pointer h-[46px]">
                    <span>
                      <Icon.Download className={'!fill-dark'} />
                    </span>
                    <span className={`text-dark font-[400] text-[14px] leading-[22px]`}>{selected}</span>
                    <span>
                      <Icon.ChevronDown className={'!fill-dark'} />
                    </span>
                  </Space>
                </Dropdown>
              </CustomButton>
              <CustomButton icon={<Icon.Upload />}>Import</CustomButton> */}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<SalesEmployee>
            columns={columns}
            handleResize={() => { }}
            rowKey="stt"
            size="small"
            bordered
            actionRef={tableRef}
            search={false}
            loading={loading}
            request={async (params = {}, sort, filter) => {
              setLoading(true);
              const data: any = await getDataSearchSalesEmployee({
                ...dataSearch,
                paging: {
                  ...dataSearch.paging,
                  orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                },
              });

              if (Object.values(sort).length) {
                setDataSearch({
                  ...dataSearch,
                  paging: {
                    ...dataSearch.paging,
                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                  },
                });
              }
              setLoading(false);
              return {
                data: data,
                success: salesEmployeeStore.searchSalesEmployeeResponse.isSuccess,
              };
            }}
            dataSource={listSalesEmployee}
            scroll={{ x: 2000, y: 500 }}
            pagination={{
              pageSize: dataSearch.paging.pageSize,
              total: salesEmployeeStore.searchSalesEmployeeResponse.paging?.recordsTotal,
              current: dataSearch.paging.pageIndex,
              hideOnSinglePage: true,
              responsive: true,
              position: ['topRight', 'bottomRight'],
              style: { margin: '10px 0' },
              showSizeChanger: false,
              showTotal: (total, range) => (
                <>
                  <span className="!absolute left-1">
                    {t('message.view')}{' '}
                    <CustomSelect
                      size="middle"
                      className="min-w-[72px] !rounded-none"
                      value={String(dataSearch.paging.pageSize)}
                      onChange={(value) => {
                        setDataSearch({
                          ...dataSearch,
                          paging: { ...dataSearch.paging, pageSize: Number(value) },
                        });
                      }}
                    >
                      {PAGE_SIZE_OPTION.map((pageSize) => (
                        <Select.Option key={pageSize} value={String(pageSize)}>
                          {pageSize}
                        </Select.Option>
                      ))}
                    </CustomSelect>{' '}
                    {t('message.item')}
                  </span>
                  <span className="hidden md:block">{`${t('message.display')} ${range[0]}-${range[1]} ${t(
                    'message.of',
                  )} ${total} ${t('message.entries')}`}</span>
                </>
              ),
              onChange: (page, pageSize) => {
                setDataSearch({
                  ...dataSearch,
                  paging: { ...dataSearch.paging, pageIndex: page, pageSize: pageSize },
                });
              },
            }}
            options={false}
          />
        </BoxContainer>

        <SearchBox // tìm kiếm
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                  onCloseDrawer();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm
            initialValues={initialValues}
            form={form}
            onFinish={(values) => {
              handleSearch(values);
              tableRef.current?.reload();
            }}
            layout="vertical"
            column="col-1"
          >
            {/* mã nhân viên */}
            <SearchItem label={t('masterdata.sales-employee.employee-code')} name={'salesEmployeeCode'}>
              <CustomInput />
            </SearchItem>
            {/* họ và tên */}
            <SearchItem label={t('masterdata.sales-employee.full-name')} name={'salesEmployeeName'}>
              <CustomInput />
            </SearchItem>
            {/* Bộ phận */}
            <SearchItem label={'Bộ phận'} name={'departmentId'}>
              <CustomSelect
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                options={departments.map((item) => {
                  return {
                    label: item.value,
                    value: item.key,
                  };
                })}
                optionFilterProp="children"
                showSearch
                allowClear
              />
            </SearchItem>

            {/* Brand */}
            {/* <SearchItem label={'Brand'} name={'brandId'}>
              <CustomSelect
                options={brand}
                mode="multiple"
                allowClear
                fieldNames={{
                  label: 'value',
                  value: 'key',
                }}
                showSearch
                filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </SearchItem> */}
            {/* Sub-Brand/LoB */}
            {/* <SearchItem label={'Sub-Brand/LoB'} name={'sub_Brand_LoBId'}>
              <CustomSelect
                options={subBrandLob}
                mode="multiple"
                allowClear
                fieldNames={{
                  label: 'value',
                  value: 'key',
                }}
                showSearch
                filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </SearchItem> */}
            {/* Group */}
            {/* <SearchItem name={'groupId'} label={`Group`}>
              <CustomSelect
                options={group}
                mode="multiple"
                allowClear
                fieldNames={{
                  label: 'value',
                  value: 'key',
                }}
                showSearch
                filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </SearchItem> */}
            {/* Sub-Group/Sub-Cate */}
            {/* <SearchItem name={'sub_Group_Sub_CateId'} label={`Sub-Group/Sub-Cate`}>
              <CustomSelect
                options={subGroupSubCate}
                mode="multiple"
                allowClear
                fieldNames={{
                  label: 'value',
                  value: 'key',
                }}
                showSearch
                filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </SearchItem> */}
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default salesEmployeePage;
