
import { createCommonServices, ICommonServices } from './Main/common_services';

import { createUserServices, IUserServices } from './User/user_services';
import { createLanguageServices, ILanguageServices } from './Language/language_services';

import { createReportSavedServices, IReportSavedServices } from './SaveRepoprtTemplete/reportSaved_servieces';
import { createProvinceServices, IProvinceServices } from './RawData/province_services';
import { createDistrictServices, IDistrictServices } from './RawData/district_services';
import { createCompanyServices, ICompanyServices } from './RawData/company_services';
import { createBranchServices, IBranchServices } from './RawData/branch_services';
import { createCatalogServices, ICatalogServices } from './RawData/catalog_services';
import { createCatalogTypeServices, ICatalogTypeServices } from './RawData/catalogType_services';
import { createStockServices, IStockServices } from './RawData/stock_services';
import { createRolesServices, IRolesServices } from './RawData/role_services';
import { createDepartmentServices, IDepartmentServices } from './RawData/department_services';
import { createAccountServices, IAccountServices } from './RawData/account_services';
import { createSalesEmployeeServices, ISalesEmployeeServices } from './RawData/salesEmployee_services';
import { createWardServices, IWardServices } from './RawData/ward_services';
import { createWorkServices, IWorkServices } from './RawData/work_services';
import { createCustomerServices, ICustomerServices } from './RawData/customer_services';
import { createCategoryServices, ICategoryServices } from './RawData/Sales_services';
import { createProductServices, IProductServices } from './RawData/product_services';
import { createUserConfigServices, IUserConfigServices } from './User/userConfig_services';
import { createCommonMasterDataServices, ICommonMasterDataServices } from './RawData/commonMasterData_services';
import { createVendorServices, IVendorServices } from './RawData/vendor_services';
import { createPurchaseOrderServices, IPurchaseOrderServices } from './Main/purchaseOrder_services';
import { createPageServices, IPageServices } from './RawData/page_services';
import { createMenuServices, IMenuServices } from './RawData/menu_services';
import { createAccessServices, IAccessServices } from './RawData/access_services';
import { createReportServices, IReportServices } from './Main/report_services';
import { createDriverServices, IDriverServices } from './RawData/driver_services';
import { createFunctionServices, IFunctionServices } from './RawData/function_services';
import { createAccessMobileServices, IAccessMobileServices } from './RawData/accessMobile_services';
import { createMobileScreenServices, IMobileScreenServices } from './RawData/mobileScreen_services';
import { createNotificationServices, INotificationServices } from './Notification/notification_services';



export interface IServices {
  // User services
  userServices: IUserServices;
  // Common services
  commonServices: ICommonServices;


  // ========================== DÙNG CHUNG =================================================================
  // Đổi ngôn ngữ
  languageServices: ILanguageServices;
  // Lưu mẫu báo cáo
  reportSavedServices: IReportSavedServices;
  // =======================================================================================================

  // ========================== CHỨC NĂNG ==============================================================
  purchaseOrderServices: IPurchaseOrderServices;
  reportServices: IReportServices;
  // Thông báo
  notificationServices: INotificationServices;
  // ===================================================================================================


  // =======================================================================
  // ===========================DỮ LIỆU NỀN=================================
  // =======================================================================
  // Province services
  provinceServices: IProvinceServices;
  // District services
  districtServices: IDistrictServices;
  // Company services
  companyServices: ICompanyServices;
  // Branch services
  branchServices: IBranchServices;
  // Catalog services
  catalogServices: ICatalogServices;
  // Catalog services
  catalogTypeServices: ICatalogTypeServices;
  // Stock services
  stockServices: IStockServices;
  // Role services
  rolesServices: IRolesServices;
  // Department services
  departmentServices: IDepartmentServices;
  // User config services
  userConfigServices: IUserConfigServices;
  // Account services
  accountServices: IAccountServices;
  // Sales employee services
  salesEmployeeServices: ISalesEmployeeServices;
  // Ward services
  wardServices: IWardServices;
  // Product services
  productServices: IProductServices;
  // Work services
  workServices: IWorkServices;
  // Sales sản phẩm
  categoryServices: ICategoryServices;
  commonMasterDataServices: ICommonMasterDataServices;
  // Customer services
  customerServices: ICustomerServices;
  // Nhà cung cấp 
  vendorServices: IVendorServices;
  // quản lý trang
  pageServices: IPageServices;
  // Quản lý danh mục
  menuServices: IMenuServices;
  // phân quyền chức năng web
  accessServices: IAccessServices;
  // Tài xế
  driverServices: IDriverServices;
  // Thao tác
  functionServices: IFunctionServices;
  // phân quyền chức năng mobile
  accessMobileServices: IAccessMobileServices,
  // Quản lý chức năng mobile
  mobileScreenServices: IMobileScreenServices,
}

export function createServices(): IServices {
  return {
    // User services
    userServices: createUserServices(),
    // Common services
    commonServices: createCommonServices(),



    // ========================== DÙNG CHUNG =================================================================
    // Đổi ngôn ngữ
    languageServices: createLanguageServices(),
    reportSavedServices: createReportSavedServices(),
    // =======================================================================================================


    // ========================== CHỨC NĂNG ==============================================================
    purchaseOrderServices: createPurchaseOrderServices(),
    reportServices: createReportServices(),
    // Thông báo
    notificationServices: createNotificationServices(),
    // ===================================================================================================

    // =======================================================================
    // =========================MASTER DATA-DỮ LIỆU NỀN=======================
    // =======================================================================
    // Province services
    provinceServices: createProvinceServices(),
    // District services
    districtServices: createDistrictServices(),
    // Company services
    companyServices: createCompanyServices(),
    // Branch services
    branchServices: createBranchServices(),
    // Catalog services
    catalogServices: createCatalogServices(),
    // CatalogType services
    catalogTypeServices: createCatalogTypeServices(),
    // Stock services
    stockServices: createStockServices(),
    // Department services
    departmentServices: createDepartmentServices(),
    // UserConfig services
    userConfigServices: createUserConfigServices(),

    // Account
    accountServices: createAccountServices(),
    // Department services
    rolesServices: createRolesServices(),
    // Sales Employee services
    salesEmployeeServices: createSalesEmployeeServices(),
    // Ward services
    wardServices: createWardServices(),
    // Product Services
    productServices: createProductServices(),
    // Sales (Menu Sản phẩm)
    categoryServices: createCategoryServices(),
    commonMasterDataServices: createCommonMasterDataServices(),
    // Customer Services
    customerServices: createCustomerServices(),
    // Work
    workServices: createWorkServices(),
    // nhà cung cấp
    vendorServices: createVendorServices(),
    // quản lý trang (chức năng)
    pageServices: createPageServices(),
    // Quản lý danh mục
    menuServices: createMenuServices(),
    // phân quyền chức năng web
    accessServices: createAccessServices(),
    // Tài xế
    driverServices: createDriverServices(),
    // Thao tác
    functionServices: createFunctionServices(),
    // phân quyền chức năng mobile
    accessMobileServices: createAccessMobileServices(),
    // Quản lý chức năng mobile
    mobileScreenServices: createMobileScreenServices(),
  };
}
