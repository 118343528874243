import rolesPage from '.';
import createAddUpdateRoles from './CreateUpdate';
import { rolesRoutes } from './routes';

export const createRolesPage = () => {
  const Roles = rolesPage();
  const AddRoles = createAddUpdateRoles();
  const UpdateRoles = createAddUpdateRoles();

  const RolesPage = rolesRoutes;
  return () => <RolesPage Roles={Roles} AddRoles={AddRoles} UpdateRoles={UpdateRoles} />;
};
