import { DataCompany, ICompanyServices } from 'services/RawData/company_services';
import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';

export type companyStore = {
  // Type default response search company
  searchCompanyResponse: DataResponse<Array<DataCompany>>;
  // Type default response get detail company
  detailCompanyResponse: DataResponse<DataCompany | undefined>;
  // Type default response update detail company
  updateDetailCompanyResponse: DataResponse<boolean>;
  // Type default response create detail company
  createDetailCompanyResponse: DataResponse<boolean>;
  // Type default response delete company
  deleteCompanyResponse: DataResponse<boolean>;
};

export interface ICompanyPresenter {
  createStore(): companyStore;
  // Search company
  searchCompany(store: companyStore, data: object): Promise<void>;
  // Search detail company
  getDetailCompany(store: companyStore, companyId: string): Promise<void>;
  // Update detail company
  updateDetailCompany(store: companyStore, params: DataCompany): Promise<void>;
  // Create detail company
  createDetailCompany(store: companyStore, data: DataCompany): Promise<void>;
  // Delete company
  deleteCompany(store: companyStore, companyId: string): Promise<void>;
}

export function createCompanyPresenter({ companyServices }: { companyServices: ICompanyServices }): ICompanyPresenter {
  return {
    createStore: (): companyStore =>
      observable({
        searchCompanyResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        detailCompanyResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        updateDetailCompanyResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
        createDetailCompanyResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
        deleteCompanyResponse: {
          data: false,
          message: '',
          isSuccess: false,
        },
      }),
    // Search company
    searchCompany: async (store, data) => {
      try {
        const result = await companyServices.searchCompany(data);
        runInAction(() => {
          store.searchCompanyResponse.data = result.data;
          store.searchCompanyResponse.isSuccess = result.isSuccess;
          store.searchCompanyResponse.message = result.message;
          store.searchCompanyResponse.paging = {
            totalCount: result.paging?.recordsTotal,
            totalPage: result.paging?.pagesCount,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Search detail company
    getDetailCompany: async (store, companyId) => {
      try {
        const result = await companyServices.getDetailCompany(companyId);
        runInAction(() => {
          store.detailCompanyResponse.data = result.data;
          store.detailCompanyResponse.isSuccess = result.isSuccess;
          store.detailCompanyResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Update detail company
    updateDetailCompany: async (store, params) => {
      try {
        const result = await companyServices.updateDetailCompany(params);
        runInAction(() => {
          store.updateDetailCompanyResponse.data = result.data;
          store.updateDetailCompanyResponse.isSuccess = result.isSuccess;
          store.updateDetailCompanyResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Create detail company
    createDetailCompany: async (store, data) => {
      try {
        const result = await companyServices.createDetailCompany(data);
        runInAction(() => {
          store.createDetailCompanyResponse.data = result.data;
          store.createDetailCompanyResponse.isSuccess = result.isSuccess;
          store.createDetailCompanyResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // Create detail company
    deleteCompany: async (store, companyId) => {
      try {
        const result = await companyServices.deleteCompany(companyId);
        runInAction(() => {
          store.deleteCompanyResponse.data = result.data;
          store.deleteCompanyResponse.isSuccess = result.isSuccess;
          store.deleteCompanyResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
