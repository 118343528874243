import { ActionType, ProColumns } from '@ant-design/pro-components'
import { DatePicker, Form, message, Select, Spin, Tag, Tooltip } from 'antd'
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable'
import { cloneDeep, omit } from 'lodash'
import { DeliveryRegistrationResulItem, DeliveryResultReport, EmailSendingReport } from 'models/Report'
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox'
import { useCallback, useEffect, useRef, useState } from 'react'
import { commonPresenter, commonStore, reportPresenter, reportStore, services, userStore } from 'stores/root_store'
import { DATE_FORMAT_DEFAULT, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from 'utils/constants'

import { ROUTES } from 'base/routes/routes'
import BoxContainer from 'base/ui/components/BoxContainer'
import CustomButton from 'base/ui/components/Button'
import { Filter } from 'base/ui/components/Icons'
import dayjs from 'dayjs'
import { usePermission } from 'hooks/usePermission'
import { observer } from 'mobx-react'
import { ExportButton } from 'pages/Components/FunctionButton'
import PageWrapper from 'pages/Components/PageWrapper'
import SaveReportForm from 'pages/Components/reportForm'
import { HiSearch } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import { CommonModel } from 'services/Main/common_services'
import { SearchEmailSendingReport } from 'services/Main/report_services'
import { debounce } from 'utils/debounce'
import { formatNumber } from 'utils/formatHelper'
import { checkPermissionWithId } from 'utils/permissionHelper'
import { useResizableColumns } from 'utils/reportHelper'
import { removeEmptyValues } from 'utils/transformHelper'
import { getAccountId, getPlantCode } from 'utils/localStorageHelper'
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon'
import { MdOutlineEmail } from 'react-icons/md'

type ColumnRenderType = "tag" | "customTag" | "date" | "number" | "link" | undefined

const initialSearch: SearchEmailSendingReport = {
    paging: {
        pageSize: PAGE_SIZE_DEFAULT,
        pageIndex: 1,
        orderBy: undefined,
        orderByDesc: undefined,
    },
    // fromTime: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    // toTime: dayjs().format('YYYY-MM-DD')
}

const createEmailSendingResult = () => {
    return observer(() => {
        document.title = 'Báo cáo - ' + SYSTEM_TITLE;
        usePermission('INDEX')
        const isExport = checkPermissionWithId("EXPORT")

        // ========== SEARCH =================
        const [searchForm] = Form.useForm()
        // mở drawer search
        const [openDrawer, setOpenDrawer] = useState(false)
        // disable nhập ngày khi chọn khác "Custom"
        const [disableDatePicker, setDisableDatePicker] = useState(false)

        const [loading, setLoading] = useState(true)
        const [tableLoading, setTableLoading] = useState(false);
        // Dropdown nhà cung cấp
        const [listVendor, setListVendor] = useState<CommonModel[]>([])
        // data search (phân trang)
        const [dataPaging, setDataPaging] = useState<SearchEmailSendingReport>(initialSearch);
        // dữ liệu trên bảng 
        const [dataTable, setDataTable] = useState<EmailSendingReport[]>([])

        const getListCommom = async () => {
            if (!commonStore.listCommonDate.length) {
                await commonPresenter.getListCommonDate(commonStore)
            }
        }
        // lấy ngày từ common date
        const getDateByCommonDate = async (commonDate: string) => {
            setDisableDatePicker(true)
            await commonPresenter.getDateByCommonDate(commonStore, commonDate)
            if (commonStore.commonDate.fromDate) {
                searchForm.setFieldsValue({ fromTime: dayjs(commonStore.commonDate.fromDate, "YYYY-MM-DD"), toTime: dayjs(commonStore.commonDate.toDate, "YYYY-MM-DD") })
            }
        }

        // Dropdown nhà cung cấp
        const getDropdownVendor = async (keyword?: string) => {
            await commonPresenter.getDropdownVendor(commonStore, { keyword: keyword, takeNumber: 20 })
            if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
                setListVendor(commonStore.commonRespone.data)
            }
        }
        const searchVendor = debounce(getDropdownVendor, 300);

        // search báo cáo
        const getDataTable = async (dataSearch: SearchEmailSendingReport) => {
            await reportPresenter.getEmailSendingReport(reportStore, { ...dataSearch, plantCode: getPlantCode() })
            if (reportStore.emailSendingRes?.isSuccess) {
                setDataTable(reportStore.emailSendingRes?.data)
                return reportStore.emailSendingRes?.data
            }
            return []
        }

        useEffect(() => {
            Promise.allSettled([
                getListCommom(),
                getDropdownVendor(),
                getDropdownSaleOrg(),
            ]).then(() => { setLoading(false) })
        }, []);

        const [columns, setColumns] = useState<ProColumns<EmailSendingReport>[]>([
            {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                fixed: 'left',
                width: 50,
                align: 'center',
            },
            {
                title: 'Tiêu đề Mail',
                dataIndex: 'title',
                key: 'title',
                width: 300,
                fixed: 'left',

            },
            //Mã phiếu
            {
                title: 'Mã Phiếu',
                dataIndex: 'objectCode',
                key: 'objectCode',
                width: 150,
                align: 'center',
                fixed: 'left',
                render: (value, record) => record.objectCode ? <Link to={`/PurchaseOrder/VehicleRegistration/${record.id}`}>{value}</Link> : <p>-</p>

            },
            {
                title: 'Mã nhà cung cấp/nhân viên',
                dataIndex: 'userCode',
                key: 'userCode',
                width: 150,
                align: 'center',
            },
            {
                title: 'Tên nhà cung cấp/nhân viên',
                dataIndex: 'userName',
                key: 'userName',
                width: 300,
                ellipsis: true,
            },
            //Trạng thái gửi tin
            {
                title: 'Email gửi',
                dataIndex: 'email',
                key: 'email',
                width: 300,
                ellipsis: true,
            },
            //Thông báo gửi tin 
            {
                title: 'Trạng thái gửi tin',
                dataIndex: 'status',
                key: 'status',
                width: 150,
                align: 'center',
                render: (value) => <p className={`${value ? "text-green-500" : "text-red-500"}`}>{value ? "Thành công" : "Thất bại"}</p>
            },
            {
                title: 'Message',
                dataIndex: 'description',
                key: 'description',
                width: 300,
                ellipsis: true,
            },
            {
                title: 'Mẫu tin gửi',
                dataIndex: 'type',
                key: 'type',
                width: 300,
                ellipsis: true,
            },
            {
                title: 'Thời gian gửi tin',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 150,
                align: 'center',
                valueType: 'date',
                fieldProps: {
                    format: "DD/MM/YYYY HH:mm",
                },
            },
            // Chức năng
            {
                title: "Chức năng",
                dataIndex: '',
                key: '',
                width: 80,
                className: '!rounded-none !py-1',

                align: 'center',
                render: (_, record) => {
                    return (
                        <div className="w-full flex items-center justify-center gap-x-1" >
                            {userStore.userPermission?.includes('RESEND_EMAIL') && record.typeCode === "CREATEACCOUNTNCC" && record.status === false && <Tooltip title={"Resend"}>
                                <CustomButtonIcon color="#007BF1">
                                    <MdOutlineEmail onClick={async () => {
                                        setTableLoading(true)
                                        const data = await services.accountServices.resendEmail({ logId: record.id });
                                        setTableLoading(false)
                                        if (data.isSuccess) {
                                            message.success("Resend email thành công");
                                            tableRef.current?.reload()
                                        }
                                    }} className="!fill-[#007BF1]" width="14" height="14" />
                                </CustomButtonIcon>
                            </Tooltip>}
                        </div >

                    )
                }
            }
        ]);


        // mẫu báo cáo
        const tableRef = useRef<ActionType>();
        const constDataName = 'BCKetQuaGuiEmail';
        const {
            isResizablePresent,
            setIsResizablePresent,
            resizableColumnSelect,
            columnsState,
            handleColumnsStateChange,
            templateReportModalRef,
            userReports,
            selectedReportId,
            templateReportModalProps,
            handleResize,
        } = useResizableColumns<EmailSendingReport>(columns, setColumns, constDataName);

        const EmailSendingTypeOptions = [{ label: "Đổi mật khẩu", value: "CHANGEPASSWORD" }, { label: "Tạo tài khoản NCC", value: "CREATEACCOUNTNCC" }, { label: "Tạo phiếu đăng ký", value: "REGISTRATION" }, { label: "Điều chỉnh phiếu đăng ký", value: "UPDATEREGISTRATION" }]
        const EmailSendingStatusOptions = [{ label: "Thành công", value: true }, { label: "Thất bại", value: false }]

        const getDropdownSaleOrg = async () => {
            if (!commonStore.commonStore.length) {
                await commonPresenter.getSeleOrgByAccountId(commonStore, getAccountId())
                searchForm.setFieldValue('plantCode', commonStore.commonStore[0].key)
            }
        }

        return (
            <PageWrapper
                breadcrumb={[{
                    text: 'Báo cáo kết quả gửi email'
                }]}
                extras={
                    <>
                        {isExport && <ExportButton loading={loading} onClick={async () => {
                            setLoading(true);
                            const exportRequest = cloneDeep(dataPaging)
                            await reportPresenter.exportEmailSendingReport(omit(exportRequest, ['paging']))
                            setLoading(false);
                        }} />}
                        <CustomButton
                            onClick={() => setOpenDrawer(true)}
                            type="primary" color="#EFF4F9"
                            className="!px-0 w-8"
                        >
                            <Filter className="!fill-primary" />
                        </CustomButton>
                    </>
                }
            >

                <BoxContainer>
                    <Spin spinning={tableLoading}>
                        <CustomProTable<EmailSendingReport>
                            bordered
                            columns={columns}
                            dataSource={dataTable}
                            actionRef={tableRef}

                            tableAlertRender={false}
                            handleResize={handleResize}
                            isResizablePresent={isResizablePresent}
                            resizableColumnSelect={resizableColumnSelect}
                            columnsState={{
                                value: Object.keys(columnsState).length > 0 ? columnsState : undefined,
                                onChange: (newColumnsState) => {
                                    handleColumnsStateChange(newColumnsState);
                                },
                            }}
                            toolBarRender={() => [

                                // nút lưu mẫu bc
                                <SaveReportForm
                                    setIsResizablePresent={setIsResizablePresent}
                                    templateReportModalRef={templateReportModalRef}
                                    userReports={userReports}
                                    selectedReportId={selectedReportId}
                                    templateReportModalProps={templateReportModalProps}
                                />,
                            ]}
                            scroll={{ y: "calc(100vh - 350px)", x: "1500px" }}
                            rowKey="stt"
                            pagination={{
                                ...tablePagination(dataPaging, setDataPaging),
                                total: reportStore.emailSendingRes?.paging?.recordsTotal,
                            }}
                            request={async (params = {}, sort, filter) => {
                                const data = await getDataTable({
                                    ...dataPaging,
                                    paging: {
                                        ...dataPaging.paging,
                                        orderBy:
                                            Object.values(sort)[0] === "ascend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                        orderByDesc:
                                            Object.values(sort)[0] === "descend"
                                                ? `${Object.keys(sort)[0].split(",").pop()}`
                                                : undefined,
                                    },
                                });
                                if (Object.values(sort).length) {
                                    setDataPaging({
                                        ...dataPaging,
                                        paging: {
                                            ...dataPaging.paging,
                                            orderBy:
                                                Object.values(sort)[0] === "ascend"
                                                    ? `${Object.keys(sort)[0].split(",").pop()}`
                                                    : undefined,
                                            orderByDesc:
                                                Object.values(sort)[0] === "descend"
                                                    ? `${Object.keys(sort)[0].split(",").pop()}`
                                                    : undefined,
                                        },
                                    });
                                }
                                return {
                                    data: data,
                                    success: reportStore.emailSendingRes?.isSuccess
                                };

                            }}
                        />
                    </Spin>
                </BoxContainer>
                <SearchBox
                    onClose={() => { setOpenDrawer(false) }}
                    className="lg:!w-[50%]"
                    open={openDrawer}
                    footer={
                        <CustomButton
                            htmlType="submit"
                            className="my-auto ml-auto"
                            type="primary"
                            icon={<HiSearch />}
                            loading={loading}
                            onClick={() => {
                                searchForm.submit();
                            }}
                        >
                            Tìm kiếm
                        </CustomButton>
                    }
                >
                    <SearchForm
                        form={searchForm}
                        onFinish={(values) => {
                            let valueClone = cloneDeep(values)
                            // convert lại from date to date
                            const fromTime = valueClone.fromTime?.format("YYYY-MM-DD")
                            const toTime = valueClone.toTime?.format("YYYY-MM-DD")
                            valueClone = omit(valueClone, ["-"])

                            Object.assign(valueClone, { fromTime, toTime })
                            // bỏ các giá trị rỗng null và trim giá trị
                            removeEmptyValues(valueClone)
                            const dataSearch: SearchEmailSendingReport = { paging: { ...dataPaging.paging, pageIndex: 1 }, ...valueClone }
                            setDataPaging(dataSearch)
                            setOpenDrawer(false)
                            tableRef.current?.reload()
                        }}
                    >
                        {/* <SearchItem name={'vendor'} label={'Nhà máy'}>
                            <Select
                                popupMatchSelectWidth={false}
                                options={listVendor.map(item => ({ value: item.key, label: item.value }))}
                                allowClear showSearch
                                filterOption={false}
                                onSearch={(value) => searchVendor(value || undefined)} />
                        </SearchItem> */}
                        {/* Nhà máy */}
                        <SearchItem initialValue={commonStore.commonStore.at(0)?.key} name={'plantCode'} label={'Nhà máy'}>
                            <Select
                                options={commonStore.commonStore}
                                allowClear={false} fieldNames={{ value: 'key', label: 'value' }}
                                placeholder="Vui lòng chọn"
                            />
                        </SearchItem>
                        {/* Mẫu tin gửi */}
                        <SearchItem name={'type'} label={'Mẫu tin gửi'}>
                            <Select
                                popupMatchSelectWidth={false}
                                options={EmailSendingTypeOptions}
                                allowClear
                            />
                        </SearchItem>
                        {/* Trạng thái gửi tin */}

                        <SearchItem name={'status'} label={'Trạng thái gửi tin'}>
                            <Select
                                popupMatchSelectWidth={false}
                                options={EmailSendingStatusOptions}
                                allowClear
                            />
                        </SearchItem>
                        {/* Ngày gửi*/}
                        <SearchItem name='-' initialValue={'Custom'} label={'Ngày gửi'} start>
                            <Select options={commonStore.listCommonDate} fieldNames={{ label: 'value', value: 'key' }} onChange={async (value) => {
                                if (value && value !== "Custom") {
                                    getDateByCommonDate(value)
                                } else {
                                    setDisableDatePicker(false)
                                    searchForm.setFieldsValue({ fromTime: undefined, toTime: undefined })
                                }
                            }} />
                        </SearchItem>

                        {/* Từ ngày */}
                        <SearchItem
                            name={'fromTime'} label={'Từ ngày'} start
                            initialValue={dayjs().subtract(1, 'month')}
                            dependencies={['toTime']}
                        // rules={[
                        //     ({ getFieldValue }) => ({
                        //         validator(_, value) {
                        //             if (value || getFieldValue('toTime')) {
                        //                 return Promise.resolve()
                        //             }
                        //             return Promise.reject(new Error('Chưa nhập giá trị "Từ ngày".'));;
                        //         },
                        //     })
                        // ]}
                        >
                            <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>
                        {/* Đến ngày */}
                        <SearchItem name={'toTime'} label={'Đến ngày'}
                            initialValue={dayjs()}
                            dependencies={['fromTime']}
                        // rules={[
                        //     ({ getFieldValue }) => ({
                        //         validator(_, value) {
                        //             if (value || getFieldValue('fromTime')) {
                        //                 return Promise.resolve();
                        //             }
                        //             return Promise.reject(new Error('Chưa nhập giá trị "Đến ngày".'));
                        //         },
                        //     })
                        // ]}
                        >
                            <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>

                    </SearchForm>
                </SearchBox>
            </PageWrapper>
        )
    })
}

export default createEmailSendingResult