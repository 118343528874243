import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const driverRoutes = ({
    List,
    Add,
    Edit,
}: {
    List: React.ComponentType;
    Add: React.ComponentType;
    Edit: React.ComponentType;
}) => (
    <Routes>
        <Route path="" element={<List />} />
        <Route path={ROUTES.MASTER_DATA.DRIVER.CREATE} element={<Add />} />
        <Route path={ROUTES.MASTER_DATA.DRIVER.EDIT} element={<Edit />} />
    </Routes>
);
