import mobileScreenPage from ".";
import createAddUpdateMobileScreen from "./detail";
import { mobileScreenRoutes } from "./routes";

export const createMobileScreenPage = () => {
    const Search = mobileScreenPage()

    const Create = createAddUpdateMobileScreen()
    const Update = createAddUpdateMobileScreen()

    const MobileScreen = mobileScreenRoutes;
    return () => (
        <MobileScreen
            Search={Search} Create={Create} Update={Update} />
    );
};
