import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';

export type MenuModel = Partial<{
    stt: number;
    menuId: string;
    menuName: string;
    orderIndex: number;
    icon: string
}>;


export interface IMenuServices {
    getListMenu(data: object): Promise<DataResponse<Array<MenuModel>>>;
    // detail
    getMenuById(id: string): Promise<DataResponse<MenuModel | undefined>>;
    // tạo danh mục
    createMenu(data: object): Promise<DataResponse<null>>
    // sửa sanh mục
    updateMenu(data: object): Promise<DataResponse<null>>
    // xóa menu
    deleteMenu(id: string): Promise<DataResponse<null>>
}
export function createMenuServices(): IMenuServices {
    return {
        // Search danh mục
        getListMenu: async (data) => {
            return (await post(MASTER_DATA_API_URL, `menu/search`, data)).data;
        },
        getMenuById: async (id) => {
            return (await get(MASTER_DATA_API_URL, `menu/${id}`)).data;
        },
        // Tạo danh mục 
        createMenu: async (data) => {
            return (await post(MASTER_DATA_API_URL, `menu/create`, data)).data;
        },
        // Sửa danh mục
        updateMenu: async (data) => {
            return (await put(MASTER_DATA_API_URL, `menu/update`, data)).data;
        },
        // xóa menu
        deleteMenu: async (id) => {
            return (await deleted(MASTER_DATA_API_URL, `menu/delete/${id}`)).data;
        }
    };
}
