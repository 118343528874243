import { LayoutHOC } from 'base/ui/layout/layout';
import { MasterDataRoutes } from './routes';
import { createCompanyPage } from './Company/create';
import { createBranchPage } from './Branch/create';
import { createDepartmentPage } from './Department/create';
import { createCatalogPage } from './Catalog/create';
import { createStockPage } from './Stock/create';
import { createCatalogTypePage } from './CatalogType/create';
import { createSalesEmployeePage } from './SalesEmployee/create';
import { createProvincePage } from './Province/create';
import { createDistrictPage } from './District/create';
import { createWardPage } from './Ward/create';
import { createTaskPage } from './Task/create';
import { createVendorPage } from './Vendor/create';
import { createDriverPage } from './Driver/create';
import { createProductPage } from './Product/create';

export const createMasterDataPage = (layoutHOC: LayoutHOC) => {
  const CompanyPage = createCompanyPage();
  const BranchPage = createBranchPage();
  const DepartmentPage = createDepartmentPage();
  const StockPage = createStockPage();
  // loại danh mục dùng chung
  const CatalogTypePage = createCatalogTypePage();
  // danh mục dùng chung
  const CatalogPage = createCatalogPage();
  // nhân viên
  const SalesEmployeePage = createSalesEmployeePage();
  // tỉnh/thành phố
  const ProvincePage = createProvincePage();
  // quận huyện
  const DistrictPage = createDistrictPage();
  // phường xã
  const WardPage = createWardPage();
  // danh sách công việc
  const TaskPage = createTaskPage();
  // nhà cung cấp
  const VendorPage = createVendorPage();
  // Tài xế
  const DriverPage = createDriverPage()
  // Thông tin sản phẩm
  const ProductPage = createProductPage()

  const MasterDataPage = layoutHOC(MasterDataRoutes);
  return () => (
    <MasterDataPage

      CompanyPage={CompanyPage}
      BranchPage={BranchPage}
      DepartmentPage={DepartmentPage}
      StockPage={StockPage}
      TaskPage={TaskPage}
      CatalogTypePage={CatalogTypePage}
      CatalogPage={CatalogPage}
      SalesEmployeePage={SalesEmployeePage}
      ProvincePage={ProvincePage}
      DistrictPage={DistrictPage}
      WardPage={WardPage}
      VendorPage={VendorPage}
      Driver={DriverPage}
      Product={ProductPage}
    />
  );
};
