import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post, put } from 'utils/api';

export type DataBranch = {
  stt: number;
  storeId: string;
  saleOrgCode: string;
  storeName: string;
  companyName: string;
  storeAddress: string;
  provinceName: string;
  actived: boolean;
  provinceId: string;
  districtId: string;
  fax: string;
  telService: string;
  telProduct: string;
  orderIndex: number;
  companyId: string;

  invoiceStoreName: string;
  email: string;
  defaultCustomerSource: string;
  smsTemplateCode: string;
  stockId: string;
  code: string;

  invoiceStorename: string;
};

export interface IBranchServices {
  // Search store
  searchBranch(data: object): Promise<DataResponse<Array<DataBranch>>>;
  // Get detail store
  getDetailBranch(storeId: string): Promise<DataResponse<DataBranch>>;
  // Update detail store
  updateDetailBranch(params: object): Promise<DataResponse<boolean>>;
  // Create detail store
  createDetailBranch(data: object): Promise<DataResponse<boolean>>;
  // Create detail store
  deleteBranch(storeId: string): Promise<DataResponse<boolean>>;
}

export function createBranchServices(): IBranchServices {
  return {
    // Search store
    searchBranch: async (data: object): Promise<DataResponse<DataBranch[]>> => {
      return (await post(MASTER_DATA_API_URL, `saleorg/search`, data)).data;
    },
    // Get detail store
    getDetailBranch: async (storeId) => {
      return (await get(MASTER_DATA_API_URL, `saleorg/${storeId}`)).data;
    },
    // Update detail store
    updateDetailBranch: async (data) => {
      return (await put(MASTER_DATA_API_URL, `saleorg/update`, data)).data;
    },
    // Create store
    createDetailBranch: async (data) => {
      return (await post(MASTER_DATA_API_URL, `saleorg/create`, data)).data;
    },
    // Delete store
    deleteBranch: async (storeId) => {
      return (await deleted(MASTER_DATA_API_URL, `saleorg/delete/${storeId}`)).data;
    },
  };
}
