import { MASTER_DATA_API_URL } from 'utils/constants';
import { DataResponse } from '../response';
import { deleted, get, post } from 'utils/api';

export type Ward = Partial<{
  stt: number;
  wardId: string;
  // tên tỉnh thành
  provinceName: string;
  provinceId: string;
  // tên quận huyện
  districtName: string;
  districtId: string;
  // tên quận huyện đầy đủ
  fullDistrictName: string;
  // mã phường xã
  wardCode: string;
  // tên phường xã
  wardName: string;
  //tên phường xã đầy đủ
  fullWardName: string;
  orderIndex: number;
  appellation: string;
  actived: boolean;
}>;

export interface IWardServices {
  // search list ward
  searchListWard(data: object): Promise<DataResponse<Array<Ward>>>;
  // create list of ward
  createWard(data: object): Promise<DataResponse<any>>;
  // update list of ward
  updateWard(data: object): Promise<DataResponse<any>>;
  // get ward by id
  getWardById(id: string): Promise<DataResponse<Ward>>;
  // delete
  deleteWard(id: string): Promise<DataResponse<Ward>>;
  // get ward by id
  getWardByDistrictId(districtId: string, keyword: string): Promise<DataResponse<Ward[]>>;
}
export function createWardServices(): IWardServices {
  return {
    // Search list ward
    searchListWard: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/Ward/search`, data)).data;
    },
    // create list of ward
    createWard: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/Ward/create`, data)).data;
    },
    // update list of ward
    updateWard: async (data) => {
      return (await post(MASTER_DATA_API_URL, `MasterData/Ward/update`, data)).data;
    },
    // get ward by id
    getWardById: async (WardId) => {
      return (await get(MASTER_DATA_API_URL, `MasterData/Ward/get-by-id?${WardId ? `WardId=${WardId}` : ''}`)).data;
    },
    // delete
    deleteWard: async (id) => {
      return (await deleted(MASTER_DATA_API_URL, `MasterData/Ward/delete`, { WardId: id })).data;
    },
    // get ward by id district
    getWardByDistrictId: async (districtId, keyword) => {
      return (
        await get(
          MASTER_DATA_API_URL,
          `MasterData/Ward/get-by-Districtid?${districtId ? `Districtid=${districtId}&` : ''}${
            keyword ? `keyword=${keyword}` : ``
          }`,
        )
      ).data;
    },
  };
}
