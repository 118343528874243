import { deleted, downloadFilePost, get, post, put } from 'utils/api';

import { DataResponse } from '../response';
import { MASTER_DATA_API_URL } from 'utils/constants';


export type Driver = {
    driverId: string;
    vendorNumber: string;
    vendorName: string;
    vendorFmt?: string;
    driverCode: string;
    driverName: string;
    phone?: string;
    licenseNumber: string;
    trainingResult: string;

    actived: boolean;
}

export interface IDriverServices {

    searchDriver(data: object): Promise<DataResponse<Array<Driver>>>;
    createDriver(data: object): Promise<DataResponse<any>>;
    updateDriver(data: object): Promise<DataResponse<any>>;
    deleteDriver(driverId: string): Promise<DataResponse<any>>;
    getDriverById(driverId: string): Promise<DataResponse<any>>;
    // xuất excel template tạo tài xế
    exportCreateDriverTemplate(): Promise<void>
}

export function createDriverServices(): IDriverServices {
    return {
        searchDriver: async (data) => {
            return (await post(MASTER_DATA_API_URL, `driver/search`, data)).data;
        },
        createDriver: async (data) => {
            return (await post(MASTER_DATA_API_URL, `driver/create`, data)).data;
        },
        updateDriver: async (data) => {
            return (await put(MASTER_DATA_API_URL, `driver/update`, data)).data;
        },
        getDriverById: async (driverId) => {
            return (
                await get(
                    MASTER_DATA_API_URL,
                    `driver/${driverId}`,
                )
            ).data;
        },
        deleteDriver: async (driverId) => {
            return (await deleted(MASTER_DATA_API_URL, `driver/delete/${driverId}`)).data;
        },
        // xuất excel template tạo tài xế
        exportCreateDriverTemplate: async () => {
            await downloadFilePost(MASTER_DATA_API_URL, 'driver/export-create-driver-template', {})
        }
    };
}
