import * as Icons from "base/ui/components/Icons";

import { Avatar, Badge, Button, Card, Divider, Drawer, Space, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { getAccountId, getUserSession } from 'utils/localStorageHelper';
import { notificationPresenter, notificationStore, uiStore } from 'stores/root_store';

import { Bell } from 'base/ui/components/Icons';
import Meta from 'antd/es/card/Meta';
import { NotificationByUser } from 'models/Notification';
import { ROUTES } from 'base/routes/routes';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

const DEFAULT_PAGE_SIZE = 10;

const NewNotification = () => {
    const [open, setOpen] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    // const [pageSize, setPageSize] = useState(10);
    const [listNoti, setListNoti] = useState<NotificationByUser[]>([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();

    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: false,
    });



    const showDefaultDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    // const loadListNotifications = async (pageIndex: number = 1, pageSize: number = 10) => {
    //     await notificationPresenter.getListNotiByUser(notificationStore, {
    //         paging: {
    //             pageIndex: pageIndex,
    //             pageSize: pageSize
    //         },
    //         userId: getAccountId() || ''
    //     })
    //     setListNoti((prev) => [
    //         ...prev,
    //         ...(notificationStore.listNotifications?.data || []),
    //     ]);

    // }
    const loadListNotifications = async (pageIndex = 1, pageSize = 10) => {
        setLoadingMore(true);
        await notificationPresenter.getListNotiByUser(notificationStore, {
            paging: {
                pageIndex,
                pageSize,
            },
            userId: getAccountId() || '',
        });
        const newNotifications = notificationStore.listNotifications?.data || [];
        setListNoti((prev) => [...prev, ...newNotifications]);
        setLoadingMore(false);
        setHasMore(newNotifications.length >= pageSize);
    };


    const getTotalsUnreadNotis = async () => {
        await notificationPresenter.countUnreadNotification(notificationStore, getAccountId() || '');
        setTotalCount(notificationStore.notiCount);
    }

    const markAllAsRead = async () => {
        await notificationPresenter.readNotification({ userId: getAccountId() || '' });
        setTotalCount(0);
        setListNoti((prev) =>
            prev.map((item) => ({
                ...item,
                isRead: true,
            }))
        );
    };

    const handleNotificationClick = async (notification: NotificationByUser) => {
        await notificationPresenter.readSingleNotification(notification.id);
        setListNoti((prev) =>
            prev.map((item) =>
                item.id === notification.id ? { ...item, isRead: true } : item
            )
        );
        getTotalsUnreadNotis();
        if (notification.type === 'RESETPASSWORD') {
            navigate(ROUTES.CHANGE_PASSWORD.LINK);
        }
    };


    // useEffect(() => {
    //     loadListNotifications(pageIndex, pageSize);
    //     getTotalsUnreadNotis();
    // }, []);

    // useEffect(() => {
    //     setListNoti(notificationStore.listNotifications.data || [])
    // }, [notificationStore.listNotifications.data])

    // useEffect(() => {
    //     loadListNotifications(pageIndex);
    //     getTotalsUnreadNotis();
    // }, [pageIndex]);
    useEffect(() => {
        getTotalsUnreadNotis();

        if (open) {
            setPageIndex(1);
            setListNoti([]);
            loadListNotifications(1, DEFAULT_PAGE_SIZE);
        }
    }, [open]);

    useEffect(() => {
        if (inView && hasMore && !loadingMore) {
            const nextPage = pageIndex + 1;
            setPageIndex(nextPage);
            loadListNotifications(nextPage, DEFAULT_PAGE_SIZE);
        }
    }, [inView, loadingMore, hasMore, pageIndex]);

    // const handleLoadMore = () => {
    //     setPageIndex(prevPageIndex => {
    //         const newPageIndex = prevPageIndex + 1;
    //         loadListNotifications(newPageIndex);
    //         return newPageIndex;
    //     });
    // };


    return (
        <>
            <Badge count={totalCount} size="small" offset={[-8, 0]}>
                <div onClick={showDefaultDrawer}>
                    <Bell
                        className={`${uiStore.theme.name === 'gray' ? '!fill-[#E3EDF6]' : '!fill-[#E3EDF6] lg:!fill-primary'} cursor-pointer`}
                    />
                </div>
            </Badge>
            <Drawer

                className='[&_.ant-drawer-body]:!p-0'
                title={`Thông báo`}
                placement="right"
                size={'default'}
                onClose={onClose}
                open={open}
                bodyStyle={{ padding: 0 }}
                extra={

                    // <div onClick={async () => {
                    //     //đọc hết tất cả thông báo
                    //     await notificationPresenter.readNotification({ userId: getAccountId() || '' })
                    //     //set tất cả thông báo chưa đọc => đã đọc 
                    //     setTotalCount(0);
                    //     //load lại list notis, chuyển isRead về true hết 
                    //     setListNoti(prev => {
                    //         const tempList = cloneDeep(prev)
                    //         return tempList.map(item => ({
                    //             ...item,
                    //             isRead: true
                    //         }))
                    //     })
                    // }}>
                    //     <span className="text-red-500 cursor-pointer">
                    //         Đánh dấu là đã đọc
                    //     </span>
                    // </div>

                    <span className="text-red-500 cursor-pointer" onClick={markAllAsRead}>
                        Đánh dấu là đã đọc
                    </span>
                }

            >
                <div style={{ height: '100%', overflowY: 'auto' }}>
                    {listNoti.map((notification) => (
                        <Card
                            key={notification.id}
                            className='[&_.ant-card-body]:!p-2 cursor-pointer hover:!bg-slate-200 duration-100 ease-in'
                            style={{ width: '100%' }}
                            // onClick={async () => {
                            //     await notificationPresenter.readSingleNotification(notification.id);
                            //     getTotalsUnreadNotis();
                            //     setListNoti((prev) => {
                            //         const tempList = cloneDeep(prev);
                            //         return tempList.map((item) => ({
                            //             ...item,
                            //             isRead: item.id === notification.id ? true : item.isRead,
                            //         }));
                            //     });
                            //     if (notification.type === 'RESETPASSWORD') {
                            //         navigate(ROUTES.CHANGE_PASSWORD.LINK);
                            //     } else {
                            //         navigate('MasterData/Vendor');
                            //     }
                            // }}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            <Meta
                                avatar={
                                    notification.type === 'RESETPASSWORD' ? (
                                        <Icons.LockPassword width="28" height="28" />
                                    ) : (
                                        <Icons.UserNotification width="28" height="28" />
                                    )
                                }
                                title={
                                    <div className='flex items-center justify-between'>
                                        <h1>{notification.titleNoti}</h1>
                                        <div className={`font-normal ${notification.isRead ? "text-gray-400" : "text-blue-500"}`}>
                                            {new Date(notification.createTime).toLocaleDateString()}{' '}
                                            {new Date(notification.createTime).toLocaleTimeString()}
                                        </div>

                                        <div className={`w-2 h-2 bg-blue-600 rounded-full ${notification.isRead && "invisible"}`}></div>

                                    </div>
                                }
                                description={
                                    <div className='flex flex-col'>
                                        <span className='font-normal text-sm flex-wrap'>{notification.description}</span>
                                    </div>
                                }
                            />
                        </Card>
                    ))}
                    {loadingMore && (
                        <div className="text-center py-4">
                            <Spin />
                        </div>
                    )}
                    <div ref={ref} style={{ height: '5px' }} />
                    {/* <div className="text-center py-4">
                        {!loadingMore && (
                            <span
                                className="text-blue-500 cursor-pointer"
                                onClick={handleLoadMore}
                            >
                                Load more
                            </span>
                        )}
                    </div> */}
                </div>
            </Drawer>
        </>
    )
}

export default observer(NewNotification)