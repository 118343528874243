import { IRolesServices, Roles } from 'services/RawData/role_services';
import { DataResponse } from '../../services/response';
import { observable, runInAction } from 'mobx';

export type RoleStore = {
  // Type default response search
  searchRoleResponse: DataResponse<Array<Roles>>;
  rolesDetailResponse: DataResponse<Roles | undefined>;
  crudResponse: DataResponse<null>;
};

export interface IRolePresenter {
  createStore(): RoleStore;
  // Search roles
  searchRoles(store: RoleStore, data: object): Promise<void>;
  // Update roles
  updateRoles(store: RoleStore, data: object): Promise<void>;
  // Create roles
  createRoles(store: RoleStore, data: object): Promise<void>;
  // Delete roles
  deleteRoles(store: RoleStore, RoleCode: string): Promise<void>;
  // get roles by id
  getRolesById(store: RoleStore, id: string): Promise<void>;
}

export function createRolesPresenter({ rolesServices }: { rolesServices: IRolesServices }): IRolePresenter {
  return {
    createStore: () =>
      observable({
        searchRoleResponse: {
          data: [],
          paging: {
            totalCount: 0,
            totalPage: 0,
            pageSize: 0,
          },
          message: '',
          isSuccess: false,
        },
        rolesDetailResponse: {
          data: undefined,
          message: '',
          isSuccess: false,
        },
        crudResponse: {
          data: null,
          message: '',
          isSuccess: false,
        },
      }),
    // Search roles
    searchRoles: async (store, data) => {
      try {
        const result = await rolesServices.searchRoles(data);
        runInAction(() => {
          store.searchRoleResponse.data = result.data;
          store.searchRoleResponse.isSuccess = result.isSuccess;
          store.searchRoleResponse.message = result.message;
          store.searchRoleResponse.paging = {
            totalCount: result.paging?.pagesCount,
            recordsTotal: result.paging?.recordsTotal,
            pageSize: result.paging?.resultsCount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    updateRoles: async (store, data) => {
      try {
        const result = await rolesServices.updateRoles(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    createRoles: async (store, data) => {
      try {
        const result = await rolesServices.createRoles(data);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
          store.crudResponse.developerMessage = result.developerMessage;
        });
      } catch (error) {
        console.log(error);
      }
    },
    deleteRoles: async (store, RoleCode) => {
      try {
        const result = await rolesServices.deleteRoles(RoleCode);
        runInAction(() => {
          store.crudResponse.isSuccess = result.isSuccess;
          store.crudResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getRolesById: async (store, id) => {
      try {
        const result = await rolesServices.getRolesByCode(id);
        runInAction(() => {
          store.rolesDetailResponse.isSuccess = result.isSuccess;
          store.rolesDetailResponse.data = result.data;
          store.rolesDetailResponse.message = result.message;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
