import React from 'react';
import { ROUTES } from 'base/routes/routes';
import { Route, Routes } from 'react-router-dom';

export const VendorRoutes = ({
  Vendor,
  AddVendor,
  UpdateVendor,
}: {
  Vendor: React.ComponentType;
  AddVendor: React.ComponentType;
  UpdateVendor: React.ComponentType;
}) => (
  <Routes>
    <Route path="" element={<Vendor />} />
    <Route path={ROUTES.MASTER_DATA.VENDOR.CREATE} element={<AddVendor />} />
    <Route path={ROUTES.MASTER_DATA.VENDOR.EDIT} element={<UpdateVendor />} />
  </Routes>
);
