type DebouncedFunction<T extends any[]> = (...args: T) => void;

export function debounce<T extends any[]>(func: (...args: T) => void, delay: number): DebouncedFunction<T> {
  let timeoutId: NodeJS.Timeout | null;

  return function debounced(...args: T) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
      timeoutId = null;
    }, delay);
  };
}
