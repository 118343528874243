import { ActionType, ProColumns } from "@ant-design/pro-components";
import { Checkbox, DatePicker, Form, Input, message, Select, Space, Table } from "antd";
import { FilterValue } from "antd/es/table/interface";
import BoxContainer from "base/ui/components/BoxContainer";
import CustomButton from 'base/ui/components/Button';
import CustomProTable, { tablePagination } from "base/ui/components/CustomTable";
import { Filter, SynchronizeArrows } from "base/ui/components/Icons";
import dayjs, { Dayjs } from "dayjs";
import { cloneDeep, uniq } from "lodash";
import { observer } from "mobx-react";
import { PurchaseOrderSAPModel } from "models/PurchaseOrderSAP";
import PageWrapper from "pages/Components/PageWrapper";
import SaveReportForm from "pages/Components/reportForm";
import { SearchBox, SearchForm, SearchItem } from "pages/Components/SearchBox";
import { Key, useEffect, useRef, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { HiDotsHorizontal, HiOutlineExternalLink, HiSearch } from "react-icons/hi";
import { SearchSynchPO } from "services/Main/purchaseOrder_services";
import { commonPresenter, commonStore, purchaseOrderPresenter, purchaseOrderStore } from "stores/root_store";
import { DATE_FORMAT_POST, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from "utils/constants";
import { compareStrings, formatNumber } from "utils/formatHelper";
import { checkPermissionWithId, useCheckPermission } from "utils/permissionHelper";
import { useResizableColumns } from "utils/reportHelper";
import ModalVendor from "./modalVendor";
import SearchMultiplePopup, { SearchMultiplePopupRef } from "./SearchMultiplePopup";

const initSearch = {
  paging: {
    pageSize: PAGE_SIZE_DEFAULT,
    pageIndex: 1,
    orderBy: undefined,
    orderByDesc: undefined,
  }
}


export function createSynchPurchaseOrder() {
  return observer(() => {
    document.title = 'Đồng bộ PO - ' + SYSTEM_TITLE;
    useCheckPermission("INDEX")
    const isSync = checkPermissionWithId('SYNC')
    const [open, setOpen] = useState(false);
    const [searchForm] = Form.useForm()
    // close filter form
    const onClose = () => {
      setOpen(false);
    };

    //=== COLUMNS TABLE =================================
    const [dataPaging, setDataPaging] = useState<SearchSynchPO>(initSearch);
    // dữ liệu bảng
    const [dataTable, setDataTable] = useState<PurchaseOrderSAPModel[]>([]);
    // Loading khi search
    const [loading, setLoading] = useState(false)
    const [isReaady, setIsReady] = useState(false)
    //=== COLUMNS TABLE =================================
    // dùng để clearFilter
    const [tableKey, setTableKey] = useState(0);
    const [rowKeys, setRowKeys] = useState<Key[]>([])
    // disable nhập ngày khi chọn khác "Custom"
    const [disableDatePicker, setDisableDatePicker] = useState(false)

    const [filteredInfo, setFilteredInfo] = useState<Record<keyof PurchaseOrderSAPModel, FilterValue | null> | null>(null)
    const getColumnSearchProps = (dataIndex: keyof PurchaseOrderSAPModel): ProColumns<PurchaseOrderSAPModel> => ({
      filterMode: 'menu',
      filterIcon: (filtered: boolean) => (
        <FaFilter style={{ color: filtered ? '#1677ff' : undefined }} />
      ),
      filterSearch(input, record) {
        return record.value.toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())
      },
      // onFilter: (value, record) => (record[dataIndex] || '-').toString()
      //     .toLowerCase()
      //     .includes((value as string).toLowerCase()),
      onFilter(value, record) {
        return (record[dataIndex] || '-').toString().toLocaleLowerCase() === (value as string).toLowerCase();
      },
      filters: (() => {
        const arrString = dataTable.map(data => data[dataIndex] ? data[dataIndex] : '-')
        const arrFilter = uniq(arrString)
        return arrFilter.at(0) ? arrFilter.map(i => ({ value: i || '', text: i || '' })) : []
      })(),
    })

    const defaultDataTable: ProColumns<PurchaseOrderSAPModel>[] = [
      {
        title: 'STT',
        dataIndex: 'stt',
        key: 'stt',
        fixed: 'left',
        width: 50,
        align: 'center',
      },
      {
        title: 'Số PO',
        dataIndex: 'pO_NUM',
        key: 'pO_NUM',
        fixed: 'left',
        width: 100,
        ...getColumnSearchProps('pO_NUM')
      },
      {
        title: 'Số PR',
        dataIndex: 'pR_NUM',
        key: 'pR_NUM',
        fixed: 'left',
        width: 120,
        ...getColumnSearchProps('pR_NUM')
      },
      {
        title: 'PO Item',
        dataIndex: 'pO_ITEM',
        key: 'pO_ITEM',
        width: 70,
      },
      {
        title: 'Loại PO',
        dataIndex: 'pO_TYPE',
        key: 'pO_TYPE',
        width: 100,
        ...getColumnSearchProps('pO_TYPE')
      },
      {
        title: 'Nhà máy',
        dataIndex: 'plant',
        key: 'plant',
        width: 250,
        // render: (text, record) => {
        //     if (record.stockCode && record.stockName) {
        //         return `${record.stockCode} | ${record.stockName}`
        //     }
        //     return text
        // },
        ...getColumnSearchProps('plant')
      },
      {
        title: 'Kho',
        dataIndex: 'sloC_NAME',
        key: 'sloC_NAME',
        width: 150,
        ...getColumnSearchProps('sloC_NAME')
      },
      {
        title: 'Mã hàng hóa',
        dataIndex: 'material',
        key: 'material',
        width: 130,
        ...getColumnSearchProps('material')
      },
      {
        title: 'Tên hàng hóa',
        dataIndex: 'materiaL_NAME',
        key: 'materiaL_NAME',
        width: 300,
        ellipsis: true,
        ...getColumnSearchProps('materiaL_NAME')
      },

      {
        title: 'Mã nhà cung cấp',
        dataIndex: 'vendor',
        key: 'vendor',
        width: 140,
        align: 'center',
        ...getColumnSearchProps('vendor')
      },
      {
        title: 'Tên nhà cung cấp',
        dataIndex: 'vendoR_NAME',
        key: 'vendoR_NAME',
        width: 350,
        ellipsis: true,
        ...getColumnSearchProps('vendoR_NAME')
      },
      {
        title: 'Số lượng đặt hàng',
        dataIndex: 'ordeR_QTY',
        key: 'ordeR_QTY',
        width: 150,
        onCell: () => ({
          className: "text-right"
        }),
        render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
      },
      {
        title: 'Số lượng đã nhập kho',
        dataIndex: 'delivereD_QTY',
        key: 'delivereD_QTY',
        width: 150,
        onCell: () => ({
          className: "text-right"
        }),
        render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
      },
      {
        title: 'Số lượng còn lại',
        dataIndex: 'remaininG_QTY',
        key: 'remaininG_QTY',
        width: 150,
        onCell: () => ({
          className: "text-right"
        }),
        render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
      },
      {
        title: 'ĐVT',
        dataIndex: 'dvt',
        key: 'dvt',
        width: 50,
      },
      {
        title: 'Số lượng gộp',
        dataIndex: 'grosS_WEIGHT',
        key: 'grosS_WEIGHT',
        width: 100,
        onCell: () => ({
          className: "text-right"
        }),
        render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
      },
      {
        title: 'Ngày giao hàng',
        dataIndex: 'deliverY_DATE',
        key: 'deliverY_DATE',
        width: 130,
        align: 'center',
        valueType: 'dateTime',
        fieldProps: {
          format: 'DD/MM/YYYY',
        },
      },

    ]
    const [columns, setColumns] = useState<ProColumns<PurchaseOrderSAPModel>[]>(defaultDataTable)
    // lưu lại các po được tick
    const [poSelected, setPOSelected] = useState<PurchaseOrderSAPModel[]>([])
    // mở modal search nhiều số po
    const [showModalPO, setShowModalPO] = useState(false)
    const [showModalPR, setShowModalPR] = useState(false)
    const [showModalProduct, setShowModalProduct] = useState(false)
    const [showModalVendor, setShowModalVendor] = useState(false)

    const searchMultiPORef = useRef<SearchMultiplePopupRef>(null)
    const searchMultiPRRef = useRef<SearchMultiplePopupRef>(null)
    const searchMultiProductRef = useRef<SearchMultiplePopupRef>(null)
    const searchMultiVendorRef = useRef<SearchMultiplePopupRef>(null)

    // Call api lấy dữ liệu table
    const getDataTable = async (dataSearch: SearchSynchPO) => {
      if (isReaady) {
        setLoading(true)
        await purchaseOrderPresenter.searchPOSAP(purchaseOrderStore, dataSearch)
        setLoading(false)
        if (purchaseOrderStore.searchSynchPO?.isSuccess) {
          const cloneData = cloneDeep(purchaseOrderStore.searchSynchPO?.data || [])
          const currentData = cloneData.map((item, index) => ({ ...item, stt: ((dataSearch.paging.pageIndex - 1) * dataSearch.paging.pageSize) + (index + 1) }))
          setDataTable(currentData)
        }
      }
    }
    // hàm search (submit form)
    const onFinish = async (values: any) => {
      const cloneData = cloneDeep(values)
      // chuyển số thành chữ
      const request = Object.fromEntries(Object.entries(cloneData).map(([key, value]) => {
        if (value instanceof dayjs) {
          return [key, dayjs(value as Dayjs).format("YYYY-MM-DD")]
        }
        return [key, value]
      }))
      const search = { ...dataPaging, paging: { ...dataPaging.paging, pageIndex: 1 }, ...request }
      setDataPaging(prev => ({ ...prev, paging: { ...prev.paging, pageIndex: 1 }, ...request }))
      // tableRef.current?.reload()
      getDataTable(search)
    }
    // Hàm đồng bộ
    const synchPurchaseOrder = async () => {
      setLoading(true)

      const { syncByDate, documentDateFrom, documentDateTo } = searchForm.getFieldsValue()
      const purchaseOrders = poSelected.map(p => ({
        // Số PO
        purchaseOrderCode: p.pO_NUM,
        // Số PR
        prCode: p.pR_NUM,
        // Loại PO
        poType: p.pO_TYPE,
        // PO item
        poItem: p.pO_ITEM,
        // mã nhà máy
        saleOrgCode: p.plant,
        // Mã kho
        stockCode: p.sloc,
        // Tên nhà máy
        stockName: p.sloC_NAME,
        // Mã hàng hóa
        material: p.material,
        // Tên hàng hóa
        materialName: p.materiaL_NAME,
        // nhóm hàng hóa
        materialGroup: p.materiaL_GROUP,
        // Loại hàng hóa
        materialType: p.materiaL_TYPE,
        // Mã nhà cung cấp
        vendorNumber: p.vendor,
        // Tên nhà cung cấp
        vendorName: p.vendoR_NAME,
        vendorAddress: p.vendoR_ADDRESS,
        vendorPhone: p.vendoR_PHONE,
        vendorEmail: p.vendoR_EMAIL,
        actived: true,
        // Số lượng đặt hàng
        totalQuantity: p.ordeR_QTY,
        // Số lượng nhập kho
        receivedQuantity: p.delivereD_QTY,
        // Số lượng còn lại
        openQuantity: p.remaininG_QTY,
        // Đơn vị tính
        unit: p.dvt,
        grossWeight: p.grosS_WEIGHT,
        // Ngày giao hàng
        documentDate: p.deliverY_DATE,
        overDelivery: p.oveR_DELIVERY,
        underDelivery: p.undeR_DELIVERY,
        lineId: p.schedulE_LINE
      }))
      const result = await purchaseOrderPresenter.synchPO({
        purchaseOrders,
        syncByDate,
        documentDateFrom: documentDateFrom ? dayjs(documentDateFrom).format(DATE_FORMAT_POST) : undefined,
        documentDateTo: documentDateTo ? dayjs(documentDateTo).format(DATE_FORMAT_POST) : undefined
      })


      setLoading(false)
      if (result && result?.isSuccess) {
        message.success(result.message)
        setPOSelected([])
        tableRef.current?.reloadAndRest && tableRef.current?.reloadAndRest()
      } else {
        message.error(result?.message)
      }
    }

    // lấy common date
    // kiểm tra đã có trong store chưa nếu chưa có thì call api lấy ds
    const getListCommom = async () => {
      if (!commonStore.listCommonDate.length) {
        await commonPresenter.getListCommonDate(commonStore)
      }
    }

    useEffect(() => {
      setColumns(prev => {
        const col = defaultDataTable.map((column, index) => ({ ...column, width: prev[index].width }))
        return col
      })
    }, [dataTable]);

    useEffect(() => {
      getListCommom()
      getDataTable(initSearch)
    }, []);
    // == SEARCH VENDOR ====================================================================
    // mở modal tìm kiếm nhà cung cấp
    const [modalVendorOpen, setModalVendorOpen] = useState(false)
    //  đặt biến lưu lại là đang chọn from hay to
    const modalVendorRef = useRef('form');

    // == CẤU HÌNH TABLE =================================================================
    const tableRef = useRef<ActionType>();
    const constDataName = 'DongBoPO';
    const {
      isResizablePresent,
      setIsResizablePresent,
      resizableColumnSelect,
      columnsState,
      handleColumnsStateChange,
      templateReportModalRef,
      userReports,
      selectedReportId,
      templateReportModalProps,
      handleResize,
    } = useResizableColumns<PurchaseOrderSAPModel>(columns, setColumns, constDataName);
    return (
      <PageWrapper breadcrumb={[{
        text: "Đồng bộ Purchase order"
      }]}
        extras={
          <>
            {/* FCAF63 */}
            {isSync && <CustomButton
              onClick={() => {
                synchPurchaseOrder()
              }}
              loading={loading}
              className="group"
              disabled={poSelected.length ? false : true}
              type="primary" color="#52C41A" >
              <SynchronizeArrows className="fill-white group-disabled:fill-[#00000040]" />
              Đồng bộ PO
            </CustomButton>}
            <CustomButton
              onClick={() => setOpen(true)}
              type="primary" color="#EFF4F9"
              className="!px-0 w-8"
            >
              <Filter className="!fill-primary" />
            </CustomButton>
          </>
        }
      >

        {/* Table */}
        <BoxContainer >
          <CustomProTable<PurchaseOrderSAPModel>
            bordered
            columns={columns}
            dataSource={dataTable}
            actionRef={tableRef}
            loading={loading}
            rowSelection={{
              onChange(selectedRowKeys, selectedRows, info) {
                if (info.type === 'all') {
                  if (rowKeys.length) {
                    setRowKeys([])
                    setPOSelected([])
                  } else {
                    const dataTableClone = cloneDeep(dataTable)
                    setRowKeys(dataTableClone.map(item => item.stt))
                    setPOSelected(dataTableClone)
                  }
                } else {
                  setPOSelected(selectedRows)
                  setRowKeys(selectedRowKeys)
                }
              },
              // columnTitle: "Chức năng",
              selectedRowKeys: rowKeys,
              columnWidth: 60,
              fixed: 'left',
              selections: [Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
              Table.SELECTION_NONE,]
            }}
            tableAlertRender={false}
            handleResize={handleResize}
            isResizablePresent={isResizablePresent}
            resizableColumnSelect={resizableColumnSelect}
            columnsState={{
              value: Object.keys(columnsState).length > 0 ? columnsState : undefined,
              onChange: (newColumnsState) => {
                handleColumnsStateChange(newColumnsState);
              },
            }}
            toolBarRender={() => [
              // nút lưu mẫu bc
              <SaveReportForm
                setIsResizablePresent={setIsResizablePresent}
                templateReportModalRef={templateReportModalRef}
                userReports={userReports}
                selectedReportId={selectedReportId}
                templateReportModalProps={templateReportModalProps}
              />,
            ]}
            key={tableKey}
            rowKey="stt"
            pagination={{
              ...tablePagination(dataPaging, setDataPaging),
              total: purchaseOrderStore.searchSynchPO?.paging?.recordsTotal,
            }}
            onChange={(_, filter) => {
              setFilteredInfo(filter)
            }}
          // request={async (params = {}, sort, filter) => {
          //     const data = await getDataTable({
          //         ...dataPaging,
          //         paging: {
          //             ...dataPaging.paging,
          //             orderBy:
          //                 Object.values(sort)[0] === "ascend"
          //                     ? `${Object.keys(sort)[0].split(",").pop()}`
          //                     : undefined,
          //             orderByDesc:
          //                 Object.values(sort)[0] === "descend"
          //                     ? `${Object.keys(sort)[0].split(",").pop()}`
          //                     : undefined,
          //         },
          //     });
          //     if (Object.values(sort).length) {
          //         setDataPaging({
          //             ...dataPaging,
          //             paging: {
          //                 ...dataPaging.paging,
          //                 orderBy:
          //                     Object.values(sort)[0] === "ascend"
          //                         ? `${Object.keys(sort)[0].split(",").pop()}`
          //                         : undefined,
          //                 orderByDesc:
          //                     Object.values(sort)[0] === "descend"
          //                         ? `${Object.keys(sort)[0].split(",").pop()}`
          //                         : undefined,
          //             },
          //         });
          //     }
          //     return {
          //         data: data,
          //         success: purchaseOrderStore.searchSynchPO?.isSuccess
          //     };

          // }}
          />
        </BoxContainer>

        {/* Form search */}
        <SearchBox
          onClose={onClose}
          className="lg:!w-[50%]"
          open={open}
          footer={
            <CustomButton
              htmlType="submit"
              className="my-auto ml-auto"
              type="primary"
              loading={loading}
              icon={<HiSearch />}
              onClick={() => {
                setIsReady(true)
                // Kiểm tra có đang filter không
                // bởi vì khi thi call api search thì bị dính filter của table
                // vì vậy khi call api search phải kiểm tra xem table có đang filter không
                // nếu có thì thay đổi table key (thay đổi table key thì table tự reset)
                // nhưng khi thay đổi table key thì sẽ call 2 lần api
                // việc kiểm tra để hạn chế call api 2 lần khi search mà không sử dụng filter của table
                // call api 2 lần mình vẫn chưa biết cách khắc phục nên chỉ có thể hạn chế lại thôi
                if (filteredInfo) {
                  const hasNonNullValue = Object.values(filteredInfo).some(v => v !== null)
                  if (hasNonNullValue) setTableKey(tableKey => tableKey + 1);
                }
                searchForm.submit()
              }}
            >
              Tìm kiếm
            </CustomButton>
          }
        >
          <SearchForm
            form={searchForm}
            // column="col-1"
            onFinish={(values) => {
              onFinish(values)
              setOpen(false)
            }}
            colon={false}
            className="gap-x-16"
          >
            {/* Purchase order ================================================================================================*/}
            <div className="relative">
              <SearchItem dependencies={['purchaseOrderCodeTo']}
                name={'purchaseOrderCodeFrom'} label={'Số PO'}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && getFieldValue('purchaseOrderCodeTo')) {
                        return Promise.reject(new Error('Chưa nhập giá trị From.'));
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Nhập giá trị từ"
                  allowClear
                  onChange={(e) => {
                    if (!e.target.value) {
                      searchMultiPORef.current?.clearData()
                    }
                  }}
                />
              </SearchItem>
              <div
                onClick={() => {
                  setShowModalPO(true)
                }}
                className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                <HiOutlineExternalLink className="text-xl" />
              </div>
            </div>
            <SearchItem
              colon={true}
              name={'purchaseOrderCodeTo'} label={'To'}
              dependencies={['purchaseOrderCodeFrom']}
              validateTrigger={['onBlur']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    // if (!value || (getFieldValue('purchaseOrderCodeFrom') || 0) < value) {
                    //     return Promise.resolve();
                    // }
                    // lấy to - from nếu ra số số âm có nghĩa là to < from
                    const comparisonResult = compareStrings(value || '', getFieldValue('purchaseOrderCodeFrom') || '')
                    if (!value || comparisonResult >= 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Giá trị To phải lớn hơn From.'));
                  },
                })
              ]}
            >
              <Input style={{ width: '100%' }} placeholder="Nhập giá trị đến" />
            </SearchItem>

            {/*Số PR ================================================================================================ */}
            <div className="relative">
              <SearchItem name={'prNumberFrom'} label={'Số PR'}
                dependencies={['prNumberTo']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && getFieldValue('prNumberTo')) {
                        return Promise.reject(new Error('Chưa nhập giá trị From.'));
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder="Nhập giá trị từ"
                  allowClear
                  onChange={(e) => {
                    if (!e.target.value) {
                      searchMultiPRRef.current?.clearData()
                    }
                  }}
                />
              </SearchItem>
              <div
                onClick={() => {
                  setShowModalPR(true)
                }}
                className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                <HiOutlineExternalLink className="text-xl" />
              </div>
            </div>
            <SearchItem colon={true} name={'prNumberTo'} label={'To'}
              dependencies={['prNumberFrom']}
              validateTrigger={['onBlur']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    // if (!value || (getFieldValue('prNumberFrom') || 0) < value) {
                    //     return Promise.resolve();
                    // }
                    const comparisonResult = compareStrings(value || '', getFieldValue('prNumberFrom') || '')
                    if (!value || comparisonResult >= 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Giá trị To phải lớn hơn From.'));
                  },
                })
              ]}
            >
              <Input style={{ width: '100%' }} placeholder="Nhập giá trị đến" />
            </SearchItem>
            {/*Loại PO ================================================================================================*/}
            <SearchItem name={'documentType'} label={'Loại PO'}>
              <Input placeholder="Nhập loại PO" />
            </SearchItem>
            {/*Nhà máy ================================================================================================*/}
            <SearchItem name={'storeName'} label={'Nhà máy'} start>
              <Input placeholder="Nhập nhà máy" />
            </SearchItem>
            {/* Mã hàng hóa ================================================================================================*/}
            <div className="relative col-start-1">
              <SearchItem name={'productCodeFrom'} label={'Mã hàng hóa'}
                dependencies={['productCodeTo']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && getFieldValue('productCodeTo')) {
                        return Promise.reject(new Error('Chưa nhập giá trị From.'));
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder="Nhập giá trị từ"
                  allowClear
                  onChange={(e) => {
                    if (!e.target.value) {
                      searchMultiProductRef.current?.clearData()
                    }
                  }}
                />
              </SearchItem>
              <div
                onClick={() => {
                  setShowModalProduct(true)
                }}
                className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                <HiOutlineExternalLink className="text-xl" />
              </div>
            </div>
            <SearchItem colon={true} name={'productCodeTo'} label={'To'}
              dependencies={['productCodeFrom']}
              validateTrigger={['onBlur']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    // if (!value || (getFieldValue('productCodeFrom') || 0) < value) {
                    //     return Promise.resolve();
                    // }
                    const comparisonResult = compareStrings(value || '', getFieldValue('productCodeFrom') || '')
                    if (!value || comparisonResult >= 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Giá trị To phải lớn hơn From.'));
                  },
                })
              ]}
            >
              <Input style={{ width: '100%' }} placeholder="Nhập giá trị đến" />
            </SearchItem>
            {/* Tên hàng hóa ================================================================================================*/}
            <SearchItem name={'productName'} label={'Tên hàng hóa'} >
              <Input placeholder="Nhập tên hàng hóa" />
            </SearchItem>
            {/*Mã nhà cung cấp ================================================================================================*/}
            <div className="relative col-start-1">
              <SearchItem
                label={'Mã nhà cung cấp'}>
                <Space.Compact block>
                  <Form.Item
                    name={'vendorNumberFrom'}
                    dependencies={['vendorNumberTo']}
                    noStyle
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value && getFieldValue('vendorNumberTo')) {
                            return Promise.reject(new Error('Chưa nhập giá trị From.'));
                          }
                          return Promise.resolve();
                        },
                      }),

                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      placeholder="Nhập giá trị từ"
                      allowClear
                      onChange={(e) => {
                        if (!e.target.value) {
                          searchMultiVendorRef.current?.clearData()
                        }
                      }}
                    />
                  </Form.Item>
                  <CustomButton onClick={() => {
                    setModalVendorOpen(true)
                    modalVendorRef.current = 'from'
                  }}>
                    <HiDotsHorizontal />
                  </CustomButton>
                </Space.Compact>
              </SearchItem>
              <div
                onClick={() => {
                  setShowModalVendor(true)
                }}
                className="absolute top-1/2 -right-6 cursor-pointer hover:text-blue-500">
                <HiOutlineExternalLink className="text-xl" />
              </div>
            </div>
            <SearchItem colon={true} label={'To'}>
              <Space.Compact block>
                <Form.Item
                  noStyle
                  name={'vendorNumberTo'}
                  dependencies={['vendorNumberFrom']}
                  validateTrigger={['onBlur']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        // lấy to - from nếu ra số số âm có nghĩa là to < from
                        const comparisonResult = compareStrings(value || '', getFieldValue('vendorNumberFrom') || '')
                        if (!value || comparisonResult >= 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Giá trị To phải lớn hơn From.'));
                      },
                    })
                  ]}
                >
                  <Input style={{ width: '100%' }} placeholder="Nhập giá trị đến" />
                </Form.Item>
                <CustomButton onClick={() => {
                  setModalVendorOpen(true)
                  modalVendorRef.current = 'to'
                }}>
                  <HiDotsHorizontal />
                </CustomButton>
              </Space.Compact>
            </SearchItem>
            {/* Vendor name ================================================================================================*/}
            <SearchItem name={'vendorName'} label={'Tên nhà cung cấp'}>
              <Input placeholder="Nhập tên nhà cung cấp" />
            </SearchItem>
            <SearchItem initialValue={'Custom'} name={'common'} label={'Ngày giao hàng'} start>
              <Select options={commonStore.listCommonDate} fieldNames={{ label: 'value', value: 'key' }} onChange={async (value) => {
                if (value && value !== "Custom") {
                  setDisableDatePicker(true)
                  await commonPresenter.getDateByCommonDate(commonStore, value)
                  if (commonStore.commonDate.fromDate) {
                    searchForm.setFieldsValue({ documentDateFrom: dayjs(commonStore.commonDate.fromDate, "YYYY-MM-DD"), documentDateTo: dayjs(commonStore.commonDate.toDate, "YYYY-MM-DD") })
                  }
                } else {
                  setDisableDatePicker(false)
                  searchForm.setFieldsValue({ documentDateFrom: undefined, documentDateTo: undefined })
                }
              }} />
            </SearchItem>
            <SearchItem valuePropName='checked' label={" "} name={'syncByDate'}>
              <Checkbox><span className='font-bold'>Đồng bộ theo ngày</span></Checkbox>
            </SearchItem>

            {/* Từ ngày ================================================================================================*/}
            <SearchItem
              required={true}
              name={'documentDateFrom'}
              label={'Ngày giao hàng dự kiến'} start
              // rules={[{ required: true, message: 'Chưa chọn "Ngày giao hàng dự kiến".' }]}
              dependencies={['documentDateTo']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value || getFieldValue('documentDateTo')) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Chưa chọn "Ngày giao hàng dự kiến".'));;
                  },
                })
              ]}
            >
              <DatePicker disabled={disableDatePicker} format={'DD/MM/YYYY'} changeOnBlur className="w-full" />
            </SearchItem>
            {/* Đến ngày ================================================================================================*/}
            <SearchItem
              colon={true}
              name={'documentDateTo'} label={'To'}
              dependencies={['documentDateFrom']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value || getFieldValue('documentDateFrom')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Chưa chọn "Ngày giao hàng dự kiến".'));
                  },
                })
              ]}
            >
              <DatePicker disabled={disableDatePicker} format={'DD/MM/YYYY'} changeOnBlur className="w-full" />
            </SearchItem>
          </SearchForm>
          {/* Modal search nhà cung cấp */}
          <ModalVendor
            openModal={modalVendorOpen}
            onModalOpenChange={(open) => setModalVendorOpen(open)}
            onSelected={(value) => {
              const currentSelectedVendor = modalVendorRef.current;
              if (currentSelectedVendor === 'from') {
                searchForm.setFieldValue('vendorNumberFrom', value.vendor);
              } else {
                searchForm.setFieldValue('vendorNumberTo', value.vendor);
                searchForm.validateFields(['vendorNumberTo'])
              }
            }} />

          {/* Tìm kiếm PO */}
          <SearchMultiplePopup
            ref={searchMultiPORef}
            title="PO"
            open={showModalPO}
            onOpenChange={setShowModalPO}
            onOk={(value) => {
              searchForm.setFieldValue('purchaseOrderCodeFrom', value)
            }}
            onReset={() => {
              searchForm.setFieldValue('purchaseOrderCodeFrom', undefined)
            }}
          />
          {/* Tìm kiếm PR */}
          <SearchMultiplePopup
            ref={searchMultiPRRef}
            title="PR"
            open={showModalPR}
            onOpenChange={setShowModalPR}
            onOk={(value) => {
              searchForm.setFieldValue('prNumberFrom', value)
            }}
            onReset={() => {
              searchForm.setFieldValue('prNumberFrom', undefined)
            }}
          />
          {/* Tìm kiếm mã hàng hóa */}
          <SearchMultiplePopup
            ref={searchMultiProductRef}
            title="Mã hàng"
            open={showModalProduct}
            onOpenChange={setShowModalProduct}
            onOk={(value) => {
              searchForm.setFieldValue('productCodeFrom', value)
            }}
            onReset={() => {
              searchForm.setFieldValue('productCodeFrom', undefined)
            }}
          />
          {/* Tìm kiếm nhà cung cấp (excel) */}
          <SearchMultiplePopup
            ref={searchMultiVendorRef}
            title="Nhà cung cấp"
            open={showModalVendor}
            onOpenChange={setShowModalVendor}
            onOk={(value) => {
              searchForm.setFieldValue('vendorNumberFrom', value)
            }}
            onReset={() => {
              searchForm.setFieldValue('vendorNumberFrom', undefined)
            }}
          />
        </SearchBox>
      </PageWrapper>
    )
  })
}