import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { moduleItem } from '../mock';
import ThemeConfig from 'pages/dashboard/changeTheme';
import { uiStore } from 'stores/root_store';
import PageWrapper from 'pages/Components/PageWrapper';
import BoxContainer from 'base/ui/components/BoxContainer';
import { ModuleModel, PageViewModel, User } from 'services/User/user_services';
import { GlobeEyeCircle } from 'base/ui/components/Icons';

const colors = [
  { backgroundColor: '#F0FDFE', border: '#C5EFF5', color: '#0BA0C7' },
  { backgroundColor: '#EEFFF8', border: '#C1EAE0', color: '#1EBD9B' },
  { backgroundColor: '#F3F7FF', border: '#B9E4F8', color: '#4074B5' },
  { backgroundColor: '#F8FCE7', border: '#DEE8B4', color: '#8E9A5D' },
  { backgroundColor: '#FFF9EA', border: '#FFE8AC', color: '#E3B724' },
  { backgroundColor: '#FFF7F0', border: '#FFE0C6', color: '#F76949' },
  { backgroundColor: '#FFF4F4', border: '#FFDDDD', color: '#CA6B8D' },
  { backgroundColor: '#F4F0FF', border: '#E6DCFF', color: '#7352C7' },
];
const colorsThemeDark = [
  { backgroundColor: '#F2F5F7', border: '#E3E3E4', color: '#354A5F' },
  { backgroundColor: '#F3F9FF', border: '#D7E9FD', color: '#2667B2' },
  { backgroundColor: '#EEFFF8', border: '#C1EAE0', color: '#1EBD9B' },
  { backgroundColor: '#F8FCE7', border: '#DEE8B4', color: '#8E9A5D' },
  { backgroundColor: '#FAF8ED', border: '#EFEAC7', color: '#ABA680' },
  { backgroundColor: '#FCF1F0', border: '#F9DEDD', color: '#E49794' },
  { backgroundColor: '#FCF6FB', border: '#ECDFEB', color: '#975F93' },
  { backgroundColor: '#F4F0FF', border: '#E6DCFF', color: '#8667D3' },
];

export const createTask = () => {
  return observer(() => {
    const { id } = useParams();
    // Lưu vị trí của color
    // nếu số lượng item lớn hơn số màu thì quay ngược lại màu số 0
    let colorIndex = 0;

    // lấy id trên url để render giao diện
    const [dataRender, setDataRender] = useState<ModuleModel>();
    // const dataRender = moduleItem.find((item) => item.id === id);
    // useEffect(() => {
    //   setDataRender(moduleItem.find((item) => item.id === id));
    // }, [id]);

    useEffect(() => {
      const data = JSON.parse(localStorage.getItem('user_session') || '{}') as User;
      const modules = data.webPermission.moduleModel || [];
      const module = modules.find((x) => x.moduleId === id);
      document.title = module ? module.moduleName + '- iCRM System' : 'iCRM System';

      setDataRender(module);
    }, [id]);

    return (
      // <div className="flex flex-col h-full">
      <PageWrapper
        breadcrumb={[
          {
            text: dataRender ? dataRender.moduleName : '',
          },
        ]}
      >
        {/* Thay đổi theme */}
        <ThemeConfig />
        <BoxContainer fullHeight>
          {dataRender &&
            dataRender.menuModel.map((item, index) => (
              <div className="" key={index}>
                <div className="bg-[#F0F2F4] px-[12px] py-2 rounded-t-[2px]">
                  <span className="text-base font-semibold text-dark">{item.menuName}</span>
                </div>
                <div className="pt-[12px] px-[12px] grid grid-cols-[repeat(auto-fill,minmax(295px,_1fr))] gap-[12px]">
                  {item.pageViewModels.map((chilItem, idx) =>
                    // kiểm tra môi trường, nếu là ở dev thì xài link, ngược lại nhảy sang mvc
                    process.env.NODE_ENV === 'development' ? (
                      <Link to={chilItem.pageUrl} key={idx}>
                        <Module child={chilItem} colorIndex={colorIndex} pageName={chilItem.pageName} />
                        <div className="hidden">
                          {colorIndex === colors.length - 1 ? (colorIndex = 0) : colorIndex++}
                        </div>
                      </Link>
                    ) : // Kiểm tra domain config xem là mvc hay fe
                      chilItem.domainConfig === 1 ? (
                        <a
                          href={
                            `${chilItem.domainConfigUrl + chilItem.pageUrl}` +
                            `${chilItem.parameter ? chilItem.parameter : ''}`
                          }
                          key={idx}
                        >
                          <input name="lang" hidden value={'vi'} />
                          <button type="submit" className="w-full">
                            <Module child={chilItem} colorIndex={colorIndex} pageName={chilItem.pageName} key={idx} />
                          </button>
                          <div className="hidden">
                            {colorIndex === colors.length - 1 ? (colorIndex = 0) : colorIndex++}
                          </div>
                        </a>
                      ) : (
                        <Link to={'/' + chilItem.pageUrl} key={idx}>
                          <Module child={chilItem} colorIndex={colorIndex} pageName={chilItem.pageName} />
                          <div className="hidden">
                            {colorIndex === colors.length - 1 ? (colorIndex = 0) : colorIndex++}
                          </div>
                        </Link>
                      ),
                  )}
                </div>
              </div>
            ))}
        </BoxContainer>
      </PageWrapper>
    );
  });
};

const Module = ({ colorIndex, child, pageName }: { colorIndex: number; child: PageViewModel; pageName: string }) => {
  return (
    <div
      style={{
        backgroundColor:
          uiStore.theme.name === 'gray'
            ? colorsThemeDark[colorIndex].backgroundColor
            : colors[colorIndex].backgroundColor,
        borderColor: uiStore.theme.name === 'gray' ? colorsThemeDark[colorIndex].border : colors[colorIndex].border,
      }}
      className={`min-h-[108px] border rounded flex flex-col justify-center items-center gap-[6px]`}
    >
      {child.icon ? (
        <div
          className="w-12 h-12 rounded-full flex items-center justify-center"
          style={{
            backgroundColor:
              uiStore.theme.name === 'gray' ? colorsThemeDark[colorIndex].color : colors[colorIndex].color,
          }}
        >
          <i className={child.icon + ' text-white text-lg'} />
        </div>
      ) : (
        <GlobeEyeCircle
          width="48"
          height="48"
          style={{
            fill: uiStore.theme.name === 'gray' ? colorsThemeDark[colorIndex].color : colors[colorIndex].color,
          }}
        />
      )}
      <span className="text-base font-medium text-dark text-center">{pageName}</span>
    </div>
  );
};
