/* eslint-disable react-hooks/exhaustive-deps */

import * as Icon from 'base/ui/components/Icons';

import { Checkbox, Form, InputNumber, Radio, Space, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { accountPresenter, accountStore, commonMasterDataPresenter, commonMasterDataStore, departmentPresenter, departmentStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import { CommonModel } from 'services/RawData/commonMasterData_services';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { DataDepartment } from 'services/RawData/department_services';
import { SYSTEM_TITLE } from 'utils/constants';
import { User } from 'services/User/user_services';
import { getUserSession } from 'utils/localStorageHelper';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

const createAddUpdateDepartment = () => {
  return observer(() => {
    const { t } = useTranslation();

    // Get data params from url
    const { id: idParams } = useParams();
    const userInLocal = getUserSession() as User;
    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }

    const navigate = useNavigate();

    //==========================STATE===================//
    // Loading
    const [loading, setLoading] = useState(false);
    // List organization
    const [listOrg, setListOrg] = useState<CommonModel[]>([]);
    // List store
    const [listStore, setListStore] = useState<CommonModel[]>([]);

    // Check button save and save continue
    let isSaveAndContinue = false;
    const [form] = Form.useForm();

    // =============================================================================================================================
    // =============================================================================================================================
    // =============================================================================================================================
    // Call api get list company
    const getListCompany = async () => {
      await commonMasterDataPresenter.getDropdownCompanyV2(commonMasterDataStore);
      if (commonMasterDataStore.listCompany.length) {
        setListOrg(commonMasterDataStore.listCompany);
      }
    };
    // Call api get list store by companyID (mã chi nhánh) => Dự án DGW gọi là trung tâm bảo hành
    const getListStore = async (companyId?: string) => {
      await commonMasterDataPresenter.getDropDownStore(commonMasterDataStore, companyId);
      if (commonMasterDataStore.commonRespone.isSuccess) {
        setListStore(commonMasterDataStore.commonRespone.data);
      }
    };

    // Call api detail department
    const getDetailDepartment = async (departmentId: string) => {
      await departmentPresenter.getDetailDepartment(departmentStore, departmentId);
      if (departmentStore.detailDepartmentResponse.isSuccess) {
        form.setFieldsValue(departmentStore.detailDepartmentResponse.data);
      }
      setLoading(false);
    };


    useEffect(() => {
      (async () => {
        if (idParams) {
          setLoading(true);
          await getListCompany();
          getDetailDepartment(idParams);
        } else {
          getListStore(userInLocal.companyId);
          getListCompany();
        }

      })()
      // Call api first access

    }, []);

    // const idCompany = useWatch('companyId', form);
    // useEffect(() => {
    //   getListStore(idCompany);
    // }, [idCompany]);

    // Handle create department
    const onFinish = async (values: any) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        const checkStore = listStore.find((i) => i.key === userInLocal.saleOrgCode);

        const dataSendApi = {
          ...values,
          actived: true,
          storeId: checkStore?.storeId,
        } as DataDepartment;
        await departmentPresenter.createDetailDepartment(departmentStore, dataSendApi);
        if (departmentStore.createDetailDepartmentResponse.isSuccess) {
          message.success(departmentStore.createDetailDepartmentResponse.message);

          // Redirect to search page department (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Department');
          }
          form.resetFields();
        }
      } else {
        const dataSendApi = {
          ...values,
          storeId: departmentStore.detailDepartmentResponse.data?.storeId,
          departmentId: idParams,
        } as DataDepartment;

        // Call api update
        await departmentPresenter.updateDetailDepartment(departmentStore, dataSendApi);
        if (departmentStore.updateDetailDepartmentResponse.isSuccess) {
          message.success(departmentStore.updateDetailDepartmentResponse.message);

          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Department');
          }
        }
      }
      setLoading(false);
    };

    const userSession = getUserSession();

    // Set value default of form
    const initialValues = {
      companyId: userSession.companyId,
      actived: true,
    };

    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }

    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('masterdata.department.title'),
                link: '/MasterData/Department',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/Department/${idParams}` : '/MasterData/Department/create',
              },
            ]}
            extra={
              <div className="custom__mobile--save flex space-x-2">
                {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
              </div>
            }
          />

          {/* Form nhập tìm kiếm */}
          <BoxContainer fullHeight>
            <Spin spinning={loading}>
              <SearchForm
                form={form}
                onFinish={onFinish}
                layout='horizontal'
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 13 }}
                autoComplete='off'
                initialValues={idParams ? {} : initialValues}
              >
                {/* Công ty */}
                <SearchItem
                  label={t('masterdata.department.company')}
                  name="companyId"
                  rules={[
                    { required: true, message: `${t('message.required-select')} ${t('masterdata.company.title')}` },
                  ]}
                >
                  <CustomSelect options={listOrg} fieldNames={{ label: 'value', value: 'key' }} allowClear />
                </SearchItem>
                {/* Mã bộ phận */}
                <SearchItem
                  label={t('masterdata.department.department-code')}
                  name="departmentCode"
                  rules={[
                    {
                      required: true,
                      message: `${t('message.required-input')} ${t('masterdata.department.department-code')}`,
                    },
                  ]}
                >
                  <CustomInput />
                </SearchItem>
                {/* Tên bộ phận */}
                <SearchItem
                  label={t('masterdata.department.department-name')}
                  name="departmentName"
                  rules={[
                    {
                      required: true,
                      message: `${t('message.required-input')} ${t('masterdata.department.department-name')}`,
                    },
                  ]}
                >
                  <CustomInput />
                </SearchItem>
                {/* Mã trung tâm bảo hành */}
                {/* <SearchItem label={t('masterdata.department.branch')} name="storeId">
                  <CustomSelect options={listStore} fieldNames={{ label: 'value', value: 'storeId' }} allowClear />
                </SearchItem> */}
                {/* Thứ tự hiển thị */}
                <SearchItem label={t('masterdata.department.order-index')} name="orderIndex">
                  <InputNumber className={'!w-full '} controls={false} />
                </SearchItem>
                {/* Trạng thái */}
                <SearchItem label={t('masterdata.status')} name="actived">
                  <Radio.Group>
                    <Radio value={true}>{t('radio-btn.active')}</Radio>
                    <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                  </Radio.Group>
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateDepartment;
