import provincePage from '.';
import createAddUpdateProvince from './CreateUpdate';
import { provinceRoutes } from './routes';

export const createProvincePage = () => {
  const Province = provincePage();
  const AddProvince = createAddUpdateProvince();
  const UpdateProvince = createAddUpdateProvince();

  const ProvincePage = provinceRoutes;
  return () => <ProvincePage Province={Province} AddProvince={AddProvince} UpdateProvince={UpdateProvince} />;
};
