
import * as Icon from 'base/ui/components/Icons';

import { Form, Input, Modal, Select, Tooltip } from 'antd';
import { DataSearchPage, statusList } from '../model';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { pagePresenter, pageStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import CustomSelect from 'base/ui/components/CustomSelect';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { HiSearch } from 'react-icons/hi';
import { ProColumns } from '@ant-design/pro-components';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import { PageModel } from 'services/RawData/page_services';

const { confirm } = Modal;

const pagePage = () => {
    return observer(() => {
        usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

        const { t } = useTranslation();

        const pagetitle = "Chức năng";
        document.title = pagetitle + ' - ' + SYSTEM_TITLE;
        const navigate = useNavigate();
        const [form] = Form.useForm();
        // state ===============================================================================
        const [openDrawer, setOpenDrawer] = useState(false);

        // Columns table title
        const defaultColumn: ProColumns<PageModel>[] = [
            // stt
            {
                title: t('permission.account.stt'),
                dataIndex: 'stt',
                key: 'stt',
                width: 60,
                align: 'center',
                render: (text) => <div className="!text-center">{text}</div>,
                fixed: 'left',
            },
            // Tên chức năng
            {
                title: "Tên chức năng",
                dataIndex: 'pageName',
                key: 'pageName',
                width: 350,
            },
            // Đường dẫn
            {
                title: 'Đường dẫn',
                dataIndex: 'pageUrl',
                key: 'pageUrl',
                width: 250,
            },
            // Danh mục
            {
                title: "Danh mục",
                dataIndex: 'menuName',
                key: 'menuName',
                width: 200,
            },
            {
                title: "Thứ tự",
                dataIndex: 'orderIndex',
                key: 'orderIndex',
                width: 70,
                onCell: () => ({
                    className: 'text-right'
                })
            },
            {
                title: "Icon",
                dataIndex: 'icon',
                key: 'icon',
                width: 70,
                onCell: () => ({
                    className: 'text-center'
                }),
                render: (text, record) => {
                    return text !== "-" ? <i className={`${text}`}></i> : text
                }
            },
            // trạng thái
            {
                title: t('permission.account.status'),
                dataIndex: 'actived',
                width: 100,
                key: 'actived',
                render: (text) => (
                    <div className="w-full flex justify-center">
                        {text ? (
                            <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
                        ) : (
                            <Icon.CloseStatus width="15" className="!fill-red-500" />
                        )}
                    </div>
                ),
            },
            // Chức năng
            {
                title: t('permission.account.actions'),
                dataIndex: '',
                key: '',
                width: 150,
                className: '!rounded-none !py-1',
                fixed: 'right',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div className="w-full flex items-center justify-center gap-x-1">
                            {userStore.userPermission?.includes('EDIT') ? (
                                <Tooltip title={t('btn.edit')}>
                                    <Link to={`${record.pageId}`}>
                                        <CustomButtonIcon color="#007BF1">
                                            <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                                        </CustomButtonIcon>
                                    </Link>
                                </Tooltip>
                            ) : null}
                            {userStore.userPermission?.includes('DELETE') ? (
                                <Tooltip title={t('btn.delete')}>
                                    <CustomButtonIcon
                                        color="#FC5C5E"
                                        onClick={() => {
                                            showDeleteConfirm(record);
                                        }}
                                    >
                                        <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                                    </CustomButtonIcon>
                                </Tooltip>
                            ) : null}

                        </div>
                    );
                },
            },
        ];
        // Custom default columns table with resizeable
        const [columns, setColumn] = useState<ProColumns<PageModel>[]>(defaultColumn);
        // Loading all page
        const [loading, setLoading] = useState(false);

        // Data search
        const [dataSearch, setDataSearch] = useState<DataSearchPage>({
            paging: {
                pageIndex: 1,
                pageSize: PAGE_SIZE_DEFAULT,
                orderBy: '',
                orderByDesc: '',
            },
        });
        const [listData, setListData] = useState<PageModel[]>([]);

        // function open hide drawer ===========================================================
        const showDrawer = () => {
            setOpenDrawer(true);
        };

        const onCloseDrawer = () => {
            setOpenDrawer(false);
        };

        // Call api search Data
        const getDataSearch = async (dataSearch: DataSearchPage) => {
            await pagePresenter.getListPage(pageStore, dataSearch);
            if (pageStore.searchPageRes.isSuccess) {
                setListData(pageStore.searchPageRes.data);
            }
        };



        // Handle click button search
        const handleSearch = async (values: any) => {
            setLoading(true);
            const tempDataSearch = {
                paging: {
                    ...dataSearch.paging,
                    pageIndex: 1,
                },
                ...values,
            };
            // set pageIndex 1 when search form
            setDataSearch(tempDataSearch);

            await getDataSearch(tempDataSearch);
            setLoading(false);
            onCloseDrawer();
        };

        // scroll to top
        useEffect(() => {
            window.scrollTo(0, 0);
            getDropDownMenu()
        }, []);

        // initialValues of form search
        const initialValues = {};

        // Call api delete
        const handleClickDeletePage = async (pageId: string) => {
            setLoading(true);
            if (pageId) {
                const result = await pagePresenter.deletePage(pageId);
                if (result) {
                    await getDataSearch(dataSearch);
                }
            }
            setLoading(false);
        };

        const getDropDownMenu = async () => {
            if (!pageStore.dropdownMenu.length) {
                await pagePresenter.getDropdownMenu(pageStore)
            }
        }

        // Ui modal confirm delete store
        const showDeleteConfirm = (item: PageModel) => {
            confirm({
                title: `${t('btn.delete')} "${item.pageName}"`,
                icon: <ExclamationCircleFilled />,
                content: `${t('message.question-delete')} "${item.pageName}"`,
                okText: t('btn.delete'),
                okType: 'danger',
                cancelText: t('btn.cancel'),
                className: 'custom__modalconfirm--delete',
                onOk() {
                    handleClickDeletePage(item.pageId || '');
                },
                onCancel() { },
            });
        };

        return (
            <div className="w-full">
                {/* ================ Breadcrumb =============== */}
                <CustomBreadcrumb
                    pagetitle={[{ title: pagetitle, link: '/Permission/Account' }]}
                    extra={
                        <>
                            <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                                <Icon.Filter className="fill-primary" />
                            </CustomButton>
                            {userStore.userPermission?.includes('CREATE') ? (
                                <CustomButton
                                    onClick={() => {
                                        navigate('create');
                                    }}
                                    type="primary"
                                    icon={<Icon.Plus className="!fill-white" />}
                                >
                                    {t('btn.add')}
                                </CustomButton>
                            ) : null}
                        </>
                    }
                />
                {/* Table */}
                <BoxContainer>
                    <CustomProTable<PageModel>
                        columns={columns}
                        handleResize={() => { }}
                        rowKey="stt"
                        size="small"
                        bordered
                        search={false}
                        loading={loading}
                        request={async (params = {}, sort, filter) => {
                            setLoading(true);
                            const values = form.getFieldsValue();
                            const data: any = await getDataSearch({
                                ...values,
                                paging: {
                                    ...dataSearch.paging,
                                    orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                    orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                },
                            });

                            if (Object.values(sort).length) {
                                setDataSearch({
                                    paging: {
                                        ...dataSearch.paging,
                                        orderBy: Object.values(sort)[0] === 'ascend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                        orderByDesc: Object.values(sort)[0] === 'descend' ? `${Object.keys(sort)[0].split(',').pop()}` : '',
                                    },
                                });
                            }
                            setLoading(false);
                            return {
                                data: data,
                                success: pageStore.searchPageRes.isSuccess,
                            };
                        }}
                        dataSource={listData}
                        scroll={{ y: 500 }}
                        pagination={{
                            ...tablePagination(dataSearch, setDataSearch),
                            total: pageStore.searchPageRes?.paging?.recordsTotal,
                        }}
                        options={false}
                    />
                </BoxContainer>
                {/* Form search */}
                <SearchBox
                    className="lg:!w-[35%]"
                    footer={[
                        <div key="footer" className="flex justify-end">
                            <CustomButton
                                type="primary"
                                icon={<HiSearch />}
                                htmlType="submit"
                                onClick={() => {
                                    form.submit();
                                    onCloseDrawer();
                                }}
                            >
                                {t('btn.search')}
                            </CustomButton>
                        </div>,
                    ]}
                    onClose={onCloseDrawer}
                    open={openDrawer}
                >
                    <SearchForm column="col-1" initialValues={initialValues} form={form} onFinish={handleSearch}>
                        <button hidden type='submit'></button>
                        {/* Tên màn hình */}
                        <SearchItem label={"Tên màn hình"} name={'screenName'}>
                            <Input />
                        </SearchItem>
                        {/* Danh mục */}
                        <SearchItem label={"Danh mục"} name={'menuId'}>
                            <Select
                                options={pageStore.dropdownMenu}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.value ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                fieldNames={{ label: 'value', value: 'key' }}
                            />
                        </SearchItem>
                        {/* trạng thái */}
                        <SearchItem label={t('permission.account.status')} name={'actived'}>
                            <CustomSelect options={statusList} className="!rounded-none" />
                        </SearchItem>

                    </SearchForm>
                </SearchBox>
            </div>
        );
    });
};

export default pagePage;
