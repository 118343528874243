/* eslint-disable react-hooks/exhaustive-deps */
import * as Icon from 'base/ui/components/Icons';

import { Form, InputNumber, Radio, Spin, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { provincePresenter, provinceStore, userStore } from 'stores/root_store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import { SYSTEM_TITLE } from 'utils/constants';
import { observer } from 'mobx-react';
import { removeEmptyValues } from 'utils/transformHelper';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';

const createAddUpdateProvince = () => {
  return observer(() => {
    // Get data params from url
    const { id: idParams } = useParams();
    const { t } = useTranslation();
    // Check id để đặt tên trang
    let pageTitle = '';

    // Nếu có id là chỉnh sửa (xem chi tiết)
    if (idParams) {
      // Kiểm tra phân quyền
      usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang
      pageTitle = t('page-title.update');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    } else {
      // Kiểm tra phân quyền
      usePermission('CREATE');
      pageTitle = t('page-title.create');
      document.title = pageTitle + ' - ' + SYSTEM_TITLE;
    }

    const navigate = useNavigate();
    // Check button save and save continue
    let isSaveAndContinue = false;
    const [form] = Form.useForm();
    // Loading
    const [loading, setLoading] = useState(false);

    // =================================================================================================
    //=================================================FUNCTION=======================================//
    // Call api detail store
    const getDetailProvince = async (id: string) => {
      await provincePresenter.getProvinceById(provinceStore, id);
      if (provinceStore.detailProvince.isSuccess) {
        form.setFieldsValue(provinceStore.detailProvince.data);
        setLoading(false);
      } else {
        navigate('/MasterData/Province');
      }
    };
    // Handle create company
    const onFinish = async (values: any) => {
      setLoading(true);
      removeEmptyValues(values);
      if (!idParams) {
        await provincePresenter.createProvince(provinceStore, values);
        if (provinceStore.crudResponse.isSuccess) {
          message.success(provinceStore.crudResponse.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Province');
          }
          form.resetFields();
        }
      } else {
        await provincePresenter.updateProvince(provinceStore, {
          ...values,
          provinceId: idParams,
        });
        if (provinceStore.crudResponse.isSuccess) {
          message.success(provinceStore.crudResponse.message);
          // Redirect to search page routing (Button Save)
          if (!isSaveAndContinue) {
            navigate('/MasterData/Province');
          }
        }
      }
      setLoading(false);
    };

    const initialValues = {
      actived: true,
    };

    useEffect(() => {
      (async () => {
        if (idParams) {
          await getDetailProvince(idParams);
        }
      })();
    }, []);

    function ButtonCreateUpdate() {
      return (
        <>
          <CustomButton
            icon={<Icon.Download className="!fill-[#262626]" />}
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = false;
              form.submit();
            }}
          >
            {t('btn.save')}
          </CustomButton>
          <CustomButton
            type="primary"
            icon={<Icon.Download />}
            disabled={loading}
            loading={loading}
            onClick={() => {
              isSaveAndContinue = true;
              form.submit();
            }}
          >
            {idParams ? t('btn.save-update') : t('btn.save-continue-create')}
          </CustomButton>
        </>
      );
    }
    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('masterdata.province.title'),
                link: '/MasterData/Province',
              },
              {
                title: pageTitle,
                link: idParams ? `/MasterData/Province/${idParams}` : '/MasterData/Province/create',
              },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  {!idParams && userStore.userPermission?.includes('CREATE') ? <ButtonCreateUpdate /> : null}
                  {idParams && userStore.userPermission?.includes('EDIT') ? <ButtonCreateUpdate /> : null}
                </div>
              </>
            }
          />

          {/* Form nhập tìm kiếm */}
          <BoxContainer fullHeight>
            <Spin spinning={loading}>
              <SearchForm onFinish={onFinish} initialValues={idParams ? {} : initialValues} form={form}>
                {/* tỉnh thành */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường tỉnh thành' }]}
                  name={'provinceCode'}
                  label={t('masterdata.province.province-code')}
                >
                  <CustomInput />
                </SearchItem>
                {/* tên tỉnh thành */}
                <SearchItem
                  rules={[{ required: true, message: 'Hãy nhập thông tin cho trường tên tỉnh thành' }]}
                  name={'provinceName'}
                  label={t('masterdata.province.province-name')}
                >
                  <CustomInput />
                </SearchItem>
                {/* khu vực */}
                <SearchItem name={'area'} label={t('masterdata.province.area')}>
                  <CustomInput />
                </SearchItem>
                {/* thứ tự hiển thị */}
                <SearchItem name={'orderIndex'} label={t('masterdata.province.order-index')}>
                  <InputNumber className="!rounded-none !w-full" />
                </SearchItem>
                {/* trạng thái */}
                <SearchItem name={'actived'} label={t('masterdata.province.status')}>
                  <Radio.Group>
                    <Radio value={true}>{t('radio-btn.active')}</Radio>
                    <Radio value={false}>{t('radio-btn.in-active')}</Radio>
                  </Radio.Group>
                </SearchItem>
              </SearchForm>
            </Spin>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createAddUpdateProvince;
