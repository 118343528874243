import { Dropdown, MenuProps, Space } from 'antd';
import { ChevronDown, LayerGroup } from 'base/ui/components/Icons';
import { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { uiPresenter, uiStore } from 'stores/root_store';
import { getUserSession } from 'utils/localStorageHelper';
import { User } from 'services/User/user_services';
import { useTranslation } from 'react-i18next';

function Module() {
  const { t } = useTranslation();
  let items: MenuProps['items'];
  const user = getUserSession() as User;
  if (user && user?.webPermission?.moduleModel.length) {
    items = user.webPermission.moduleModel.map((item) => ({
      label: (
        <Link
          onClick={(e) => {
            uiPresenter.setSubmenuOpen(uiStore, '', '', '');
          }}
          to={'/Module/' + item.moduleId}
          className="flex gap-2 items-center"
        >
          <i className={item.icon} /> {item.moduleName}
        </Link>
      ),
      key: item.moduleId,
    }));
  }

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Space className="cursor-pointer h-[46px]">
        <span>
          <LayerGroup className={`${uiStore.theme.name === 'gray' ? '!fill-[#D1E8FF]' : '!fill-primary'}`} />
        </span>
        <span
          className={`${
            uiStore.theme.name === 'gray' ? 'text-[#FFFFFF]' : 'text-dark'
          } font-[400] text-[14px] leading-[22px]`}
        >
          {t('btn.select')}
        </span>
        <span>
          <ChevronDown className={`${uiStore.theme.name === 'gray' ? '!fill-[#D1E8FF]' : '!fill-dark'}`} />
        </span>
      </Space>
    </Dropdown>
  );
}

export default memo(observer(Module));
