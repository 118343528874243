import "antd/dist/reset.css";

import * as rootStore from "stores/root_store";

import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Button, ConfigProvider, Modal } from "antd";
import { PrivateRoute, PublicRoute } from "base/ui/components/Route";
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
import { clearUserSession, getAccessToken, getAccountId, getDeviceId, getRole, getUserName, getUserSession } from "utils/localStorageHelper";
import { cloneDeep, debounce } from "lodash";
import { createDefaultLayout, createLoginLayout } from "base/ui/layout/layout";
import { getDatabase, onValue, ref } from 'firebase/database';
import { uiPresenter, uiStore, userPresenter, userStore } from "stores/root_store";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";

import { FIREBASE_USERS } from "utils/constants";
import { Footer as FooterView } from "base/ui/layout/footer/footer";
import Header from "base/ui/layout/header/header";
import InternalServer from "pages/error/500/500page";
import Logout from "pages/logout";
import NotFound from "pages/error/404/404page";
import { ROUTES } from "base/routes/routes";
import RouteGuard from "RouteGuard";
import Sidebar from "base/ui/layout/sideBar/sideBar";
import { createPages } from "pages/create";
import dayjs from "dayjs";
import SignalRListener from "SignalRListener";
import enUS from "antd/locale/en_US";
import firebaseApp from './firebase/config';
import { observer } from "mobx-react";
import signalrConnection from "services/Notification/signalr-connection";
import viVN from "antd/locale/vi_VN";

export function createApp() {
  return observer(() => {
    const services = rootStore.services;

    // khởi tạo header, sidebar, footer
    // const Header = observer(() => <HeaderView />);
    // const Sidebar = observer(() => <SidebarView />);
    const Footer = observer(() => <FooterView />);

    // Tạo ra 2 layout
    // Layout cho trang login chỉ có content không có sidebar, header, footer
    const layoutLogin = createLoginLayout();
    // Layout mặc định sẽ có đầy đủ sidebar, header, footer
    const layoutDefault = createDefaultLayout(Header, Sidebar, Footer);
    // khỏi tạo các trang với layout tương ứng
    const { LoginPage, VerifyPage, PrintPDFPage } = useMemo(() => createPages(layoutLogin, services), []);


    const {
      //=============Mobile=====================
      HomePage,
      ModulePage,
      ChangePasswordPage,
      ChangePasswordPageAfter90Days,
      PurchaseOrderPage,
      LogInHistoryPage,
      VehicleRegistrationPage,
      IconsPage,
      MasterData,
      Permission,
      SynchPOPage,
      ReportPage,
      ReceiveSchedulePage,
      ConnectMSPage,
    } = useMemo(() => createPages(layoutDefault, services), []);

    const isAuthenticated = getAccessToken() ? true : false;

    /**
        * Hàm dùng để kiểm tra xem refreshtoken hiện tại so với refreshtoken lưu trên firebase
        * */
    const triggerToken = () => {
      const db = getDatabase(firebaseApp);
      const userUrl: string = `${FIREBASE_USERS || 'mes-dev-users'}/` + getUserName();
      const starCountRef = ref(db, userUrl);
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val().refreshToken;
        const user = getUserSession();

        if (data !== user.refreshToken) {
          uiPresenter.setOpenModalAuthorize(uiStore, true);
          uiPresenter.setMessageRefreshError(uiStore, 'Phiên đăng nhập hết hạn');
          userPresenter.signOut(userStore, getDeviceId(), true);
          // clearUserSession();
          // window.location.reload();
        }
      });
    };
    /**
     * Kiểm tra sự thay đổi user khi login
     */
    useEffect(() => {
      const user = getUserSession();
      userPresenter.setCurrentUser(userStore, user.refreshToken || null);
    }, []);

    useEffect(() => {
      if (userStore.currentUser) {
        const currentPath = window.location.pathname;


        if (currentPath !== ROUTES.CHANGE_PASSWORD_AFTER_90DAYS.LINK) {

          triggerToken();
        }
      }
    }, [userStore.currentUser]);

    // =========== XỬ LÝ THÔNG BÁO
    const userId = getAccountId()
    useEffect(() => {

      rootStore.notificationPresenter.countUnreadNotification(rootStore.notificationStore, userId || '')
      const connection = signalrConnection()
      // Nhận thông báo
      connection.on("ReceiveNotification", (message) => {
        const count = cloneDeep(rootStore.notificationStore.notiCount)
        rootStore.notificationPresenter.setNotificationCount(rootStore.notificationStore, count + 1)
      })
      return () => {
        connection.stop();
      };

    }, [userStore.userRespone.data]);

    const checkCloseWindow = () => {
      const storage = localStorage.getItem("logoutTime")
      const logoutTimeStorage = JSON.parse(storage || 'null');
      if (logoutTimeStorage) {
        const currentTime = dayjs();
        const timeParse = dayjs(logoutTimeStorage)
        if (currentTime.isAfter(timeParse)) {
          // userPresenter.signOut();
          // userPresenter.setCurrentUser(userStore, null)
          return false
        }
      }
      return true
    }

    const [logoutTime, setLogoutTime] = useState<number>(8 * 60 * 60); //

    useEffect(() => {
      const checkContinue = checkCloseWindow()
      if (checkContinue) {
        const logoutTimer = setTimeout(() => {
          // Thực hiện đăng xuất ở đây
          userPresenter.signOut(userStore, getDeviceId(), false);
          userPresenter.setCurrentUser(userStore, null)
        }, logoutTime * 1000);

        // Đặt lại thời gian đếm ngược mỗi khi người dùng thực hiện hành động
        // const resetTimer = () => {
        //   setLogoutTime(prev => {
        //     return prev === 1 * 60 ? 2 * 60 : 1 * 60
        //   }); // Đặt lại thời gian về 15 phút sau mỗi hành động
        //   clearTimeout(logoutTimer);
        // };

        const resetTimer = debounce(() => {
          setLogoutTime(prev => {
            return prev === (8 * 61 * 60) ? (8 * 60 * 60) : (8 * 61 * 60)
          }); // Đặt lại thời gian về 15 phút sau mỗi hành động
          // kiểm tra logoutTime đang là 15 phút hay không vì nếu đang là 15 phút mà set lại 15 phút thì nó sẽ không chạy useEffect
          // cần sử dụng useMemo để khi logoutTime thay đổi thì component không render lại
          const currentTime = dayjs();
          // gắng thời gian hết hạn (thời gian hiện tại + 1 tiếng)
          const newTime = currentTime.add(8, "hour")
          localStorage.setItem("logoutTime", JSON.stringify(newTime.format('YYYY-MM-DD[T]HH:mm:ss')))
          clearTimeout(logoutTimer);
        }, 1000)

        // Lắng nghe sự kiện DOM
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keypress', resetTimer);
        window.addEventListener('scroll', resetTimer);

        // Xóa bỏ lắng nghe khi component unmount
        return () => {
          clearTimeout(logoutTimer);
          window.removeEventListener('mousemove', resetTimer);
          window.removeEventListener('keypress', resetTimer);
          window.removeEventListener('scroll', resetTimer);
        };
      } else {
        userPresenter.signOut(userStore, getDeviceId(), false);
        setLogoutTime(prev => {
          return prev === (8 * 61 * 60) ? (8 * 60 * 60) : (8 * 61 * 60)
        });
      }
    }, [logoutTime]);


    return (
      <>
        <ConfigProvider
          // Chuyển ngôn ngữ của antd
          locale={rootStore.uiStore.language === "en" ? enUS : viVN}
          // đặt lại theme mặc định của antd (Đổi font, đổi cỡ chữ, đổi màu, ...)
          theme={{
            token: {
              colorPrimary: rootStore.uiStore.theme.color || "#1677ff",
              borderRadius: 2,
              fontSize: 13,
              fontFamily: "Roboto",
            },
          }}
        >
          <StyleProvider
            hashPriority="high"
            transformers={[legacyLogicalPropertiesTransformer]}
          >
            <Modal
              open={rootStore.uiStore.openModalAuthorize}
              title={`${rootStore.uiStore.messageRefreshError || 'Phiên làm việc đã hết hạn, vui lòng đăng nhập lại.'}`}
              footer={[
                <div className="flex justify-end">
                  <Button
                    type="primary"
                    key="submit"
                    onClick={() => {
                      rootStore.uiPresenter.setOpenModalAuthorize(rootStore.uiStore, false);
                      uiPresenter.setMessageRefreshError(uiStore, '');
                      // rootStore.userPresenter.signOut(userStore, getDeviceId(), true);
                      // clearUserSession();
                      // window.location.assign('/login');
                    }}
                  >
                    Đăng nhập lại
                  </Button>
                </div>
              ]}
              closable={false}
              closeIcon={<></>}
            />

            <BrowserRouter>
              <SignalRListener currentUserAccountId={getAccountId()} />
              <ScrollToTop />
              {/* <RouteGuard> */}
              <Routes>
                {/* Trang đăng nhập */}
                <Route path={ROUTES.LOGIN.BASE} element={<PublicRoute isAuthenticated={isAuthenticated} ><LoginPage /></PublicRoute>} />
                <Route path={ROUTES.VERIFY.BASE} element={<PublicRoute isAuthenticated={isAuthenticated} ><VerifyPage /></PublicRoute>} />
                {/* Trang chủ */}
                <Route path={ROUTES.HOME} element={<PrivateRoute isAuthenticated={isAuthenticated} ><HomePage /></PrivateRoute>} />
                <Route path={ROUTES.CHANGE_PASSWORD.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><ChangePasswordPage /></PrivateRoute>} />

                <Route path={ROUTES.CHANGE_PASSWORD_AFTER_90DAYS.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><ChangePasswordPageAfter90Days /></PrivateRoute>} />
                <Route path={ROUTES.HISTORY.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><LogInHistoryPage /></PrivateRoute>} />
                {/* ======= MODULE PAGE ============================================================================================== */}
                <Route path={ROUTES.MODULE.BASE} element={<ModulePage />} />

                {/* ========= PAGE CỦA DỰ ÁN ============================================================================================= */}
                {/* PURCHASE ORDER */}
                <Route path={ROUTES.PURCHASE_ORDER.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><PurchaseOrderPage /></PrivateRoute>} />
                {/* ĐĂNG KÝ GIAO HÀNG */}
                <Route path={ROUTES.VEHICLE_REGISTRATION.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><VehicleRegistrationPage /></PrivateRoute>} />
                {/* ĐỒNG BỘ PURCHASE ORDER */}
                <Route path={ROUTES.SYNCH_PO.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><SynchPOPage /></PrivateRoute>} />
                {/* BÁO CÁO */}
                <Route path={ROUTES.REPORT.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><ReportPage /></PrivateRoute>} />
                {/* LỊCH NHẬN HÀNG */}
                <Route path={ROUTES.RECEIVE_SCHEDULE.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><ReceiveSchedulePage /></PrivateRoute>} />
                {/* Trang in PDF */}
                <Route path={ROUTES.PRINT_PDF.BASE} element={<PrintPDFPage />} />
                {/* Kết nối Microsoft */}
                <Route path={ROUTES.CONNECT_MS.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><ConnectMSPage /></PrivateRoute>} />
                {/* Thông báo */}
                {/* ====================================================================================================================== */}


                {/* ========= MASTER DATA =============================================================================================== */}
                <Route path={ROUTES.MASTER_DATA.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><MasterData /></PrivateRoute>} />
                {/* PERMISSION */}
                <Route path={ROUTES.PERMISSION.BASE} element={<PrivateRoute isAuthenticated={isAuthenticated} ><Permission /></PrivateRoute>} />
                {/* ===================================================================================================================== */}

                <Route path={ROUTES.ICONS.BASE} element={<IconsPage />} />
                <Route path={"/logout"} element={<Logout />} />
                <Route path="/500" element={<InternalServer />} />
                <Route path="/*" element={<NotFound />} />
                {/* <Route path="/404" element={<NotFound />} /> */}
                {/* <Route path="/*" element={<Navigate to={'/404'} />} /> */}
              </Routes>
              {/* </RouteGuard> */}
            </BrowserRouter>
          </StyleProvider>
        </ConfigProvider>
        {/* )} */}
      </>
    );
  });
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
