import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import './index.css';
import { createApp } from './create_app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react';
// import { MaintainSession } from 'utils/common';

import i18n from 'translation/i18n';
import { I18nextProvider } from 'react-i18next';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

// Phải thêm phần mở rộng của dayjs để không bị lỗi
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

// MaintainSession();
// i18n.changeLanguage('en');
const App = createApp();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider>
      <App />
    </Provider>
  </I18nextProvider>,
);

reportWebVitals();
